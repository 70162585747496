import * as authenticationApi from "../../../../api/authentication";
import * as installationApi from "../../../../api/installation";

import { UserPlus, Upload } from "react-feather";
import React, { useContext, useState } from "react";
import { forEach, map, find } from "lodash";
import {
	useFetchData,
	usePermissions,
	useSearchFilter,
} from "../../../../hooks";
import { AppContext } from "../../../../context/app-context";
import Button from "../../../../components/layout/Button";
import UserWizard from "../../../../components/wizards/user-wizard/UserWizard";
import ImportUsersWizard from "../../../../components/wizards/groups-wizard/ImportUsersWizard";
import ReportWrapper from "../../../../components/report/ReportWrapper";
import { usersColumns, usersDefaultSorting } from "./helpers";
import { ManageColumns } from "../ManageColumns";

export default function RolesContainer(props) {
	const [state, setState] = useState({
		search: "",
		userWizardOpen: false,
		userWizardUser: null,
		userWizardMode: "add",
		importUsersWizardOpen: false,
	});

	const {
		state: { currentUser, spaceAccountOwnerOnly },
	} = useContext(AppContext);

	function openUserWizard(user, mode) {
		setState((_state) => ({
			..._state,
			userWizardOpen: true,
			userWizardUser: user,
			userWizardMode: mode,
		}));
	}

	async function updateOptions({ search }) {
		setState((_state) => ({ ..._state, initialLoad: false, search }));
	}

	const isAdmin = usePermissions("IsAdmin");
	const canManageUsers =
		usePermissions(null, "ManageUsers", true) || spaceAccountOwnerOnly;
	const clientId =
		currentUser && currentUser.ClientAdmin && currentUser.ClientAdmin.ClientID
			? currentUser.ClientAdmin.ClientID
			: null;

	const organizationId = props.selectedOrganization
		? props.selectedOrganization.OrganizationID
		: null;

	const { data, isLoading } = useFetchData(
		[],
		[
			authenticationApi.getUserReportForOrganization,
			installationApi.getOrganizationAccessGroupsWithSites,
			authenticationApi.getRolesForOrganization,
			installationApi.getLeaseRatesForOrganizationId,
		],
		[[organizationId], [organizationId], [organizationId], [organizationId]],
		[organizationId, state.userWizardOpen, state.importUsersWizardOpen]
	);

	const [users, organizationAccessGroups, roles] = data;

	// display group names
	forEach(users, (user) => {
		user.displayOrganizationAccessGroups = map(
			user.organizationAccessGroups,
			(group) => {
				let accessGroup = find(organizationAccessGroups, {
					OrganizationAccessGroupID: group.OrganizationAccessGroupID,
				});

				return accessGroup ? accessGroup.Name : "(deleted)";
			}
		).join(", ");

		if (user.notRedeemed) {
			user.hasPermissions = "Not Redeemed";
		} else {
			user.hasPermissions = user._hasPermissions;
		}

		user.status = !isNaN(user.UserID)
			? user.notRedeemed
				? "Not Redeemed"
				: "Registered"
			: user.UserID;
	});

	const filteredUsers = useSearchFilter(users, state.search, [
		"Email",
		"FirstName",
		"LastName",
		"displayOrganizationAccessGroups",
		"displayOrganizationAccessGroupsExpirations",
	]);

	if (state.userWizardOpen) {
		return (
			<UserWizard
				close={() => {
					setState((_state) => ({ ..._state, userWizardOpen: false }));
				}}
				mode={state.userWizardMode}
				user={state.userWizardUser}
				users={users}
				groups={organizationAccessGroups}
				roles={roles}
				selectedOrganization={props.selectedOrganization}
				sites={props.availableSites}
				currentUserIsAdmin={isAdmin}
				currentUserClientId={clientId}
				spaceAccountOwnerOnly={spaceAccountOwnerOnly}
			/>
		);
	}

	if (state.importUsersWizardOpen) {
		return (
			<ImportUsersWizard
				close={() => {
					setState((_state) => ({ ..._state, importUsersWizardOpen: false }));
				}}
				mode={state.groupsWizardMode}
				groups={organizationAccessGroups}
				selectedOrganization={props.selectedOrganization}
			/>
		);
	}

	return (
		<ReportWrapper
			{...props}
			title={"User Roles"}
			data={filteredUsers}
			columns={
				canManageUsers
					? ManageColumns(props, usersColumns(props), openUserWizard)
					: usersColumns({ spaceAccountOwnerOnly })
			}
			defaultSorted={usersDefaultSorting}
			updateOptions={updateOptions}
			loading={isLoading}
			showDateRangePicker={false}
			showSitePicker={false}
			rightActions={
				canManageUsers ? (
					<React.Fragment>
						<Button color="blue" onClick={() => openUserWizard(null, "add")}>
							<UserPlus size={20} /> Add User
						</Button>
						<Button
							style={{ marginLeft: 10 }}
							color="blue"
							onClick={() =>
								setState((_state) => ({
									..._state,
									importUsersWizardOpen: true,
								}))
							}
						>
							<Upload size={20} /> Import Users
						</Button>
					</React.Fragment>
				) : null
			}
		/>
	);
}
