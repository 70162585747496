import React from "react";
import { colours } from "../../styles";
import styled from "styled-components";

const Wrapper = styled.div`
	border: 1px solid ${colours.highlightBlue};
	border-radius: 4px;
	display: flex;
	height: 40px;
	margin: 0 auto;
	overflow: hidden;
	transition: 0.2s ease;
	width: ${(props) => `${props.length * 100}px`};
`;

const Item = styled.div`
	background-color: ${(props) =>
		props.selected ? colours.highlightBlue : colours.offWhite};
	border-right: 1px solid ${colours.highlightBlue};
	color: ${(props) => (props.selected ? colours.white : colours.highlightBlue)};
	flex: 1;
	font-size: 18px;
	font-weight: 600;
	line-height: 40px;
	text-align: center;

	&:hover {
		cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
		opacity: ${(props) => (props.disabled ? "1" : "0.8")};
	}

	&:last-child {
		border-right: 0;
	}
`;

export default function TabSelector(props) {
	return (
		<div>
			<Wrapper length={props.items.length}>
				{props.items.map((item) => (
					<Item
						key={item.value}
						onClick={() => props.onChange(item.value)}
						selected={props.value === item.value}
					>
						{item.label}
					</Item>
				))}
			</Wrapper>
		</div>
	);
}
