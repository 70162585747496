import * as Yup from "yup";
import * as sessionApi from "../../../api/session";
import WizardLayout, { StepTitle } from "../WizardLayout";
import Alert from "react-s-alert";
import Button from "../../layout/Button";
import DatePicker from "../../layout/DatePicker";
import React from "react";
import WizardNavigation from "../WizardNavigation";
import moment from "moment";

function _editStep({
	close,
	handleSubmit,
	isSubmitting,
	setFieldValue,
	values,
	keyStrokeHandler,
}) {
	return {
		id: "edit",
		label: "Edit Booking",
		render: () => {
			const showTime = values.durationType === "Hours";
			const format = values.durationType === "Hours" ? "d M Y h:iK" : "d M Y ";
			return (
				<div>
					<StepTitle>Please select the booking time range</StepTitle>
					<h3>Available from:</h3>
					<DatePicker
						style={{ width: "220px" }}
						options={{
							pickerMode: "datePicker",
							enableTime: showTime,
							altFormat: format,
						}}
						value={values.startDateTime}
						onChange={([date]) => {
							setFieldValue("startDateTime", date);

							if (
								date &&
								values.endDateTime &&
								moment(values.endDateTime).isBefore(moment(date))
							) {
								setFieldValue("endDateTime", null);
							}
						}}
					/>
					<h3>Available to:</h3>
					<DatePicker
						style={{ width: "220px" }}
						options={{
							pickerMode: "datePicker",
							enableTime: showTime,
							altFormat: format,
						}}
						value={values.endDateTime}
						onChange={([date]) => {
							if (values.durationType !== "Hours") {
								date = moment(date).endOf("day").toDate();
							}
							setFieldValue("endDateTime", date);

							if (
								date &&
								values.startDateTime &&
								moment(date).isBefore(moment(values.startDateTime))
							) {
								setFieldValue("startDateTime", null);
							}
						}}
					/>
				</div>
			);
		},
		footer: () => {
			return (
				<WizardNavigation
					leftItems={[
						<Button key="previous" onClick={close} color="red">
							Cancel
						</Button>,
					]}
					rightItems={[
						<Button
							key="submit"
							color="green"
							onClick={handleSubmit}
							disabled={isSubmitting}
							keyStrokeHandler={keyStrokeHandler}
						>
							Update
						</Button>,
					]}
				/>
			);
		},
	};
}

export default function EditBookingLeaseWizard(props) {
	return (
		<WizardLayout
			close={props.close}
			title={"Edit booking"}
			values={[
				{
					name: "organizationId",
					value: props.lease.OrganizationID,
					validator: Yup.string(),
				},
				{
					name: "userLeaseId",
					value: props.lease.UserLeaseID,
					validator: Yup.string(),
				},
				{
					name: "startDateTime",
					value: moment(props.lease.StartDateTime, "YYYYMMDDHHmm").toDate(),
				},
				{
					name: "endDateTime",
					value:
						props.lease.CachedDurationType === "Hours"
							? moment(props.lease.EndDateTime, "YYYYMMDDHHmm")
									.add(1, "minutes")
									.toDate()
							: moment(props.lease.EndDateTime, "YYYYMMDDHHmm").toDate(),
				},
				{
					name: "durationType",
					value: props.lease.CachedDurationType,
				},
			]}
			onSubmit={async (values, { setSubmitting }) => {
				setSubmitting(true);

				let data = {
					startDateTime: parseInt(
						moment(values.startDateTime).format("YYYYMMDDHHmm")
					),
					endDateTime: parseInt(
						props.lease.CachedDurationType === "Hours"
							? moment(values.endDateTime)
									.subtract(1, "minutes")
									.format("YYYYMMDDHHmm")
							: moment(values.endDateTime).format("YYYYMMDDHHmm")
					),
				};

				try {
					await sessionApi.updateUserLeaseTimeframes(
						values.organizationId,
						values.userLeaseId,
						data
					);

					Alert.success("Booking updated");
					props.close(true);
				} catch (error) {
					if (
						error.errors &&
						error.errors[0].code === "NewUserLeaseTimeframeOverflowsSpaces"
					) {
						Alert.error(
							"There are not enough spaces available to move the booking to the time you entered."
						);
					} else {
						Alert.error("Something went wrong. Please try again.");
					}
				} finally {
					setSubmitting(false);
				}
			}}
			steps={[_editStep]}
			initialStep={0}
			wizardProps={props}
		/>
	);
}
