import * as Yup from "yup";
import * as api from "./api";
import * as geofencingSteps from "./geofencing-steps";
import React from "react";
import WizardLayout from "../WizardLayout";

const constructWizard = (props) => {
	if (props.mode === "add-zone") {
		return {
			initialStep: 0,
			steps: [
				geofencingSteps.nameStep,
				geofencingSteps.bufferStep,
				geofencingSteps.drawingStep,
			],
		};
	} else if (props.mode === "edit-zone") {
		return {
			initialStep: 0,
			steps: [
				geofencingSteps.nameStep,
				geofencingSteps.bufferStep,
				geofencingSteps.editingStep,
			],
		};
	} else if (props.mode === "delete-zone") {
		return {
			initialStep: 0,
			steps: [geofencingSteps.deleteStep],
		};
	}
};

export default function GeofencingWizard(props) {
	const wizard = constructWizard(props);

	return (
		<WizardLayout
			close={props.close}
			title={(values) => {
				let _title = values.mode.replace(/-/g, " ");
				//capitalize the 1st letter of each word
				_title = _title.replace(/\b[a-z](?=[a-z]{2})/g, (letter) =>
					letter.toUpperCase()
				);

				return _title;
			}}
			values={[
				{ name: "mode", value: props.mode, validator: Yup.string() },
				{
					name: "zoneId",
					value: props.zone ? props.zone.ZoneID : null,
					validator: Yup.number().integer().nullable(),
				},
				{
					name: "name",
					value: props.zone ? props.zone.Name : null,
					validator: Yup.string(),
				},
				{
					name: "buffer",
					value: props.zone ? props.zone.BufferMeters : 15,
					validator: Yup.number().integer(),
				},
				{
					name: "points",
					value: props.zone ? props.zone.Points : [],
					validator: Yup.array(Yup.object()),
				},
				{
					name: "lat",
					value: props.lat,
					validator: Yup.number(),
				},
				{
					name: "lng",
					value: props.lng,
					validator: Yup.number(),
				},
				{
					name: "zones",
					value: props.zones,
					validator: Yup.array(Yup.object()),
				},
			]}
			onSubmit={(values, { setSubmitting }) => {
				setSubmitting(true);

				if (values.mode === "add-zone") {
					api.createZone(props, values, setSubmitting);
				} else if (values.mode === "edit-zone") {
					api.updateZone(props, values, setSubmitting);
				} else if (values.mode === "delete-zone") {
					api.deleteZone(props, values, setSubmitting);
				}
			}}
			steps={wizard.steps}
			initialStep={wizard.initialStep}
			wizardProps={props}
		/>
	);
}
