import { StepText, StepTitle } from "../WizardLayout";
import Button from "../../layout/Button";
import DatePicker from "../../layout/DatePicker";
import EditableInputField from "../../layout/EditableInputField";
import React from "react";
import WizardNavigation from "../WizardNavigation";
import { format } from "../../../helpers";

export const addPlate = ({
	values,
	errors,
	setFieldValue,
	handleSubmit,
	isSubmitting,
	wizardProps,
}) => ({
	id: "add",
	label: "Add plate",
	render: () => (
		<div>
			<StepTitle>Manually add plate to site</StepTitle>
			<EditableInputField
				type="text"
				name="plate"
				useLabel="Plate"
				maxLength="10"
				value={values.Numberplate || ""}
				error={errors.Numberplate}
				onChange={(event) =>
					setFieldValue("Numberplate", format.plate(event.target.value))
				}
			/>
			<EditableInputField
				type="text"
				name="description"
				useLabel="Description"
				maxLength="255"
				value={values.Description || ""}
				error={errors.Description}
				onChange={(event) => setFieldValue("Description", event.target.value)}
			/>
			<h3>Expiry:</h3>
			<DatePicker
				style={{ width: "220px" }}
				options={{
					pickerMode: "datePicker",
					showClearButton: true,
					minDate: new Date(),
					enableTime: true,
					altFormat: "d M Y h:iK",
					minuteIncrement: 1,
				}}
				clearLeft={"240px"}
				value={values.ExpiresAt}
				error={errors.ExpiresAt}
				onChange={([date]) => setFieldValue("ExpiresAt", date)}
				whenCleared={() => setFieldValue("ExpiresAt", null)}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={isSubmitting || Object.values(errors).length}
				>
					Add
				</Button>,
			]}
		/>
	),
});

export const delPlate = ({
	values,
	errors,
	handleSubmit,
	isSubmitting,
	wizardProps,
}) => ({
	id: "delete",
	label: "Remove plate",
	render: () => (
		<div>
			<StepText>
				Are you sure you want to remove <b>{values.Numberplate}</b>?
			</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={isSubmitting || Object.values(errors).length}
				>
					Remove
				</Button>,
			]}
		/>
	),
});

export const editPlate = ({
	values,
	errors,
	setFieldValue,
	handleSubmit,
	isSubmitting,
	wizardProps,
}) => ({
	id: "edit",
	label: "Update plate",
	render: () => (
		<div>
			<StepTitle>Update plate manually added to site</StepTitle>
			<EditableInputField
				type="text"
				name="plate"
				useLabel="Plate"
				value={values.Numberplate}
				error={errors.Numberplate}
				onChange={(event) =>
					setFieldValue("Numberplate", format.plate(event.target.value))
				}
			/>
			<EditableInputField
				type="text"
				name="description"
				useLabel="Description"
				maxLength="255"
				value={values.Description || ""}
				error={errors.Description}
				onChange={(event) => setFieldValue("Description", event.target.value)}
			/>
			<h3>Expiry:</h3>
			<DatePicker
				style={{ width: "220px" }}
				options={{
					pickerMode: "datePicker",
					showClearButton: true,
					minDate: new Date(),
					enableTime: true,
					altFormat: "d M Y h:iK",
					minuteIncrement: 1,
				}}
				clearLeft={"240px"}
				value={values.ExpiresAt}
				error={errors.ExpiresAt}
				onChange={([date]) => setFieldValue("ExpiresAt", date)}
				whenCleared={() => setFieldValue("ExpiresAt", null)}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={isSubmitting || Object.values(errors).length}
				>
					Update
				</Button>,
			]}
		/>
	),
});
