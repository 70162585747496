import moment from "moment";
import React from "react";
import Card from "../../layout/Card";
import TableLayout from "../../layout/TableLayout";
import DetailsPageWrapper from "../DetailsPageWrapper";
import { useGetSeriesById } from "../../../containers/organization/queries/eventsApiHooks";
import LoadingPlaceholder from "../../report/LoadingPlaceholder";
import styled from "styled-components";
import { formatEvents } from "../../wizards/events-wizard/EventWizard.helpers";
import { defaultTimeFormat } from "../../../helpers/constants";

const defaultPageSize = 50;

export const ColorCircle = styled.div`
	display: inline-block; /* Make ColorCircle inline */
	width: 20px;
	height: 20px;
	border-radius: 50%;
	margin-right: 20px;
`;

export const ColorValue = styled.div`
	display: inline-block; /* Make ColorValue inline */
	margin-left: 10px;
	margin-right: 20px;
	vertical-align: top; /* Align with ColorCircle if needed */
`;

function EventSeriesInformation({ eventSeriesData }) {
	const eventSeriesColumns = [
		{
			id: "SeriesName",
			Header: "Event Series Name",
			Cell: (cellProps) => {
				const { EventSeries } = cellProps.original;
				return <>{EventSeries?.Name}</>;
			},
		},
		{
			id: "ResetTime",
			Header: "Reset Time",
			Cell: (cellProps) => {
				const { EventSeries } = cellProps.original;
				return <>{EventSeries?.ResetTime} Minutes after event</>;
			},
		},
		{
			id: "Image",
			Header: "Logo",
			Cell: (cellProps) => {
				const {
					Brand: { IconLogo },
				} = cellProps.original.EventSeries;
				return (
					<img
						src={IconLogo}
						alt="Brand Logo"
						style={{ width: "50px", height: "50px", objectFit: "contain" }}
					/>
				);
			},
		},
		{
			id: "PrimaryBrandColour",
			Header: "Primary Color",
			Cell: (cellProps) => {
				const {
					Brand: { MainColor },
				} = cellProps.original.EventSeries;
				return (
					<>
						<ColorCircle style={{ backgroundColor: MainColor }} />
						<ColorValue>{MainColor}</ColorValue>
					</>
				);
			},
		},
		{
			id: "SecondaryBrandColour",
			Header: "Secondary Color",
			Cell: (cellProps) => {
				const {
					Brand: { SecondaryColor },
				} = cellProps.original.EventSeries;
				return (
					<>
						<ColorCircle style={{ backgroundColor: SecondaryColor }} />
						<ColorValue>{SecondaryColor}</ColorValue>
					</>
				);
			},
		},
	];
	return (
		<Card>
			<h2>Event Series Information</h2>
			<TableLayout
				data={eventSeriesData}
				columns={eventSeriesColumns}
				defaultPageSize={defaultPageSize}
			/>
		</Card>
	);
}

function GroupsInformation({ groupsData }) {
	const groupsColumns = [
		{
			id: "Group",
			Header: "Group",
			accessor: "Name",
		},
		{
			id: "Spaces",
			Header: "Sites and spaces",
			Cell: (cellProps) => {
				// Check if Sites is null or undefined before mapping
				const Sites = cellProps.original?.Sites ?? [];

				return (
					<>
						{Sites.map((site) => {
							const parksDisplay = site?.LeaseParks?.map(
								(park) => park.Name
							).join(", ");

							return (
								<div key={site.Name} style={{ marginBottom: "10px" }}>
									<strong>Site: </strong>
									{site.Name}
									<br />
									<strong>Spaces: </strong>
									{parksDisplay}
								</div>
							);
						})}
					</>
				);
			},
		},
	];
	return (
		<Card>
			<h2>Groups Information</h2>
			<TableLayout
				data={groupsData}
				columns={groupsColumns}
				defaultPageSize={defaultPageSize}
			/>
		</Card>
	);
}

function SitesInformation({ sitesData }) {
	const sitesColumns = [
		{
			id: "SiteName",
			Header: "Site Name",
			accessor: "Name",
		},
		{
			id: "Spaces",
			Header: "Sites and spaces",
			Cell: (cellProps) => {
				// Check if Sites is null or undefined before mapping
				const LeaseParks = cellProps.original?.LeaseParks ?? [];
				const parksDisplay = LeaseParks?.map((park) => park.Name).join(", ");

				return (
					<>
						<div key="LeaseParks" style={{ marginBottom: "10px" }}>
							<strong>Spaces: </strong>
							{parksDisplay}
						</div>
					</>
				);
			},
		},
	];

	return (
		<Card>
			<h2>Sites Information</h2>
			<TableLayout
				data={sitesData}
				columns={sitesColumns}
				defaultPageSize={defaultPageSize}
			/>
		</Card>
	);
}

function EventInformation({ eventData, siteTimezone }) {
	const formattedEvents = formatEvents({
		Events: eventData,
		Timezone: siteTimezone,
	});
	const eventsColumns = [
		{
			id: "Event",
			Header: "Event",
			accessor: "name",
		},
		{
			id: "StartTimestamp",
			Header: "Start Date",
			accessor: (row) => moment(row.startTime).format(defaultTimeFormat),
		},
		{
			id: "EndTimestamp",
			Header: "End Date",
			accessor: (row) => moment(row.endTime).format(defaultTimeFormat),
		},
	];
	return (
		<Card>
			<h2>Events</h2>
			<TableLayout
				data={formattedEvents}
				columns={eventsColumns}
				defaultPageSize={defaultPageSize}
			/>
		</Card>
	);
}

function EventSeriesDetails(props) {
	const { seriesData, isLoading, isError } = useGetSeriesById(props.seriesId);

	if (isLoading) {
		return <LoadingPlaceholder noCardWrapper={true} />;
	}

	if (isError) {
		return <div>Error...</div>;
	}

	return (
		<DetailsPageWrapper close={props.close} title={"Event series details"}>
			<EventSeriesInformation
				eventSeriesData={[
					{
						EventSeries: {
							Name: seriesData.Name,
							ResetTime: seriesData.ResetTime,
							Brand: seriesData.Brand,
						},
					},
				]}
			/>
			<GroupsInformation groupsData={seriesData.Groups} />
			<SitesInformation sitesData={seriesData.Sites} />
			<EventInformation
				eventData={seriesData.Events}
				siteTimezone={seriesData.Sites[0]?.Timezone}
			/>
		</DetailsPageWrapper>
	);
}

export default EventSeriesDetails;
