import * as Yup from "yup";
import * as hoursSteps from "./hours-steps";
import React from "react";
import WizardLayout from "../WizardLayout";
import moment from "moment";

const constructWizard = (props) => {
	if (props.mode === "remove") {
		return {
			initialStep: 0,
			steps: [hoursSteps.removeStep],
		};
	} else if (props.mode === "addCustom") {
		return {
			initialStep: 0,
			steps: [hoursSteps.addCustomStep],
		};
	}
};

export default function HoursWizard(props) {
	const wizard = constructWizard(props);

	return (
		<WizardLayout
			close={props.close}
			title={(values) => {
				if (values.mode === "remove") return "Remove custom date";
				if (values.mode === "addCustom") return "Add custom date";
			}}
			values={[
				{
					name: "siteId",
					value: props.siteId,
					validator: Yup.number().integer(),
				},
				{ name: "mode", value: props.mode, validator: Yup.string() },
				{
					name: "customDate",
					value: props.customDate,
					validator: Yup.string().nullable(),
				},
				{ name: "dateValue", value: props.dateValue, validator: Yup.string() },
				{
					name: "minDate",
					value: moment().subtract(2, "days").startOf("day").toDate(),
					validator: Yup.string(),
				},
				{
					name: "isDisabled",
					value:
						props.disabledDate && props.disabledDate.length
							? props.disabledDate.map((date) =>
									moment(date, "YYYYMMDD").toDate()
							  )
							: [],
				},
			]}
			onSubmit={async (values, { setSubmitting }) => {
				if (values.mode === "remove") {
					props.removeCustom(true);
					props.close(true);
					setSubmitting(false);
				} else if (values.mode === "addCustom") {
					props.newCustom(moment(values.dateValue).format("YYYYMMDD"));
					props.close(true);
					setSubmitting(false);
				}
			}}
			steps={wizard.steps}
			initialStep={wizard.initialStep}
			wizardProps={props}
		/>
	);
}
