import { useMutateData, useQueryData } from "../../../hooks";
import Alert from "react-s-alert";
import LoadingPlaceholder from "../../../components/report/LoadingPlaceholder";
import MapContainer from "./MapContainer";
import React from "react";
import gql from "graphql-tag";

const SiteMap = (props) => {
	let blueprints = {};
	let bays = [];
	let floors = [];
	let serializations = {};
	let spaces = [];

	const saveFloorPlans = useMutateData(gql`
		mutation ($floors: [FloorPlanInput]) {
			saveFloorPlans(floors: $floors)
		}
	`);

	const deleteFloorPlan = useMutateData(gql`
		mutation ($floorPlanId: Int!) {
			deleteFloorPlan(floorPlanId: $floorPlanId)
		}
	`);

	const createFloorLevel = useMutateData(gql`
		mutation ($siteId: Int!, $name: String!) {
			createFloorLevel(siteId: $siteId, name: $name)
		}
	`);

	const updateFloorName = useMutateData(gql`
		mutation ($floorPlanId: Int!, $name: String!) {
			updateFloorName(floorPlanId: $floorPlanId, name: $name)
		}
	`);

	const uploadBlueprint = useMutateData(gql`
		mutation (
			$file: Upload!
			$serialization: String!
			$siteId: Int!
			$floorPlanId: Int!
		) {
			uploadBlueprint(
				file: $file
				serialization: $serialization
				siteId: $siteId
				floorPlanId: $floorPlanId
			)
		}
	`);

	const deleteBlueprint = useMutateData(gql`
		mutation ($floorPlanId: Int!) {
			deleteBlueprint(floorPlanId: $floorPlanId)
		}
	`);

	const {
		data: { getLeaseParksForSiteMap: leaseParks },
		parksIsLoading,
		refetch: refetchParks,
	} = useQueryData(
		gql`
			query ($siteId: Int!) {
				getLeaseParksForSiteMap(siteId: $siteId) {
					LeaseParkID
					Name
					Bays {
						BayID
						Name
						LeaseParkID
						AttachedToFloorPlan
					}
				}
			}
		`,
		{
			siteId: props.selectedSite.SiteID,
		},
		!props.selectedSite,
		{ fetchPolicy: "no-cache" }
	);

	const {
		data: { getFloorPlansForSite: floorPlans },
		floorPlansIsLoading,
		refetch: refetchFloors,
	} = useQueryData(
		gql`
			query ($siteId: Int!) {
				getFloorPlansForSite(siteId: $siteId) {
					FloorPlanID
					Name
					Serialization
					FloorPlanUrl
				}
			}
		`,
		{
			siteId: props.selectedSite.SiteID,
		},
		!props.selectedSite,
		{ fetchPolicy: "no-cache" }
	);

	const saveSiteMap = async (newSerialization, floorsOptions) => {
		// TODO: should move checking if updating/creating to server side
		const getFloorPlanID = (name) => {
			const id = floorsOptions.find(({ label }) => label === name);
			return id ? id.value : undefined;
		};

		const data = Object.keys(newSerialization)
			.map((name) => ({
				FloorPlanID: getFloorPlanID(name),
				Name: name,
				Serialization:
					newSerialization[name] && JSON.stringify(newSerialization[name]),
				SiteID: props.selectedSite.SiteID,
			}))
			.filter((s) => s.Serialization);

		try {
			if (data && data.length) {
				await saveFloorPlans({
					variables: { floors: data },
				});
			}
			Alert.success("Saved site map.");
		} catch (error) {
			Alert.error("Something went wrong. Please try again.");
		}
		refetchParks();
		refetchFloors();
	};

	const deleteLevel = async (floorPlanId) => {
		try {
			await deleteFloorPlan({
				variables: { floorPlanId },
			});
			Alert.success("Deleted level.");
		} catch (error) {
			Alert.error("Something went wrong. Please try again.");
		}
		refetchParks();
		refetchFloors();
	};

	const createLevel = async (levelName) => {
		try {
			await createFloorLevel({
				variables: { siteId: props.selectedSite.SiteID, name: levelName },
			});
			Alert.success("Created level.");
		} catch (error) {
			Alert.error("Something went wrong. Please try again.");
		}
		refetchParks();
		refetchFloors();
	};

	const updateLevel = async (floorPlanId, name) => {
		try {
			await updateFloorName({
				variables: { floorPlanId, name },
			});
			Alert.success("Updated level name.");
		} catch (error) {
			Alert.error("Something went wrong. Please try again.");
		}
		refetchParks();
		refetchFloors();
	};

	const uploadPlan = async (image, floorPlanId, serialization) => {
		try {
			await uploadBlueprint({
				variables: {
					file: image,
					serialization: JSON.stringify(serialization),
					floorPlanId,
					siteId: props.selectedSite.SiteID,
				},
			});
			Alert.success("Uploaded image.");
		} catch (error) {
			Alert.error("Something went wrong. Please try again.");
		}
		refetchFloors();
	};

	const deletePlan = async (floorPlanId) => {
		try {
			await deleteBlueprint({
				variables: {
					floorPlanId,
				},
			});
			Alert.success("Deleted floor plan.");
		} catch (error) {
			Alert.error("Something went wrong. Please try again.");
		}
		refetchFloors();
	};

	if (leaseParks) {
		spaces = leaseParks.map(({ LeaseParkID, Name }) => ({
			value: LeaseParkID,
			label: Name,
		}));

		bays = [];
		leaseParks.forEach(({ Bays }) => {
			bays = [...bays, ...Bays];
		});
	}

	if (floorPlans) {
		floors = floorPlans.map(({ FloorPlanID, Name }) => ({
			value: FloorPlanID,
			label: Name,
		}));
		serializations = Object.fromEntries(
			floorPlans.map(({ Name, Serialization }) => [
				Name,
				JSON.parse(Serialization),
			])
		);
		blueprints = Object.fromEntries(
			floorPlans.map(({ Name, FloorPlanUrl, Serialization }) => {
				const s = JSON.parse(Serialization);
				return [
					Name,
					s?.blueprint && {
						url: FloorPlanUrl,
						x: s.blueprint.x,
						y: s.blueprint.y,
					},
				];
			})
		);
	}

	if (parksIsLoading || floorPlansIsLoading) {
		return <LoadingPlaceholder />;
	}

	return (
		<MapContainer
			blueprints={blueprints}
			bays={bays}
			exsitingSerials={serializations}
			floors={floors}
			onCreateLevel={createLevel}
			onDeleteLevel={deleteLevel}
			onSaveSiteMap={saveSiteMap}
			onUpdateLevel={updateLevel}
			onUploadBlueprint={uploadPlan}
			onDeleteBlueprint={deletePlan}
			spaces={spaces}
		/>
	);
};

export default SiteMap;
