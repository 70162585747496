import * as Yup from "yup";
import { CLIENTS, CLIENT_UI_CONFIG } from "../../helpers/constants";
import React, { useState } from "react";
import Alert from "react-s-alert";
import Button from "../../components/layout/Button";
import EditableInputField from "../../components/layout/EditableInputField";
import FormLayout from "../../components/layout/FormLayout";
import LocalLink from "../../components/layout/LocalLink";
import gql from "graphql-tag";
import qs from "qs";
import styled from "styled-components";
import { useMutateData } from "../../hooks";

const FormComplete = styled.div`
	display: block;

	margin: 64px 0 32px;
	text-align: center;
`;

function canSubmit(isSubmitting, errors, values, code) {
	return !isSubmitting && !errors.password && !!values.password && !!code;
}

const schema = Yup.object().shape({
	password: Yup.string().min(8).max(100).required("Please supply a password."),
});

export default function ResetPasswordForm(props) {
	const options = qs.parse(props.location.search, { ignoreQueryPrefix: true });
	const clientCode = props.client || CLIENTS.INUGO;
	const [state, setState] = useState({
		formComplete: false,
	});

	const resetPasswordMutation = useMutateData(gql`
		mutation ($passwordResetKey: String!, $password: Password!) {
			resetPassword(passwordResetKey: $passwordResetKey, password: $password)
		}
	`);

	if (!options.code) {
		props.history.push("/login");
	}

	const clientConfig = CLIENT_UI_CONFIG[clientCode];

	return (
		<FormLayout
			initialValues={{
				password: "",
			}}
			validationSchema={schema}
			onSubmit={async (values, { setSubmitting }) => {
				try {
					setSubmitting(true);

					await resetPasswordMutation({
						variables: {
							passwordResetKey: options.code,
							password: values.password,
						},
					});

					Alert.success("Your password was reset.");
					setState((_state) => ({ ..._state, formComplete: true }));
				} catch (error) {
					setSubmitting(false);

					if (error && error.errors[0].code === "ResetExpired") {
						Alert.error("This password reset request has expired.");
					} else {
						Alert.error("Something went wrong. Please try again.");
					}
				}
			}}
			render={({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
			}) => (
				<div>
					<h1>Reset Password</h1>
					{!state.formComplete && (
						<form className="form" onSubmit={handleSubmit}>
							<EditableInputField
								type="password"
								name="password"
								useLabel="Password"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.password}
								error={
									errors.password && touched.password ? errors.password : null
								}
							/>

							<Button
								type="submit"
								color={clientConfig.colours.button}
								disabled={
									!canSubmit(isSubmitting, errors, values, options.code)
								}
							>
								Reset password
							</Button>
						</form>
					)}
					{state.formComplete && (
						<FormComplete>Your password has been changed.</FormComplete>
					)}
					<div
						style={{
							display: "block",
							margin: "64px 0 32px",
							textAlign: "center",
						}}
					>
						{options.admin && <LocalLink to="/login">Go to login</LocalLink>}
					</div>
				</div>
			)}
		/>
	);
}
