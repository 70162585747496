import * as Yup from "yup";
import * as installationApi from "../../api/installation";
import { GoogleMap, Marker, withGoogleMap } from "react-google-maps";
import { PlusCircle, XCircle } from "react-feather";
import React, { useContext, useEffect, useState } from "react";
import { cloneDeep, filter, keys, map, pick, some } from "lodash";
import {
	constants,
	errors as errorMessages,
	site as siteConstants,
	user as userHelpers,
} from "../../helpers";
import {
	useFetchData,
	useHasClientAdmin,
	useMutateData,
	usePermissions,
	useQueryData,
} from "../../hooks";
import Accordion from "../../components/layout/Accordion";
import Alert from "react-s-alert";
import { AppContext } from "../../context/app-context";
import Button from "../../components/layout/Button";
import Card from "../../components/layout/Card";
import Dropdown from "../../components/layout/Dropdown";
import EditableInputField from "../../components/layout/EditableInputField";
import ErrorBoundary from "../ErrorBoundary";
import FormLayout from "../../components/layout/FormLayout";
import GeoCodeFinder from "../../components/settings/GeoCodeFinder";
import ImageImportBox from "../../components/wizards/ImageDropBox";
import InfoBox from "../../components/layout/InfoBox";
import Input from "../../components/layout/Input";
import LoadingPlaceholder from "../../components/report/LoadingPlaceholder";
import ModalDialog from "../../components/layout/ModalDialog";
import MultiTextInput from "../../components/layout/MultiTextInput";
import Slider from "../../components/layout/Slider";
import Toggle from "../../components/layout/Toggle";
import { colours } from "../../styles";
import environment from "../../api/environment";
import gql from "graphql-tag";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import FixedFooterBox from "../../components/layout/FixedFooterBox";

const googleKey = environment.config.googleKey;

const editableFields = [
	"Name",
	"Description",
	"EnquiryEmail",
	"EnquiryPhone",
	"Address",
	"Country",
	"Latitude",
	"Longitude",
	"StreetViewRotation",
	"Timezone",
	"Currency",
	"SiteType",
	"HasPublicAccess",
	"HasCasualParking",
	"HasLeaseParking",
	"HasValidation",
	"HasLPRPayAfterExit",
	"HasLPRPayOnExit",
	"HasLPRPayBeforeExit",
	"HasLPRPayAutomatically",
	"ValidationDiscountStacking",
	"HasSessionlessAccess",
	"TnCs",
	"ParentSite",
	"ChildSiteIDs",
	"MaximumCapacity",
	"VirtualMachines",
	"HasITSIntegration",
	"HasT2Integration",
	"IsDeactivated",
	"ExternalID",
	"InfoID",
	"AutoExpiry",
	"HasANPR",
	"Has2FA",
	"PermitRadius",
	"PermitExpiry",
	"UseCustomImages",
	"Images",
	"HasEntryNotifications",
	"EntryMessage",
	"HasBayEntryNotifications",
	"SiteConfigurationID",
	"LocationCode",
	"OutstandingBalanceExpiry",
	"EnforcementReference",
	"OutstandingBalanceExpiryMinutes",
	"SendSessionExpiryNotificationMinutes",
	"AutomaticallyCloseSessionAfterMinutes",
	"TaxRateID",
	"TaxType",
	"TaxRate",
	"SiteCategory",
];

const FieldWrapper = styled.div`
	margin: 16px 32px;
`;

const FlexWrapper = styled.div`
	display: flex;
	position: relative;

	> * {
		flex: 1;
		margin: 16px 32px;
	}
`;

const FieldContent = styled.div`
	> * {
		margin: 0;
		max-width: 600px;
		min-height: 32px;
	}
`;

const Label = styled.div`
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 4px;
`;

const Spacer = styled.div`
	height: 56px;
`;

const ImgWrapper = styled.img`
	object-fit: cover;
	width: 100%;
`;

const settingsSchema = Yup.object().shape({
	ExternalID: Yup.string()
		.max(255, "External Reference should be no more than 255 characters long.")
		.nullable(),
	Name: Yup.string()
		.max(100, "The site name should be no more than 100 characters long.")
		.required("Please supply a name for the site."),
	Address: Yup.string().required("Please supply an address for the site."),
	Latitude: Yup.string().required("Please supply a latitude for the site."),
	Longitude: Yup.string().required("Please supply a longitude for the site."),
	EnquiryEmail: Yup.string()
		.email("Please supply a valid enquiry email.")
		.required("Please supply an enquiry email."),
	EnquiryPhone: Yup.string()
		.max(20, "Please supply a valid phone number.")
		.required("Please supply an enquiry phone."),
	Description: Yup.string(),
	MaximumCapacity: Yup.number()
		.min(1)
		.required(
			"Please supply the number greater than 0 for the amount of spaces at the site."
		),
	AutoExpiry: Yup.number()
		.integer()
		.min(1, "Please supply a number greater than 0")
		.max(1440, "Please supply a number less than or equal to 1440")
		.nullable(),
	UseCustomImages: Yup.boolean(),
	Images: Yup.array()
		.nullable()
		.when("UseCustomImages", {
			is: true,
			then: Yup.array().required("Please supply an entry message."),
		}),
	SecureParkingExternalID: Yup.string().nullable(),
	LocationCode: Yup.string()
		.matches(
			/^\d+$/,
			"Location codes can only contain numbers, please supply a valid location code."
		) // only digits
		.max(
			6,
			"Location codes can have a maximum character limit of 6, please supply a valid location code."
		)
		.nullable(),
});

export default function SiteSettingsContainer(props) {
	const { t: translate } = useTranslation();

	const {
		state: { contextLoading },
		dispatch: { getAvailableSites },
	} = useContext(AppContext);

	const [state, setState] = useState({
		reloadToggle: false,
		deleteConfirmationShow: false,
		deletingSite: false,
		OutstandingBalanceExpiryMinutes: false,
	});

	const siteId = props.selectedSite.SiteID;
	const organizationId = props.selectedOrganization.OrganizationID;
	const isAdmin = usePermissions("IsAdmin");
	const hasClientAdmin = useHasClientAdmin(props.selectedOrganization.ClientID);
	const hasEditSettings = usePermissions(null, "EditSettings");
	const canEditSettings = hasClientAdmin || hasEditSettings;
	let hasCorrectEnforcement = true;

	const {
		data: { getSiteById: siteData },
		isLoading,
	} = useQueryData(
		gql`
			query ($siteId: Int!) {
				getSiteById(siteId: $siteId) {
					SiteID
					OrganizationID
					Name
					Latitude
					Longitude
					Description
					MaximumCapacity
					Timezone
					SiteType
					HasPublicAccess
					Currency
					Address
					HasSessionlessAccess
					GracePeriodSeconds
					EnquiryEmail
					EnquiryPhone
					TnCs
					TaxRateCountryCode
					IsUnaffiliated
					StreetViewRotation
					IsOperational
					HasCasualParking
					HasLeaseParking
					ValidationDiscountStacking
					HasITSIntegration
					HasANPR
					Country
					HasT2Integration
					FeeCapType
					FeeCapAmount
					FeeCapRateIDs
					HasValidation
					SiteConfigurationID
					Configuration {
						HasLPRPayAfterExit
						HasLPRPayOnExit
						HasLPRPayAutomatically
						HasLPRPayBeforeExit
						LocationCode
						OutstandingBalanceExpiryMinutes
						SendSessionExpiryNotificationMinutes
						AutomaticallyCloseSessionAfterMinutes
						TaxRate {
							TaxRateID
							Name
							TaxPercentage
						}
						EnforcementReference
						SiteCategory
					}
					BillingAccountID
					InstallationID
					IsDeactivated
					ExternalID
					InfoID
					AutoExpiry
					Has2FA
					PermitRadius
					PermitExpiry
					UseCustomImages
					Images
					HasEntryNotifications
					EntryMessage
					HasBayEntryNotifications
					SecureParkingExternalID
					ParentSite {
						SiteID
						Name
					}
					Children {
						SiteID
						Name
					}
					VirtualMachines {
						VirtualMachineID
						ItsID
						T2ID
						ZoneID
						SiteID
						T2RegionName
					}
					Zones {
						ZoneID
						Name
					}
					IsWebPayEnabled
					Logo
					WebPayPrimaryColour
				}
			}
		`,
		{
			siteId: siteId,
		}
	);

	useEffect(() => {
		setState((_state) => ({
			..._state,
			OutstandingBalanceExpiryMinutes:
				!!siteData?.Configuration?.OutstandingBalanceExpiryMinutes,
			SendSessionExpiryNotificationMinutes:
				!!siteData?.Configuration?.SendSessionExpiryNotificationMinutes,
			AutomaticallyCloseSessionAfterMinutes:
				!!siteData?.Configuration?.AutomaticallyCloseSessionAfterMinutes,
		}));
	}, [siteData]);

	const addEnforcementReport = useMutateData(gql`
		mutation (
			$siteId: Int!
			$gracePeriod: Int
			$interval: Int
			$notificationEmails: [String!]
			$isEnabled: Boolean!
		) {
			addEnforcementReport(
				siteId: $siteId
				gracePeriod: $gracePeriod
				interval: $interval
				notificationEmails: $notificationEmails
				isEnabled: $isEnabled
			)
		}
	`);

	const {
		data: { getEnforcementReport: enforcementReport },
	} = useQueryData(
		gql`
			query ($siteId: Int!) {
				getEnforcementReport(siteId: $siteId) {
					GracePeriod
					Interval
					NotificationEmails
					ANPRIsEnabled: IsEnabled
				}
			}
		`,
		{
			siteId: siteId,
		}
	);

	const uploadSiteImageMutation = useMutateData(gql`
		mutation ($files: [Upload]!, $siteId: Int!) {
			uploadSiteImages(files: $files, siteId: $siteId)
		}
	`);

	// tbd: get T2 region names
	const T2_REGION_NAMES = [
		{
			value: "Inugo Test Location",
			label: "Test Site",
		},
	];

	const { data: orgAccessGroups } = useFetchData(
		[],
		installationApi.getOrganizationAccessGroups,
		[organizationId],
		[organizationId, state.reloadToggle]
	);

	const { data: siteAccessGroups } = useFetchData(
		[],
		installationApi.getSiteAccessGroups,
		[organizationId, siteId],
		[organizationId, siteId, state.reloadToggle]
	);

	const { data: childSites } = useFetchData(
		[],
		installationApi.getChildrenSites,
		[siteId],
		[siteId, state.reloadToggle]
	);

	const {
		data: { getClientInfos },
	} = useQueryData(
		gql`
			query {
				getClientInfos {
					InfoID
					Name
					CountryCode
				}
			}
		`,
		{},
		!isAdmin
	);

	if (
		contextLoading ||
		!orgAccessGroups ||
		!siteAccessGroups ||
		!childSites ||
		!enforcementReport ||
		isLoading
	) {
		return <LoadingPlaceholder />;
	}

	let toggles = [];

	if (isAdmin || hasClientAdmin) {
		toggles = [
			[
				{
					label: "Public",
					value: "HasPublicAccess",
				},
				{
					label: "Transient Parking",
					value: "HasCasualParking",
				},
				{
					label: "Bookings",
					value: "HasLeaseParking",
				},
				{},
				{
					label: "ANPR Pay After Exit",
					value: "HasLPRPayAfterExit",
				},
				{
					label: "ANPR Pay On Exit",
					value: "HasLPRPayOnExit",
				},
				{
					label: "ANPR Requires Payment to End Sessions",
					value: "HasLPRPayBeforeExit",
				},
				siteData.SiteType === constants.SITE_TYPES.GATELESS && {
					label: "ANPR Pay Automatically",
					value: "HasLPRPayAutomatically",
				},
				{},
				{
					label: "Sessionless Access",
					value: "HasSessionlessAccess",
				},
				{
					label: "Deactivated",
					value: "IsDeactivated",
				},
			],
			[
				{
					label: "Validation",
					value: "HasValidation",
				},
				{
					label: "Validation Discount Stacking",
					value: "ValidationDiscountStacking",
				},
				siteData.SiteType === constants.SITE_TYPES.GATED
					? {
							label: "Allocated Bay Notification",
							value: "HasBayEntryNotifications",
					  }
					: {},
				{},
				{
					label: "Outstanding Balance Expiry",
					value: "OutstandingBalanceExpiryMinutes",
				},
				state.OutstandingBalanceExpiryMinutes
					? {
							label: "Expiry Time (mins)",
							value: "OutstandingBalanceExpiryMinutes",
							type: "Number",
							min: 1,
							max: 4320,
					  }
					: null,
				{
					label: "Session Open Reminder",
					value: "SendSessionExpiryNotificationMinutes",
				},
				state.SendSessionExpiryNotificationMinutes
					? {
							label: "Session Open Reminder Time (mins)",
							value: "SendSessionExpiryNotificationMinutes",
							type: "Number",
							min: 1,
							max: 4320,
					  }
					: null,
				{
					label: "Automatic Close Session",
					value: "AutomaticallyCloseSessionAfterMinutes",
				},
				state.AutomaticallyCloseSessionAfterMinutes
					? {
							label: "Automatic Close Session Time (mins)",
							value: "AutomaticallyCloseSessionAfterMinutes",
							type: "Number",
							min: 1,
							max: 4320,
					  }
					: null,
				{},
				{},
				{
					label: "Auto Expiry",
					value: "hasAutoExpiry",
				},
				{
					label: "ANPR Support",
					value: "HasANPR",
				},
			],
		];
	} else {
		toggles = [
			[
				{
					label: "Public",
					value: "HasPublicAccess",
				},
				{
					label: "Transient Parking",
					value: "HasCasualParking",
				},
				{
					label: "Bookings",
					value: "HasLeaseParking",
				},
			],
		];
	}

	const IntegrationToggles = [
		{
			label: "ITS Integration",
			value: "HasITSIntegration",
		},
		{
			label: "T2 Integration",
			value: "HasT2Integration",
		},
		// {
		// 	label: "Flowbird Integration",
		// 	value: "HasFlowbirdIntegration"
		// }
	];

	const EnforcementToggles = [
		{
			label: "Enforcement Emails",
			value: "ANPRIsEnabled",
		},
	];

	const twoFactorToggle = {
		label: "User Proximity Detection",
		value: "Has2FA",
	};
	const maxRadius = 1000;
	const minRadius = 50;
	const defaultRadius = 200;

	const minPermitExpiry = 15;
	const maxPermitExpiry = 24 * 60;
	const defaultPermitExpiry = 15;

	const defaultTaxRates = props.selectedOrganization.DefaultTaxRates?.map(
		(taxRate) => ({
			Name: taxRate.Name,
			TaxPercentage: taxRate.Percentage,
		})
	);

	const BatchAlertOptions = [
		{ value: 5, label: "5 Minutes" },
		{ value: 10, label: "10 Minutes" },
		{ value: 15, label: "15 Minutes" },
		{ value: 30, label: "30 Minutes" },
		{ value: 45, label: "45 Minutes" },
		{ value: 60, label: "60 Minutes" },
		{ value: 120, label: "120 Minutes" },
	];

	let infos = getClientInfos
		? getClientInfos.map((info) => ({
				value: info.InfoID,
				label: `${info.Name} ${info.CountryCode}`,
		  }))
		: [];

	const initialValues = cloneDeep({
		...pick(siteData, editableFields),
		orgAccessGroups,
		siteAccessGroups,
		siteAccessGroupIds: siteAccessGroups.map(
			(e) => e.OrganizationAccessGroupID
		),
		childSites,
		childSitesIds: (siteData.Children || []).map((e) => e.SiteID),
		validAddress: true,
		AutoExpiry: Math.round(siteData.AutoExpiry / 60) || null,
		hasAutoExpiry: siteData.AutoExpiry > 0,
		...enforcementReport,
		validEmail: true,
		missingEmail: false,
		Logo: siteData.Logo,
		logoFile: null,
		SecureParkingExternalID: siteData.SecureParkingExternalID,
		HasLPRPayAfterExit: siteData.Configuration?.HasLPRPayAfterExit,
		HasLPRPayOnExit: siteData.Configuration?.HasLPRPayOnExit,
		HasLPRPayAutomatically: siteData.Configuration?.HasLPRPayAutomatically,
		HasLPRPayBeforeExit: siteData.Configuration?.HasLPRPayBeforeExit,
		LocationCode: siteData.Configuration?.LocationCode,
		EnforcementReference: siteData.Configuration?.EnforcementReference,
		OutstandingBalanceExpiryMinutes:
			siteData.Configuration?.OutstandingBalanceExpiryMinutes,
		SendSessionExpiryNotificationMinutes:
			siteData.Configuration?.SendSessionExpiryNotificationMinutes,
		AutomaticallyCloseSessionAfterMinutes:
			siteData.Configuration?.AutomaticallyCloseSessionAfterMinutes,
		TaxRateID: siteData.Configuration?.TaxRate?.TaxRateID,
		TaxType: siteData.Configuration?.TaxRate?.Name,
		TaxRate: siteData.Configuration?.TaxRate?.TaxPercentage,
		SiteCategory: siteData.Configuration?.SiteCategory,
	});

	const zoneOptions = map(siteData.Zones, (zone) => ({
		value: zone.ZoneID,
		label: zone.Name,
	}));

	const MapWithMarker = withGoogleMap((_props) => (
		<GoogleMap
			defaultZoom={19}
			defaultTilt={0}
			defaultMapTypeId={"satellite"}
			center={{
				lat: _props.siteLat || null,
				lng: _props.siteLng || null,
			}}
		>
			<Marker
				position={{
					lat: _props.siteLat || null,
					lng: _props.siteLng || null,
				}}
				draggable={true}
				onDragEnd={(event) => onMarkerDragEnd(event, _props.setFieldValue)}
				name={"Site Location"}
			/>
		</GoogleMap>
	));

	function onMarkerDragEnd(event, setFieldValue) {
		setFieldValue("Latitude", parseFloat(event.latLng.lat().toFixed(6)));
		setFieldValue("Longitude", parseFloat(event.latLng.lng().toFixed(6)));
	}

	function restoreSiteGroups(siteAccessGroupIds, _orgAccessGroups) {
		let value = [];
		siteAccessGroupIds.forEach((id) => {
			_orgAccessGroups.forEach((e) => {
				if (e.OrganizationAccessGroupID === id) {
					value.push({ value: id, label: e.Name });
				}
			});
		});

		return value;
	}

	function restoreEnforcementEmails(emails) {
		let value = [];
		emails.forEach((item) => {
			value.push({ value: item, label: item });
		});
		return value;
	}

	function restoreChildSites(childSitesIds, _childSites) {
		let value = [];
		childSitesIds.forEach((id) => {
			_childSites.forEach((site) => {
				if (site.SiteID === id) {
					value.push({ value: site.SiteID, label: site.DisplayName });
				}
			});
		});
		return value;
	}

	function disableToggle(siteType, label) {
		const ungatedDisableLabels = [
			"Sessionless Access",
			"Offline Openings",
			"Auto Lane Detection",
		];

		return siteType === "Gateless" && ungatedDisableLabels.includes(label);
	}

	async function _handleSubmit(values, { setSubmitting, setValues }) {
		setSubmitting(true);

		let data = cloneDeep(values);

		data.VirtualMachines = filter(
			values.VirtualMachines,
			(vm) =>
				vm.ZoneID !== "0" &&
				vm.T2RegionName !== "0" &&
				(vm.T2RegionName || vm.ZoneID)
		);

		data.VirtualMachines.forEach((vm) => {
			if (vm.ItsID) {
				vm.ItsID = vm.ItsID.trim();
			}
		});

		if (!data.hasAutoExpiry || data.AutoExpiry <= 0) {
			data.AutoExpiry = null;
		} else {
			data.AutoExpiry = Math.round(data.AutoExpiry * 60);
		}

		if (data.Has2FA) {
			if (!data.PermitRadius) {
				data.PermitRadius = defaultRadius;
			}
			if (!data.PermitExpiry) {
				data.PermitExpiry = defaultPermitExpiry;
			}
		}

		try {
			delete data.ParentSite;
			data.ChildSiteIDs = data.childSitesIds;

			if (
				data.Images &&
				data.Images.length &&
				typeof data.Images[0] !== "string"
			) {
				await uploadSiteImageMutation({
					variables: { files: data.Images, siteId },
				});
			}
			delete data.Images;

			await installationApi.updateSite(siteId, pick(data, editableFields));

			await installationApi.setOrganizationAccessGroupsForSite(
				organizationId,
				siteId,
				data.siteAccessGroupIds
			);
			if (data.HasANPR && data.NotificationEmails.length) {
				let variables = {
					variables: {
						siteId: siteId,
						gracePeriod: parseInt(data.GracePeriod),
						interval: parseInt(data.Interval),
						notificationEmails: data.NotificationEmails,
						isEnabled: data.ANPRIsEnabled,
					},
				};
				await addEnforcementReport(variables);
			}

			//make sure the values are updated after we have submitted
			setState((_state) => ({
				..._state,
				reloadToggle: !state.reloadToggle,
			}));

			Alert.success("Site settings updated");
		} catch (error) {
			if (error && error.errors) {
				if (some(error.errors, (e) => e.code === "GroupHasAssociatedRate")) {
					Alert.error(
						"This Group has associated rate(s). Please delete the rate(s) first."
					);

					setValues({
						...values,
						siteAccessGroupIds: initialValues.siteAccessGroupIds,
					});
				} else if (
					some(
						error.errors,
						(e) => e.code === "SessionlessAccessNotAllowedOnUngatedSite"
					)
				) {
					Alert.error("Sessionless Access can only be set on Gated sites.");
				} else if (
					some(error.errors, (e) => e.code === "InvalidSharedInstallation")
				) {
					Alert.error(errorMessages.INVALID_SHARED_INSTALLATION_VIA_SITE);
				} else if (
					some(
						error.errors,
						(e) => e.code === "InvalidSharedInstallationViaGroup"
					)
				) {
					Alert.error(
						"Cannot add group to site. This group contains users that have access to another site on the shared installation."
					);
				} else if (
					some(error.errors, (e) => e.code === "LocationCodeAlreadyInUse")
				) {
					Alert.error(
						"Location code already in use. Please use a location code that does not already exist."
					);
				} else {
					Alert.error(translate("Errors.Generic"));
				}
			} else {
				Alert.error(translate("Errors.Generic"));
			}
		}

		getAvailableSites();

		setSubmitting(false);
	}

	function handleAddressChange(suggestion, setFieldValue) {
		setFieldValue("Address", suggestion.Name);
		setFieldValue("Country", suggestion.Country);
		setFieldValue("Latitude", parseFloat(suggestion.Latitude.toFixed(6)));
		setFieldValue("Longitude", parseFloat(suggestion.Longitude.toFixed(6)));
	}

	async function deleteSite(_siteId) {
		try {
			setState((_state) => ({ ..._state, deletingSite: true }));
			await installationApi.deleteSiteById(_siteId);
			Alert.success("Site deleted.");

			setTimeout(() => {
				window.location.reload();
			}, 1000);
		} catch (error) {
			setState((_state) => ({ ..._state, deletingSite: false }));
			Alert.error(translate("Errors.Generic"));
		}
	}

	function confirmationText() {
		return (
			<div>
				Are you sure you want to DELETE this site
				<div style={{ fontWeight: "bold" }}>{siteData.Name}?</div>
				This action CANNOT be undone.
			</div>
		);
	}

	return (
		<ErrorBoundary>
			<ModalDialog
				isOpen={state.deleteConfirmationShow}
				close={() => {
					setState((_state) => ({ ..._state, deleteConfirmationShow: false }));
				}}
				dialogText={confirmationText()}
				leftButtonText="Cancel"
				leftButtonColor="blue"
				leftButtonDisabled={state.deletingSite}
				onLeftButtonClick={() => {
					setState((_state) => ({ ..._state, deleteConfirmationShow: false }));
				}}
				rightButtonText="Delete"
				rightButtonColor="red"
				rightButtonDisabled={state.deletingSite}
				onRightButtonClick={() => deleteSite(siteData.SiteID)}
			/>
			<FormLayout
				enableReinitialize={true}
				initialValues={initialValues}
				validationSchema={settingsSchema}
				onSubmit={_handleSubmit}
				render={({
					values,
					errors,
					touched,
					handleBlur,
					handleChange,
					handleSubmit,
					isSubmitting,
					setFieldValue,
				}) => (
					<form
						className="form"
						onSubmit={(event) => {
							event.preventDefault();
						}}
					>
						{
							(hasCorrectEnforcement =
								!values.ANPRIsEnabled ||
								(values.ANPRIsEnabled &&
									values.validEmail &&
									values.NotificationEmails.length !== 0))
						}
						<Card>
							<Accordion title="General Info" expanded={true}>
								{(isAdmin || hasClientAdmin) && (
									<FieldWrapper>
										<Label>Site ID</Label>
										<FieldContent>
											<EditableInputField
												name="SiteID"
												value={siteId}
												disabled={true}
											/>
										</FieldContent>
									</FieldWrapper>
								)}

								{values.SecureParkingExternalID && (
									<FieldWrapper>
										<Label>Voyager Carpark External ID</Label>

										<div>{values.SecureParkingExternalID}</div>
									</FieldWrapper>
								)}

								<FieldWrapper>
									<Label>External Reference</Label>
									<FieldContent>
										<EditableInputField
											name="ExternalID"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.ExternalID || ""}
											error={
												errors.ExternalID && touched.ExternalID
													? errors.ExternalID
													: null
											}
											disabled={!canEditSettings}
										/>
									</FieldContent>
								</FieldWrapper>

								<FieldWrapper>
									<Label>Name</Label>
									<FieldContent>
										<EditableInputField
											name="Name"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.Name || ""}
											error={errors.Name && touched.Name ? errors.Name : null}
											disabled={!canEditSettings}
										/>
									</FieldContent>
								</FieldWrapper>

								<FieldWrapper>
									<Label>Description</Label>
									<FieldContent>
										<EditableInputField
											name="Description"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.Description || ""}
											error={
												errors.Description && touched.Description
													? errors.Description
													: null
											}
											disabled={!canEditSettings}
										/>
									</FieldContent>
								</FieldWrapper>

								<FieldWrapper>
									<Label>Enquiry Email</Label>
									<FieldContent>
										<EditableInputField
											type="email"
											name="EnquiryEmail"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.EnquiryEmail || ""}
											error={
												errors.EnquiryEmail && touched.EnquiryEmail
													? errors.EnquiryEmail
													: null
											}
											disabled={!canEditSettings}
										/>
									</FieldContent>
								</FieldWrapper>

								<FieldWrapper>
									<Label>Enquiry Phone</Label>
									<FieldContent>
										<EditableInputField
											name="EnquiryPhone"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.EnquiryPhone}
											error={
												errors.EnquiryPhone && touched.EnquiryPhone
													? errors.EnquiryPhone
													: null
											}
											disabled={!canEditSettings}
										/>
									</FieldContent>
								</FieldWrapper>

								<FieldWrapper>
									<Label>Location Code</Label>
									<FieldContent>
										<EditableInputField
											name="LocationCode"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.LocationCode}
											error={
												errors.LocationCode && touched.LocationCode
													? errors.LocationCode
													: null
											}
											disabled={!canEditSettings}
										/>
									</FieldContent>
								</FieldWrapper>

								<FieldWrapper>
									<Label>Enforcement ID</Label>
									<FieldContent>
										<EditableInputField
											name="EnforcementReference"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.EnforcementReference}
											error={
												errors.EnforcementReference &&
												touched.EnforcementReference
													? errors.EnforcementReference
													: null
											}
											disabled={!canEditSettings}
										/>
									</FieldContent>
								</FieldWrapper>

								<FieldWrapper>
									<Label>Site Configuration</Label>
									<FieldContent>
										<Dropdown
											options={siteConstants.SITE_CATEGORIES}
											value={siteConstants.SITE_CATEGORIES.find(
												(e) => e.value === values.SiteCategory
											)}
											onChange={(value) =>
												handleChange({
													target: {
														name: "SiteCategory",
														value: value.value,
													},
												})
											}
											isDisabled={!canEditSettings}
										/>
									</FieldContent>
								</FieldWrapper>
							</Accordion>
						</Card>

						<Card>
							<Accordion title="Location">
								<FieldWrapper>
									<Label>Address</Label>
									<FieldContent>
										<GeoCodeFinder
											address={values.Address}
											onChange={(event) => {
												setFieldValue("validAddress", !event.isDirty);
												if (event.suggestion)
													handleAddressChange(event.suggestion, setFieldValue);
											}}
											disabled={!canEditSettings}
										/>
									</FieldContent>
								</FieldWrapper>

								<FieldWrapper>
									<Label>Latitude</Label>
									<FieldContent>
										<EditableInputField
											type="number"
											name="Latitude"
											value={values.Latitude}
											step={0.000001}
											min={-90}
											max={90}
											onChange={handleChange}
											onBlur={handleBlur}
											error={
												errors.Latitude && touched.Latitude
													? errors.Latitude
													: null
											}
											disabled={!canEditSettings}
										/>
									</FieldContent>
								</FieldWrapper>

								<FieldWrapper>
									<Label>Longitude</Label>
									<FieldContent>
										<EditableInputField
											type="number"
											name="Longitude"
											value={values.Longitude}
											step={0.000001}
											min={-180}
											max={180}
											onChange={handleChange}
											onBlur={handleBlur}
											error={
												errors.Longitude && touched.Longitude
													? errors.Longitude
													: null
											}
											disabled={!canEditSettings}
										/>
									</FieldContent>
								</FieldWrapper>

								<FieldWrapper>
									<Label>Timezone</Label>
									<FieldContent>
										<Dropdown
											options={siteConstants.TIME_ZONES}
											value={siteConstants.TIME_ZONES.find(
												(e) => e.value === values.Timezone
											)}
											onChange={(value) =>
												handleChange({
													target: { name: "Timezone", value: value.value },
												})
											}
											isDisabled={!canEditSettings}
										/>
									</FieldContent>
								</FieldWrapper>

								<FieldWrapper>
									<Label>Street View Rotation</Label>
									<FieldContent>
										<Slider
											min={0}
											max={359}
											onChange={(value) =>
												handleChange({
													target: { name: "StreetViewRotation", value },
												})
											}
											value={values.StreetViewRotation}
											disabled={!canEditSettings}
										/>
									</FieldContent>
								</FieldWrapper>

								<FlexWrapper style={{ margin: "0 16px" }}>
									<div style={{ flex: "1 1 0", width: "100%" }}>
										<MapWithMarker
											siteLat={values.Latitude}
											siteLng={values.Longitude}
											googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleKey}`}
											setFieldValue={setFieldValue}
											loadingElement={<div style={{ height: "100%" }} />}
											containerElement={
												<div
													style={{
														height: "100%",
														width: "100%",
													}}
												/>
											}
											mapElement={<div style={{ height: "100%" }} />}
										/>
									</div>
									<div style={{ flex: "1 1 0", width: "100%" }}>
										<ImgWrapper
											src={`https://maps.googleapis.com/maps/api/streetview?size=520x300&location=${values.Latitude},${values.Longitude}&heading=${values.StreetViewRotation}&key=${googleKey}`}
										/>
									</div>
								</FlexWrapper>

								<FieldWrapper>
									<Label>Currency</Label>
									<FieldContent>
										<Dropdown
											options={siteConstants.CURRENCIES}
											value={siteConstants.CURRENCIES.find(
												(e) => e.value === values.Currency
											)}
											onChange={(value) =>
												handleChange({
													target: { name: "Currency", value: value.value },
												})
											}
											isDisabled={!canEditSettings}
										/>
									</FieldContent>
								</FieldWrapper>

								<FlexWrapper>
									<FieldWrapper>
										<Label>Tax Type</Label>
										<FieldContent>
											<Dropdown
												options={constants.TAX_TYPES}
												value={constants.TAX_TYPES.find(
													(e) => e.value === values.TaxType
												)}
												onChange={(value) => {
													handleChange({
														target: { name: "TaxType", value: value.value },
													});

													const defaultTaxRate =
														defaultTaxRates?.find((e) => e.Name === value.value)
															?.TaxPercentage || 0;

													if (defaultTaxRate) {
														setFieldValue("TaxRate", defaultTaxRate);
													}

													if (value.value === "Zero Rated") {
														setFieldValue("TaxRate", 0);
													}
												}}
												isDisabled={!canEditSettings}
											/>
										</FieldContent>
									</FieldWrapper>
									<FieldWrapper>
										<Label>Tax Percentage</Label>
										<FieldContent>
											<EditableInputField
												type="number"
												name="TaxRate"
												onBlur={handleBlur}
												value={values.TaxRate || 0}
												error={
													errors.TaxRate && touched.TaxRate
														? errors.TaxRate
														: null
												}
												disabled={
													!canEditSettings || values.TaxType === "Zero Rated"
												}
												onChange={(event) => {
													let val = event.target.value;

													if (val > 100) {
														val = 100;
													} else if (val < 0) {
														val = 0;
													}

													setFieldValue("TaxRate", val);
												}}
											/>
										</FieldContent>
									</FieldWrapper>
								</FlexWrapper>
							</Accordion>
						</Card>

						<Card>
							<Accordion title="Terms and Conditions">
								<FieldWrapper>
									<Label>Terms and Conditions</Label>
									<FieldContent>
										<Input
											textArea={true}
											name="TnCs"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.TnCs || ""}
											error={errors.TnCs && touched.TnCs ? errors.TnCs : null}
											disabled={!canEditSettings}
										/>
									</FieldContent>
								</FieldWrapper>
							</Accordion>
						</Card>

						<Card>
							<Accordion title="Site Configuration">
								<FieldWrapper>
									<Label>Total Parking Spaces</Label>
									<FieldContent>
										<EditableInputField
											type="number"
											name="MaximumCapacity"
											min={1}
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.MaximumCapacity || 0}
											error={
												errors.MaximumCapacity && touched.MaximumCapacity
													? errors.MaximumCapacity
													: null
											}
											disabled={!canEditSettings}
										/>
									</FieldContent>
								</FieldWrapper>

								<FieldWrapper>
									<Label>Site Type</Label>
									<FieldContent>
										<Dropdown
											options={siteConstants.SITE_TYPES}
											value={siteConstants.SITE_TYPES.find(
												(e) => e.value === values.SiteType
											)}
											onChange={(value) =>
												handleChange({
													target: { name: "SiteType", value: value.value },
												})
											}
											isDisabled={!(isAdmin || hasClientAdmin)}
										/>
									</FieldContent>
								</FieldWrapper>

								<FlexWrapper>
									{toggles.map((column, key) => (
										<div key={key}>
											{column.map((setting, index) => {
												if (!setting) {
													return;
												}

												if (!setting.value) {
													return <Spacer key={index} />;
												}

												if (setting.type === "Number") {
													return (
														<FieldWrapper key={index}>
															<Label>{setting.label}</Label>
															<FieldContent>
																<EditableInputField
																	type="number"
																	name={setting.value}
																	min={setting.min}
																	max={setting.max}
																	onChange={(event) => {
																		let val = event.target.value;
																		if (setting.min || setting.max) {
																			val = parseInt(val);

																			if (isNaN(val)) {
																				val = 0;
																			}

																			if (setting.max && val > setting.max) {
																				val = setting.max;
																			}

																			if (setting.min && val < setting.min) {
																				val = setting.min;
																			}
																		}

																		setFieldValue(setting.value, val);
																	}}
																	onBlur={handleBlur}
																	value={values[setting.value] || 0}
																	error={
																		errors[setting.value] &&
																		touched[setting.value]
																			? errors[setting.value]
																			: null
																	}
																	disabled={
																		!canEditSettings ||
																		disableToggle(
																			values.SiteType,
																			setting.label
																		)
																	}
																/>
															</FieldContent>
														</FieldWrapper>
													);
												}

												return (
													<Toggle
														key={index}
														style={{
															margin: "16px 16px",
															padding: "8px 0",
														}}
														label={setting.label}
														floatRight={true}
														onChange={(value) => {
															setFieldValue(setting.value, value ? 1 : 0);

															if (Object.keys(state).includes(setting.value)) {
																setState({
																	...state,
																	[`${setting.value}`]: value,
																});
															}
														}}
														checked={!!values[setting.value]}
														disabled={
															!canEditSettings ||
															disableToggle(values.SiteType, setting.label)
														}
													/>
												);
											})}
										</div>
									))}
								</FlexWrapper>

								{!!values.hasAutoExpiry && (
									<FieldWrapper>
										<Label>Auto Expiry Time (mins)</Label>
										<FieldContent>
											<EditableInputField
												type="number"
												name="AutoExpiry"
												min={1}
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.AutoExpiry || null}
												error={
													errors.AutoExpiry && touched.AutoExpiry
														? errors.AutoExpiry
														: null
												}
												disabled={!canEditSettings}
											/>
										</FieldContent>
									</FieldWrapper>
								)}

								<FieldWrapper>
									<Label>Private Access Groups</Label>
									<FieldContent>
										<Dropdown
											isMulti={true}
											options={values.orgAccessGroups.map((e) => ({
												value: e.OrganizationAccessGroupID,
												label: e.Name,
											}))}
											value={restoreSiteGroups(
												values.siteAccessGroupIds,
												values.orgAccessGroups
											)}
											onChange={(value) =>
												handleChange({
													target: {
														name: "siteAccessGroupIds",
														value: value ? value.map((e) => e.value) : [],
													},
												})
											}
											isDisabled={!canEditSettings}
										/>
									</FieldContent>
								</FieldWrapper>

								{isAdmin && (
									<FieldWrapper>
										<Label>Email Template</Label>
										<FieldContent>
											<Dropdown
												isMulti={false}
												options={infos}
												value={infos.find((e) => e.value === values.InfoID)}
												onChange={(value) =>
													handleChange({
														target: {
															name: "InfoID",
															value: value.value,
														},
													})
												}
												isDisabled={!canEditSettings}
											/>
										</FieldContent>
									</FieldWrapper>
								)}
							</Accordion>
						</Card>

						<Card hidden={!isAdmin || siteData.SiteType !== "Gated"}>
							<Accordion title="Site Tree">
								<FieldWrapper>
									<Label>Parent Site</Label>
									<FieldContent>
										<EditableInputField
											name="ParentSite"
											onChange={handleChange}
											onBlur={handleBlur}
											value={
												values.ParentSite ? values.ParentSite.Name : "(None)"
											}
											disabled={true}
										/>
									</FieldContent>
								</FieldWrapper>

								<FieldWrapper>
									<Label>Child Sites</Label>
									<FieldContent>
										<Dropdown
											isMulti={true}
											options={values.childSites.map((e) => ({
												value: e.SiteID,
												label: e.DisplayName,
											}))}
											value={restoreChildSites(
												values.childSitesIds,
												values.childSites
											)}
											onChange={(value) =>
												handleChange({
													target: {
														name: "childSitesIds",
														value: value ? value.map((e) => e.value) : [],
													},
												})
											}
											isDisabled={!canEditSettings}
										/>
									</FieldContent>
								</FieldWrapper>
							</Accordion>
						</Card>

						<Card hidden={!isAdmin}>
							<Accordion title="Integration">
								<div
									style={{
										margin: "0 32px",
										display: "flex",
										justifyContent: "flex-start",
									}}
								>
									{IntegrationToggles.map((column, key) => (
										<div
											style={{
												display: "flex",
												justifyContent: "flex-start",
												marginRight: "128px",
											}}
											key={key}
										>
											<Toggle
												key={`tog-${key}`}
												label={column.label}
												onChange={(value) =>
													setFieldValue(column.value, value ? 1 : 0)
												}
												checked={!!values[column.value]}
												disabled={
													!canEditSettings ||
													disableToggle(values.SiteType, column.label)
												}
											/>
											<PlusCircle
												style={{ cursor: "pointer", margin: "16px 0 0 96px" }}
												key={`pc-${key}`}
												color={
													values[column.value]
														? `${colours.blue}`
														: `${colours.midGrey}`
												}
												onClick={() => {
													if (values[column.value]) {
														if (column.value === "HasITSIntegration") {
															let vm = cloneDeep(values.VirtualMachines);
															vm.push({
																ZoneID: "0", // temporary id to show setting
																ItsID: null,
																SiteID: siteId,
															});

															setFieldValue("VirtualMachines", vm);
														}
														if (column.value === "HasT2Integration") {
															let vm = cloneDeep(values.VirtualMachines);
															vm.push({
																T2RegionName: "0", // temporary id to show setting
																SiteID: siteId,
															});

															setFieldValue("VirtualMachines", vm);
														}
													}
												}}
											/>
										</div>
									))}
								</div>
								{values.VirtualMachines.map((virtualMachine, key) => (
									<div key={key} style={{ margin: "16px 0 36px 32px" }}>
										{virtualMachine.T2RegionName &&
											!virtualMachine.IsDeleted && (
												<div style={{ width: "40%" }}>
													<Label>T2 Region Name</Label>
													<div style={{ display: "flex" }}>
														<div style={{ flex: 1 }}>
															<Dropdown
																key={`zone-${key}`}
																options={T2_REGION_NAMES}
																value={
																	T2_REGION_NAMES.find(
																		(vm) =>
																			vm.value ===
																			values.VirtualMachines[key].T2RegionName
																	) || ""
																}
																onChange={(value) => {
																	let vm = cloneDeep(values.VirtualMachines);
																	vm[key].T2RegionName = value.value;
																	setFieldValue("VirtualMachines", vm);
																}}
															/>
														</div>
														<div style={{ flex: 1 }}>
															<XCircle
																onClick={() => {
																	let vm = cloneDeep(values.VirtualMachines);
																	vm[key].IsDeleted = 1;
																	setFieldValue("VirtualMachines", vm);
																}}
																style={{
																	cursor: "pointer",
																	margin: "8px 0 0 16px",
																}}
																color={`${colours.red}`}
															/>
														</div>
													</div>
												</div>
											)}
										{virtualMachine.ZoneID && !virtualMachine.IsDeleted && (
											<div
												key={`its-${key}`}
												style={{
													display: "flex",
													width: "70%",
													marginTop: "16px",
													marginBottom: "-40px",
												}}
											>
												<div style={{ flex: 1, marginRight: "12px" }}>
													<Label>Zone</Label>
													<Dropdown
														options={zoneOptions}
														value={
															zoneOptions.find(
																(zone) =>
																	zone.value ===
																	values.VirtualMachines[key].ZoneID
															) || ""
														}
														onChange={(value) => {
															let vm = cloneDeep(values.VirtualMachines);
															vm[key].ZoneID = value.value;
															setFieldValue("VirtualMachines", vm);
														}}
													/>
												</div>
												<div style={{ flex: 1 }}>
													<Label>ITS ID</Label>
													<EditableInputField
														value={values.VirtualMachines[key].ItsID || ""}
														onChange={(event) => {
															let vm = cloneDeep(values.VirtualMachines);
															vm[key].ItsID = event.target.value;
															setFieldValue("VirtualMachines", vm);
														}}
													/>
												</div>
												<div style={{ flex: 1 }}>
													<XCircle
														onClick={() => {
															let vm = cloneDeep(values.VirtualMachines);
															vm[key].IsDeleted = 1;
															setFieldValue("VirtualMachines", vm);
														}}
														style={{
															cursor: "pointer",
															margin: "36px 0 0 16px",
														}}
														color={`${colours.red}`}
													/>
												</div>
											</div>
										)}
									</div>
								))}
							</Accordion>
						</Card>

						<Card hidden={!isAdmin || !values.HasANPR}>
							<Accordion title="ANPR">
								<div>
									<div
										style={{
											display: "flex",
											flex: "1",
											flexDirection: "column",
										}}
									>
										{EnforcementToggles.map((column, index) => (
											<Toggle
												key={index}
												style={{
													margin: "16px 16px",
													padding: "8px 0",
												}}
												toggleFontSize={"22px"}
												label={column.label}
												onChange={(value) => setFieldValue(column.value, value)}
												checked={!!values[column.value]}
												disabled={
													!canEditSettings ||
													disableToggle(values.SiteType, column.label)
												}
											/>
										))}
										<FieldWrapper>
											<Label>Report Recipients</Label>
											<FieldContent>
												<MultiTextInput
													isMulti={true}
													value={restoreEnforcementEmails(
														values.NotificationEmails
													)}
													isClearable={true}
													onChange={(value) => {
														if (value) {
															for (let i = 0; i < value.length; i++) {
																values.validEmail = userHelpers.emailValidator(
																	String(value[i].value).toLowerCase()
																);
																if (!values.validEmail) break;
															}
														}
														handleChange({
															target: {
																name: "NotificationEmails",
																value: value ? value.map((e) => e.value) : [],
															},
														});
													}}
													isDisabled={!canEditSettings || !values.ANPRIsEnabled}
												/>

												{!values.validEmail && (
													<div
														style={{
															fontSize: "12px",
															color: "rgb(244, 67, 54)",
														}}
													>
														Please supply valid email addresses.
													</div>
												)}
												{!!values.ANPRIsEnabled &&
													values.NotificationEmails.length === 0 &&
													values.HasANPR && (
														<div
															style={{
																fontSize: "12px",
																color: "rgb(244, 67, 54)",
															}}
														>
															Please supply an email address for alerts.
														</div>
													)}
											</FieldContent>
										</FieldWrapper>
										<FieldWrapper>
											<Label>Grace Period</Label>
											<FieldContent style={{ width: "180px" }}>
												<EditableInputField
													type="number"
													name="GracePeriod"
													min={0}
													max={120}
													disabled={!canEditSettings || !values.ANPRIsEnabled}
													useLabel=" "
													onChange={(event) => {
														let val = event.target.value;
														if (val > 120) {
															val = 120;
														} else if (val < 0) {
															val = 0;
														}
														setFieldValue("GracePeriod", val);
													}}
													value={
														values.GracePeriod == null ? "" : values.GracePeriod
													}
													descriptor={"Minutes"}
													style={{ textAlign: "center" }}
												/>
											</FieldContent>
										</FieldWrapper>
										<FieldWrapper>
											<Label>Batch Alerts</Label>
											<FieldContent style={{ width: "180px" }}>
												<Dropdown
													isMulti={false}
													options={BatchAlertOptions}
													value={
														BatchAlertOptions.find(
															(e) => e.value === values.Interval
														) || ""
													}
													onChange={(value) =>
														handleChange({
															target: {
																name: "Interval",
																value: value.value,
															},
														})
													}
													isDisabled={!canEditSettings || !values.ANPRIsEnabled}
												/>
											</FieldContent>
										</FieldWrapper>
									</div>
									<div style={{ marginTop: "50px" }}>
										<Toggle
											style={{
												margin: "16px 16px",
												padding: "8px 0",
											}}
											toggleFontSize={"22px"}
											label={twoFactorToggle.label}
											onChange={(value) =>
												setFieldValue(twoFactorToggle.value, value ? 1 : 0)
											}
											checked={!!values[twoFactorToggle.value]}
											disabled={
												!canEditSettings ||
												disableToggle(values.SiteType, twoFactorToggle.label)
											}
										/>
										{!!values[twoFactorToggle.value] && (
											<InfoBox
												style={{
													marginBottom: 16,
													marginLeft: 32,
												}}
												text={
													"This feature requires users to have allowed 'background location use' permission and have location services on"
												}
											/>
										)}
										<FieldWrapper>
											<FieldContent
												style={{
													width: "180px",
													marginBottom: "16px",
													marginTop: "32px",
												}}
											>
												<Label>Distance From Site</Label>
												<EditableInputField
													type="number"
													name="PermitRadius"
													min={minRadius}
													max={maxRadius}
													disabled={!canEditSettings || !values.Has2FA}
													useLabel=" "
													onChange={(event) => {
														setFieldValue("PermitRadius", event.target.value);
													}}
													onBlur={() => {
														setFieldValue(
															"PermitRadius",
															Math.max(
																minRadius,
																Math.min(values.PermitRadius, maxRadius)
															)
														);
													}}
													value={
														!values.PermitRadius
															? defaultRadius
															: values.PermitRadius
													}
													descriptor={"Meters"}
													style={{ textAlign: "center" }}
												/>
											</FieldContent>
										</FieldWrapper>
										<FieldWrapper>
											<FieldContent
												style={{
													width: "180px",
													marginBottom: "16px",
													marginTop: "0px",
												}}
											>
												<Label>Entry Permit Expiry</Label>
												<EditableInputField
													type="number"
													name="PermitExpiry"
													min={minPermitExpiry}
													max={maxPermitExpiry}
													disabled={!canEditSettings || !values.Has2FA}
													useLabel=" "
													onChange={(event) => {
														setFieldValue("PermitExpiry", event.target.value);
													}}
													onBlur={() => {
														setFieldValue(
															"PermitExpiry",
															Math.max(
																minPermitExpiry,
																Math.min(values.PermitExpiry, maxPermitExpiry)
															)
														);
													}}
													value={
														!values.PermitExpiry
															? defaultPermitExpiry
															: values.PermitExpiry
													}
													descriptor={"Minutes"}
													style={{ textAlign: "center" }}
												/>
											</FieldContent>
										</FieldWrapper>
									</div>
									<div style={{ marginTop: "50px" }}>
										<Toggle
											style={{
												margin: "16px 16px",
												padding: "8px 0",
											}}
											toggleFontSize={"22px"}
											label={"Entry Notifications"}
											onChange={(value) =>
												setFieldValue("HasEntryNotifications", value ? 1 : 0)
											}
											checked={!!values["HasEntryNotifications"]}
											disabled={!canEditSettings}
										/>
										{values.HasEntryNotifications ? (
											<>
												<FieldWrapper>
													<Label>Entry Message</Label>
													<div
														style={{
															display: "flex",
															justifyContent: "start",
															alignItems: "center",
															gap: 40,
														}}
													>
														<FieldContent style={{ width: 600 }}>
															<EditableInputField
																type="text"
																name="EntryMessage"
																onChange={handleChange}
																onBlur={handleBlur}
																value={values.EntryMessage || ""}
																error={errors.EntryMessage}
																disabled={!canEditSettings}
															/>
														</FieldContent>
													</div>
													<InfoBox
														style={{
															marginTop: 16,
														}}
														text={`If you leave this field blank this default message will show instead: "You have entered ${values.Name} via automatic gate openings"`}
													/>
												</FieldWrapper>
											</>
										) : null}
									</div>
								</div>
							</Accordion>
						</Card>

						<Card>
							<Accordion title="Site Image">
								<FieldWrapper>
									<Toggle
										label={"Use Custom Site Image"}
										onChange={(value) => {
											setFieldValue("UseCustomImages", value);
										}}
										checked={values.UseCustomImages}
									/>
								</FieldWrapper>
								{values.UseCustomImages && (
									<ImageImportBox
										onChange={(newImages) => {
											setFieldValue("Images", newImages);
										}}
										title={
											values.Images?.length
												? "Current Site Image"
												: "Import Site Image"
										}
										url={values.Images?.[0]}
										maxSize={500 * 1000}
										aspect={16 / 9}
									/>
								)}
								{errors.Images && (
									<div
										style={{
											fontSize: "12px",
											color: "rgb(244, 67, 54)",
											textAlign: "center",
										}}
									>
										Please supply a site image.
									</div>
								)}
							</Accordion>
						</Card>

						<div style={{ height: "50px" }}></div>

						<FixedFooterBox>
							{isAdmin && (
								<Button
									color="red"
									onClick={() => {
										setState((_state) => ({
											..._state,
											deleteConfirmationShow: true,
										}));
									}}
								>
									Delete Site
								</Button>
							)}
							{canEditSettings && hasCorrectEnforcement && (
								<Button
									color="green"
									onClick={handleSubmit}
									disabled={isSubmitting || keys(errors).length > 0}
								>
									Update Site Settings
								</Button>
							)}
						</FixedFooterBox>
					</form>
				)}
			/>
		</ErrorBoundary>
	);
}
