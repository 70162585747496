import * as webPayApi from "../../../api/web-pay";
import React, { useState } from "react";
import { fetchDataCallback, paginatedState } from "../../../helpers/pagination";
import DropdownMenuBeta from "../../../components/layout/DropdownMenuBeta";
import { MoreHorizontal } from "react-feather";
import ReportWrapperBeta from "../ReportWrapperBeta";
import gql from "graphql-tag";
import moment from "moment";
import { useQueryData } from "../../../hooks";
import Alert from "react-s-alert";
import { defaultTimeFormat, QR_CODE_TYPES } from "../../../helpers/constants";

async function downloadZoneQR(data) {
	const { error } = await webPayApi.downloadZoneQrPdfUrl({
		siteId: data.siteId,
		webPayType: data.webPayType,
		codeOnly: data.codeOnly,
		format: data.format,
		security: {
			color: data.security?.color || false,
			logo: data.security?.logo || false,
		},
		uniqueCode: data.uniqueCode,
		redownload: true,
	});

	if (error) {
		Alert.error(`Could not download QR Code, ${error.message}`);
	}
}

const defaultSorting = [
	{
		id: "CreatedOn",
		desc: false,
	},
];

export default function SignageQRCodes(props) {
	const [state, setState] = useState({
		initialLoad: true,
		options: {
			"start-date-time": null,
			"end-date-time": null,
		},
		search: "",
		wizardOpen: false,
	});

	const [paginate, setPaginate] = useState(paginatedState);
	const fetchPaginatedData = fetchDataCallback(setPaginate);

	async function updateOptions({ search, ...options }) {
		setState((_state) => ({ ..._state, initialLoad: false, search, options }));
	}

	const columns = [
		{
			id: "UniqueCode",
			Header: "Unique Code",
			accessor: (d) => d.UniqueCode,
		},
		{
			id: "CreatedOn",
			Header: "Created On",
			accessor: (d) =>
				d.CreatedOn ? moment(d.CreatedOn, "X").format(defaultTimeFormat) : "",
		},
		{
			id: "Type",
			Header: "Type",
			accessor: (d) => d.Type,
		},
		{
			id: "security",
			Header: "Security",
			accessor: (d) => d?.Security ?? {},
			Cell: (cellProps) => {
				const security = cellProps.row.original.Security;
				return (
					<span>
						{security
							? Object.keys(security)
									.filter((value) => value !== "__typename")
									.join(", ")
							: ""}
					</span>
				);
			},
		},
		{
			id: "SiteName",
			Header: "Site",
			accessor: (d) => d.Sites,
			Cell: (cellProps) => {
				return (
					<span>
						{cellProps.row.original.Sites.map((site) => site.Name).join(", ")}
					</span>
				);
			},
		},
		{
			id: "tasks",
			Header: "",
			accessor: null,
			Cell: (cellProps) => {
				const data = cellProps.row.original;
				return (
					<DropdownMenuBeta
						triggerContent={<MoreHorizontal size={24} />}
						items={[
							<div
								key="svg-download"
								onClick={() => {
									downloadZoneQR({
										siteId: data.Site.SiteID,
										webPayType: data.WebPayType,
										codeOnly: data.Type === QR_CODE_TYPES.LOCATION,
										security: data.Security,
										uniqueCode: data.UniqueCode,
										format: "svg",
									});
								}}
							>
								Re-download as SVG
							</div>,
							<div
								key="pdf-download"
								onClick={() =>
									downloadZoneQR({
										siteId: data.Site.SiteID,
										webPayType: data.WebPayType,
										codeOnly: data.Type === QR_CODE_TYPES.LOCATION,
										security: data.Security,
										uniqueCode: data.UniqueCode,
										format: "pdf",
									})
								}
							>
								Re-download as PDF
							</div>,
						]}
					/>
				);
			},
			resizable: false,
			width: 50,
		},
	];

	const skipQuery =
		!paginate.pageSize ||
		!paginate.sortBy ||
		!paginate.sortOrder ||
		!state.options["start-date-time"] ||
		!state.options["end-date-time"] ||
		!state.options.sites;

	const {
		data: { getQRCodesForOrganization },
		isLoading,
	} = useQueryData(
		gql`
			query (
				$siteIds: [Int!]
				$startTimestamp: Timestamp!
				$endTimestamp: Timestamp!
				$page: PageInfo!
				$cursor: Int
			) {
				getQRCodesForOrganization(
					siteIds: $siteIds
					startTimestamp: $startTimestamp
					endTimestamp: $endTimestamp
					page: $page
					cursor: $cursor
				) {
					previousEvent
					qrCodes {
						UniqueCode
						Type
						WebPayType
						CreatedOn
						Security {
							color
							logo
						}
						Sites {
							SiteID
							Name
						}
					}
				}
			}
		`,
		{
			siteIds: state.options.sites || [],
			startTimestamp: moment(state.options["start-date-time"])
				.startOf("day")
				.unix(),
			endTimestamp: moment(state.options["end-date-time"]).endOf("day").unix(),
			page: {
				pageOffset: paginate.pageOffset,
				pageSize: paginate.pageSize,
				sortBy: paginate.sortBy,
				sortOrder: paginate.sortOrder,
				searchTokens: paginate.searchTokens,
				eventType: paginate.eventType,
				sortedVal: paginate.cursor.sortedVal,
			},
			cursor: paginate.cursor.cursor,
		},
		skipQuery
	);

	const qrCodes = getQRCodesForOrganization?.qrCodes || [];

	return (
		<ReportWrapperBeta
			{...props}
			title="Unique QR Codes"
			data={qrCodes}
			columns={columns}
			defaultSortBy={defaultSorting}
			updateOptions={updateOptions}
			loading={state.submitting}
			cursorColumn="CreatedOn"
			paginationIsLoading={isLoading || skipQuery || props.isLoading}
			pageSize={state.pageSize}
			dataTrueLength={qrCodes.length}
			previousEvent={getQRCodesForOrganization?.previousEvent}
			searchTokens={paginate.searchTokens}
			fetchPaginatedData={fetchPaginatedData}
		/>
	);
}
