import React from "react";
import { colours } from "../../styles";
import styled from "styled-components";

const Wrapper = styled.div`
	display: flex;
	flex: 1;
	position: relative;

	input,
	textarea {
		background-color: inherit;
		border: 0;
		border-bottom: 2px solid ${colours.midGrey};
		border-radius: 0;
		color: ${colours.darkGrey};
		font-size: 18px;
		height: 32px;
		outline: 0;
		position: relative;
		transition: 0.2s ease;
		width: 100%;

		&:focus {
			border-color: ${colours.lighterBlue};
			box-shadow: none;
			outline: 0;
		}

		&::placeholder {
			color: ${colours.midGrey};
		}

		&:focus + label,
		&:not([value=""]) + label {
			top: -16px;
			transform: scale(0.75);
		}

		&:disabled {
			background-color: ${colours.offWhite};
		}
	}

	textarea {
		background: ${colours.offWhite};
		height: 300px;
		resize: none;
	}

	label {
		top: 6px;
		color: ${colours.midGrey};
		font-size: 18px;
		font-weight: 400;
		margin: 0;
		pointer-events: none;
		position: absolute;
		transform: scale(1);
		transform-origin: left top;
		transition: 0.2s ease;
	}

	input.rightUnit {
		width: 80%;
	}

	span {
		margin-top: 10px;
		margin-left: 20px;
	}
`;

export default function Input(props) {
	const { textArea, useLabel, rightUnit, ...rest } = props;

	let inputClass = rest.color;

	if (rightUnit) {
		inputClass += " rightUnit";
	}

	return (
		<Wrapper>
			{textArea ? (
				<textarea maxLength="8000" {...rest} className={rest.color} />
			) : (
				<input {...rest} className={inputClass} />
			)}
			{useLabel && <label>{useLabel}</label>}
			{rightUnit && <span>{rightUnit}</span>}
		</Wrapper>
	);
}
