import * as Yup from "yup";
import * as installationApi from "../../../api/installation";
import WizardLayout, { StepTitle } from "../WizardLayout";
import Alert from "react-s-alert";
import Button from "../../layout/Button";
import CodeSnippet from "../../layout/CodeSnippet";
import Dropdown from "../../layout/Dropdown";
import Input from "../../layout/Input";
import React from "react";
import Toggle from "../../layout/Toggle";
import WizardNavigation from "../WizardNavigation";
import { errors as errorMessages } from "../../../helpers";
import { some } from "lodash";
import styled from "styled-components";

const Label = styled.div`
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 16px;
	margin-top: 16px;
`;

const Spacer = styled.div`
	height: 16px;
`;

export const importStep = ({
	handleSubmit,
	isSubmitting,
	setFieldValue,
	values,
	wizardProps,
}) => ({
	id: "import",
	label: "Import",
	render: () => {
		const groupOptions = wizardProps.groups.map((e) => ({
			value: e.OrganizationAccessGroupID,
			label: e.Name,
		}));

		return (
			<div>
				<StepTitle>Upload a file and select the group(s):</StepTitle>

				<Label>File to import (.csv)</Label>
				<Input
					style={{ width: "50%" }}
					type="file"
					name="file"
					accept="text/csv"
					onChange={(event) => setFieldValue("file", event.target.files[0])}
				/>
				<Spacer />

				<div>* The CSV file should be in either of the following formats:</div>
				<CodeSnippet>
					example@mail.com,
					<br />
					example2@mail.com
				</CodeSnippet>
				<CodeSnippet>example@mail.com,example2@mail.com</CodeSnippet>

				<Toggle
					style={{ flex: "0 auto" }}
					label={"Notify users by email"}
					onChange={(value) => setFieldValue("notifyUsersByEmail", value)}
					checked={values.notifyUsersByEmail}
				/>

				<Spacer />
				<Label>Groups to add users to</Label>
				<Dropdown
					isMulti={true}
					options={groupOptions}
					value={values.groups}
					onChange={(value) => {
						setFieldValue("groups", value);
					}}
				/>
			</div>
		);
	},
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={isSubmitting || !values.file || !values.groups.length}
				>
					Invite Users
				</Button>,
			]}
		/>
	),
});

export default function ImportUsersWizard(props) {
	return (
		<WizardLayout
			close={props.close}
			title="Import and Invite"
			values={[
				{ name: "file", value: null, validator: Yup.mixed() },
				{
					name: "groups",
					value: [],
					validator: Yup.array(Yup.object()),
				},
				{ name: "notifyUsersByEmail", value: true, validator: Yup.boolean() },
			]}
			onSubmit={async (values, { setSubmitting }) => {
				setSubmitting(true);

				const data = {
					csv: values.file,
					organizationAccessGroupIds: JSON.stringify(
						values.groups.map((e) => {
							return {
								groupId: parseInt(e.value),
							};
						})
					),
					notifyUsersByEmail: values.notifyUsersByEmail,
				};

				try {
					await installationApi.importUsersForOrganization(
						props.selectedOrganization.OrganizationID,
						data
					);

					setSubmitting(false);
					Alert.success(
						"Users importing. If users are not currently shown in the list, please refresh the page later."
					);
					props.close(true);
				} catch (error) {
					if (error && error.errors[0].code === "InvalidInput") {
						Alert.error("Invalid CSV file.");
					} else if (
						error &&
						error.errors &&
						some(error.errors, (e) => e.code === "InvalidSharedInstallation")
					) {
						Alert.error(errorMessages.INVALID_SHARED_INSTALLATION_VIA_GROUP);
					} else {
						Alert.error("Something went wrong. Please try again.");
					}

					setSubmitting(false);
				}
			}}
			steps={[importStep]}
			wizardProps={props}
		/>
	);
}
