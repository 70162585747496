import React, { useState } from "react";
import { useMutateData, useQueryData } from "../../../hooks";
import Alert from "react-s-alert";
import Card from "../../layout/Card";
import FlexWrapper from "../../layout/FlexWrapper";
import LocalLink from "../../layout/LocalLink";
import MultiSelect from "../../layout/MultiSelect";
import ReportWrapperBeta from "../ReportWrapperBeta";
import StatCard from "../../layout/StatCard";
import TransactionDetails from "../../details-pages/users/TransactionDetails";
import gql from "graphql-tag";
import moment from "moment";
import { format } from "../../../helpers";
import { PAGINATION_EVENTS } from "../../../helpers/constants";

const defaultSorting = [
	{
		id: "CreatedDate",
		desc: true,
	},
];

export default function ValidationHistory(props) {
	const [state, setState] = useState({
		options: {
			sites: [],
			"start-date-time": null,
			"end-date-time": null,
			startDateTime: null,
			endDateTime: null,
		},
		type: ["Promotion", "Validation"],
		selectableType: [
			{ label: "Promotion", value: "Promotion" },
			{ label: "Validation", value: "Validation" },
		],
		selectedSiteId: null,
		selectedParkingSessionId: null,
		selectedParkingSessionUser: null,
		reportStyleOverride: {},
		search: "",
		pageEvent: PAGINATION_EVENTS.INIT,
		pageConnection: null,
		pageSize: 100,
		sortColumn: "CreatedDate",
		sortOrder: "desc",
	});

	async function updateOptions({ search, ...options }) {
		setState((_state) => ({
			..._state,
			search,
			options: {
				...options,
				startDateTime: moment(options["start-date-time"])
					.startOf("day")
					.format(),
				endDateTime: moment(options["end-date-time"]).endOf("day").format(),
			},
		}));
	}

	const mapDuration = (Duration, ExpiryDuration) => {
		if (Duration && ExpiryDuration) {
			return `${format.duration(Duration, true, true)} (${format.duration(
				ExpiryDuration,
				true,
				true
			)} limit)`;
		} else if (Duration) {
			return format.duration(Duration, true, true);
		} else {
			return "Unknown";
		}
	};

	const columns = [
		{
			id: "Status",
			Header: "Status",
			accessor: "Status",
		},
		{
			id: "ParkingSessionIDFormatted",
			Header: "Session ID",
			accessor: "ParkingSessionIDFormatted",
			Cell: (cellProps) => (
				<LocalLink
					to={`#${cellProps.value}`}
					onClick={() =>
						showParkingSessionCharges(
							cellProps.row.original.SiteID,
							cellProps.row.original.ParkingSessionID,
							{
								Email: cellProps.row.original.Email,
								Name: cellProps.row.original.FullName,
							}
						)
					}
				>
					{cellProps.row.original.ParkingSessionIDFormatted}
				</LocalLink>
			),
		},
		{
			id: "Numberplate",
			Header: "Numberplate",
			Cell: (cellProps) => {
				return cellProps.row.original.Numberplate || "";
			},
		},
		{
			id: "SiteName",
			Header: "Site",
			accessor: "SiteName",
			highlightCell: (cellProps) => cellProps.row.original.SiteName,
		},
		{
			id: "UserName",
			Header: "User",
			accessor: "UserName",
			hiddenExport: true,
			highlightCell: (cellProps) => cellProps.row.original.UserName,
		},
		{
			id: "FirstName",
			Header: "First Name",
			accessor: "FirstName",
			hiddenWeb: true,
			highlightCell: (cellProps) => cellProps.row.original.FirstName,
		},
		{
			id: "LastName",
			Header: "Last Name",
			accessor: "LastName",
			hiddenWeb: true,
			highlightCell: (cellProps) => cellProps.row.original.LastName,
		},
		{
			id: "Email",
			Header: "Email",
			accessor: "Email",
			hiddenWeb: true,
			highlightCell: (cellProps) => cellProps.row.original.Email,
		},
		{
			id: "Phone",
			Header: "Phone Number",
			accessor: "Phone",
			hiddenWeb: true,
			highlightCell: (cellProps) => cellProps.row.original.Phone,
		},
		{
			id: "Type",
			Header: "Type",
			accessor: "Type",
			highlightCell: (cellProps) => cellProps.row.original.Type,
		},
		{
			id: "DiscountName",
			Header: "Discount Name",
			accessor: "DiscountName",
			highlightCell: (cellProps) => cellProps.row.original.DiscountName,
		},
		{
			id: "PromotionCode",
			Header: "Promotion Code",
			accessor: "PromotionCode",
			highlightCell: (cellProps) => cellProps.row.original.PromotionCode || "",
		},
		{
			id: "DiscountType",
			Header: "Discount Type",
			accessor: "DiscountType",
			highlightCell: (cellProps) => cellProps.row.original.DiscountType || "",
		},
		{
			id: "DiscountAmount",
			Header: "Amount",
			accessor: "DiscountAmount",
			highlightCell: (cellProps) => cellProps.row.original.DiscountAmount,
		},
		{
			id: "CreatedDate",
			Header: "Time",
			accessor: "CreatedOnTimestamp",
			Cell: (cellProps) => {
				return cellProps.row.original.CreatedOnFormatted;
			},
		},
		{
			id: "Duration",
			Header: "Session Duration",
			accessor: "Duration",
			Cell: ({ row }) => {
				return mapDuration(row.original.Duration, row.original.ExpiryDuration);
			},
		},
		{
			id: "FinalBaseFee",
			Header: "Parking Charge",
			accessor: "FinalBaseFee",
			Cell: (cellProps) => {
				return format.money(cellProps.row.original.FinalBaseFee);
			},
		},
		{
			id: "ConsumerServiceFee",
			Header: "Convenience Fee",
			accessor: "ConsumerServiceFee",
			Cell: (cellProps) => {
				return format.money(cellProps.row.original.ConsumerServiceFee);
			},
		},
		{
			id: "ValidationDiscount",
			Header: "Discount Value",
			accessor: "ValidationDiscount",
			Cell: (cellProps) => {
				return format.money(
					cellProps.row.original.ValidationDiscount,
					false,
					true,
					true
				);
			},
		},
		{
			id: "FinalFee",
			Header: "Total User Charge",
			accessor: "FinalFee",
			Cell: (cellProps) => {
				return format.money(cellProps.row.original.FinalFee);
			},
		},
	];

	const bulkDownloadValidations = useMutateData(gql`
		mutation ($args: BulkDownloadReportInput!) {
			bulkDownloadValidationsReport(args: $args)
		}
	`);

	const skipDataQuery =
		!state.options["start-date-time"] || !state.options["end-date-time"];

	const { data, isLoading } = useQueryData(
		gql`
			query (
				$start: Timestamp
				$end: Timestamp
				$sites: [Int!]
				$discountType: [String!]
				$page: Page!
				$searchTokens: String
			) {
				getDiscountsHistory(
					start: $start
					end: $end
					sites: $sites
					discountType: $discountType
					page: $page
					searchTokens: $searchTokens
				) {
					discounts {
						DiscountID
						ParkingSessionID
						ParkingSessionIDFormatted
						SiteName
						SiteID
						SiteTimezone
						Email
						FirstName
						LastName
						Phone
						FullName
						UserName
						Numberplate
						DiscountType
						DiscountAmount
						Type
						IsActive
						DiscountName
						PromotionCode
						CreatedOn
						CreatedOnFormatted
						Duration
						ExpiryDuration
						FinalBaseFee
						ConsumerServiceFee
						ValidationDiscount
						FinalFee
						Status
						RankingScore
						CreatedOnTimestamp
					}
					validationCurrentInteractions
					promotionCurrentInteractions
					totalPromotions
					totalValidations
					connection
					pageIndex
				}
			}
		`,
		{
			start: state.options.startDateTime,
			end: state.options.endDateTime,
			sites: state.options.sites,
			discountType: state.type || [],
			searchTokens: state.search,
			page: {
				connection: state.pageConnection,
				event: state.pageEvent,
				size: state.pageSize,
				order: state.sortOrder,
				orderBy: state.sortColumn,
				goto: state.goto,
			},
		},
		skipDataQuery
	);

	const discountHistory = data?.getDiscountsHistory;

	const totalValidations = discountHistory?.totalValidations || 0;
	const totalPromotions = discountHistory?.totalPromotions || 0;
	const validationCurrentInteractions =
		discountHistory?.validationCurrentInteractions || 0;
	const promotionCurrentInteractions =
		discountHistory?.promotionCurrentInteractions || 0;

	const csvHeaders = () => {
		return columns
			.filter((column) => !column.hiddenExport)
			.map((column) => {
				return {
					label: column.Header,
					key: column.accessor,
				};
			});
	};

	function showParkingSessionCharges(siteId, parkingSessionId, user) {
		setState((_state) => ({
			..._state,
			selectedSiteId: siteId,
			selectedParkingSessionId: parkingSessionId,
			selectedParkingSessionUser: user,
			reportStyleOverride: { display: "none" },
		}));
	}

	function hideParkingSessionCharges() {
		setState((_state) => ({
			..._state,
			selectedSiteId: null,
			selectedParkingSessionId: null,
			selectedParkingSessionUser: null,
			reportStyleOverride: {},
		}));
	}

	return (
		<div>
			<ReportWrapperBeta
				{...props}
				style={state.reportStyleOverride}
				title="History"
				data={discountHistory?.discounts || []}
				columns={columns}
				defaultSortBy={defaultSorting}
				updateOptions={updateOptions}
				csvHeaders={csvHeaders()}
				csvName={"inugo-validation-report.csv"}
				stats={
					<FlexWrapper style={{ marginBottom: -16 }}>
						<Card style={{ flex: 0.25 }}>
							<StatCard
								value={validationCurrentInteractions}
								title="Current Validated Sessions"
								size="medium"
							/>
						</Card>
						<Card style={{ flex: 0.25 }}>
							<StatCard
								value={totalValidations}
								title="Total Validated Sessions"
								size="medium"
							/>
						</Card>
						<Card style={{ flex: 0.25 }}>
							<StatCard
								value={promotionCurrentInteractions}
								title="Current Promotions on Sessions"
								size="medium"
							/>
						</Card>
						<Card style={{ flex: 0.25 }}>
							<StatCard
								value={totalPromotions}
								title="Total Promotions on Sessions"
								size="medium"
							/>
						</Card>
					</FlexWrapper>
				}
				leftActions={
					<div style={{ width: "250px", marginLeft: "16px" }}>
						<MultiSelect
							options={state.selectableType}
							selected={state.type.filter((option) =>
								state.selectableType.map((o) => o.value).includes(option)
							)}
							onSelectedChanged={(values) => {
								setState((_state) => ({ ..._state, type: values }));
							}}
							overrideStrings={{
								selectSomeItems: `Select Types`,
								allItemsAreSelected: `All Types`,
								selectAll: `All Types`,
								search: "Search",
							}}
							valueRenderer={(selected, options) => {
								if (selected.length === 0) {
									return `Select Types`;
								}

								if (selected.length === options.length) {
									return `All Types`;
								}

								if (selected.length === 1) {
									return selected.name;
								}

								return `${selected.length} types selected`;
							}}
						/>
					</div>
				}
				loading={isLoading}
				searchTokens={state.search}
				paginateV2
				pageSize={state.pageSize}
				pageIndex={discountHistory?.pageIndex ?? 0}
				totalRecords={totalValidations + totalPromotions}
				onChange={(event, page) => {
					setState((_state) => ({
						..._state,
						pageEvent: event,
						pageConnection: discountHistory?.connection,
						sortOrder: page.order ?? _state.sortOrder,
						sortColumn: page.column ?? _state.sortColumn,
						pageSize: page.pageSize ?? _state.pageSize,
						goto: page.goto,
						search: page.search ?? _state.search,
					}));
				}}
				onBulkDownloadClicked={async () => {
					try {
						await bulkDownloadValidations({
							variables: {
								args: {
									organizationId: props.selectedOrganization?.OrganizationID,
									siteIds: state.options.sites,
									startDateTime: state.options.startDateTime,
									endDateTime: state.options.endDateTime,
									page: {
										sortBy: state.sortColumn,
										sortOrder: state.sortOrder,
										searchTokens: state.search,
									},
									discountType: state.type || [],
								},
							},
						});

						Alert.success("The report will be sent to your email shortly");
					} catch (error) {
						Alert.error("Something went wrong please try again");
					}
				}}
			/>

			{state.selectedParkingSessionId && (
				<TransactionDetails
					{...props}
					siteId={state.selectedSiteId}
					session={{ ParkingSessionID: state.selectedParkingSessionId }}
					user={state.selectedParkingSessionUser}
					onClose={hideParkingSessionCharges}
				/>
			)}
		</div>
	);
}
