import Card from "../../components/layout/Card";
import Loader from "../../components/layout/Loader";
import React from "react";
import styled from "styled-components";

const Item = styled.div`
	height: 400px;
	padding-top: 160px;
`;

export default function LoadingPlaceholder(props) {
	if (props.noCardWrapper) {
		return (
			<Item>
				<Loader />
			</Item>
		);
	}

	return (
		<Card>
			<Item>
				<Loader />
			</Item>
		</Card>
	);
}
