import { every, find, flatten, map } from "lodash";

// check if the current user has the given permission(s) for the current organization or site
export function userHasPermission(
	{ currentUser, permissions, selectedOrganization, selectedSite },
	adminPermissionNames,
	permissionNames,
	organizationLevel = false,
	clientId
) {
	if (clientId === 0) {
		// a client id of '0' will match any client admin
		return currentUser && currentUser.ClientAdmin;
	} else if (clientId) {
		return (
			currentUser &&
			currentUser.ClientAdmin &&
			currentUser.ClientAdmin.ClientID === clientId
		);
	}

	const adminPermissionChecks = map(
		flatten([adminPermissionNames]),
		(adminPermissionName) =>
			checkPermission(
				{ currentUser, permissions, selectedOrganization, selectedSite },
				adminPermissionName,
				null,
				organizationLevel
			)
	);

	const permissionChecks = map(flatten([permissionNames]), (permissionName) =>
		checkPermission(
			{ currentUser, permissions, selectedOrganization, selectedSite },
			null,
			permissionName,
			organizationLevel
		)
	);

	if (adminPermissionNames) {
		return every(adminPermissionChecks);
	} else if (permissionNames) {
		return every(permissionChecks);
	} else {
		return true;
	}
}

/**
 *
 * @param {options}
 * @param {options.currentUser}
 */
function checkPermission(
	{ currentUser, permissions, selectedOrganization, selectedSite },
	adminPermissionName,
	permissionName,
	organizationLevel
) {
	// check for admin permissions first
	if (currentUser && adminPermissionName) {
		if (adminPermissionName === "IsAdmin") return !!currentUser.IsAdmin;
		if (adminPermissionName === "IsCSAdmin") return !!currentUser.IsCSAdmin;
		if (adminPermissionName === "AdminDashboardEnabled")
			return !!currentUser.AdminDashboardEnabled;
	}

	// general admin check
	if (currentUser && currentUser.IsAdmin) return true;

	// client admin check
	if (
		currentUser &&
		currentUser.ClientAdmin &&
		selectedOrganization &&
		selectedOrganization.ClientID &&
		currentUser.ClientAdmin.ClientID === selectedOrganization.ClientID
	) {
		return true;
	}

	// only check at an organizational level
	if (permissionName && organizationLevel && selectedOrganization) {
		const matchingPermission = find(permissions, (permission) => {
			if (permission.PermissionName === permissionName) {
				if (permission.OrganizationID === selectedOrganization.OrganizationID) {
					return true;
				}
			}
		});

		return !!matchingPermission;
	}

	// check at a site level first then at an organization level
	if (
		permissionName &&
		!organizationLevel &&
		selectedOrganization &&
		selectedSite
	) {
		const matchingPermission = find(permissions, (permission) => {
			if (permission.PermissionName === permissionName) {
				if (
					permission.SiteID === selectedSite.SiteID ||
					(permission.SiteID === 0 &&
						permission.OrganizationID === selectedOrganization.OrganizationID)
				) {
					return true;
				}
			}
		});

		return !!matchingPermission;
	}

	return false;
}
