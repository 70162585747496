import React, { useRef, useState } from "react";
import { boxShadows, colours } from "../../styles";
import styled from "styled-components";

const Wrapper = styled.div`
	margin-top: -12px;
	position: absolute;
	white-space: nowrap;
`;

const Trigger = styled.div`
	&:hover {
		cursor: pointer;
		opacity: 0.8;
	}
`;

const Menu = styled.div`
	background: ${colours.white};
	border: 1px solid ${colours.borderGrey};
	box-shadow: ${boxShadows.medium};
	left: 24px;
	min-width: 50px;
	position: absolute;
	top: 24px;
	transform: translateX(-100%);
	z-index: 1;

	&::before {
		border-bottom: 6px solid ${colours.borderGrey};
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		content: "";
		height: 0;
		left: 100%;
		position: absolute;
		top: -7px;
		transform: translateX(-100%);
		width: 0;
	}
`;

const MenuItem = styled.div`
	background: ${colours.background};
	font-size: 16px;
	height: 40px;
	line-height: 24px;
	padding: 8px 12px;
	transition: 0.2s ease;

	&:hover {
		background-color: ${colours.lightGrey};
		cursor: pointer;
	}
`;

export default function DropdownMenu(props) {
	const [showMenu, setShowMenu] = useState(false);
	const dropdownMenu = useRef(null);

	function openMenu(event) {
		event.preventDefault();
		setShowMenu(!showMenu);

		document.addEventListener("click", closeMenu);
	}

	function closeMenu(event) {
		if (dropdownMenu.current && !dropdownMenu.current.contains(event.target)) {
			setShowMenu(false);

			document.removeEventListener("click", closeMenu);
		}
	}

	return (
		<Wrapper ref={dropdownMenu}>
			<Trigger onClick={openMenu}>{props.triggerContent}</Trigger>
			{showMenu && (
				<Menu>
					{props.items.map((item, id) =>
						item ? <MenuItem key={id}>{item}</MenuItem> : null
					)}
				</Menu>
			)}
		</Wrapper>
	);
}
