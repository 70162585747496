import * as Yup from "yup";

export const validationSchema = {
	seriesName: Yup.string()
		.trim()
		.max(100, "The Series Name should be no more than 100 characters long.")
		.required("Please supply a name for this series."),
	reset: Yup.number()
		.positive("The number must be a positive number.")
		.required("Please set a reset time."),
	selectedBranding: Yup.object().required("Please select a branding."),
	siteSpaceRates: Yup.array().of(
		Yup.object().shape({
			selectedSite: Yup.object().nullable().required("Site is required."),
			selectedSpaces: Yup.array().min(
				1,
				"At least one space must be selected."
			),
			selectedRates: Yup.array().min(1, "At least one rate must be selected."),
		})
	),
};
