import * as installationApi from "../../api/installation";
import { GoogleMap, Marker, Polygon, withGoogleMap } from "react-google-maps";
import { MoreHorizontal, Plus } from "react-feather";
import React, { useContext, useState } from "react";
import { buffer, polygon } from "@turf/turf";
import { cloneDeep, filter, map } from "lodash";
import { useFetchData, usePermissions } from "../../hooks";
import { AppContext } from "../../context/app-context";
import Button from "../../components/layout/Button";
import Card from "../../components/layout/Card";
import DropdownMenu from "../../components/layout/DropdownMenu";
import GeofencingWizard from "../../components/wizards/geofencing-wizard/GeofencingWizard";
import TableLayout from "../../components/layout/TableLayout";
import { colours } from "../../styles";
import environment from "../../api/environment";

const googleKey = environment.config.googleKey;

const MapWithZones = withGoogleMap((props) => (
	<GoogleMap
		defaultZoom={19}
		defaultTilt={0}
		defaultMapTypeId={"satellite"}
		center={{
			lat: props.siteLat || null,
			lng: props.siteLng || null,
		}}
		onClick={() => {
			props.selectZone(null);
		}}
	>
		<Marker
			position={{
				lat: props.siteLat || null,
				lng: props.siteLng || null,
			}}
			draggable={false}
		/>
		{props.zones.map((zone) => {
			let _polygon = React.createRef();
			let isSelected = zone.ZoneID === props.selectedZone;
			return (
				<div key={zone.ZoneID}>
					<Polygon
						ref={_polygon}
						path={zone.BufferPoints}
						key={zone.ZoneID + "-buffer"}
						editable={false}
						options={{
							strokeColor: `${colours.white}`,
							strokeOpacity: 1,
							fillColor: isSelected ? `${colours.green}` : `${colours.white}`,
							fillOpacity: 0.25,
						}}
						onClick={() => {
							props.selectZone(zone.ZoneID);
						}}
					/>
					<Polygon
						ref={_polygon}
						path={zone.Points}
						key={zone.ZoneID + "-zone"}
						editable={false}
						options={{
							strokeColor: `${colours.white}`,
							strokeOpacity: 1,
							fillColor: isSelected ? `${colours.green}` : `${colours.white}`,
							fillOpacity: 0.4,
						}}
						onClick={() => {
							props.selectZone(zone.ZoneID);
						}}
					/>
				</div>
			);
		})}
	</GoogleMap>
));

const flexStyle = {
	alignSelf: "flex-start",
	flex: 1,
};

const columns = (props) => [
	{
		id: "Name",
		Header: "Zone Name",
		accessor: "Name",
	},
	{
		id: "BufferSize",
		Header: "Buffer Size (meters)",
		accessor: "BufferMeters",
	},
	{
		id: "tasks",
		Header: "",
		accessor: null,
		style: { color: `${colours.darkGrey}` },
		Cell: (cellProps) =>
			props.canManageGeofencing ? (
				<DropdownMenu
					triggerContent={<MoreHorizontal size={24} />}
					items={[
						<div
							key="edit-zone"
							onClick={() =>
								props.openGeofencingWizard(cellProps.original, "edit-zone")
							}
						>
							Edit
						</div>,
						<div
							key="delete-zone"
							onClick={() =>
								props.openGeofencingWizard(cellProps.original, "delete-zone")
							}
						>
							Delete
						</div>,
					]}
				/>
			) : null,
		resizable: false,
		width: 50,
	},
];

export default function GeofencingContainer(props) {
	const [state, setState] = useState({
		geofencingWizardOpen: false,
		selectedZone: null,
	});

	const siteId = props.selectedSite.SiteID;

	const {
		dispatch: { getAvailableSites },
	} = useContext(AppContext);

	const canManageGeofencing = usePermissions(null, "EditSettings");

	const {
		data: site,
		isLoading: siteLoading,
		initialFetchComplete: siteLoaded,
	} = useFetchData(
		[],
		installationApi.getSite,
		[siteId],
		[state.geofencingWizardOpen, siteId]
	);

	let zones = [];

	if (siteLoaded) zones = filter(site.Zones, (zone) => !zone.IsDeleted);

	map(zones, (zone) => {
		let polygonPoints = [];

		for (let index in zone.Points) {
			polygonPoints.push([zone.Points[index].lng, zone.Points[index].lat]);
		}

		//Flatten the polygon in Turf
		const turfPolyPoints = cloneDeep(polygonPoints);
		turfPolyPoints.push(turfPolyPoints[0]);
		const turfPolygon = polygon([turfPolyPoints]);
		//use the turfPolygon to generate the buffer area in Turf
		zone.Buffer = buffer(turfPolygon, zone.BufferMeters, { units: "meters" });
		//convert turf buffer points into google maps format
		zone.BufferPoints = map(zone.Buffer.geometry.coordinates[0], (point) => ({
			lng: point[0],
			lat: point[1],
		}));
	});

	function openGeofencingWizard(zone, mode) {
		setState((_state) => ({
			..._state,
			geofencingWizardOpen: true,
			geofencingWizardZone: zone,
			geofencingWizardMode: mode,
		}));
	}

	function selectZone(zoneId) {
		setState((_state) => ({
			..._state,
			selectedZone: zoneId,
		}));
	}

	if (state.geofencingWizardOpen) {
		return (
			<GeofencingWizard
				close={() => {
					setState((_state) => ({ ..._state, geofencingWizardOpen: false }));
					getAvailableSites();
				}}
				mode={state.geofencingWizardMode}
				zone={state.geofencingWizardZone}
				lat={site.Latitude}
				lng={site.Longitude}
				zones={zones}
				siteId={site.SiteID}
			/>
		);
	}

	return (
		<Card>
			<div style={{ textAlign: "right", margin: "10px 0px 50px 0px" }}>
				{canManageGeofencing && (
					<Button
						color="blue"
						onClick={() => openGeofencingWizard(null, "add-zone")}
					>
						<Plus style={{ marginRight: 4, verticalAlign: "sub" }} size={20} />
						Add zone
					</Button>
				)}
			</div>
			<div style={{ display: "flex" }}>
				{siteLoaded && (
					<>
						<MapWithZones
							style={flexStyle}
							zones={zones}
							siteLat={site.Latitude}
							siteLng={site.Longitude}
							googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=geometry,drawing`}
							loadingElement={
								<div style={{ height: window.innerHeight - 150 }} />
							}
							containerElement={
								<div
									style={{
										height: window.innerHeight - 150,
										width: "70%",
									}}
								/>
							}
							mapElement={<div style={{ height: window.innerHeight - 150 }} />}
							selectZone={selectZone}
							selectedZone={state.selectedZone}
						/>
						<div style={flexStyle}>
							{zones.length ? (
								<TableLayout
									{...props}
									style={{ marginLeft: "15px" }}
									data={zones}
									loading={siteLoading}
									columns={columns({
										openGeofencingWizard: openGeofencingWizard,
										canManageGeofencing: canManageGeofencing,
									})}
									sortable={false}
									selectedZone={state.selectedZone}
									getTrProps={(_state, rowInfo) => {
										// hack the row colour when zone is selected
										if (rowInfo && rowInfo.original) {
											return {
												style: {
													background:
														rowInfo.original.ZoneID !== _state.selectedZone
															? rowInfo.index % 2 === 0
																? `${colours.offWhite}`
																: `${colours.white}`
															: `${colours.green}`,
													color:
														rowInfo.original.ZoneID !== _state.selectedZone
															? `${colours.darkGrey}`
															: `${colours.white}`,
												},
											};
										}
									}}
								/>
							) : (
								<div
									style={{
										paddingTop: "50px",
										fontSize: 18,
										textAlign: "center",
									}}
								>
									No Zones Found
								</div>
							)}
						</div>
					</>
				)}
			</div>
		</Card>
	);
}
