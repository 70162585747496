import * as Yup from "yup";
import React, { useContext, useState } from "react";
import { cloneDeep, keys } from "lodash";
import {
	useHasClientAdmin,
	useMutateData,
	usePermissions,
	useQueryData,
} from "../hooks";
import Accordion from "../components/layout/Accordion";
import Alert from "react-s-alert";
import { AppContext } from "../context/app-context";
import Button from "../components/layout/Button";
import Card from "../components/layout/Card";
import EditableInputField from "../components/layout/EditableInputField";
import ErrorBoundary from "./ErrorBoundary";
import FormLayout from "../components/layout/FormLayout";
import PageTitle from "../components/layout/PageTitle";
import gql from "graphql-tag";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Dropdown from "../components/layout/Dropdown";

const FieldWrapper = styled.div`
	margin: 16px 32px;

	> * {
		margin: 0 10px;
	}
`;

const FieldContent = styled.div`
	> * {
		margin: 0;
		max-width: 600px;
		min-height: 32px;
	}
`;

const Label = styled.div`
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 4px;
`;

export default function OperatorSettingsContainer(props) {
	const { t: translate } = useTranslation();

	const [state, setState] = useState({
		reloadToggle: false,
		deleteConfirmationShow: false,
		deletingOrganization: false,
	});

	const {
		state: { selectedOperator },
		dispatch: { getAvailableOperators },
	} = useContext(AppContext);

	const mutation = useMutateData(gql`
		mutation ($operatorId: Int!, $name: String, $clientId: Int) {
			updateOperator(operatorId: $operatorId, name: $name, clientId: $clientId)
		}
	`);

	let initialValues = cloneDeep({
		Name: props.selectedOperator.Name,
		ClientID: props.selectedOperator.ClientID,
	});

	const settingsSchema = Yup.object().shape({
		Name: Yup.string().required(),
	});

	const isAdmin = usePermissions("IsAdmin");
	const hasClientAdmin = useHasClientAdmin(selectedOperator.ClientID);

	if (!(isAdmin || hasClientAdmin)) {
		props.history.push("/");
	}

	const {
		data: { getClients },
	} = useQueryData(
		gql`
			query {
				getClients {
					ClientID
					Name
				}
			}
		`,
		{ organizationId: props.organizationId },
		!isAdmin
	);

	const clientOptions = (getClients || []).map((client) => ({
		value: client.ClientID,
		label: client.Name,
	}));

	return (
		<ErrorBoundary>
			<PageTitle>Operator</PageTitle>
			<FormLayout
				enableReinitialize={true}
				initialValues={initialValues}
				validationSchema={settingsSchema}
				onSubmit={async (values) => {
					try {
						await mutation({
							variables: {
								operatorId: props.selectedOperator.OperatorID,
								name: values.Name,
								clientId: values.ClientID,
							},
						});

						setState((_state) => ({
							..._state,
							reloadToggle: !state.reloadToggle,
						}));

						getAvailableOperators();

						Alert.success("Operator settings updated");
					} catch (error) {
						Alert.error(translate("Errors.Generic"));
					}
				}}
				render={({
					values,
					errors,
					touched,
					handleBlur,
					handleChange,
					handleSubmit,
					setFieldValue,
					isSubmitting,
				}) => {
					return (
						<form
							className="form"
							onSubmit={(event) => {
								event.preventDefault();
							}}
						>
							<Card>
								<Accordion title="General Info" expanded={true}>
									<FieldWrapper>
										<Label>Operator Name</Label>
										<FieldContent>
											<EditableInputField
												name="Name"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.Name || ""}
												error={errors.Name && touched.Name ? errors.Name : null}
											/>
										</FieldContent>
									</FieldWrapper>
								</Accordion>
							</Card>

							{isAdmin && (
								<Card>
									<Accordion title="Account" expanded={true}>
										<FieldWrapper>
											<FieldContent style={{ marginLeft: "0" }}>
												<Dropdown
													isClearable={false}
													onChange={(value) =>
														setFieldValue(
															"ClientID",
															value ? value.value : null
														)
													}
													options={clientOptions}
													value={clientOptions.find(
														(e) => e.value === values.ClientID
													)}
												/>
											</FieldContent>
										</FieldWrapper>
									</Accordion>
								</Card>
							)}

							<div>
								<Button
									style={{ float: "right", marginBottom: "20px" }}
									color="green"
									onClick={handleSubmit}
									disabled={isSubmitting || keys(errors).length > 0}
								>
									Update Operator
								</Button>
							</div>
						</form>
					);
				}}
			/>
		</ErrorBoundary>
	);
}
