import * as installationApi from "../../../api/installation";
import Alert from "react-s-alert";

export async function deleteSpecialInstruction(props, values, setSubmitting) {
	try {
		await installationApi.deleteSpecialInstruction(
			props.siteId,
			values.specialInstructionId
		);

		setSubmitting(false);
		Alert.success("Special instruction deleted");
		props.close(true);
	} catch (error) {
		setSubmitting(false);
		Alert.error("Something went wrong. Please try again.");
	}
}

export async function addSpecialInstruction(props, values, setSubmitting) {
	let specialInstruction = {
		Type: values.type,
		Title: values.title,
		Instruction: values.instruction,
		GroupIDs: values.groupIds,
	};

	try {
		await installationApi.createSpecialInstruction(
			props.siteId,
			specialInstruction
		);

		setSubmitting(false);
		Alert.success("Special instruction added");
		props.close(true);
	} catch (error) {
		setSubmitting(false);
		Alert.error("Something went wrong. Please try again.");
	}
}

export async function updateSpecialInstruction(props, values, setSubmitting) {
	let specialInstruction = {
		SpecialInstructionID: values.specialInstructionId,
		Type: values.type,
		Title: values.title,
		Instruction: values.instruction,
		GroupIDs: values.groupIds,
	};

	try {
		await installationApi.updateSpecialInstruction(
			props.siteId,
			values.specialInstructionId,
			specialInstruction
		);

		setSubmitting(false);
		Alert.success("Special instruction updated");
		props.close(true);
	} catch (error) {
		setSubmitting(false);
		Alert.error("Something went wrong. Please try again.");
	}
}
