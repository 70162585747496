import Slider, { createSliderWithTooltip } from "rc-slider";
import styled, { createGlobalStyle } from "styled-components";
import React from "react";
import Tooltip from "rc-tooltip";

const SliderWithTooltip = createSliderWithTooltip(Slider);

import { colours } from "../../styles";

const Item = styled.div`
	padding: 8px;
	width: 100%;
`;

const GlobalStyle = createGlobalStyle`
	.rc-slider-rail {
		background-color: ${colours.lightGrey};
	}

	.rc-slider-track {
		background-color: ${colours.green};
	}

	.rc-slider-disabled {
		background: none;
	}

	.rc-slider:not(.rc-slider-disabled) .rc-slider-handle {
		border-color: ${colours.green} !important;
		box-shadow: 0 0 5px ${colours.green} !important;
	}
`;

const handle = (props) => {
	const { value, dragging, index, ...rest } = props;
	return (
		<Tooltip overlay={value} visible={dragging} placement="top" key={index}>
			<Slider.Handle value={value} {...rest} />
		</Tooltip>
	);
};

export default function SliderComponent(props) {
	return (
		<Item>
			<GlobalStyle />
			<SliderWithTooltip handle={handle} {...props} />
		</Item>
	);
}
