import * as authenticationApi from "../../../api/authentication";
import * as billingApi from "../../../api/billing";
import { format, user as userhelpers } from "../../../helpers";
import Card from "../../layout/Card";
import DetailsPageWrapper from "../DetailsPageWrapper";
import PropTypes from "prop-types";
import React from "react";
import TableLayout from "../../layout/TableLayout";
import { useFetchData } from "../../../hooks";

ViewRefund.propTypes = {
	siteId: PropTypes.number.isRequired,
	refundRequestId: PropTypes.number.isRequired,
	onClose: PropTypes.func.isRequired,
};

function ViewRefund(props) {
	const columns = [
		{
			id: "Amount",
			Header: "Amount",
			accessor: (d) => format.money(d.amount),
		},
		{
			id: "RefundedBy",
			Header: "Refunded By",
			accessor: (d) => d.userName,
		},
		{
			id: "Reason",
			Header: "Reason",
			style: {
				whiteSpace: "pre-line",
				wordBreak: "break-all",
				wordWrap: "break-word",
			},
			accessor: (d) => d.reason,
		},
	];

	const { data: refundRequest } = useFetchData(
		{},
		billingApi.getRefundRequest,
		[props.siteId, props.refundRequestId],
		[props.siteId, props.refundRequestId]
	);

	const { data: user, isLoading: isLoadingUser } = useFetchData(
		{},
		authenticationApi.getUserWithoutPrivileges,
		refundRequest.RequestingUserID ? [refundRequest.RequestingUserID] : null,
		[refundRequest.RequestingUserID]
	);

	const refundData = [
		{
			amount: refundRequest.Amount,
			reason: refundRequest.Reason,
			userName: user ? userhelpers.fullNameWithEmail(user) : null,
		},
	];

	const content = (
		<Card>
			<TableLayout
				{...props}
				title="Refund Details"
				data={refundData}
				columns={columns}
				loading={isLoadingUser}
			/>
		</Card>
	);

	return (
		<DetailsPageWrapper
			close={props.onClose}
			content={content}
			title="Refund Details"
		/>
	);
}

export default ViewRefund;
