import React from "react";

export default class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false, error: null, info: null };
	}

	static getDerivedStateFromError(error) {
		// eslint-disable-next-line no-console
		console.error(error);

		return { hasError: true };
	}

	render() {
		if (this.state.hasError) {
			return (
				<h1>Something went wrong. Please reload the page and try again.</h1>
			);
		}

		return this.props.children;
	}
}
