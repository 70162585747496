import * as Yup from "yup";
import { addPlate, delPlate, editPlate } from "./plate-steps";
import Alert from "react-s-alert";
import React from "react";
import WizardLayout from "../WizardLayout";
import { errors as errorMessages } from "../../../helpers";
import gql from "graphql-tag";
import moment from "moment";
import { useMutateData } from "../../../hooks";

const constructWizard = (props) => {
	switch (props.mode) {
		case "add":
			return { initialStep: 0, steps: [addPlate] };
		case "edit":
			return { initialStep: 0, steps: [editPlate] };
		case "delete":
			return { initialStep: 0, steps: [delPlate] };
	}
};

export default function PlateWizard(props) {
	const addNumberplateToSite = useMutateData(gql`
		mutation (
			$numberplate: String!
			$siteId: Int!
			$expiresAt: Int
			$description: String
		) {
			addNumberplateToSite(
				numberplate: $numberplate
				siteId: $siteId
				expiresAt: $expiresAt
				description: $description
			)
		}
	`);

	const updateNumberplateOnSite = useMutateData(gql`
		mutation (
			$numberplateId: Int!
			$siteId: Int!
			$numberplate: String!
			$expiresAt: Int
			$description: String
		) {
			updateNumberplateOnSite(
				numberplateId: $numberplateId
				siteId: $siteId
				numberplate: $numberplate
				expiresAt: $expiresAt
				description: $description
			)
		}
	`);

	const removeNumberplateFromSite = useMutateData(gql`
		mutation ($numberplateId: Int!, $siteId: Int!) {
			removeNumberplateFromSite(numberplateId: $numberplateId, siteId: $siteId)
		}
	`);

	const wizard = constructWizard(props);

	const onSubmit = async (values, { setSubmitting }) => {
		setSubmitting(true);

		try {
			let msg;

			if (values.mode === "add") {
				msg = "added";
				await addNumberplateToSite({
					variables: {
						numberplate: values.Numberplate,
						siteId: props.siteId,
						expiresAt: values.ExpiresAt && moment(values.ExpiresAt).unix(),
						description: values.Description,
					},
				});
			} else if (values.mode === "edit") {
				msg = "updated";
				await updateNumberplateOnSite({
					variables: {
						numberplateId: values.NumberplateID,
						siteId: props.siteId,
						numberplate: values.Numberplate,
						expiresAt: values.ExpiresAt && moment(values.ExpiresAt).unix(),
						description: values.Description,
					},
				});
			} else if (values.mode === "delete") {
				msg = "deleted";
				await removeNumberplateFromSite({
					variables: {
						numberplateId: values.NumberplateID,
						siteId: props.siteId,
					},
				});
			}

			setSubmitting(false);
			Alert.success(`Plate ${msg}`);
			props.close(true);
			props.refetch();
		} catch (error) {
			setSubmitting(false);
			const errorCode = error.graphQLErrors?.[0]?.extensions?.code;
			switch (errorCode) {
				case "INVALID_SHARED_INSTALLATION":
					Alert.error(errorMessages.INVALID_SHARED_INSTALLATION_VIA_PLATE);
					break;
				case "DUPLICATE_NUMBERPLATE_SYNCED":
					Alert.error(errorMessages.PLATE_ALREADY_EXISTS_ON_SITE);
					break;
				case "DUPLICATE_ANPR_NUMBERPLATE":
					Alert.error(errorMessages.ANPR_PLATE_ALREADY_EXISTS);
					break;
				default:
					Alert.error("Something went wrong. Please try again.");
					break;
			}
		}
	};

	const values = [
		{ name: "mode", value: props.mode, validator: Yup.string() },
		{
			name: "NumberplateID",
			value: props.plateDetails?.NumberplateID,
			validator: Yup.number().integer().nullable(),
		},
		{
			name: "Numberplate",
			value: props.plateDetails?.Numberplate,
			validator: Yup.string()
				.matches(/^[A-Z0-9]{1,10}$/)
				.required("Please provide a valid plate"),
		},
		{
			name: "ExpiresAt",
			value:
				props.plateDetails?.ExpiresAt &&
				new Date(props.plateDetails.ExpiresAt * 1000),
			validator: Yup.date().nullable(),
		},
		{
			name: "Description",
			value: props.plateDetails?.Description,
			validator: Yup.string().max(255).nullable(),
		},
	];

	return (
		<WizardLayout
			close={props.close}
			title={({ mode }) => {
				if (mode === "add") return "Add plate";
				if (mode === "edit") return "Edit plate";
				if (mode === "delete") return "Remove plate";
			}}
			values={values}
			onSubmit={onSubmit}
			steps={wizard.steps}
			initialStep={wizard.initialStep}
			wizardProps={props}
			hideStepIndicator={true}
		/>
	);
}
