import { StepText, StepTitle } from "../WizardLayout";
import { find, map } from "lodash";
import AddressLookup from "../AddressLookup";
import Button from "../../layout/Button";
import Dropdown from "../../layout/Dropdown";
import EditableInputField from "../../layout/EditableInputField";
import MultiChoiceButton from "../MultiChoiceButton";
import React from "react";
import SummaryTable from "../SummaryTable";
import Toggle from "../../layout/Toggle";
import WizardNavigation from "../WizardNavigation";
import { user as userHelpers } from "../../../helpers";

function getButton(
	_name,
	color,
	onClick,
	isDisabled,
	isEditing,
	goTo,
	keyStrokeHandler
) {
	let label = isEditing
		? "Review"
		: `${_name.charAt(0).toUpperCase()}${_name.slice(1)}`;
	return (
		<Button
			key={_name}
			color={isEditing ? "blue" : color}
			onClick={isEditing ? () => goTo("summary") : onClick}
			disabled={isDisabled}
			keyStrokeHandler={keyStrokeHandler}
		>
			{label}
		</Button>
	);
}

export function selectName({
	close,
	next,
	isSubmitting,
	values,
	goTo,
	keyStrokeHandler,
}) {
	const isIncomplete = isSubmitting || !values.name;

	return {
		id: "name",
		label: "Name",
		render: () => (
			<div>
				<StepTitle>Please select a name for this account</StepTitle>
				<EditableInputField
					type="text"
					name="name"
					value={values.name || ""}
					useLabel="Name"
				/>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [getButton("cancel", "red", close)]
				}
				rightItems={[
					getButton(
						"next",
						"blue",
						next,
						isIncomplete,
						values.editFromSummary,
						goTo,
						keyStrokeHandler
					),
				]}
			/>
		),
	};
}

export function managed({
	previous,
	setFieldValue,
	goTo,
	values,
	keyStrokeHandler,
}) {
	return {
		id: "managed",
		label: "Managed",
		render: () => {
			return (
				<div>
					<StepTitle>
						Do you want to invite a user to manage this account?
					</StepTitle>
					<MultiChoiceButton
						selected={!values.isUnmanaged}
						onClick={() => {
							setFieldValue("isUnmanaged", false);
						}}
					>
						Yes
					</MultiChoiceButton>
					<MultiChoiceButton
						selected={values.isUnmanaged}
						onClick={() => {
							setFieldValue("isUnmanaged", true);
						}}
					>
						No
					</MultiChoiceButton>
				</div>
			);
		},
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [getButton("back", "blue", previous)]
				}
				rightItems={[
					<Button
						key={selectName}
						color={"blue"}
						onClick={() =>
							values.isUnmanaged ? goTo("address") : goTo("email")
						}
						keyStrokeHandler={keyStrokeHandler}
					>
						Next
					</Button>,
				]}
			/>
		),
	};
}

export function setEmail({
	goTo,
	previous,
	setFieldValue,
	values,
	keyStrokeHandler,
}) {
	return {
		id: "email",
		label: "Email",
		render: () => {
			return (
				<div>
					<StepTitle>What is the email address of the user?</StepTitle>
					<EditableInputField
						type="text"
						name={"email"}
						useLabel={"Email Address"}
						value={values.email || ""}
						onChange={(event) => {
							const result = userHelpers.emailValidator(
								String(event.target.value).toLowerCase()
							);

							setFieldValue("email", event.target.value);
							setFieldValue("validEmail", result);
						}}
						error={
							!values.email || values.validEmail
								? null
								: "Please supply a valid email address"
						}
					/>
				</div>
			);
		},
		footer: () => (
			<WizardNavigation
				leftItems={[getButton("back", "blue", previous)]}
				rightItems={[
					<Button
						key={setEmail}
						color={"blue"}
						onClick={() => goTo("address")}
						keyStrokeHandler={keyStrokeHandler}
					>
						Next
					</Button>,
				]}
			/>
		),
	};
}

export function setAddress({
	next,
	setFieldValue,
	isSubmitting,
	goTo,
	values: {
		address,
		billingAddress,
		isUnmanaged,
		useBillingAddress,
		editFromSummary,
	},
	keyStrokeHandler,
}) {
	const isIncomplete = isSubmitting || !address || !billingAddress;

	const onAddressChange = (_address) => {
		setFieldValue("address", _address);

		if (!useBillingAddress) {
			setFieldValue("billingAddress", _address);
		}
	};

	return {
		id: "address",
		label: "Address",
		render: () => {
			return (
				<div>
					<StepTitle>Set address</StepTitle>

					<AddressLookup
						address={address}
						onChange={(event) => onAddressChange(event.address)}
					/>

					<Toggle
						style={{ marginTop: 16, marginBottom: 16 }}
						label={"Use Separate Billing Address"}
						checked={useBillingAddress}
						onChange={(event) => setFieldValue("useBillingAddress", event)}
					/>

					{useBillingAddress && (
						<AddressLookup
							address={billingAddress}
							onChange={(event) =>
								setFieldValue("billingAddress", event.address)
							}
						/>
					)}
				</div>
			);
		},
		footer: () => (
			<WizardNavigation
				leftItems={
					!editFromSummary && (
						<Button
							key={"back"}
							color={"blue"}
							onClick={() => (isUnmanaged ? goTo("managed") : goTo("email"))}
						>
							Back
						</Button>
					)
				}
				rightItems={[
					getButton(
						"next",
						"blue",
						next,
						isIncomplete,
						editFromSummary,
						goTo,
						keyStrokeHandler
					),
				]}
			/>
		),
	};
}

export function selectBeacons({
	next,
	previous,
	setFieldValue,
	isSubmitting,
	values: { beacons = [], editFromSummary },
	wizardProps = {},
	goTo,
	keyStrokeHandler,
}) {
	const options = wizardProps.beacons
		? [{ label: "All Beacons", value: 0 }].concat(
				map(wizardProps.beacons, (beacon) => ({
					label: beacon.name,
					value: beacon.id,
				}))
		  )
		: [];

	const selected = options.filter(({ value }) =>
		beacons.find((site) => value > 0 && site.id === value)
	);

	const isIncomplete = isSubmitting || !selected.length;

	return {
		id: "beacons",
		label: "Select Beacons",
		render: () => (
			<div>
				<StepTitle>Select beacons</StepTitle>

				<Dropdown
					isMulti={true}
					options={options}
					value={selected}
					onChange={(_options) => {
						const _beacons = find(_options, ({ value }) => value === 0)
							? wizardProps.beacons
							: map(_options, (option) => ({
									id: option.value,
									name: option.label,
							  }));

						setFieldValue("beacons", _beacons);
					}}
				/>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={!editFromSummary && [getButton("back", "blue", previous)]}
				rightItems={[
					getButton(
						"next",
						"blue",
						next,
						isIncomplete,
						editFromSummary,
						goTo,
						keyStrokeHandler
					),
				]}
			/>
		),
	};
}

export function summary({
	goTo,
	handleSubmit,
	isSubmitting,
	setFieldValue,
	values: { name, isUnmanaged, email, address, billingAddress, beacons = [] },
	wizardProps: { mode },
	keyStrokeHandler,
}) {
	const items = isUnmanaged
		? [
				{
					title: "Name",
					value: name,
					key: "name",
					edit: () => {
						setFieldValue("editFromSummary", true);
						goTo("name");
					},
				},
				{
					title: "Address",
					value: address,
					key: "address",
					edit: () => {
						setFieldValue("editFromSummary", true);
						goTo("address");
					},
				},
				{
					title: "Billing Address",
					value: billingAddress,
					key: "billingAddress",
					edit: () => {
						setFieldValue("editFromSummary", true);
						goTo("address");
					},
				},
				{
					title: "Beacons",
					value: beacons
						.map((beacon) => beacon.name || beacon.UniqueID)
						.join(", "),
					key: "beacons",
					edit: () => {
						setFieldValue("editFromSummary", true);
						goTo("beacons");
					},
				},
		  ]
		: [
				{
					title: "Name",
					value: name,
					key: "name",
					edit: () => {
						setFieldValue("editFromSummary", true);
						goTo("name");
					},
				},
				{
					title: "Email",
					value: email,
					key: "email",
				},
				{
					title: "Beacons",
					value: beacons
						.map((beacon) => beacon.name || beacon.UniqueID)
						.join(", "),
					key: "beacons",
					edit: () => {
						setFieldValue("editFromSummary", true);
						goTo("beacons");
					},
				},
		  ];

	let button = getButton(
		"submit",
		"green",
		handleSubmit,
		isSubmitting,
		undefined,
		undefined,
		keyStrokeHandler
	);

	if (mode === "delete") {
		items.forEach((item) => delete item.edit);
		button = getButton("remove", "red", handleSubmit, isSubmitting);
	}

	return {
		id: "summary",
		label: "Summary",
		render: () => (
			<div>
				<StepTitle>Summary</StepTitle>
				<SummaryTable items={items} />
			</div>
		),
		footer: () => <WizardNavigation rightItems={[button]} />,
	};
}

export function deleteStep({
	close,
	handleSubmit,
	isSubmitting,
	values,
	keyStrokeHandler,
}) {
	return {
		id: "delete",
		label: "Delete Account",
		render: () => (
			<div>
				<StepText>
					Are you sure you want to delete account <b>{values.name}?</b>
				</StepText>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={[getButton("cancel", "blue", close)]}
				rightItems={[
					getButton(
						"submit",
						"red",
						handleSubmit,
						isSubmitting,
						undefined,
						undefined,
						keyStrokeHandler
					),
				]}
			/>
		),
	};
}
