import React from "react";
import { FieldArray } from "formik";
import { SpaceSelect } from "./SpaceSelect";
import { Label } from "./styles";
import FieldError from "../../layout/FieldError";
import { sortBy } from "lodash";
import { MinusCircle, PlusCircle } from "react-feather";
import Dropdown from "../../layout/Dropdown";
import { colours } from "../../../styles";

export const SiteSpaces = ({ siteOptions, values, setFieldValue, errors }) => {
	const filterSelectedSites = (options, selectedSpaces) => {
		const selectedSiteValues = selectedSpaces
			.map((item) => item.selectedSite?.value)
			.filter((value) => value !== undefined);
		return options.filter(
			(option) => !selectedSiteValues.includes(option.value)
		);
	};

	const [currentSiteOptions, setCurrentSiteOptions] = React.useState(() => {
		return filterSelectedSites(siteOptions, values.siteSpaces);
	});

	const handleSiteChange = (index, selectedSite) => {
		const items = [...values.siteSpaces];
		const previousSite = items[index]?.selectedSite;

		if (previousSite) {
			setCurrentSiteOptions((prevOptions) => [...prevOptions, previousSite]);
		}

		items[index] = { selectedSite, selectedSpace: null };
		setFieldValue("siteSpaces", items);

		setCurrentSiteOptions(filterSelectedSites(siteOptions, items));
	};

	const handleSpaceChange = (index, selectedSpace) => {
		const items = [...values.siteSpaces];
		items[index].selectedSpace = selectedSpace;
		setFieldValue("siteSpaces", items);
	};

	const handleAdd = (arrayHelpers) => {
		arrayHelpers.push({ selectedSite: null, selectedSpace: null });
		setCurrentSiteOptions(filterSelectedSites(siteOptions, values.siteSpaces));
	};

	const handleRemove = (arrayHelpers, index) => {
		const items = [...values.siteSpaces];
		const removedSite = items[index]?.selectedSite;

		arrayHelpers.remove(index);

		if (removedSite) {
			setCurrentSiteOptions((prevOptions) => [...prevOptions, removedSite]);
		}

		const updatedItems = [...items.slice(0, index), ...items.slice(index + 1)];
		setCurrentSiteOptions(filterSelectedSites(siteOptions, updatedItems));

		// Ensure there's always an empty row available for adding
		if (updatedItems.length === 0) {
			arrayHelpers.push({ selectedSite: null, selectedSpace: null });
		}
	};

	return (
		<div>
			<Label>Sites and Spaces</Label>
			<FieldArray name="siteSpaces">
				{(arrayHelpers) => (
					<div>
						{values.siteSpaces.map((item, index) => (
							<div key={index}>
								<div
									style={{
										display: "flex",
										gap: 8,
										marginBottom: 8,
										alignItems: "center",
									}}
								>
									<Dropdown
										options={sortBy(currentSiteOptions, "label")}
										value={item.selectedSite}
										onChange={(site) => handleSiteChange(index, site)}
										style={{ width: 250 }}
										placeholder="Select a site"
									/>
									<SpaceSelect
										selectedSite={item.selectedSite?.value}
										selectedSpace={item.selectedSpace}
										onChange={(space) => handleSpaceChange(index, space)}
									/>
									{values.siteSpaces.length > 1 && (
										<MinusCircle
											onClick={() => handleRemove(arrayHelpers, index)}
											color={colours.red}
											style={{ cursor: "pointer" }}
										/>
									)}

									{index === values.siteSpaces.length - 1 && (
										<PlusCircle
											onClick={() => handleAdd(arrayHelpers)}
											color={colours.blue}
											style={{ cursor: "pointer" }}
										/>
									)}
								</div>
								{errors.siteSpaces && errors.siteSpaces[index] && (
									<div style={{ marginTop: 4 }}>
										<FieldError
											showError={true}
											errorText={
												errors.siteSpaces[index].selectedSite ||
												errors.siteSpaces[index].selectedSpace
											}
										/>
									</div>
								)}
							</div>
						))}
					</div>
				)}
			</FieldArray>
		</div>
	);
};
