import React from "react";
import { colours } from "../../styles";
import imageLarge from "../../images/inugo-light.png";
import imageSmall from "../../images/inugo-n.png";
import styled from "styled-components";

const TopLogo = styled.div`
	background-color: ${colours.darkerBlue};
	cursor: pointer;
	height: 64px;
	position: relative;
	text-align: center;
	width: 120px;

	img {
		position: absolute;
		height: 40px;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
	}
`;

const SideLogo = styled.div`
	background-color: ${colours.darkerBlue};
	cursor: pointer;
	height: 64px;
	margin: 8px 0;
	position: relative;
	text-align: center;

	img {
		position: absolute;
		height: ${(props) => (props.menuCollapsed ? "36px" : "48px")};
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
	}
`;

export default function MenuLogo(props) {
	if (props.topMenu) {
		return (
			<TopLogo menuCollapsed={props.menuCollapsed} onClick={props.onClick}>
				<img src={imageLarge} />
			</TopLogo>
		);
	}

	return (
		<SideLogo menuCollapsed={props.menuCollapsed} onClick={props.onClick}>
			<img src={props.menuCollapsed ? imageSmall : imageLarge} />
		</SideLogo>
	);
}
