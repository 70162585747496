import * as Yup from "yup";
import * as api from "./api";
import * as specialInstructionSteps from "./special-instruction-steps";
import { find, map } from "lodash";
import React from "react";
import WizardLayout from "../WizardLayout";

const constructWizard = (props) => {
	if (props.mode === "add-special-instruction") {
		return {
			initialStep: 0,
			steps: [
				specialInstructionSteps.typeStep,
				specialInstructionSteps.titleStep,
				specialInstructionSteps.instructionStep,
				specialInstructionSteps.groupStep,
				specialInstructionSteps.summaryStep,
			],
		};
	} else if (props.mode === "edit-special-instruction") {
		return {
			initialStep: 4,
			steps: [
				specialInstructionSteps.typeStep,
				specialInstructionSteps.titleStep,
				specialInstructionSteps.instructionStep,
				specialInstructionSteps.groupStep,
				specialInstructionSteps.summaryStep,
			],
		};
	} else if (props.mode === "delete-special-instruction") {
		return {
			initialStep: 0,
			steps: [specialInstructionSteps.deleteStep],
		};
	}
};

function getGroup(organizationAccessGroups, groupIds) {
	return map(groupIds, (id) => {
		let group = find(
			organizationAccessGroups,
			(_group) => _group.OrganizationAccessGroupID === id
		);

		return {
			value: id,
			label: group ? group.Name : "(deleted)",
		};
	});
}

export default function SpecialInstructionWizard(props) {
	const wizard = constructWizard(props);

	return (
		<WizardLayout
			close={props.close}
			title={(values) => {
				let _title = values.mode.replace(/-/g, " ");
				//capitalize the 1st letter of each word
				_title = _title.replace(/\b[a-z](?=[a-z]{2})/g, (letter) =>
					letter.toUpperCase()
				);

				return _title;
			}}
			values={[
				{ name: "mode", value: props.mode, validator: Yup.string() },
				{
					name: "type",
					value: props.specialInstruction
						? props.specialInstruction.Type
						: null,
					validator: Yup.string(),
				},
				{
					name: "title",
					value: props.specialInstruction
						? props.specialInstruction.Title
						: null,
					validator: Yup.string(),
				},
				{
					name: "instruction",
					value: props.specialInstruction
						? props.specialInstruction.Instruction
						: null,
					validator: Yup.string(),
				},
				{
					name: "groups",
					value: props.specialInstruction
						? getGroup(props.accessGroups, props.specialInstruction._groupIds)
						: null,
					validator: Yup.object().nullable(),
				},
				{
					name: "groupIds",
					value: props.specialInstruction
						? props.specialInstruction.GroupIDs
						: null,
					validator: Yup.string().nullable(),
				},
				{
					name: "groupNames",
					value: props.specialInstruction
						? props.specialInstruction.groupNames
						: null,
					validator: Yup.string().nullable(),
				},
				{
					name: "specialInstructionId",
					value: props.specialInstruction
						? props.specialInstruction.SpecialInstructionID
						: null,
					validator: Yup.number().nullable(),
				},
			]}
			onSubmit={(values, { setSubmitting }) => {
				setSubmitting(true);

				if (values.mode === "add-special-instruction") {
					api.addSpecialInstruction(props, values, setSubmitting);
				} else if (values.mode === "edit-special-instruction") {
					api.updateSpecialInstruction(props, values, setSubmitting);
				} else if (values.mode === "delete-special-instruction") {
					api.deleteSpecialInstruction(props, values, setSubmitting);
				}
			}}
			steps={wizard.steps}
			initialStep={wizard.initialStep}
			wizardProps={props}
		/>
	);
}
