/* eslint-disable no-console */
import environment from "./environment";

const SESSION_KEY_NAME = "sessionKey";
export const basePath = environment.config.basePath;
export const baseGraphqlPath = environment.config.baseGraphqlPath;

function handleResponse(response, handlerType = "json") {
	if (response.status >= 400) {
		return response[handlerType]().then((result) => Promise.reject(result));
	}

	return response[handlerType]();
}

function handleError(error) {
	if (
		error &&
		error.errors &&
		error.errors.find(
			(e) => e.code === "InvalidSession" || e.code === "NotLoggedIn"
		)
	) {
		window.localStorage.removeItem(SESSION_KEY_NAME);
		window.location.href = "/login";
		return;
	} else if (
		error &&
		(error.message === "Failed to fetch" ||
			error.message === "NetworkError when attempting to fetch resource.")
	) {
		console.error(error);
		window.location.href = "/error";
		return;
	}

	return Promise.reject(error);
}

export function setSessionKey(sessionKey) {
	window.localStorage.setItem(SESSION_KEY_NAME, sessionKey);
}

export function getSessionKey() {
	return window.localStorage.getItem(SESSION_KEY_NAME) || "";
}

export function hasSessionKey() {
	return !!window.localStorage.getItem(SESSION_KEY_NAME);
}

export function clearSessionKey() {
	window.localStorage.removeItem(SESSION_KEY_NAME);
}

export function getURL(url) {
	return `${basePath}${url}`;
}

export function getJSON(url) {
	return fetch(`${basePath}${url}`, {
		method: "GET",
		headers: {
			Authorization: getSessionKey(),
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function postForm(url, data) {
	let bodyData = new FormData();

	Object.entries(data).forEach(([key, value]) => bodyData.append(key, value));

	return fetch(`${basePath}${url}`, {
		method: "POST",
		headers: {
			Authorization: getSessionKey(),
		},
		body: bodyData,
	})
		.then(handleResponse)
		.catch(handleError);
}

export function postJSON(url, data, fileUpload = false, handlerType = "json") {
	if (fileUpload) {
		return fetch(`${basePath}${url}`, {
			method: "POST",
			headers: {
				Authorization: getSessionKey(),
			},
			body: data,
		})
			.then(handleResponse)
			.catch(handleError);
	}

	return fetch(`${basePath}${url}`, {
		method: "POST",
		headers: {
			Authorization: getSessionKey(),
			"Content-Type": "application/json",
		},
		body: JSON.stringify(data),
	})
		.then((response) => handleResponse(response, handlerType))
		.catch(handleError);
}

export function putJSON(url, data) {
	return fetch(`${basePath}${url}`, {
		method: "PUT",
		headers: {
			Authorization: getSessionKey(),
			"Content-Type": "application/json",
		},
		body: JSON.stringify(data),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function deleteJSON(url) {
	return fetch(`${basePath}${url}`, {
		method: "DELETE",
		headers: {
			Authorization: getSessionKey(),
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
