import moment from "moment";
import {
	CREATE_SERIES_STEP,
	CREATE_EVENTS_STEP,
	CREATE_SUMMARY_STEP,
	CANCEL_EVENT_STEP,
} from "./steps";
import { EVENT_WIZARD_MODES } from "./EventWizard.constants";
import Alert from "react-s-alert";
import { validationSchema } from "./EventWizard.validations";
import { EventSchema } from "./steps/events/SingleEvent.helpers";

export const formatEvents = ({ Events, Timezone }) => {
	const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

	return Events.map(({ EventID, Name, StartTimestamp, EndTimestamp }) => {
		const startMoment = moment
			.unix(StartTimestamp)
			.tz(Timezone)
			.tz(browserTimezone, true);
		const endMoment = moment
			.unix(EndTimestamp)
			.tz(Timezone)
			.tz(browserTimezone, true);

		return {
			EventID: EventID ?? null,
			name: Name,
			startTime: startMoment.toDate(),
			endTime: endMoment.toDate(),
			Timezone,
		};
	});
};

export const formatOptions = (items, idKey = "value", labelKey = "label") =>
	items.map((item) => ({
		value: item[idKey],
		label: item[labelKey],
	}));

export const constructSiteSpaceRates = (seriesData, Sites, Spaces, Rates) => {
	if (!seriesData) return [];
	return Sites.map((site) => {
		const selectedSpaces = Spaces.filter((space) =>
			seriesData.Sites.some(
				(s) =>
					s.SiteID === site.value &&
					s.LeaseParks.some((lp) => lp.LeaseParkID === space.value)
			)
		);

		const selectedRates = Rates.filter((rate) =>
			seriesData.EventSeriesRates.some(
				(er) => er.RateID === rate.value && er.SiteID === site.value
			)
		);

		return {
			selectedSite: site,
			selectedSpaces,
			selectedRates,
		};
	});
};

export const constructWizard = (mode) => {
	switch (mode) {
		case EVENT_WIZARD_MODES.ADD:
			return {
				initialStep: 0,
				steps: [CREATE_SERIES_STEP, CREATE_EVENTS_STEP, CREATE_SUMMARY_STEP],
			};
		case EVENT_WIZARD_MODES.EDIT:
			return {
				initialStep: 2,
				steps: [CREATE_SERIES_STEP, CREATE_EVENTS_STEP, CREATE_SUMMARY_STEP],
			};
		case EVENT_WIZARD_MODES.CANCEL:
			return {
				initialStep: 0,
				steps: [CANCEL_EVENT_STEP],
			};
		default:
			console.error("Invalid mode on EventsWizard");
			return null;
	}
};

export const initializeValues = (initialData, props, eventToCancel) => [
	{ name: "events", value: initialData.Events, validator: EventSchema },
	{
		name: "seriesName",
		value: initialData.Name,
		validator: validationSchema.seriesName,
	},
	{
		name: "reset",
		value: initialData.ResetTime,
		validator: validationSchema.reset,
	},
	{
		name: "selectedBranding",
		value: initialData.Brand,
		validator: validationSchema.selectedBranding,
	},
	{ name: "selectedGroups", value: initialData.Groups },
	{ name: "seriesId", value: props.seriesId ?? null },
	{ name: "eventToCancel", value: eventToCancel ?? null },
	{ name: "availableRates", value: props.availableRates },
	{
		name: "siteSpaceRates",
		value: initialData.SiteSpaceRates,
		validator: validationSchema.siteSpaceRates,
	},
	{ name: "conflictRateErrorMessage", value: null },
];

export const handleSubmit = async (
	values,
	setSubmitting,
	upsertEventSeriesAndEvents,
	props
) => {
	try {
		const formattedSiteSpaceRates = values.siteSpaceRates.map(
			(siteSpaceRate) => ({
				selectedSite: siteSpaceRate.selectedSite.value,
				selectedSpaces: siteSpaceRate.selectedSpaces.map(
					(space) => space.value
				),
				selectedRates: siteSpaceRate.selectedRates.map((rate) => rate.value),
			})
		);

		setSubmitting(true);
		await upsertEventSeriesAndEvents({
			variables: {
				name: values.seriesName || "",
				brandId: values.selectedBranding?.value || null,
				events:
					values.events?.map((e) => ({
						Name: e.name || "",
						EventID: e.EventID || null,
						StartTime: moment(e.startTime).format("YYYYMMDDHHmm"),
						EndTime: moment(e.endTime).format("YYYYMMDDHHmm"),
					})) || [],
				groupIds: values.selectedGroups?.map((s) => s.value) || [],
				siteSpaceRates: formattedSiteSpaceRates,
				resetTime: parseInt(values.reset ?? 120, 10),
				eventSeriesId: values.seriesId || null,
			},
		});
		let successMessage;
		switch (props.mode) {
			case EVENT_WIZARD_MODES.CANCEL:
				successMessage = "Event Series Cancelled!";
				break;
			case EVENT_WIZARD_MODES.EDIT:
				successMessage = "Event Series Updated!";
				break;
			default:
				successMessage = "Event Series Created!";
				break;
		}
		Alert.success(successMessage);
		props.close(true);
	} catch (error) {
		Alert.error(
			props.mode === EVENT_WIZARD_MODES.CANCEL
				? "Failed to cancel event"
				: "Failed to create event series"
		);
	} finally {
		setSubmitting(false);
	}
};
