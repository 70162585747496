import { StepText, StepTitle } from "../WizardLayout";
import Button from "../../layout/Button";
import CodeSnippet from "../../layout/CodeSnippet";
import Dropdown from "../../layout/Dropdown";
import Input from "../../layout/Input";
import React from "react";
import WizardNavigation from "../WizardNavigation";
import styled from "styled-components";
import MultiChoiceButton from "../MultiChoiceButton";
import {
	useGetAllEventSeries,
	useGetAllEventsBySeries,
	useGetGroupsForEventSeries,
} from "../../../containers/organization/queries/eventsApiHooks";
import LoadingPlaceholder from "../../report/LoadingPlaceholder";
import { colours } from "../../../styles";
import TableLayout from "../../layout/TableLayout";

const Label = styled.div`
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 16px;
	margin-top: 16px;
`;

const Spacer = styled.div`
	height: 16px;
`;

const Wrapper = styled.div`
	a {
		color: ${colours.blue};
		text-decoration: none;
		transition: 0.2s ease;

		&:hover {
			cursor: pointer;
			opacity: 0.8;
		}
	}
`;

const schema = [
	{ name: "Plate", required: true },
	{ name: "Description", required: true },
];

function EventSelector(props) {
	const { eventSeries, isLoading } = useGetAllEventSeries(true);

	const { data: eventsData, isLoading: eventsLoading } =
		useGetAllEventsBySeries(props.values.eventSeries?.value, true);

	const { data: groupsData, isLoading: groupsLoading } =
		useGetGroupsForEventSeries(props.values.eventSeries?.value);

	let groups = groupsData?.getGroupsForEventSeries;

	if (props.onlyAllowWhitelistedPlates) {
		// Filter out all groups that don't have the flag AllowWhitelistedPlates set to true
		groups = groups?.filter((group) => group.AllowWhitelistedPlates);
	}

	const groupOptions = groups?.map(({ OrganizationAccessGroupID, Name }) => ({
		value: OrganizationAccessGroupID,
		label: Name,
	}));

	const eventSeriesOptions = eventSeries?.map(({ EventSeriesID, Name }) => ({
		value: EventSeriesID,
		label: Name,
	}));

	const eventOptions = eventsData?.getAllEventsBySeries?.map(
		({ EventID, Name }) => ({
			value: EventID,
			label: Name,
		})
	);

	return (
		<div>
			{isLoading ? (
				<LoadingPlaceholder />
			) : (
				<>
					<Label>Choose an event series:</Label>
					<Dropdown
						options={eventSeriesOptions}
						value={props.values.eventSeries}
						onChange={(value) => {
							props.setFieldValue("eventSeries", value);
							props.setFieldValue("event", null);
							props.setFieldValue("eventGroup", null);
						}}
					/>
				</>
			)}

			{!isLoading && props.values.eventSeries ? (
				<>
					{eventsLoading || groupsLoading ? (
						<LoadingPlaceholder />
					) : (
						<>
							<Spacer />
							<Label>Event to add users to:</Label>
							<Dropdown
								options={eventOptions}
								value={props.values.event}
								onChange={(value) => {
									props.setFieldValue("event", value);
									props.setFieldValue("eventGroup", null);
								}}
							/>

							<Spacer />
							<Label>Group to add users to:</Label>
							<Dropdown
								isDisabled={!props.values.event}
								options={groupOptions}
								value={props.values.eventGroup}
								onChange={(value) => {
									props.setFieldValue("eventGroup", value);
								}}
							/>
						</>
					)}
				</>
			) : null}
		</div>
	);
}

export const importTypeStep = ({
	goTo,
	keyStrokeHandler,
	setFieldValue,
	wizardProps,
}) => ({
	id: "importType",
	label: "Import Type",
	render: () => (
		<div>
			<StepTitle>What type of import is this?</StepTitle>
			<MultiChoiceButton
				selected={wizardProps.mode === "users"}
				onClick={() => {
					wizardProps.setMode("users");
					setFieldValue("eventSeries", null);
					setFieldValue("event", null);
					setFieldValue("eventGroup", null);
				}}
			>
				Users
			</MultiChoiceButton>
			<MultiChoiceButton
				selected={wizardProps.mode === "plates"}
				onClick={() => {
					wizardProps.setMode("plates");
					setFieldValue("eventSeries", null);
					setFieldValue("event", null);
					setFieldValue("eventGroup", null);
				}}
			>
				Plates
			</MultiChoiceButton>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (wizardProps.mode === "users") {
							goTo("actionType");
						} else {
							goTo("platesImport");
						}
					}}
					keyStrokeHandler={keyStrokeHandler}
				>
					Next
				</Button>,
			]}
		/>
	),
});

export const actionTypeStep = ({
	values,
	setFieldValue,
	goTo,
	previous,
	keyStrokeHandler,
}) => ({
	id: "actionType",
	label: "Action Type",
	render: () => (
		<div>
			<StepTitle>
				Should the import add to or replace the existing users?
			</StepTitle>
			<MultiChoiceButton
				selected={values.actionType === "add"}
				onClick={() => {
					setFieldValue("actionType", "add");
				}}
			>
				Add
			</MultiChoiceButton>
			<MultiChoiceButton
				selected={values.actionType === "replace"}
				onClick={() => {
					setFieldValue("actionType", "replace");
				}}
			>
				Replace
			</MultiChoiceButton>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" onClick={previous} color="blue">
					Back
				</Button>,
			]}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						goTo("usersImport");
					}}
					keyStrokeHandler={keyStrokeHandler}
				>
					Next
				</Button>,
			]}
		/>
	),
});

export const usersImportStep = ({
	handleSubmit,
	isSubmitting,
	setFieldValue,
	values,
	previous,
	goTo,
}) => ({
	id: "usersImport",
	label: "Users Import",
	render: () => {
		return (
			<div>
				<StepTitle>Upload a file and select the event:</StepTitle>

				<Label>File to import (.csv)</Label>
				<Input
					style={{ width: "50%" }}
					type="file"
					name="file"
					accept="text/csv"
					onChange={(event) => setFieldValue("file", event.target.files[0])}
				/>
				<Spacer />

				<div>* The CSV file should be in either of the following formats:</div>
				<CodeSnippet>
					example@mail.com
					<br />
					example2@mail.com
				</CodeSnippet>

				<Spacer />
				<EventSelector values={values} setFieldValue={setFieldValue} />
			</div>
		);
	},
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" onClick={previous} color="blue">
					Back
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={() => {
						goTo("summary");
						handleSubmit();
					}}
					disabled={
						isSubmitting || !values.file || !values.event || !values.eventGroup
					}
				>
					Invite Users
				</Button>,
			]}
		/>
	),
});

export const platesImportStep = ({
	handleSubmit,
	isSubmitting,
	setFieldValue,
	values,
	previous,
	goTo,
}) => ({
	id: "platesImport",
	label: "Plates Import",
	render: () => {
		return (
			<div>
				<StepTitle>Upload a file and select the event:</StepTitle>

				<Label>File to import (.csv)</Label>
				<Input
					style={{ width: "50%" }}
					type="file"
					name="file"
					accept="text/csv"
					onChange={(event) => setFieldValue("file", event.target.files[0])}
				/>
				<Spacer />

				<Wrapper>
					*The file must be in the following format{" "}
					<a
						href={`data:text/csv;charset=utf-8,${encodeURI(
							schema.map((column) => column.name).join(",")
						)}`}
						download="format.csv"
					>
						CSV format
					</a>
				</Wrapper>

				<Spacer />
				<EventSelector
					values={values}
					setFieldValue={setFieldValue}
					onlyAllowWhitelistedPlates={true}
				/>
			</div>
		);
	},
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" onClick={previous} color="blue">
					Back
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={() => {
						goTo("summary");
						handleSubmit();
					}}
					disabled={
						isSubmitting || !values.file || !values.event || !values.eventGroup
					}
				>
					Import Plates
				</Button>,
			]}
		/>
	),
});

export const removeStep = ({ handleSubmit, wizardProps }) => ({
	id: "remove",
	label: "Remove",
	render: () => (
		<div>
			<StepText>
				Are you sure you want to remove the event pass for{" "}
				{wizardProps.eventPass.Email || wizardProps.eventPass.Numberplate}? This
				action CANNOT be undone.
			</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button key="submit" color="red" onClick={handleSubmit}>
					Remove
				</Button>,
			]}
		/>
	),
});

export const summaryStep = ({
	wizardProps: { mode, uploadResults, close },
	values,
}) => ({
	id: "summary",
	label: "Summary",
	render: () => {
		const inputType = mode === "users" ? "user(s)" : "plate(s)";
		const actionType = values.actionType === "add" ? "Add" : "Replace";
		const emails = uploadResults?.emails ?? [];
		const invalidRows = uploadResults?.invalidRows ?? [];
		const plates = uploadResults?.plates ?? [];
		return (
			<div>
				<StepTitle>
					{actionType} {mode} upload results
				</StepTitle>

				{emails && emails.length > 0 && (
					<>
						<Label>
							{emails.length} {inputType} successfully added.
						</Label>
						<TableLayout
							data={emails}
							columns={[
								{
									id: "Email",
									Header: "Added Users",
									accessor: (d) => d,
								},
							]}
							sortable={false}
							resizable={true}
							verticalScroll={true}
						/>
					</>
				)}

				{plates && plates.length > 0 && (
					<>
						<Label>
							{plates.length} {inputType} successfully added.
						</Label>
						<TableLayout
							data={plates}
							columns={[
								{
									id: "Plate",
									Header: "Added Plates",
									accessor: (d) => d,
								},
							]}
							sortable={false}
							resizable={false}
							verticalScroll={true}
						/>
					</>
				)}

				<Spacer />
				{invalidRows && invalidRows.length > 0 && (
					<>
						<Label>
							{invalidRows.length} error(s) occurred during the upload. Please
							review.
						</Label>
						<TableLayout
							data={invalidRows}
							columns={[
								{
									id: "Invalid",
									Header: `Invalid ${mode}`,
									accessor: (d) => d,
								},
							]}
							sortable={false}
							resizable={true}
							verticalScroll={true}
						/>
					</>
				)}
			</div>
		);
	},
	footer: () => (
		<WizardNavigation
			rightItems={[
				<Button key="close" color="blue" onClick={close}>
					Done
				</Button>,
			]}
		/>
	),
});
