import AddressLookup from "./AddressLookup";
import { Field } from "formik";
import React from "react";

export default function AddressLookupField(props) {
	return (
		<Field
			type="text"
			{...props}
			render={({ field, form: { setFieldValue } }) => {
				return (
					<AddressLookup
						{...field}
						{...props}
						onChange={({ address }) => setFieldValue(props.name, address)}
					/>
				);
			}}
		/>
	);
}
