import * as installationApi from "../../../api/installation";
import { StepText } from "../WizardLayout";
import { cloneDeep } from "lodash";
import Dropdown from "../../layout/Dropdown";
import React from "react";

import { useFetchData } from "../../../hooks";

export function ChooseValidationRate(props) {
	const { data: rates, isLoading } = useFetchData(
		[],
		installationApi.getCurrentRatesByOrganizationId,
		[props.organizationId],
		[]
	);

	const getRatesOptions = (siteId, _rates) => {
		let options = [];

		_rates
			.filter((rate) => rate.IsValidationRate && rate.SiteID === siteId)
			.forEach((rate) =>
				options.push({
					value: rate.RateID,
					label: `${rate.RateID} - ${rate.Name}`,
				})
			);
		return options;
	};

	const handleValueChange = (value, key) => {
		const _sites = cloneDeep(props.validationSites);
		_sites[key].id = _sites[key].id || _sites[key].SiteID;
		_sites[key].rateId = value;

		return _sites;
	};

	if (props.validationSite?.id) {
		const options = getRatesOptions(props.validationSite.id, rates);

		return (
			<div>
				{!isLoading && (
					<div style={{ marginTop: "15px" }}>
						<StepText>{props.validationSite.name}</StepText>
						{options.length ? (
							<div style={{ marginTop: "5px" }}>
								<Dropdown
									options={options}
									value={options.find(
										(e) => e.value === props.validationSite.rateId
									)}
									isDisabled={options.length === 0}
									onChange={(value) => {
										props.handleChange({
											target: {
												name: "rateId",
												value: value.value,
											},
										});
									}}
								/>
							</div>
						) : (
							<p>
								No validation rate available. Please add a validation rate in{" "}
								the Rates page or remove the site from this beacon.
							</p>
						)}
					</div>
				)}
			</div>
		);
	}

	return (
		<div>
			{!isLoading &&
				props.validationSites?.map((beaconSite, key) => {
					const options = getRatesOptions(beaconSite.id, rates);
					return (
						<div key={key} style={{ marginTop: "15px" }}>
							<StepText>{beaconSite.name}</StepText>
							{options.length ? (
								<div style={{ marginTop: "5px" }}>
									<Dropdown
										options={options}
										value={options.find((e) => e.value === beaconSite.rateId)}
										isDisabled={options.length === 0}
										onChange={(value) => {
											props.handleChange({
												target: {
													name: "validationSites",
													value: handleValueChange(value.value, key),
												},
											});
										}}
									/>
								</div>
							) : (
								<p>
									No validation rate available. Please add a validation rate in{" "}
									the Rates page or remove the site from this beacon.
								</p>
							)}
						</div>
					);
				})}
		</div>
	);
}
