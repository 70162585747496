import { StepTitle } from "../../../WizardLayout";
import WizardNavigation from "../../../WizardNavigation";
import SummaryTable from "../../../SummaryTable";
import Button from "../../../../layout/Button";
import moment from "moment";
import AlertMessage from "../../../../layout/AlertMessage";
import Alert from "react-s-alert";
import { defaultTimeFormat } from "../../../../../helpers/constants";

export const CREATE_SUMMARY_STEP = ({
	goTo,
	isSubmitting,
	setFieldValue,
	values,
	handleSubmit,
	keyStrokeHandler,
	previous,
	wizardProps,
	validateForm,
}) => {
	const submitButtonLabel =
		wizardProps?.mode === "EDIT" ? "Update Series" : "Add Series";
	return {
		id: "summary",
		label: "Summary",
		render: () => (
			<div>
				{values.conflictRateErrorMessage &&
					values.conflictRateErrorMessage.length > 0 && (
						<AlertMessage
							key={values.conflictRateErrorMessage[0]}
							text={`${values.conflictRateErrorMessage[0]} (and ${values.conflictRateErrorMessage.length} more conflicts)`}
							borderColor="red"
							backgroundColor="red"
							textColor="white"
						/>
					)}
				<StepTitle>Summary</StepTitle>
				<SummaryTable
					items={[
						{
							title: "Series",
							value: values.seriesName,
							key: "seriesName",
							edit: () => {
								setFieldValue("editFromSummary", true);
								goTo("create-series");
							},
						},
						{
							title: "Branding",
							value: values.selectedBranding?.label,
							key: "branding",
							edit: () => {
								setFieldValue("editFromSummary", true);
								goTo("create-series");
							},
						},
						{
							title: "Sites",
							value:
								values.siteSpaceRates
									?.map((entry) => entry.selectedSite?.label)
									.filter((label) => label)
									.join(", ") || "No sites selected",
							key: "sites",
							edit: () => {
								setFieldValue("editFromSummary", true);
								goTo("create-series");
							},
						},
						{
							title: "Spaces",
							value:
								values.siteSpaceRates
									?.flatMap((entry) =>
										entry.selectedSpaces?.map((space) => space.label)
									)
									.filter((label) => label)
									.join(", ") || "No spaces selected",
							key: "spaces",
							edit: () => {
								setFieldValue("editFromSummary", true);
								goTo("create-series");
							},
						},
						{
							title: "Rates",
							value:
								values.siteSpaceRates
									?.flatMap((entry) =>
										entry.selectedRates?.map((rate) => rate.label)
									)
									.filter((label) => label)
									.join(", ") || "No rates selected",
							key: "rates",
							edit: () => {
								setFieldValue("editFromSummary", true);
								goTo("create-series");
							},
						},
						{
							title: "Group Access",
							value: values.selectedGroups?.map((s) => s.label)?.join(" , "),
							key: "groupAccess",
							edit: () => {
								setFieldValue("editFromSummary", true);
								goTo("create-series");
							},
						},
						{
							title: "Reset",
							value: values.reset,
							key: "reset",
							edit: () => {
								setFieldValue("editFromSummary", true);
								goTo("create-series");
							},
						},
						{
							title: "Events",
							value: values.events?.map((event) => {
								const start = moment(event.startTime).format(defaultTimeFormat);
								const end = moment(event.endTime).format(defaultTimeFormat);
								return (
									<div key={event.name}>
										<strong>{event.name}</strong>
										<br />
										{`Start: ${start}`}
										<br />
										{`End: ${end}`}
									</div>
								);
							}),
							key: "events",
							edit: () => {
								setFieldValue("editFromSummary", true);
								goTo("create-events");
							},
						},
					]}
				/>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={[
					<Button key="previous" color="blue" onClick={previous}>
						Back
					</Button>,
				]}
				rightItems={[
					<Button
						key="submit"
						onClick={async () => {
							const errors = await validateForm();

							const extractErrors = (obj) => {
								let errorMessages = "";
								for (const key in obj) {
									if (typeof obj[key] === "object") {
										errorMessages += extractErrors(obj[key]);
									} else {
										errorMessages += `${obj[key]}`;
									}
								}
								return errorMessages;
							};

							if (Object.keys(errors).length > 0) {
								Alert.error(extractErrors(errors));
							} else {
								handleSubmit();
							}
						}}
						color={"green"}
						disabled={isSubmitting}
						keyStrokeHandler={keyStrokeHandler}
					>
						{submitButtonLabel}
					</Button>,
				]}
			/>
		),
	};
};
