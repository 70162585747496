import React from "react";
import { Label, FieldContent } from "../../styles";
import Dropdown from "../../../../layout/Dropdown";
import LoadingPlaceholder from "../../../../report/LoadingPlaceholder";
import FieldError from "../../../../layout/FieldError";
import { useGetAllEventOrgAccessGroups } from "./data";

export const SeriesGroupAccess = ({
	orgId,
	selectedGroups,
	onChange,
	error,
}) => {
	const { organizationAccessGroups, isLoading } =
		useGetAllEventOrgAccessGroups(orgId);

	if (isLoading) {
		return <LoadingPlaceholder />;
	}

	const groups = organizationAccessGroups.map((o) => ({
		value: o.OrganizationAccessGroupID,
		label: o.Name,
	}));

	return (
		<div>
			<Label>Series Group Access</Label>
			<FieldContent>
				<Dropdown
					isMulti={true}
					options={groups}
					value={selectedGroups}
					onChange={onChange}
				/>
			</FieldContent>
			<FieldError showError={error} errorText={error} />
		</div>
	);
};
