import { AUTHORIZATION_TYPES } from "../../../helpers/constants";
import { StepText, StepTitle } from "../WizardLayout";
import Button from "../../layout/Button";
import React from "react";
import WizardNavigation from "../WizardNavigation";
import FlexWrapper from "../../layout/FlexWrapper";
import Input from "../../layout/Input";
import SummaryTable from "../SummaryTable";
import { trimStart } from "lodash";
import Dropdown from "../../layout/Dropdown";
import { getRandomBytes } from "../../../helpers/crypto";
import Toggle from "../../layout/Toggle";

export const cachePeriodStep = ({
	close,
	values,
	handleSubmit,
	isSubmitting,
	keyStrokeHandler,
	setFieldValue,
}) => ({
	id: "cachePeriod",
	label: "cachePeriod",
	render: () => (
		<div>
			<StepText>Specify the cache expiry period in minutes</StepText>
			<Input
				type="number"
				min="1"
				max="1000000"
				name="cachePeriod"
				onChange={(event) => setFieldValue("cachePeriod", event.target.value)}
				value={values.cachePeriod}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					Next
				</Button>,
			]}
		/>
	),
});

export const authStep = ({
	values,
	setFieldValue,
	goTo,
	isSubmitting,
	keyStrokeHandler,
	close,
	next,
}) => {
	const authOptions = [
		{ value: AUTHORIZATION_TYPES.API_KEY, label: "API Key" },
		{ value: AUTHORIZATION_TYPES.BASIC, label: "Basic" },
	];

	return {
		id: "authorizationType",
		label: "Authorization",
		render: () => (
			<div>
				<StepText style={{ marginBottom: "20px" }}>Authorization Type</StepText>
				<Dropdown
					name="authorizationType"
					options={authOptions}
					value={authOptions.find(
						(auth) => auth.value === values.authorizationType
					)}
					onChange={(value) => {
						if (value?.value === AUTHORIZATION_TYPES.BASIC) {
							if (!values.basicAuthUsername) {
								setFieldValue("basicAuthUsername", getRandomBytes(6));
							}

							if (!values.basicAuthPassword) {
								setFieldValue("basicAuthPassword", getRandomBytes(6));
							}
						}
						setFieldValue("authorizationType", value?.value);
					}}
				/>
				{values.authorizationType === AUTHORIZATION_TYPES.BASIC && (
					<>
						<StepText style={{ marginTop: "20px" }}>Username</StepText>
						<Input
							type="text"
							name="basicAuthUsername"
							value={values.basicAuthUsername || ""}
							onChange={(event) => {
								const username = trimStart(event.target.value);
								setFieldValue("basicAuthUsername", username);
							}}
						/>
						<div style={{ marginTop: "20px" }}>
							<div
								style={{
									display: "flex",
									gap: "12px",
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
								}}
							>
								<StepText>Password</StepText>
								<div
									style={{
										display: "flex",
										gap: "12px",
										flexDirection: "row",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<div style={{ fontWeight: "bold" }}>Show Password</div>
									<Toggle
										onChange={() =>
											setFieldValue(
												"showBasicAuthPassword",
												!values.showBasicAuthPassword
											)
										}
										checked={values.showBasicAuthPassword}
										style={{ width: "100px" }}
									/>
								</div>
							</div>
							<div>
								<Input
									type={values.showBasicAuthPassword ? "text" : "password"}
									name="basicAuthPassword"
									value={values.basicAuthPassword || ""}
									onChange={(event) => {
										const password = event.target.value;
										setFieldValue("basicAuthPassword", password);
									}}
								/>
							</div>
						</div>
					</>
				)}
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={[
					<Button key="cancel" color="blue" onClick={close}>
						Cancel
					</Button>,
				]}
				rightItems={[
					<Button
						key="submit"
						color="blue"
						disabled={
							isSubmitting ||
							!values.authorizationType ||
							(values.authorizationType === AUTHORIZATION_TYPES.BASIC &&
								(!values.basicAuthUsername || !values.basicAuthPassword))
						}
						keyStrokeHandler={keyStrokeHandler}
						onClick={() => {
							if (values.editFromSummary) {
								goTo("summary");
							} else {
								next();
							}
						}}
					>
						{values.editFromSummary ? "Review" : "Next"}
					</Button>,
				]}
			/>
		),
	};
};

export const summaryStep = ({
	goTo,
	handleSubmit,
	isSubmitting,
	setFieldValue,
	values,
	keyStrokeHandler,
}) => ({
	id: "summary",
	label: "Summary",
	render: () => (
		<div>
			<StepTitle>Summary</StepTitle>
			<SummaryTable
				items={[
					{
						title: "Secret",
						value: values.email,
						key: "secret",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("assignToUser");
						},
					},
					{
						title: "Space",
						value: values.bay.ParkName,
						key: "space",
					},
					{
						title: "Bay",
						value: values.bay.Name,
						key: "bayName",
					},
				]}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					Assign Bay
				</Button>,
			]}
		/>
	),
});

export const toggleStep =
	(isEnabled) =>
	({ handleSubmit, isSubmitting, wizardProps }) => ({
		id: "toggle",
		label: "Enable",
		render: () => (
			<div>
				<StepText>
					Are you sure you want to {isEnabled ? "disable" : "enable"} this api
					key?
				</StepText>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={[
					<Button key="cancel" color="blue" onClick={wizardProps.close}>
						Cancel
					</Button>,
				]}
				rightItems={[
					<Button
						key="submit"
						color={isEnabled ? "yellow" : "green"}
						onClick={handleSubmit}
						disabled={isSubmitting}
					>
						{isEnabled ? "Disable" : "Enable"}
					</Button>,
				]}
			/>
		),
	});

export const deleteStep = ({ handleSubmit, isSubmitting, wizardProps }) => ({
	id: "delete",
	label: "Delete",
	render: () => (
		<div>
			<StepText>Are you sure you want to delete this api key?</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={isSubmitting}
				>
					Delete
				</Button>,
			]}
		/>
	),
});

export const showApiKey = ({ handleSubmit, isSubmitting, wizardProps }) => ({
	id: "showApiKey",
	label: "API Key",
	render: () => (
		<div>
			<StepTitle>API Key</StepTitle>
			<StepText>
				Please store it in a secure place as after you leave this page, you will
				not be able to see it again.
			</StepText>

			<FlexWrapper>
				<div style={{ fontWeight: "bold" }}>API Key</div>
				<div>{wizardProps.rawApiKey}</div>
			</FlexWrapper>
		</div>
	),
	footer: () => (
		<WizardNavigation
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={isSubmitting}
				>
					Close
				</Button>,
			]}
		/>
	),
});

export const downloadDocumentationStep = ({
	handleSubmit,
	isSubmitting,
	wizardProps,
	setFieldValue,
	values,
}) => ({
	id: "download-documentation",
	label: "Download Documentation",
	render: () => (
		<div>
			<StepText>Please enter your api key</StepText>
			<Input
				type="text"
				name="api key"
				onChange={(event) => setFieldValue("apiKey", event.target.value)}
				value={values.apiKey}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={isSubmitting}
				>
					Download
				</Button>,
			]}
		/>
	),
});
