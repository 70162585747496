import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
	position: fixed;
	right: 0;
	bottom: 0;
	width: 100%;
	background: white;
	border-top: 1px solid #e8e8e8;
`;

const FlexRight = styled.div`
	display: flex;
	width: 100%;
	justify-content: end;
	gap: 16px;
	padding: 20px 64px;
`;

export default function FixedFooterBox(props) {
	return (
		<Wrapper>
			<FlexRight>{props.children}</FlexRight>
		</Wrapper>
	);
}
