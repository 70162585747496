import * as webPayApi from "../../api/web-pay";
import React, { useState } from "react";
import {
	Redirect,
	Route,
	Switch,
} from "react-router-dom/cjs/react-router-dom.min";
import Accordion from "../../components/layout/Accordion";
import Button from "../../components/layout/Button";
import Card from "../../components/layout/Card";
import Dropdown from "../../components/layout/Dropdown";
import ErrorBoundary from "../ErrorBoundary";
import Input from "../../components/layout/Input";
import PageTitle from "../../components/layout/PageTitle";
import SignageQRCodes from "../../components/report/signage/SignageQRCodes";
import SignageQRCodesHistory from "../../components/report/signage/SignageQRCodesHistory";
import TableLayoutBeta from "../../components/layout/TableLayoutBeta";
import gql from "graphql-tag";
import { useQueryData } from "../../hooks";
import { cloneDeep } from "lodash";
import Alert from "react-s-alert";

async function downloadZoneQR(site, codeOnly) {
	const webPayType =
		site.selectedWebPayConfigDomain?.value ||
		site.WebPayConfig?.Domains?.[0]?.Type;

	const newSec = cloneDeep(site?.selectSecurity);

	const security = newSec?.reduce((obj, item) => {
		Object.assign(obj, { [item.value]: true });
		return obj;
	}, {});

	const format = site.selectedFormat ? site.selectedFormat.value : "svg";
	const uniqueCode = site.uniqueCode;

	const { error } = await webPayApi.downloadZoneQrPdfUrl({
		siteId: site?.SiteID,
		webPayType,
		codeOnly,
		format,
		security,
		uniqueCode,
	});

	if (error) {
		Alert.error(
			`Could not download ${codeOnly ? "QR Code" : "QR Code Signage"}, ${
				error.message
			}`
		);
	}
}

const sitesColumns = (state, setState) => {
	return [
		{
			id: "siteName",
			Header: "Site Name",
			accessor: "Name",
			fixedWidth: 150,
		},
		{
			id: "domains",
			Header: "Domain(s)",
			accessor: (site) => {
				const options = site.WebPayConfig.Domains.map((d) => ({
					value: d.Type,
					label: d.Domain,
				}));

				const index = state.findIndex((s) => s.SiteID === site.SiteID);

				return (
					<Dropdown
						menuPortalTarget={document.body}
						styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
						options={options}
						value={state[index]?.selectedWebPayConfigDomain}
						onChange={(value) => {
							setState((_state) => {
								const newState = cloneDeep(_state);
								Object.assign(newState[index], {
									selectedWebPayConfigDomain: value,
								});

								return newState;
							});
						}}
					/>
				);
			},
			fixedWidth: 250,
		},
		{
			id: "format",
			Header: "Format",
			accessor: (site) => {
				const options = [
					{
						value: "svg",
						label: "SVG",
					},
					{
						value: "pdf",
						label: "PDF",
					},
				];

				const index = state.findIndex((s) => s.SiteID === site.SiteID);

				return (
					<Dropdown
						menuPortalTarget={document.body}
						styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
						options={options}
						value={state[index]?.selectedFormat}
						onChange={(value) => {
							setState((_state) => {
								const newState = cloneDeep(_state);
								Object.assign(newState[index], { selectedFormat: value });

								return newState;
							});
						}}
					/>
				);
			},
			fixedWidth: 250,
		},
		{
			id: "security",
			Header: "Security",
			accessor: (site) => {
				const options = [
					{
						value: "color",
						label: "Colour",
					},
					{
						value: "logo",
						label: "Logo",
					},
				];

				const index = state.findIndex((s) => s.SiteID === site.SiteID);

				return (
					<Dropdown
						isMulti={true}
						menuPortalTarget={document.body}
						styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
						value={state[index]?.selectSecurity}
						options={options}
						onChange={(values) => {
							setState((_state) => {
								const newState = cloneDeep(_state);
								Object.assign(newState[index], {
									selectSecurity: values,
								});

								return newState;
							});
						}}
					/>
				);
			},
			fixedWidth: 250,
		},
		{
			id: "uniqueCode",
			Header: "Unique Code (optional)",
			accessor: (site) => {
				const index = state.findIndex((s) => s.SiteID === site.SiteID);
				return (
					<Input
						type="text"
						value={state[index]?.uniqueCode}
						onChange={(e) => {
							setState((_state) => {
								const newState = cloneDeep(_state);
								Object.assign(newState[index], {
									uniqueCode: e.target.value,
								});

								return newState;
							});
						}}
					/>
				);
			},
			fixedWidth: 150,
		},
		{
			id: "signage",
			Header: "",
			accessor: (site) => {
				const index = state.findIndex((s) => s.SiteID === site.SiteID);
				return (
					<Button
						color="blue"
						style={{ width: 280 }}
						onClick={() => downloadZoneQR(state[index], false)}
					>
						Download QR Signage
					</Button>
				);
			},
			fixedWidth: 300,
		},
		{
			id: "qrCode",
			Header: "",
			accessor: (site) => {
				const index = state.findIndex((s) => s.SiteID === site.SiteID);
				return (
					<Button
						color="blue"
						style={{ width: 280 }}
						onClick={() => downloadZoneQR(state[index], true)}
					>
						Download QR Code
					</Button>
				);
			},
			fixedWidth: 280,
		},
	];
};

export default function SignageContainer(props) {
	const organizationId = props.selectedOrganization.OrganizationID;

	const [qrCode, setQRCode] = useState(null);
	const [sinage, setSinage] = useState(null);

	const {
		data: { getWebPayEnabledSitesForOrganization },
		isLoading,
		// refetch: refetchWebPayEnabledSites,
	} = useQueryData(
		gql`
			query ($organizationId: Int!) {
				getWebPayEnabledSitesForOrganization(organizationId: $organizationId) {
					SiteID
					Name
					WebPayConfig {
						Domains {
							Type
							Domain
						}
					}
				}
			}
		`,
		{ organizationId }
	);

	if (!isLoading && !qrCode && !sinage) {
		setQRCode(getWebPayEnabledSitesForOrganization);
		setSinage(getWebPayEnabledSitesForOrganization);
	}

	return (
		<ErrorBoundary>
			<Switch>
				<Route
					exact
					path={`${props.match.url}/generate`}
					render={() => (
						<div>
							<PageTitle>QR Codes</PageTitle>
							<Card>
								<Accordion
									title="Sites with QR code payment enabled"
									expanded={true}
								>
									<TableLayoutBeta
										data={getWebPayEnabledSitesForOrganization}
										columns={sitesColumns(qrCode, setQRCode).filter(
											(column) => column.id !== "signage"
										)}
										defaultSortBy={[]}
									/>
								</Accordion>
							</Card>
							<PageTitle>Signage</PageTitle>
							<Card>
								<Accordion
									title="Sites with QR code payment enabled"
									expanded={true}
								>
									<TableLayoutBeta
										data={getWebPayEnabledSitesForOrganization}
										columns={sitesColumns(sinage, setSinage).filter(
											(column) => column.id !== "qrCode"
										)}
										defaultSortBy={[]}
									/>
								</Accordion>
							</Card>
						</div>
					)}
				/>
				<Route
					path={`${props.match.url}/unique-qr-codes`}
					render={(_props) => <SignageQRCodes {...props} {..._props} />}
				/>
				<Route
					path={`${props.match.url}/history`}
					render={(_props) => <SignageQRCodesHistory {...props} {..._props} />}
				/>
				<Redirect to={`${props.match.url}/generate`} />
			</Switch>
		</ErrorBoundary>
	);
}
