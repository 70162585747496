import { booleanValueFormatter } from "../../../../components/layout/TableLayout";

export const groupsColumns = [
	{
		id: "Name",
		Header: "Group Name",
		accessor: (d) => d.Name,
		highlightCell: (cellProps) => cellProps.original.Name,
		className: "bold",
	},
	{
		id: "displaySites",
		Header: "Sites",
		accessor: (d) => d.displaySites,
		highlightCell: (props) =>
			props.original.sites.length > 0 ? props.original.displaySites : "",
	},
	{
		id: "accountName",
		Header: "Account",
		accessor: (d) => d.accountName,
		highlightCell: (cellProps) => cellProps.original.accountName,
	},
	{
		id: "displayLeaseRates",
		Header: "Booking Rates",
		accessor: (d) => d.leaseRates,
		highlightCell: (props) =>
			props.original.leaseRates.length > 0
				? props.original.leaseRates
						.map((leaseRate) => leaseRate.Name)
						.join(", ")
				: "",
	},
	{
		id: "locationBasedAccessNodes",
		Header: "Location Based Access Gates",
		accessor: (d) => d.locationBasedAccessNodes,
		highlightCell: (props) =>
			props.original.nodes.length > 0
				? props.original.locationBasedAccessNodes
				: "",
	},
	{
		id: "restrictedAccessNodes",
		Header: "Restricted Access Gates",
		accessor: (d) => d.restrictedAccessNodes,
		highlightCell: (props) =>
			props.original.nodes.length > 0
				? props.original.restrictedAccessNodes
				: "",
	},
	{
		id: "sessionlessAccess",
		Header: "Sessionless Access",
		accessor: (d) => d.ForceSessionlessAccessOnSites,
		Cell: (props) => booleanValueFormatter(props, true),
	},
	{
		id: "overridesOpeningHours",
		Header: "Overrides Opening Hours",
		accessor: (d) => d.OverridesOpeningHours,
		Cell: (props) => booleanValueFormatter(props, true),
	},
	{
		id: "anprAccess",
		Header: "ANPR Access",
		accessor: (d) => d.HasANPRAccess,
		Cell: (props) => booleanValueFormatter(props, true),
	},
	{
		id: "waiveConvenienceFee",
		Header: "Waive Convenience Fee",
		accessor: (d) => d.WaiveConvenienceFee,
		Cell: (props) => booleanValueFormatter(props, true),
	},
	{
		id: "isEnforcementExempt",
		Header: "Exempt from Enforcement",
		accessor: (d) => d.IsEnforcementExempt,
		Cell: (props) => booleanValueFormatter(props, true),
	},
];

export const groupsDefaultSorting = [
	{
		id: "Name",
		desc: false,
	},
];
