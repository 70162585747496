import React from "react";
import { colours } from "../../styles";
import styled from "styled-components";

const Item = styled.div`
	color: ${colours.midGrey};
	font-size: 16px;
	letter-spacing: 0.5px;
	line-height: 32px;
	text-transform: uppercase;
	transition: 0.2s ease;

	.link {
		transition: 0.2s ease;

		&:hover {
			cursor: pointer;
			opacity: 0.8;
		}
	}
`;

export default function Breadcrumb(props) {
	return (
		<Item>
			{props.items.map((item, index) => (
				<span key={index} className="link" onClick={item.onClick}>
					{index !== 0 && item.text && " / "} {item.text}
				</span>
			))}
		</Item>
	);
}
