import { StepTitle } from "./WizardLayout";
import Button from "../layout/Button";
import React from "react";
import WizardNavigation from "./WizardNavigation";
import Input from "../layout/Input";
import Dropdown from "../layout/Dropdown";
import { InputWithVariables } from "./components/InputWithVariables";
import SummaryTable from "./SummaryTable";
import reactStringReplace from "react-string-replace";
import Tooltip from "rc-tooltip";
import Label from "../layout/Label";
import _ from "lodash";
import { GENERIC_INPUT_TYPES } from "../../helpers/constants";
import MultiChoiceButton from "../wizards/MultiChoiceButton";

function toTitle(key) {
	return _.startCase(_.camelCase(key));
}

function toValueName(key) {
	return _.camelCase(key);
}

export const suggestableInputStep =
	(key, label, question) =>
	({ values, setFieldValue, next, goTo, previous }) => {
		return {
			id: key,
			label,
			render: () => (
				<div>
					<StepTitle>{question}</StepTitle>

					<InputWithVariables
						id={key}
						values={values}
						setFieldValue={setFieldValue}
					/>
				</div>
			),
			footer: () => {
				return (
					<WizardNavigation
						leftItems={
							values.editFromSummary
								? []
								: [
										<Button key="submit" color="blue" onClick={previous}>
											Back
										</Button>,
								  ]
						}
						rightItems={[
							<Button
								key="submit"
								color="blue"
								onClick={() => {
									if (values.editFromSummary) {
										goTo("summary");
									} else {
										next();
									}
								}}
								disabled={!values[key]}
							>
								{values.editFromSummary ? "Review" : "Next"}
							</Button>,
						]}
					/>
				);
			},
		};
	};

export const suggestableInputSummaryItem = (
	key,
	title,
	edit,
	customVariables,
	values,
	setFieldValue,
	goTo
) => ({
	title,
	value: (
		<>
			{reactStringReplace(values[key], /{{{(.*?)}}}/, (match) => {
				const variable = customVariables?.find((v) => v.value === match);
				return (
					<Tooltip
						key={key}
						overlay={
							variable?.variableValue ||
							variable?.value ||
							variable?.label ||
							variable?.name
						}
						placement="top"
					>
						<Label
							style={{
								marginRight: "10px",
								fontFamily: "Arial",
								cursor: "pointer",
							}}
							color="green"
						>
							{variable?.value || variable?.label || variable?.name}
						</Label>
					</Tooltip>
				);
			})}
		</>
	),
	key,
	edit: () => {
		setFieldValue("editFromSummary", true);
		goTo(edit || key);
	},
});

export const inputStep = ({
	key,
	question,
	validate = () => true,
	secrifiable,
	suggestable,
	options,
	type,
	...params
}) =>
	suggestable
		? suggestableInputStep(key.toLowerCase(), key, question)
		: ({ values, setFieldValue, next, goTo, previous, skip }) => {
				const getInput = () => {
					switch (type) {
						case GENERIC_INPUT_TYPES.MULTI_SELECT:
						case GENERIC_INPUT_TYPES.SELECT: {
							const isMulti = type === GENERIC_INPUT_TYPES.MULTI_SELECT;

							return (
								<Dropdown
									isMulti={isMulti}
									key={`option${key.toLowerCase()}`}
									placeholder={key}
									value={
										isMulti
											? values[key.toLowerCase()]
											: options.find(
													(o) => o.value === values[key.toLowerCase()]
											  )
									}
									options={options ?? []}
									onChange={(value) => {
										if (params.onChange) {
											params.onChange({ value, values, setFieldValue });
										} else {
											setFieldValue(
												key.toLowerCase(),
												isMulti ? value || [] : value?.value
											);
										}
									}}
								/>
							);
						}
						case GENERIC_INPUT_TYPES.NUMBER:
							return (
								<Input
									type="number"
									useLabel={params.useLabel}
									min={params.min}
									max={params.max}
									name={key.toLowerCase()}
									onChange={(event) =>
										setFieldValue(toValueName(key), event.target.value)
									}
									value={
										_.isNil(values[toValueName(key)])
											? params.min
											: values[toValueName(key)]
									}
								/>
							);
						case GENERIC_INPUT_TYPES.MULTI_CHOICE_BUTTON: {
							return (
								<>
									{options?.map((option) => (
										<MultiChoiceButton
											key={option}
											selected={values[toValueName(key)] === option}
											onClick={() => {
												setFieldValue(toValueName(key), option);
											}}
										>
											{option}
										</MultiChoiceButton>
									))}
								</>
							);
						}
						case GENERIC_INPUT_TYPES.TEXT:
						default:
							return (
								<Input
									key={key.toLowerCase()}
									type={
										values.isSecret === "Yes" && secrifiable
											? "password"
											: "text"
									}
									name={key.toLowerCase()}
									value={values[key.toLowerCase()] || ""}
									onChange={(event) => {
										setFieldValue(key.toLowerCase(), event.target.value);
									}}
								/>
							);
					}
				};

				return {
					id: toValueName(key),
					label: toTitle(key),
					render: () => (
						<div>
							<StepTitle>{question}</StepTitle>

							{getInput()}
						</div>
					),
					footer: () => {
						return (
							<WizardNavigation
								leftItems={
									values.editFromSummary
										? []
										: [
												<Button key="submit" color="blue" onClick={previous}>
													Back
												</Button>,
										  ]
								}
								rightItems={[
									<Button
										key="submit"
										color="blue"
										onClick={() => {
											if (values.editFromSummary) {
												goTo("summary");
											} else {
												if (params.next) {
													params.next({ values, next, skip });
												} else {
													next();
												}
											}
										}}
										disabled={!validate(values[toValueName(key)])}
									>
										{values.editFromSummary ? "Review" : "Next"}
									</Button>,
								]}
							/>
						);
					},
				};
		  };

export const summaryStep =
	(questions) =>
	({
		close,
		goTo,
		handleSubmit,
		isSubmitting,
		setFieldValue,
		values,
		wizardProps,
	}) => {
		const mapSummaryValue = ({ key, secrifiable, getSummaryValue }) => {
			if (getSummaryValue) {
				return getSummaryValue({ values });
			}

			if (secrifiable && values.isSecret === "Yes") {
				return <strong>••••••••••••••••••••</strong>;
			}

			if (Array.isArray(values[toValueName(key)])) {
				return values[toValueName(key)].map((v) => v.label).join(", ");
			}

			return values[toValueName(key)];
		};

		const items = [
			{
				title: "Secret",
				value: values.isSecret,
				key: "secret",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("secret");
				},
			},
			...questions
				.filter((q) => {
					if (q.hideInSummary) {
						return !q.hideInSummary(values);
					} else {
						return true;
					}
				})
				.map(({ key, secrifiable, suggestable, getSummaryValue }) =>
					suggestable
						? suggestableInputSummaryItem(
								key.toLowerCase(),
								key,
								key.toLowerCase(),
								_.flatten(values.variableGroups?.map((grp) => grp.variables)),
								values,
								setFieldValue,
								goTo
						  )
						: {
								title: toTitle(key),
								value: mapSummaryValue({ key, secrifiable, getSummaryValue }),
								key: key.toLowerCase(),
								edit: () => {
									setFieldValue("editFromSummary", true);
									goTo(toValueName(key));
								},
						  }
				),
		];

		return {
			id: "summary",
			label: "Summary",
			render: () => (
				<div>
					<StepTitle>Summary</StepTitle>
					<SummaryTable
						valueStyle={{ whiteSpace: "none" }}
						items={items.filter((i) => i)}
					/>
				</div>
			),
			footer: () => {
				return (
					<WizardNavigation
						leftItems={[
							<Button key="previous" onClick={close} color="blue">
								Cancel
							</Button>,
						]}
						rightItems={[
							<Button
								key="submit"
								color="green"
								onClick={handleSubmit}
								disabled={isSubmitting}
							>
								{wizardProps.mode === "edit" ? "Update" : "Create"}
							</Button>,
						]}
					/>
				);
			},
		};
	};
