import React from "react";
import Dropdown from "../../../../layout/Dropdown";
import { FieldContent } from "../../styles";
import LoadingPlaceholder from "../../../../report/LoadingPlaceholder";
import { useGetAllRates } from "../../../../../containers/organization/queries/eventsApiHooks";

export const Rates = ({ selectedRates, onChange }) => {
	const { data, isLoading } = useGetAllRates();

	if (isLoading) {
		return <LoadingPlaceholder />;
	}

	const rates = data?.map((b) => ({
		value: b.RateID,
		label: b.Name,
	}));

	return (
		<FieldContent>
			<Dropdown
				isMulti={true}
				options={rates}
				value={selectedRates}
				onChange={onChange}
			/>
		</FieldContent>
	);
};
