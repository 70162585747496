import AlertMessage from "../../layout/AlertMessage";
import { StepTitle } from "../WizardLayout";
import Dropdown from "../../layout/Dropdown";
import { getUnitLabel } from "../booking-rate-wizard/helper";

export function GroupStep(props) {
	const {
		organizationAccessGroups,
		leaseParkId,
		selectGroups,
		selectedSites,
		setFieldValue,
		durationType,
		errors,
		isPrivate,
	} = props;
	let options = organizationAccessGroups
		.filter((group) =>
			group.Sites?.every((groupSite) => {
				const isAlreadyConnectedToThisSpace = groupSite.LeaseParks?.some(
					(leasePark) => leasePark.LeaseParkID === leaseParkId
				);
				const isSiteNotSelected = !selectedSites.some(
					(selected) => selected.value === groupSite.SiteID
				);
				const noLeaseParksOnThisSite =
					!groupSite.LeaseParks || groupSite.LeaseParks.length === 0;
				const noSitesGiven = !selectedSites || selectedSites.length === 0;
				return (
					noSitesGiven ||
					isSiteNotSelected ||
					noLeaseParksOnThisSite ||
					isAlreadyConnectedToThisSpace
				);
			})
		)
		.map((group) => ({
			label: group.Name,
			value: group.OrganizationAccessGroupID,
		}));

	const noGroupOption = isPrivate
		? { label: "No Group", value: -1, isFixed: true }
		: { label: "No Group (Public)", value: -1, isFixed: true };

	//Add public as an option - will be defaulted 'on' by logic in the formik data model for non-grouped rates
	options.unshift(noGroupOption);

	return (
		<div>
			<StepTitle>
				Please choose the groups that apply to this{" "}
				{getUnitLabel(durationType, "period", "lower")} booking
			</StepTitle>
			<Dropdown
				isMulti={true}
				options={options}
				value={selectGroups}
				onChange={(value, event) => {
					//If clearing, populate the placeholder group
					if (value === null || event.action === "clear") {
						setFieldValue("organizationAccessGroups", [noGroupOption]);
					}
					//If removing, just allow it
					else if (event.action === "remove-value") {
						setFieldValue("organizationAccessGroups", value);
					}
					//If adding public group, remove all other groups
					else if (event.option.value === -1) {
						setFieldValue("organizationAccessGroups", [noGroupOption]);
					}
					//If adding any other group, remove public if it's selected
					else {
						const privateGroups = value.reduce((arr, val) => {
							if (val.value !== -1) {
								arr.push(val);
							}

							return arr;
						}, []);

						setFieldValue("organizationAccessGroups", privateGroups);
					}
				}}
			/>
			{errors && (
				<AlertMessage
					text={errors}
					borderColor="red"
					backgroundColor="red"
					textColor="white"
					top="30px"
				/>
			)}
		</div>
	);
}
