import * as Yup from "yup";
import * as authenticationApi from "../../api/authentication";
import React, { useCallback, useState } from "react";
import { useFetchData, useQueryData } from "../../hooks";
import Dropdown from "../layout/Dropdown";
import EditableInputField from "../layout/EditableInputField";
import FlexWrapper from "../layout/FlexWrapper";
import SearchBox from "../layout/SearchBox";
import { colours } from "../../styles";
import { debounce } from "lodash";
import gql from "graphql-tag";
import styled from "styled-components";
import { user } from "../../helpers";
import indefinite from "indefinite";

const Status = styled.div`
	height: 16px;
	margin: 16px 0;

	.error {
		color: ${colours.red};
	}
`;

const NameWrapper = styled(FlexWrapper)`
	margin-top: 32px;

	> * {
		flex: 1 1 0;
	}
`;

const schema = Yup.object().shape({
	email: Yup.string()
		.email("Please supply a valid email.")
		.required("Please supply an email."),
});

export default function EmailLookup(props) {
	const [state, setState] = useState({
		email: props.email || "",
		emailValid: !!props.email,
		user: props.user && props.user.UserID ? props.user : null,
		firstName:
			props.user && !props.user.UserID ? props.user.FirstName || null : null,
		lastName:
			props.user && !props.user.UserID ? props.user.LastName || null : null,
		showStatus: !!props.user,
		requestedUser: null,
		userOptions: [],
	});

	const {
		data: { getUsersByEmail },
		isLoading: loadingEmail,
	} = useQueryData(
		gql`
			query ($email: Email!) {
				getUsersByEmail(email: $email) {
					UserID
					Email
					FirstName
					LastName
					Numberplates {
						NumberplateID
						Numberplate
					}
					Client {
						ClientID
						Name
					}
				}
			}
		`,
		{
			email: state.email,
		},
		!state.email || !state.emailValid
	);

	const { data: userDataWithPrivileges, isLoading: loadingUser } = useFetchData(
		{},
		authenticationApi.getUserWithPrivileges,
		state.requestedUser ? [state.requestedUser.UserID] : null,
		state.requestedUser ? [state.requestedUser.UserID] : [null]
	);

	if (
		getUsersByEmail?.length &&
		!state.requestedUser &&
		!state.userOptions.length
	) {
		if (getUsersByEmail.length > 1) {
			if (state.user) {
				setState((_state) => ({
					..._state,
					requestedUser: getUsersByEmail.find(
						(u) => u.UserID === state.user.UserID
					),
					userOptions: getUsersByEmail,
				}));
			} else {
				setState((_state) => ({
					..._state,
					userOptions: getUsersByEmail,
				}));
			}
		} else {
			setState((_state) => ({
				..._state,
				requestedUser: getUsersByEmail[0],
			}));
		}
	}

	if (
		!loadingEmail &&
		state.email &&
		state.requestedUser &&
		userDataWithPrivileges &&
		state.requestedUser.Email === userDataWithPrivileges.Email &&
		state.email === userDataWithPrivileges.Email &&
		(!state.user || state.user.UserID !== userDataWithPrivileges.UserID)
	) {
		// set new user if we have one
		setState((_state) => ({
			..._state,
			user: userDataWithPrivileges || null,
			showStatus: true,
		}));

		props.onChange({
			email: userDataWithPrivileges.Email,
			user: userDataWithPrivileges,
			plates: state.requestedUser.Numberplates,
		});
	} else if (state.email && !state.requestedUser && !state.showStatus) {
		setState((_state) => ({
			..._state,
			user: null,
			showStatus: true,
			requestedUser: null,
			userOptions: [],
		}));
	}

	const lookupUser = useCallback(
		debounce(async (email) => {
			const isValid = await schema.isValidSync({ email });

			setState((_state) => ({
				..._state,
				emailValid: isValid,
			}));
		}, 500),
		[]
	);

	async function onEmailChange(email) {
		const emailFormatted = email.toLowerCase().trim();

		setState((_state) => ({
			..._state,
			email: emailFormatted,
			emailValid: false,
			user: null,
			firstName: null,
			lastName: null,
			showStatus: false,
			requestedUser: null,
			userOptions: [],
		}));

		props.onChange({
			email: emailFormatted,
			user: null,
		});

		lookupUser(emailFormatted);
	}

	async function onNameChange(type, value) {
		setState((_state) => ({ ..._state, [type]: value }));

		props.onChange({
			email: state.email,
			user: {
				Email: state.email,
				FirstName: type === "firstName" ? value : state.firstName,
				LastName: type === "lastName" ? value : state.lastName,
			},
		});
	}

	const isLoading = loadingEmail || loadingUser;

	const userDropdownOptions = state.userOptions.map((u) => ({
		value: u.UserID,
		label: `${u.Email} (${u.Client?.Name ?? ""})`,
	}));

	const clientName = state.requestedUser?.Client?.Name ?? "Inugo";

	return (
		<div>
			<SearchBox
				placeholder="Email address"
				onChange={(value) => {
					onEmailChange(value);
				}}
				value={state.email}
				showLoader={isLoading}
			/>
			{!!state.userOptions.length && (
				<div style={{ marginTop: "16px" }}>
					<div style={{ marginBottom: "10px", fontWeight: 700 }}>
						More than one user was found, please select one:
					</div>
					<Dropdown
						options={userDropdownOptions}
						value={userDropdownOptions.find(
							(o) => o.value === state.requestedUser?.UserID
						)}
						onChange={(option) => {
							const newUserRequested = state.userOptions.find(
								(u) => u.UserID === option.value
							);
							setState((_state) => ({
								..._state,
								requestedUser: newUserRequested,
							}));
						}}
					/>
				</div>
			)}
			{!isLoading &&
				state.showStatus &&
				state.emailValid &&
				!props.customStatus && (
					<Status>
						{!props.allowNew &&
							state.emailValid &&
							!state.user &&
							!state.userOptions.length && (
								<div className="error">
									No user exists with the email {state.email}. Please supply a
									registered user.
								</div>
							)}
						{props.allowNew &&
							state.emailValid &&
							!state.user &&
							!state.userOptions.length && (
								<div>
									No user exists with the email {state.email}. Please enter a
									name:
								</div>
							)}
						{state.user && (
							<div>
								{`${user.fullNameWithEmail(state.user)} has ${indefinite(
									clientName
								)} account.`}
							</div>
						)}
					</Status>
				)}

			{!isLoading &&
				state.showStatus &&
				props.allowNew &&
				state.emailValid &&
				!state.userOptions.length &&
				!state.user &&
				!props.customStatus && (
					<div>
						<NameWrapper>
							<EditableInputField
								type="text"
								name="firstName"
								useLabel="First name"
								onChange={(event) =>
									onNameChange("firstName", event.target.value)
								}
								value={state.firstName || ""}
								error={
									state.firstName !== null && state.firstName.length === 0
										? "Please supply a first name."
										: null
								}
							/>
							<EditableInputField
								type="text"
								name="lastName"
								useLabel="Last name"
								onChange={(event) =>
									onNameChange("lastName", event.target.value)
								}
								value={state.lastName || ""}
								error={
									state.lastName !== null && state.lastName.length === 0
										? "Please supply a last name."
										: null
								}
							/>
						</NameWrapper>
					</div>
				)}

			{props.customStatus && <Status>{props.customStatus}</Status>}
		</div>
	);
}
