import * as billingApi from "../../api/billing";
import * as installationApi from "../../api/installation";
import { MoreHorizontal, Plus } from "react-feather";
import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { filter, find } from "lodash";
import {
	useFetchData,
	useHasPermissionsOnEverySite,
	usePermissions,
	useQueryData,
} from "../../hooks";
import Alert from "react-s-alert";
import BaysContainer from "./BaysContainer";
import Button from "../../components/layout/Button";
import Card from "../../components/layout/Card";
import DropdownMenuBeta from "../../components/layout/DropdownMenuBeta";
import FlexWrapper from "../../components/layout/FlexWrapper";
import ReportWrapperBeta from "../../components/report/ReportWrapperBeta";
import SpaceAccountWizard from "../../components/wizards/space-account-wizard/SpaceAccountWizard";
import SpaceWizard from "../../components/wizards/space-wizard/SpaceWizard";
import StatCard from "../../components/layout/StatCard";
import { booleanValueFormatter } from "../../components/layout/TableLayoutBeta";
import gql from "graphql-tag";
import { user } from "../../helpers";

const spaceColumns = (props) => [
	{
		Header: "Name",
		accessor: "Name",
	},
	{
		Header: "Type",
		accessor: "SpaceType",
	},
	{
		Header: "Signage",
		accessor: "HasSignage",
		Cell: ({ row: { original } }) =>
			booleanValueFormatter(original.HasSignage, true),
	},
	{
		Header: "Total Spaces",
		accessor: "Spaces",
	},
	{
		Header: "Occupancy Priority",
		accessor: (d) => d.OccupancyPriority ?? "",
	},
	{
		Header: "Booked Spaces",
		accessor: "spacesInUse",
		className: "bold",
	},
	{
		Header: "Sites",
		accessor: "siteNames",
	},
	{
		Header: "Accounts",
		accessor: "accountNames",
	},
	{
		Header: "Billing",
		accessor: "billing",
	},
	{
		id: "tasks",
		Header: "",
		accessor: null,
		Cell: ({ row: { original } }) => {
			const canEditLeasePark = useHasPermissionsOnEverySite(
				"EditSettings",
				original.sites
			);

			if (!props.canManageRates || !canEditLeasePark) {
				return null;
			}

			return (
				<DropdownMenuBeta
					triggerContent={<MoreHorizontal size={24} />}
					items={[
						<div
							key="update"
							onClick={() => props.openWizard(original, "update")}
						>
							Edit
						</div>,
						<div
							key="remove"
							disabled={props.hasOccupancy}
							onClick={() => props.openWizard(original, "remove")}
						>
							Remove
						</div>,
					]}
				/>
			);
		},
		fixedWidth: 50,
	},
];

const accountColumns = (props) => [
	{
		Header: "Name",
		accessor: "Name",
		className: "bold",
	},
	{
		Header: "Account Owner",
		accessor: (d) => user.fullNameWithEmail(d.AccountOwnerUser),
	},
	{
		Header: "Number of Parks",
		accessor: "TotalSpaces",
	},
	{
		Header: "Spaces",
		accessor: "spaceNames",
	},
	{
		Header: "Total Users",
		accessor: "TotalUsers",
	},
	{
		id: "tasks",
		Header: "",
		accessor: null,
		Cell: ({ row: { original } }) => {
			const canEditLeasePark = useHasPermissionsOnEverySite(
				"EditSettings",
				original.sites
			);

			if (!props.canManageRates || !canEditLeasePark) {
				return null;
			}

			return (
				<DropdownMenuBeta
					triggerContent={<MoreHorizontal size={24} />}
					items={[
						<div
							key="update"
							onClick={() => props.openWizard(original, "update")}
						>
							Edit
						</div>,
						<div
							key="remove"
							onClick={() => props.openWizard(original, "remove")}
						>
							Remove
						</div>,
					]}
				/>
			);
		},
		fixedWidth: 50,
	},
];

export default function SpaceContainer(props) {
	const [state, setState] = useState({
		initialLoad: true,
		options: { sites: [] },
		spaceWizardOpen: false,
		spaceWizardMode: "update",
		spaceAccountWizardOpen: false,
		spaceAccountWizardMode: "update",
	});

	const organizationId = props.selectedOrganization?.OrganizationID;

	const isAdmin = usePermissions("IsAdmin");
	const canManageLeases = usePermissions(null, "ManageLeases", true);
	const canManageRates = usePermissions(null, "ManageRates", true);
	const canEditSettings = usePermissions(null, "EditSettings", true);

	async function updateOptions({ search, ...options }) {
		setState((_state) => ({ ..._state, initialLoad: false, search, options }));
	}

	const { data: leaseParks, isLoading } = useFetchData(
		[],
		installationApi.getLeaseParksForOrganization,
		[organizationId, state.options],
		[organizationId, ...Object.values(state.options)]
	);

	const { data: billingDetails } = useFetchData(
		[],
		billingApi.getInsensitiveBillingDetailsForOrganization,
		[organizationId],
		[]
	);

	const {
		data: {
			getOrganizationAccessGroups: organizationAccessGroups,
			getSpaceAccounts: spaceAccounts,
		},
		refetch,
	} = useQueryData(
		gql`
			query ($organizationId: Int!) {
				getOrganizationAccessGroups(organizationId: $organizationId) {
					OrganizationAccessGroupID
					OrganizationID
					Name
					OverridesOpeningHours
					ForceSessionlessAccessOnSites
					Sites {
						SiteID
						Name
						LeaseParks {
							LeaseParkID
							Name
						}
					}
				}

				getSpaceAccounts(organizationId: $organizationId) {
					SpaceAccountID
					Name
					OrganizationID
					TotalSpaces
					AccountOwnerUser {
						UserID
						Email
						FirstName
						LastName
					}
					SpaceAllocations {
						SpaceAccountLeaseParkID
						SpaceAccountID
						LeaseParkID
						Spaces
					}
					OrganizationAccessGroups {
						OrganizationAccessGroupID
						OrganizationID
						Name
					}
				}
			}
		`,
		{
			organizationId,
		}
	);

	//calculate stat card values
	const totalParkingSpaces = props.availableSites.reduce((acc, site) => {
		if (
			state.options.sites.length === 0 ||
			state.options.sites.indexOf(site.SiteID) !== -1
		) {
			acc += site.MaximumCapacity;
		}
		return acc;
	}, 0);

	let totalExclusivelyMonthlySpaces = 0;
	let totalMonthlySpaces = 0;
	let totalPrivateSpaces = 0;

	for (let leasePark of leaseParks) {
		if (leasePark.SpaceType === "Private") {
			totalPrivateSpaces += leasePark.Spaces;

			if (leasePark.HasSignage) {
				totalExclusivelyMonthlySpaces += leasePark.Spaces;
			}
		} else {
			totalMonthlySpaces += leasePark.Spaces;

			if (leasePark.HasSignage) {
				totalExclusivelyMonthlySpaces += leasePark.Spaces;
			}
		}
	}

	const totalTransientSpaces =
		totalParkingSpaces - totalExclusivelyMonthlySpaces;

	function openSpaceWizard(leasePark, mode) {
		if (mode === "remove" && leasePark.spacesInUse > 0) {
			Alert.error("This space has active user bookings.");
			return;
		}

		setState((_state) => ({
			..._state,
			spaceWizardOpen: true,
			leasePark: leasePark,
			spaceWizardMode: mode,
		}));
	}

	function openSpaceAccountWizard(spaceAccount, mode) {
		setState((_state) => ({
			..._state,
			spaceAccountWizardOpen: true,
			spaceAccount: spaceAccount,
			spaceAccountWizardMode: mode,
		}));
	}

	function _renderStats() {
		return (
			<FlexWrapper style={{ marginBottom: -16 }}>
				<Card>
					<StatCard
						value={totalParkingSpaces}
						title="Total Parking Spaces"
						size="medium"
					/>
				</Card>

				<Card>
					<StatCard
						value={totalMonthlySpaces}
						title="Total Public Spaces"
						size="medium"
					/>
				</Card>

				<Card>
					<StatCard
						value={totalPrivateSpaces}
						title="Total Private Spaces"
						size="medium"
					/>
				</Card>

				<Card>
					<StatCard
						value={totalTransientSpaces}
						title="Total Transient Spaces"
						size="medium"
					/>
				</Card>
			</FlexWrapper>
		);
	}

	function refetchData() {
		setTimeout(() => {
			refetch();
		}, 300);
	}

	if (state.spaceWizardOpen) {
		return (
			<SpaceWizard
				close={() => {
					setState((_state) => ({ ..._state, spaceWizardOpen: false }));
					refetchData();
				}}
				mode={state.spaceWizardMode}
				leasePark={state.leasePark}
				billingDetails={billingDetails}
				organizationAccessGroups={organizationAccessGroups}
				availableSites={props.availableSites}
				operator={props.selectedOperator}
				organization={props.selectedOrganization}
				history={props.history}
				leaseParks={leaseParks}
			/>
		);
	}

	if (state.spaceAccountWizardOpen) {
		return (
			<SpaceAccountWizard
				close={() => {
					setState((_state) => ({ ..._state, spaceAccountWizardOpen: false }));
					refetchData();
				}}
				mode={state.spaceAccountWizardMode}
				spaceAccount={state.spaceAccount}
				leaseParks={leaseParks}
				organizationAccessGroups={organizationAccessGroups}
				availableSites={props.availableSites}
				organization={props.selectedOrganization}
				history={props.history}
			/>
		);
	}

	const mappedLeaseParkData = leaseParks.map((item) => {
		const billing = find(
			billingDetails,
			(_billing) => _billing.BillingDetailsID === item.BillingDetailsID
		);

		const accounts = filter(spaceAccounts, (account) =>
			account.SpaceAllocations.find(
				(spaceAllocation) => spaceAllocation.LeaseParkID === item.LeaseParkID
			)
		);

		return {
			...item,
			siteNames: item.sites.map((site) => site.Name).join(", "),
			accountNames: accounts.map((e) => e.Name).join(", "),
			billing: billing
				? `${billing.CompanyName} - ${billing.TaxRegistration}`
				: "",
		};
	});

	const mappedSpaceAccountData = (spaceAccounts || []).map((item) => {
		const spaces = filter(leaseParks, (leasePark) =>
			item.SpaceAllocations.find(
				(spaceAllocation) =>
					spaceAllocation.LeaseParkID === leasePark.LeaseParkID
			)
		);

		return {
			...item,
			spaceNames: spaces.map((e) => e.Name).join(", "),
		};
	});

	return (
		<Switch>
			<Route path={`${props.match.url}/spaces`}>
				<ReportWrapperBeta
					{...props}
					title="Spaces"
					data={mappedLeaseParkData}
					availableSites={props.availableSites}
					columns={spaceColumns({
						openWizard: openSpaceWizard,
						isAdmin: isAdmin,
						canManageRates: canManageRates,
						billingDetails: billingDetails,
						hasOccupancy: props.selectedOrganization?.hasOccupancy,
					})}
					filterOptions={[
						"Name",
						"SpaceType",
						"Spaces",
						"spacesInUse",
						"siteNames",
						"billing",
					]}
					defaultSortBy={[
						{
							id: "Name",
						},
					]}
					updateOptions={updateOptions}
					loading={isLoading}
					showDateRangePicker={false}
					stats={_renderStats()}
					rightActions={
						isAdmin || canEditSettings ? (
							<Button color="blue" onClick={() => openSpaceWizard({}, "add")}>
								<Plus size={20} /> Create Space
							</Button>
						) : null
					}
				/>
			</Route>
			<Route path={`${props.match.url}/bays`}>
				<BaysContainer
					{...props}
					isAdmin={isAdmin}
					leaseParks={leaseParks}
					canEditSettings={canEditSettings}
				/>
			</Route>
			<Route path={`${props.match.url}/accounts`}>
				<ReportWrapperBeta
					{...props}
					title="Accounts"
					data={mappedSpaceAccountData}
					availableSites={props.availableSites}
					columns={accountColumns({
						openWizard: openSpaceAccountWizard,
						isAdmin: isAdmin,
						canManageRates: canManageRates,
						billingDetails: billingDetails,
					})}
					filterOptions={["Name", "spaceNames"]}
					defaultSortBy={[
						{
							id: "Name",
						},
					]}
					updateOptions={updateOptions}
					loading={isLoading}
					showSitePicker={false}
					showDateRangePicker={false}
					rightActions={
						isAdmin || canManageRates || canManageLeases ? (
							<Button
								color="blue"
								onClick={() => openSpaceAccountWizard({}, "add")}
							>
								<Plus size={20} /> Create Account
							</Button>
						) : null
					}
				/>
			</Route>
			<Redirect to={`${props.match.url}/spaces`} />
		</Switch>
	);
}
