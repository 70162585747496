import React from "react";
import Dropdown from "../../layout/Dropdown";
import { StepText } from "../WizardLayout";
import { cloneDeep } from "lodash";

export function ChooseSpaces(props) {
	const values = props.values;
	const selectedSites = values.onAllSites
		? values.availableSites.map((site) => ({
				SiteID: site.id,
				Name: site.name,
		  }))
		: values.promotionSites;

	const leaseParks = props.wizardProps.availableSpaces;

	const handleValueChange = (value, key) => {
		let _leaseParkIds = cloneDeep(values.leaseParkIds);
		_leaseParkIds = {
			..._leaseParkIds,
			[key]: value,
		};

		return _leaseParkIds;
	};

	return (
		<div>
			{selectedSites.map((site) => {
				const options = leaseParks
					.filter((lp) => lp.SiteID === site.SiteID)
					.flatMap((lp) =>
						lp.Spaces.map((space) => ({
							value: space.LeaseParkID,
							label: space.Name,
						}))
					);
				return (
					<div key={site.SiteID} style={{ marginTop: "15px" }}>
						<StepText>{site.Name}</StepText>
						{options.length ? (
							<div style={{ marginTop: "5px" }}>
								<Dropdown
									options={options}
									value={options.find((option) => {
										return (
											option.value === values.leaseParkIds[site.SiteID] || null
										);
									})}
									isDisabled={options.length === 0}
									onChange={(value) => {
										props.handleChange({
											target: {
												name: "leaseParkIds",
												value: handleValueChange(
													value?.value || null,
													site.SiteID
												),
											},
										});
									}}
									isClearable
								/>
							</div>
						) : (
							<p>
								No available spaces for this site. A space can be assigned to
								this site in the Spaces page.
							</p>
						)}
					</div>
				);
			})}
		</div>
	);
}
