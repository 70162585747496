/*
 * some helper functions to get rid of boilerplate code
 */

import Alert from "react-s-alert";

export const convertModeNameToWizardTitle = (mode) =>
	mode
		.replace(/-/g, " ")
		.replace(/\b[a-z](?=[a-z]{2})/g, (letter) => letter.toUpperCase());

export async function submitMutation(
	mutation,
	variables,
	closeWizard,
	successMessage = "Successfully submitted.",
	errorMessage = "Something went wrong. Please try again."
) {
	try {
		await mutation({
			variables,
		});

		Alert.success(successMessage);
		closeWizard();
	} catch (error) {
		Alert.error(errorMessage);
	}
}
