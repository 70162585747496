import { AlertCircle } from "react-feather";
import React from "react";
import { colours } from "../../styles";
import styled from "styled-components";

const Wrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	color: ${colours.red};

	.field-error-icon {
		color: ${colours.red};
		height: 20px;
		padding-left: 4px;
		width: 24px;

		svg {
			position: initial;
			left: 0;
			top: 0;
		}
	}
`;

export default function FieldError(props) {
	return props.showError ? (
		<Wrapper>
			<div className="field-error-icon">
				<AlertCircle size={16} />
			</div>
			<div>{props.errorText}</div>
		</Wrapper>
	) : null;
}
