import PageActions, {
	LeftActions,
	RightActions,
} from "../../../components/layout/PageActions";
import React, { useContext, useState } from "react";
import TableLayout, { highlightedCell } from "../../layout/TableLayout";
import { cloneDeep, filter, forEach } from "lodash";
import { usePermissions, useQueryData, useSearchFilter } from "../../../hooks";
import { AppContext } from "../../../context/app-context";
import Card from "../../layout/Card";
import DatePicker from "../../../components/layout/DatePicker";
import DetailsPageWrapper from "../DetailsPageWrapper";
import DropdownMenu from "../../layout/DropdownMenu";
import Label from "../../layout/Label";
import { MoreHorizontal } from "react-feather";
import ParkingSessionFeePreview from "../../report/ParkingSessionFeePreview";
import PropTypes from "prop-types";
import SearchBox from "../../../components/layout/SearchBox";
import SessionWizard from "../../wizards/session-wizard/SessionWizard";
import TransactionDetails from "./TransactionDetails";
import { colours } from "../../../styles";
import { format } from "../../../helpers";
import moment from "moment";
import styled from "styled-components";
import gql from "graphql-tag";

AnonymousUserDetails.propTypes = {
	user: PropTypes.object.isRequired,
	organizationId: PropTypes.number,
	availableSites: PropTypes.array,
	global: PropTypes.bool,
	close: PropTypes.func.isRequired,
};

AnonymousUserDetails.defaultProps = {
	global: false,
};

const NoData = styled.div`
	margin-top: 40px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	background-color: ${colours.offWhite};
`;

const sessionColumns = (_props) => [
	{
		id: "Status",
		Header: "Status",
		accessor: (d) => d.IsActive,
		Cell: (cellProps) => (
			<Label
				color={
					cellProps.original.IsActive
						? "green"
						: cellProps.original.WasVoided
						? "blue"
						: "red"
				}
			>
				{cellProps.original.IsActive
					? "Active"
					: cellProps.original.WasVoided
					? "Void"
					: "Closed"}
			</Label>
		),
		width: 100,
	},
	{
		id: "Plate",
		Header: "Plate",
		accessor: "Numberplate",
		Cell: (cellProps) =>
			highlightedCell(
				String(cellProps.original.Numberplate?.Numberplate || ""),
				_props.state.search
			),
	},
	{
		id: "Site",
		Header: "Site",
		accessor: "SiteName",
		Cell: (cellProps) =>
			highlightedCell(
				String(cellProps.original.Site.Name || ""),
				_props.state.search
			),
	},
	{
		id: "StartTime",
		Header: "Start Time",
		accessor: "EntryTimestamp",
		Cell: (cellProps) => cellProps.original.EntryTimestamp,
	},
	{
		id: "Duration",
		Header: "Duration",
		accessor: (d) =>
			d.FinalDuration
				? format.duration(d.FinalDuration, true, true)
				: format.duration(moment().unix() - d.EntryTimestampValue, true, true),
	},
	{
		id: "Fee",
		Header: "Fee",
		accessor: (d) => d.FinalFee,
		Cell: (cellProps) => {
			if (!cellProps.original.IsActive) {
				return format.money(cellProps.original.Fee);
			} else {
				return (
					<ParkingSessionFeePreview
						key={cellProps.original.ParkingSessionID}
						parkingSessionId={cellProps.original.ParkingSessionID}
						siteId={cellProps.original.Site.SiteID}
						fee={cellProps.original.Fee || null}
						onChange={(fee) => {
							_props.setState((_state) => ({
								..._state,
								sessionPreviewFees: {
									..._state.sessionPreviewFees,
									[cellProps.original.ParkingSessionID]: fee,
								},
							}));
						}}
					/>
				);
			}
		},
	},
	{
		id: "tasks",
		Header: "",
		accessor: null,
		Cell: (cellProps) =>
			_props.canManageSessions && cellProps.original.IsActive ? (
				<DropdownMenu
					triggerContent={<MoreHorizontal size={24} />}
					items={[
						<div
							key="end-session"
							onClick={() =>
								_props.openSessionWizard(cellProps.original, "end-session")
							}
						>
							End Session
						</div>,
					]}
				/>
			) : !cellProps.original.IsActive ? (
				<DropdownMenu
					triggerContent={<MoreHorizontal size={24} />}
					items={[
						<div
							key="session-details"
							onClick={() =>
								_props.showTransactionDetails(
									cellProps.original.Site.SiteID,
									cellProps.original,
									null
								)
							}
						>
							Transaction details
						</div>,
					]}
				/>
			) : null,
		resizable: false,
		width: 50,
	},
];

export default function AnonymousUserDetails(props) {
	const {
		dispatch: { setReportDateRange },
		state: { reportDateRange },
	} = useContext(AppContext);

	const canManageSessions = usePermissions(null, "SessionAdmin", true);

	const [state, setState] = useState({
		dateRange: reportDateRange || [
			moment().startOf("month").toDate(),
			moment().endOf("month").startOf("day").toDate(),
		],
		search: "",
		sessionWizardOpen: false,
		transactionDetailsOpen: false,
		sessionPreviewFees: {},
	});

	function openSessionWizard(session, mode) {
		setState((_state) => ({
			..._state,
			sessionWizardOpen: true,
			sessionWizardSession: session,
			sessionWizardMode: mode,
		}));
	}

	function showTransactionDetails(siteId, session, lease) {
		setState((_state) => ({
			..._state,
			transactionDetailsOpen: true,
			selectedSiteId: siteId,
			transactionDetailsSession: session,
			transactionDetailsLease: lease,
		}));
	}

	async function onDateRangeChange(dateRange) {
		if (dateRange && dateRange.length > 1) {
			setState((_state) => ({ ..._state, dateRange }));
			setReportDateRange(dateRange);
		}
	}

	async function onSearchFilterChange(search) {
		setState((_state) => ({ ..._state, search: search }));
	}

	const {
		data: { getSessionsForAnonymousUser },
	} = useQueryData(
		gql`
			query (
				$email: String!
				$startTimestamp: Timestamp
				$endTimestamp: Timestamp
				$organizationId: Int!
			) {
				getSessionsForAnonymousUser(
					email: $email
					startTimestamp: $startTimestamp
					endTimestamp: $endTimestamp
					organizationId: $organizationId
				) {
					ParkingSessionID
					IsActive
					Numberplate {
						NumberplateID
						Numberplate
					}
					EntryTimestamp
					EntryTimestampValue
					ExitTimestamp
					ExitTimestampValue
					FinalDuration
					FinalFee
					Site {
						SiteID
						Name
					}
				}
			}
		`,
		{
			email: props.user.Email,
			startTimestamp: moment(state.dateRange[0]).startOf("day").unix(),
			endTimestamp: moment(state.dateRange[1]).endOf("day").unix(),
			organizationId: props.organizationId,
		},
		!props.user.Email
	);

	const sessionsData = cloneDeep(getSessionsForAnonymousUser || []);

	const filteredSessions = useSearchFilter(sessionsData, state.search, [
		"Numberplate",
		"Site.Name",
	]);

	forEach(sessionsData, (data) => {
		if (data && state.sessionPreviewFees[data.ParkingSessionID] !== undefined) {
			data.Fee = state.sessionPreviewFees[data.ParkingSessionID];
		} else {
			data.Fee = data.FinalFee;
		}
	});

	if (state.sessionWizardOpen) {
		return (
			<SessionWizard
				close={() => {
					setState((_state) => ({ ..._state, sessionWizardOpen: false }));
				}}
				mode={state.sessionWizardMode}
				user={props.user}
				session={state.sessionWizardSession}
				sites={filter(
					props.availableSites,
					(site) =>
						site.HasCasualParking &&
						!site.HasSessionlessAccess &&
						site.SiteType === "Gated"
				)}
			/>
		);
	}

	if (state.transactionDetailsOpen) {
		return (
			<TransactionDetails
				{...props}
				siteId={state.selectedSiteId}
				session={state.transactionDetailsSession}
				lease={state.transactionDetailsLease}
				onClose={() => {
					setState((_state) => ({
						..._state,
						transactionDetailsOpen: false,
					}));
				}}
			/>
		);
	}

	const content = (
		<React.Fragment>
			<PageActions>
				<LeftActions>
					<SearchBox
						onChange={onSearchFilterChange}
						value={state.searchFilter}
					/>
				</LeftActions>
				<RightActions>
					<DatePicker
						options={{ pickerMode: "dateRangePicker" }}
						value={state.dateRange}
						onChange={onDateRangeChange}
					/>
				</RightActions>
			</PageActions>
			<Card>
				<h2>User Information</h2>
				<TableLayout
					data={[props.user]}
					columns={[
						{
							id: "Email",
							Header: "Email",
							accessor: "Email",
							resizable: false,
						},
					]}
					sortable={false}
				/>
			</Card>
			<Card>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<h2>Sessions</h2>
				</div>
				{filteredSessions.length ? (
					<TableLayout
						data={filteredSessions}
						columns={sessionColumns({
							availableSites: props.availableSites,
							canManageSessions: canManageSessions,
							state: state,
							setState: setState,
							openSessionWizard: openSessionWizard,
							showTransactionDetails: showTransactionDetails,
						})}
						showPagination={true}
						defaultPageSize={10}
						defaultSorted={[{ id: "EntryTimestamp", desc: true }]}
					/>
				) : (
					<NoData>No sessions found within the selected period.</NoData>
				)}
			</Card>
		</React.Fragment>
	);

	return (
		<DetailsPageWrapper
			close={props.close}
			content={content}
			title="User Details"
		/>
	);
}
