import { MoreHorizontal, Plus } from "react-feather";
import React, { useState } from "react";
import { fetchDataCallback, paginatedState } from "../../../helpers/pagination";
import { usePermissions, useQueryData } from "../../../hooks";
import BeaconWizard from "../../wizards/beacon-wizard/BeaconWizard";
import Button from "../../layout/Button";
import DropdownMenu from "../../layout/DropdownMenu";
import { HARDWARE_STATUSES } from "../../../helpers/constants";
import ReportWrapperBeta from "../ReportWrapperBeta";
import { format } from "../../../helpers";
import gql from "graphql-tag";
import { map } from "lodash";

const defaultSorting = [
	{
		id: "BeaconDeviceID",
		desc: false,
	},
];

export default function Beacons(props) {
	const [state, setState] = useState({
		initialLoad: true,
		search: "",
		beacon: "",
		wizardOpen: false,
	});

	let beacons = [];

	const isAdmin = usePermissions("IsAdmin");
	const isCSAdmin = usePermissions("IsCSAdmin");
	const isSuperAdmin = isAdmin && !isCSAdmin;

	const [paginate, setPaginate] = useState(paginatedState);

	const fetchPaginatedData = fetchDataCallback(setPaginate);

	async function updateOptions({ search, ...options }) {
		setState((_state) => ({ ..._state, initialLoad: false, search, options }));
	}

	const openWizard = (mode, beacon) => {
		setState((_state) => ({
			..._state,
			beacon,
			wizardOpen: true,
			wizardMode: mode,
		}));
	};

	const clientMap = isAdmin
		? props.clients.reduce((res, val) => {
				res[val.value] = val.label;
				return res;
		  }, {})
		: {};

	const skipQuery =
		!paginate.pageSize || !paginate.sortBy || !paginate.sortOrder;

	const {
		data: { getAllBeacons },
		isLoading,
		refetch,
	} = useQueryData(
		gql`
			query ($page: PageInfo!, $cursor: Int) {
				getAllBeacons(page: $page, cursor: $cursor) {
					trueLength
					beacons {
						BeaconID
						BeaconDeviceID
						LastDetectedTimestamp
						UniqueID
						Namespace
						DeviceType
						Model
						Minor
						Major
						MacAddress
						Status
						ClientID
						CreatedOn
						UpdatedOn
						RankingScore
						Sites {
							SiteID
							OrganizationID
							Name
						}
					}
					previousEvent
				}
			}
		`,
		{
			page: {
				next: paginate.next,
				pageOffset: paginate.pageOffset,
				pageSize: paginate.pageSize,
				sortBy: paginate.sortBy,
				sortOrder: paginate.sortOrder,
				searchTokens: paginate.searchTokens,
				eventType: paginate.eventType,
				sortedVal: paginate.cursor.sortedVal,
			},
			cursor: paginate.cursor.cursor,
		},
		skipQuery
	);

	if (getAllBeacons && getAllBeacons.beacons) {
		beacons = getAllBeacons.beacons.map((beacon) => {
			return {
				...beacon,
				siteNames: map(beacon.Sites, (site) => site.Name).join(", "),
			};
		});
	}
	const columns = [
		{
			id: "BeaconDeviceID",
			Header: "Device ID",
			accessor: (d) => d.BeaconDeviceID,
			highlightCell: (cellProps) => cellProps.row.original.BeaconDeviceID,
		},
		{
			id: "UniqueID",
			Header: "Unique ID",
			accessor: (d) => d.UniqueID || "",
			highlightCell: (cellProps) => cellProps.row.original.UniqueID || "",
		},
		{
			id: "Namespace",
			Header: "Namespace",
			accessor: (d) => d.Namespace || "",
			highlightCell: (cellProps) => cellProps.row.original.Namespace || "",
		},
		{
			id: "Minor",
			Header: "Minor",
			accessor: (d) => d.Minor || 0,
			Cell: (cellProps) => cellProps.row.original.Minor,
		},
		{
			id: "Major",
			Header: "Major",
			accessor: (d) => d.Major || 0,
			Cell: (cellProps) => cellProps.row.original.Major,
		},
		{
			id: "MacAddress",
			Header: "MAC Address",
			accessor: (d) => d.MacAddress || "",
			highlightCell: (cellProps) => cellProps.row.original.MacAddress || "",
		},
		{
			id: "LastDetectedTimestamp",
			Header: "Last Detected",
			accessor: (d) => d.LastDetectedTimestamp || 0,
			Cell: (cellProps) =>
				cellProps.row.original.LastDetectedTimestamp
					? format.localDate(cellProps.row.original.LastDetectedTimestamp, "X")
					: "",
		},
		isAdmin
			? {
					id: "ClientName",
					Header: "Client",
					accessor: (d) => clientMap[d.ClientID] || "",
					highlightCell: (cellProps) =>
						clientMap[cellProps.row.original.ClientID] || "",
			  }
			: null,
		{
			id: "Status",
			Header: "Status",
			accessor: (d) => HARDWARE_STATUSES[d.Status] || "",
			highlightCell: (cellProps) =>
				HARDWARE_STATUSES[cellProps.row.original.Status] || "",
			className: "bold",
		},
		{
			id: "tasks",
			Header: "",
			accessor: null,
			Cell: (cellProps) => (
				<DropdownMenu
					triggerContent={<MoreHorizontal size={24} />}
					items={[
						<div
							key="update"
							onClick={() =>
								openWizard("admin-edit-beacon", cellProps.row.original)
							}
						>
							Edit
						</div>,
					]}
				/>
			),
			resizable: false,
			width: 50,
		},
	].filter((e) => e);

	const trueLength = getAllBeacons?.trueLength || 0;

	return (
		<div>
			{state.wizardOpen && (
				<BeaconWizard
					close={() => {
						setState((_state) => ({
							..._state,
							wizardOpen: false,
						}));

						refetch();
					}}
					mode={state.wizardMode}
					beacon={state.beacon}
					clients={props.clients}
					hardwareStatuses={HARDWARE_STATUSES}
					currentUserIsAdmin={isAdmin}
					operator={props.selectedOperator}
				/>
			)}
			<ReportWrapperBeta
				{...props}
				title="Beacons"
				data={beacons}
				columns={columns}
				loading={false}
				defaultSortBy={defaultSorting}
				updateOptions={updateOptions}
				showDateRangePicker={false}
				showSitePicker={false}
				rightActions={
					isSuperAdmin ? (
						<Button
							key="submit"
							color="blue"
							onClick={() => openWizard("admin-add-beacon")}
						>
							<Plus size={20} /> Add Beacon
						</Button>
					) : null
				}
				dataTrueLength={trueLength}
				cursorColumn="BeaconID"
				paginationIsLoading={isLoading || skipQuery || props.isLoading}
				pageSize={state.pageSize}
				previousEvent={getAllBeacons?.previousEvent}
				searchTokens={paginate.searchTokens}
				fetchPaginatedData={fetchPaginatedData}
			/>
		</div>
	);
}
