import { GoogleMap, Marker, Polygon, withGoogleMap } from "react-google-maps";
import React, { useState } from "react";
import { StepText, StepTitle } from "../WizardLayout";
import { buffer, polygon } from "@turf/turf";
import { cloneDeep, map } from "lodash";
import Button from "../../layout/Button";
import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager";
import EditableInputField from "../../layout/EditableInputField";
import WizardNavigation from "../WizardNavigation";
import { colours } from "../../../styles";
import environment from "../../../api/environment";

const googleKey = environment.config.googleKey;

const MapWithDrawing = withGoogleMap((props) => {
	const [state, setState] = useState({
		newZone: null,
		newBufferZone: null,
		drawing: true,
	});

	let polygonRef = React.createRef();

	function getPoints(path) {
		let points = [];
		path.forEach(function (latLng) {
			let point = { lat: latLng.lat(), lng: latLng.lng() };
			points.push(point);
		});
		return points;
	}

	function updateZoneData(points) {
		props.onClick({ points: points });
	}

	function getBufferZone(points) {
		let polygonPoints = [];
		for (let index in points) {
			polygonPoints.push([points[index].lng, points[index].lat]);
		}

		//Flatten the polygon in Turf
		const turfPolyPoints = cloneDeep(polygonPoints);
		turfPolyPoints.push(turfPolyPoints[0]);
		const turfPolygon = polygon([turfPolyPoints]);
		//use the turfPolygon to generate the buffer area in Turf
		const Buffer = buffer(turfPolygon, props.BufferMeters, {
			units: "meters",
		});
		//convert turf buffer points into google maps format
		return map(Buffer.geometry.coordinates[0], (point) => ({
			lng: point[0],
			lat: point[1],
		}));
	}

	return (
		<GoogleMap
			defaultZoom={19}
			defaultTilt={0}
			defaultMapTypeId={"satellite"}
			defaultCenter={{ lat: props.siteLat || null, lng: props.siteLng || null }}
		>
			<Marker
				position={{
					lat: props.siteLat || null,
					lng: props.siteLng || null,
				}}
				draggable={false}
			/>
			{props.zones.map((zone) => {
				return (
					<div key={zone.ZoneID}>
						<Polygon
							path={zone.BufferPoints}
							key={zone.ZoneID + "-buffer"}
							editable={false}
							options={{
								strokeColor: `${colours.white}`,
								strokeOpacity: 1,
								fillColor: `${colours.white}`,
								fillOpacity: 0.25,
							}}
						/>
						<Polygon
							path={zone.Points}
							key={zone.ZoneID + "-zone"}
							editable={false}
							options={{
								strokeColor: `${colours.white}`,
								strokeOpacity: 1,
								fillColor: `${colours.white}`,
								fillOpacity: 0.4,
							}}
						/>
					</div>
				);
			})}
			{state.drawing && (
				<DrawingManager
					defaultDrawingMode={google.maps.drawing.OverlayType.POLYGON}
					defaultOptions={{
						drawingControl: true,
						drawingControlOptions: {
							position: google.maps.ControlPosition.TOP_CENTER,
							drawingModes: [google.maps.drawing.OverlayType.POLYGON],
						},
						polygonOptions: {
							strokeColor: `${colours.green}`,
							strokeOpacity: 1,
							fillColor: `${colours.green}`,
							fillOpacity: 0.4,
							clickable: true,
							editable: true,
							zIndex: 1,
						},
					}}
					onPolygonComplete={(_polygon) => {
						const newZone = getPoints(_polygon.getPath());
						updateZoneData(newZone);

						const newBufferZone = getBufferZone(newZone);

						setState((_state) => ({
							..._state,
							newZone: newZone,
							newBufferZone: newBufferZone,
							drawing: false,
						}));
						_polygon.setMap(null);
					}}
				/>
			)}
			{!state.drawing && state.newBufferZone && (
				<Polygon
					path={state.newBufferZone}
					key={"newBuffer"}
					editable={false}
					options={{
						strokeColor: `${colours.white}`,
						strokeOpacity: 1,
						fillColor: `${colours.white}`,
						fillOpacity: 0.25,
					}}
				/>
			)}
			{!state.drawing && (
				<Polygon
					ref={polygonRef}
					path={state.newZone}
					key={"newZone"}
					editable={true}
					options={{
						strokeColor: `${colours.green}`,
						strokeOpacity: 1,
						fillColor: `${colours.green}`,
						fillOpacity: 0.4,
					}}
					onRightClick={(event) => {
						if (event.vertex !== null) {
							let path = polygonRef.current.getPath();
							if (path.length > 3) {
								path.removeAt(event.vertex);

								const points = getPoints(path);
								const newBufferZone = getBufferZone(points);

								setState((_state) => ({
									..._state,
									newBufferZone: newBufferZone,
								}));

								updateZoneData(points);
							}
						}
					}}
					onMouseUp={() => {
						const points = getPoints(polygonRef.current.getPath());
						const newBufferZone = getBufferZone(points);

						setState((_state) => ({
							..._state,
							newBufferZone: newBufferZone,
						}));

						updateZoneData(points);
					}}
				/>
			)}
		</GoogleMap>
	);
});

const MapWithEditing = withGoogleMap((props) => {
	const [state, setState] = useState({
		edited: false,
		points: null,
	});

	let polygonRef = React.createRef();

	function getPoints(path) {
		let points = [];
		path.forEach(function (latLng) {
			let point = { lat: latLng.lat(), lng: latLng.lng() };
			points.push(point);
		});
		return points;
	}

	function updateZoneData(points) {
		props.onClick({ points: points });
	}

	function getBufferZone(points) {
		let polygonPoints = [];
		for (let index in points) {
			polygonPoints.push([points[index].lng, points[index].lat]);
		}

		//Flatten the polygon in Turf
		const turfPolyPoints = cloneDeep(polygonPoints);
		turfPolyPoints.push(turfPolyPoints[0]);
		const turfPolygon = polygon([turfPolyPoints]);
		//use the turfPolygon to generate the buffer area in Turf
		const Buffer = buffer(turfPolygon, props.BufferMeters, {
			units: "meters",
		});
		//convert turf buffer points into google maps format
		return map(Buffer.geometry.coordinates[0], (point) => ({
			lng: point[0],
			lat: point[1],
		}));
	}

	return (
		<GoogleMap
			defaultZoom={19}
			defaultTilt={0}
			defaultMapTypeId={"satellite"}
			defaultCenter={{ lat: props.siteLat || null, lng: props.siteLng || null }}
		>
			<Marker
				position={{
					lat: props.siteLat || null,
					lng: props.siteLng || null,
				}}
				draggable={false}
			/>
			{props.zones.map((zone) => {
				if (zone.ZoneID !== props.zoneId) {
					return (
						<div key={zone.ZoneID}>
							<Polygon
								path={zone.BufferPoints}
								key={zone.ZoneID + "-buffer"}
								editable={false}
								options={{
									strokeColor: `${colours.white}`,
									strokeOpacity: 1,
									fillColor: `${colours.white}`,
									fillOpacity: 0.25,
								}}
							/>
							<Polygon
								path={zone.Points}
								key={zone.ZoneID + "-zone"}
								editable={false}
								options={{
									strokeColor: `${colours.white}`,
									strokeOpacity: 1,
									fillColor: `${colours.white}`,
									fillOpacity: 0.4,
								}}
							/>
						</div>
					);
				} else {
					return (
						<div key={"SelectedZone"}>
							<Polygon
								path={
									state.edited
										? getBufferZone(state.points)
										: getBufferZone(zone.Points)
								}
								key={zone.ZoneID + "-buffer"}
								editable={false}
								options={{
									strokeColor: `${colours.white}`,
									strokeOpacity: 1,
									fillColor: `${colours.white}`,
									fillOpacity: 0.25,
								}}
							/>
							<Polygon
								ref={polygonRef}
								path={zone.Points}
								key={zone.ZoneID}
								editable={true}
								options={{
									strokeColor: `${colours.green}`,
									strokeOpacity: 1,
									fillColor: `${colours.green}`,
									fillOpacity: 0.4,
								}}
								onRightClick={(event) => {
									if (event.vertex !== null) {
										let path = polygonRef.current.getPath();
										if (path.length > 3) {
											path.removeAt(event.vertex);

											const points = getPoints(path);

											setState((_state) => ({
												..._state,
												points: points,
												edited: true,
											}));

											updateZoneData(points);
										}
									}
								}}
								onMouseUp={() => {
									const points = getPoints(polygonRef.current.getPath());

									setState((_state) => ({
										..._state,
										points: points,
										edited: true,
									}));

									updateZoneData(points);
								}}
							/>
						</div>
					);
				}
			})}
		</GoogleMap>
	);
});

export const nameStep = ({
	values,
	setFieldValue,
	next,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "name",
	label: "Name",
	render: () => (
		<div>
			<StepTitle>What is the name of the zone?</StepTitle>
			<EditableInputField
				type="text"
				name={"name"}
				value={values.name || ""}
				useLabel={"Name"}
				onChange={(event) => {
					setFieldValue("name", event.target.value);
				}}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="back" color="blue" onClick={wizardProps.close}>
					Back
				</Button>,
			]}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={next}
					disabled={!values.name}
					keyStrokeHandler={keyStrokeHandler}
				>
					Next
				</Button>,
			]}
		/>
	),
});

export const bufferStep = ({
	values,
	setFieldValue,
	next,
	previous,
	keyStrokeHandler,
}) => ({
	id: "buffer",
	label: "Buffer",
	render: () => (
		<div>
			<StepTitle>What is the buffer size of the zone?</StepTitle>
			<EditableInputField
				type="number"
				min={0}
				max={100}
				name="buffer"
				useLabel="(meters)"
				onChange={(event) => {
					let bufferSize = event.target.value
						.replace(/[^0-9.]/g, "")
						.replace(/(\..*)\./g, "$1");
					if (bufferSize > 100) {
						bufferSize = 100;
					}
					setFieldValue("buffer", bufferSize);
				}}
				value={values.buffer}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="back" color="blue" onClick={previous}>
					Back
				</Button>,
			]}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={next}
					disabled={!values.buffer && values.buffer !== 0}
					keyStrokeHandler={keyStrokeHandler}
				>
					Next
				</Button>,
			]}
		/>
	),
});

export const drawingStep = ({
	previous,
	values,
	setFieldValue,
	isSubmitting,
	handleSubmit,
	keyStrokeHandler,
}) => ({
	id: "zone",
	label: "Zone",
	render: () => (
		<div>
			<StepTitle>Please draw the new zone</StepTitle>
			<MapWithDrawing
				zones={values.zones}
				siteLat={values.lat}
				siteLng={values.lng}
				BufferMeters={values.buffer}
				googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=geometry,drawing`}
				loadingElement={<div style={{ height: window.innerHeight - 150 }} />}
				containerElement={
					<div
						style={{
							height: window.innerHeight - 150,
							width: "100%",
						}}
					/>
				}
				mapElement={<div style={{ height: window.innerHeight - 150 }} />}
				onClick={(newValue) => setFieldValue("points", newValue.points)}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button
					key="previous"
					color="blue"
					onClick={() => {
						setFieldValue("points", []);
						previous();
					}}
				>
					Back
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={isSubmitting || values.points.length < 3}
					keyStrokeHandler={keyStrokeHandler}
				>
					Submit
				</Button>,
			]}
		/>
	),
});

export const editingStep = ({
	previous,
	values,
	setFieldValue,
	isSubmitting,
	handleSubmit,
	keyStrokeHandler,
}) => ({
	id: "zone",
	label: "Zone",
	render: () => (
		<div>
			<StepTitle>Please draw the new zone</StepTitle>
			<MapWithEditing
				zones={values.zones}
				zoneId={values.zoneId}
				siteLat={values.lat}
				siteLng={values.lng}
				BufferMeters={values.buffer}
				googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=geometry,drawing`}
				loadingElement={<div style={{ height: window.innerHeight - 150 }} />}
				containerElement={
					<div
						style={{
							height: window.innerHeight - 150,
							width: "100%",
						}}
					/>
				}
				mapElement={<div style={{ height: window.innerHeight - 150 }} />}
				onClick={(newValue) => setFieldValue("points", newValue.points)}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="previous" onClick={previous} color="blue">
					Back
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={isSubmitting || values.points.length < 3}
					keyStrokeHandler={keyStrokeHandler}
				>
					Submit
				</Button>,
			]}
		/>
	),
});

export const deleteStep = ({
	handleSubmit,
	isSubmitting,
	values,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "delete",
	label: "Delete",
	render: () => (
		<div>
			<StepText>
				Are you sure you want to delete Zone <b>{values.name}</b>? This action{" "}
				CANNOT be undone.
			</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					Delete Zone
				</Button>,
			]}
		/>
	),
});
