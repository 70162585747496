import DatePicker from "./DatePicker";
import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import styled from "styled-components";

const Wrapper = styled.div`
	display: flex;
	padding: 10px 10px 10px ${(props) => (props.noLeftPadding ? "0" : "10px")};
	position: relative;

	> * {
		margin: 0 10px;
	}
`;

const Dash = styled.div`
	display: inline-block;
	margin: 8px 4px 0;
`;

TimeRangePicker.propTypes = {
	startTime: PropTypes.instanceOf(Date),
	endTime: PropTypes.instanceOf(Date),
	time24hr: PropTypes.bool,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
};

TimeRangePicker.defaultProps = {
	time24hr: true,
	onChange: () => {},
};

function TimeRangePicker(props) {
	return (
		<Wrapper noLeftPadding={props.noLeftPadding}>
			<DatePicker
				options={{
					pickerMode: "timePicker",
					time_24hr: props.time24hr,
					maxDate: props.noLimits ? null : moment(props.endTime).toDate(),
					errorHandler: () => {},
					onOpen: props.onOpen
						? () =>
								props.startTime
									? undefined
									: props.onChange({
											from: props.onOpen.start,
											to: props.endTime,
									  })
						: undefined,
				}}
				value={props.startTime}
				onChange={(value) =>
					props.onChange({ from: value[0], to: props.endTime })
				}
				disabled={props.disabled}
			/>
			<Dash>{props.dash || "-"}</Dash>
			<DatePicker
				options={{
					pickerMode: "timePicker",
					time_24hr: props.time24hr,
					minDate: props.noLimits ? null : moment(props.startTime).toDate(),
					errorHandler: () => {},
					onOpen: props.onOpen
						? () =>
								props.endTime
									? undefined
									: props.onChange({
											from: props.startTime,
											to: props.onOpen.end,
									  })
						: undefined,
				}}
				value={props.endTime}
				onChange={(value) => {
					if (props.canEndAt24Hours) {
						const newEnd = moment(value[0]);
						if (newEnd.format("HH:mm") === "00:00") {
							newEnd.add(1, "day");
						}
						return props.onChange({
							from: props.startTime,
							to: newEnd.toDate(),
						});
					} else {
						return props.onChange({ from: props.startTime, to: value[0] });
					}
				}}
				disabled={props.disabled}
			/>
			{moment(props.startTime).format("HH:mm") === "00:00" &&
				moment(props.endTime).format("HH:mm") === "00:00" &&
				props.canEndAt24Hours && <Dash>(24 hours)</Dash>}
		</Wrapper>
	);
}

export default TimeRangePicker;
