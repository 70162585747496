import styled from "styled-components";

const FlexWrapper = styled.div`
	display: flex;
	margin: 0 -16px;
	padding: 16px 0;
	position: relative;

	> * {
		flex: 1;
		margin: 0 16px;
	}
`;

export default FlexWrapper;
