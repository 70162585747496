import MultiSelect from "../layout/MultiSelect";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
	width: 250px;
`;

export default function ReportPicker(props) {
	return (
		<Wrapper style={props.wrapperStyle}>
			<MultiSelect
				style={props.style}
				options={props.options}
				selected={props.selected.filter((option) =>
					props.options.map((o) => o.value).includes(option)
				)}
				onSelectedChanged={props.onSelectedChanged}
				overrideStrings={{
					selectSomeItems: `Select ${props.name}`,
					allItemsAreSelected: `All ${props.name}`,
					selectAll: `All ${props.name}`,
					search: "Search",
				}}
				valueRenderer={(selected, options) => {
					if (selected.length === 0) {
						return `Select ${props.name}`;
					}

					if (selected.length === options.length) {
						return `All ${props.name}`;
					}

					if (selected.length === 1) {
						return selected.name;
					}

					return `${selected.length} ${props.name} selected`;
				}}
				disabled={props.disabled}
			/>
		</Wrapper>
	);
}
