import * as InstallationApi from "../../../api/installation";
import * as WebPayApi from "../../../api/web-pay";
import * as Yup from "yup";
import * as steps from "./steps";
import Alert from "react-s-alert";
import ErrorBoundary from "../../../containers/ErrorBoundary";
import PropTypes from "prop-types";
import React from "react";
import WizardLayout from "../WizardLayout";
import { VALIDATION_WIZARD_TYPE } from "../../../helpers/constants";
import { useMutateData } from "../../../hooks";
import gql from "graphql-tag";

function getWizard({
	mode,
	type,
	organizationId,
	organizationAccessGroups,
	beacon,
	qrCode,
	availableSites,
	availableSpaces,
	validationTypeOptions,
	rateData,
}) {
	const isBeacon = type === VALIDATION_WIZARD_TYPE.BEACON;

	const accessGroup = organizationAccessGroups?.find(
		(group) =>
			group.OrganizationAccessGroupID === beacon?.OrganizationAccessGroupID
	);

	const fields = [
		{
			name: "organizationId",
			value: organizationId,
			validator: Yup.number(),
		},
		{
			name: isBeacon ? "beaconId" : "qrCodeId",
			value: isBeacon ? beacon.BeaconID : qrCode.QRCodeID,
			validator: Yup.number(),
		},
		{
			name: "location",
			value: isBeacon ? beacon.Location : qrCode.Location,
			validator: Yup.string().nullable(),
		},
		isBeacon
			? {
					name: "uniqueId",
					value: beacon.UniqueID,
					validator: Yup.string(),
			  }
			: null,
		{
			name: "selfValidation",
			value: isBeacon ? !!beacon.IsSelfValidation : !!qrCode.IsSelfValidation,
			validator: Yup.boolean(),
		},
		{
			name: "validationSites",
			value: (isBeacon ? beacon.sites : qrCode.sites) || [],
			validator: Yup.array(),
		},
		{
			name: "availableSites",
			value: availableSites,
			validator: Yup.array(),
		},
		{
			name: "availableSpaces",
			value: availableSpaces,
			validator: Yup.array(),
		},
		{
			name: "validationType",
			value: isBeacon ? beacon.ValidationType : qrCode.ValidationType,
			validator: Yup.string(),
		},
		{
			name: "validationTypeOptions",
			value: validationTypeOptions,
			validator: Yup.array(),
		},
		{
			name: "validationValue",
			value: isBeacon ? beacon.ValidationValue : qrCode.ValidationValue,
			validator: Yup.mixed().when("validationType", {
				is: (val) => val === "Rate" || val === "GroupAccess",
				then: Yup.mixed().notRequired(),
				otherwise: Yup.number()
					.positive()
					.required("Please supply a value for this beacon")
					.typeError("Please specify a number"),
			}),
		},
		{
			name: "rateData",
			value: rateData,
			validator: Yup.array(),
		},
		{
			name: "rateName",
			value: (isBeacon ? beacon.rate : qrCode.rate) || "",
			validator: Yup.string(),
		},
		{
			name: "rateId",
			value: qrCode.RateID,
			validator: Yup.number().nullable(),
		},
		isBeacon
			? {
					name: "uniqueIdValid",
					value: true,
			  }
			: null,
		isBeacon
			? {
					name: "accessGroup",
					value: accessGroup
						? {
								value: accessGroup.OrganizationAccessGroupID,
								label: accessGroup.Name,
						  }
						: null,
					validator: Yup.object().nullable(),
			  }
			: null,
		{
			name: "type",
			value: type,
			validator: Yup.string(),
		},
		{
			name: "format",
			value: null,
			validator: Yup.string().nullable(),
		},
		{
			name: "security",
			value: null,
			validator: Yup.object().nullable(),
		},
		!isBeacon && {
			name: "leaseParkIds",
			value:
				qrCode?.LeaseParks?.map((lp) => ({
					lp,
					key: availableSpaces.find((slp) =>
						slp.Spaces.some((s) => s.LeaseParkID === lp.LeaseParkID)
					)?.SiteID,
				}))
					.filter((item) => item.key !== undefined)
					.reduce((acc, item) => {
						acc[item.key] = item.lp.LeaseParkID;
						return acc;
					}, {}) || {},
			validator: Yup.array().nullable(true),
		},
	].filter((field) => field);

	switch (mode) {
		case "add":
			return {
				title: `Add Validation ${isBeacon ? "Beacon" : "QR Code"}`,
				initialStep: 0,
				steps: [
					steps.location,
					isBeacon ? steps.uniqueId : null,
					isBeacon ? steps.beaconType : null,
					isBeacon ? steps.groupAccess : null,
					steps.selfValidation,
					steps.validationType,
					steps.sites,
					!isBeacon ? steps.spaces : null,
					steps.validationValue,
					steps.summary,
				].filter((step) => step),
				fields,
			};
		case "update": {
			const updateSteps = [
				steps.location,
				isBeacon ? steps.uniqueId : null,
				isBeacon ? steps.beaconType : null,
				isBeacon ? steps.groupAccess : null,
				steps.selfValidation,
				steps.validationType,
				steps.sites,
				!isBeacon ? steps.spaces : null,
				steps.validationValue,
				steps.summary,
			].filter((step) => step);

			return {
				title: `Update Validation ${isBeacon ? "Beacon" : "QR Code"}`,
				initialStep: updateSteps.length - 1,
				steps: updateSteps,
				fields,
			};
		}

		case "delete":
			return {
				title: `Delete Validation ${isBeacon ? "Beacon" : "QR Code"}`,
				initialStep: 0,
				steps: [steps.summary],
				fields,
			};

		case "download":
			return {
				title: "Download Validation QR Code",
				initialStep: 0,
				steps: [steps.download],
				fields,
			};
	}
}

ValidationWizard.propTypes = {
	mode: PropTypes.oneOf(["add", "update", "delete"]).isRequired,
	availableSites: PropTypes.arrayOf(PropTypes.object).isRequired,
	close: PropTypes.func.isRequired,
	beacon: PropTypes.object,
	qrCode: PropTypes.object,
};

ValidationWizard.defaultProps = {
	beacon: {},
	qrCode: {},
};

function ValidationWizard(props) {
	const isBeacon = props.type === VALIDATION_WIZARD_TYPE.BEACON;

	const wizard = getWizard({
		mode: props.mode,
		type: props.type,
		organizationId: props.organizationId,
		organizationAccessGroups: props.organizationAccessGroups,
		beacon: props.beacon,
		qrCode: props.qrCode,
		availableSites: props.availableSites,
		availableSpaces: props.availableSpaces,
		validationTypeOptions: props.validationTypeOptions,
		rateData: props.rateData,
	});

	const createValidationQRCode = useMutateData(gql`
		mutation ($qrCode: ValidationQRCodeInput!) {
			createValidationQRCode(qrCode: $qrCode)
		}
	`);

	const updateValidationQRCode = useMutateData(gql`
		mutation ($qrCode: ValidationQRCodeInput!) {
			updateValidationQRCode(qrCode: $qrCode)
		}
	`);

	const deleteValidationQRCode = useMutateData(gql`
		mutation ($qrCodeId: Int!) {
			deleteValidationQRCode(qrCodeId: $qrCodeId)
		}
	`);

	const buildValidationObject = (values) => {
		const baseValidation = {
			isSelfValidation: !!values.selfValidation,
			validationType: values.validationType,
			accessGroupId: values.accessGroup?.value,
			location: values.location,
		};

		if (isBeacon) {
			return {
				...baseValidation,
				beaconId: values.beaconId,
			};
		}

		return {
			...baseValidation,
			qrCodeId: values.qrCodeId,
			leaseParkIds: Object.values(values.leaseParkIds).filter((v) => !!v),
		};
	};

	const handleRateValidation = (values, validation) => {
		validation.sitesWithRates = [];
		values.validationSites.map((site) => {
			validation.sitesWithRates.push({
				id: site.id || site.SiteID,
				rateId: site.rateId,
			});
		});
	};

	const handleNonRateValidation = (values, validation) => {
		validation.validationValue = values.validationValue;

		validation.siteIds = values.validationSites.map((site) => site.id);
	};

	const handleAddMode = async (values, validation) => {
		if (isBeacon) {
			validation.beaconId = values.uniqueId;
			await InstallationApi.createValidationBeacon(
				values.organizationId,
				validation
			);
		} else {
			await createValidationQRCode({
				variables: {
					qrCode: { ...validation, organizationId: values.organizationId },
				},
			});
		}
	};

	const handleUpdateMode = async (values, validation) => {
		if (isBeacon) {
			await InstallationApi.updateValidationBeacon(
				values.organizationId,
				values.beaconId,
				validation
			);
		} else {
			await updateValidationQRCode({
				variables: {
					qrCode: { ...validation, organizationId: values.organizationId },
				},
			});
		}
	};

	const handleDeleteMode = async (values) => {
		if (isBeacon) {
			await InstallationApi.deleteValidationBeaconById(
				values.organizationId,
				values.beaconId
			);
		} else {
			await deleteValidationQRCode({
				variables: { qrCodeId: values.qrCodeId },
			});
		}
	};

	const onSubmit = async (values, { setSubmitting }) => {
		setSubmitting(true);

		const validation = buildValidationObject(values);
		let message = "";

		if (values.validationType === "Rate") {
			handleRateValidation(values, validation);
		} else {
			handleNonRateValidation(values, validation);
		}

		try {
			if (props.mode === "add") {
				message = "added";
				await handleAddMode(values, validation);
			}

			if (props.mode === "update") {
				message = "updated";
				await handleUpdateMode(values, validation);
			}

			if (props.mode === "delete") {
				message = "deleted";
				await handleDeleteMode(values, validation);
			}

			if (props.mode === "download") {
				message = "downloaded";

				const security = values.security?.reduce((obj, item) => {
					Object.assign(obj, { [item.value]: true });
					return obj;
				}, {});

				const { error } = await WebPayApi.getValidationQRCodeUrl({
					format: values.format,
					security: security,
					qrCodeId: values.qrCodeId,
				});

				if (error) {
					Alert.error(`Could not download QR Code, ${error.message}`);
					setSubmitting(false);

					return;
				}
			}

			setSubmitting(false);
			Alert.success(`${isBeacon ? "Beacon" : "QR Code"} ${message}`);
			props.close(true);
		} catch (error) {
			setSubmitting(false);
			Alert.error("Something went wrong. Please try again.");
		}
	};

	return (
		<ErrorBoundary>
			<WizardLayout
				close={props.close}
				title={wizard.title}
				values={wizard.fields}
				onSubmit={onSubmit}
				steps={wizard.steps}
				initialStep={wizard.initialStep}
				wizardProps={props}
			/>
		</ErrorBoundary>
	);
}

export default ValidationWizard;
