import React from "react";
import { colours } from "../../styles";
import styled from "styled-components";

const Item = styled.div`
	color: ${(props) => (props.selected ? colours.green : "inherit")};
	font-size: 18px;
	font-weight: 600;
	height: 56px;
	line-height: 56px;
	padding: 0 24px;
	text-align: center;
	transition: 0.2s ease;
	white-space: nowrap;

	&:hover {
		cursor: pointer;
		opacity: 0.8;
	}
`;

export default function ContextMenu(props) {
	return (
		<Item selected={props.selected} onClick={props.onClick}>
			{props.itemName}
		</Item>
	);
}
