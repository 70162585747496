import * as billingApi from "../../../api/billing";
import { isEqual, pick } from "lodash";
import Alert from "react-s-alert";

function trimData(values, sites) {
	let data = pick(values, [
		"ADVAMAccountID",
		"IsDefault",
		"OrganizationID",
		"CompanyName",
		"TaxRegistration",
		"BillingAddress",
		"PaymentProvider",
		"BillingAccountID",
		"PXPostUsername",
		"PXPostSecretKey",
		"PxPayUsername",
		"PxPaySecretKey",
		"MerchantAccountSettlement",
		"ConvenienceFee",
		"ConvenienceFeePaidBy",
		"PaymentProcessingFixedFee",
		"PaymentProcessingPercentageFee",
		"UserPaysCreditCardFee",
		"AcceptedCreditCardTypes",
		"siteIds",
		"WebPayEnabled",
	]);

	// if no payment provider, clean up fee info
	if (!data.PaymentProvider) {
		data = {
			...data,
			BillingAccountID: null,
			PXPostUsername: null,
			PXPostSecretKey: null,
			PxPayUsername: null,
			PxPaySecretKey: null,
			MerchantAccountSettlement: null,
			ConvenienceFee: null,
			ConvenienceFeePaidBy: null,
			PaymentProcessingFixedFee: null,
			PaymentProcessingPercentageFee: null,
			UserPaysCreditCardFee: null,
			AcceptedCreditCardTypes: [],
		};
	}

	// if no settlement
	if (
		values.PaymentProvider === "PaymentExpress" &&
		!values.MerchantAccountSettlement
	) {
		data = {
			...data,
			PaymentProcessingFixedFee: null,
			PaymentProcessingPercentageFee: null,
		};
	}

	if (values.PaymentProvider === "PaymentExpress") {
		data.BillingAccountID = null;
	}

	if (values.PaymentProvider === "Stripe") {
		data = {
			...data,
			PXPostUsername: null,
			PXPostSecretKey: null,
			PxPayUsername: null,
			PxPaySecretKey: null,
			MerchantAccountSettlement: null,
		};
	}

	if (isEqual(values.siteIds, [0])) {
		data.siteIds = sites.map((site) => site.SiteID);
	}

	if (values.WebPayEnabled) {
		data.BrandID = values.brand ? values.brand.value : null;
		data.WebPayTypes = values.webPayTypes.map((t) => t.value);
		data.SiteType = values.siteType ? values.siteType.value : null;
	}

	return data;
}

export async function createBillingDetails(props, values, setSubmitting) {
	const data = trimData(values, props.sites);

	try {
		await billingApi.createBillingDetails(values.OrganizationID, data);

		setSubmitting(false);
		Alert.success("Billing details added");
		props.close(true);
	} catch (error) {
		setSubmitting(false);
		Alert.error("Something went wrong. Please try again.");
		props.close(true);
	}
}

export async function updateBillingDetails(props, values, setSubmitting) {
	const data = trimData(values, props.sites);

	try {
		await billingApi.updateBillingDetails(
			values.OrganizationID,
			values.BillingDetailsID,
			data
		);

		setSubmitting(false);
		Alert.success("Billing details updated");
		props.close(true);
	} catch (error) {
		setSubmitting(false);
		Alert.error("Something went wrong. Please try again.");
		props.close(true);
	}
}

export async function deleteBillingDetails(props, values, setSubmitting) {
	try {
		await billingApi.deleteBillingDetails(
			values.OrganizationID,
			values.BillingDetailsID
		);

		setSubmitting(false);
		Alert.success("Billing details deleted");
		props.close(true);
	} catch (error) {
		setSubmitting(false);

		if (
			error.errors &&
			error.errors[0] &&
			error.errors[0].code === "BillingDetailsAssignedToSpace"
		) {
			Alert.error("Please remove it from space before deleting.");
		} else {
			Alert.error("Something went wrong. Please try again.");
		}

		props.close(true);
	}
}
