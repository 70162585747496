import { StepTitle } from "../../../WizardLayout";
import WizardNavigation from "../../../WizardNavigation";
import Button from "../../../../layout/Button";
import { Rates } from "./Rates";

export const CREATE_RATES_STEP = ({
	values,
	setFieldValue,
	next,
	previous,
	keyStrokeHandler,
}) => ({
	id: "create-rates",
	label: "Rates",
	render: () => {
		return (
			<div>
				<StepTitle>Rates</StepTitle>
				<Rates
					selectedRates={values.selectedRates}
					onChange={(value) => {
						setFieldValue("selectedRates", value);
					}}
				/>
			</div>
		);
	},
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="previous" color="blue" onClick={previous}>
					Previous
				</Button>,
			]}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={next}
					// disabled={!values.selectedSpace}
					keyStrokeHandler={keyStrokeHandler}
				>
					Next
				</Button>,
			]}
		/>
	),
});
