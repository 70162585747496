import React, { useContext, useEffect } from "react";
import { colours, mediaSizes } from "../../styles";
import { MenuContext } from "../../context/menu-context";
import PageTitle from "../layout/PageTitle";
import { X } from "react-feather";
import styled from "styled-components";

const Wrapper = styled.div`
	background-color: ${colours.offWhite};
	bottom: 0;
	left: ${(props) => (props.menuCollapsed ? 56 : 256)}px;
	overflow-y: auto;
	position: fixed;
	right: 0;
	top: 0;
	transition: 0.2s ease;
	z-index: 2;

	@media (max-width: ${mediaSizes.medium}px) {
		left: 0;
		top: 64px;
	}
`;

const TitleBar = styled.div`
	background-color: ${colours.highlightGrey};
	padding: 16px 32px;
`;

const CloseButton = styled.div`
	position: absolute;
	right: 24px;
	top: 24px;
	transition: 0.2s ease;

	&:hover {
		cursor: pointer;
		opacity: 0.8;
	}
`;

export default function DetailsPageWrapper(props) {
	const {
		state: { menuCollapsed },
	} = useContext(MenuContext);

	useEffect(() => {
		// set a class on the document body to prevent overflow scrolling
		document.body.classList.add("modal-open");

		return () => {
			document.body.classList.remove("modal-open");
		};
	}, []);

	return (
		<Wrapper menuCollapsed={menuCollapsed}>
			<TitleBar>
				<PageTitle>{props.title}</PageTitle>
				<CloseButton onClick={props.close}>
					<X size={40} />
				</CloseButton>
			</TitleBar>
			<div style={{ margin: "40px 64px" }}>
				{props.content}
				{props.children}
			</div>
		</Wrapper>
	);
}
