import * as Yup from "yup";
import * as steps from "./steps";
import React, { useContext } from "react";
import Alert from "react-s-alert";
import { AppContext } from "../../../context/app-context";
import ErrorBoundary from "../../../containers/ErrorBoundary";
import PropTypes from "prop-types";
import WizardLayout from "../WizardLayout";
import gql from "graphql-tag";
import { map } from "lodash";
import { useMutateData } from "../../../hooks";

function getWizard(type, mode, account) {
	switch (mode) {
		case "add":
			return {
				title: `Add ${type} account`,
				initialStep: 0,
				steps: [
					steps.selectName,
					steps.managed,
					steps.setEmail,
					steps.setAddress,
					steps.selectBeacons,
					steps.summary,
				],
			};
		case "update":
			return {
				title: account.accountId
					? `Update ${type} account`
					: `Update ${type} account invitation`,
				initialStep: 5,
				steps: [
					steps.selectName,
					steps.managed,
					steps.setEmail,
					steps.setAddress,
					steps.selectBeacons,
					steps.summary,
				],
			};
		case "delete":
			return {
				title: account.accountId
					? `Delete ${type} account`
					: `Delete ${type} account invitation`,
				initialStep: 0,
				steps: [steps.deleteStep],
			};
	}
}

AccountWizard.propTypes = {
	type: PropTypes.oneOf(["validation"]).isRequired,
	mode: PropTypes.oneOf(["add", "update", "delete"]).isRequired,
	beacons: PropTypes.arrayOf(PropTypes.object).isRequired,
	close: PropTypes.func.isRequired,
	account: PropTypes.object,
};

AccountWizard.defaultProps = {
	account: {},
};

function AccountWizard(props) {
	const wizard = getWizard(props.type, props.mode, props.account);

	const {
		dispatch: { getAvailableOrganizations },
	} = useContext(AppContext);

	const fields = [
		{
			name: "accountId",
			value: props.account.accountId,
			validator: Yup.number().integer().nullable(),
		},
		{
			name: "accountInvitationId",
			value: props.account.accountInvitationId,
			validator: Yup.number().integer().nullable(),
		},
		{
			name: "name",
			value: props.account.name,
			validator: Yup.string(),
		},
		{
			name: "isUnmanaged",
			value: props.account.isUnmanaged || false,
			validator: Yup.boolean(),
		},
		{
			name: "email",
			value: props.account.email,
			validator: Yup.string().email().nullable(),
		},
		{
			name: "validEmail",
			value: false,
			validator: Yup.boolean().nullable(),
		},
		{
			name: "useBillingAddress",
			value: false,
			validator: Yup.boolean().nullable(),
		},
		{
			name: "address",
			value: props.account.address,
			validator: Yup.string().nullable(),
		},
		{
			name: "billingAddress",
			value: props.account.billingAddress,
			validator: Yup.string().nullable(),
		},
		{
			name: "beacons",
			value: map(props.account.beacons, (beacon) => ({
				id: beacon.BeaconID,
				name: beacon.UniqueID,
			})),
			validator: Yup.array(Yup.object()),
		},
		{
			name: "editFromSummary",
			value: props.mode === "update",
			validator: Yup.boolean(),
		},
	];

	const createAccountInvitationMutation = useMutateData(gql`
		mutation ($name: String!, $email: String!, $beacons: [Int!]) {
			createAccountInvitation(name: $name, email: $email, beacons: $beacons) {
				Name
				Email
				Beacons {
					BeaconID
				}
			}
		}
	`);

	const createAccountMutation = useMutateData(gql`
		mutation (
			$name: String!
			$address: String!
			$billingAddress: String
			$beacons: [Int!]
		) {
			createUnmanagedAccount(
				name: $name
				address: $address
				billingAddress: $billingAddress
				beacons: $beacons
			) {
				Name
				Address
				BillingAddress
				Beacons {
					BeaconID
				}
			}
		}
	`);

	const updateAccountMutation = useMutateData(gql`
		mutation (
			$accountId: Int!
			$name: String!
			$address: String!
			$billingAddress: String
			$beacons: [Int!]
		) {
			updateAccount(
				id: $accountId
				name: $name
				address: $address
				billingAddress: $billingAddress
				beacons: $beacons
			) {
				Name
				Address
				BillingAddress
				Beacons {
					BeaconID
				}
			}
		}
	`);

	const updateAccountInvitationMutation = useMutateData(gql`
		mutation ($accountInvitationId: Int!, $name: String!, $beacons: [Int!]) {
			updateAccountInvitation(
				accountInvitationId: $accountInvitationId
				name: $name
				beacons: $beacons
			) {
				Name
				Beacons {
					BeaconID
				}
			}
		}
	`);

	const deleteAccountMutation = useMutateData(gql`
		mutation ($accountId: Int!) {
			deleteAccount(id: $accountId)
		}
	`);

	const deleteAccountInvitationMutation = useMutateData(gql`
		mutation ($accountInvitationId: Int!) {
			deleteAccountInvitation(accountInvitationId: $accountInvitationId)
		}
	`);

	const onSubmit = async (values, { setSubmitting }) => {
		let message;

		try {
			setSubmitting(true);

			let variables = {
				variables: {
					...values,
					beacons: values.beacons.map((beacon) => beacon.id),
				},
			};

			if (props.mode === "add") {
				message = "added";

				values.isUnmanaged
					? await createAccountInvitationMutation(variables)
					: await createAccountMutation(variables);
			}

			if (props.mode === "update") {
				message = "updated";

				values.accountId
					? await updateAccountMutation(variables)
					: await updateAccountInvitationMutation(variables);
			}

			if (props.mode === "delete") {
				message = "deleted";

				values.accountId
					? await deleteAccountMutation(variables)
					: await deleteAccountInvitationMutation(variables);
			}

			Alert.success(`Account ${message}`);
			props.close(true);
			setSubmitting(false);

			if (props.mode === "add" && values.isUnmanaged) {
				getAvailableOrganizations();
			}
		} catch (error) {
			setSubmitting(false);
			Alert.error("Something went wrong. Please try again.");
		}
	};

	return (
		<ErrorBoundary>
			<WizardLayout
				close={props.close}
				title={wizard.title}
				values={fields}
				onSubmit={onSubmit}
				steps={wizard.steps}
				initialStep={wizard.initialStep}
				wizardProps={props}
				hideStepIndicator={true}
			/>
		</ErrorBoundary>
	);
}

export default AccountWizard;
