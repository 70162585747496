import * as installationApi from "../../api/installation";
import React, { useState } from "react";
import { useFetchData, usePermissions } from "../../hooks";
import Alert from "react-s-alert";
import Button from "../../components/layout/Button";
import Card from "../../components/layout/Card";
import DrawHoursBlocks from "../../components/hours/DrawHoursBlocks";
import ErrorBoundary from "../ErrorBoundary";
import FormLayout from "../../components/layout/FormLayout";
import HoursWizard from "../../components/wizards/hours-wizard/HoursWizard";
import LoadingPlaceholder from "../../components/report/LoadingPlaceholder";
import { cloneDeep } from "lodash";
import moment from "moment";

export default function HoursContainer(props) {
	const [state, setState] = useState({
		hoursWizardOpen: false,
		hoursWizardMode: "remove",
		customDate: "",
		customDayKey: "",
		disabledDate: [],
		invalidDay: [],
		invalidCustomDay: [],
		reloadToggle: false,
	});

	const hasEditPermissions = usePermissions(null, "EditSettings");

	const minDate = moment().startOf("day").toDate();

	const { data } = useFetchData(
		{},
		installationApi.getSiteOpeningHours,
		[props.selectedSite.SiteID],
		[state.reloadToggle, props.selectedSite.SiteID]
	);

	const initialValues = { ...data, newCustomDate: [], removedCustomDate: [] };

	const applyToAll = (daysValue, setFieldValue) => {
		const days = cloneDeep(daysValue);
		const mondayBlocks = cloneDeep(
			days[0].default.blocks.map((block) => ({
				from: block.from,
				to: block.to,
			}))
		);

		const modifiedDays = days.map((d) => {
			if (daysValue[0].default.open) {
				return d.default.name === "Monday"
					? d
					: {
							custom: d.custom,
							default: {
								...d.default,
								all: [],
								blocks: cloneDeep(mondayBlocks),
								open: true,
							},
					  };
			} else {
				return {
					custom: d.custom,
					default: {
						...d.default,
						all: d.default.blocks,
						blocks: [],
						open: false,
					},
				};
			}
		});

		setFieldValue("days", modifiedDays);
	};

	function handleNewCustomDate(values, customDate, setFieldValue) {
		let days = cloneDeep(values.days);
		let newCustomDate = cloneDeep(values.newCustomDate);

		const index = moment(customDate, "YYYYMMDD").isoWeekday() - 1;

		days[index].custom.push({
			all: [],
			blocks: [
				{
					from: { time: "00:00" },
					to: { time: "23:59" },
					customDate: customDate,
				},
			],
			name: moment(customDate, "YYYYMMDD").format("DD MMM YYYY"),
			customDate: customDate,
			open: true,
		});

		newCustomDate.push(customDate);

		setFieldValue("days", days);
		setFieldValue("newCustomDate", newCustomDate);
	}

	function handleRemoveCustomDate(values, setFieldValue) {
		let days = cloneDeep(values.days);
		let removedCustomDate = cloneDeep(values.removedCustomDate);

		const index = moment(state.customDate, "YYYYMMDD").isoWeekday() - 1;

		days[index].custom.splice(state.customDayKey, 1);
		removedCustomDate.push(state.customDate);

		setFieldValue("days", days);
		setFieldValue("removedCustomDate", removedCustomDate);
	}

	function openHoursWizard(mode, customDate, customDayKey, disabledDate) {
		setState((_state) => ({
			..._state,
			hoursWizardOpen: true,
			hoursWizardMode: mode,
			customDate: customDate,
			customDayKey: customDayKey,
			disabledDate: disabledDate,
		}));
	}

	function closeHoursWizard() {
		setState((_state) => ({
			..._state,
			hoursWizardOpen: false,
		}));
	}

	async function _handleSubmit(values, { setSubmitting }) {
		setSubmitting(true);

		try {
			await installationApi
				.updateSiteOpeningHours(props.selectedSite.SiteID, {
					days: values.days,
				})
				.then(function (response) {
					if (response.hasInvalidBlocks) {
						Alert.error(
							"Overlapping hours of operation. Please review the hours block with alert icon and provide valid hours block."
						);
					} else {
						//if no confilct blocks, reload to order blocks.

						setState((_state) => ({
							..._state,
							reloadToggle: !state.reloadToggle,
						}));

						Alert.success("Opening hours updated");
					}

					setState((_state) => ({
						..._state,
						invalidDay: response.dayOfInvalid ? response.dayOfInvalid : [],
						invalidCustomDay: response.invalidCustomDate
							? response.invalidCustomDate
							: [],
					}));
				});
		} catch (error) {
			Alert.error("Something went wrong. Please try again.");
		}

		setSubmitting(false);
	}

	if (!data.days) {
		return <LoadingPlaceholder />;
	}

	return (
		<ErrorBoundary>
			<FormLayout
				enableReinitialize={true}
				initialValues={initialValues}
				onSubmit={_handleSubmit}
				render={({ values, handleSubmit, isSubmitting, setFieldValue }) => {
					if (state.hoursWizardOpen) {
						return (
							<HoursWizard
								close={closeHoursWizard}
								mode={state.hoursWizardMode}
								customDate={state.customDate}
								siteId={props.selectedSite.SiteID}
								dateValue={minDate}
								disabledDate={state.disabledDate}
								newCustom={(value) => {
									handleNewCustomDate(values, value, setFieldValue);
								}}
								removeCustom={() => {
									handleRemoveCustomDate(values, setFieldValue);
								}}
							/>
						);
					} else {
						return (
							<>
								<Card>
									<h2 style={{ margin: "20px 35px" }}>Hours of Operation</h2>

									{values.days.map((day, index) => (
										<DrawHoursBlocks
											key={index}
											operationalIndex={index}
											values={values}
											weekDay={day}
											setFieldValue={setFieldValue}
											openHoursWizard={openHoursWizard}
											applyToAll={applyToAll}
											isOperationalHours={true}
											hasInvalidDayBlocks={state.invalidDay}
											hasEditPermissions={hasEditPermissions}
										/>
									))}

									<h2 style={{ margin: "20px 35px" }}>Custom Date</h2>

									<DrawHoursBlocks
										values={values}
										setFieldValue={setFieldValue}
										openHoursWizard={openHoursWizard}
										isOperationalHours={false}
										hasInvalidCustomBlocks={state.invalidCustomDay}
										hasEditPermissions={hasEditPermissions}
									/>

									<div style={{ margin: "5px 35px" }}>
										<Button
											hidden={!hasEditPermissions}
											color="blue"
											onClick={() => {
												openHoursWizard(
													"addCustom",
													null,
													null,
													values.datesForDisable
														.concat(values.newCustomDate)
														//make sure we filter out removed dates
														.filter(
															(e) => !values.removedCustomDate.includes(e)
														)
												);
											}}
										>
											Add Custom Date
										</Button>
									</div>
								</Card>
								<div style={{ textAlign: "right" }}>
									<Button
										hidden={!hasEditPermissions}
										color="green"
										onClick={handleSubmit}
										disabled={isSubmitting || !hasEditPermissions}
									>
										Update Hours
									</Button>
								</div>
							</>
						);
					}
				}}
			/>
		</ErrorBoundary>
	);
}
