import { StepText, StepTitle } from "../WizardLayout";
import Button from "../../layout/Button";
import React from "react";
import WizardNavigation from "../WizardNavigation";
import MultiChoiceButton from "../MultiChoiceButton";

export const secretStep = ({ values, setFieldValue, next, goTo, close }) => {
	return {
		id: "secret",
		label: "Secret",
		render: () => (
			<div>
				<StepTitle>Do you want this variable to be a secret?</StepTitle>

				{["No", "Yes"].map((value) => (
					<MultiChoiceButton
						key={value}
						selected={values.isSecret === value}
						onClick={() => {
							setFieldValue("isSecret", value);
						}}
					>
						{value}
					</MultiChoiceButton>
				))}
			</div>
		),
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="submit" color="blue" onClick={close}>
										Cancel
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
};

export const deleteStep = ({ handleSubmit, isSubmitting, wizardProps }) => ({
	id: "delete",
	label: "Delete",
	render: () => (
		<div>
			<StepText>Are you sure you want to delete this variable?</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={isSubmitting}
				>
					Delete
				</Button>,
			]}
		/>
	),
});
