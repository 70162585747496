import { AlertCircle } from "react-feather";
import React from "react";
import { colours } from "../../styles";
import styled from "styled-components";

const Wrapper = styled.div`
	color: ${colours.yellow};

	.field-warning-text {
		display: inline-block;
	}

	.field-warning-icon {
		padding-right: 5px;
		float: left;
	}
`;

export default function Warning(props) {
	return (
		<Wrapper>
			<div>
				<div className="field-warning-text">
					<div className="field-warning-icon">
						<AlertCircle size={16} />
					</div>
					{props.text}
				</div>
			</div>
		</Wrapper>
	);
}
