import * as installationApi from "../../../../api/installation";
import { Plus } from "react-feather";
import React, { useContext, useState } from "react";
import { filter, find, forEach, map } from "lodash";
import {
	useFetchData,
	usePermissions,
	useSearchFilter,
} from "../../../../hooks";
import { AppContext } from "../../../../context/app-context";
import Button from "../../../../components/layout/Button";
import GroupsWizard from "../../../../components/wizards/groups-wizard/GroupsWizard";
import ReportWrapper from "../../../../components/report/ReportWrapper";
import { ManageColumns } from "../ManageColumns";
import { groupsColumns, groupsDefaultSorting } from "./helpers";
import { useGetNodesAndSpaceAccounts } from "./data";

export default function GroupsContainer(props) {
	const [state, setState] = useState({
		search: "",
		groupsWizardOpen: false,
		groupsWizardGroup: null,
		groupsWizardMode: "add",
	});

	const {
		state: { currentUser, spaceAccountOwnerOnly },
	} = useContext(AppContext);

	function openGroupsWizard(group, mode) {
		setState((_state) => ({
			..._state,
			groupsWizardOpen: true,
			groupsWizardGroup: group,
			groupsWizardMode: mode,
		}));
	}

	async function updateOptions({ search }) {
		setState((_state) => ({ ..._state, initialLoad: false, search }));
	}

	const canManageUsers =
		usePermissions(null, "ManageUsers", true) || spaceAccountOwnerOnly;

	const canManageEvents =
		usePermissions(null, "ManageEvents", true) || spaceAccountOwnerOnly;

	const organizationId = props.selectedOrganization
		? props.selectedOrganization.OrganizationID
		: null;

	const { data, isLoading } = useFetchData(
		[],
		[
			installationApi.getOrganizationAccessGroupsWithSites,
			installationApi.getLeaseRatesForOrganizationId,
		],
		[[organizationId], [organizationId]],
		[organizationId, state.groupsWizardOpen]
	);

	const { nodes, spaceAccounts } = useGetNodesAndSpaceAccounts(organizationId);

	const [organizationAccessGroups, leaseRates] = data;

	// display site names
	forEach(organizationAccessGroups, (group) => {
		const account = find(
			spaceAccounts,
			(_account) => _account.SpaceAccountID === group.SpaceAccountID
		);

		group.displaySites = map(group.sites, (site) => site.Name).join(", ");
		group.accountName = account ? account.Name : "";

		group.locationBasedAccessNodes = map(
			filter(group.nodes, (node) => node.LocationBasedAccess),
			(node) => node.Name || node.SerialNumber
		).join(", ");

		group.restrictedAccessNodes = map(
			filter(group.nodes, (node) => node.GroupBasedAccess),
			(node) => node.Name || node.SerialNumber
		).join(", ");
	});

	const filteredGroups = useSearchFilter(
		organizationAccessGroups || [],
		state.search,
		["Name", "displaySites"]
	);

	if (state.groupsWizardOpen) {
		return (
			<GroupsWizard
				close={() => {
					setState((_state) => ({ ..._state, groupsWizardOpen: false }));
				}}
				mode={state.groupsWizardMode}
				selectedOrganization={props.selectedOrganization}
				group={state.groupsWizardGroup}
				groups={organizationAccessGroups}
				sites={props.availableSites}
				leaseRates={leaseRates}
				nodes={nodes}
				spaceAccounts={spaceAccounts}
				spaceAccountOwnerOnly={spaceAccountOwnerOnly}
				user={currentUser}
				hasEventManagement={props.selectedOrganization?.hasEventManagement}
			/>
		);
	}

	return (
		<ReportWrapper
			{...props}
			title={"User Groups"}
			data={filteredGroups}
			columns={
				canManageUsers || canManageEvents
					? ManageColumns(props, groupsColumns, openGroupsWizard)
					: groupsColumns
			}
			defaultSorted={groupsDefaultSorting}
			updateOptions={updateOptions}
			loading={isLoading}
			showDateRangePicker={false}
			showSitePicker={false}
			rightActions={
				canManageUsers || canManageEvents ? (
					<Button color="blue" onClick={() => openGroupsWizard(null, "add")}>
						<Plus size={20} /> Create Group
					</Button>
				) : null
			}
		/>
	);
}
