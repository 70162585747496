import Button from "../../../layout/Button";
import React from "react";
import { StepText } from "../../WizardLayout";
import WizardNavigation from "../../WizardNavigation";

export default ({ handleSubmit, isSubmitting, values, wizardProps }) => ({
	id: "remove",
	label: "Remove",
	render: () => (
		<div>
			<StepText>
				Are you sure you want to remove the rate &quot;{values.name}&quot;?
			</StepText>
			<p>
				Note: Users already being billed at this Rate will not have their
				session interrupted.
			</p>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={isSubmitting}
				>
					Remove
				</Button>,
			]}
		/>
	),
});
