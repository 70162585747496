import * as systemApi from "../../../api/system";
import * as Yup from "yup";
import * as steps from "./steps";
import {
	CREATE_METHOD_KEYS,
	EVENT_TYPES,
	INTEGRATION_TYPES,
} from "../../../helpers/constants";
import React, { useState } from "react";
import Alert from "react-s-alert";
import WizardLayout from "../WizardLayout";
import gql from "graphql-tag";
import { useMutateData } from "../../../hooks";

const constructWizard = (
	props,
	integrationType,
	setIntegrationType,
	createMethod,
	setCreateMethod,
	eventType,
	setEventType,
	hasVariables,
	setHasVariables
) => {
	const integration = props.integration
		? props.integration
		: {
				IntegrationType: integrationType,
				EventType: eventType,
		  };

	const isEnabled = integration?.IsEnabled ? true : false;

	const integrationIdSchema = {
		name: "integrationId",
		value: integration?.IntegrationID,
		validator: Yup.number(),
	};
	const isEnabledSchema = {
		name: "isEnabled",
		value: isEnabled,
		validator: Yup.boolean(),
	};

	if (props.mode === "delete") {
		return {
			initialStep: 0,
			steps: [steps.deleteStep],
			values: [integrationIdSchema],
			title: "Delete Integration",
		};
	}

	if (props.mode === "toggle") {
		return {
			initialStep: 0,
			steps: [steps.toggleStep(isEnabled)],
			values: [integrationIdSchema, isEnabledSchema],
			title: `${isEnabled ? "Disable" : "Enable"} Integration`,
		};
	}

	const isExporting = props.mode === "export";

	const isPolling = integration.IntegrationType === INTEGRATION_TYPES.POLLING;
	const isFileTransfer =
		integration.IntegrationType === INTEGRATION_TYPES.FILE_TRANSFER;

	const contextualStep = steps.selectContextStep("Sites");

	const isImporting = [
		CREATE_METHOD_KEYS.IMPORT,
		CREATE_METHOD_KEYS.TEMPLATE,
	].includes(createMethod);

	const currentSteps = isExporting
		? [steps.summaryStep(props.mode)]
		: [
				steps.createMethodStep(
					setCreateMethod,
					setIntegrationType,
					setHasVariables
				),
				steps.integrationTypeStep(setIntegrationType),
				steps.eventTypeStep(setEventType),
				props.context === "Client" ? contextualStep : null,
				steps.requestStep,
				isPolling ? steps.intervalStep : null,
				isPolling ? steps.expiryStep : null,
				isFileTransfer ? steps.directoryStep : null,
				isFileTransfer ? steps.headersStep : null,
				isFileTransfer ? steps.mapperStep : null,
				isImporting && hasVariables ? steps.variablesStep : null,
				steps.synchronousStep,
				steps.isEnabledStep,
				steps.loggingEnabledStep,
				steps.summaryStep(props.mode),
		  ].filter((step) => step);

	const isAdding = props.mode === "add";

	const getInitialContextualStep = (name, valueProp, labelProp, dataProp) => {
		const data = integration?.[name];
		return {
			name: `selected${name}`,
			value: data?.length
				? props[dataProp]?.length === data?.length
					? [{ value: 0, label: `All ${name}` }]
					: data?.map((option) => ({
							value: option[valueProp],
							label: option[labelProp],
					  }))
				: [],
			validator: Yup.array(Yup.object()),
		};
	};

	return {
		initialStep: isAdding ? 0 : currentSteps.length - 1,
		steps: currentSteps,
		values: [
			integrationIdSchema,
			{
				name: "createMethod",
				value: "New",
				validator: Yup.string(),
			},
			{
				name: "integrationType",
				value: integration?.IntegrationType,
				validator: Yup.string(),
			},
			{
				name: "eventType",
				value: integration?.EventType,
				validator: Yup.string(),
			},
			getInitialContextualStep(
				"Cameras",
				"CameraID",
				"SerialNumber",
				"cameras"
			),
			getInitialContextualStep("Sites", "SiteID", "Name", "availableSites"),
			{
				name: "availableCameras",
				value: props.cameras?.map((camera) => ({
					value: camera.CameraID,
					label: camera.SerialNumber,
				})),
				validator: Yup.array(Yup.object()),
			},
			{
				name: "availableSites",
				value: props.availableSites.map((site) => ({
					value: site.SiteID,
					label: site.Name,
				})),
				validator: Yup.array(Yup.object()),
			},
			{
				name: "requests",
				value: props.requests?.map((request) => ({
					value: request.RequestID,
					label: request.Name,
					method: request.Method,
					url: request.URL,
				})),
				validator: Yup.array(Yup.object()),
			},
			{
				name: "request",
				value: integration.FetchRequest
					? {
							value: integration.FetchRequest.RequestID,
							label: integration.FetchRequest.Name,
							method: integration.FetchRequest.Method,
							url: integration.FetchRequest.URL,
					  }
					: null,
				validator: Yup.object(),
			},
			{
				name: "interval",
				value: integration?.Metadata?.Interval || 2,
				validator: Yup.number(),
			},
			{
				name: "expiry",
				value: integration?.Metadata?.Expiry || 24,
				validator: Yup.number(),
			},
			...(isFileTransfer
				? [
						{
							name: "directory",
							value: integration?.Metadata?.Directory,
							validator: Yup.string(),
						},
				  ]
				: []),
			{
				name: "headers",
				value: integration?.Metadata?.CSVConfig?.Headers
					? integration?.Metadata?.CSVConfig?.Headers?.map((header) => ({
							column: header.Header,
							required: header.Required,
					  }))
					: [
							{ column: "cardnumber", required: true },
							{ column: "branchid", required: true },
							{ column: "status", required: true },
							{ column: "PersonType", required: true },
					  ],
				validator: Yup.array(),
			},
			{
				name: "mapper",
				value: {
					externalUserCode: integration?.Metadata?.ExternalUserCode
						? {
								column: integration?.Metadata?.ExternalUserCode?.Column,
								type: integration?.Metadata?.ExternalUserCode?.Type,
						  }
						: { column: "cardnumber", type: "String" },
					siteId: integration?.Metadata?.SiteID
						? {
								column: integration?.Metadata?.SiteID?.Column,
								type: integration?.Metadata?.SiteID?.Type,
						  }
						: { column: "branchid", type: "String" },
					groupName: integration?.Metadata?.GroupName
						? {
								column: integration?.Metadata?.GroupName?.Column,
								type: integration?.Metadata?.GroupName?.Type,
						  }
						: { column: "PersonType", type: "String" },
				},
				validator: Yup.object(),
			},
			{
				name: "valueMappings",
				value:
					integration?.Metadata?.CSVConfig?.MappedValues?.map((mv) => ({
						column: mv.Column,
						from: mv.From,
						to: mv.To,
					})) || [],
				validator: Yup.array().nullable(),
			},
			{
				name: "authType",
				value: integration?.AuthType || null,
				validator: Yup.string().nullable(),
			},
			isEnabledSchema,
			{
				name: "loggingEnabled",
				value: integration?.LoggingEnabled ? true : false,
				validator: Yup.boolean(),
			},
			{
				name: "exportAs",
				value: "file",
				validator: Yup.string().default("file"),
			},
			{
				name: "isSynchronous",
				value: integration?.IsSynchronous ?? false,
				validator: Yup.boolean(),
			},
		],
		title: `${isExporting ? "Export" : isAdding ? "Add" : "Edit"} Integration`,
	};
};

export default function IntegrationWizard(props) {
	const [integrationType, setIntegrationType] = useState(
		props.integration?.IntegrationType || INTEGRATION_TYPES.WEBHOOK
	);
	const [createMethod, setCreateMethod] = useState("create");
	const [eventType, setEventType] = useState(
		props.integration?.EventType || EVENT_TYPES.LPR
	);
	const [hasVariables, setHasVariables] = useState(false);

	const isImporting = [
		CREATE_METHOD_KEYS.IMPORT,
		CREATE_METHOD_KEYS.TEMPLATE,
	].includes(createMethod);

	const wizard = constructWizard(
		props,
		integrationType,
		setIntegrationType,
		createMethod,
		setCreateMethod,
		eventType,
		setEventType,
		hasVariables,
		setHasVariables
	);

	const createIntegrationTemplate = useMutateData(gql`
		mutation ($id: Int!, $name: String) {
			createIntegrationTemplate(id: $id, name: $name)
		}
	`);

	const createIntegration = useMutateData(gql`
		mutation (
			$createMethod: IntegrationCreateMethod!
			$context: IntegrationContext!
			$organizationId: Int
			$integrationType: IntegrationType!
			$eventType: EventType!
			$siteIds: [Int!]
			$cameraIds: [Int]
			$metadata: IntegrationMetadataInput
			$authType: AuthType
			$requestId: Int
			$isEnabled: Boolean
			$loggingEnabled: Boolean
			$requestUUID: String
			$requests: [RequestInput]
			$variables: [VariableInput]
			$isSynchronous: Boolean
		) {
			createIntegration(
				createMethod: $createMethod
				context: $context
				organizationId: $organizationId
				integrationType: $integrationType
				eventType: $eventType
				siteIds: $siteIds
				cameraIds: $cameraIds
				metadata: $metadata
				authType: $authType
				requestId: $requestId
				isEnabled: $isEnabled
				loggingEnabled: $loggingEnabled
				isSynchronous: $isSynchronous

				requestUUID: $requestUUID
				requests: $requests
				variables: $variables
			)
		}
	`);

	const deleteIntegration = useMutateData(gql`
		mutation ($id: Int!) {
			deleteIntegration(id: $id)
		}
	`);

	const updateIntegration = useMutateData(gql`
		mutation (
			$integrationId: Int!
			$integrationType: IntegrationType!
			$eventType: EventType!
			$siteIds: [Int!]
			$cameraIds: [Int]
			$metadata: IntegrationMetadataInput
			$authType: AuthType
			$requestId: Int
			$isEnabled: Boolean
			$loggingEnabled: Boolean
			$isSynchronous: Boolean
		) {
			updateIntegration(
				integrationId: $integrationId
				integrationType: $integrationType
				eventType: $eventType
				siteIds: $siteIds
				cameraIds: $cameraIds
				metadata: $metadata
				authType: $authType
				requestId: $requestId
				isEnabled: $isEnabled
				loggingEnabled: $loggingEnabled
				isSynchronous: $isSynchronous
			)
		}
	`);

	const disableIntegration = useMutateData(gql`
		mutation ($id: Int!) {
			disableIntegration(id: $id)
		}
	`);

	const enableIntegration = useMutateData(gql`
		mutation ($id: Int!) {
			enableIntegration(id: $id)
		}
	`);

	const onSubmit = async (values) => {
		try {
			if (props.mode === "export") {
				const asFile = values.exportAs === "file";
				if (asFile) {
					const data = await systemApi.exportIntegration(values.integrationId);
					const content = JSON.stringify(data.content);
					const bytes = new TextEncoder().encode(content);
					const blob = new Blob([bytes], {
						type: "application/json;charset=utf-8",
					});
					const a = document.createElement("a");
					a.href = window.URL.createObjectURL(blob);
					a.setAttribute("download", data.fileName);
					a.click();
				} else {
					await createIntegrationTemplate({
						variables: {
							id: values.integrationId,
							name: values.templateName,
						},
					});
				}
				Alert.success(
					`Integration exported ${asFile ? "as file" : "as template"}`
				);
			} else if (props.mode === "delete") {
				await deleteIntegration({
					variables: {
						id: values.integrationId,
						integrationType,
					},
				});
				Alert.success("Deleted integration");
			} else if (props.mode === "toggle") {
				if (values.isEnabled) {
					await disableIntegration({
						variables: {
							id: values.integrationId,
						},
					});
					Alert.success("Disabled integration");
				} else {
					await enableIntegration({
						variables: {
							id: values.integrationId,
						},
					});
					Alert.success("Enabled integration");
				}
			} else {
				const metadataByType = {
					[INTEGRATION_TYPES.POLLING]: {
						Interval: values.interval,
						Expiry: values.expiry,
					},
					[INTEGRATION_TYPES.FILE_TRANSFER]: {
						Directory: values.directory,
						CSVConfig: {
							Headers:
								values.headers?.map((header) => ({
									Header: header.column,
									Required: header.required,
								})) || [],
							ExternalUserCode: {
								Column: values.mapper?.externalUserCode?.column,
							},
							SiteID: {
								Column: values.mapper?.siteId?.column,
							},
							GroupName: {
								Column: values.mapper?.groupName?.column,
							},
							MappedValues:
								values.valueMappings?.map((mapping) => ({
									Column: mapping.column,
									From: mapping.from,
									To: mapping.to,
								})) || [],
						},
					},
					[INTEGRATION_TYPES.WEBHOOK]: null,
				};
				const variables = {
					integrationType: values.integrationType,
					eventType: values.eventType,
					siteIds: values.selectedSites?.some((s) => !s.value)
						? null
						: values.selectedSites?.map((s) => s.value),
					cameraIds: values.selectedCameras?.map((c) => c.value),
					metadata: metadataByType[values.integrationType],
					authType: values.authType,
					authRequestId: values.authRequest?.value,
					requestId: values.request?.value,
					isEnabled: values.isEnabled,
					loggingEnabled: values.loggingEnabled,
					isSynchronous: values.isSynchronous,
				};

				props.mode === "edit"
					? await updateIntegration({
							variables: {
								integrationId: values.integrationId,
								...variables,
							},
					  })
					: await createIntegration({
							variables: {
								createMethod: values.createMethod,
								context: props.context,
								organizationId: props.organizationId,
								...variables,

								requestId: isImporting ? 0 : values.request?.value,

								requestUUID: isImporting ? values.request?.value : "",
								requests: values.importContent?.Requests || [],
								variables: values.importContent?.Variables || [],
							},
					  });

				Alert.success(
					`${
						isImporting
							? "Imported"
							: props.mode === "edit"
							? "Updated"
							: "Added"
					} integration`
				);
			}
		} catch (error) {
			console.log(error);
			Alert.error("Something went wrong.");
		}

		props.close();
	};

	return (
		<WizardLayout
			rootTabIndex={null}
			close={props.close}
			title={wizard.title}
			onSubmit={onSubmit}
			values={wizard.values}
			steps={wizard.steps}
			initialStep={wizard.initialStep}
			wizardProps={props}
		/>
	);
}
