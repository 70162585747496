import * as installationApi from "../../../api/installation";
import Alert from "react-s-alert";

export async function deleteZone(props, values, setSubmitting) {
	try {
		await installationApi.deleteZone(props.siteId, values.zoneId);

		setSubmitting(false);
		Alert.success("Zone deleted");
		props.close(true);
	} catch (error) {
		setSubmitting(false);
		Alert.error("Something went wrong. Please try again.");
		props.close(true);
	}
}

export async function createZone(props, values, setSubmitting) {
	const data = {
		Name: values.name,
		BufferMeters: values.buffer,
		Points: values.points,
		SiteID: props.siteId,
	};

	try {
		await installationApi.createZone(props.siteId, data);

		setSubmitting(false);
		Alert.success("Zone created");
		props.close(true);
	} catch (error) {
		setSubmitting(false);
		Alert.error("Something went wrong. Please try again.");
		props.close(true);
	}
}

export async function updateZone(props, values, setSubmitting) {
	const data = {
		Name: values.name,
		BufferMeters: values.buffer,
		Points: values.points,
	};

	try {
		await installationApi.updateZone(props.siteId, values.zoneId, data);

		setSubmitting(false);
		Alert.success("Zone updated");
		props.close(true);
	} catch (error) {
		setSubmitting(false);
		Alert.error("Something went wrong. Please try again.");
		props.close(true);
	}
}
