import * as Yup from "yup";
import * as api from "../../api";
import Alert from "react-s-alert";
import Button from "../../components/layout/Button";
import EditableInputField from "../../components/layout/EditableInputField";
import FormLayout from "../../components/layout/FormLayout";
import LocalLink from "../../components/layout/LocalLink";
import React from "react";
import gql from "graphql-tag";
import { useMutateData } from "../../hooks";

function canSubmit(isSubmitting, errors, values) {
	return (
		!isSubmitting &&
		!errors.email &&
		!errors.password &&
		!!values.email &&
		!!values.password
	);
}

const schema = Yup.object().shape({
	email: Yup.string()
		.email("Please supply a valid email.")
		.required("Please supply an email."),
	password: Yup.string().min(6).required("Please supply a password."),
});

export default function LoginForm(props) {
	const loginMutation = useMutateData(gql`
		mutation ($email: Email, $password: String) {
			login(email: $email, password: $password) {
				SessionKey
			}
		}
	`);

	return (
		<FormLayout
			initialValues={{
				email: "",
				password: "",
			}}
			validationSchema={schema}
			onSubmit={async (values, { setSubmitting }) => {
				try {
					setSubmitting(true);
					const { data } = await loginMutation({
						variables: {
							email: values.email,
							password: values.password,
						},
					});

					if (data.login.SessionKey) {
						api.setSessionKey(data.login.SessionKey);
					}

					window.location.href = "/";
				} catch (error) {
					setSubmitting(false);
					Alert.error(
						"The email and password combination you provided doesn't match an existing account."
					);
				}
			}}
			render={({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
			}) => (
				<form className="form" onSubmit={handleSubmit}>
					<EditableInputField
						type="email"
						name="email"
						useLabel="Email"
						onChange={(value) => {
							handleChange(value);
							props.onEmailChange(value.target.value);
						}}
						onBlur={handleBlur}
						value={values.email}
						error={errors.email && touched.email ? errors.email : null}
					/>

					<EditableInputField
						type="password"
						name="password"
						useLabel="Password"
						onChange={handleChange}
						onBlur={handleBlur}
						value={values.password}
						error={errors.password && touched.password ? errors.password : null}
					/>

					<Button
						type="submit"
						color="green"
						disabled={!canSubmit(isSubmitting, errors, values)}
					>
						Log in
					</Button>

					<div
						style={{
							display: "block",
							margin: "64px 0 32px",
							textAlign: "center",
						}}
					>
						<LocalLink to="/forgot-password">Forgot password?</LocalLink>
					</div>
				</form>
			)}
		/>
	);
}
