import * as Yup from "yup";
import * as steps from "./steps";
import {
	convertModeNameToWizardTitle,
	submitMutation,
} from "../wizard-helpers";
import React from "react";
import WizardLayout from "../WizardLayout";
import gql from "graphql-tag";
import { useMutateData } from "../../../hooks";

const constructWizard = (props) => {
	switch (props.mode) {
		case "add-pay-station":
			return {
				initialStep: 0,
				steps: [steps.payStationStep],
			};
		case "edit-pay-station":
			return {
				initialStep: 0,
				steps: [steps.payStationStep],
			};
		case "remove-pay-station":
			return {
				initialStep: 0,
				steps: [steps.removePayStationStep],
			};
		case "add-kiosk":
			return {
				initialStep: 0,
				steps: [
					steps.kioskSerialNumberStep,
					steps.kioskNameStep,
					steps.kioskSummaryStep,
				],
			};
		case "remove-kiosk":
			return {
				initialStep: 0,
				steps: [steps.removeKioskStep],
			};
	}
};

export default function PayStationWizard(props) {
	const wizard = constructWizard(props);
	const createPayStationMutation = useMutateData(gql`
		mutation ($installationId: Int!, $name: String, $groupName: String) {
			createPayStation(
				installationId: $installationId
				name: $name
				groupName: $groupName
			) {
				PayStationID
			}
		}
	`);

	const editPayStationMutation = useMutateData(gql`
		mutation ($payStationId: Int!, $name: String, $groupName: String) {
			editPayStation(
				payStationId: $payStationId
				name: $name
				groupName: $groupName
			) {
				PayStationID
			}
		}
	`);

	const deletePayStationMutation = useMutateData(gql`
		mutation ($payStationId: Int!) {
			deletePayStation(payStationId: $payStationId)
		}
	`);

	const addKioskToPayStationMutation = useMutateData(gql`
		mutation ($payStationId: Int!, $hardwareId: Int!, $kioskName: String!) {
			addKioskToPayStation(
				payStationId: $payStationId
				hardwareId: $hardwareId
				kioskName: $kioskName
			) {
				PayStationID
			}
		}
	`);

	const removeKioskFromPayStationMutation = useMutateData(gql`
		mutation ($payStationId: Int!, $hardwareId: Int!) {
			removeKioskFromPayStation(
				payStationId: $payStationId
				hardwareId: $hardwareId
			) {
				PayStationID
			}
		}
	`);

	return (
		<WizardLayout
			close={props.close}
			title={(values) => convertModeNameToWizardTitle(values.mode)}
			values={[
				{
					name: "mode",
					value: props.mode,
					validator: Yup.string(),
				},
				{
					name: "payStationId",
					value: props.payStation?.PayStationID,
					validator: Yup.number().integer(),
				},
				{
					name: "payStationName",
					value: props.payStation?.Name,
					validator: Yup.string().nullable(),
				},
				{
					name: "groupName",
					value: props.groupName,
					validator: Yup.string().nullable(),
				},
				{
					name: "hardwareId",
					value: props.kiosk?.HardwareID,
					validator: Yup.number().integer().nullable(),
				},
				{
					name: "payStationKioskId",
					value: props.kiosk?.PayStationKioskID,
					validator: Yup.number().integer().nullable(),
				},
				{
					name: "serialNumber",
					value: props.kiosk?.SerialNumber,
					validator: Yup.string().nullable(),
				},
				{
					name: "kioskName",
					value: props.kiosk?.KioskName,
					validator: Yup.string().nullable(),
				},
			]}
			steps={wizard.steps}
			initialStep={wizard.initialStep}
			wizardProps={props}
			onSubmit={async (values) => {
				switch (values.mode) {
					case "add-pay-station":
						await submitMutation(
							createPayStationMutation,
							{
								installationId: props.installationId,
								name: values.payStationName,
								groupName: values.groupName,
							},
							props.close,
							"Pay station added."
						);
						break;
					case "edit-pay-station":
						await submitMutation(
							editPayStationMutation,
							{
								payStationId: values.payStationId,
								name: values.payStationName,
								groupName: values.groupName,
							},
							props.close,
							"Pay station updated."
						);
						break;
					case "remove-pay-station":
						await submitMutation(
							deletePayStationMutation,
							{ payStationId: values.payStationId },
							props.close,
							"Pay station removed."
						);
						break;
					case "add-kiosk":
						{
							await submitMutation(
								addKioskToPayStationMutation,
								{
									payStationId: values.payStationId,
									hardwareId: values.hardwareId,
									kioskName: values.kioskName,
								},
								props.close,
								"Kiosk added."
							);
						}
						break;
					case "remove-kiosk":
						await submitMutation(
							removeKioskFromPayStationMutation,
							{
								payStationId: values.payStationId,
								hardwareId: values.hardwareId,
							},
							props.close,
							"Kiosk removed."
						);
						break;
				}
			}}
		/>
	);
}
