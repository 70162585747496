import { CheckCircle, Circle } from "react-feather";
import Button from "../layout/Button";
import React from "react";
import { colours } from "../../styles";
import styled from "styled-components";

const Wrapper = styled(Button)`
	background: transparent;
	border: 2px solid
		${(props) => (props.selected ? colours.green : colours.borderGrey)};
	display: block;
	height: 80px;
	margin: 24px 0;
	outline: none;
	padding: 24px;
	width: 300px;
`;

const Icon = styled.div`
	left: 24px;
	position: absolute;
	top: 26px;
`;

const Text = styled.div`
	margin-left: 48px;
	text-align: left;
`;

export default function MultiChoiceButton(props) {
	return (
		<Wrapper {...props}>
			<Icon>
				{props.selected ? (
					<CheckCircle size={24} color={colours.green} />
				) : (
					<Circle size={24} />
				)}
			</Icon>
			<Text>{props.children}</Text>
		</Wrapper>
	);
}
