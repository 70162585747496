import React from "react";
import { colours } from "../../styles";
import styled from "styled-components";

const Wrapper = styled.div`
	border: solid ${(props) => colours[props.borderColor]};
	color: ${(props) => colours[props.textColor]};
	background-color: ${(props) => colours[props.backgroundColor]};
	padding: 16px;
	border-radius: 4px;
	margin-top: ${(props) => props.top || "0px"};
	margin-bottom: ${(props) => props.bottom || "16px"};
	margin-left: ${(props) => props.left || "0px"};
	margin-right: ${(props) => props.right || "0px"};
`;

export default function AlertMessage(props) {
	return (
		<Wrapper
			textColor={props.textColor}
			borderColor={
				props.borderColor ? props.borderColor : props.backgroundColor
			}
			backgroundColor={props.backgroundColor}
			hidden={props.hidden}
			top={props.top}
			bottom={props.bottom}
			left={props.left}
			right={props.right}
		>
			{props.text}
		</Wrapper>
	);
}
