import { DollarSign } from "react-feather";
import EditableInputField from "../layout/EditableInputField";
import React from "react";
import { colours } from "../../styles";
import styled from "styled-components";

const Wrapper = styled.div`
	display: flex;
	position: relative;
	margin-left: -10px;

	svg {
		margin-top: 5px;
		margin-right: -10px;
	}

	> * {
		margin: 0 10px;
	}
`;

const Title = styled.div`
	font-size: 18px;
	font-weight: 700;
	margin-left: ${(props) => props.left};
	margin-bottom: 10px;
`;

export default function FeeBlock(props) {
	const {
		isDisabled,
		isTitle,
		isLastBlock,
		fee,
		max,
		min,
		onChange,
		handleBlur,
	} = props;
	return (
		<div>
			{isTitle && (
				<Wrapper>
					<Title left={"15px"}>Parking Charge</Title>
					<Title left={"280px"}>Inugo Fee</Title>
				</Wrapper>
			)}
			{isLastBlock ? (
				<Wrapper>
					<DollarSign color={colours.blue} />
					<EditableInputField
						type="number"
						min="0"
						disabled={isDisabled}
						useLabel=" "
						onChange={(event) => onChange({ min: event.target.value })}
						value={min || ""}
					/>
					+
					<div style={{ width: "200px" }} />
					= <DollarSign color={colours.blue} />
					<EditableInputField
						type="number"
						min="0"
						disabled={isDisabled}
						name={"InugoFee"}
						useLabel=" "
						onChange={(event) => onChange({ fee: event.target.value })}
						value={fee || ""}
						onBlur={handleBlur}
					/>
				</Wrapper>
			) : (
				<Wrapper>
					<DollarSign color={colours.blue} />
					<EditableInputField
						type="number"
						min="0"
						disabled={isDisabled}
						useLabel=" "
						onChange={(event) => onChange({ min: event.target.value })}
						value={min || ""}
					/>
					to <DollarSign color={colours.blue} />
					<EditableInputField
						type="number"
						min="0"
						disabled={isDisabled}
						useLabel=" "
						onChange={(event) => onChange({ max: event.target.value })}
						value={max || ""}
					/>
					= <DollarSign color={colours.blue} />
					<EditableInputField
						type="number"
						min="0"
						disabled={isDisabled}
						name={"InugoFee"}
						useLabel=" "
						onChange={(event) => onChange({ fee: event.target.value })}
						value={fee || ""}
						onBlur={handleBlur}
					/>
				</Wrapper>
			)}
		</div>
	);
}
