import Button from "./Button";
import Modal from "react-modal";
import PropTypes from "prop-types";
import React from "react";
import { X } from "react-feather";
import { colours } from "../../styles";
import styled from "styled-components";

ModalDialog.propTypes = {
	close: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
	dialogText: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.object.isRequired,
	]),
	onLeftButtonClick: PropTypes.func,
	onRightButtonClick: PropTypes.func,
	leftButtonColor: PropTypes.string,
	rightButtonColor: PropTypes.string,
	leftButtonText: PropTypes.string,
	rightButtonText: PropTypes.string,
	leftButtonDisabled: PropTypes.bool,
	rightButtonDiabled: PropTypes.bool,
};

const modalStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		height: "30%",
		minHeight: "100px",
		width: "40%",
		minWidth: "300px",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		backgroundColor: `${colours.offWhite}`,
		padding: "16px",
	},
};

const CloseButton = styled.div`
	position: absolute;
	right: 16px;
	top: 16px;
	transition: 0.2s ease;

	&:hover {
		cursor: pointer;
		opacity: 0.8;
	}
`;

const DialogText = styled.div`
	margin: 48px 24px;
	font-size: 20px;
	font-weight: 600;
	line-height: 1.3em;
	text-align: center;
`;

const ButtonsWrapper = styled.div`
	position: absolute;
	bottom: 24px;
	width: 94%;
	display: flex;
`;

function ModalDialog(props) {
	Modal.setAppElement("#app");

	return (
		<Modal
			isOpen={props.isOpen}
			style={modalStyles}
			overlayClassName="ModalOverlay"
		>
			<CloseButton onClick={props.close}>
				<X size={30} />
			</CloseButton>
			<DialogText>{props.dialogText}</DialogText>
			<ButtonsWrapper>
				<div style={{ width: "50%" }}>
					{props.onLeftButtonClick && (
						<Button
							disabled={props.leftButtonDisabled}
							style={{ marginLeft: "24px" }}
							color={props.leftButtonColor || "red"}
							onClick={props.onLeftButtonClick}
						>
							{props.leftButtonText || "No"}
						</Button>
					)}
				</div>
				<div style={{ width: "50%" }}>
					{props.onRightButtonClick && (
						<Button
							disabled={props.rightButtonDisabled}
							style={{ float: "right", marginRight: "24px" }}
							color={props.rightButtonColor || "green"}
							onClick={props.onRightButtonClick}
						>
							{props.rightButtonText || "Yes"}
						</Button>
					)}
				</div>
			</ButtonsWrapper>
		</Modal>
	);
}

export default ModalDialog;
