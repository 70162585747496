import * as Yup from "yup";
import React, { useState } from "react";
import Alert from "react-s-alert";
import Button from "../../components/layout/Button";
import EditableInputField from "../../components/layout/EditableInputField";
import FormLayout from "../../components/layout/FormLayout";
import LocalLink from "../../components/layout/LocalLink";
import gql from "graphql-tag";
import styled from "styled-components";
import { useMutateData } from "../../hooks";

const FormComplete = styled.div`
	display: block;

	margin: 64px 0 32px;
	text-align: center;
`;

function canSubmit(isSubmitting, errors, values) {
	return !isSubmitting && !errors.email && !!values.email;
}

const schema = Yup.object().shape({
	email: Yup.string()
		.email("Please supply a valid email.")
		.required("Please supply an email."),
});

export default function ForgotPasswordForm(props) {
	const [state, setState] = useState({
		formComplete: false,
	});

	const requestPasswordResetMutation = useMutateData(gql`
		mutation ($email: Email!) {
			requestPasswordReset(email: $email)
		}
	`);

	return (
		<FormLayout
			initialValues={{
				email: props.email ? props.email : "",
			}}
			validationSchema={schema}
			onSubmit={async (values, { setSubmitting }) => {
				setSubmitting(true);
				try {
					await requestPasswordResetMutation({
						variables: {
							email: values.email,
						},
					});
					setSubmitting(false);
					Alert.success(
						"If the email address matches a valid account, a password reset will be sent."
					);

					setState((_state) => ({ ..._state, formComplete: true }));
				} catch (error) {
					setSubmitting(false);
					Alert.error("Something went wrong. Please try again.");
				}
			}}
			render={({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
			}) => (
				<div>
					<h1>Send Password Reset</h1>
					{!state.formComplete && (
						<form className="form" onSubmit={handleSubmit}>
							<EditableInputField
								type="email"
								name="email"
								useLabel="Email"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.email}
								error={errors.email && touched.email ? errors.email : null}
							/>

							<Button
								type="submit"
								color="green"
								disabled={!canSubmit(isSubmitting, errors, values)}
							>
								Reset password
							</Button>
						</form>
					)}
					{state.formComplete && (
						<FormComplete>
							A link has been sent to your email address.
						</FormComplete>
					)}
					<div
						style={{
							display: "block",
							margin: "64px 0 32px",
							textAlign: "center",
						}}
					>
						<LocalLink to="/login">Back to login</LocalLink>
					</div>
				</div>
			)}
		/>
	);
}
