import { StepText, StepTitle } from "../WizardLayout";
import { cloneDeep, keys } from "lodash";
import Button from "../../layout/Button";
import Dropdown from "../../layout/Dropdown";
import EditableInputField from "../../layout/EditableInputField";
import React from "react";
import TimeRangePicker from "../../layout/TimeRangePicker";
import Toggle from "../../layout/Toggle";
import Tooltip from "../../layout/Tooltip";
import WizardNavigation from "../WizardNavigation";
import { XCircle } from "react-feather";
import { colours } from "../../../styles";
import moment from "moment";
import { Label, DayLabel, Wrapper } from "./styles";
import { SiteSpaces } from "./SiteSpaces";
import Alert from "react-s-alert";

export const editStep = ({
	errors,
	handleBlur,
	handleChange,
	handleSubmit,
	isSubmitting,
	setFieldValue,
	touched,
	values,
	wizardProps,
	keyStrokeHandler,
	validateForm,
}) => ({
	id: "edit",
	label: "Edit",
	render: () => {
		const days = [
			"Monday",
			"Tuesday",
			"Wednesday",
			"Thursday",
			"Friday",
			"Saturday",
			"Sunday",
		];

		const defaultStartTime = moment("00:00", "HH:mm").toDate();

		const accountOptions = wizardProps?.spaceAccounts
			?.filter((account) => {
				if (wizardProps?.spaceAccountOwnerOnly) {
					if (
						wizardProps.user.SpaceAccountOwners.find(
							(e) => e.SpaceAccountID === account.SpaceAccountID
						)
					) {
						return true;
					} else {
						return false;
					}
				}

				return true;
			})
			.map((account) => ({
				label: account.Name,
				value: account.SpaceAccountID,
			}));

		const siteOptions = wizardProps.sites.map((site) => ({
			label: site.Name,
			value: site.SiteID,
		}));

		const rateOptions = wizardProps?.leaseRates?.map((leaseRate) => {
			return { label: leaseRate.Name, value: leaseRate.LeaseRateID };
		});
		rateOptions.unshift({ label: "No rate", value: -1 });

		const locationBasedNodeOptions = wizardProps.nodes.map((node) => {
			return { label: node.Name || node.SerialNumber, value: node.NodeID };
		});

		const restrictedAccessNodeOptions = wizardProps.nodes
			.filter((node) => node.RestrictedAccess)
			.map((node) => {
				return { label: node.Name || node.SerialNumber, value: node.NodeID };
			});

		function handleTimeChange(group, day, time, isOpen) {
			const _group = cloneDeep(group);
			if (time) {
				if (time.from) {
					_group[`${day}OpeningTimestamp`] =
						moment(time.from).unix() - moment(defaultStartTime).unix();
				} else {
					_group[`${day}OpeningTimestamp`] = null;
				}
				if (time.to) {
					_group[`${day}ClosingTimestamp`] =
						moment(time.to).unix() - moment(defaultStartTime).unix();
				} else {
					_group[`${day}ClosingTimestamp`] = null;
				}
			} else {
				_group[`${day}Open`] = isOpen ? 1 : 0;
			}

			setFieldValue("group", _group);
		}

		return (
			<div>
				<StepTitle>Group details:</StepTitle>

				<EditableInputField
					type="text"
					name="name"
					onChange={handleChange}
					onBlur={handleBlur}
					value={values.name || ""}
					useLabel="Name"
					error={touched.name && errors.name ? errors.name : null}
				/>

				{values.hasEventManagement && (
					<Toggle
						label="Event Pass"
						onChange={(value) => {
							setFieldValue("isEvent", value);

							if (value) {
								setFieldValue("sessionlessAccess", false);
								setFieldValue("overridesOpeningHours", false);
							}
						}}
						checked={values.isEvent}
						disabled={wizardProps.mode === "update"}
						info={`When enabled, will create event passes for any user or plate
							imported into the group. This group has no rates and can not be
							charged, the event pass is the user/plate’s right to access the
							event.`}
					/>
				)}

				{values.isEvent && (
					<>
						<Toggle
							label="Allow Whitelisted Plates"
							onChange={(value) =>
								setFieldValue("allowWhitelistedPlates", value)
							}
							checked={values.allowWhitelistedPlates}
						/>
						{/*
						This has been disabled for now, as it is not used in the rivercenter go live.
						Talk to Charlotte if you want to re-enable it.
						<Toggle
							label="Allow Multi Access"
							onChange={(value) => setFieldValue("allowMultiAccess", value)}
							checked={values.allowMultiAccess}
						/> */}
					</>
				)}

				<Label
					style={{
						color: values.isEvent ? "gray" : "",
					}}
				>
					Account
				</Label>
				<Dropdown
					isDisabled={values.isEvent}
					isClearable={!wizardProps.spaceAccountOwnerOnly}
					options={accountOptions}
					value={values.account}
					onChange={(value) => {
						setFieldValue("account", value);
					}}
				/>

				<SiteSpaces
					siteOptions={siteOptions}
					values={values}
					setFieldValue={setFieldValue}
					errors={errors}
					mode={wizardProps.mode}
				/>

				<Label
					style={{
						color: values.isEvent ? "gray" : "",
					}}
				>
					Booking Rates In Group
				</Label>
				<Dropdown
					disabled={values.isEvent}
					isMulti={true}
					options={rateOptions}
					value={values.leaseRates}
					onChange={(value, event) => {
						let model = "leaseRates";

						//If nulling out values completely, revert to 'no site'
						if (
							(value === null && event.action === "remove-value") ||
							event.action === "clear"
						) {
							setFieldValue(model, [{ label: "No rate", value: -1 }]);
						}
						//If removing a value, proceed as normal
						else if (event.action === "remove-value") {
							setFieldValue(model, value);
						}
						//If adding public group, remove all other groups
						else if (event.option.value === -1) {
							setFieldValue(model, [{ label: "No rate", value: -1 }]);
						}
						//Else, add the option and remove "No site" if applicable
						else {
							const newValues = value.reduce((arr, val) => {
								if (val.value !== -1) {
									arr.push(val);
								}

								return arr;
							}, []);

							setFieldValue(model, newValues);
						}
					}}
				/>

				<Label
					style={{
						color: values.isEvent ? "gray" : "",
					}}
				>
					Gates with Location Based Access
				</Label>
				<Dropdown
					isDisabled={values.isEvent}
					isMulti={true}
					options={locationBasedNodeOptions}
					value={values.locationBasedNodes}
					onChange={(value) => {
						setFieldValue("locationBasedNodes", value);
					}}
				/>

				<Label
					style={{
						color: values.isEvent ? "gray" : "",
					}}
				>
					Gates with Restricted Access
				</Label>
				<Dropdown
					isDisabled={values.isEvent}
					isMulti={true}
					options={restrictedAccessNodeOptions}
					value={values.restrictedAccessNodes}
					onChange={(value) => {
						setFieldValue("restrictedAccessNodes", value);
					}}
				/>

				<Toggle
					disabled={values.isEvent}
					label="Sessionless Access"
					onChange={(value) => setFieldValue("sessionlessAccess", value)}
					checked={values.sessionlessAccess && !values.isEvent}
				/>

				<Toggle
					label="Exclude From ANPR Openings"
					onChange={(value) => setFieldValue("hasANPRAccess", !value)}
					checked={!values.hasANPRAccess}
				/>

				<Toggle
					disabled={values.isEvent}
					label="Specific Site Access Hours"
					onChange={(value) => setFieldValue("overridesOpeningHours", value)}
					checked={values.overridesOpeningHours && !values.isEvent}
				/>

				<Toggle
					label="Exempt from Enforcement"
					onChange={(value) => setFieldValue("isEnforcementExempt", value)}
					checked={values.isEnforcementExempt}
				/>

				{wizardProps.user.IsAdmin && (
					<Toggle
						label="Waive Convenience Fees"
						onChange={(value) => setFieldValue("waiveConvenienceFee", value)}
						checked={values.waiveConvenienceFee}
					/>
				)}

				{values.overridesOpeningHours && (
					<div>
						{days.map((day) => {
							return (
								<Wrapper key={day}>
									<DayLabel>
										<div>{day}</div>
									</DayLabel>
									<TimeRangePicker
										startTime={
											values.group[`${day}OpeningTimestamp`] ||
											values.group[`${day}OpeningTimestamp`] === 0
												? moment(defaultStartTime)
														.add(
															values.group[`${day}OpeningTimestamp`],
															"seconds"
														)
														.toDate()
												: null
										}
										endTime={
											values.group[`${day}ClosingTimestamp`] ||
											values.group[`${day}ClosingTimestamp`] === 0
												? moment(defaultStartTime)
														.add(
															values.group[`${day}ClosingTimestamp`],
															"seconds"
														)
														.toDate()
												: null
										}
										disabled={!!(values.group && !values.group[`${day}Open`])}
										onChange={(value) =>
											handleTimeChange(values.group, day, value)
										}
										onOpen={{
											start: moment("08:00", "HH:mm").toDate(),
											end: moment("17:00", "HH:mm").toDate(),
										}}
									/>
									<div
										onClick={() =>
											values.group && values.group[`${day}Open`]
												? handleTimeChange(values.group, day, {
														from: null,
														to: null,
												  })
												: null
										}
									>
										<Tooltip
											tooltipContent={"Clear input"}
											render={
												<XCircle
													color={
														values.group && values.group[`${day}Open`]
															? colours.red
															: colours.midGrey
													}
												/>
											}
										/>
									</div>

									<Toggle
										onChange={(value) =>
											handleTimeChange(values.group, day, null, value)
										}
										checked={!!(values.group && values.group[`${day}Open`])}
									/>
								</Wrapper>
							);
						})}
					</div>
				)}
			</div>
		);
	},
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={() => {
						validateForm().then((err) => {
							if (keys(err).length === 0) {
								handleSubmit();
							} else {
								Alert.error("Please fill out all required fields");
							}
						});
					}}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.mode === "add" ? "Create" : "Update"}
				</Button>,
			]}
		/>
	),
});

export const removeStep = ({
	handleSubmit,
	isSubmitting,
	values,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "remove",
	label: "Remove",
	render: () => (
		<div>
			<StepText>
				Delete {values.name}? All Users in the group will have their site access
				removed.
			</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					Remove Group
				</Button>,
			]}
		/>
	),
});
