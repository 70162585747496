import { useQueryData } from "../../../hooks";
import DetailsPageWrapper from "../DetailsPageWrapper";
import gql from "graphql-tag";
import TableLayout from "../../layout/TableLayout";
import Card from "../../layout/Card";
import { format } from "../../../helpers";
import LoadingPlaceholder from "../../report/LoadingPlaceholder";
import moment from "moment";
import Accordion from "../../layout/Accordion";
import {
	OFFENCE_EVENTS,
	OFFENCE_EVENTS_TRANSLATIONS,
} from "../../../helpers/constants";
import _ from "lodash";

const eventDetailColumns = [
	{
		id: "ID",
		Header: "ID",
		accessor: "EnforcementViolationID",
		Cell: (cellProps) => cellProps.original.EnforcementViolationID,
	},
	{
		id: "Plate",
		Header: "Plate",
		accessor: (d) => d.Numberplate.Numberplate,
		Cell: (cellProps) => cellProps.original.Numberplate.Numberplate,
	},
	{
		id: "Site",
		Header: "Site",
		accessor: (d) => d.Site.Name,
		Cell: (cellProps) => cellProps.original.Site.Name,
	},
	{
		id: "RuleCode",
		Header: "Rule Code",
		accessor: (d) => d.EnforcementRule.OffenceCode,
		Cell: (cellProps) => cellProps.original.EnforcementRule.OffenceCode,
	},
	{
		id: "ParkingDuration",
		Header: "Parking Duration",
		accessor: "ParkingDuration",
		Cell: (cellProps) =>
			format.duration(cellProps.original.ParkingDuration, true, true),
	},
];

function ViolationEventInformation(props) {
	return (
		<Card>
			<h2>Violation Event Information</h2>
			<TableLayout
				data={[props.violation]}
				columns={eventDetailColumns}
				defaultPageSize={10}
			/>
		</Card>
	);
}

const plateReadColumns = [
	{
		id: "Plate",
		Header: "Plate",
		accessor: (d) => d.numberplate,
		Cell: (cellProps) => cellProps.original.numberplate,
	},
	{
		id: "Direction",
		Header: "Direction",
		accessor: (d) => d.direction,
		Cell: (cellProps) => cellProps.original.direction,
	},
	{
		id: "Site",
		Header: "Site",
		accessor: (d) => d.siteName,
		Cell: (cellProps) => cellProps.original.siteName,
	},
	{
		id: "Timestamp",
		Header: "Timestamp",
		accessor: (d) => d.timestamp,
		Cell: (cellProps) =>
			moment(cellProps.original.timestamp, "X").format(
				"HH:mm:ss on DD MMM YYYY"
			),
	},
	{
		id: "Camera",
		Header: "Camera",
		accessor: "laneAndGate",
		Cell: (cellProps) => cellProps.original.laneAndGate,
	},
	{
		id: "Snapshot",
		Header: "Snapshot",
		Cell: (cellProps) => (
			<img
				style={{ maxHeight: "36px", maxWidth: "84px" }}
				src={cellProps.original.image}
			/>
		),
	},
];
function PlateReads({ violation }) {
	return (
		<Card>
			<h2>Plate reads</h2>
			<TableLayout
				data={[
					{
						numberplate: violation.Numberplate.Numberplate,
						direction: violation.EntryCameraEvent.AccessType,
						siteName: violation.Site.Name,
						timestamp: violation.EntryCameraEvent.CreatedOn,
						laneAndGate: format.formatLane(
							violation.EntryCamera.Node.Lane,
							violation.EntryCamera.Node
						),
						image: violation.EntryCameraEvent.PatchImageUrl,
					},
					{
						numberplate: violation.Numberplate.Numberplate,
						direction: violation.ExitCameraEvent.AccessType,
						siteName: violation.Site.Name,
						timestamp: violation.ExitCameraEvent.CreatedOn,
						laneAndGate: format.formatLane(
							violation.ExitCamera.Node.Lane,
							violation.ExitCamera.Node
						),
						image: violation.ExitCameraEvent.PatchImageUrl,
					},
				]}
				columns={plateReadColumns}
				defaultPageSize={10}
			/>
		</Card>
	);
}

function ImageDetailPanel({ cameraEvent, camera }) {
	return (
		<div display={{ flex: 1 }}>
			<img
				src={cameraEvent.FullImageUrl}
				style={{ objectFit: "cover", maxWidth: "100%" }}
			/>

			<div
				style={{
					marginTop: "12px",
					display: "grid",
					gridTemplateColumns: "10rem auto",
					gridTemplateRows: "46px repeat(3, 24px)",
					padding: "10px",
				}}
			>
				<div style={{ alignSelf: "end" }}>Snapshot</div>
				<img
					style={{
						maxHeight: "36px",
						maxWidth: "84px",
					}}
					src={cameraEvent.PatchImageUrl}
				/>
				<div>Type</div>
				<div>{cameraEvent.AccessType}</div>
				<div>Camera</div>
				<div>{format.formatLane(camera.Node.Lane, camera.Node)}</div>
				<div>Timestamp</div>

				<div>
					{moment(cameraEvent.CreatedOn, "X").format("HH:mm:ss on DD MMM YYYY")}
				</div>
			</div>
		</div>
	);
}

function Images({ violation }) {
	return (
		<Card>
			<Accordion title={<h2>Images</h2>} expanded={true}>
				<div
					style={{
						padding: "36px",
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-around",
							alignItems: "center",
							gap: "128px",
						}}
					>
						<ImageDetailPanel
							camera={violation.EntryCamera}
							cameraEvent={violation.EntryCameraEvent}
						/>

						<ImageDetailPanel
							camera={violation.ExitCamera}
							cameraEvent={violation.ExitCameraEvent}
						/>
					</div>
				</div>
			</Accordion>
		</Card>
	);
}

const integrationColumns = [
	{
		id: "IntegrationID",
		Header: "Integration ID",
		accessor: (d) => d.integrationId,
		Cell: (cellProps) => cellProps.original.integrationId,
	},
	{
		id: "Request",
		Header: "Request",
		accessor: (d) => d.name,
		Cell: (cellProps) => cellProps.original.name,
	},
	{
		id: "Timestamp",
		Header: "Timestamp",
		accessor: (d) => d.timestamp,
		Cell: (cellProps) =>
			moment(cellProps.original.timestamp, "X").format(
				"HH:mm:ss on DD MMM YYYY"
			),
	},
	{
		id: "Response",
		Header: "Response",
		accessor: (d) => d.statusCode,
		Cell: ({ original }) =>
			`${original.statusCode} - ${
				original.statusCode >= 200 && original.statusCode < 300
					? "Succeeded"
					: "Failed"
			}`,
	},
];
function Integrations({ violation }) {
	const requests = [];
	for (const result of violation.IntegrationResults) {
		for (const request of result.Requests) {
			requests.push({
				requestId: request.RequestID,
				name: request.Name,
				statusCode: request.StatusCode,
				timestamp: request.Timestamp,
				integrationId: result.IntegrationID,
			});
		}
	}

	return (
		<Card>
			<h2>Integrations</h2>
			<TableLayout
				data={requests}
				columns={integrationColumns}
				defaultPageSize={10}
			/>
		</Card>
	);
}

const metadataColumns = [
	{
		id: "Type",
		Header: "Type",
		accessor: (d) => d.type,
		Cell: (cellProps) => cellProps.original.type,
	},
	{
		id: "Value",
		Header: "Value",
		accessor: (d) => d.value,
		Cell: (cellProps) => cellProps.original.value,
	},
];
function Metadata({ violation }) {
	const rule = violation.EnforcementRule;
	const allowedDurationWithGrace =
		rule.OffenceEvent === OFFENCE_EVENTS.PARKING_OVERSTAY
			? (rule.TimeRestriction ?? 0) +
			  (rule.EntryGracePeriod ?? 0) +
			  (rule.ExitGracePeriod ?? 0)
			: null;

	return (
		<Card style={{ paddingBottom: "32px" }}>
			<Accordion title={<h2>Metadata</h2>} expanded={true}>
				<TableLayout
					data={[
						{
							type: "Rule",
							value: `${rule.EnforcementRuleID} - ${
								OFFENCE_EVENTS_TRANSLATIONS[rule.OffenceEvent]
							}${
								rule.DeletedOn
									? ` (retired on ${moment(rule.DeletedOn, "X").format(
											"HH:mm:ss on DD MMM YYYY"
									  )})`
									: ""
							}`,
						},
						{
							type: "Allowed Parking Duration With Grace",
							value:
								rule.OffenceEvent === OFFENCE_EVENTS.PARKING_OVERSTAY
									? format.duration(allowedDurationWithGrace, true, true)
									: "N/A",
						},
						{
							type: "Allowed Parking Duration",
							value:
								rule.OffenceEvent === OFFENCE_EVENTS.PARKING_OVERSTAY
									? format.duration(rule.TimeRestriction ?? 0, true, true)
									: "N/A",
						},
						{
							type: "Entry OCR",
							value: violation.Numberplate.Numberplate,
						},
						{
							type: "Entry time",
							value: moment(violation.EntryCameraEvent.CreatedOn, "X").format(
								"HH:mm:ss on DD MMM YYYY"
							),
						},
						{
							type: "Exit OCR",
							value: violation.Numberplate.Numberplate,
						},
						{
							type: "Exit time",
							value: moment(violation.ExitCameraEvent.CreatedOn, "X").format(
								"HH:mm:ss on DD MMM YYYY"
							),
						},
						{
							type: "Grace Period Entry",
							value: [
								OFFENCE_EVENTS.PARKING_OVERSTAY,
								OFFENCE_EVENTS.PERMIT_OVERSTAY,
							].includes(rule.OffenceEvent)
								? format.duration(rule.EntryGracePeriod ?? 0, true, true)
								: "N/A",
						},
						{
							type: "Grace Period Exit",
							value: [
								OFFENCE_EVENTS.PARKING_OVERSTAY,
								OFFENCE_EVENTS.PERMIT_OVERSTAY,
							].includes(rule.OffenceEvent)
								? format.duration(rule.ExitGracePeriod ?? 0, true, true)
								: "N/A",
						},
						{
							type: "Overstay Period",
							value: _.isNumber(violation.OverstayedDuration)
								? format.duration(violation.OverstayedDuration, true, true)
								: "N/A",
						},
						{
							type: "Parking Period",
							value: format.duration(violation.ParkingDuration, true, true),
						},
						{
							type: "Alert Creation Timestamp",
							value: moment(violation.CreatedOn, "X").format(
								"HH:mm:ss on DD MMM YYYY"
							),
						},
					]}
					columns={metadataColumns}
				/>
			</Accordion>
		</Card>
	);
}

export default function EnforcementViolationDetails(props) {
	const {
		data: { getEnforcementViolationById: violation },
		isLoading,
	} = useQueryData(
		gql`
			query ($enforcementViolationId: Int!) {
				getEnforcementViolationById(
					enforcementViolationId: $enforcementViolationId
				) {
					EnforcementViolationID
					ParkingDuration
					CreatedOn
					OverstayedDuration
					EnforcementRule {
						EnforcementRuleID
						OffenceEvent
						OffenceCode
						TimeRestriction
						EntryGracePeriod
						ExitGracePeriod
						DeletedOn
					}
					Site {
						Name
					}
					Numberplate {
						NumberplateID
						Numberplate
					}
					EntryCamera {
						Node {
							Name
							AccessType
							Lane {
								Name
								GroupName
								GroupOrder
							}
						}
					}
					EntryCameraEvent {
						AccessType
						CreatedOn
						FullImageUrl
						PatchImageUrl
					}
					ExitCamera {
						Node {
							Name
							AccessType
							Lane {
								Name
								GroupName
								GroupOrder
							}
						}
					}
					ExitCameraEvent {
						AccessType
						CreatedOn
						FullImageUrl
						PatchImageUrl
					}
					IntegrationResults {
						IntegrationResultID
						IntegrationID
						Succeeded
						CreatedOn
						Requests {
							RequestID
							Name
							StatusCode
							Timestamp
						}
					}
				}
			}
		`,
		{
			enforcementViolationId: props.enforcementViolationId,
		},
		!props.enforcementViolationId
	);

	if (isLoading) {
		return <LoadingPlaceholder noCardWrapper={true} />;
	}

	if (!violation) {
		<DetailsPageWrapper close={props.close} title="Violation Event Details" />;
	}

	return (
		<DetailsPageWrapper close={props.close} title="Violation Event Details">
			<ViolationEventInformation violation={violation} />
			<PlateReads violation={violation} />
			<Images violation={violation} />
			<Integrations violation={violation} />
			<Metadata violation={violation} />
		</DetailsPageWrapper>
	);
}
