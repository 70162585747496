import { GroupStep } from "./GroupStep";
import { StepText, StepTitle } from "../WizardLayout";
import { find, isNil, map, sortBy, intersection } from "lodash";
import Button from "../../layout/Button";
import DatePicker from "../../layout/DatePicker";
import Dropdown from "../../layout/Dropdown";
import EditableInputField from "../../layout/EditableInputField";
import React from "react";
import SummaryTable from "../SummaryTable";
import Warning from "../../layout/Warning";
import WizardNavigation from "../WizardNavigation";
import { colours } from "../../../styles";
import moment from "moment";
import styled from "styled-components";
import NumberInput from "../../layout/NumberInput";
import InfoBox from "../../layout/InfoBox";

const EditBlock = styled.div`
	text-align: center;
	color: ${colours.white};
	background-color: ${colours.blue};
	display: block;
	padding: 10px;

	a {
		text-decoration: underline;
		cursor: pointer;
	}
`;

const reviewAction = { key: "review", text: "Review", next: "summary" };
const footer = (
	values,
	previous,
	goTo,
	next,
	isFirstStep,
	disableNext = () => {
		return false;
	},
	keyStrokeHandler
) => {
	const showNavigation = !values.editFromSummary && values.mode === "add";
	let right = showNavigation
		? { key: "next", text: "Next", next: next }
		: reviewAction;
	return (
		<WizardNavigation
			leftItems={[
				showNavigation && !isFirstStep && (
					<Button key="back" onClick={previous} color="blue">
						Back
					</Button>
				),
			]}
			rightItems={[
				<Button
					disabled={disableNext()}
					key={right.key}
					onClick={() => goTo(right.next)}
					color="blue"
					keyStrokeHandler={keyStrokeHandler}
				>
					{right.text}
				</Button>,
			]}
		/>
	);
};

export const nameStep = ({
	goTo,
	setFieldValue,
	values,
	errors,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "name",
	label: "Name",
	render: () => {
		return (
			<div>
				<StepTitle>
					What is the name for this block of {values.spaceType.toLowerCase()}{" "}
					spaces?
				</StepTitle>
				<EditableInputField
					type="text"
					maxLength="30"
					name="name"
					onChange={(event) => setFieldValue("name", event.target.value)}
					value={values.name}
					useLabel="Name"
					error={errors.name ? errors.name : ""}
				/>
			</div>
		);
	},
	footer: () => {
		const showNavigation = !values.editFromSummary && values.mode === "add";
		let right = showNavigation
			? { key: "next", text: "Next", next: "sites" }
			: reviewAction;

		let backOptions = [];

		if (values.mode === "add") {
			backOptions = [
				<Button
					key="back"
					onClick={() => wizardProps.clearSpaceType()}
					color="blue"
				>
					Back
				</Button>,
			];
		}

		return (
			<WizardNavigation
				leftItems={backOptions}
				rightItems={[
					<Button
						disabled={errors.name}
						key={right.key}
						onClick={() => goTo(right.next)}
						color="blue"
						keyStrokeHandler={keyStrokeHandler}
					>
						{right.text}
					</Button>,
				]}
			/>
		);
	},
});

export const sitesStep = ({
	errors,
	setFieldValue,
	values,
	wizardProps,
	previous,
	goTo,
	keyStrokeHandler,
}) => ({
	id: "sites",
	label: "Sites",
	render: () => {
		//Transform site data into data for the form
		let siteOptions = [];
		wizardProps.availableSites.forEach((site) => {
			siteOptions.push({ value: site.SiteID, label: site.Name });
		});

		return (
			<div>
				<StepTitle>Which sites are included in this block?</StepTitle>
				<Dropdown
					isMulti={true}
					options={siteOptions}
					value={values.sites}
					onChange={(value) => {
						setFieldValue("sites", value || []);
					}}
				/>
			</div>
		);
	},
	footer: () =>
		footer(
			values,
			previous,
			goTo,
			"spaces",
			false,
			() => {
				return !values.sites || values.sites.length < 1 || errors.sites;
			},
			keyStrokeHandler
		),
});

export const spacesStep = ({
	errors,
	setFieldValue,
	values,
	wizardProps,
	previous,
	goTo,
	keyStrokeHandler,
}) => ({
	id: "spaces",
	label: "Spaces",
	render: () => {
		//Get SiteIDs applicable; values array only contains limited data
		const usedSiteIds = values.sites.map((site) => {
			return site.value;
		});

		//Calculate the maximum capacities for all selected sites
		let smallestCapacity = 0;

		for (let site of wizardProps.availableSites) {
			if (usedSiteIds.includes(site.SiteID)) {
				smallestCapacity += site.MaximumCapacity;
			}
		}

		return (
			<div>
				<StepTitle>How many spaces for bookings are in this block?</StepTitle>
				<EditableInputField
					type="number"
					name="spaces"
					onChange={(event) => {
						let spaces = event.target.value
							.replace(/[^0-9.]/g, "")
							.replace(/(\..*)\./g, "$1");
						if (spaces > smallestCapacity) {
							setFieldValue(
								"spacesWarning",
								`Note that the number of booking spaces supplied exceeds the total of unassigned spaces across all selected sites (${smallestCapacity} total)`
							);
							setTimeout(() => {
								setFieldValue("spacesWarning", null);
							}, 3000);
						} else {
							setFieldValue("spaces", spaces);
							setFieldValue("spacesWarning", null);
						}
					}}
					value={values.spaces}
					useLabel="Spaces"
					error={errors.spaces ? errors.spaces : ""}
					max={smallestCapacity}
				/>
				<div>
					{values.spacesWarning && <Warning text={values.spacesWarning} />}
				</div>
			</div>
		);
	},
	footer: () => {
		return footer(
			values,
			previous,
			goTo,
			"occupancy-priority",
			false,
			() => {
				return !values.spaces || values.spaces < 0 || errors.spaces;
			},
			keyStrokeHandler
		);
	},
});

function occupancyPriorityAvailable(values, wizardProps) {
	const priority = values.occupancyPriority;
	if (isNil(priority)) {
		return true;
	}

	const selectedSiteIds = values.sites.map((s) => s.value);
	const selectedPark = wizardProps.leasePark;
	const leaseParks = wizardProps.leaseParks;

	return !leaseParks
		.filter((lp) => {
			const parkSiteIds = lp.sites.map((s) => s.SiteID);
			const shareSites = intersection(parkSiteIds, selectedSiteIds).length > 0;
			return lp.LeaseParkID !== selectedPark.LeaseParkID && shareSites;
		})
		.map((lp) => lp.OccupancyPriority)
		.includes(priority);
}

export const occupancyPriorityStep = ({
	setFieldValue,
	values,
	wizardProps,
	previous,
	goTo,
	keyStrokeHandler,
}) => {
	const priorityAvailable = occupancyPriorityAvailable(values, wizardProps);

	return {
		id: "occupancy-priority",
		label: "Occupancy Priority",
		render: () => {
			return (
				<div>
					<StepTitle>What is the occupancy priority of this space?</StepTitle>
					<InfoBox
						style={{ marginBottom: "12px" }}
						text="This value is specifically used for occupancy. A space with a lower priority value will take occupancy precedence."
					/>
					<NumberInput
						min={1}
						max={50}
						value={values.occupancyPriority}
						onChange={(num) => setFieldValue("occupancyPriority", num)}
					/>

					{!priorityAvailable && (
						<div style={{ marginTop: "8px", color: colours.red }}>
							This priority is already being used by another space, please
							choose another priority.
						</div>
					)}
				</div>
			);
		},
		footer: () => {
			return footer(
				values,
				previous,
				goTo,
				values.spaceType === "Private" ? "groups" : "availabilityDates",
				false,
				() => !priorityAvailable,
				keyStrokeHandler
			);
		},
	};
};

export const groups = ({
	goTo,
	previous,
	setFieldValue,
	values,
	errors,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "groups",
	label: "Groups",
	render: () => (
		<GroupStep
			organizationAccessGroups={wizardProps.organizationAccessGroups}
			leaseParkId={values.leaseParkId}
			selectGroups={values.organizationAccessGroups}
			selectedSites={values.sites}
			setFieldValue={setFieldValue}
			durationType={values.durationType}
			errors={errors.minimumDurationMonths}
			isPrivate={true}
		/>
	),
	footer: () =>
		footer(
			values,
			previous,
			goTo,
			"availabilityDates",
			false,
			() =>
				!values.organizationAccessGroups ||
				values.organizationAccessGroups.length === 0 ||
				errors.minimumDurationMonths,
			keyStrokeHandler
		),
});

export const availabilityStep = ({
	goTo,
	previous,
	setFieldValue,
	values,
	keyStrokeHandler,
}) => ({
	id: "availabilityDates",
	label: "Available Dates",
	render: () => {
		return (
			<div>
				<StepTitle>Please select the available dates for this space</StepTitle>
				<h3>Available from:</h3>
				<DatePicker
					options={{ pickerMode: "datePicker", showClearButton: true }}
					value={values.startDate}
					onChange={([date]) => {
						setFieldValue("startDate", date);

						if (
							date &&
							values.endDate &&
							moment(values.endDate).isBefore(moment(date))
						) {
							setFieldValue("endDate", null);
						}
					}}
					whenCleared={() => setFieldValue("startDate", null)}
				/>
				<h3>Available to:</h3>
				<DatePicker
					options={{ pickerMode: "datePicker", showClearButton: true }}
					value={values.endDate}
					onChange={([date]) => {
						setFieldValue("endDate", date);

						if (
							values.startDate &&
							date &&
							moment(date).isBefore(moment(values.startDate))
						) {
							setFieldValue("startDate", null);
						}
					}}
					whenCleared={() => setFieldValue("endDate", null)}
				/>

				{values.mode === "update" && (
					<Warning
						text={
							"Changing availability for these spaces will not affect active bookings"
						}
					/>
				)}
			</div>
		);
	},
	footer: () =>
		footer(
			values,
			previous,
			goTo,
			"billing",
			false,
			() => {
				return false;
			},
			keyStrokeHandler
		),
});

const getSites = (values) => {
	if (values.sites.length < 1) {
		return "None";
	}

	const siteNames = values.sites.map((e) => e.label);

	return siteNames.length ? sortBy(siteNames).join(", ") : "None";
};

const goToEdit = ({ operator, organization, history, leasePark }) => {
	history.push(
		`/operator/${operator.OperatorID}/organization/${organization.OrganizationID}/rates/monthly?lease-rate-id=${leasePark.pairedLeaseRate.LeaseRateID}`
	);
};

export const billingStep = ({
	goTo,
	previous,
	setFieldValue,
	values,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "billing",
	label: "Billing",
	render: () => {
		const options = map(wizardProps.billingDetails, (billing) => ({
			label: `${billing.CompanyName} - ${billing.TaxRegistration}`,
			value: billing.BillingDetailsID,
		}));

		return (
			<div>
				<StepTitle>
					Please choose the default billing details that apply to this space
				</StepTitle>
				<Dropdown
					isMulti={false}
					options={options}
					value={options.find((e) => e.value === values.billingDetailsId)}
					onChange={(value) => {
						setFieldValue("billingDetailsId", value.value);
					}}
				/>
			</div>
		);
	},
	footer: () =>
		footer(
			values,
			previous,
			goTo,
			"summary",
			false,
			() => !values.billingDetailsId,
			keyStrokeHandler
		),
});

export const summaryStep = ({
	handleSubmit,
	isSubmitting,
	setFieldValue,
	goTo,
	values,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "summary",
	label: "Summary",
	render: () => {
		//Additional values
		let additionalValues = [];

		if (
			(values.mode === "add" || values.mode === "update") &&
			values.spaceType === "Private"
		) {
			additionalValues = [
				{
					title: "Groups",
					value:
						values.organizationAccessGroups &&
						values.organizationAccessGroups.length > 0
							? values.organizationAccessGroups
									.map((item) => item.label)
									.join(", ")
							: "Public",
					key: "groups",
					edit: () => {
						setFieldValue("editFromSummary", true);
						goTo("groups");
					},
				},
			];
		}

		let availableDates = "";
		if (!values.startDate & !values.endDate) {
			availableDates = "Any time";
		} else {
			let startDate = values.startDate
				? `From ${moment(values.startDate).format("DD MMM YYYY")}`
				: "";
			let endDate = values.endDate
				? `To ${moment(values.endDate).format("DD MMM YYYY")}`
				: "";

			availableDates = `${startDate} ${endDate}`;
		}

		let billing = find(
			wizardProps.billingDetails,
			(_billing) => _billing.BillingDetailsID === values.billingDetailsId
		);

		return (
			<div>
				<StepTitle>Summary</StepTitle>

				{wizardProps.leasePark.pairedLeaseRate && (
					<EditBlock>
						<div>
							<a onClick={() => goToEdit(wizardProps)}>Click here</a> to go to
							rate page in order to edit minimum and maximum booking time.
						</div>
					</EditBlock>
				)}

				<SummaryTable
					items={[
						{
							title: "Type",
							value: values.spaceType,
							key: "type",
						},
						{
							title: "Default",
							value: wizardProps.leasePark.IsDefault ? "Yes" : "No",
							key: "default",
						},
						{
							title: "Name",
							value: values.name,
							key: "name",
							edit: () => {
								setFieldValue("editFromSummary", true);
								goTo("name");
							},
						},
						{
							title: "Sites",
							value: getSites(values),
							key: "sites",
							edit: wizardProps.leasePark.IsDefault
								? undefined
								: () => {
										setFieldValue("editFromSummary", true);
										goTo("sites");
								  },
						},
						{
							title: "Spaces",
							value: values.spaces,
							key: "spaces",
							edit: () => {
								setFieldValue("editFromSummary", true);
								goTo("spaces");
							},
						},
						{
							title: "Occupancy Priority",
							value: values.occupancyPriority ?? "None",
							key: "occupancy-priority",
							edit: () => {
								setFieldValue("editFromSummary", true);
								goTo("occupancy-priority");
							},
						},
						...additionalValues,
						{
							title: "Availability Range",
							value: availableDates,
							key: "availabilityDates",
							edit: () => {
								setFieldValue("editFromSummary", true);
								goTo("availabilityDates");
							},
						},
						{
							title: "Billing Details",
							value: billing
								? `${billing.CompanyName} - ${billing.TaxRegistration}`
								: "",
							key: "billing",
							edit: wizardProps.leasePark.IsDefault
								? undefined
								: () => {
										setFieldValue("editFromSummary", true);
										goTo("billing");
								  },
						},
					]}
				/>
			</div>
		);
	},
	footer: () => (
		<WizardNavigation
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={
						isSubmitting || !occupancyPriorityAvailable(values, wizardProps)
					}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.mode === "update" ? "Update" : "Create"} Space
				</Button>,
			]}
		/>
	),
});

export const removeStep = ({
	handleSubmit,
	isSubmitting,
	values,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "remove",
	label: "Remove",
	render: () => (
		<div>
			<StepText>
				Are you sure you want to remove {values.name}? This will also delete all
				associated bays. This action CANNOT be undone.
			</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					Remove
				</Button>,
			]}
		/>
	),
});
