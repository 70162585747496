import React, { useState } from "react";
import Autosuggest from "react-autosuggest";
import PropTypes from "prop-types";
import { debounce } from "lodash";
import { geocode } from "../../api/google";

AddressLookup.propTypes = {
	address: PropTypes.string,
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
};

AddressLookup.defaultProps = {
	address: "",
	placeholder: "Type in the address...",
	onChange: () => {},
};

function AddressLookup(props) {
	const [state, setState] = useState({
		address: props.address,
		suggestions: [],
	});

	function onChange(address = "") {
		setState((_state) => ({ ..._state, address }));
		props.onChange({ address });
	}

	function onSuggestionsFetchRequested({ value, reason }) {
		if (value && value.length >= 4 && reason === "input-changed") {
			debounce(
				() =>
					geocode(value)
						.then((suggestions) => {
							setState((_state) => ({ ..._state, suggestions }));
						})
						.catch((error) => {
							// eslint-disable-next-line no-console
							console.error(error);
							setState((_state) => ({ ..._state, suggestions: [] }));
						}),
				500
			)();
		}
	}

	function onSuggestionsClearRequested() {
		setState((_state) => ({ ..._state, suggestions: [] }));
	}

	return (
		<Autosuggest
			suggestions={state.suggestions}
			onSuggestionsFetchRequested={onSuggestionsFetchRequested}
			onSuggestionsClearRequested={onSuggestionsClearRequested}
			getSuggestionValue={(suggestion) => suggestion}
			renderSuggestion={(suggestion) => <div>{suggestion}</div>}
			inputProps={{
				placeholder: props.placeholder,
				value: state.address,
				onChange: (event, { newValue }) => onChange(newValue),
			}}
			theme={{
				container: { width: "100%" },
				input: { width: "100%" },
			}}
		/>
	);
}

export default AddressLookup;
