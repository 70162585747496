import { map } from "lodash";

/**
 * Locates an address on Google Maps.
 * @param {string} address
 * @returns {Promise<string[]>}
 */
export function geocode(address = "") {
	const autocomplete = new google.maps.places.AutocompleteService();

	return new Promise((resolve, reject) =>
		autocomplete.getPlacePredictions(
			{
				input: address,
				types: ["address"],
			},
			(suggestions, status) => {
				if (status !== "OK" && status !== "ZERO_RESULTS") {
					return reject(`Google Maps API autocomplete error: ${status}`);
				}

				if (status === "OK") {
					const descriptions = suggestions.map(
						(suggestion) => suggestion.description
					);

					return resolve(descriptions);
				}

				return resolve([]);
			}
		)
	);
}

/**
 * A service for converts between an address and a LatLng.
 * @param {string} address
 * @returns {Promise<string[]>}
 */
export function geocodeFinder(address) {
	const geoCoder = new google.maps.Geocoder();

	return new Promise((resolve) => {
		if (!address || address.length < 3) {
			return resolve([]);
		}

		geoCoder.geocode({ address }, (results) => {
			const _results = map(results, (result) => {
				const { address_components, formatted_address, geometry } = result;

				let state;
				let country;

				//Get country and state
				for (let component of address_components) {
					if (component.types[0] === "administrative_area_level_1") {
						state = component.short_name;
					}

					if (component.types[0] === "country") {
						country = component.short_name;
					}
				}

				return {
					Name: formatted_address,
					Latitude: geometry.location.lat(),
					Longitude: geometry.location.lng(),
					Country: country,
					State: state,
				};
			});

			return resolve(_results);
		});
	});
}
