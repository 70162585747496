import React from "react";
import { colours } from "../../styles";
import styled from "styled-components";

const Item = styled.div`
	background-color: ${colours.blue};
	border-radius: 4px;
	color: ${colours.white};
	display: inline-block;
	font-size: 12px;
	line-height: 12px;
	padding: 6px 8px;

	&.blue {
		background-color: ${colours.blue};
		color: ${colours.white};
	}

	&.green {
		background-color: ${colours.green};
		color: ${colours.white};
	}

	&.red {
		background-color: ${colours.red};
		color: ${colours.white};
	}

	&.yellow {
		background-color: ${colours.yellow};
		color: ${colours.white};
	}

	&.orange {
		background-color: ${colours.orange};
		color: ${colours.white};
	}
`;

export default function Label(props) {
	return (
		<Item {...props} className={`${props.className || ""} ${props.color}`} />
	);
}
