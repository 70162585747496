import React from "react";
import Tooltip from "rc-tooltip";
import styled from "styled-components";

const VariablesContainer = styled.div`
	background-color: white;
	padding: 10px;
	width: 270px;
	max-height: 40vh;
	overflow-y: auto;
`;

const Variable = styled.div`
	display: flex;
	:hover {
		cursor: pointer;
		opacity: 0.6;
	}
	background-color: #e6e6e6;
	margin: 10px 0 10px 0;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
	border-radius: 3px;
`;

const VariableType = styled.div`
	background-color: #b9b9b9;
	border-radius: 3px;
	width: 20px;
	height: 100%;
	margin-right: 10px;
	text-align: center;
`;

const VariableHeader = styled.div`
	font-size: 16px;
	font-weight: 400;
	line-height: 1.3em;
	color: grey;
`;

export const supportedColors = {
	default: { variable: "#e6e6e6", type: "#b9b9b9" },
	purple: { variable: "#E5CDEB", type: "#CA9FD1" },
	orange: { variable: "#FFE4CA", type: "#FFD2A7" },
	blue: { variable: "#D3EEEF", type: "#A3D4D6" },
};

export function Variables(props) {
	return (
		<VariablesContainer>
			{props.variableGroups?.map((group) => {
				const colors = supportedColors[group.color]
					? supportedColors[group.color]
					: supportedColors.default;
				return (
					<>
						{group.variables?.length ? (
							<VariableHeader>{group.name}</VariableHeader>
						) : null}
						{group.variables?.map((variable, vId) => (
							<Tooltip
								key={`variable${vId}`}
								overlay={
									variable.description || variable.label || variable.value
								}
								placement="right"
							>
								<Variable
									style={{
										backgroundColor: colors.variable,
									}}
									onClick={() => props.onVariableClicked(variable)}
								>
									<Tooltip overlay={variable.type} placement="top">
										<VariableType style={{ backgroundColor: colors.type }}>
											{variable.type?.substring(0, 1)?.toLowerCase()}
										</VariableType>
									</Tooltip>
									{variable.label || variable.value}
								</Variable>
							</Tooltip>
						))}
					</>
				);
			})}
		</VariablesContainer>
	);
}
