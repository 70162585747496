import WizardLayout, { StepText } from "../WizardLayout";
import Alert from "react-s-alert";
import Button from "../../layout/Button";
import React from "react";
import WizardNavigation from "../WizardNavigation";
import gql from "graphql-tag";
import { useMutateData } from "../../../hooks/index";

function _deleteStep({
	close,
	handleSubmit,
	isSubmitting,
	keyStrokeHandler,
	wizardProps,
}) {
	return {
		id: "delete",
		label: "Delete User",
		render: () => {
			return (
				<div>
					<StepText>
						Are you sure you want to DELETE the user{" "}
						<span style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>
							{wizardProps.user.Email}?
						</span>
						<br />
						This action CANNOT be undone.
					</StepText>
				</div>
			);
		},
		footer: () => {
			return (
				<WizardNavigation
					leftItems={[
						<Button key="previous" onClick={close} color="blue">
							Cancel
						</Button>,
					]}
					Cancel
					rightItems={[
						<Button
							key="submit"
							color="red"
							onClick={handleSubmit}
							disabled={isSubmitting}
							keyStrokeHandler={keyStrokeHandler}
						>
							Delete User
						</Button>,
					]}
				/>
			);
		},
	};
}

export default function DeleteUserWizard(props) {
	const deleteUser = useMutateData(gql`
		mutation ($userId: Int!) {
			deleteUser(userId: $userId)
		}
	`);

	return (
		<WizardLayout
			close={props.onClose}
			title={"Delete User"}
			values={[]}
			onSubmit={async (values, { setSubmitting }) => {
				setSubmitting(true);

				try {
					await deleteUser({ variables: { userId: props.user.UserID } });

					setSubmitting(false);
					Alert.success("Successfully deleted user");
					props.refetch();
					props.close();
				} catch (error) {
					setSubmitting(false);

					if (
						error.graphQLErrors &&
						error.graphQLErrors[0] &&
						error.graphQLErrors[0].extensions &&
						error.graphQLErrors[0].extensions.code
					) {
						const code = error.graphQLErrors[0].extensions.code;
						switch (code) {
							case "ACTIVE_PARKING_SESSIONS":
								Alert.error(
									"You cannot delete a user account while they have an active parking session"
								);
								break;

							case "ACTIVE_OR_UPCOMING_BOOKINGS":
								Alert.error(
									"You cannot delete a user account while they have an active or upcoming booking"
								);
								break;
							case "OUTSTANDING_PAYMENTS":
								Alert.error(
									"You cannot delete a user account while they have an outstanding payment"
								);
								break;
							default:
								Alert.error("Something went wrong");
								break;
						}
					} else {
						Alert.error("Something went wrong");
					}
				}
			}}
			steps={[_deleteStep]}
			initialStep={0}
			wizardProps={props}
		/>
	);
}
