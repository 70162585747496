// set environment specific variables
let config = {};

if (process.env.PACKAGE_ENV === "docker") {
	config = require("../config/docker");
} else if (process.env.PACKAGE_ENV === "staging") {
	config = require("../config/staging");
} else if (process.env.PACKAGE_ENV === "production") {
	config = require("../config/production");
} else {
	config = require("../config/local");
}

export default { config: config };
