import { MoreHorizontal, Plus } from "react-feather";
import React, { useMemo, useState } from "react";
import { usePermissions, useQueryData, useSearchFilter } from "../../../hooks";
import Button from "../../layout/Button";
import DropdownMenu from "../../layout/DropdownMenu";
import { HARDWARE_STATUSES } from "../../../helpers/constants";
import InstallationWizard from "../../wizards/installation-wizard";
import ReportWrapper from "../ReportWrapper";
import { booleanValueFormatter } from "../../../components/layout/TableLayout";
import { format } from "../../../helpers";
import gql from "graphql-tag";
import { map } from "lodash";

const defaultSorting = [
	{
		id: "LastHandshakeAt",
		desc: true,
	},
];

export default function GateControllers(props) {
	const [state, setState] = useState({
		initialLoad: true,
		search: "",
		node: "",
		wizardOpen: false,
		submitting: false,
	});

	let installations = [];
	let nodes = [];

	const isAdmin = usePermissions("IsAdmin");
	const isCSAdmin = usePermissions("IsCSAdmin");
	const isSuperAdmin = isAdmin && !isCSAdmin;

	async function updateOptions({ search, ...options }) {
		setState((_state) => ({ ..._state, initialLoad: false, search, options }));
	}

	const openWizard = (mode, node) => {
		setState((_state) => ({
			..._state,
			node,
			wizardOpen: true,
			wizardMode: mode,
		}));
	};

	const clientMap = isAdmin
		? props.clients.reduce((res, val) => {
				res[val.value] = val.label;
				return res;
		  }, {})
		: {};

	const { data, isLoading, refetch } = useQueryData(
		gql`
			query {
				getInstallations {
					InugoPublicECKeyID
					InstallationECKeyID
					Nodes {
						NodeID
					}
				}

				getAllNodes {
					NodeID
					HardwareID
					LastTriggeredTimestamp
					LastHandshakeAt
					LastOpeningDelayDuration
					BLEAddress
					IsOnline
					SoftwareVersion
					SystemFirmware
					ProductVersion
					ProductName
					Notes
					SerialNumber
					CommunicationMethod
					CachedDiagnostics
					Status
					ClientID
					CreatedOn
					UpdatedOn
					BeaconAttached
					BeaconHardwareID
					BeaconBLEAddress
					BeaconSoftwareVersion
					BeaconSystemFirmware
					BeaconProductVersion
					BeaconProductName
					PreAuthEnabled
					OfflineOnly
					GateTxPower
					TxPower
					Attenuation
					IsPedestal
					InugoPublicECKeyID
					InstallationECKeyID
					IsBoatRamp
					ShowAccessButton
					Sites {
						SiteID
						OrganizationID
						Name
						IsDeleted
					}
				}
			}
		`
	);

	if (data.getInstallations) {
		installations = data.getInstallations;
	}

	nodes = useMemo(() => {
		if (!data.getAllNodes) return [];

		return data.getAllNodes.map((node) => {
			const installation = find(installations, (i) =>
				find(i.Nodes, (n) => n.NodeID === node.NodeID)
			);

			return {
				...node,
				siteNames: map(node.Sites, (site) =>
					site.IsDeleted ? `${site.Name} (Deleted)` : site.Name
				).join(", "),
				latestInugoPublicECKeyID:
					installation && node.PreAuthEnabled
						? installation.InugoPublicECKeyID === node.InugoPublicECKeyID
						: null,
				latestInstallationECKeyID:
					installation && node.PreAuthEnabled
						? installation.InstallationECKeyID === node.InstallationECKeyID
						: null,
			};
		});
	}, [data.getAllNodes]);

	const columns = [
		{
			id: "SerialNumber",
			Header: "Serial Number",
			accessor: (d) => d.SerialNumber || "",
			highlightCell: (cellProps) => cellProps.original.SerialNumber || "",
			className: "bold",
		},
		{
			id: "HardwareID",
			Header: "Device ID",
			accessor: (d) => d.HardwareID,
			highlightCell: (cellProps) => (
				<>
					<div>{cellProps.original.HardwareID || "-"}</div>
					<div>{cellProps.original.BeaconHardwareID || ""}</div>
				</>
			),
		},
		{
			id: "BLEAddress",
			Header: "BLE Address",
			accessor: (d) => d.BLEAddress || "",
			highlightCell: (cellProps) => (
				<>
					<div>{cellProps.original.BLEAddress || "-"}</div>
					<div>{cellProps.original.BeaconBLEAddress || ""}</div>
				</>
			),
		},
		{
			id: "ProductName",
			Header: "Product",
			accessor: (d) => d.ProductName || "",
			highlightCell: (cellProps) => (
				<>
					<div>{cellProps.original.ProductName || "-"}</div>
					<div>{cellProps.original.BeaconProductName || ""}</div>
				</>
			),
		},
		{
			id: "SoftwareVersion",
			Header: "Software Version",
			accessor: (d) => d.SoftwareVersion || "",
			highlightCell: (cellProps) => (
				<>
					<div>{cellProps.original.SoftwareVersion || "-"}</div>
					<div>{cellProps.original.BeaconSoftwareVersion || ""}</div>
				</>
			),
		},
		isAdmin
			? {
					id: "SystemFirmware",
					Header: "System Firmware",
					accessor: (d) => d.SystemFirmware || "",
					highlightCell: (cellProps) => (
						<>
							<div>{cellProps.original.SystemFirmware || "-"}</div>
							<div>{cellProps.original.BeaconSystemFirmware || ""}</div>
						</>
					),
			  }
			: null,
		{
			id: "PreAuthEnabled",
			Header: "Pre-auth Enabled",
			accessor: (d) => d.PreAuthEnabled,
			Cell: booleanValueFormatter,
		},
		isAdmin
			? {
					id: "KeyVersions",
					Header: "Key Versions",
					accessor: (d) =>
						d.latestInugoPublicECKeyID || d.latestInstallationECKeyID || "",
					Cell: (cellProps) => (
						<>
							<div>
								{cellProps.original.latestInugoPublicECKeyID !== null ? (
									<div>
										{booleanValueFormatter({
											value: cellProps.original.latestInugoPublicECKeyID,
										})}
										(G)
									</div>
								) : null}
							</div>
							<div>
								{cellProps.original.latestInstallationECKeyID !== null ? (
									<div>
										{booleanValueFormatter({
											value: cellProps.original.latestInstallationECKeyID,
										})}
										(I)
									</div>
								) : null}
							</div>
						</>
					),
					sortable: false,
			  }
			: null,
		isAdmin
			? {
					id: "ClientID",
					Header: "Client",
					accessor: (d) => clientMap[d.ClientID] || "",
					highlightCell: (cellProps) =>
						clientMap[cellProps.original.ClientID] || "",
			  }
			: null,
		{
			id: "OfflineOnly",
			Header: "Offline Only",
			accessor: (d) => d.OfflineOnly,
			Cell: booleanValueFormatter,
		},
		{
			id: "Status",
			Header: "Status",
			accessor: (d) => HARDWARE_STATUSES[d.Status] || "",
			highlightCell: (cellProps) =>
				HARDWARE_STATUSES[cellProps.original.Status] || "",
			className: "bold",
		},
		{
			id: "IsOnline",
			Header: "Online",
			accessor: (d) => d.IsOnline,
			Cell: (cellProps) => (
				<div
					className={cellProps.original.IsOnline ? "led-cyan" : "led-white"}
				></div>
			),
			width: 80,
		},
		{
			id: "LastHandshakeAt",
			Header: "Last Successful Connection",
			accessor: (d) => d.LastHandshakeAt || "",
			Cell: (cellProps) =>
				cellProps.original.LastHandshakeAt
					? format.localDate(cellProps.original.LastHandshakeAt, null)
					: "",
		},
		{
			id: "Sites",
			Header: "Sites",
			accessor: (d) => d.siteNames || "",
			highlightCell: (cellProps) => cellProps.original.siteNames || "",
		},
		{
			id: "tasks",
			Header: "",
			accessor: null,
			Cell: (row) => {
				const items = [
					<div
						key="update"
						onClick={() => openWizard("admin-edit", row.original)}
					>
						Edit
					</div>,
					<div
						key="ping"
						onClick={() =>
							openWizard("admin-ping-gate", {
								...row.original,
								Name: `${row.original.HardwareID}${
									row.original.SerialNumber && ` (${row.original.SerialNumber})`
								}`,
							})
						}
					>
						Ping
					</div>,
				];
				if (isAdmin && row.original.IsPedestal) {
					items.push(
						<div
							key="refresh"
							onClick={() =>
								openWizard("admin-refresh-settings", {
									...row.original,
								})
							}
						>
							Refresh
						</div>
					);
				}
				return (
					<DropdownMenu
						triggerContent={<MoreHorizontal size={24} />}
						items={items}
					/>
				);
			},
			resizable: false,
			width: 50,
		},
	].filter((e) => e);

	const filteredData = useSearchFilter(nodes || [], state.search, [
		"HardwareID",
		"SerialNumber",
		"BLEAddress",
		"ProductVersion",
		"ProductName",
		"SoftwareVersion",
		"SystemFirmware",
		"BeaconHardwareID",
		"BeaconBLEAddress",
		"BeaconSoftwareVersion",
		"BeaconSystemFirmware",
		"BeaconProductName",
		"siteNames",
		{ key: "ClientID", get: (clientId) => clientMap[clientId] || "" },
	]);

	async function refetchData() {
		await refetch();
		setState((_state) => ({ ..._state, submitting: false }));
	}

	return (
		<div>
			{state.wizardOpen && (
				<InstallationWizard
					close={() => {
						setState((_state) => ({
							..._state,
							wizardOpen: false,
							submitting: true,
						}));

						refetchData();
					}}
					mode={state.wizardMode}
					node={state.node}
					clients={props.clients}
					hardwareStatuses={HARDWARE_STATUSES}
					currentUserIsAdmin={isAdmin}
				/>
			)}
			<ReportWrapper
				{...props}
				title="Gate Controllers"
				data={filteredData}
				columns={columns}
				defaultSorted={defaultSorting}
				updateOptions={updateOptions}
				loading={isLoading || state.submitting || props.isLoading}
				showDateRangePicker={false}
				showSitePicker={false}
				rightActions={
					isSuperAdmin ? (
						<Button
							color="blue"
							style={{ marginLeft: 8 }}
							onClick={() => openWizard("admin-add-gates")}
						>
							<Plus size={20} /> Add Gate Controllers
						</Button>
					) : null
				}
			/>
		</div>
	);
}
