function getRandomBytes(len) {
	if (!window.crypto) {
		return null;
	}

	const rb = new Uint8Array(len);
	window.crypto.getRandomValues(rb);
	return rb.reduce((o, v) => o + ("00" + v.toString(16)).slice(-2), "");
}

module.exports = {
	getRandomBytes,
};
