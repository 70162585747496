import { boxShadows, colours, mediaSizes } from "../../styles";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
	height: 56px;
`;

const Menu = styled.div`
	background-color: ${colours.highlightGrey};
	box-shadow: ${boxShadows.standard};
	left: ${(props) => (props.menuCollapsed ? 56 : 256)}px;
	min-width: 500px;
	padding: 0 16px;
	position: fixed;
	top: 0;
	transition: 0.2s ease;
	user-select: none;
	width: ${(props) =>
		props.menuCollapsed ? "calc(100% - 56px)" : "calc(100% - 256px)"};
	z-index: 2;

	@media (max-width: ${mediaSizes.medium}px) {
		left: 0;
		top: 64px;
		width: 100%;
	}

	.container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
`;

export default function ContextMenu(props) {
	return (
		<Wrapper>
			<Menu menuCollapsed={props.menuCollapsed}>
				<div className="container">{props.children}</div>
			</Menu>
		</Wrapper>
	);
}
