import React, { useContext, useEffect, useState } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { animated, config, useTransition } from "react-spring";
import ActivityContainer from "./organization/ActivityContainer";
import AdminHardwareContainer from "./admin/AdminHardwareContainer";
import AdminPermissionsContainer from "./admin/AdminPermissionsContainer";
import AdminUsersContainer from "./admin/AdminUsersContainer";
import { AppContext } from "../context/app-context";
import ContextMenu from "../components/menu/ContextMenu";
import ContextMenuLink from "../components/menu/ContextMenuLink";
import ErrorBoundary from "./ErrorBoundary";
import HistoryContainer from "./organization/HistoryContainer";
import Logo from "../components/layout/Logo";
import MenuContainer from "./MenuContainer";
import OrganizationContainer from "./organization/OrganizationContainer";
import { mediaSizes } from "../styles";
import { routing } from "../helpers";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import IntegrationContainer from "./organization/IntegrationContainer";
import OperatorContainer from "./OperatorContainer";
import OperatorSettingsContainer from "./OperatorSettingsContainer";
import Search from "./search";

const LoadingWrapper = styled.div`
	height: 400px;
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
`;

const ContentWrapper = styled.div`
	flex: 1;
	margin-left: ${(props) => (props.menuCollapsed ? 56 : 256)}px;
	transition: 0.2s ease margin;

	@media (max-width: ${mediaSizes.medium}px) {
		margin-left: 0;
		margin-top: 64px;
	}
`;

const Content = styled.div`
	padding: 32px 64px;
	margin: 0 auto;
	max-width: 2400px;

	@media (max-width: ${mediaSizes.medium}px) {
		padding: 32px;
	}

	@media (max-width: ${mediaSizes.small}px) {
		padding: 16px;
	}
`;

const LogoLoader = styled.div`
	animation: logo-loader 3s cubic-bezier(0.5, 0, 0.75, 1) infinite;

	@keyframes logo-loader {
		0% {
			opacity: 0.6;
		}
		50% {
			opacity: 1;
		}
		100% {
			opacity: 0.6;
		}
	}
`;

export default function RootContainer(props) {
	const { t: translate } = useTranslation();

	const [state, setState] = useState({
		showContent: false,
	});

	const transitions = useTransition(state.showContent, {
		config: config.slow,
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
	});

	const {
		state: {
			availableOperators,
			availableOrganizations,
			availableSites,
			contextLoading,
			selectedOperator,
			selectedOrganization,
			selectedSite,
			menuCollapsed,
		},
		dispatch: {
			getInitialContext,
			getAvailableOrganizations,
			setOperator,
			setOrganization,
			setSite,
		},
	} = useContext(AppContext);

	if (!state.showContent) {
		setTimeout(
			() => setState((_state) => ({ ..._state, showContent: true })),
			1000
		);
	}

	useEffect(() => {
		getInitialContext();
	}, []);

	const operatorPath = "/operator/:operatorId";
	const orgPath = "/organization/:organizationId";
	const operatorIntegrationsPath = `${operatorPath}/integrations`;
	const matchOperatorOnly = useRouteMatch(operatorPath);
	const matchOperatorWithOrg = useRouteMatch(`${operatorPath}${orgPath}`);
	const matchOperatorSettings = useRouteMatch(`${operatorPath}/settings`);
	const matchOperatorIntegrations = useRouteMatch(operatorIntegrationsPath);
	const matchOperatorIntegrationsRequests = useRouteMatch(
		`${operatorIntegrationsPath}/requests`
	);
	const matchOperatorIntegrationsVariables = useRouteMatch(
		`${operatorIntegrationsPath}/variables`
	);
	const matchOperatorSearch = useRouteMatch(`${operatorPath}/user-information`);

	const operatorId = matchOperatorOnly?.params?.operatorId;
	const organizationId = matchOperatorWithOrg?.params?.organizationId;

	const matchingOperator = operatorId
		? availableOperators?.find((o) => o.OperatorID === parseInt(operatorId))
		: null;

	const matchingOrganization = organizationId
		? availableOrganizations?.find(
				(o) => o.OrganizationID === parseInt(organizationId)
		  )
		: null;

	const firstOrganization = availableOrganizations?.filter(
		(o) => o.OperatorID === selectedOperator?.OperatorID
	)?.[0];

	const matchOperatorRoute =
		matchOperatorSettings ||
		matchOperatorIntegrationsRequests ||
		matchOperatorIntegrationsVariables ||
		matchOperatorIntegrations ||
		matchOperatorSearch;

	const isOperatorRoute = matchOperatorRoute?.isExact === true;

	const orgPathWithValue = orgPath.replace(":organizationId", organizationId);
	const page = isOperatorRoute
		? matchOperatorRoute.path.replace(`${operatorPath}/`, "")
		: props.history.location.pathname
				.replace(`${operatorPath.replace(":operatorId", operatorId)}`, "")
				.replace(`${orgPathWithValue}/`, "")
				.replace(`${orgPathWithValue}`, "")
				.replace(/\/$/, "");

	useEffect(() => {
		if (selectedOperator) {
			getAvailableOrganizations();
		}
	}, [selectedOperator]);

	useEffect(() => {
		routing.loadContext({
			history: props.history,
			operatorId,
			organizationId,
			firstOrganization,
			selectedOperator,
			selectedOrganization,
			matchingOperator,
			matchingOrganization,
			setOperator,
			setOrganization,
			page,
			shouldLoadFirstOrg: !isOperatorRoute,
		});
	}, [
		operatorId,
		organizationId,
		firstOrganization,
		selectedOperator,
		selectedOrganization,
		matchingOperator,
		matchingOrganization,
		page,
	]);

	const shareableProps = {
		getAvailableOrganizations,
		availableOperators,
		availableOrganizations,
		availableSites,
		contextLoading,
		setOperator,
		setOrganization,
		menuCollapsed,
		selectedOperator,
		selectedOrganization,
		selectedSite,
		setSite,
	};

	const defaultOperator = availableOperators?.[0];

	return transitions((transitionProps, item) =>
		!item ? (
			<animated.div style={transitionProps}>
				<LoadingWrapper>
					<LogoLoader style={{ marginTop: 140 }}>
						<Logo dark={true} height={"120px"} />
					</LogoLoader>
				</LoadingWrapper>
			</animated.div>
		) : (
			<animated.div style={{ ...transitionProps, flex: 1 }}>
				<MenuContainer {...props} {...shareableProps} />
				<ContentWrapper menuCollapsed={menuCollapsed}>
					<Content>
						<ErrorBoundary>
							<Switch>
								<Route
									exact={true}
									path="/operator/:operatorId"
									render={(routeProps) => (
										<OperatorContainer
											{...props}
											{...routeProps}
											{...shareableProps}
										/>
									)}
								/>
								<Route
									exact={true}
									path="/operator/:operatorId/settings"
									render={(routeProps) => (
										<OperatorSettingsContainer
											{...props}
											{...routeProps}
											{...shareableProps}
										/>
									)}
								/>
								<Route
									exact={false}
									path="/operator/:operatorId/integrations"
									render={(routeProps) => (
										<>
											<ContextMenu menuCollapsed={props.menuCollapsed}>
												<ContextMenuLink
													key="operator-integrations"
													path={routeProps.match.url}
													name={translate(
														"ContextMenu.Operator.Integration.Integrations"
													)}
													location={props.location}
												/>
												<ContextMenuLink
													key="operator-api-keys"
													path={`${routeProps.match.url}/api-keys`}
													name={translate(
														"ContextMenu.Operator.Integration.ApiKeys"
													)}
													location={props.location}
												/>
												<ContextMenuLink
													key="operator-requests"
													path={`${routeProps.match.url}/requests`}
													name={translate(
														"ContextMenu.Operator.Integration.Requests"
													)}
													location={props.location}
												/>
												<ContextMenuLink
													key="operator-variables"
													path={`${routeProps.match.url}/variables`}
													name={translate(
														"ContextMenu.Operator.Integration.Variables"
													)}
													location={props.location}
												/>
											</ContextMenu>
											<ErrorBoundary>
												<IntegrationContainer
													{...props}
													{...routeProps}
													{...shareableProps}
													context="Operator"
												/>
											</ErrorBoundary>
										</>
									)}
								/>
								<Route
									exact={true}
									path="/operator/:operatorId/user-information"
									render={(routeProps) => (
										<Search {...props} {...routeProps} {...shareableProps} />
									)}
								/>
								<Route
									exact={false}
									path="/operator/:operatorId/organization/:organizationId"
									render={(routeProps) => (
										<OrganizationContainer
											{...props}
											{...routeProps}
											{...shareableProps}
										/>
									)}
								/>
								<Route
									exact={false}
									path="/admin-hardware"
									render={(routeProps) => (
										<>
											<ContextMenu menuCollapsed={menuCollapsed}>
												<ContextMenuLink
													key="installations"
													path={`/admin-hardware/installations`}
													name={translate(
														"ContextMenu.AdminHardware.Installations"
													)}
													location={props.location}
												/>
												<ContextMenuLink
													key="gate-controllers"
													path={`/admin-hardware/gate-controllers`}
													name={translate(
														"ContextMenu.AdminHardware.GateControllers"
													)}
													location={props.location}
												/>
												<ContextMenuLink
													key="beacons"
													path={`/admin-hardware/beacons`}
													name={translate("ContextMenu.AdminHardware.Beacons")}
													location={props.location}
												/>
												<ContextMenuLink
													key="cameras"
													path={`/admin-hardware/cameras`}
													name={translate("ContextMenu.AdminHardware.Cameras")}
													location={props.location}
												/>
												<ContextMenuLink
													key="kiosks"
													path={`/admin-hardware/kiosks`}
													name={translate("ContextMenu.AdminHardware.Kiosks")}
													location={props.location}
												/>
												<ContextMenuLink
													key="controlBoxes"
													path={`/admin-hardware/control-boxes`}
													name={translate(
														"ContextMenu.AdminHardware.ControlBoxes"
													)}
													location={props.location}
												/>
											</ContextMenu>
											<AdminHardwareContainer
												admin={true}
												{...props}
												{...routeProps}
												{...shareableProps}
											/>
										</>
									)}
								/>
								<Route
									exact={false}
									path="/admin-integrations"
									render={(routeProps) => (
										<>
											<ContextMenu menuCollapsed={props.menuCollapsed}>
												<ContextMenuLink
													key="integrations"
													path="/admin-integrations"
													name={translate(
														"ContextMenu.AdminIntegrations.Integrations"
													)}
													location={props.location}
												/>
												<ContextMenuLink
													key="requests"
													path="/admin-integrations/requests"
													name={translate(
														"ContextMenu.AdminIntegrations.Requests"
													)}
													location={props.location}
												/>
												<ContextMenuLink
													key="variables"
													path="/admin-integrations/variables"
													name={translate(
														"ContextMenu.AdminIntegrations.Variables"
													)}
													location={props.location}
												/>
												<ContextMenuLink
													key="templates"
													path="/admin-integrations/templates"
													name={translate(
														"ContextMenu.AdminIntegrations.Templates"
													)}
													location={props.location}
												/>
											</ContextMenu>
											<ErrorBoundary>
												<IntegrationContainer
													{...props}
													{...routeProps}
													{...shareableProps}
													context="Account"
												/>
											</ErrorBoundary>
										</>
									)}
								/>
								<Route
									exact={false}
									path="/admin-permissions"
									render={(routeProps) => (
										<AdminPermissionsContainer
											{...props}
											{...routeProps}
											{...shareableProps}
										/>
									)}
								/>
								<Route
									exact={true}
									path="/admin-activity"
									render={(routeProps) => (
										<ActivityContainer
											admin={true}
											{...props}
											{...routeProps}
											{...shareableProps}
										/>
									)}
								/>
								<Route
									exact={true}
									path="/admin-audit"
									render={(routeProps) => (
										<HistoryContainer
											admin={true}
											{...props}
											{...routeProps}
											{...shareableProps}
										/>
									)}
								/>
								<Route
									exact={true}
									path="/admin-users"
									render={(routeProps) => (
										<AdminUsersContainer
											admin={true}
											{...props}
											{...routeProps}
											{...shareableProps}
										/>
									)}
								/>
								{defaultOperator && (
									<Redirect to={`/operator/${defaultOperator.OperatorID}`} />
								)}
							</Switch>
						</ErrorBoundary>
					</Content>
				</ContentWrapper>
			</animated.div>
		)
	);
}
