import { StepText, StepTitle } from "../WizardLayout";
import { format, user as userHelpers, validate } from "../../../helpers";
import Button from "../../layout/Button";
import DatePicker from "../../layout/DatePicker";
import Dropdown from "../../layout/Dropdown";
import EditableInputField from "../../layout/EditableInputField";
import EmailLookup from "../EmailLookup";
import MultiChoiceButton from "../MultiChoiceButton";
import React from "react";
import SummaryTable from "../SummaryTable";
import WizardNavigation from "../WizardNavigation";
import { colours } from "../../../styles";
import moment from "moment";
import styled from "styled-components";

const Wrapper = styled.div`
	display: flex;
`;

const HalfHalf = styled.div`
	flex: 1;
	align-self: flex-start;
`;

const Numberplate = styled.div`
	width: 240px;
	border: 2px solid ${colours.green};
	padding: 5px;
	margin-bottom: 15px;
	text-align: center;
	font-weight: bold;
	border-radius: 5px;
	font-size: 20px;
	cursor: pointer;
	background-color: ${(props) => props.selected && colours.green};
	color: ${(props) => props.selected && colours.white};
`;

export const chooseSpaceStep = ({
	goTo,
	values,
	setFieldValue,
	next,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "choose-space",
	label: "Space",
	render: () => {
		const spaceOption = wizardProps.spaces.map((space) => ({
			value: space.LeaseParkID,
			label: space.Name,
		}));

		return (
			<div>
				<StepTitle>Please choose a space</StepTitle>
				<Dropdown
					options={spaceOption}
					value={values.selectedSpace}
					onChange={(value) => {
						setFieldValue("selectedSpace", value);
					}}
				/>
			</div>
		);
	},
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="cancel" color="blue" onClick={wizardProps.close}>
						Cancel
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("numberplate");
						} else {
							next();
						}
					}}
					disabled={!values.selectedSpace}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Edit Plate" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const enterLeaseUserStep = ({
	goTo,
	values,
	setFieldValue,
	previous,
	keyStrokeHandler,
}) => ({
	id: "lease-user",
	label: "User",
	render: () => (
		<div>
			<StepTitle>What is the email address of the user?</StepTitle>
			<EmailLookup
				email={values.email}
				user={values.user}
				allowNew={true}
				onChange={(value) => {
					if (value.user && value.user.FirstName && value.user.LastName) {
						value.user.FirstName = value.user.FirstName.trim();
						value.user.LastName = value.user.LastName.trim();
					}

					setFieldValue("email", value.email);
					setFieldValue("user", value.user);
					setFieldValue("notifyUser", true);
					setFieldValue("dateRange", [
						moment().startOf("month").toDate(),
						moment().endOf("month").toDate(),
					]);

					if (value.user && value.user.UserID) {
						setFieldValue("userExists", true);
					} else {
						setFieldValue("userExists", false);
					}

					setFieldValue("plates", value.plates);
				}}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="previous" onClick={previous} color="blue">
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							goTo("numberplate");
						}
					}}
					disabled={
						!values.user ||
						(values.user && (!values.user.FirstName || !values.user.LastName))
					}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const selectNumberplateStep = ({
	goTo,
	values,
	setFieldValue,
	next,
	previous,
	keyStrokeHandler,
}) => ({
	id: "numberplate",
	label: "Plate",
	render: () => {
		return (
			<div>
				<StepTitle>What is the plate for this user?</StepTitle>
				<Wrapper>
					<HalfHalf>
						<h3>Existing plates</h3>
						<div>
							{values?.plates?.map((p) => (
								<Numberplate
									selected={values.numberplate === p.Numberplate}
									key={p.NumberplateID}
									onClick={() => {
										setFieldValue("numberplate", format.plate(p.Numberplate));
										setFieldValue("plateId", p.NumberplateID);
									}}
								>
									{p.Numberplate}
								</Numberplate>
							))}
							{!values?.plates?.length ? <p>No existing plate.</p> : null}
						</div>
					</HalfHalf>
					<HalfHalf>
						<h3>Or: Add new plate</h3>
						<EditableInputField
							style={{ width: "240px" }}
							type="text"
							name="newNumberplate"
							useLabel="New plate"
							maxLength="10"
							value={!values.numberplateId && values.numberplate}
							onChange={(event) => {
								setFieldValue("numberplate", format.plate(event.target.value));
								setFieldValue("plateId", null);
							}}
							error={
								values.numberplate && !validate.plate(values.numberplate)
									? "Please provide a valid plate"
									: null
							}
						/>
					</HalfHalf>
				</Wrapper>
			</div>
		);
	},
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="previous" onClick={previous} color="blue">
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					disabled={!validate.plate(values.numberplate)}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const setLeaseDateStep = ({
	values,
	setFieldValue,
	next,
	goTo,
	keyStrokeHandler,
}) => ({
	id: "lease-date",
	label: "Date",
	render: () => (
		<div>
			<StepTitle>What is the start and end date for this lease?</StepTitle>
			<DatePicker
				options={{ pickerMode: "dateRangePicker" }}
				value={values.dateRange}
				onChange={(dateRange) => {
					if (dateRange && dateRange.length > 1) {
						setFieldValue("dateRange", dateRange);
					}
				}}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button
						key="previous"
						color="blue"
						onClick={() => goTo("numberplate")}
					>
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					disabled={!values.dateRange}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const notifyStep = ({
	goTo,
	previous,
	setFieldValue,
	values,
	keyStrokeHandler,
}) => ({
	id: "notify",
	label: "Notify",
	render: () => (
		<div>
			<StepTitle>Would you like the user to be notified by email?</StepTitle>
			<MultiChoiceButton
				selected={!values.notifyUser}
				onClick={() => {
					setFieldValue("notifyUser", false);
				}}
			>
				No
			</MultiChoiceButton>
			<MultiChoiceButton
				selected={values.notifyUser}
				onClick={() => {
					setFieldValue("notifyUser", true);
				}}
			>
				Yes
			</MultiChoiceButton>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="back" onClick={previous} color="blue">
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => goTo("summary")}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const cancelLeaseStep = ({
	handleSubmit,
	wizardProps,
	isSubmitting,
	values,
	keyStrokeHandler,
}) => ({
	id: "cancel-lease",
	label: "Cancel Lease",
	render: () => (
		<div>
			{!values.lease.IsExternal && values.lease.DurationType === "Months" ? (
				<StepText>
					Cancel the lease for {values.lease.Email}? Please note that the user{" "}
					will not be charged a cancellation fee, and will be able to park until{" "}
					the end of their currently paid period.
				</StepText>
			) : null}
			{values.lease.IsExternal ? (
				<StepText>
					Cancel the external lease for user{" "}
					{values.lease.Email || values.lease.UnregisteredEmail}?
				</StepText>
			) : null}
			{!values.lease.IsExternal && values.lease.DurationType !== "Months" ? (
				<StepText>
					Cancel the lease for {values.lease.Email}? Please note this user will{" "}
					get a full refund of the booking fee.
				</StepText>
			) : null}
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Back
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					Cancel Lease
				</Button>,
			]}
		/>
	),
});

export const summaryStep = ({
	goTo,
	handleSubmit,
	isSubmitting,
	setFieldValue,
	values,
	keyStrokeHandler,
}) => ({
	id: "summary",
	label: "Summary",
	render: () => (
		<div>
			<StepTitle>Summary</StepTitle>
			<SummaryTable
				items={[
					{
						title: "Space",
						value: values.selectedSpace.label,
						key: "space",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("choose-space");
						},
					},
					{
						title: "Email",
						value: values.user.Email,
						key: "email",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("lease-user");
						},
					},
					{
						title: "Name",
						value: userHelpers.fullName(values.user),
						key: "name",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("lease-user");
						},
					},
					values.numberplate
						? {
								title: "Plate",
								value: values.numberplate,
								key: "numberplate",
								edit: () => {
									setFieldValue("editFromSummary", true);
									goTo("numberplate");
								},
						  }
						: {
								title: "Plate",
								value: "(Not Needed)",
								key: "numberplate",
						  },
					{
						title: "Date",
						value: `${moment(values.dateRange[0]).format(
							"DD MMM YYYY"
						)} to ${moment(values.dateRange[1]).format("DD MMM YYYY")}`,
						key: "date",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("lease-date");
						},
					},
					{
						title: "Notify",
						value: values.notifyUser ? "Yes" : "No",
						key: "notify",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("notify");
						},
					},
				]}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					Add Lease
				</Button>,
			]}
		/>
	),
});
