import { MoreHorizontal, Plus } from "react-feather";
import React, { useState } from "react";
import Button from "../../layout/Button";
import {
	useGetAllEventSeries,
	useSearchEventPasses,
} from "../../../containers/organization/queries/eventsApiHooks";
import { isEmpty, isNil } from "lodash";
import moment from "moment";
import ReportWrapperBeta from "../ReportWrapperBeta";
import { PAGINATION_EVENTS } from "../../../helpers/constants";
import ReportPicker from "../ReportPicker";
import DropdownMenu from "../../layout/DropdownMenuBeta";
import EventPassWizard from "../../wizards/event-pass-wizard/EventPassWizard";
import { booleanValueFormatter } from "../../../components/layout/TableLayoutBeta";

const defaultPageSize = 10;
const defaultDateRangeStart = moment().startOf("day").toDate();
const defaultDateRangeEnd = moment().add(12, "months").startOf("day").toDate();

const checkInMethods = {
	app: "App",
	webpay: "Web Pay",
	attendant: "Attendant",
	attendant_manual: "Attendant Manual",
};

export default function EventPasses(props) {
	const [state, setState] = useState({
		initialLoad: true,
		eventWizardOpen: false,
		eventWizardMode: "users",
		eventPassToRemove: null,
	});
	const [searchQuery, setSearchQuery] = useState("");
	const [selectedSiteIds, setSelectedSiteIds] = useState([]);
	const [selectedSeriesIds, setSelectedSeriesIds] = useState([]);
	const [startTimestamp, setStartTimestamp] = useState(defaultDateRangeStart);
	const [endTimestamp, setEndTimestamp] = useState(defaultDateRangeEnd);

	const [paginationData, setPaginationData] = useState({
		connection: null,
		event: PAGINATION_EVENTS.INIT,
		size: defaultPageSize,
		order: "asc",
		orderBy: "StartTimestamp",
		goto: null,
	});

	const { eventSeries } = useGetAllEventSeries();

	const seriesIds = eventSeries?.map((series) => ({
		value: series.EventSeriesID,
		label: series.Name,
	}));

	const {
		eventPassesData,
		connection,
		pageIndex,
		trueLength,
		isLoading,
		refetch,
	} = useSearchEventPasses(
		searchQuery,
		selectedSiteIds,
		selectedSeriesIds,
		startTimestamp,
		endTimestamp,
		paginationData
	);

	function handleSelectSeries(arrayOfSelectedSeriesIds) {
		setSelectedSeriesIds(arrayOfSelectedSeriesIds);
	}

	function handleChange(optionsData) {
		if (optionsData.sites && isEmpty(optionsData.sites) === false) {
			setSelectedSiteIds(optionsData.sites);
		} else {
			setSelectedSiteIds([]); //Set blank if no sites
		}

		if (optionsData["start-date-time"]) {
			const startDateTime = optionsData["start-date-time"];
			//convert to unix timestamp
			const convertedTimestamp = moment(startDateTime, "YYYYMMDD").unix();
			setStartTimestamp(convertedTimestamp);
		}

		if (optionsData["end-date-time"]) {
			const endDateTime = optionsData["end-date-time"];
			//convert to unix timestamp
			const convertedTimestamp = moment(endDateTime, "YYYYMMDD").unix();
			setEndTimestamp(convertedTimestamp);
		}
	}

	function openEventWizard(mode, eventPassToRemove) {
		setState((_state) => ({
			..._state,
			eventWizardOpen: true,
			eventWizardMode: mode,
			eventPassToRemove,
		}));
	}

	function refetchData() {
		setTimeout(() => {
			refetch();
		}, 300);
	}

	if (state.eventWizardOpen) {
		return (
			<EventPassWizard
				mode={state.eventWizardMode}
				eventPass={state.eventPassToRemove}
				close={() => {
					setState((_state) => ({ ..._state, eventWizardOpen: false }));
					refetchData();
				}}
			/>
		);
	}

	const columns = [
		{
			id: "Event",
			Header: "Event",
			accessor: "Event.Name",
		},
		{
			id: "Group",
			Header: "Group",
			accessor: "Group.Name",
		},
		{
			id: "StartTimestamp",
			Header: "Start Date",
			accessor: (row) =>
				moment
					.tz(row.StartTimestamp, "X", row.Sites?.[0]?.Timezone)
					.format("HH:mm on DD MMM YYYY"),
		},
		{
			id: "EndTimestamp",
			Header: "End Date",
			accessor: (row) =>
				moment
					.tz(row.EndTimestamp, "X", row.Sites?.[0]?.Timezone)
					.format("HH:mm on DD MMM YYYY"),
		},
		{
			id: "Email",
			Header: "Email",
			accessor: "Email",
		},
		{
			id: "Description",
			Header: "Description",
			accessor: "Description",
		},
		{
			id: "Registered",
			Header: "Registered",
			accessor: (d) => (d.UserID ? 1 : 0),
			Cell: (cellProps) => booleanValueFormatter(cellProps.value, true),
			fixedWidth: 100,
		},
		{
			id: "Site",
			Header: "Site",
			accessor: "Sites",
			Cell: (cellProps) => {
				const sites = cellProps.value;
				if (sites) {
					return sites.map((site) => site.Name).join(", ");
				}
				return "";
			},
		},
		{
			id: "Numberplate",
			Header: "Plate",
			accessor: ({ CheckInHistory, Numberplate }) => {
				if (!isNil(Numberplate)) {
					return Numberplate;
				}
				const uniqueNumberplates = new Set(
					CheckInHistory?.map(({ Numberplate: N }) => N?.Numberplate)
				);
				return Array.from(uniqueNumberplates).join(", ");
			},
		},
		{
			id: "CheckedInCreatedOn",
			Header: "Checked In On",
			accessor: (d) =>
				d.CheckInHistory?.map((c) =>
					c.CreatedOn
						? moment
								.tz(c.CreatedOn, "X", d.Sites?.[0]?.Timezone)
								.format("HH:mm on DD MMM YYYY")
						: ""
				).join(", "),
		},
		{
			id: "CheckedInMethod",
			Header: "Checked In Method",
			accessor: (d) =>
				d.CheckInHistory?.map((c) => checkInMethods[c.CheckInMethod]).join(
					", "
				),
		},
		{
			id: "tasks",
			Header: "",
			Cell: (cellProps) => (
				<DropdownMenu
					triggerContent={<MoreHorizontal size={24} />}
					items={[
						<div
							key="removePass"
							onClick={() => {
								openEventWizard("remove", cellProps.row.original);
							}}
						>
							Remove Event Pass
						</div>,
					]}
				/>
			),
			fixedWidth: 50,
		},
	];

	return (
		<ReportWrapperBeta
			{...props}
			title={"Event Passes"}
			defaultDateRange={[defaultDateRangeStart, defaultDateRangeEnd]}
			data={eventPassesData || []}
			columns={columns}
			defaultSortBy={[{ id: "StartTimestamp", desc: false }]}
			updateOptions={(optionsData) => {
				handleChange(optionsData);
			}}
			onChange={(event, data) => {
				setPaginationData((_state) => ({
					..._state,
					connection: connection ?? _state.connection,
					event: event,
					size: data.pageSize ?? _state.size,
					order: data.order ?? _state.order,
					orderBy: data.column ?? _state.orderBy,
					goto: data.goto ?? _state.goto,
				}));
				setSearchQuery(data.search);
			}}
			totalRecords={trueLength}
			pageSize={paginationData.pageSize || defaultPageSize}
			pageIndex={pageIndex ?? 0}
			loading={isLoading}
			showDateRangePicker={true}
			showSitePicker={true}
			paginateV2={true}
			leftActions={
				<ReportPicker
					style={{
						marginLeft: 16,
					}}
					name="Event Series"
					options={seriesIds ?? []}
					selected={selectedSeriesIds}
					onSelectedChanged={handleSelectSeries}
				/>
			}
			rightActions={
				<Button color="blue" onClick={() => openEventWizard("users")}>
					<Plus size={20} /> Import Passes
				</Button>
			}
		/>
	);
}
