import { StepTitle } from "../../../WizardLayout";
import WizardNavigation from "../../../WizardNavigation";
import Button from "../../../../layout/Button";
import EditableInputField from "../../../../layout/EditableInputField";
import { FieldContent } from "../../styles";
import { Branding } from "./Brandings";
import { SeriesGroupAccess } from "./SeriesGroupAccess";
import { keys } from "lodash";
import { SiteSpaceRates } from "./siteSpaceRates";

export const CREATE_SERIES_STEP = ({
	values,
	validateForm,
	setFieldValue,
	next,
	wizardProps,
	keyStrokeHandler,
	errors,
}) => {
	return {
		id: "create-series",
		label: "Series",
		render: () => {
			return (
				<div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
					<StepTitle>Series</StepTitle>
					<FieldContent>
						<EditableInputField
							type="text"
							name="seriesName"
							useLabel="Series Name"
							value={values.seriesName}
							onChange={(event) => {
								setFieldValue("seriesName", event.target.value);
							}}
							error={errors.seriesName}
						/>
					</FieldContent>
					<Branding
						orgId={wizardProps.selectedOrganization.OrganizationID}
						selectedBranding={values.selectedBranding}
						onChange={(value) => {
							setFieldValue("selectedBranding", value);
						}}
						error={errors.selectedBranding}
					/>
					<SiteSpaceRates
						availableSites={wizardProps.availableSites}
						selectedSites={values.selectedSites ?? []}
						selectedSpaces={values.selectedSpaces ?? []}
						selectedRates={values.selectedRates ?? []}
						setFieldValue={setFieldValue}
						values={values}
						errors={errors}
					/>
					<SeriesGroupAccess
						orgId={wizardProps.selectedOrganization.OrganizationID}
						selectedGroups={values.selectedGroups ?? []}
						onChange={(value) => {
							setFieldValue("selectedGroups", value);
						}}
					/>
					<FieldContent style={{ paddingTop: "8px" }}>
						<EditableInputField
							type="number"
							name="reset"
							useLabel="Reset time (in mins)"
							maxLength="3"
							value={values.reset}
							onChange={(event) => {
								setFieldValue("reset", event.target.value);
							}}
							error={errors.reset}
						/>
					</FieldContent>
				</div>
			);
		},
		footer: () => (
			<WizardNavigation
				leftItems={[
					<Button key="cancel" color="blue" onClick={wizardProps.close}>
						Cancel
					</Button>,
				]}
				rightItems={[
					<Button
						key="next"
						color="blue"
						onClick={() => {
							validateForm().then((e) => {
								if (keys(e).length === 0) {
									next();
								}
							});
						}}
						keyStrokeHandler={keyStrokeHandler}
					>
						Next
					</Button>,
				]}
			/>
		),
	};
};
