import React from "react";
import { FieldArray } from "formik";
import { StepTitle } from "../../../WizardLayout";
import WizardNavigation from "../../../WizardNavigation";
import Button from "../../../../layout/Button";
import { isNil, keys } from "lodash";
import { validateEvents } from "./SingleEvent.helpers";
import { SingleEvent } from "./SingleEvent";
import { useCheckForEventSeriesRateConflicts } from "../../../../../containers/organization/queries/eventsApiHooks";
import AlertMessage from "../../../../layout/AlertMessage";
import { PlusCircle, MinusCircle } from "react-feather";
import { colours } from "../../../../../styles";
import moment from "moment";

export const CREATE_EVENTS_STEP = ({
	values,
	setFieldValue,
	next,
	previous,
	keyStrokeHandler,
	errors,
	setErrors,
}) => {
	const { events, selectedGroups, siteSpaceRates, seriesId } = values;

	const formattedEvents = events.map(
		({ EventID, name, startTime, endTime }) => ({
			EventSeriesID: seriesId,
			EventID,
			Name: name,
			StartTime: moment(startTime).format("YYYYMMDDHHmm"),
			EndTime: moment(endTime).format("YYYYMMDDHHmm"),
		})
	);

	const groupIds = selectedGroups.map((group) => group.value);

	const formattedSiteSpaceRates = siteSpaceRates?.map((siteSpaceRate) => ({
		selectedSite: siteSpaceRate.selectedSite?.value,
		selectedSpaces: siteSpaceRate.selectedSpaces?.map((space) => space.value),
		selectedRates: siteSpaceRate.selectedRates?.map((rate) => rate.value),
	}));

	const checkForEventSeriesRateConflicts = useCheckForEventSeriesRateConflicts(
		formattedEvents,
		groupIds,
		formattedSiteSpaceRates
	);

	const handleAddEvent = (arrayHelpers) => {
		arrayHelpers.push({});
	};

	const handleRemoveEvent = (arrayHelpers, index) => {
		if (index === 0 && values.events.length === 1) {
			// If the first row is the only row, reset it instead of deleting
			setFieldValue("events", [
				{
					name: "",
					EventID: null,
					startTime: null,
					endTime: null,
				},
			]);
		} else {
			arrayHelpers.remove(index);
		}
	};

	return {
		id: "create-events",
		label: "Events",
		render: () => (
			<div>
				{values.conflictRateErrorMessage &&
					values.conflictRateErrorMessage.length > 0 && (
						<AlertMessage
							key={values.conflictRateErrorMessage[0]}
							text={`${values.conflictRateErrorMessage}`}
							borderColor="red"
							backgroundColor="red"
							textColor="white"
						/>
					)}
				<StepTitle>Events</StepTitle>
				<FieldArray name="events">
					{(arrayHelpers) => (
						<div style={{ width: "100%" }}>
							{values.events.map((event, index) => (
								<div
									key={index}
									style={{
										display: "flex",
										alignItems: "baseline",
										marginBottom: 16,
									}}
								>
									<SingleEvent
										event={event}
										index={index}
										values={values}
										setFieldValue={setFieldValue}
										errors={errors}
									/>
									<div
										style={{ display: "flex", gap: 8, alignItems: "center" }}
									>
										{values.events.length > 1 && (
											<MinusCircle
												onClick={() => handleRemoveEvent(arrayHelpers, index)}
												color={colours.red}
												style={{ cursor: "pointer" }}
											/>
										)}

										{index === values.events.length - 1 && (
											<PlusCircle
												onClick={() => handleAddEvent(arrayHelpers)}
												color={colours.blue}
												style={{ cursor: "pointer" }}
											/>
										)}
									</div>
								</div>
							))}
						</div>
					)}
				</FieldArray>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={[
					<Button key="previous" color="blue" onClick={previous}>
						Back
					</Button>,
				]}
				rightItems={[
					<Button
						key="next"
						color="blue"
						onClick={async () => {
							const validationErrors = await validateEvents(values.events);
							if (keys(validationErrors).length > 0) {
								setErrors(validationErrors);
								return; //Return early here so we dont hit the conflict manager with unvalidated data
							}
							const eventRateConflictsResponse =
								await checkForEventSeriesRateConflicts();
							const eventRateConflicts =
								eventRateConflictsResponse?.data
									?.checkForEventSeriesRateConflicts?.message || null;

							if (eventRateConflicts) {
								setFieldValue("conflictRateErrorMessage", eventRateConflicts);
							}

							if (
								keys(validationErrors).length === 0 &&
								isNil(eventRateConflicts)
							) {
								next();
								setFieldValue("conflictRateErrorMessage", null);
							}
						}}
						keyStrokeHandler={keyStrokeHandler}
					>
						Next
					</Button>,
				]}
			/>
		),
	};
};
