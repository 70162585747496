import { StepText, StepTitle } from "../WizardLayout";
import Button from "../../layout/Button";
import Dropdown from "../../layout/Dropdown";
import EditableInputField from "../../layout/EditableInputField";
import EmailLookup from "../EmailLookup";
import InputWithIcon from "../../layout/InputWithIcon";
import React from "react";
import Repeater from "../../layout/Repeater";
import SummaryTable from "../SummaryTable";
import WizardNavigation from "../WizardNavigation";
import { user } from "../../../helpers";

export const nameStep = ({
	goTo,
	next,
	setFieldValue,
	values,
	keyStrokeHandler,
}) => ({
	id: "name",
	label: "Name",
	render: () => (
		<div>
			<StepTitle>What is the name of the account?</StepTitle>
			<EditableInputField
				type="text"
				name={"name"}
				value={values.name || ""}
				useLabel={"Name"}
				onChange={(event) => {
					setFieldValue("name", event.target.value);
				}}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					disabled={!values.name}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const emailStep = ({
	goTo,
	next,
	previous,
	setFieldValue,
	values,
	keyStrokeHandler,
}) => ({
	id: "email",
	label: "Email",
	render: () => (
		<div>
			<StepTitle>What is the email address of the account owner?</StepTitle>
			<EmailLookup
				email={values.email}
				user={values.user}
				onChange={(value) => {
					if (value.user && value.user.FirstName && value.user.LastName) {
						value.user.FirstName = value.user.FirstName.trim();
						value.user.LastName = value.user.LastName.trim();
					}

					setFieldValue("email", value.email);
					setFieldValue("user", value.user);
				}}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="back" color="blue" onClick={previous}>
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					disabled={!values.user}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const spacesStep = ({
	goTo,
	handleChange,
	next,
	previous,
	values,
	keyStrokeHandler,
}) => ({
	id: "totalSpaces",
	label: "Parking Spaces",
	render: () => (
		<div>
			<StepTitle>
				What is the total number of spaces available for the account?
			</StepTitle>
			<EditableInputField
				type="number"
				min="1"
				name={"totalSpaces"}
				value={values.totalSpaces}
				useLabel={"Parking Spaces"}
				onChange={handleChange}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="back" color="blue" onClick={previous}>
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					disabled={values.totalSpaces < 1}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const spaceAllocationStep = ({
	goTo,
	next,
	previous,
	setFieldValue,
	values,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "spaceAllocations",
	label: "Space Allocation",
	render: () => {
		const leaseParkOptions = wizardProps.leaseParks.map((leasePark) => ({
			value: leasePark.LeaseParkID,
			label: leasePark.Name,
		}));

		return (
			<div>
				<StepTitle>
					How many parks should be available for each space?
				</StepTitle>
				<div style={{ width: "100%" }}>
					<Repeater
						items={values.spaceAllocations || [{}]}
						disableAdd={
							values.spaceAllocations.length >= leaseParkOptions.length
						}
						add={() => {
							let items = [...values.spaceAllocations];
							items.push({});
							setFieldValue("spaceAllocations", items);
						}}
						subtract={(index) => {
							let items = [...values.spaceAllocations];
							items.splice(index, index + 1);
							setFieldValue("spaceAllocations", items);
						}}
						template={(item, index) => (
							<React.Fragment>
								<div style={{ width: "45.5%", marginRight: "8px" }}>
									<Dropdown
										options={leaseParkOptions.filter(
											(leaseParkOption) =>
												!values.spaceAllocations.find(
													(spaceAllocation) =>
														spaceAllocation.LeaseParkID ===
														leaseParkOption.value
												)
										)}
										value={leaseParkOptions.find(
											(leaseParkOption) =>
												leaseParkOption.value === item.LeaseParkID
										)}
										onChange={(_item) => {
											let items = [...values.spaceAllocations];
											items[index].LeaseParkID = _item.value;
											items[index].Spaces = 0;
											setFieldValue("spaceAllocations", items);
										}}
									/>
								</div>

								<div style={{ marginRight: "8px" }}>
									<InputWithIcon
										type="number"
										value={item.Spaces || 0}
										onFormat={(value) => {
											let items = [...values.spaceAllocations];
											items[index].Spaces =
												parseInt(value) > 0 ? parseInt(value) : 0;
											setFieldValue("spaceAllocations", items);
											//TODO: fix the display value
										}}
										width="150px"
										icon="PieChart"
										align="left"
									/>
								</div>
							</React.Fragment>
						)}
					/>
				</div>
			</div>
		);
	},
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="back" color="blue" onClick={previous}>
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					disabled={values.totalSpaces < 1}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const summaryStep = ({
	goTo,
	handleSubmit,
	isSubmitting,
	setFieldValue,
	values,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "summary",
	label: "Summary",
	render: () => (
		<div>
			<StepTitle>Summary</StepTitle>
			<SummaryTable
				items={[
					{
						title: "Name",
						value: values.name,
						key: "name",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("name");
						},
					},
					{
						title: "Account Owner",
						value: user.fullNameWithEmail(values.user),
						key: "email",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("email");
						},
					},
					{
						title: "Parking Spaces",
						value: values.totalSpaces,
						key: "totalSpaces",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("totalSpaces");
						},
					},
					{
						title: "Space Allocations",
						value: values.spaceAllocations
							.map((e) => {
								const leasePark = wizardProps.leaseParks.find(
									(item) => item.LeaseParkID === e.LeaseParkID
								);

								return leasePark ? leasePark.Name : null;
							})
							.filter((e) => e)
							.join(", "),
						key: "spaceAllocations",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("spaceAllocations");
						},
					},
				]}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.mode === "add" ? "Create" : "Update"}
				</Button>,
			]}
		/>
	),
});

export const removeStep = ({
	handleSubmit,
	isSubmitting,
	values,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "remove",
	label: "Remove",
	render: () => (
		<div>
			<StepText>
				Are you sure you want to remove <b>{values.name}</b>?
			</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					Remove
				</Button>,
			]}
		/>
	),
});
