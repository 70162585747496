import React, { useEffect, useState } from "react";
import WizardLayout from "../WizardLayout"; // Assuming similar layout component exists
import LoadingPlaceholder from "../../report/LoadingPlaceholder";
import { useGetAllEventSeries } from "../../../containers/organization/queries/eventsApiHooks";
import { eventSeriesStep } from "./steps";

const constructMenuWizard = () => {
	return {
		initialStep: 0,
		steps: [eventSeriesStep],
	};
};

export default function EventCreateOrEditWizard(props) {
	const [eventSeries, setEventSeries] = useState([{}]); // Assuming some data needs to be fetched
	const [finishedLoadingData, setFinishedLoadingData] = useState(false);

	const {
		eventSeries: eventSeriesRaw,
		isLoading,
		isError,
	} = useGetAllEventSeries();

	useEffect(() => {
		if (!isLoading && eventSeriesRaw) {
			setEventSeries(
				eventSeriesRaw?.map((series) => ({
					value: series.EventSeriesID,
					label: series.Name,
				}))
			);
			setFinishedLoadingData(true);
		}
	}, [eventSeriesRaw, isLoading, isError]);

	const wizard = constructMenuWizard();

	if (isLoading || !finishedLoadingData) {
		return <LoadingPlaceholder />;
	}

	if (isError) {
		return <div>Error...</div>;
	}

	return (
		<WizardLayout
			close={props.close}
			title={props.title}
			steps={wizard.steps}
			initialStep={wizard.initialStep}
			onSubmit={props.onSubmit}
			values={[
				{
					name: "eventSeries",
					value: eventSeries,
				},
				{
					name: "selectedEventSeries",
					value: props.selectedEventSeries,
				},
				{
					name: "isEditingEventSeries",
					value: props.isEditingEventSeries || false,
				},
				{
					name: "buttonLabel",
					value: props.buttonLabel || "Create Event Series",
				},
				{
					name: "isButtonDisabled",
					value: props.isButtonDisabled || false,
				},
			]}
			wizardProps={props}
		/>
	);
}
