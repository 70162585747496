import { getURL } from "../index";
import { downloadFile } from "../util";
const webPayPath = "/web-pay/api";

export async function downloadZoneQrPdfUrl(data) {
	const {
		siteId,
		webPayType,
		codeOnly = false,
		format,
		security,
		uniqueCode,
		redownload = false,
	} = data;

	return downloadFile({
		endpoint: getURL(
			`${webPayPath}/v1/admin/site/${siteId}/zone-qr?codeOnly=${codeOnly}&webPayType=${webPayType}&format=${format}${
				security ? `&security=${JSON.stringify(security)}` : ""
			}${
				uniqueCode ? `&uniqueCode=${uniqueCode}` : ""
			}&redownload=${redownload}`
		),
		method: "GET",
	});
}

export async function getValidationQRCodeUrl(data) {
	const { format, security, qrCodeId } = data;

	return downloadFile({
		endpoint: getURL(
			`${webPayPath}/v1/admin/qr-code/${qrCodeId}/validation?format=${format}${
				security ? `&security=${JSON.stringify(security)}` : ""
			}`
		),
		method: "GET",
	});
}
