import { boxShadows, colours } from "../styles";
import styled, { createGlobalStyle } from "styled-components";
import AlertStack from "../components/layout/AlertStack";
import { AppContextProvider } from "../context/app-context";
import { MenuContextProvider } from "../context/menu-context";
import React from "react";

const GlobalStyle = createGlobalStyle`
	* {
		box-sizing: border-box;
	}

	body {
		background-color: ${colours.offWhite};
		color: ${colours.darkGrey};
		font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
		font-size: 16px;
		font-weight: 400;
		letter-spacing: 0.2px;
		line-height: 24px;

		&.modal-open {
			overflow: hidden;
		}
	}

	a {
		color: inherit;
		text-decoration: none;
	}

	.ModalOverlay {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.4);
		z-index: 3;
	}

	.recharts-wrapper {
		font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
		font-size: 14px;
	}

	.rbc-calendar {
		.rbc-btn-group {
			button {
				background-color: ${colours.blue};
				border: 0;
				border-radius: 4px !important;
				box-shadow: ${boxShadows.light} !important;
				color: ${colours.white};
				font-size: 18px;
				font-weight: 600;
				height: 40px;
				line-height: 24px;
				margin: 4px !important;
				padding: 8px 32px;
				transition: 0.2s ease;

				&:active,
				&:focus,
				&:hover {
					background-color: ${colours.blue} !important;
					color: ${colours.white} !important;
					cursor: pointer;
					opacity: 0.8;
				}
			}
		}

		.rbc-header {
			height: 24px;
		}

		.rbc-toolbar-label {
			font-size: 20px;
			line-height: 36px;
			font-weight: 600;
		}

		.rbc-current-time-indicator {
			z-index: 1;
		}

		.rbc-row-content {
			z-index: auto;
		}
	}

	.led-cyan {
		width: 24px;
		height: 24px;
		background-color: #00ffff;
		border-radius: 50%;
		box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #006 0 -1px 9px,
			#3f8cff 0 2px 14px;
		-webkit-animation: blinkCyan 3s infinite;
		-moz-animation: blinkCyan 3s infinite;
		-ms-animation: blinkCyan 3s infinite;
		-o-animation: blinkCyan 3s infinite;
		animation: blinkCyan 3s infinite;
	}

	@-webkit-keyframes blinkCyan {
		from {
			background-color: #00ffff;
		}
		50% {
			background-color: #00b6b6;
			box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #006 0 -1px 9px,
				#00ffff 0 2px 0;
		}
		to {
			background-color: #00ffff;
		}
	}
	@-moz-keyframes blinkCyan {
		from {
			background-color: #00ffff;
		}
		50% {
			background-color: #00b6b6;
			box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #006 0 -1px 9px,
				#00ffff 0 2px 0;
		}
		to {
			background-color: #00ffff;
		}
	}
	@-ms-keyframes blinkCyan {
		from {
			background-color: #00ffff;
		}
		50% {
			background-color: #00b6b6;
			box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #006 0 -1px 9px,
				#00ffff 0 2px 0;
		}
		to {
			background-color: #00ffff;
		}
	}
	@-o-keyframes blinkCyan {
		from {
			background-color: #00ffff;
		}
		50% {
			background-color: #00b6b6;
			box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #006 0 -1px 9px,
				#00ffff 0 2px 0;
		}
		to {
			background-color: #00ffff;
		}
	}
	@keyframes blinkCyan {
		from {
			background-color: #00ffff;
		}
		50% {
			background-color: #00b6b6;
			box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #006 0 -1px 9px,
				#00ffff 0 2px 0;
		}
		to {
			background-color: #00ffff;
		}
	}

	.led-white {
		width: 24px;
		height: 24px;
		background-color: white;
		border-radius: 50%;
		box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #006 0 -1px 9px,
			white 0 2px 14px;
	}
`;

const Wrapper = styled.div`
	display: flex;
	min-width: 320px;
	width: 100%;
`;

export default function AppWrapper(props) {
	return (
		<Wrapper>
			<AppContextProvider>
				<MenuContextProvider>
					<GlobalStyle />
					<AlertStack />
					{props.children}
				</MenuContextProvider>
			</AppContextProvider>
		</Wrapper>
	);
}
