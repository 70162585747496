import PhoneInput from "react-phone-input-2";
import React from "react";
import { colours } from "../../styles";
import styled from "styled-components";

const Wrapper = styled.div`
	.react-tel-input .form-control {
		width: 400px;
		padding-left: 60px;
		border-top-style: hidden;
		border-right-style: hidden;
		border-left-style: hidden;
		border-bottom: 2px solid ${colours.midGrey};
		border-radius: 0px;
	}
	.react-tel-input .form-control.invalid-number {
		border-style: hidden;
		border-bottom: 2px solid;
		border-radius: 0px;
	}
	.react-tel-input .flag-dropdown {
		border-radius: 0px;
		border-style: hidden;
		background: ${colours.white};
	}
	.react-tel-input .selected-flag {
		width: 50px;
		border-radius: 0px;
		padding-left: 12px;
		border-top-style: hidden;
		border-right-style: hidden;
		border-left-style: hidden;
		border-bottom: 2px solid ${colours.midGrey};
	}
	.react-tel-input .country-list {
		width: 400px;
	}
`;

export default function PhoneNumberInput(props) {
	return (
		<Wrapper>
			<PhoneInput {...props} />
		</Wrapper>
	);
}
