import { colours } from "../../styles";
import styled from "styled-components";

const CodeSnippet = styled.pre`
	background-color: ${colours.borderGrey};
	border-radius: 4px;
	padding: 16px;
`;

export default CodeSnippet;
