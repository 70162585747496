import EditableInput from "./EditableInput";
import { Field } from "formik";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
	margin: 16px 0;
	min-height: 64px;
	position: relative;
`;

export default function EditableInputField(props) {
	return (
		<Wrapper>
			<Field type="text" {...props}>
				{({ field }) => <EditableInput {...field} {...props} />}
			</Field>
		</Wrapper>
	);
}
