import React, { useState } from "react";
import TableLayoutBeta from "../../components/layout/TableLayoutBeta";
import Accordion from "../../components/layout/Accordion";
import Card from "../../components/layout/Card";
import { useQueryData, usePermissions, useHasClientAdmin } from "../../hooks";
import Button from "../../components/layout/Button";
import gql from "graphql-tag";
import {
	OFFENCE_EVENTS,
	OFFENCE_EVENTS_TRANSLATIONS,
} from "../../helpers/constants";
import LoadingPlaceholder from "../../components/report/LoadingPlaceholder";
import EnforcementRuleWizard from "../../components/wizards/enforcement-rules-wizard/EnforcementRuleWizard";
import DropdownMenuBeta from "../../components/layout/DropdownMenuBeta";
import { MoreHorizontal } from "react-feather";

const ruleColumns = (props) => [
	{
		id: "offenceCode",
		Header: "Offence Code",
		accessor: (d) => d.OffenceCode,
	},
	{
		id: "offenceEvent",
		Header: "Offence Event",
		accessor: (d) => OFFENCE_EVENTS_TRANSLATIONS[d.OffenceEvent] ?? "",
	},
	{
		id: "timeRestriction",
		Header: "Restriction",
		accessor: (d) => {
			if (d.OffenceEvent === OFFENCE_EVENTS.PARKING_OVERSTAY) {
				return `P${Math.ceil(d.TimeRestriction / 60)}`;
			}
			return "N/A";
		},
	},
	{
		id: "priority",
		Header: "Priority",
		accessor: (d) => d.Priority,
	},
	{
		id: "sites",
		Header: "Sites",
		accessor: (d) => d.Sites?.map((s) => s.Name).join(", ") ?? "",
	},
	{
		id: "tasks",
		Header: "",
		accessor: null,
		Cell: ({ row: { original } }) =>
			props.isAdmin || props.isClientAdmin ? (
				<DropdownMenuBeta
					triggerContent={<MoreHorizontal size={24} />}
					items={[
						<div key="edit" onClick={() => props.openWizard(original, "edit")}>
							Edit
						</div>,
						<div
							key="delete"
							onClick={() => props.openWizard(original, "delete")}
						>
							Delete
						</div>,
					]}
				/>
			) : null,
		fixedWidth: 50,
	},
];

const siteRuleColumns = [
	{
		id: "site",
		Header: "Site",
		accessor: (d) => d.SiteName,
	},
	{
		id: "code",
		Header: "Offence Code",
		accessor: (d) => d.Codes,
	},
];

function mapSiteRules(rules) {
	const format = (rule) =>
		`${rule.OffenceCode} - ${
			OFFENCE_EVENTS_TRANSLATIONS[rule.OffenceEvent] ?? ""
		}`;

	const result = {};
	for (const rule of rules) {
		for (const site of rule.Sites) {
			if (result[site.SiteID]) {
				result[site.SiteID].Codes.push(format(rule));
			} else {
				result[site.SiteID] = { Codes: [format(rule)], SiteName: site.Name };
			}
		}
	}

	return Object.keys(result).map((siteId) => ({
		SiteName: result[siteId].SiteName,
		Codes: result[siteId].Codes.join(", "),
	}));
}

export default function EnforcementRulesContainer(props) {
	const [state, setState] = useState({
		wizardOpen: false,
		enforcementRule: null,
		wizardMode: null,
	});
	const isAdmin = usePermissions("IsAdmin");
	const isClientAdmin = useHasClientAdmin(props.selectedOperator.ClientID);

	function openWizard(enforcementRule, mode) {
		setState((_state) => ({
			..._state,
			wizardOpen: true,
			enforcementRule,
			wizardMode: mode,
		}));
	}

	const organizationId = props.selectedOrganization
		? props.selectedOrganization.OrganizationID
		: null;

	const {
		data: { getEnforcementRulesForOrganization: allRules },
		isLoading: ruleIsLoading,
		refetch,
	} = useQueryData(
		gql`
			query ($organizationId: Int!) {
				getEnforcementRulesForOrganization(organizationId: $organizationId) {
					EnforcementRuleID
					OffenceEvent
					OffenceCode
					TimeRestriction
					EntryGracePeriod
					ExitGracePeriod
					Priority
					Sites {
						SiteID
						Name
					}
				}
			}
		`,
		{
			organizationId,
		},
		!organizationId
	);

	const siteRules = mapSiteRules(allRules ?? []);

	if (state.wizardOpen) {
		return (
			<EnforcementRuleWizard
				close={() => setState((_state) => ({ ..._state, wizardOpen: false }))}
				availableSites={props.availableSites}
				mode={state.wizardMode}
				enforcementRule={state.enforcementRule}
				refetchRules={refetch}
			/>
		);
	}

	return (
		<div>
			{(isAdmin || isClientAdmin) && (
				<div
					style={{
						display: "flex",
						justifyContent: "flex-end",
						alignItems: "center",
					}}
				>
					<Button color="blue" onClick={() => openWizard(null, "create")}>
						Add Rule
					</Button>
				</div>
			)}

			<Card>
				<Accordion title={<h2>Enforcement Rules</h2>} expanded={true}>
					{ruleIsLoading ? (
						<LoadingPlaceholder />
					) : (
						<TableLayoutBeta
							data={allRules ?? []}
							columns={ruleColumns({ isAdmin, isClientAdmin, openWizard })}
							defaultSortBy={[]}
						/>
					)}
				</Accordion>
			</Card>
			<Card>
				<Accordion title={<h2>Assigned Rules</h2>} expanded={true}>
					{ruleIsLoading ? (
						<LoadingPlaceholder />
					) : (
						<TableLayoutBeta
							data={siteRules}
							columns={siteRuleColumns}
							defaultSortBy={[]}
						/>
					)}
				</Accordion>
			</Card>
		</div>
	);
}
