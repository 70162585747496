/* eslint-disable react/jsx-key */
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { usePagination, useSortBy, useTable } from "react-table";
import { PaginationEventTypes as EventTypes } from "../../helpers/pagination";
import Highlighter from "react-highlight-words";
import LoadingPlaceholder from "../report/LoadingPlaceholder";
import { colours } from "../../styles";
import styled from "styled-components";

const Wrapper = styled.div`
	table {
		border: 0;
		border-spacing: 0;
		width: 100%;
		line-height: 1.5;

		th,
		td {
			border: 0;
			padding: 16px 8px;
			text-align: left;
			user-select: none;
			max-width: 300px;
		}

		th {
			padding-right: 16px;
			font-weight: 600;
		}

		td {
			overflow: hidden;
			text-overflow: ellipsis;
		}

		thead {
			font-size: 16px;

			th {
				border-bottom: 2px solid ${colours.borderGrey};
			}
		}

		tbody {
			tr:nth-child(odd) {
				background-color: ${colours.offWhite};
			}

			tr:hover {
				background: ${colours.lightGrey};
			}

			td {
				font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica,
					Arial, sans-serif;
				font-size: 14px;
			}
		}
	}

	.pagination-bottom {
		border: 0;
		box-shadow: none;
		margin-top: 32px;
		display: flex;
		padding: 4px;

		.-previous button {
			display: block;
			margin-left: auto;
		}

		.-previous,
		.-next {
			flex: 1;

			button[disabled],
			button {
				border: 0;
				background-color: ${colours.lightGrey};
				border-radius: 0;
				color: ${colours.darkGrey};
				font-size: 18px;
				font-weight: 600;
				height: 40px;
				line-height: 24px;
				width: 100%;
				max-width: 200px;
				padding: 8px 32px;
				position: relative;
				text-align: center;
				transition: 0.2s ease;
				outline: none;

				&::after {
					background-color: inherit;
					bottom: 0;
					content: "";
					filter: brightness(75%);
					height: 2px;
					left: 0;
					position: absolute;
					width: 100%;
				}
			}

			button[disabled] {
				opacity: 0.5;
				cursor: default;
			}

			button:hover:enabled {
				background: rgba(0, 0, 0, 0.3);
				color: #fff;
				cursor: pointer;
			}
		}

		.-center {
			display: flex;
			flex: 1.5;
			justify-content: space-around;

			select {
				border: 1px solid rgba(0, 0, 0, 0.1);
				background: #fff;
				padding: 4px 8px;
				font-size: inherit;
				border-radius: 4px;
				font-weight: normal;
				outline: 0;
			}
			span {
				padding-top: 5px;
				display: inline-block;
				margin: 3px 10px;
				white-space: nowrap;

				input {
					padding: 5px 7px;
					border: 1px solid rgba(0, 0, 0, 0.1);
					width: 70px;
					text-align: center;
				}
			}
		}
	}

	.boolean {
		position: relative;

		.cross {
			height: 12px;
			left: 8px;
			position: absolute;
			top: -6px;
			width: 12px;

			&::before,
			&::after {
				background-color: ${colours.darkGrey};
				content: "";
				height: 12px;
				left: 6px;
				position: absolute;
				width: 2px;
			}

			&::before {
				transform: rotate(45deg);
			}

			&::after {
				transform: rotate(-45deg);
			}

			&.colour {
				&::before,
				&::after {
					background-color: ${colours.red};
				}
			}
		}

		.tick {
			height: 16px;
			left: 8px;
			position: absolute;
			top: -6px;
			width: 16px;

			&::before {
				background-color: ${colours.darkGrey};
				content: "";
				height: 2px;
				position: absolute;
				width: 6px;
				top: 7px;
				transform: rotate(45deg);
			}

			&::after {
				background-color: ${colours.darkGrey};
				content: "";
				height: 12px;
				left: 7px;
				position: absolute;
				transform: rotate(45deg);
				width: 2px;
			}

			&.colour {
				&::before,
				&::after {
					background-color: ${colours.green};
				}
			}
		}
	}
`;

const Resizer = styled.div`
	position: absolute;
	right: -16px;
	top: -12px;

	&::before {
		border-bottom: ${(props) =>
			props.isSortedDesc ? 0 : `4px solid ${colours.green}`};
		border-top: ${(props) =>
			props.isSortedDesc ? `4px solid ${colours.green}` : 0};
		content: "";
		border-left: 4px solid transparent;
		border-right: 4px solid transparent;
		height: 0;
		position: absolute;
		right: 4px;
		top: 50%;
		transform: translateY(-50%);
		width: 0;
	}
`;

const Results = styled.div`
	font-size: 16px;
	font-weight: 600;
	padding: 16px 0;
	text-align: center;
`;

const NoData = styled.div`
	font-size: 20px;
	font-weight: 600;
	margin: 64px 0;
	text-align: center;
`;

const rankingCol = "RankingScore";

export function booleanValueFormatter(value, onlyShowTick = false) {
	return (
		<div className="boolean">
			<div className={value ? "tick" : onlyShowTick ? "" : "cross"} />
		</div>
	);
}

export function booleanValueFormatterColour(value) {
	return (
		<div className="boolean">
			<div className={value ? "tick colour" : "cross colour"} />
		</div>
	);
}

export function highlightedCell(value, search) {
	const tokens = search.split(" ");
	if (React.isValidElement(value) && value.props.children) {
		return React.Children.map(value.props.children, (child) =>
			React.cloneElement(child, {
				children: (
					<Highlighter
						searchWords={tokens}
						autoEscape={true}
						textToHighlight={String(child.props.children || "")}
					/>
				),
			})
		);
	}

	return (
		<Highlighter
			searchWords={tokens}
			autoEscape={true}
			textToHighlight={String(value) || ""}
		/>
	);
}

function TableWrapper({
	columns,
	data,
	tableProps: { defaultSortBy, sortable = true },
	fetchData,
	pageCount: controlledPageCount,
	isLoading,
	defaultPageSize,
	getTrProps = () => undefined,
	previousEvent,
}) {
	const [controlledPageIndex, setControlledPage] = useState(0);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		setPageSize,
		setSortBy,
		state: { pageSize, sortBy },
	} = useTable(
		{
			columns,
			data,
			initialState: {
				sortBy: defaultSortBy,
				pageSize: defaultPageSize,
			},
			useControlledState: (state) => {
				return useMemo(
					() => ({
						...state,
						pageIndex: controlledPageIndex,
					}),
					[state, controlledPageIndex]
				);
			},
			manualPagination: true,
			manualSortBy: true,
			disableSortBy: !sortable,
			disableMultiSort: true,
			disableSortRemove: true,
			autoResetPage: false,
			autoResetSortBy: false,
			pageCount: controlledPageCount,
		},
		useSortBy,
		usePagination
	);

	const [inputValue, setInputValue] = useState(0);
	const [prevCursor, setPrevCursor] = useState({
		index: undefined,
		pageSize: pageSize,
		sortBy: undefined,
		orderDesc: undefined,
	});
	const [activeSort, setActiveSort] = useState(false);
	const [ranking, setRanking] = useState(false);
	const [preventRefetch, setPreventRefetch] = useState(false);

	function _updatePrevCursor() {
		setPrevCursor({
			index: controlledPageIndex,
			pageSize,
			sortBy: ranking ? rankingCol : sortBy[0].id,
			orderDesc: ranking ? true : sortBy[0].desc,
		});
	}

	useEffect(() => {
		if (
			isSortEvent(prevCursor, sortBy) ||
			previousEvent === EventTypes.CLEAR_SEARCH
		) {
			setRanking(false);
			setActiveSort(!activeSort);
			if (previousEvent === EventTypes.CLEAR_SEARCH) {
				_updatePrevCursor();
			}
			setControlledPage(0);
		}
	}, [sortBy]);

	useEffect(() => {
		if (previousEvent === EventTypes.SEARCH) {
			setRanking(true);
			if (controlledPageIndex !== 0) {
				setPreventRefetch(true);
			}
			_updatePrevCursor();
			setControlledPage(0);
		} else if (previousEvent === EventTypes.CLEAR_SEARCH && ranking) {
			setPreventRefetch(true);
			setSortBy([{ id: defaultSortBy[0].id, desc: defaultSortBy[0].desc }]);
		}
	}, [previousEvent]);

	useEffect(() => {
		setInputValue(controlledPageIndex);
		if (!preventRefetch) {
			fetchData({
				pageIndex: controlledPageIndex,
				pageSize,
				sortBy: ranking ? [{ id: rankingCol, desc: true }] : sortBy,
				newData: data,
				prevCursor,
			});
		} else {
			setPreventRefetch(false);
		}
		_updatePrevCursor();
	}, [fetchData, controlledPageIndex, pageSize, activeSort]);
	return (
		<>
			{isLoading ? (
				<LoadingPlaceholder noCardWrapper={true} />
			) : (
				<Wrapper>
					<table {...getTableProps()}>
						<thead>
							{headerGroups.map((headerGroup) => (
								<tr {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map((column) => (
										<th
											{...column.getHeaderProps(column.getSortByToggleProps())}
											title=""
											style={{
												color:
													column.isSorted && !ranking
														? colours.green
														: colours.darkGrey,
												minWidth: column.fixedWidth || "auto",
												maxWidth: column.fixedWidth || "auto",
												cursor: column.canSort ? "pointer" : "default",
											}}
										>
											{column.render("Header")}
											{column.isSorted && !ranking ? (
												<div style={{ position: "relative" }}>
													<Resizer isSortedDesc={column.isSortedDesc}></Resizer>
												</div>
											) : null}
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody {...getTableBodyProps()}>
							{rows.map((row) => {
								prepareRow(row);
								return (
									<tr {...getTrProps(row)} {...row.getRowProps()}>
										{row.cells.map((cell) => {
											return (
												<td
													{...cell.getCellProps({
														className: cell.column.className,
													})}
												>
													{cell.render("Cell")}
												</td>
											);
										})}
									</tr>
								);
							})}
						</tbody>
					</table>
					<div className="pagination-bottom">
						<div className="-previous">
							<button
								onClick={() => setControlledPage(controlledPageIndex - 1)}
								disabled={controlledPageIndex <= 0}
							>
								{"Previous"}
							</button>
						</div>
						<div className="-center">
							<span>
								{"\t"} Page
								<input
									type="number"
									value={inputValue + 1}
									onChange={(e) => {
										const page = e.target.value
											? Number(e.target.value) - 1
											: 0;
										if (0 <= page && page < controlledPageCount) {
											setInputValue(page);
										}
									}}
									onKeyUp={(e) => {
										if (e.key === "Enter") {
											setControlledPage(inputValue);
										}
									}}
								/>{" "}
								of {controlledPageCount}
							</span>
							<select
								value={pageSize}
								onChange={(e) => {
									const newPageSize = Number(e.target.value);
									setControlledPage(0);
									setPageSize(newPageSize);
								}}
							>
								{[10, 25, 50, 100].map((_pageSize) => (
									<option key={_pageSize} value={_pageSize}>
										{_pageSize} rows
									</option>
								))}
							</select>
						</div>
						<div className="-next">
							<button
								onClick={() => setControlledPage(controlledPageIndex + 1)}
								disabled={controlledPageIndex === controlledPageCount - 1}
							>
								{"Next"}
							</button>
						</div>
					</div>
				</Wrapper>
			)}
		</>
	);
}

function isSortEvent(prevCursor, sortBy) {
	return (
		prevCursor.sortBy &&
		(sortBy[0].id !== prevCursor.sortBy ||
			sortBy[0].desc !== prevCursor.orderDesc)
	);
}

function getEventType(prevCursor, sortBy, pageSize, pageIndex) {
	if (isSortEvent(prevCursor, sortBy)) {
		return EventTypes.SORT;
	} else if (pageSize != prevCursor.pageSize) {
		return EventTypes.PAGE_SIZE;
	} else if (prevCursor.index > pageIndex) {
		return EventTypes.PREVIOUS;
	} else if (pageIndex > prevCursor.index) {
		return EventTypes.NEXT;
	}

	return null;
}

function getCursor(cursorCol, data, sortBy) {
	let sortedVal;
	if (typeof sortBy === "function") {
		sortedVal = JSON.stringify(sortBy(data));
	} else {
		sortedVal = JSON.stringify(data[sortBy]);
	}

	if (typeof cursorCol === "function") {
		return {
			cursor: cursorCol(data),
			sortedVal,
		};
	} else {
		return {
			cursor: data[cursorCol],
			sortedVal,
		};
	}
}

export default function TableLayoutPaginate(props) {
	const columns = useMemo(() => props.columns || []);
	const data = useMemo(() => props.data);
	const fetchIdRef = useRef(0);
	const [pageSize, setPageSize] = useState(0);

	const fetchData = useCallback(
		({
			pageSize: _pageSize,
			pageIndex,
			sortBy: _sortBy,
			newData,
			prevCursor,
		}) => {
			const fetchId = ++fetchIdRef.current;
			if (fetchIdRef.current === fetchId) {
				const eventType = getEventType(
					prevCursor,
					_sortBy,
					_pageSize,
					pageIndex
				);
				const isRanking = _sortBy[0].id === rankingCol;
				let cursor;
				let pageOffset;
				let sortByCol = isRanking
					? rankingCol
					: columns.filter((col) => col.id === _sortBy[0].id)[0].accessor;
				switch (eventType) {
					case EventTypes.SORT:
						pageOffset = 0;
						break;
					case EventTypes.PAGE_SIZE:
						pageOffset = 0;
						cursor = pageIndex
							? getCursor(props.cursorColumn, newData[0], sortByCol)
							: undefined;
						break;
					case EventTypes.PREVIOUS:
						pageOffset = prevCursor.index - pageIndex;
						cursor = getCursor(props.cursorColumn, newData[0], sortByCol);
						break;
					case EventTypes.NEXT:
						pageOffset = pageIndex - prevCursor.index;
						cursor = getCursor(
							props.cursorColumn,
							newData[_pageSize - 1],
							sortByCol
						);
						break;
					default:
						break;
				}
				setPageSize(_pageSize);
				props.fetchPaginatedData({
					cursor: cursor || {},
					pageOffset,
					eventType,
					pageSize: _pageSize,
					sortBy: _sortBy[0].id,
					sortOrder: _sortBy[0].desc ? "desc" : "asc",
				});
			}
		},
		[]
	);

	return (
		<div>
			{(data.length > 0 || props.paginationIsLoading) && (
				<TableWrapper
					columns={columns}
					data={data}
					tableProps={props}
					fetchData={fetchData}
					pageCount={pageSize ? Math.ceil(props.trueLength / pageSize) : 0}
					isLoading={props.paginationIsLoading}
					defaultPageSize={100}
					getTrProps={props.getTrProps}
					previousEvent={props.previousEvent}
				/>
			)}
			{props.showResultsLength &&
				data.length > 0 &&
				!props.paginationIsLoading && (
					<Results>
						{`Showing ${data.length} result${data.length === 1 ? "" : "s"}`}
					</Results>
				)}

			{data.length === 0 && !props.paginationIsLoading && (
				<NoData>No data found</NoData>
			)}
		</div>
	);
}
