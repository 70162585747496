import * as billingApi from "../../api/billing";
import * as installationApi from "../../api/installation";
import Alert from "react-s-alert";
import { cloneDeep } from "lodash";

export async function togglePaymentProvider(
	paymentProvider,
	values,
	handleChange,
	setFieldValue,
	isSuperAdmin
) {
	if (isSuperAdmin) {
		//set payment provider first
		handleChange({
			target: {
				name: "PaymentProvider",
				value: paymentProvider,
			},
		});

		//validate if site is operational
		if (
			paymentProvider === "PaymentExpress" &&
			values.PXPostUsername === undefined
		) {
			setFieldValue("IsOperational", false);
		} else if (
			paymentProvider === "Stripe" &&
			values.billingAccountDetails === undefined
		) {
			setFieldValue("IsOperational", false);
		} else {
			setFieldValue("IsOperational", true);
		}
	}
}

export async function testAccountAccess(
	siteId,
	billingAccountId,
	isSuperAdmin
) {
	if (isSuperAdmin) {
		try {
			await billingApi.testAccountAccess(siteId, billingAccountId);
			Alert.success("Account appears to be set up correctly.");
		} catch (error) {
			Alert.error("Something went wrong. Please try again.");
		}
	}
}

export async function testAccountAccessV2(
	organizationId,
	billingAccountId,
	isAdmin
) {
	if (isAdmin) {
		try {
			await billingApi.testAccountAccessV2(organizationId, billingAccountId);
			Alert.success("Account appears to be set up correctly.");
		} catch (error) {
			Alert.error("Something went wrong. Please try again.");
		}
	}
}

export async function removeBillingAccount(
	siteId,
	setFieldValue,
	isSuperAdmin
) {
	try {
		if (isSuperAdmin) {
			await billingApi.removeBillingAccountFromSite(siteId);

			setFieldValue("billingAccountId", null);
			setFieldValue("billingAccountDetails", null);
			setFieldValue("billingAccountEmail", null);
			setFieldValue("IsOperational", false);

			Alert.success("Account removed.");
		}
	} catch (error) {
		Alert.error("Something went wrong. Please try again.");
	}
}

export async function createNewBillingAccount(
	email,
	companyName,
	setFieldValue,
	isSuperAdmin
) {
	const data = {
		Email: email,
		CompanyName: companyName,
		Country: "NZ",
	};

	if (isSuperAdmin) {
		try {
			let response = await billingApi.createBillingAccount(data);

			setFieldValue("billingAccountId", response.BillingAccountID);
			setFieldValue("BillingAccountID", response.BillingAccountID);
			setFieldValue("billingAccountDetails", response);
			setFieldValue("newBillngEmail", "");

			Alert.success("Account created");
		} catch (error) {
			if (error.errors[0].code === "BillingAccountAlreadyExistsForEmail") {
				Alert.error("A Billing Account already exists for this Email address");
			} else {
				Alert.error(error.errors[0].code);
			}
		}
	}
}

export async function addExistingBillingEmail(
	email,
	setFieldValue,
	isSuperAdmin
) {
	if (isSuperAdmin) {
		try {
			let response = await billingApi.getBillingAccountByEmail(email);

			setFieldValue("billingAccountDetails", response);
			setFieldValue("BillingAccountID", response.BillingAccountID);
			setFieldValue("billingAccountEmail", response.Email);
			setFieldValue("ExistingBillingEmail", "");
			setFieldValue("IsOperational", true);

			Alert.success("Account added");
		} catch (error) {
			if (error.status === 404) {
				Alert.error("No Billing Account exists for that Email");
			} else {
				Alert.error(error.errors[0].code);
			}
		}
	}
}

export function handleFeeChange(value, key, feeAmount, setFieldValue) {
	let fee = cloneDeep(feeAmount);

	if (value.min !== undefined) {
		fee[key].min = value.min;
	} else if (value.max !== undefined) {
		fee[key].max = value.max;
	} else if (value.fee !== undefined) {
		fee[key].fee = value.fee;
	}

	setFieldValue("InternalFeeAmounts", fee);
	setFieldValue("ConvenienceFee", fee);
}

export function handleValueChange(
	settlement,
	provider,
	values,
	handleChange,
	setFieldValue
) {
	//handle drop down change first
	handleChange({
		target: {
			name: "MerchantAccountSettlement",
			value: settlement,
		},
	});

	handleChange({
		target: {
			name: "PaymentProvider",
			value: provider,
		},
	});

	const result = getProcessingFeeValue(
		provider,
		settlement,
		values.paymentProviderFeeAmounts
	);

	//set fixed and percentage fee value
	setFieldValue("FixedFee", result.fixedFee);
	setFieldValue("PercentageFee", result.percentageFee);
}

export function getProcessingFeeValue(provider, settlement, feeAmount) {
	let fixedFee;
	let percentageFee;

	if (provider === "Stripe") {
		fixedFee = feeAmount.stripe.external.flat;
		percentageFee = feeAmount.stripe.external.percentage;
	} else if (provider === "PaymentExpress") {
		if (settlement === "US") {
			fixedFee = feeAmount.paymentExpress.us.flat;
			percentageFee = feeAmount.paymentExpress.us.percentage;
		} else if (settlement === "NZ") {
			fixedFee = feeAmount.paymentExpress.nz.flat;
			percentageFee = feeAmount.paymentExpress.nz.percentage;
		} else {
			fixedFee = 0;
			percentageFee = 0;
		}
	}

	return { fixedFee: fixedFee, percentageFee: percentageFee };
}

export function setProcessingFeeValue(
	provider,
	settlement,
	feeAmount,
	fixedFee,
	percentageFee,
	setFieldValue
) {
	let _fixedFee = fixedFee;
	let _percentageFee = percentageFee;

	//set fee amount value
	if (provider === "Stripe") {
		feeAmount.stripe.external.flat = _fixedFee;
		feeAmount.stripe.external.percentage = _percentageFee;
	} else if (provider === "PaymentExpress") {
		if (settlement === "US") {
			feeAmount.paymentExpress.us.flat = _fixedFee;
			feeAmount.paymentExpress.us.percentage = _percentageFee;
		} else if (settlement === "NZ") {
			feeAmount.paymentExpress.us.flat = _fixedFee;
			feeAmount.paymentExpress.nz.percentage = _percentageFee;
		}
	}

	setFieldValue("paymentProviderFeeAmounts", feeAmount);
	setFieldValue("FixedFee", _fixedFee);
	setFieldValue("PercentageFee", _percentageFee);
}

export function getInitialFeeValue(
	sensitiveSiteData,
	paymentProvider,
	paymentProviderFeeAmounts
) {
	if (sensitiveSiteData.pxPostAccount) {
		return getProcessingFeeValue(
			paymentProvider,
			sensitiveSiteData.pxPostAccount.MerchantAccountSettlement,
			paymentProviderFeeAmounts
		);
	} else {
		return getProcessingFeeValue(
			paymentProvider,
			null,
			paymentProviderFeeAmounts
		);
	}
}

export async function submit(
	siteId,
	values,
	setValues,
	isSuperAdmin,
	canEditBilling,
	selectedSite
) {
	try {
		const invoiceData = {
			CompanyName: values.CompanyName,
			TaxRegistration: values.TaxRegistration,
			BillingAddress: values.BillingAddress,
		};

		let accountUpdateRequest = null;

		//update invoice details
		if (canEditBilling) {
			await billingApi.setInvoicingDetailsOnSite(siteId, invoiceData);
		}

		//update payment provider
		if (isSuperAdmin) {
			const response = await installationApi.updateSite(siteId, {
				PaymentProvider: values.PaymentProvider,
			});

			if (response.siteCanBeOperational !== undefined) {
				setValues({
					...values,
					IsOperational: response.siteCanBeOperational,
				});
			}

			if (values.PaymentProvider === "PaymentExpress") {
				if (!values.PXPostSecretKey && !values.PXPostUsername) {
					accountUpdateRequest = billingApi.clearPXPostAccountDetails(siteId);
				} else {
					accountUpdateRequest = billingApi.setPXPostAccountDetails(
						siteId,
						values.PXPostUsername,
						values.PXPostSecretKey,
						values.MerchantAccountSettlement
					);
				}
			} else if (values.PaymentProvider === "Stripe") {
				if (values.billingAccountEmail) {
					accountUpdateRequest = billingApi.setSiteBillingAccount(
						siteId,
						values.billingAccountDetails.BillingAccountID
					);
				}
			}

			if (values.PaymentProvider !== null) {
				//update billing account details and handle response
				if (accountUpdateRequest !== null) {
					await accountUpdateRequest;

					let _response = await installationApi.getSiteOperationalStatus(
						siteId
					);
					if (_response.siteCanBeOperational !== undefined) {
						setValues({
							...values,
							IsOperational: _response.siteCanBeOperational,
						});
					}
				}

				let internalFeeAmounts = values.InternalFeeAmounts;
				let operatorFeeAmount = values.OperatorFeeAmount;

				//make sure the service fee has value on submitting
				if (!values.InternalFeeAmounts) {
					const serviceFees = JSON.parse(
						selectedSite[`${values.PaymentProvider}ServiceFeesJSON`]
					);

					internalFeeAmounts = serviceFees.internalFeeAmounts;
					operatorFeeAmount = serviceFees.operatorFeeAmount;
				}

				//update fee structure
				const feeData = {
					internalFeeAmounts: internalFeeAmounts,
					operatorFeeAmount: operatorFeeAmount,
					paymentProviderFeeAmounts: values.paymentProviderFeeAmounts,
				};

				await billingApi.setFeeStructureOnSite(siteId, feeData);
			}
		}

		Alert.success("Billing details updated");
	} catch (error) {
		Alert.error("Something went wrong. Please try again.");
	}
}
