import * as installationApi from "../../api/installation";
import { MoreHorizontal, Plus } from "react-feather";
import React, { useState } from "react";
import {
	useFetchData,
	useHasPermissionsOnEverySite,
	usePermissions,
} from "../../hooks";
import BookingRateWizard from "../../components/wizards/booking-rate-wizard/BookingRateWizard";
import Button from "../../components/layout/Button";
import { DURATION_TYPES } from "../../helpers/constants";
import DropdownMenu from "../../components/layout/DropdownMenu";
import ReportPicker from "../../components/report/ReportPicker";
import ReportWrapper from "../../components/report/ReportWrapper";
import { format } from "../../helpers";
import { getUnitLabel } from "../../components/wizards/booking-rate-wizard/helper";
import { useGetAllEventSeries } from "../../containers/organization/queries/eventsApiHooks";

/**
 * Checks if a user can edit a parking block, either due to being an admin or due to having ManageRates permissions.
 *
 * @param {object} fixedTermRate
 * @returns {Boolean}
 * @private
 */
function _canEditFixedTermRate(props, fixedTermRate) {
	//Permission checks - must be "Site Administration, CS admin, Super admin" to make changes to content
	return useHasPermissionsOnEverySite("ManageRates", fixedTermRate.sites);
}

function _getHalfHourlyDuration(duration) {
	return duration > 1
		? duration > 2
			? `${duration / 2} Hours`
			: "1 Hour"
		: "30 Minutes";
}

const halfHourDuration = 30;

function _getDurationString(duration, unit, hourlyBillableDuration) {
	if (hourlyBillableDuration === halfHourDuration) {
		return _getHalfHourlyDuration(duration, unit);
	}
	const unitToLabel = {
		Months: "Month",
		Weeks: "Week",
		Days: "Day",
		Hours: "Hour",
		Minutes: "Minute",
		Event: "Event",
	};

	if (!duration) {
		return `No limit`;
	} else if (duration > 1) {
		return `${duration} ${unitToLabel[unit]}s`;
	} else {
		return `${duration} ${unitToLabel[unit]}`;
	}
}

function _advancePaymentString(durationType, advanceUnit) {
	if (durationType !== "Months") {
		return "n/a";
	}

	if (advanceUnit > 1) {
		return `${advanceUnit} Months`;
	} else {
		return `${advanceUnit} Month`;
	}
}

function _cancellationFee(data) {
	if (data.DurationType !== DURATION_TYPES.MONTHS) {
		return "n/a";
	}

	return data.EarlyCancellationFee && data.EarlyCancellationFee > 0
		? format.money(data.EarlyCancellationFee)
		: "Free";
}

function _proRataDayString(data) {
	if (data.DurationType !== DURATION_TYPES.MONTHS) {
		return "n/a";
	}

	return data.ProRataDay ? `Day ${data.ProRataDay}` : "None";
}

function _getSpacesAndSites(data) {
	let items = [];

	for (let leasePark of data.leaseParks) {
		let sites = leasePark.sites.map((site) => site.Name);
		items.push(`${leasePark.Name} (${sites.join(", ")})`);
	}

	return items.join(", ");
}

function _getEventSeries(data) {
	return data.eventSeries.map((event) => event.Name).join(", \n") + "\n";
}

/**
 * Gets an an array of columns and their display/mapping data.
 *
 * @param {object} props
 * @returns {object}
 * @private
 */
const _getColumns = (props) => {
	return [
		{
			id: "Name",
			Header: "Rate Name",
			accessor: (d) => d.Name,
		},
		{
			id: "FeePerTerm",
			Header: "Fee",
			accessor: (d) => {
				if (!d.MonthlyFee) {
					return "Free";
				}

				if (d.DailyFees) {
					return format.dailyFeeRange(d.DailyFees);
				}

				return format.money(d.MonthlyFee);
			},
			width: 150,
		},
		{
			id: "DurationType",
			Header: "Duration Type",
			accessor: (d) =>
				`${d.DurationType}${
					d.NonConsecutiveBooking ? " (Non-Consecutive)" : ""
				}`,
		},
		{
			id: "MinimumDuration",
			Header: "Minimum Booking",
			accessor: (d) =>
				_getDurationString(
					d.MinimumDurationMonths,
					d.DurationType,
					d.HourlyBillableDuration
				),
		},
		{
			id: "MaximumDuration",
			Header: "Maximum Booking",
			accessor: (d) =>
				_getDurationString(
					d.MaximumDurationAmount,
					d.MaximumDurationType,
					d.HourlyBillableDuration
				),
		},
		{
			id: "UnitToPayInAdvance",
			Header: "Pay in Advance",
			accessor: (d) =>
				_advancePaymentString(d.DurationType, d.UnitToPayInAdvance),
		},
		{
			id: "BookInAdvance",
			Header: "Book In Advance",
			accessor: (d) =>
				d.BookInAdvanceDuration
					? `${d.BookInAdvanceDuration} ${
							d.BookInAdvanceDuration > 1
								? getUnitLabel(d.BookInAdvanceUnitType, "plural", "lower")
								: getUnitLabel(d.BookInAdvanceUnitType, "single", "lower")
					  }`
					: "No limit",
		},
		{
			id: "CancellationFee",
			Header: "Cancellation Fees",
			accessor: (d) => _cancellationFee(d),
		},
		{
			id: "ProRataDay",
			Header: "Pro-rata day",
			accessor: (d) => _proRataDayString(d),
		},
		{
			id: "Event Series",
			Header: "Event Series",
			accessor: (d) => _getEventSeries(d),
		},
		{
			id: "SpaceBlocks",
			Header: "Spaces",
			accessor: (d) => _getSpacesAndSites(d),
		},
		{
			id: "Groups",
			Header: "Groups",
			accessor: (d) =>
				d.organizationAccessGroups.length > 0
					? d.organizationAccessGroups.map((group) => group.Name).join(", ")
					: "Public",
		},
		{
			id: "tasks",
			Header: "",
			accessor: null,
			Cell: (cellProps) => {
				if (!_canEditFixedTermRate(props, cellProps.original)) {
					return null;
				}

				return (
					<DropdownMenu
						triggerContent={<MoreHorizontal size={24} />}
						items={[
							<div
								key="update"
								onClick={() => props.openWizard(cellProps.original, "update")}
							>
								Edit
							</div>,
							<div
								key="remove"
								onClick={() => props.openWizard(cellProps.original, "remove")}
							>
								Remove
							</div>,
						]}
					/>
				);
			},
			resizable: false,
			width: 50,
			sortable: false,
		},
	];
};

/**
 * Monthly Rates container; lists monthly rates and serves the Monthly Rate Wizards.
 */
export default function BookingRatesContainer(props) {
	const isAdmin = usePermissions("IsAdmin");
	const canManageRates = usePermissions(null, "ManageRates", true);

	const canCreate = isAdmin || canManageRates;

	const [state, setState] = useState({
		initialLoad: true,
		manipulated: false,
		options: {
			sites: [],
			"lease-parks": [],
		},
	});

	/**
	 * Updates the filtering options on the rates, which triggers the
	 * useFetch methods to resubmit to the API and get a new set of data.
	 *
	 * @param {object} state New content for the state.options field
	 * @param {integer[]} state.sites Array of IDs to filter sites by
	 * @param {integer[]?} state.parkingBlocks Array of IDs to filter parking blocks by
	 */
	async function updateOptions({ search, ...options }, leaseParksManipulated) {
		//If this method was called by the ReportWrapper, it won't have the notion
		//of parking blocks, so insert it back into the update object before applying it
		//over the current state
		if (!options["lease-parks"]) {
			options["lease-parks"] = state.options["lease-parks"]
				? state.options["lease-parks"]
				: [];
		}

		if (leaseParksManipulated) {
			setState((_state) => ({
				..._state,
				initialLoad: false,
				search,
				options,
				manipulated: true,
			}));
		} else {
			setState((_state) => ({
				..._state,
				initialLoad: false,
				search,
				options,
			}));
		}
	}

	/**
	 * Whenever the parking block filter dropdown is changed, append the change
	 * to the state.options data and call the parent handler
	 *
	 * @param {integer[]} leaseParkFilter Array of IDs to filter parking blocks by
	 */
	async function leaseParkSelectionChanged(leaseParkFilter) {
		const options = state.options;
		options["lease-parks"] = leaseParkFilter;
		updateOptions(options, true);
	}

	const organizationId = props.selectedOrganization
		? props.selectedOrganization.OrganizationID
		: null;

	//Load data into reference variables
	const { data: leaseParks, isLoading: isLoadingParkingBlocks } = useFetchData(
		[],
		installationApi.getLeaseParksForOrganization,
		[organizationId],
		[state.options.sites, state.options["lease-parks"]]
	);

	const { data: organizationAccessGroups } = useFetchData(
		[],
		installationApi.getOrganizationAccessGroups,
		[organizationId],
		[]
	);

	const { eventSeries, isLoading: isEventSeriesLoading } =
		useGetAllEventSeries(true);

	const { data, isLoading } = useFetchData(
		[],
		installationApi.getLeaseRatesForOrganizationId,
		[organizationId, state.options],
		[state.options.sites, state.options["lease-parks"]]
	);

	//Drop all sites from lease parks into the rates so they can iterated over easily
	if (data) {
		for (let leaseRate of data) {
			leaseRate.sites = [];

			for (let leasePark of leaseRate.leaseParks) {
				for (let site of leasePark.sites) {
					leaseRate.sites.push(site);
				}
			}
		}
	}

	function openWizard(fixedTermRate, mode) {
		//Make an empty monthly rate to hold data if creating a new one
		if (fixedTermRate === null && mode === "add") {
			fixedTermRate = { name: "none" };
		}

		setState((_state) => ({
			..._state,
			wizardOpen: true,
			mode: mode,
			leaseRates: { data },
			leaseRate: fixedTermRate,
			leaseParks: leaseParks,
			sites: props.availableSites,
		}));
	}

	//If redirected here from a Lease Park with Corporate rate pairing,
	//find the lease rate and open the wizard for it in edit mode
	if (
		data &&
		props.history.location.search &&
		props.history.location.search.includes("lease-rate-id") &&
		!state.redirectProcessed
	) {
		let leaseRateId = parseInt(
			props.history.location.search.match(new RegExp(/[0-9]+/))
		);
		const leaseRate = data.find((i) => i.LeaseRateID === leaseRateId);

		if (leaseRate) {
			setState((_state) => ({ ..._state, redirectProcessed: true }));
			openWizard(leaseRate, "update");
			return null;
		}
	}

	function _rightActions() {
		if (!canCreate) {
			return null;
		}

		return (
			<Button onClick={() => openWizard(null, "add")} color="blue">
				<Plus size={20} />
				Add Booking Rate
			</Button>
		);
	}

	if (state.wizardOpen) {
		return (
			<BookingRateWizard
				close={() => {
					setState((_state) => ({ ..._state, wizardOpen: false }));

					//remove lease id in url if directed from space mgmt page
					let url = window.location.href;
					if (url.indexOf("lease-rate-id") !== -1) {
						props.history.push(
							`/organization/${organizationId}/space-management`
						);
					}
				}}
				mode={state.mode}
				leaseRate={state.leaseRate}
				leaseRates={data}
				organization={props.selectedOrganization}
				sites={props.availableSites}
				availableSites={props.availableSites}
				leaseParks={leaseParks}
				organizationAccessGroups={organizationAccessGroups}
				eventSeries={eventSeries}
			/>
		);
	}

	return (
		<div>
			<ReportWrapper
				{...props}
				data={data}
				defaultSorted={[
					{
						id: "Name",
						desc: false,
					},
				]}
				title={"Booking Rates"}
				sites={props.availableSites}
				availableSites={props.availableSites}
				columns={_getColumns({
					openWizard: openWizard,
					leaseParks: leaseParks,
					availableSites: props.availableSites,
					permissions: {
						isAdmin: isAdmin,
						canManageRates: canManageRates,
					},
				})}
				loading={isLoading || isLoadingParkingBlocks || isEventSeriesLoading}
				updateOptions={updateOptions}
				showDateRangePicker={false}
				showSearchBox={false}
				leftActions={
					<ReportPicker
						style={{ marginLeft: 16 }}
						name="spaces"
						options={leaseParks.map((leasePark) => {
							return { value: leasePark.LeaseParkID, label: leasePark.Name };
						})}
						selected={
							state.manipulated
								? state.options["lease-parks"]
								: leaseParks.map((leasePark) => leasePark.LeaseParkID)
						}
						onSelectedChanged={leaseParkSelectionChanged}
					/>
				}
				rightActions={_rightActions()}
			/>
		</div>
	);
}
