import React from "react";
import DropdownMenu from "../../../components/layout/DropdownMenu";
import { MoreHorizontal } from "react-feather";
import styled from "styled-components";

const RemoveButton = styled.div`
	color: ${(props) => (props.disabled ? "grey" : "black")};
	cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;
export const ManageColumns = (props, columns, openWizard) => [
	...columns,
	{
		id: "tasks",
		Header: "",
		accessor: null,
		Cell: (cellProps) => (
			<DropdownMenu
				triggerContent={<MoreHorizontal size={24} />}
				items={[
					<div
						key="update"
						onClick={() =>
							openWizard(
								cellProps.original,
								cellProps.original._isUserInvitation
									? "invite-update"
									: "update"
							)
						}
					>
						Edit
					</div>,
					<RemoveButton
						key="remove"
						onClick={() => {
							openWizard(
								cellProps.original,
								cellProps.original._isUserInvitation ||
									cellProps.original.notRedeemed
									? "invite-remove"
									: "remove"
							);
						}}
					>
						Remove
					</RemoveButton>,
				]}
			/>
		),
		resizable: false,
		width: 50,
		sortable: false,
	},
];
