import styled from "styled-components";

export const FieldWrapper = styled.div`
	margin: 16px 32px;
`;

export const FieldContent = styled.div`
	> * {
		margin: 0;
		width: 100%;
		min-height: 32px;
	}
`;

export const Label = styled.div`
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 4px;
`;
