import EditableInputField from "../../../layout/EditableInputField";
import React from "react";
import { StepTitle } from "../../WizardLayout";
import footer from "../helpers/footer";

export default ({
	goTo,
	previous,
	setFieldValue,
	values,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "name",
	label: "Name",
	summary: {
		value: values.name,
		edit: () => {
			setFieldValue("editFromSummary", true);
			goTo("name");
		},
	},
	render: () => (
		<div>
			<StepTitle>What is the name for this rate?</StepTitle>
			<EditableInputField
				type="text"
				name="name"
				onChange={(event) => setFieldValue("name", event.target.value)}
				value={values.name}
				useLabel="Name"
			/>
		</div>
	),
	footer: () =>
		footer(
			values,
			previous,
			goTo,
			"appliesTo",
			wizardProps,
			!!(values.name || "").trim(),
			true,
			setFieldValue,
			false,
			keyStrokeHandler
		),
});
