import React from "react";
import { SearchTabs } from "./Search";

export default function Search() {
	return (
		<>
			<SearchTabs />
		</>
	);
}
