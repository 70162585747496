/* eslint-disable react/jsx-key */

import React, { useEffect, useMemo, useState, useRef } from "react";
import { usePagination, useSortBy, useTable } from "react-table";
import Highlighter from "react-highlight-words";
import { colours } from "../../styles";
import styled from "styled-components";
import LoadingPlaceholder from "../report/LoadingPlaceholder";
import { PAGINATION_EVENTS } from "../../helpers/constants";

const Wrapper = styled.div`
	table {
		border: 0;
		border-spacing: 0;
		width: 100%;
		line-height: 1.5;

		th,
		td {
			border: 0;
			padding: 16px 8px;
			text-align: left;
			user-select: none;
			max-width: 300px;
		}

		th {
			padding-right: 16px;
			font-weight: 600;
		}

		td {
			overflow: hidden;
			text-overflow: ellipsis;
		}

		thead {
			font-size: 16px;

			th {
				border-bottom: 2px solid ${colours.borderGrey};
			}
		}

		tbody {
			tr:nth-child(odd) {
				background-color: ${colours.offWhite};
			}

			tr:hover {
				background: ${colours.lightGrey};
			}

			td {
				font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica,
					Arial, sans-serif;
				font-size: 14px;
			}
		}
	}

	.pagination-bottom {
		border: 0;
		box-shadow: none;
		margin-top: 32px;
		display: flex;
		padding: 4px;

		.-previous button {
			display: block;
			margin-left: auto;
		}

		.-previous,
		.-next {
			flex: 1;

			button[disabled],
			button {
				border: 0;
				background-color: ${colours.lightGrey};
				border-radius: 0;
				color: ${colours.darkGrey};
				font-size: 18px;
				font-weight: 600;
				height: 40px;
				line-height: 24px;
				width: 100%;
				max-width: 200px;
				padding: 8px 32px;
				position: relative;
				text-align: center;
				transition: 0.2s ease;
				outline: none;

				&::after {
					background-color: inherit;
					bottom: 0;
					content: "";
					filter: brightness(75%);
					height: 2px;
					left: 0;
					position: absolute;
					width: 100%;
				}
			}

			button[disabled] {
				opacity: 0.5;
				cursor: default;
			}

			button:hover:enabled {
				background: rgba(0, 0, 0, 0.3);
				color: #fff;
				cursor: pointer;
			}
		}

		.-center {
			display: flex;
			flex: 1.5;
			justify-content: space-around;

			select {
				border: 1px solid rgba(0, 0, 0, 0.1);
				background: #fff;
				padding: 4px 8px;
				font-size: inherit;
				border-radius: 4px;
				font-weight: normal;
				outline: 0;
			}
			span {
				padding-top: 5px;
				display: inline-block;
				margin: 3px 10px;
				white-space: nowrap;

				input {
					padding: 5px 7px;
					border: 1px solid rgba(0, 0, 0, 0.1);
					width: 70px;
					text-align: center;
				}
			}
		}
	}

	.boolean {
		position: relative;

		.cross {
			height: 12px;
			left: 8px;
			position: absolute;
			top: -6px;
			width: 12px;

			&::before,
			&::after {
				background-color: ${colours.darkGrey};
				content: "";
				height: 12px;
				left: 6px;
				position: absolute;
				width: 2px;
			}

			&::before {
				transform: rotate(45deg);
			}

			&::after {
				transform: rotate(-45deg);
			}

			&.colour {
				&::before,
				&::after {
					background-color: ${colours.red};
				}
			}
		}

		.tick {
			height: 16px;
			left: 8px;
			position: absolute;
			top: -6px;
			width: 16px;

			&::before {
				background-color: ${colours.darkGrey};
				content: "";
				height: 2px;
				position: absolute;
				width: 6px;
				top: 7px;
				transform: rotate(45deg);
			}

			&::after {
				background-color: ${colours.darkGrey};
				content: "";
				height: 12px;
				left: 7px;
				position: absolute;
				transform: rotate(45deg);
				width: 2px;
			}

			&.colour {
				&::before,
				&::after {
					background-color: ${colours.green};
				}
			}
		}
	}
`;

const Resizer = styled.div`
	position: absolute;
	right: -16px;
	top: -12px;

	&::before {
		border-bottom: ${(props) =>
			props.isSortedDesc ? 0 : `4px solid ${colours.green}`};
		border-top: ${(props) =>
			props.isSortedDesc ? `4px solid ${colours.green}` : 0};
		content: "";
		border-left: 4px solid transparent;
		border-right: 4px solid transparent;
		height: 0;
		position: absolute;
		right: 4px;
		top: 50%;
		transform: translateY(-50%);
		width: 0;
	}
`;

const Results = styled.div`
	font-size: 16px;
	font-weight: 600;
	padding: 16px 0;
	text-align: center;
`;

const NoData = styled.div`
	font-size: 20px;
	font-weight: 600;
	margin: 64px 0;
	text-align: center;
`;

export function booleanValueFormatter(value, onlyShowTick = false) {
	return (
		<div className="boolean">
			<div className={value ? "tick" : onlyShowTick ? "" : "cross"} />
		</div>
	);
}

export function booleanValueFormatterColour(value) {
	return (
		<div className="boolean">
			<div className={value ? "tick colour" : "cross colour"} />
		</div>
	);
}

export function highlightedCell(value, search) {
	if (React.isValidElement(value) && value.props.children) {
		return React.Children.map(value.props.children, (child) =>
			React.cloneElement(child, {
				children: (
					<Highlighter
						searchWords={[search]}
						autoEscape={true}
						textToHighlight={String(child.props.children || "")}
					/>
				),
			})
		);
	}

	return (
		<Highlighter
			searchWords={[search]}
			autoEscape={true}
			textToHighlight={String(value) || ""}
		/>
	);
}

function TableWrapper(props) {
	const isMountingRef = useRef(false);

	useEffect(() => {
		isMountingRef.current = true;
	}, []);

	const [inputValue, setInputValue] = useState(0);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		setPageSize,
		state: { pageSize, sortBy },
	} = useTable(
		{
			columns: props.columns,
			data: props.data,
			initialState: {
				sortBy: props.tableProps.defaultSortBy,
				pageSize: props.pageSize,
			},
			useControlledState: (state) => {
				return useMemo(
					() => ({
						...state,
						pageIndex: props.pageIndex,
					}),
					[state, props.pageIndex]
				);
			},
			manualPagination: true,
			manualSortBy: true,
			disableSortBy: false,
			disableMultiSort: true,
			disableSortRemove: true,
			autoResetPage: false,
			autoResetSortBy: false,
			pageCount: props.pageCount,
		},
		useSortBy,
		usePagination
	);

	useEffect(() => {
		if (!isMountingRef.current) {
			const column = sortBy[0].id;
			const desc = sortBy[0].desc;

			onChange(PAGINATION_EVENTS.SORT, {
				column,
				order: desc ? "desc" : "asc",
			});
		} else {
			isMountingRef.current = false;
		}
	}, [sortBy]);

	useEffect(() => {
		setInputValue(props.pageIndex);
	}, [props.pageIndex]);

	const onChange = (event, data = {}) => {
		props.onChange(event, data);
	};

	if (props.loading) {
		return <LoadingPlaceholder noCardWrapper={true} />;
	}

	return (
		<Wrapper>
			<table {...getTableProps()}>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th
									{...column.getHeaderProps(column.getSortByToggleProps())}
									title=""
									style={{
										color: column.isSorted ? colours.green : colours.darkGrey,
										width: column.fixedWidth || "auto",
										cursor: column.disableSort ? "default" : "pointer",
									}}
								>
									{column.render("Header")}
									{column.isSorted ? (
										<div style={{ position: "relative" }}>
											<Resizer isSortedDesc={column.isSortedDesc}></Resizer>
										</div>
									) : null}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{rows.map((row) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map((cell) => {
									return (
										<td {...cell.getCellProps()}>{cell.render("Cell")}</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
			<div className="pagination-bottom">
				<div className="-previous">
					<button
						onClick={() => onChange(PAGINATION_EVENTS.PREVIOUS)}
						disabled={props.pageIndex === 0}
					>
						{"Previous"}
					</button>
				</div>
				<div className="-center">
					<span>
						Page{" "}
						<input
							type="number"
							value={inputValue + 1}
							onChange={(e) => {
								const page = e.target.value ? Number(e.target.value) - 1 : 0;
								if (0 <= page && page < props.pageCount) {
									setInputValue(page);
								}
							}}
							onKeyUp={(e) => {
								if (e.key === "Enter") {
									onChange(PAGINATION_EVENTS.GOTO, { goto: inputValue });
								}
							}}
						/>{" "}
						of {props.pageCount}
					</span>
					<select
						value={pageSize}
						onChange={(e) => {
							const newPageSize = Number(e.target.value);
							setPageSize(newPageSize);
							onChange(PAGINATION_EVENTS.PAGE_SIZE, {
								pageSize: newPageSize,
							});
						}}
					>
						{[10, 25, 50, 100].map((_pageSize) => (
							<option key={_pageSize} value={_pageSize}>
								{_pageSize} rows
							</option>
						))}
					</select>
				</div>
				<div className="-next">
					<button
						onClick={() => onChange(PAGINATION_EVENTS.NEXT)}
						disabled={props.pageIndex === props.pageCount - 1}
					>
						{"Next"}
					</button>
				</div>
			</div>
		</Wrapper>
	);
}

export default function TableLayoutPaginateV2(props) {
	const columns = useMemo(() => props.columns || []);
	const data = useMemo(() => props.data);

	return (
		<div>
			{(data.length > 0 || props.loading) && (
				<TableWrapper
					columns={columns}
					data={data}
					tableProps={props}
					onChange={(event, d) => props.onChange(event, d)}
					pageCount={
						props.pageSize ? Math.ceil(props.totalRecords / props.pageSize) : 0
					}
					pageSize={props.pageSize}
					pageIndex={props.pageIndex}
					loading={props.loading}
				/>
			)}
			{props.showResultsLength && data.length > 0 && (
				<Results>
					{`Showing ${data.length} result${data.length > 1 ? "s" : ""}`}
				</Results>
			)}
			{data.length === 0 && !props.loading && <NoData>No data found</NoData>}
		</div>
	);
}
