import * as Yup from "yup";
import * as beaconSteps from "./beacon-steps";
import * as installationApi from "../../../api/installation";
import Alert from "react-s-alert";
import React from "react";
import WizardLayout from "../WizardLayout";
import gql from "graphql-tag";
import { useMutateData } from "../../../hooks";

const constructWizard = (props) => {
	if (props.mode === "add-beacon") {
		return {
			initialStep: 0,
			steps: [beaconSteps.addBeaconStep],
		};
	} else if (props.mode === "delete-beacon") {
		return {
			initialStep: 0,
			steps: [beaconSteps.deleteBeaconStep],
		};
	} else if (props.mode === "admin-add-beacon") {
		return {
			initialStep: 0,
			steps: [beaconSteps.addAdminBeaconStep],
		};
	} else if (props.mode === "admin-edit-beacon") {
		return {
			initialStep: 0,
			steps: [beaconSteps.adminEditBeaconStep],
		};
	}
};

export default function BeaconWizard(props) {
	const wizard = constructWizard(props);

	const updateBeacon = useMutateData(gql`
		mutation ($beaconId: Int!, $status: String!, $clientId: Int) {
			updateBeacon(beaconId: $beaconId, status: $status, clientId: $clientId)
		}
	`);

	const addBeaconToNode = useMutateData(gql`
		mutation ($uniqueId: String!, $nodeId: Int!, $siteId: Int!) {
			addBeaconToNode(uniqueId: $uniqueId, nodeId: $nodeId, siteId: $siteId)
		}
	`);

	const deleteBeaconFromNode = useMutateData(gql`
		mutation ($beaconId: Int!, $nodeId: Int!, $siteId: Int!) {
			deleteBeaconFromNode(
				beaconId: $beaconId
				nodeId: $nodeId
				siteId: $siteId
			)
		}
	`);

	return (
		<WizardLayout
			close={props.close}
			title={(values) => {
				let _title = values.mode.replace(/-/g, " ");
				//capitalize the 1st letter of each word
				_title = _title.replace(/\b[a-z](?=[a-z]{2})/g, (letter) =>
					letter.toUpperCase()
				);

				return _title;
			}}
			values={[
				{ name: "mode", value: props.mode, validator: Yup.string() },
				{
					name: "beaconId",
					value: props.beacon ? props.beacon.BeaconID : null,
					validator: Yup.number().integer().nullable(),
				},
				{
					name: "siteId",
					value: props.siteId,
					validator: Yup.number().integer().nullable(),
				},
				{
					name: "uniqueId",
					value: props.beacon ? props.beacon.UniqueID : null,
					validator: Yup.string(),
				},
				{
					name: "beaconSiteId",
					value: props.beacon ? props.beacon.BeaconSiteID : null,
					validator: Yup.number().integer().nullable(),
				},
				{
					name: "uniqueIdValid",
					value: false,
					validator: Yup.boolean(),
				},
				{
					name: "status",
					value: props.beacon ? props.beacon.Status : null,
					validator: Yup.string().nullable(),
				},
				{
					name: "clientId",
					value: props.beacon ? props.beacon.ClientID : null,
					validator: Yup.number().integer().nullable(),
				},
			]}
			onSubmit={async (values, { setSubmitting }) => {
				setSubmitting(true);
				let message = "";
				try {
					if (values.mode === "add-beacon") {
						message = "added";

						await addBeaconToNode({
							variables: {
								uniqueId: values.uniqueId,
								nodeId: props.node.NodeID,
								siteId: props.siteId,
							},
						});
					} else if (values.mode === "delete-beacon") {
						message = "deleted";
						await deleteBeaconFromNode({
							variables: {
								beaconId: props.beacon.BeaconID,
								nodeId: props.node.NodeID,
								siteId: props.siteId,
							},
						});
					} else if (values.mode === "admin-add-beacon") {
						message = "added";
						await installationApi.createBeacon({
							beaconId: values.uniqueId,
						});
					} else if (values.mode === "admin-edit-beacon") {
						message = "updated";
						await updateBeacon({
							variables: {
								beaconId: values.beaconId,
								clientId: values.clientId,
								status: values.status,
							},
						});
					}

					setSubmitting(false);
					Alert.success(`Beacon ${message}`);
					props.close(true);
				} catch {
					setSubmitting(false);
					Alert.error("Something went wrong. Please try again.");
				}
			}}
			steps={wizard.steps}
			initialStep={wizard.initialStep}
			wizardProps={props}
		/>
	);
}
