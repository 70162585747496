import * as installationApi from "../../../api/installation";
import React, { useCallback, useState } from "react";
import { isObject, sortBy } from "lodash";
import Button from "../../layout/Button";
import Loader from "../../../components/layout/Loader";
import TableLayout from "../../layout/TableLayout";
import { colours } from "../../../styles";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import { useFetchData } from "../../../hooks";
import { validateCSV } from "../../../helpers/validate";

const Wrapper = styled.div`
	a {
		color: ${colours.blue};
		text-decoration: none;
		transition: 0.2s ease;

		&:hover {
			cursor: pointer;
			opacity: 0.8;
		}
	}
`;

const Label = styled.div`
	font-size: 24px;
	font-weight: 700;
	margin-bottom: 16px;
`;

const ErrorText = styled.div`
	color: ${colours.red};
	font-size: 18px;
	font-weight: 600;
	margin: 16px 0 32px;
	text-align: center;
`;

const FormatLink = styled.div`
	font-size: 16px;
	margin: 16px 0;
`;

const DropBox = styled.div`
	background: ${colours.highlightGrey};
	border: 4px dashed ${colours.borderGrey};
	border-radius: 4px;
	margin-bottom: 10px;
	padding: 60px 10px;
	text-align: center;
	width: 100%;

	p {
		font-size: 18px;
		font-weight: 600;
	}

	&.dragover-err {
		border: 5px dashed $red;
	}

	&.dragover {
		border: 5px dashed $green;
	}
`;

function getValue(name, value) {
	return isObject(value[name]) ? value[name].value : value[name];
}

function Results(props) {
	return (
		<div>
			<Label>Hardware Import Results</Label>
			{[
				["Passed", "added"],
				["Failed", "failed"],
				["Duplicates", "duplicates"],
			].map((e) => {
				if (!props.data[e[1]] || props.data[e[1]].length === 0) return null;

				return (
					<div key={e[1]}>
						<h3 style={{ marginTop: 48 }}>{e[0]}</h3>
						<TableLayout
							data={sortBy(props.data[e[1]], "index")}
							columns={[
								{
									id: "Row",
									Header: "Row",
									accessor: "index",
								},
								{
									id: "Serial Number",
									Header: "Serial Number",
									accessor: (d) => getValue("Serial Number", d),
								},
								{
									id: "Hardware ID",
									Header: "Hardware ID",
									accessor: (d) => getValue("Hardware ID", d),
								},
								{
									id: "BLE Address",
									Header: "BLE Address",
									accessor: (d) => getValue("BLE Address", d),
								},
								{
									id: "Software Version",
									Header: "Software Version",
									accessor: "Software Version",
								},
								{
									id: "System Firmware",
									Header: "System Firmware",
									accessor: "System Firmware",
								},
								{
									id: "Product Version",
									Header: "Product Version",
									accessor: (d) => getValue("Product Version", d),
								},
								{
									id: "Beacon Attached",
									Header: "Beacon Attached",
									accessor: "Beacon Attached",
								},
								{
									id: "Beacon Hardware ID",
									Header: "Beacon Hardware ID",
									accessor: "Beacon Hardware ID",
								},
								{
									id: "Beacon BLE Address",
									Header: "Beacon BLE Address",
									accessor: "Beacon BLE Address",
								},
								{
									id: "Beacon Software Version",
									Header: "Beacon Software Version",
									accessor: "Beacon Software Version",
								},
								{
									id: "Beacon System Firmware",
									Header: "Beacon System Firmware",
									accessor: "Beacon System Firmware",
								},
								{
									id: "Beacon Product Version",
									Header: "Beacon Product Version",
									accessor: (d) => getValue("Beacon Product Version", d),
								},
								{
									id: "Pre-auth Enabled",
									Header: "Pre-auth Enabled",
									accessor: "Pre-auth Enabled",
								},
								{
									id: "Notes",
									Header: "Notes",
									accessor: "Notes",
								},
								{
									id: "Status",
									Header: "Status",
									accessor: "Status",
								},
								{
									id: "Client",
									Header: "Client",
									accessor: "Client",
								},
							]}
							sortable={false}
							resizable={false}
							showResultsLength={true}
							showPagination={props.data[e[1]] && props.data[e[1]].length > 10}
							defaultPageSize={10}
						/>
					</div>
				);
			})}
		</div>
	);
}

async function importCSV(file, setSuccess, setError) {
	try {
		const results = await installationApi.importNodesCSV(file);
		setSuccess(results);
	} catch (error) {
		setError();
	}
}

export default function ImportHardware() {
	const [state, setState] = useState({
		file: null,
		submitting: false,
		submitted: false,
		error: false,
		results: null,
	});

	const onDrop = useCallback((acceptedFiles) => {
		setState((_state) => ({
			..._state,
			file: acceptedFiles ? acceptedFiles[0] : null,
			error: false,
		}));
	}, []);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

	const { data: csvSchema, isLoading: csvLoading } = useFetchData(
		[],
		installationApi.getNodesImportCSVSchema,
		[],
		[]
	);

	if (csvLoading || state.submitting) {
		return <Loader />;
	}

	return (
		<Wrapper>
			{!state.submitted && <Label>Import Hardware</Label>}
			{!state.submitted && (
				<FormatLink>
					The file must be in the following{" "}
					<a
						href={`data:text/csv;charset=utf-8,${encodeURI(
							csvSchema.map((column) => column.name).join(",")
						)}`}
						download="format.csv"
					>
						CSV format
					</a>
					.
				</FormatLink>
			)}
			{!state.submitted && (
				<DropBox {...getRootProps()}>
					<input {...getInputProps()} multiple={false} accept="text/csv" />
					{isDragActive && <p>Drop the file here...</p>}
					{!state.file && !isDragActive && (
						<p>Click to choose a file or drag one here...</p>
					)}
					{state.file && !isDragActive && <p>File chosen: {state.file.name}</p>}
				</DropBox>
			)}
			{!state.submitted && (
				<div style={{ textAlign: "center" }}>
					<Button
						color="green"
						onClick={() => {
							setState((_state) => ({
								..._state,
								submitting: true,
								submitted: true,
								error: false,
							}));

							validateCSV(
								state.file,
								csvSchema,
								(results) => {
									setState((_state) => ({
										..._state,
										submitting: false,
										submitted: true,
										error: false,
										results,
									}));
								},
								() => {
									setState((_state) => ({
										..._state,
										submitting: false,
										submitted: false,
										error: true,
									}));
								},
								importCSV
							);
						}}
						disabled={state.submitted || state.error || !state.file}
					>
						Import
					</Button>
				</div>
			)}
			{state.error && (
				<ErrorText>The file does not match the required format</ErrorText>
			)}
			{state.submitted && <Results data={state.results} />}
		</Wrapper>
	);
}
