import * as installationApi from "../../api/installation";
import * as sessionApi from "../../api/session";
import {
	Area,
	AreaChart,
	Bar,
	BarChart,
	CartesianGrid,
	Legend,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import PageActions, {
	LeftActions,
	RightActions,
} from "../../components/layout/PageActions";
import React, { useContext, useState } from "react";
import { AppContext } from "../../context/app-context";
import Card from "../../components/layout/Card";
import DatePicker from "../../components/layout/DatePicker";
import FlexWrapper from "../../components/layout/FlexWrapper";
import LoadingPlaceholder from "../../components/report/LoadingPlaceholder";
import PageTitle from "../../components/layout/PageTitle";
import ReportPicker from "../../components/report/ReportPicker";
import StatCard from "../../components/layout/StatCard";
import { colours } from "../../styles";
import { format } from "../../helpers";
import { isEqual } from "lodash";
import moment from "moment";
import styled from "styled-components";
import { useFetchData } from "../../hooks";
import { useTranslation } from "react-i18next";

const NoData = styled.div`
	font-size: 20px;
	height: 200px;
	position: relative;

	> div {
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
	}
`;

const Title = styled.h2`
	margin-left: 32px;
`;

const Info = styled.div`
	background-color: ${colours.background};
	border: 1px solid ${colours.borderGrey};
	padding: 16px;
`;

export default function DashboardContainer(props) {
	const { t: translate } = useTranslation();

	const {
		dispatch: { setReportDateRange, setReportSiteFilter },
		state: { reportDateRange, reportSiteFilter, spaceAccountOwnerOnly },
	} = useContext(AppContext);

	const [state, setState] = useState({
		initialLoad: true,
		availableSites: props.availableSites,
		siteFilter:
			reportSiteFilter || props.availableSites.map((site) => site.SiteID),
		sites: props.availableSites.map((site) => ({
			value: site.SiteID,
			label: site.Name,
		})),
		dateRange: reportDateRange || [
			moment().startOf("day").toDate(),
			moment().startOf("day").toDate(),
		],
	});

	if (!isEqual(props.availableSites, state.availableSites)) {
		setState((_state) => ({
			..._state,
			availableSites: props.availableSites,
			siteFilter: props.availableSites.map((site) => site.SiteID),
			sites: props.availableSites.map((site) => ({
				value: site.SiteID,
				label: site.Name,
			})),
		}));
	}

	const organizationId = props.selectedOrganization
		? props.selectedOrganization.OrganizationID
		: null;

	if (organizationId && spaceAccountOwnerOnly) {
		// redirect if we should not show this as the default page
		props.history.push(`/organization/${organizationId}/permissions`);
	}

	const options = {
		sites: state.siteFilter,
		"start-date-time": moment(state.dateRange[0]).format("YYYYMMDD"),
		"end-date-time": moment(state.dateRange[1]).format("YYYYMMDD"),
	};

	const { data: sessionReport, isLoading: sessionReportIsLoading } =
		useFetchData(
			{},
			sessionApi.getSessionReport,
			organizationId ? [organizationId, options] : null,
			[organizationId, state.dateRange, state.siteFilter, state.sites]
		);

	const { data: openingsReport, isLoading: openingsReportIsLoading } =
		useFetchData(
			{},
			installationApi.getOpeningsReport,
			organizationId ? [organizationId, options] : null,
			[organizationId, state.dateRange, state.siteFilter, state.sites]
		);

	const isLoading = sessionReportIsLoading || openingsReportIsLoading;

	const occupancyData = sessionReport.sessionData || [];

	let openingsData = [];
	let hasOpeningsData = false;

	if (openingsReport.both) {
		openingsReport.both.data.forEach((value, key) => {
			openingsData.push({
				date: parseInt(key),
				entry: openingsReport.entry.data[key].count,
				exit: openingsReport.exit.data[key].count,
				both: value.count,
			});

			if (
				!hasOpeningsData &&
				(openingsReport.entry.data[key].count > 0 ||
					openingsReport.exit.data[key].count ||
					value.count)
			) {
				hasOpeningsData = true;
			}
		});
	}

	async function onSiteFilterChange(siteFilter) {
		setState((_state) => ({ ..._state, siteFilter }));
		setReportSiteFilter(siteFilter);
	}

	async function onDateRangeChange(dateRange) {
		if (dateRange && dateRange.length > 1) {
			setState((_state) => ({ ..._state, dateRange }));
			setReportDateRange(dateRange);
		}
	}

	function dateFormatter(type, tickItem) {
		if (type === "hourly") {
			return `${String((tickItem / 4) | 0).padStart(2, "0")}:${String(
				(tickItem % 4) * 15
			).padStart(2, "0")}`;
		} else if (type === "daily") {
			return moment(state.dateRange[0]).add(tickItem, "day").format("DD MMM");
		} else if (type === "monthly") {
			return moment(state.dateRange[0])
				.add(tickItem, "month")
				.format("MMM YYYY");
		}
	}

	function formatTooltipDate(type, tickItem) {
		if (type === "daily") {
			return moment(state.dateRange[0])
				.add(tickItem, "day")
				.format("ddd DD MMM");
		} else {
			return dateFormatter(type, tickItem);
		}
	}

	function showDateRange(type, data) {
		if (type === "monthly") {
			onDateRangeChange([
				moment(state.dateRange[0])
					.add(data.activeLabel, "month")
					.startOf("month")
					.toDate(),
				moment(state.dateRange[0])
					.add(data.activeLabel, "month")
					.endOf("month")
					.startOf("day")
					.toDate(),
			]);
		} else if (type === "daily") {
			onDateRangeChange([
				moment(state.dateRange[0]).add(data.activeLabel, "day").toDate(),
				moment(state.dateRange[0]).add(data.activeLabel, "day").toDate(),
			]);
		}
	}

	function renderTooltip({ payload }) {
		const data = payload && payload[0] ? payload[0].payload : null;

		if (data && data.sessionCount !== undefined) {
			return (
				<Info>
					<span>Sessions: </span>
					<span style={{ fontWeight: 700 }}>{data.sessionCount}</span>
					<br />
					<span>{sessionReport.type === "hourly" ? "Time: " : "Date: "} </span>
					<span style={{ fontWeight: 700 }}>
						{formatTooltipDate(sessionReport.type, data.date)}
					</span>
				</Info>
			);
		}

		if (data && data.both !== undefined) {
			return (
				<Info>
					<div>{dateFormatter(data.both.type, data.date)} </div>
					<span>Entry: </span>
					<span style={{ fontWeight: 700 }}>{data.entry}</span>
					<br />
					<span>Exit: </span>
					<span style={{ fontWeight: 700 }}>{data.exit}</span>
					<br />
					<span>Entry/Exit: </span>
					<span style={{ fontWeight: 700 }}>{data.both}</span>
				</Info>
			);
		}

		return null;
	}

	return (
		<div>
			<PageTitle>{translate("Menu.Dashboard")}</PageTitle>
			<PageActions>
				<LeftActions>
					<ReportPicker
						name="sites"
						options={state.sites}
						selected={state.siteFilter}
						onSelectedChanged={onSiteFilterChange}
					/>
				</LeftActions>
				<RightActions>
					<DatePicker
						options={{ pickerMode: "dateRangePicker" }}
						value={state.dateRange}
						onChange={onDateRangeChange}
					/>
				</RightActions>
			</PageActions>

			{isLoading ? (
				<LoadingPlaceholder />
			) : (
				<div>
					{occupancyData.length ||
					(!occupancyData.length && !hasOpeningsData) ? (
						<FlexWrapper style={{ marginBottom: -16 }}>
							<Card>
								<StatCard
									value={
										format.addCommasToNumber(sessionReport.totalSessionCount) ||
										0
									}
									title="Total Session Count"
									subTitle="Within Selected Dates"
									size="large"
								/>
							</Card>
							<Card>
								<StatCard
									value={format.duration(
										sessionReport.averageDuration,
										false,
										true
									)}
									title="Average Duration"
									subTitle="Of Parking Sessions"
									size="large"
								/>
							</Card>
							<Card>
								<StatCard
									value={format.money(sessionReport.averageCharge)}
									title="Average Charge"
									subTitle="Of Parking Sessions"
									size="large"
								/>
							</Card>
						</FlexWrapper>
					) : null}
					{occupancyData.length ||
					(!occupancyData.length && !hasOpeningsData) ? (
						<Card>
							<Title>Sessions</Title>
							{occupancyData.length ? (
								<ResponsiveContainer width="100%" height={400}>
									<AreaChart
										data={occupancyData}
										margin={{ top: 15, right: 30, left: 5, bottom: 5 }}
										onClick={(e) => showDateRange(sessionReport.type, e)}
									>
										<defs>
											<linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
												<stop
													offset="0%"
													stopColor={colours.green}
													stopOpacity={1}
												/>
												<stop
													offset="50%"
													stopColor={colours.green}
													stopOpacity={0.95}
												/>
												<stop
													offset="100%"
													stopColor={colours.green}
													stopOpacity={0.5}
												/>
											</linearGradient>
										</defs>
										<CartesianGrid
											vertical={false}
											stroke={colours.lightGrey}
										/>
										<XAxis
											dataKey="date"
											domain={[0, sessionReport.sessionData.length - 1]}
											tickFormatter={(e) =>
												dateFormatter(sessionReport.type, e)
											}
											interval="preserveStartEnd"
											minTickGap={50}
											axisLine={false}
											tickMargin={12}
											tickLine={false}
										/>
										<YAxis
											dataKey="sessionCount"
											allowDecimals={false}
											axisLine={false}
											tickMargin={24}
											tickLine={false}
										/>
										<Tooltip content={renderTooltip} />
										<Legend wrapperStyle={{ paddingTop: 20 }} />
										<Area
											type="monotone"
											name="Inugo Occupancy"
											dataKey="sessionCount"
											stroke={colours.green}
											strokeWidth={2}
											fill="url(#gradient)"
											isAnimationActive={false}
										/>
									</AreaChart>
								</ResponsiveContainer>
							) : (
								<NoData>
									<div>No parking session data found</div>
								</NoData>
							)}
						</Card>
					) : null}
					{(hasOpeningsData || !occupancyData.length) && (
						<Card>
							<Title>Openings</Title>
							{hasOpeningsData ? (
								<ResponsiveContainer width="100%" height={400}>
									<BarChart
										data={openingsData}
										margin={{ top: 15, right: 30, left: 5, bottom: 5 }}
										onClick={(e) => showDateRange(openingsReport.both.type, e)}
									>
										<defs>
											<linearGradient
												id="gradientGreen"
												x1="0"
												y1="0"
												x2="0"
												y2="1"
											>
												<stop
													offset="0%"
													stopColor={colours.green}
													stopOpacity={1}
												/>
												<stop
													offset="100%"
													stopColor={colours.green}
													stopOpacity={0.9}
												/>
											</linearGradient>
											<linearGradient
												id="gradientRed"
												x1="0"
												y1="0"
												x2="0"
												y2="1"
											>
												<stop
													offset="0%"
													stopColor={colours.red}
													stopOpacity={1}
												/>
												<stop
													offset="100%"
													stopColor={colours.red}
													stopOpacity={0.9}
												/>
											</linearGradient>
											<linearGradient
												id="gradientBlue"
												x1="0"
												y1="0"
												x2="0"
												y2="1"
											>
												<stop
													offset="0%"
													stopColor={colours.blue}
													stopOpacity={1}
												/>
												<stop
													offset="100%"
													stopColor={colours.blue}
													stopOpacity={0.9}
												/>
											</linearGradient>
										</defs>
										<CartesianGrid
											vertical={false}
											stroke={colours.lightGrey}
										/>
										<XAxis
											dataKey="date"
											domain={[0, openingsReport.both.length - 1]}
											tickFormatter={(e) =>
												dateFormatter(openingsReport.both.type, e)
											}
											interval="preserveStartEnd"
											minTickGap={50}
											axisLine={false}
											tickMargin={12}
											tickLine={false}
										/>
										<YAxis
											allowDecimals={false}
											axisLine={false}
											tickMargin={12}
											tickLine={false}
										/>
										<Tooltip content={renderTooltip} />
										<Legend wrapperStyle={{ paddingTop: 20 }} />
										<Bar
											name="Entry"
											dataKey="entry"
											stackId="a"
											fill="url(#gradientGreen)"
											isAnimationActive={false}
										/>
										<Bar
											name="Exit"
											dataKey="exit"
											stackId="a"
											fill="url(#gradientRed)"
											isAnimationActive={false}
										/>
										<Bar
											name="Entry/Exit"
											dataKey="both"
											stackId="a"
											fill="url(#gradientBlue)"
											isAnimationActive={false}
										/>
									</BarChart>
								</ResponsiveContainer>
							) : (
								<NoData>
									<div>No gate opening data found</div>
								</NoData>
							)}
						</Card>
					)}
				</div>
			)}
		</div>
	);
}
