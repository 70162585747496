import * as Yup from "yup";
import * as api from "./api";
import * as steps from "./steps";
import { constants, format } from "../../../helpers";
import { useLazyQueryData, useMutateData } from "../../../hooks";
import React from "react";
import WizardLayout from "../WizardLayout";
import gql from "graphql-tag";
import { maxBy } from "lodash";

const constructWizard = (props) => {
	switch (props.mode) {
		case "add-gate-controller":
			return {
				initialStep: 0,
				steps: [
					steps.serialNumberStep,
					steps.nameStep,
					steps.accessTypeStep,
					steps.communicationMethodStep,
					steps.boatRampStep,
					steps.showAccessButtonStep,
					steps.loopDetectionStep,
					steps.gateOpenSignalStep,
					steps.gateCloseRelayStep,
					steps.forceSessionlessAccessStep,
					steps.restrictedAccessStep,
					steps.qrCodeStep,
					steps.summaryStep,
				],
			};
		case "edit-gate-controller":
			return {
				initialStep: 12,
				steps: [
					steps.serialNumberStep,
					steps.nameStep,
					steps.accessTypeStep,
					steps.communicationMethodStep,
					steps.boatRampStep,
					steps.showAccessButtonStep,
					steps.loopDetectionStep,
					steps.gateOpenSignalStep,
					steps.gateCloseRelayStep,
					steps.forceSessionlessAccessStep,
					steps.restrictedAccessStep,
					steps.qrCodeStep,
					steps.summaryStep,
				],
			};
		case "ping-gate":
			return {
				initialStep: 0,
				steps: [steps.pingGateStep],
			};
		case "open-gate":
			return {
				initialStep: 0,
				steps: [steps.openGateStep],
			};
		case "close-gate":
			return {
				initialStep: 0,
				steps: [steps.closeGateStep],
			};
		case "toggle-gate-controller":
			return {
				initialStep: 0,
				steps: [steps.toggleNodeStep],
			};
		case "delete-gate-controller":
			return {
				initialStep: 0,
				steps: [steps.deleteNodeStep],
			};
		case "add-group":
			return {
				initialStep: 0,
				steps: [steps.groupStep],
			};
		case "not-installing":
			return {
				initialStep: 0,
				steps: [steps.didNotInstallStep],
			};
		case "remove-status":
			return {
				initialStep: 0,
				steps: [steps.removeStatusStep],
			};
		case "edit-group":
			return {
				initialStep: 0,
				steps: [steps.groupStep],
			};
		case "remove-group":
			return {
				initialStep: 0,
				steps: [steps.deleteGroupStep],
			};
		case "add-lane":
			return {
				initialStep: 0,
				steps: [steps.laneStep],
			};
		case "edit-lane":
			return {
				initialStep: 0,
				steps: [steps.laneStep],
			};
		case "remove-lane":
			return {
				initialStep: 0,
				steps: [steps.deleteLaneStep],
			};
		case "audit-logs":
			return {
				initialStep: 0,
				steps: [steps.auditLogStep],
			};
		case "admin-edit":
			return {
				initialStep: 0,
				steps: [steps.adminEditStep],
			};
		case "admin-ping-gate":
			return {
				initialStep: 0,
				steps: [steps.adminPingGateStep],
			};
		case "admin-add-gates":
			return {
				initialStep: 0,
				steps: [steps.adminAddHardwareStep],
			};
		case "admin-refresh-settings":
			return {
				initialStep: 0,
				steps: [steps.adminRefreshSettingsStep],
			};
		case "edit-power-strength":
			return {
				initialStep: 0,
				steps: [steps.editPowerStrengthStep],
			};
		case "create-installation":
			return {
				initialStep: 0,
				steps: [steps.createInstallationStep],
			};
		case "delete-installation":
			return {
				initialStep: 0,
				steps: [steps.deleteInstallationStep],
			};
		case "remove-installation-from-site":
			return {
				initialStep: 0,
				steps: [steps.removeInstallationFromSiteStep],
			};
	}
};

export default function InstallationWizard(props) {
	const wizard = constructWizard(props);

	const createLane = useMutateData(gql`
		mutation (
			$installationId: Int!
			$name: String
			$isReversible: Boolean
			$groupName: String
			$groupOrder: Int
		) {
			createLane(
				installationId: $installationId
				name: $name
				isReversible: $isReversible
				groupName: $groupName
				groupOrder: $groupOrder
			) {
				LaneID
			}
		}
	`);

	const updateLaneGroup = useMutateData(gql`
		mutation ($laneIds: [Int!]!, $groupName: String) {
			updateLaneGroup(laneIds: $laneIds, groupName: $groupName) {
				Name
			}
		}
	`);

	const updateLane = useMutateData(gql`
		mutation (
			$laneId: Int!
			$name: String
			$isReversible: Boolean
			$groupName: String
			$groupOrder: Int!
		) {
			updateLane(
				laneId: $laneId
				name: $name
				isReversible: $isReversible
				groupName: $groupName
				groupOrder: $groupOrder
			) {
				LaneID
			}
		}
	`);

	const updateLaneStatus = useMutateData(gql`
		mutation (
			$laneId: Int!
			$status: LaneStatus
			$reason: String
			$position: NodePosition!
		) {
			updateLane(
				laneId: $laneId
				status: $status
				reason: $reason
				position: $position
			) {
				LaneID
			}
		}
	`);

	const deleteLane = useMutateData(gql`
		mutation ($laneId: Int!) {
			deleteLane(laneId: $laneId)
		}
	`);

	const removeGroup = useMutateData(gql`
		mutation ($laneIds: [Int!]!) {
			deleteLaneGroup(laneIds: $laneIds)
		}
	`);

	const updateNodeStatus = useMutateData(gql`
		mutation (
			$nodeId: Int!
			$status: String!
			$clientId: Int
			$gateTxPower: Int
			$txPower: Int
			$attenuation: Int
		) {
			updateNode(
				nodeId: $nodeId
				status: $status
				clientId: $clientId
				gateTxPower: $gateTxPower
				txPower: $txPower
				attenuation: $attenuation
			)
		}
	`);

	const updateNode = useMutateData(gql`
		mutation (
			$nodeId: Int!
			$siteId: Int
			$laneId: Int
			$name: String
			$accessType: String
			$communicationMethod: CommunicationMethod
			$loopDetection: Boolean
			$gateOpenSignal: Boolean
			$gateCloseRelay: Boolean
			$forceSessionlessAccess: Boolean
			$restrictedAccess: Boolean
			$position: NodePosition!
			$gateTxPower: Int
			$txPower: Int
			$attenuation: Int
			$qrCode: String
			$isBoatRamp: Boolean
			$showAccessButton: Boolean
		) {
			updateNode(
				nodeId: $nodeId
				siteId: $siteId
				laneId: $laneId
				name: $name
				accessType: $accessType
				communicationMethod: $communicationMethod
				loopDetection: $loopDetection
				gateOpenSignal: $gateOpenSignal
				gateCloseRelay: $gateCloseRelay
				forceSessionlessAccess: $forceSessionlessAccess
				restrictedAccess: $restrictedAccess
				position: $position
				gateTxPower: $gateTxPower
				txPower: $txPower
				attenuation: $attenuation
				qrCode: $qrCode
				isBoatRamp: $isBoatRamp
				showAccessButton: $showAccessButton
			)
		}
	`);

	const deleteNode = useMutateData(gql`
		mutation ($nodeId: Int!, $siteId: Int!, $position: NodePosition!) {
			deleteNode(nodeId: $nodeId, siteId: $siteId, position: $position)
		}
	`);

	const enableNode = useMutateData(gql`
		mutation ($nodeId: Int!, $siteId: Int!) {
			enableNode(nodeId: $nodeId, siteId: $siteId)
		}
	`);

	const disableNode = useMutateData(gql`
		mutation ($nodeId: Int!, $siteId: Int!) {
			disableNode(nodeId: $nodeId, siteId: $siteId)
		}
	`);

	const pingDevice = useLazyQueryData(gql`
		query ($nodeId: Int!, $siteId: Int) {
			pingDevice(nodeId: $nodeId, siteId: $siteId)
		}
	`);

	const refreshSettings = useMutateData(gql`
		mutation ($nodeId: Int!) {
			refreshSettings(nodeId: $nodeId)
		}
	`);

	const createInstallation = useMutateData(
		gql`
			mutation ($siteId: Int!, $parentInstallationId: Int) {
				createInstallation(
					siteId: $siteId
					parentInstallationId: $parentInstallationId
				)
			}
		`
	);

	const deleteInstallation = useMutateData(
		gql`
			mutation ($installationId: Int!) {
				deleteInstallation(installationId: $installationId)
			}
		`
	);

	const removeInstallationFromSite = useMutateData(
		gql`
			mutation ($siteId: Int!) {
				removeInstallationFromSite(siteId: $siteId)
			}
		`
	);

	let maxGroupOrder = 0;
	if (props.group) {
		maxGroupOrder = maxBy(props.group.Lanes, "GroupOrder");
	}

	return (
		<WizardLayout
			close={props.close}
			title={(values) => {
				let _title = values.mode.replace(/-/g, " ");
				//capitalize the 1st letter of each word
				_title = _title.replace(/\b[a-z](?=[a-z]{2})/g, (letter) =>
					letter.toUpperCase()
				);

				return _title;
			}}
			values={[
				{
					name: "mode",
					value: props.mode,
					validator: Yup.string(),
				},
				{
					name: "siteId",
					value: props.siteId,
					validator: Yup.number().integer(),
				},
				{
					name: "serialNumber",
					value: props.node ? props.node.SerialNumber : null,
					validator: Yup.string().nullable(),
				},
				{
					name: "hardwareId",
					value: props.node ? props.node.HardWareID : null,
					validator: Yup.number().integer().nullable(),
				},
				{
					name: "nodeId",
					value: props.node ? props.node.NodeID : null,
					validator: Yup.number().integer().nullable(),
				},
				{
					name: "nodeSiteId",
					value: props.node ? props.node.NodeSiteID : null,
					validator: Yup.number().integer().nullable(),
				},
				{
					name: "name",
					value: props.node ? props.node.Name : null,
					validator: Yup.string().nullable(),
				},
				{
					name: "accessType",
					value: props.node ? props.node.AccessType || "Entry" : "Entry",
					validator: Yup.string(),
				},
				{
					name: "communicationMethod",
					value: props.node
						? props.node.CommunicationMethod || "OnlineWithFailover"
						: "OnlineWithFailover",
					validator: Yup.string(),
				},
				{
					name: "isBoatRamp",
					value:
						props.mode === "add-gate-controller"
							? false
							: props.node?.IsBoatRamp || false,
					validator: Yup.boolean(),
				},
				{
					name: "showAccessButton",
					value:
						props.mode === "add-gate-controller"
							? false
							: props.node?.ShowAccessButton || false,
					validator: Yup.boolean(),
				},
				{
					name: "loopDetection",
					value: props.node ? props.node.LoopDetection : false,
					validator: Yup.boolean(),
				},
				{
					name: "gateOpenSignal",
					value: props.node ? props.node.GateOpenSignal : false,
					validator: Yup.boolean(),
				},

				{
					name: "gateCloseRelay",
					value: props.node ? props.node.GateCloseRelay : false,
					validator: Yup.boolean(),
				},

				{
					name: "forceSessionlessAccess",
					value: props.node ? props.node.ForceSessionlessAccess : false,
					validator: Yup.boolean(),
				},
				{
					name: "restrictedAccess",
					value: props.node ? props.node.RestrictedAccess : false,
					validator: Yup.boolean(),
				},
				{
					name: "stateIntent",
					value: props.node
						? props.node.IsEnabled
							? "Disable"
							: "Enable"
						: null,
					validator: Yup.string().nullable(),
				},
				{
					name: "group",
					value:
						props.group && props.group.id
							? {
									value: props.group.id,
									label: props.group.name,
							  }
							: null,
					validator: Yup.object().nullable(),
				},
				{
					name: "nodeGroupId",
					value: props.nodeGroup ? props.nodeGroup.NodeGroupID : null,
					validator: Yup.number().integer().nullable(),
				},
				{
					name: "groupName",
					value: props.group ? props.group.Name : null,
					validator: Yup.string().nullable(),
				},
				{
					name: "laneName",
					value: props.lane ? props.lane.Name : null,
					validator: Yup.string().nullable(),
				},
				{
					name: "status",
					value: props.node ? props.node.Status : null,
					validator: Yup.string().nullable(),
				},
				{
					name: "sites",
					value:
						props.node && props.node.Sites
							? props.node.Sites.map((site) => ({
									value: site.SiteID,
									label: site.Name,
							  }))
							: [],
					validator: Yup.array(Yup.object()),
				},
				{
					name: "notes",
					value: props.node ? props.node.notes : "",
					validator: Yup.string(),
				},
				{
					name: "nextOrder",
					value:
						props.group && props.group.Lanes && props.group.Lanes.length
							? maxGroupOrder
								? maxGroupOrder.GroupOrder + 1
								: 0
							: 0,
				},
				{
					name: "isReversible",
					value: props.lane ? props.lane.IsReversible : false,
					validator: Yup.boolean(),
				},
				{
					name: "clientId",
					value: props.node ? props.node.ClientID : null,
					validator: Yup.number().integer().nullable(),
				},
				{
					name: "auditLogs",
					value: ((props.lane && props.lane.Logs) || [])
						.filter(
							(log) =>
								log.OriginalData &&
								log.OriginalData.position ===
									(props.node
										? constants.NODE_POSITION_VALUES[props.node.Position]
										: 1)
						)
						.map((log) => ({
							Description: log.OriginalData.description,
							User: log.UserData,
							CreatedOn: format.localDate(log.CreatedOn),
						})),
				},
				{
					name: "gateTxPower",
					value: props.node ? props.node.GateTxPower : null,
					validator: Yup.number().integer().nullable(),
				},
				{
					name: "txPower",
					value: props.node ? props.node.TxPower : null,
					validator: Yup.number().integer().nullable(),
				},
				{
					name: "attenuation",
					value: props.node ? props.node.Attenuation : null,
					validator: Yup.number().integer().nullable(),
				},
				{
					name: "isOnline",
					value: props.node ? props.node.IsOnline : null,
					validator: Yup.boolean().nullable(),
				},
				{
					name: "isPedestal",
					value: props.node ? props.node.IsPedestal : null,
					validator: Yup.boolean().nullable(),
				},
				{
					name: "canUpdateSiteLevelPowerSettings",
					value: false,
					validator: Yup.boolean().nullable(),
				},
				{
					name: "qrCode",
					value: props.node ? props.node.QRCode : null,
					validator: Yup.string()
						.max(100, "The QR code cannot be greater than 100 characters")
						.nullable(),
				},
				{
					name: "createFromExistingInstallation",
					value: false,
					validator: Yup.boolean(),
				},
				{
					name: "parentInstallationId",
					value: null,
					validator: Yup.number().nullable(),
				},
			]}
			onSubmit={async (values) => {
				switch (values.mode) {
					case "create-installation":
						await api.createInstallation(props, values, createInstallation);
						break;
					case "delete-installation":
						await api.deleteInstallation(props, values, deleteInstallation);
						break;
					case "remove-installation-from-site":
						await api.removeInstallationFromSite(
							props,
							values,
							removeInstallationFromSite
						);
						break;
					case "add-gate-controller":
						await api.addGateController(props, values, updateNode);
						break;
					case "edit-gate-controller":
						await api.updateGateController(props, values, updateNode);
						break;
					case "ping-gate":
						await api.pingGate(props, values, pingDevice);
						break;
					case "open-gate":
						await api.openGate(props, values);
						break;
					case "close-gate":
						await api.closeGate(props, values);
						break;
					case "toggle-gate-controller":
						await api.toggleGateControllerState(
							props,
							values,
							props.node.IsEnabled ? disableNode : enableNode
						);
						break;
					case "delete-gate-controller":
						await api.deleteGateController(props, values, deleteNode);
						break;
					case "add-group":
						await api.addGroup(props, values);
						break;
					case "edit-group":
						await api.updateGroup(props, values, updateLaneGroup);
						break;
					case "remove-group":
						await api.removeGroup(props, values, removeGroup);
						break;
					case "add-lane":
						await api.addLane(props, values, createLane);
						break;
					case "edit-lane":
						await api.updateLane(props, values, updateLane);
						break;
					case "not-installing":
						await api.updateLaneStatus(props, values, updateLaneStatus);
						break;
					case "remove-status":
						await api.removeLaneStatus(props, values, updateLaneStatus);
						break;
					case "remove-lane":
						await api.removeLane(props, values, deleteLane);
						break;
					case "admin-edit":
						await api.updateNode(props, values, updateNodeStatus);
						break;
					case "admin-ping-gate":
						await api.adminPingGate(props, values, pingDevice);
						break;
					case "admin-refresh-settings":
						await api.adminRefreshSettings(props, values, refreshSettings);
						break;
					case "edit-power-strength":
						await api.updateNode(props, values, updateNodeStatus);
						break;
				}
			}}
			steps={wizard.steps}
			initialStep={wizard.initialStep}
			wizardProps={props}
		/>
	);
}
