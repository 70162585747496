import React, { useState } from "react";
import WizardLayout from "../WizardLayout";
import { useEventSeriesData } from "./hooks";
import {
	formatOptions,
	constructSiteSpaceRates,
	constructWizard,
	initializeValues,
	handleSubmit,
} from "./EventWizard.helpers";
import { useUpsertEventSeriesAndEvents } from "../../../containers/organization/queries/eventsApiHooks";

export default function EventsWizard(props) {
	const [isLoadingEvents, setIsLoadingEvents] = useState(true);
	const [eventToCancel, setEventToCancel] = useState({});
	const [Events, setEvents] = useState([{}]);

	const { seriesData, isLoading, isError } = useEventSeriesData(
		props.seriesId,
		props.eventIdToCancel,
		setEvents,
		setEventToCancel,
		setIsLoadingEvents
	);

	const Brand =
		formatOptions(
			seriesData?.Brand ? [seriesData.Brand] : [],
			"BrandID",
			"CompanyName"
		)[0] || props.selectedBranding;

	const Sites = formatOptions(
		seriesData?.Sites || props.selectedSites || [],
		"SiteID",
		"Name"
	);

	const Spaces = formatOptions(
		seriesData?.Spaces || props.selectedSpaces || [],
		"LeaseParkID",
		"Name"
	);

	const Groups = formatOptions(
		seriesData?.Groups || props.selectedGroups || [],
		"OrganizationAccessGroupID",
		"Name"
	);

	const Rates = formatOptions(
		seriesData?.EventSeriesRates || props.selectedRates || [],
		"RateID",
		"Name"
	);

	const SiteSpaceRates = constructSiteSpaceRates(
		seriesData,
		Sites,
		Spaces,
		Rates
	);

	if (SiteSpaceRates.length === 0) {
		SiteSpaceRates.push({
			selectedSite: null,
			selectedSpaces: [],
			selectedRates: [],
		});
	}

	const initialData = {
		Events,
		Name: seriesData?.Name ?? props.seriesName,
		ResetTime: seriesData?.ResetTime ?? 120,
		Brand,
		Sites,
		Spaces,
		Groups,
		Rates,
		SiteSpaceRates,
	};

	const wizard = constructWizard(props.mode);
	const upsertEventSeriesAndEvents = useUpsertEventSeriesAndEvents();

	if (isLoading || isLoadingEvents) {
		return <div>Loading...</div>;
	}
	if (isError) {
		return <div>Error...</div>;
	}

	return (
		<WizardLayout
			close={props.close}
			title="Event Management"
			values={initializeValues(initialData, props, eventToCancel)}
			onSubmit={(values, { setSubmitting }) =>
				handleSubmit(values, setSubmitting, upsertEventSeriesAndEvents, props)
			}
			steps={wizard.steps}
			initialStep={wizard.initialStep}
			wizardProps={props}
		/>
	);
}
