import FlexWrapper from "./FlexWrapper";
import PropTypes from "prop-types";
import React from "react";
import Switch from "react-switch";
import { colours } from "../../styles";
import styled from "styled-components";
import Tooltip from "./Tooltip";
import { Info } from "react-feather";

const Wrapper = styled.div`
	.toggle-switch {
		position: absolute !important;
		top: 50%;
		transform: translateY(-50%);
	}

	input {
		display: none;
	}
`;

const Position = styled.div`
	float: ${(props) => (props.floatRight ? "right" : "left")};
	margin-right: ${(props) => (props.floatRight ? "56px" : "0")};
`;

const ToggleLabel = styled.div`
	font-size: ${(props) => props.fontSize || "18px"};
	font-weight: 700;
	flex: 0 auto;
`;

Toggle.propTypes = {
	id: PropTypes.string,
	style: PropTypes.object,
	label: PropTypes.string,
	checked: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	floatRight: PropTypes.bool,
};

Toggle.defaultProps = {
	checked: false,
};

function Toggle({ floatRight, toggleFontSize, ...props }) {
	return (
		<FlexWrapper style={props.style}>
			{props.label && (
				<>
					<ToggleLabel
						fontSize={toggleFontSize}
						style={{
							color: props.disabled ? "gray" : "",
							display: "flex",
							columnGap: 4,
						}}
					>
						{props.info && (
							<Tooltip
								styles={{ width: "16px" }}
								tooltipContent={props.info}
								render={<Info className="tooltip-icon" color={colours.blue} />}
							/>
						)}
						{props.label}
					</ToggleLabel>
				</>
			)}
			{props.labelElement}
			<Wrapper>
				<Position floatRight={floatRight}>
					<Switch
						{...props}
						className="toggle-switch"
						checked={!!props.checked}
						onChange={props.onChange}
						onColor={colours.green}
						offColor={colours.borderGrey}
						checkedIcon={false}
						uncheckedIcon={false}
					/>
				</Position>
			</Wrapper>
		</FlexWrapper>
	);
}

export default Toggle;
