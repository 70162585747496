/* eslint-disable react/jsx-key */

import React, { useMemo } from "react";
import { useSortBy, useTable } from "react-table";
import Highlighter from "react-highlight-words";
import { colours } from "../../styles";
import styled from "styled-components";
import { useSearchFilter } from "../../hooks";

const Wrapper = styled.div`
	table {
		border: 0;
		border-spacing: 0;
		width: 100%;

		th,
		td {
			border: 0;
			padding: 16px 8px;
			text-align: left;
			user-select: none;
		}

		th {
			padding-right: 16px;
		}

		td {
			overflow: hidden;
			text-overflow: ellipsis;
		}

		thead {
			font-size: 16px;

			th {
				border-bottom: 2px solid ${colours.borderGrey};
			}
		}

		tbody {
			tr:nth-child(odd) {
				background-color: ${colours.offWhite};
			}

			tr:hover {
				background: ${colours.lightGrey};
			}

			td {
				font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica,
					Arial, sans-serif;
				font-size: 14px;
			}
		}
	}

	.bold {
		font-weight: 700;
	}

	.boolean {
		position: relative;

		.cross {
			height: 12px;
			left: 8px;
			position: absolute;
			top: -6px;
			width: 12px;

			&::before,
			&::after {
				background-color: ${colours.darkGrey};
				content: "";
				height: 12px;
				left: 6px;
				position: absolute;
				width: 2px;
			}

			&::before {
				transform: rotate(45deg);
			}

			&::after {
				transform: rotate(-45deg);
			}

			&.colour {
				&::before,
				&::after {
					background-color: ${colours.red};
				}
			}
		}

		.tick {
			height: 16px;
			left: 8px;
			position: absolute;
			top: -6px;
			width: 16px;

			&::before {
				background-color: ${colours.darkGrey};
				content: "";
				height: 2px;
				position: absolute;
				width: 6px;
				top: 7px;
				transform: rotate(45deg);
			}

			&::after {
				background-color: ${colours.darkGrey};
				content: "";
				height: 12px;
				left: 7px;
				position: absolute;
				transform: rotate(45deg);
				width: 2px;
			}

			&.colour {
				&::before,
				&::after {
					background-color: ${colours.green};
				}
			}
		}
	}
`;

const Resizer = styled.div`
	position: absolute;
	right: -16px;
	top: -12px;

	&::before {
		border-bottom: ${(props) =>
			props.isSortedDesc ? 0 : `4px solid ${colours.green}`};
		border-top: ${(props) =>
			props.isSortedDesc ? `4px solid ${colours.green}` : 0};
		content: "";
		border-left: 4px solid transparent;
		border-right: 4px solid transparent;
		height: 0;
		position: absolute;
		right: 4px;
		top: 50%;
		transform: translateY(-50%);
		width: 0;
	}
`;

const Results = styled.div`
	font-size: 16px;
	font-weight: 600;
	padding: 16px 0;
	text-align: center;
`;

const NoData = styled.div`
	font-size: 20px;
	font-weight: 600;
	margin: 64px 0;
	text-align: center;
`;

export function booleanValueFormatter(value, onlyShowTick = false) {
	return (
		<div className="boolean">
			<div className={value ? "tick" : onlyShowTick ? "" : "cross"} />
		</div>
	);
}

export function booleanValueFormatterColour(value) {
	return (
		<div className="boolean">
			<div className={value ? "tick colour" : "cross colour"} />
		</div>
	);
}

export function highlightedCell(value, search) {
	if (React.isValidElement(value) && value.props.children) {
		return React.Children.map(value.props.children, (child) =>
			React.cloneElement(child, {
				children: (
					<Highlighter
						searchWords={[search]}
						autoEscape={true}
						textToHighlight={String(child.props.children || "")}
					/>
				),
			})
		);
	}

	return (
		<Highlighter
			searchWords={[search]}
			autoEscape={true}
			textToHighlight={String(value) || ""}
		/>
	);
}

function TableWrapper({
	columns,
	data,
	tableProps: { defaultSortBy, sortable = true },
}) {
	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable(
			{
				columns,
				data,
				initialState: {
					sortBy: defaultSortBy,
				},
				disableSortBy: !sortable,
				disableMultiSort: true,
				disableSortRemove: true,
			},
			useSortBy
		);

	return (
		<Wrapper>
			<table {...getTableProps()}>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th
									{...column.getHeaderProps(column.getSortByToggleProps())}
									title=""
									style={{
										color: column.isSorted ? colours.green : colours.darkGrey,
										width: column.fixedWidth || "auto",
										cursor: column.canSort ? "pointer" : "default",
									}}
								>
									{column.render("Header")}
									{column.isSorted ? (
										<div style={{ position: "relative" }}>
											<Resizer isSortedDesc={column.isSortedDesc}></Resizer>
										</div>
									) : null}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{rows.map((row) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map((cell) => {
									return (
										<td {...cell.getCellProps()}>{cell.render("Cell")}</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
		</Wrapper>
	);
}

export default function TableLayoutBeta(props) {
	const filteredData = useSearchFilter(
		props.data || [],
		props.searchFilter,
		props.filterOptions || []
	);

	const columns = useMemo(() => props.columns || []);
	const data = useMemo(() => filteredData);

	return (
		<div>
			{data.length > 0 && (
				<TableWrapper columns={columns} data={data} tableProps={props} />
			)}
			{props.showResultsLength && data.length > 0 && (
				<Results>
					{`Showing ${data.length} result${data.length > 1 ? "s" : ""}`}
				</Results>
			)}
			{data.length === 0 && <NoData>No data found</NoData>}
		</div>
	);
}
