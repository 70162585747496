import { MoreHorizontal, Plus } from "react-feather";
import React, { useState } from "react";
import Button from "../../components/layout/Button";
import DropdownMenuBeta from "../../components/layout/DropdownMenuBeta";
import PlateWizard from "../../components/wizards/plate-wizard/PlateWizard";
import ReportWrapperBeta from "../../components/report/ReportWrapperBeta";
import gql from "graphql-tag";
import { isAnonymous } from "../../helpers/user";
import { localDate } from "../../helpers/format";
import { useQueryData } from "../../hooks";

const columns = (props) => [
	{ Header: "Plate", accessor: (d) => d.Numberplate },
	{
		Header: "First name",
		accessor: (d) => !isAnonymous(d.User.Email) && d.User.FirstName,
	},
	{
		Header: "Last name",
		accessor: (d) => !isAnonymous(d.User.Email) && d.User.LastName,
	},
	{
		Header: "Email",
		accessor: (d) => !isAnonymous(d.User.Email) && d.User.Email,
	},
	{
		Header: "Type",
		accessor: "Type",
		Cell: ({ row: { original } }) =>
			original.Type === "Lease"
				? "Booking"
				: original.Type === "Group"
				? "Group"
				: original.Type === "Site"
				? "Manual Entry"
				: "",
	},
	{
		Header: "Expiry",
		accessor: "ExpiresAt",
		Cell: ({ row: { original } }) => localDate(original.ExpiresAt, "X"),
	},
	{ Header: "Description", accessor: "Description" },
	{
		id: "tasks",
		Header: "",
		accessor: null,
		Cell: ({ row: { original } }) =>
			original.Type === "Site" && (
				<DropdownMenuBeta
					triggerContent={<MoreHorizontal size={24} />}
					items={[
						<div
							key="edit"
							onClick={() => props.openPlateWizard(original, "edit")}
						>
							Edit
						</div>,
						<div
							key="delete"
							onClick={() => props.openPlateWizard(original, "delete")}
						>
							Remove
						</div>,
					]}
				/>
			),
		fixedWidth: 50,
	},
];

export default function PlatesContainer(props) {
	const siteId = props.selectedSite.SiteID;
	const { data, isLoading, refetch } = useQueryData(
		gql`
			query {
				getNumberplatesOnSite(siteId: ${siteId}) {
					NumberplateID
					Numberplate
					Description
					ExpiresAt
					Type
					User {
						UserID
						FirstName
						LastName
						Email
					}
				}
			}
		`,
		{},
		false,
		{ fetchPolicy: "no-cache" }
	);
	const [state, setState] = useState({
		plateWizardOpen: false,
	});

	function openPlateWizard(details, mode) {
		setState((s) => ({
			...s,
			plateWizardOpen: true,
			plateWizardDetails: details,
			plateWizardMode: mode,
		}));
	}

	if (state.plateWizardOpen) {
		return (
			<PlateWizard
				close={() => {
					setState((s) => ({ ...s, plateWizardOpen: false }));
				}}
				mode={state.plateWizardMode}
				refetch={refetch}
				plateDetails={state.plateWizardDetails}
				siteId={siteId}
			/>
		);
	}

	return (
		<div>
			<ReportWrapperBeta
				{...props}
				title=""
				data={data.getNumberplatesOnSite}
				columns={columns({ openPlateWizard })}
				filterOptions={[
					"Type",
					"Numberplate",
					"User.FirstName",
					"User.LastName",
					"User.Email",
					"Description",
				]}
				defaultSortBy={[{ id: "Plate" }]}
				loading={isLoading}
				showDateRangePicker={false}
				showSitePicker={false}
				updateOptions={() => {}}
				rightActions={
					<Button color="blue" onClick={() => openPlateWizard(null, "add")}>
						<Plus size={20} /> Add Plate
					</Button>
				}
			/>
		</div>
	);
}
