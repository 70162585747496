import { Redirect, Route, Switch } from "react-router-dom";
import BookingRatesContainer from "../../containers/organization/BookingRatesContainer";
import ErrorBoundary from "../ErrorBoundary";
import ProductsContainer from "../../containers/organization/ProductsContainer";
import React from "react";
import TransientRatesContainer from "../../containers/organization/TransientRatesContainer.js";
import { usePermissions, useHasClientAdmin } from "../../hooks";

/**
 * Wrapper which serves the tabs for the Rates pages.
 *
 * Defaults to 'transient' tab.
 */
export default function RatesContainer(props) {
	const isAdmin = usePermissions("IsAdmin");
	const hasClientAdmin = useHasClientAdmin(
		props.selectedOrganization?.ClientID
	);

	return (
		<ErrorBoundary>
			<Switch>
				<Route
					path={`${props.match.url}/transient`}
					render={(_props) => (
						<TransientRatesContainer {...props} {..._props} />
					)}
				/>
				<Route
					path={`${props.match.url}/monthly`}
					render={(_props) => <BookingRatesContainer {...props} {..._props} />}
				/>
				{isAdmin || hasClientAdmin ? (
					<Route
						path={`${props.match.url}/products`}
						render={(_props) => <ProductsContainer {...props} {..._props} />}
					/>
				) : null}
				<Redirect to={`${props.match.url}/transient`} />
			</Switch>
		</ErrorBoundary>
	);
}
