import { StepText, StepTitle } from "../WizardLayout";
import Button from "../../layout/Button";
import Dropdown from "../../layout/Dropdown";
import EmailLookup from "../EmailLookup";
import MultiChoiceButton from "../MultiChoiceButton";
import PermissionToggle from "./PermissionToggle";
import React from "react";
import SummaryTable from "../SummaryTable";
import WizardNavigation from "../WizardNavigation";
import { find } from "lodash";
import { user } from "../../../helpers";

const tooltips = {
	NotifyYes:
		"By default a user will not be notified by email. Selecting 'Yes' will send an email to the user.",
};

const getAdminPermissions = (values) => {
	let permissions = [];

	if (values.superAdmin) permissions.push("Super Admin");
	if (values.csAdmin) permissions.push("Admin");
	if (values.adminDashboardEnabled) permissions.push("Admin Dashboard");
	if (values.featuresPreview) permissions.push("Features Preview");
	if (values.debugEnabled) permissions.push("Debug Enabled");

	if (permissions.length === 0) permissions.push("None");

	return permissions.join(", ");
};

export const removeStep = ({
	handleSubmit,
	isSubmitting,
	values,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "remove",
	label: "Remove",
	render: () => (
		<div>
			<StepText>
				This will remove any admin permissions from{" "}
				{user.fullNameWithEmail(values.user)}.
			</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					Remove Permissions
				</Button>,
			]}
		/>
	),
});

export const emailStep = ({
	next,
	setFieldValue,
	values,
	keyStrokeHandler,
}) => ({
	id: "email",
	label: "Email",
	render: () => (
		<div>
			<StepTitle>What is the email address of the user?</StepTitle>
			<EmailLookup
				email={values.email}
				user={values.user}
				onChange={(value) => {
					if (value.user && value.user.FirstName && value.user.LastName) {
						value.user.FirstName = value.user.FirstName.trim();
						value.user.LastName = value.user.LastName.trim();
					}

					setFieldValue("email", value.email);
					setFieldValue("user", value.user);

					if (value.user) {
						setFieldValue(
							"superAdmin",
							Boolean(value.user.IsAdmin && !value.user.IsCSAdmin)
						);
						setFieldValue(
							"csAdmin",
							Boolean(value.user.IsAdmin && value.user.IsCSAdmin)
						);
						setFieldValue(
							"adminDashboardEnabled",
							Boolean(value.user.AdminDashboardEnabled)
						);
						setFieldValue(
							"featuresPreview",
							Boolean(value.user.FeaturesPreview)
						);
						setFieldValue("debugEnabled", Boolean(value.user.DebugEnabled));
						setFieldValue("notifyUserByEmail", true);
					}
				}}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			rightItems={[
				<Button
					key="next"
					onClick={next}
					color="blue"
					disabled={!values.user}
					keyStrokeHandler={keyStrokeHandler}
				>
					Next
				</Button>,
			]}
		/>
	),
});

export const permissionsStep = ({
	goTo,
	setFieldValue,
	previous,
	values,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "permissions",
	label: "Permissions",
	render: () => (
		<div>
			<StepTitle>What admin type(s) will this user require?</StepTitle>

			<PermissionToggle
				key="Super Admin"
				title="Super Admin"
				value={values.superAdmin}
				disabled={values.csAdmin}
				onChange={(value) => {
					setFieldValue("superAdmin", value);
				}}
			/>
			<PermissionToggle
				key="Admin"
				title="Admin"
				value={values.csAdmin}
				disabled={values.superAdmin}
				onChange={(value) => {
					setFieldValue("csAdmin", value);
				}}
			/>
			<PermissionToggle
				key="Admin Dashboard"
				title="Admin Dashboard"
				value={values.adminDashboardEnabled}
				onChange={(value) => {
					setFieldValue("adminDashboardEnabled", value);
				}}
			/>
			{/* <PermissionToggle
				key="Features Preview"
				title="Features Preview"
				value={values.featuresPreview}
				onChange={(value) => {
					setFieldValue("featuresPreview", value);
				}}
			/> */}
			<PermissionToggle
				key="Debug Enabled"
				title="Debug Enabled"
				value={values.debugEnabled}
				onChange={(value) => {
					setFieldValue("debugEnabled", value);
				}}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary &&
				!wizardProps.user && [
					<Button key="back" onClick={previous} color="blue">
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					onClick={() => {
						goTo("summary");
					}}
					color="blue"
					disabled={!values.user}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const notifyStep = ({
	goTo,
	previous,
	setFieldValue,
	values,
	keyStrokeHandler,
}) => ({
	id: "notify",
	label: "Notify",
	render: () => (
		<div>
			<StepTitle>Would you like the user to be notified by email?</StepTitle>
			<MultiChoiceButton
				selected={!values.notifyUserByEmail}
				onClick={() => {
					setFieldValue("notifyUserByEmail", false);
				}}
			>
				No
			</MultiChoiceButton>
			<MultiChoiceButton
				selected={values.notifyUserByEmail}
				onClick={() => {
					setFieldValue("notifyUserByEmail", true);
				}}
			>
				Yes
			</MultiChoiceButton>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="back" onClick={previous} color="blue">
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					onClick={() => goTo("summary")}
					color="blue"
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const summaryStep = ({
	goTo,
	handleSubmit,
	isSubmitting,
	setFieldValue,
	values,
	keyStrokeHandler,
}) => ({
	id: "summary",
	label: "Summary",
	render: () => (
		<div>
			<StepTitle>Summary</StepTitle>
			<SummaryTable
				items={[
					{
						title: "Permissions",
						value: getAdminPermissions(values),
						key: "permissions",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("permissions");
						},
					},
					{
						title: "Email",
						value: values.user.Email,
						key: "email",
					},
					{
						title: "Name",
						value: user.fullName(values.user),
						key: "name",
					},
				]}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					Set Permissions
				</Button>,
			]}
		/>
	),
});

export const clientEmailStep = ({
	next,
	setFieldValue,
	values,
	keyStrokeHandler,
}) => ({
	id: "email",
	label: "Email",
	render: () => (
		<div>
			<StepTitle>What is the email address of the user?</StepTitle>
			<EmailLookup
				email={values.email}
				user={values.user}
				onChange={(value) => {
					if (value.user && value.user.FirstName && value.user.LastName) {
						value.user.FirstName = value.user.FirstName.trim();
						value.user.LastName = value.user.LastName.trim();
					}

					setFieldValue("email", value.email);
					setFieldValue("user", value.user);

					if (value.user) {
						setFieldValue("notifyUserByEmail", true);
					}
				}}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			rightItems={[
				<Button
					key="next"
					onClick={next}
					color="blue"
					disabled={!values.user}
					keyStrokeHandler={keyStrokeHandler}
				>
					Next
				</Button>,
			]}
		/>
	),
});

export const clientPermissionStep = ({
	goTo,
	next,
	setFieldValue,
	previous,
	values,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "client",
	label: "Client",
	render: () => (
		<div>
			<StepTitle>Which client does this user belong to?</StepTitle>
			<Dropdown
				options={wizardProps.clients}
				value={find(wizardProps.clients, (e) => e.value === values.clientId)}
				onChange={(value) => setFieldValue("clientId", value.value)}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary &&
				!wizardProps.user && [
					<Button key="back" onClick={previous} color="blue">
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					color="blue"
					disabled={!values.user || !values.clientId}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const clientSummaryStep = ({
	goTo,
	handleSubmit,
	isSubmitting,
	setFieldValue,
	values,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "summary",
	label: "Summary",
	render: () => (
		<div>
			<StepTitle>Summary</StepTitle>
			<SummaryTable
				items={[
					wizardProps.clients && {
						title: "Client",
						value: find(wizardProps.clients, (e) => e.value === values.clientId)
							.label,
						key: "client",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("client");
						},
					},
					{
						title: "Email",
						value: values.user.Email,
						key: "email",
					},
					{
						title: "Name",
						value: user.fullName(values.user),
						key: "name",
					},
					{
						tooltip: tooltips.NotifyYes,
						title: "Notify",
						value: values.notifyUserByEmail ? "Yes" : "No",
						key: "notify",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("notify");
						},
					},
				].filter((e) => e)}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					Set Permissions
				</Button>,
			]}
		/>
	),
});
