import {
	AddBaysWizard,
	AssignBayWizard,
	DeleteBayWizard,
	EditBayWizard,
	UnassignBayWizard,
} from "../../components/wizards/bay-wizard/BayWizard";
import { MoreHorizontal, Plus } from "react-feather";
import React, { useState } from "react";
import { fetchDataCallback, paginatedState } from "../../helpers/pagination";
import Button from "../../components/layout/Button";
import DropdownMenuBeta from "../../components/layout/DropdownMenuBeta";
import ReportWrapperBeta from "../../components/report/ReportWrapperBeta";
import gql from "graphql-tag";
import { useQueryData } from "../../hooks";

const columns = (props) => [
	{
		id: "Name",
		Header: "Bay Number",
		accessor: (d) => d.Name || "",
		highlightCell: (cellProps) => cellProps.row.original.Name,
	},
	{
		id: "ParkName",
		Header: "Space",
		accessor: (d) => d.ParkName || "",
		highlightCell: (cellProps) => cellProps.row.original.ParkName || "",
	},
	{
		id: "SiteName",
		Header: "Site",
		accessor: (d) => d.SiteName || "",
		highlightCell: (cellProps) => cellProps.row.original.SiteName || "",
	},
	{
		id: "Assignee",
		Header: "Assignee",
		accessor: (d) => d.User?.Email || "",
		highlightCell: (cellProps) => cellProps.row.original.User?.Email || "",
	},
	{
		id: "tasks",
		Header: "",
		accessor: null,
		Cell: ({ row: { original } }) => (
			<DropdownMenuBeta
				triggerContent={<MoreHorizontal size={24} />}
				items={[
					<div key="edit" onClick={() => props.openEditBayWizard(original)}>
						Edit
					</div>,
					original.User ? (
						<div
							key="unassign"
							onClick={() => props.openUnassignBayWizard(original)}
						>
							Unassign
						</div>
					) : (
						<div
							key="assign"
							onClick={() => props.openAssignBayWizard(original)}
						>
							Assign
						</div>
					),
					<div key="remove" onClick={() => props.openDeleteBayWizard(original)}>
						Remove
					</div>,
				]}
			/>
		),
		resizable: false,
		// fixedWidth: 24,
		sortable: false,
	},
];

function BaysContainer(props) {
	const [state, setState] = useState({
		initialLoad: true,
		options: { sites: [], spaces: [] },
		addBaysWizardOpen: false,
		deleteBayWizardOpen: false,
		editBayWizardOpen: false,
		assignBayWizardOpen: false,
		unassignBayWizardOpen: false,
		wizardMode: "update",
		selectedBay: null,
	});

	let bays = [];
	let availableSpaces = [];

	const organizationId = props.selectedOrganization.OrganizationID;
	const [paginate, setPaginate] = useState(paginatedState);
	const fetchPaginatedData = fetchDataCallback(setPaginate);

	async function updateOptions({ search, ...options }) {
		setState((_state) => ({ ..._state, initialLoad: false, search, options }));
	}

	const {
		data: { getLeaseParksForOrganization: allLeaseParks },
		isLoading: parksIsLoading,
		refetch: refetchParks,
	} = useQueryData(
		gql`
			query ($organizationId: Int!) {
				getLeaseParksForOrganization(organizationId: $organizationId) {
					LeaseParkID
					Name
					Spaces
					AvailableSpaces
					Sites {
						SiteID
						Name
					}
				}
			}
		`,
		{
			organizationId,
		}
	);

	if (allLeaseParks) {
		availableSpaces = allLeaseParks.filter((space) =>
			state.options.sites.some((siteId) => siteId === space.Sites[0].SiteID)
		);
	}

	const skipQuery =
		!paginate.pageSize || !paginate.sortBy || !paginate.sortOrder;

	const {
		data: { getBaysForSpaces },
		isLoading: baysIsLoading,
		refetch: refetchBays,
	} = useQueryData(
		gql`
			query ($leaseParkIds: [Int]!, $page: PageInfo!, $cursor: Int) {
				getBaysForSpaces(
					leaseParkIds: $leaseParkIds
					page: $page
					cursor: $cursor
				) {
					trueLength
					bays {
						BayID
						LeaseParkID
						Name
						ParkName
						SiteName
						User {
							UserID
							Email
							FirstName
							LastName
						}
						RankingScore
					}
					previousEvent
				}
			}
		`,
		{
			leaseParkIds: state.options.spaces,
			page: {
				next: paginate.next,
				pageOffset: paginate.pageOffset,
				pageSize: paginate.pageSize,
				sortBy: paginate.sortBy,
				sortOrder: paginate.sortOrder,
				searchTokens: paginate.searchTokens,
				eventType: paginate.eventType,
				sortedVal: paginate.cursor.sortedVal,
			},
			cursor: paginate.cursor.cursor,
		},
		skipQuery
	);

	if (getBaysForSpaces && getBaysForSpaces?.bays.length) {
		bays = getBaysForSpaces.bays;
	}

	function openAddBaysWizard(mode) {
		setState((_state) => ({
			..._state,
			addBaysWizardOpen: true,
			wizardMode: mode,
		}));
	}

	function openDeleteBayWizard(bay) {
		setState((_state) => ({
			..._state,
			deleteBayWizardOpen: true,
			selectedBay: bay,
		}));
	}

	function openEditBayWizard(bay) {
		setState((_state) => ({
			..._state,
			editBayWizardOpen: true,
			selectedBay: bay,
		}));
	}

	function openAssignBayWizard(bay) {
		setState((_state) => ({
			..._state,
			assignBayWizardOpen: true,
			selectedBay: bay,
		}));
	}

	function openUnassignBayWizard(bay) {
		setState((_state) => ({
			..._state,
			unassignBayWizardOpen: true,
			selectedBay: bay,
		}));
	}

	if (state.addBaysWizardOpen) {
		return (
			<AddBaysWizard
				close={() =>
					setState((_state) => ({
						..._state,
						addBaysWizardOpen: false,
					}))
				}
				mode={state.wizardMode}
				leaseParks={allLeaseParks}
				refetchBays={refetchBays}
				refetchParks={refetchParks}
			/>
		);
	}

	if (state.deleteBayWizardOpen) {
		return (
			<DeleteBayWizard
				close={() =>
					setState((_state) => ({
						..._state,
						deleteBayWizardOpen: false,
					}))
				}
				bayId={state.selectedBay.BayID}
				refetchBays={refetchBays}
				refetchParks={refetchParks}
			/>
		);
	}

	if (state.editBayWizardOpen) {
		return (
			<EditBayWizard
				close={() =>
					setState((_state) => ({
						..._state,
						editBayWizardOpen: false,
					}))
				}
				bay={state.selectedBay}
				refetch={refetchBays}
			/>
		);
	}

	if (state.assignBayWizardOpen) {
		return (
			<AssignBayWizard
				close={() =>
					setState((_state) => ({
						..._state,
						assignBayWizardOpen: false,
					}))
				}
				bay={state.selectedBay}
				refetch={refetchBays}
			/>
		);
	}

	if (state.unassignBayWizardOpen) {
		return (
			<UnassignBayWizard
				close={() =>
					setState((_state) => ({
						..._state,
						unassignBayWizardOpen: false,
					}))
				}
				bay={state.selectedBay}
				refetch={refetchBays}
			/>
		);
	}

	const trueLength = getBaysForSpaces?.trueLength || 0;

	return (
		<ReportWrapperBeta
			{...props}
			title="Bays"
			data={bays}
			availableSites={props.availableSites}
			availableSpaces={availableSpaces}
			showSpacePicker={true}
			columns={columns({
				openDeleteBayWizard,
				openEditBayWizard,
				openAssignBayWizard,
				openUnassignBayWizard,
			})}
			filterOptions={[
				"Name",
				"SpaceType",
				"Spaces",
				"spacesInUse",
				"siteNames",
				"billing",
			]}
			defaultSortBy={[
				{
					id: "Name",
					desc: false,
				},
			]}
			updateOptions={updateOptions}
			loading={false}
			showDateRangePicker={false}
			rightActions={
				props.isAdmin || props.canEditSettings ? (
					<Button color="blue" onClick={() => openAddBaysWizard("add")}>
						<Plus size={20} /> Create Bays
					</Button>
				) : null
			}
			dataTrueLength={trueLength}
			cursorColumn="BayID"
			paginationIsLoading={
				baysIsLoading || skipQuery || parksIsLoading || props.isLoading
			}
			pageSize={state.pageSize}
			previousEvent={getBaysForSpaces?.previousEvent}
			searchTokens={paginate.searchTokens}
			fetchPaginatedData={fetchPaginatedData}
		/>
	);
}

export default BaysContainer;
