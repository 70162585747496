import React from "react";
import footer from "../helpers/footer";
import rules from "./rules";

export default ({
	values,
	setFieldValue,
	goTo,
	wizardProps,
	previous,
	keyStrokeHandler,
}) => {
	const steps = rules({
		values,
		setFieldValue,
		goTo,
		wizardProps,
		previous,
		keyStrokeHandler,
	})
		.execute()
		//return the steps
		.map((rule) => rule.step);
	const firstStep = steps[0];
	const currentStepId = values.configuration || firstStep.id;
	const currentStep = steps.find((step) => step.id === currentStepId);
	const first = firstStep === currentStep;
	const last = steps[steps.length - 1] === currentStep;
	const currentStepIndex = steps.indexOf(currentStep);
	const previousStep = steps[currentStepIndex - 1];
	const nextStep = steps[currentStepIndex + 1];
	const isValid =
		typeof currentStep.isValid === "function"
			? currentStep.isValid()
			: currentStep.isValid;
	return {
		id: "configuration",
		label: "Configuration",
		render: () => <div>{currentStep && currentStep.render()}</div>,
		footer: () =>
			footer(
				values,
				first
					? previous //go back within the wizard
					: () => {
							//go to previous step inside the configuration
							setFieldValue("configuration", previousStep.id);
					  },
				goTo,
				last
					? "summary" //continue on with the wizard
					: () => {
							//go to next step inside the configuration
							setFieldValue("configuration", nextStep.id);
					  },
				wizardProps,
				isValid,
				false,
				setFieldValue,
				currentStepId === "schedule" ||
					currentStepId === "oneOff" ||
					currentStepId === "advanced",
				keyStrokeHandler
			),
	};
};
