import React, { createContext, useState } from "react";

const MenuContext = createContext({});

function MenuContextProvider(props) {
	let [state, setState] = useState({
		expandedItem: null,
		menuCollapsed: false,
	});

	function collapse() {
		setState((_state) => ({
			..._state,
			expandedItem: null,
			menuCollapsed: !state.menuCollapsed,
		}));
	}

	function expandItem(item) {
		setState((_state) => ({
			..._state,
			expandedItem:
				state.expandedItem === item && !state.menuCollapsed ? null : item,
			menuCollapsed: false,
		}));
	}

	return (
		<MenuContext.Provider
			value={{
				state,
				dispatch: {
					collapse,
					expandItem,
				},
			}}
		>
			{props.children}
		</MenuContext.Provider>
	);
}

export { MenuContext, MenuContextProvider };
