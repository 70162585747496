import React from "react";
import { FieldContent } from "../../../styles";
import Dropdown from "../../../../../layout/Dropdown";
import FieldError from "../../../../../layout/FieldError";
import { useGetSitesByIds } from "../../../../../../containers/organization/queries/eventsApiHooks";

export default function RatesSelector({
	selectedSite,
	selectedRates,
	onChange,
	error,
}) {
	const { sites, isLoading } = useGetSitesByIds([selectedSite]);

	const reduceRates = (listOfSites) => {
		if (!listOfSites) {
			return [];
		}

		const allRates = listOfSites.flatMap((site) => {
			if (!site || !site.EventSeriesRates) {
				return [];
			}
			return site.EventSeriesRates.filter(
				(rate) => rate.RateClass === "EventSeries" && rate.RootRateID === null
			);
		});

		const uniqueRates = Object.values(
			allRates.reduce((acc, rate) => {
				if (!acc[rate.RateID]) {
					acc[rate.RateID] = rate;
				}
				return acc;
			}, {})
		);

		return uniqueRates.map(({ RateID, Name, ...rest }) => ({
			...rest,
			value: RateID,
			label: Name,
		}));
	};

	if (isLoading) {
		return <div>Loading Rates...</div>;
	}

	return (
		<div>
			<FieldContent>
				<Dropdown
					isMulti={true}
					options={reduceRates(sites)}
					value={selectedRates}
					onChange={onChange}
					placeholder="Select Rate"
				/>
			</FieldContent>
			<FieldError showError={error} errorText={error} />
		</div>
	);
}
