import * as Yup from "yup";
import * as steps from "./branding-steps";
import { useMutateData, useQueryData } from "../../../hooks";
import Alert from "react-s-alert";
import React from "react";
import WizardLayout from "../WizardLayout";
import gql from "graphql-tag";

const constructWizard = (props) => {
	switch (props.mode) {
		case "add":
			return {
				initialStep: 0,
				steps: [
					steps.companyNameStep,
					steps.logoStep,
					steps.iconLogoStep,
					steps.colorStep,
					steps.summary,
				],
			};
		case "edit":
			return {
				initialStep: 4,
				steps: [
					steps.companyNameStep,
					steps.logoStep,
					steps.iconLogoStep,
					steps.colorStep,
					steps.summary,
				],
			};
		case "delete":
			return {
				initialStep: 0,
				steps: [steps.deleteStep],
			};
	}
};

export default function BrandingWizard(props) {
	const wizard = constructWizard(props);

	const addBrand = useMutateData(gql`
		mutation (
			$organizationId: Int!
			$companyName: String!
			$logo: Upload!
			$iconLogo: Upload
			$color: String!
			$secondaryColor: String
		) {
			createBrand(
				organizationId: $organizationId
				companyName: $companyName
				logo: $logo
				iconLogo: $iconLogo
				color: $color
				secondaryColor: $secondaryColor
			)
		}
	`);

	const editBrand = useMutateData(gql`
		mutation (
			$brandId: Int!
			$companyName: String!
			$logo: Upload
			$iconLogo: Upload
			$color: String!
			$secondaryColor: String
		) {
			updateBrand(
				brandId: $brandId
				companyName: $companyName
				logo: $logo
				iconLogo: $iconLogo
				color: $color
				secondaryColor: $secondaryColor
			)
		}
	`);

	const deleteBrand = useMutateData(gql`
		mutation ($brandId: Int!) {
			deleteBrand(brandId: $brandId) {
				BrandUsage {
					Sites {
						Name
					}
				}
			}
		}
	`);

	//TODO: tell the user about the brand usage
	const {
		data: { getBrandUsage },
	} = useQueryData(
		gql`
			query ($brandId: Int!) {
				getBrandUsage(brandId: $brandId) {
					Sites {
						Name
					}
				}
			}
		`,
		{ brandId: props.brandingDetails?.BrandID ?? 0 }
	);

	return (
		<WizardLayout
			close={props.close}
			title={(_props) => {
				if (_props.mode === "add") return "Add Brand";
				if (_props.mode === "edit") return "Edit Brand";
				if (_props.mode === "delete") return "Delete Brand";
			}}
			values={[
				{ name: "mode", value: props.mode, validator: Yup.string() },
				{
					name: "OrganizationID",
					value: props.organizationId,
					validator: Yup.number().integer(),
				},
				{
					name: "BrandID",
					value: props.brandingDetails?.BrandID,
					validator: Yup.number().integer(),
				},
				{
					name: "PrimaryColour",
					value: props.brandingDetails?.MainColor || "#3cb98a",
					validator: Yup.string(),
				},
				{
					name: "SecondaryColor",
					value: props.brandingDetails?.SecondaryColor || "#414e57",
					validator: Yup.string().optional(),
				},
				{
					name: "CompanyName",
					value: props.brandingDetails?.CompanyName,
					validator: Yup.string(),
				},
				{
					name: "brandUsage",
					value: getBrandUsage,
					validator: Yup.array(),
				},
			]}
			onSubmit={async (values, { setSubmitting }) => {
				setSubmitting(true);

				try {
					let successMessage = "";
					let errorMessage = "";
					if (values.mode === "add") {
						await addBrand({
							variables: {
								organizationId: values.OrganizationID,
								companyName: values.CompanyName,
								logo: values.logoFile,
								iconLogo: values.iconLogoFile,
								color: values.PrimaryColour,
								secondaryColor: values.SecondaryColor,
							},
						});

						successMessage = "Brand added";
					} else if (values.mode === "edit") {
						await editBrand({
							variables: {
								brandId: values.BrandID,
								companyName: values.CompanyName,
								logo: values.logoFile,
								iconLogo: values.iconLogoFile,
								color: values.PrimaryColour,
								secondaryColor: values.SecondaryColor,
							},
						});

						successMessage = "Brand updated";
					} else if (values.mode === "delete") {
						const result = await deleteBrand({
							variables: {
								brandId: values.BrandID,
							},
						});

						const sites = result.data?.deleteBrand?.BrandUsage?.Sites;

						if (sites?.length) {
							errorMessage = `This brand cannot be deleted as it's being used by: <br /><ul>${sites
								.map((s) => `<li>${s.Name}</li>`)
								.join("")}</ul>`;
						} else {
							successMessage = "Brand deleted";
						}
					}

					props.refetch();
					if (successMessage) {
						Alert.success(successMessage);
					} else if (errorMessage) {
						Alert.error(errorMessage, { html: true });
					}
					props.close(true);
				} catch (error) {
					Alert.error("Something went wrong. Please try again.");
				} finally {
					setSubmitting(false);
				}
			}}
			steps={wizard.steps}
			initialStep={wizard.initialStep}
			wizardProps={props}
			hideStepIndicator={false}
		/>
	);
}
