import Button from "../../layout/Button";
import Dropdown from "../../layout/Dropdown";
import EditableInputField from "../../layout/EditableInputField";
import ImportImageBox from "../../../components/wizards/ImageDropBox";
import InfoBox from "../../layout/InfoBox";
import React from "react";
import { StepTitle } from "../WizardLayout";
import WizardNavigation from "../WizardNavigation";

export function createStep({
	close,
	handleSubmit,
	isSubmitting,
	keyStrokeHandler,
	setFieldValue,
	values,
}) {
	return {
		id: "create",
		label: "Create Level",
		render: () => {
			return (
				<div>
					<StepTitle>What is the name of this level?</StepTitle>
					<EditableInputField
						type="text"
						name="name"
						useLabel="Name"
						onChange={(event) => setFieldValue("levelName", event.target.value)}
						value={values.levelName || ""}
					/>
				</div>
			);
		},
		footer: () => {
			return (
				<WizardNavigation
					leftItems={[
						<Button key="previous" onClick={close} color="blue">
							Cancel
						</Button>,
					]}
					rightItems={[
						<Button
							key="submit"
							color="green"
							onClick={handleSubmit}
							disabled={isSubmitting || !values.levelName}
							keyStrokeHandler={keyStrokeHandler}
						>
							Save Level
						</Button>,
					]}
				/>
			);
		},
	};
}

export function deleteLevelStep({
	close,
	handleSubmit,
	isSubmitting,
	keyStrokeHandler,
	values,
}) {
	return {
		id: "delete",
		label: "Delete Level",
		render: () => {
			return (
				<div>
					<StepTitle>{`Are you sure you want to delete ${values.name}. This action CANNOT be undone.`}</StepTitle>
				</div>
			);
		},
		footer: () => {
			return (
				<WizardNavigation
					leftItems={[
						<Button key="previous" onClick={close} color="blue">
							Cancel
						</Button>,
					]}
					rightItems={[
						<Button
							key="submit"
							color="red"
							onClick={handleSubmit}
							disabled={isSubmitting}
							keyStrokeHandler={keyStrokeHandler}
						>
							Delete Level
						</Button>,
					]}
				/>
			);
		},
	};
}

export function editStep({
	close,
	handleSubmit,
	isSubmitting,
	keyStrokeHandler,
	values,
	setFieldValue,
}) {
	return {
		id: "edit",
		label: "Edit Level",
		render: () => {
			return (
				<div>
					<StepTitle>What is the name for this level?</StepTitle>
					<EditableInputField
						type="text"
						maxLength="50"
						name="name"
						onChange={(event) => setFieldValue("name", event.target.value)}
						value={values.name}
						useLabel="Name"
						error={values.name ? "" : "Please supply a name for this level."}
					/>
				</div>
			);
		},
		footer: () => {
			return (
				<WizardNavigation
					leftItems={[
						<Button key="previous" onClick={close} color="blue">
							Cancel
						</Button>,
					]}
					rightItems={[
						<Button
							key="submit"
							color="green"
							onClick={handleSubmit}
							disabled={isSubmitting || !values.name}
							keyStrokeHandler={keyStrokeHandler}
						>
							Update Name
						</Button>,
					]}
				/>
			);
		},
	};
}

export function selectFloorStep({
	close,
	keyStrokeHandler,
	next,
	values,
	setFieldValue,
}) {
	return {
		id: "edit",
		label: "Edit Level",
		render: () => {
			return (
				<div>
					<StepTitle>Select level</StepTitle>
					<Dropdown
						options={values.availableFloors}
						value={values.selectedFloor}
						onChange={(value) => {
							setFieldValue("selectedFloor", value);
						}}
					/>
				</div>
			);
		},
		footer: () => {
			return (
				<WizardNavigation
					leftItems={[
						<Button key="previous" onClick={close} color="blue">
							Cancel
						</Button>,
					]}
					rightItems={[
						<Button
							disabled={!values.selectedFloor}
							key="next"
							color="blue"
							onClick={() => {
								next();
							}}
							keyStrokeHandler={keyStrokeHandler}
						>
							Next
						</Button>,
					]}
				/>
			);
		},
	};
}

export function uploadImageStep({
	previous,
	handleSubmit,
	isSubmitting,
	keyStrokeHandler,
	values,
	setFieldValue,
}) {
	return {
		id: "edit",
		label: "Edit Level",
		render: () => {
			return (
				<div>
					<InfoBox
						text={
							"This floor plan is only a guide to build your site map on. The site map the users see will not show this image"
						}
						style={{ marginBottom: 36 }}
					/>
					<ImportImageBox
						onChange={(newImages) => {
							setFieldValue("floorPlan", newImages[0]);
						}}
						title={"Import Floor Plan"}
						url={values.Images?.[0]}
						maxSize={500 * 1000}
						acceptableFiles={["image/jpeg"]}
					/>
				</div>
			);
		},
		footer: () => {
			return (
				<WizardNavigation
					leftItems={[
						<Button key="previous" onClick={previous} color="blue">
							Back
						</Button>,
					]}
					rightItems={[
						<Button
							key="submit"
							color="green"
							onClick={handleSubmit}
							disabled={isSubmitting || !values.floorPlan}
							keyStrokeHandler={keyStrokeHandler}
						>
							Upload
						</Button>,
					]}
				/>
			);
		},
	};
}

export function deleteBlueprintStep({
	close,
	handleSubmit,
	isSubmitting,
	keyStrokeHandler,
}) {
	return {
		id: "delete",
		label: "Delete Floor Plan Reference",
		render: () => {
			return (
				<div>
					<StepTitle>
						Are you sure you want to delete the floor plan reference. This
						action CANNOT be undone.
					</StepTitle>
				</div>
			);
		},
		footer: () => {
			return (
				<WizardNavigation
					leftItems={[
						<Button key="previous" onClick={close} color="blue">
							Cancel
						</Button>,
					]}
					rightItems={[
						<Button
							key="submit"
							color="red"
							onClick={handleSubmit}
							disabled={isSubmitting}
							keyStrokeHandler={keyStrokeHandler}
						>
							Delete Floor Plan Reference
						</Button>,
					]}
				/>
			);
		},
	};
}
