import styled from "styled-components";

const FlexWrapperCol = styled.div`
	display: flex;
	flex-direction: column;
	margin: -16px 0;
	padding: 16px 0;
	position: relative;
	gap: 16px;
`;

export default FlexWrapperCol;
