async function downloadFile({ endpoint, headers, method }) {
	try {
		const response = await fetch(endpoint, {
			method,
			headers,
		});

		if (!response.ok) {
			const body = await response.json();
			return {
				error:
					response.status >= 400 && response.status < 500
						? body.error
						: { message: "Something went wrong" },
			};
		}

		const filename =
			response.headers
				.get("Content-Disposition")
				?.match(/filename="(.+?)"/)?.[1] || `${Math.floor(Date.now() / 1000)}`;

		const blob = await response.blob();
		const url = window.URL.createObjectURL(blob);

		const a = document.createElement("a");
		a.href = url;
		a.download = filename;

		a.click();

		window.URL.revokeObjectURL(url);
		return { url };
	} catch (error) {
		return { error: { message: "Something went wrong" } };
	}
}

export { downloadFile };
