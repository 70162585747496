import Select, { components } from "react-select";
import React from "react";
import { Search } from "react-feather";
import { colours } from "../../styles";
import styled from "styled-components";

const overrideStyles = {
	multiValueRemove: (base, state) => {
		return state.data.isFixed ? { ...base, display: "none" } : base;
	},
};

const Wrapper = styled.div`
	margin-right: 4px;

	.react-select__control {
		background: #5e6d76;
		border: none;
		cursor: pointer;
		transition: 0.2s ease;
		color: ${colours.background};
		min-height: 36px;
		margin: 16px;

		&:hover {
			opacity: 0.8;
		}

		&.react-select__control--is-focused {
			box-shadow: none;
		}
	}

	.react-select__dropdown-indicator,
	.react-select__clear-indicator {
		color: ${colours.borderGrey};
		transition: 0.2s ease;

		&:hover {
			color: ${colours.borderGrey};
			opacity: 0.8;
		}
	}

	.react-select__indicator {
		padding: 8px 12px;
	}

	.react-select__indicator-separator {
		display: none;
	}

	.react-select__value-container {
		padding: 4px 8px;

		.react-select__placeholder {
			color: ${colours.lightGrey};
		}
	}

	.react-select__single-value,
	.react-select__input-container {
		color: ${colours.white};
		overflow: visible;
	}

	.react-select__multi-value__label {
		color: ${colours.darkGrey};
	}

	.react-select__menu {
		border-radius: 0;
		background: none;
		color: ${colours.white};
		box-shadow: none;
		margin-top: -1px;
		position: relative;
	}

	.react-select__menu-list {
		padding: 0;
	}

	.react-select__option {
		color: ${colours.white};
		cursor: pointer;
		padding: 8px 2px 8px 24px;
		background: none;
		width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		&:active {
			background: none;
		}

		&.react-select__option--is-focused {
			color: ${colours.lightGrey};
		}

		&.react-select__option--is-selected {
			color: ${colours.green};
			font-weight: bold;
		}
	}
`;

const DropdownIndicator = (props) => {
	return (
		<components.DropdownIndicator {...props}>
			<Search size={20} />
		</components.DropdownIndicator>
	);
};

const SiteDropdown = React.forwardRef((props, ref) => (
	<Wrapper>
		<Select
			ref={ref}
			styles={overrideStyles}
			components={{ DropdownIndicator }}
			classNamePrefix="react-select"
			{...props}
		/>
	</Wrapper>
));

export default SiteDropdown;
