export const paginatedState = {
	cursor: {}, //Cursor for pagination
	pageSize: 0, //Number of items per page
	pageOffset: 1, //Current page
	sortBy: "", //Column/Field to sort by`
	sortOrder: "", //Sort direction (asc/dsc)
	searchTokens: "", //Search query free text input
	eventType: null, //Action taken to cause pagination refresh
};

export const PaginationEventTypes = {
	CLEAR_SEARCH: "CLEAR_SEARCH",
	NEXT: "NEXT",
	PAGE_SIZE: "PAGE_SIZE",
	PREVIOUS: "PREVIOUS",
	SEARCH: "SEARCH",
	SORT: "SORT",
};

export function fetchDataCallback(_setState) {
	return function ({
		cursor,
		pageSize,
		sortBy,
		sortOrder,
		searchTokens,
		pageOffset,
		eventType,
	}) {
		_setState((_state) => ({
			cursor: cursor || {},
			pageOffset,
			eventType,
			pageSize: pageSize || _state.pageSize,
			sortBy: sortBy || _state.sortBy,
			sortOrder: sortOrder || _state.sortOrder,
			searchTokens:
				searchTokens != null ? searchTokens.trim() : _state.searchTokens,
		}));
	};
}
