import * as sessionApi from "../../api/session";
import React, { useState } from "react";
import FetchButton from "../layout/FetchButton";
import Loader from "../layout/Loader";
import PropTypes from "prop-types";
import { RefreshCw } from "react-feather";
import { format } from "../../helpers";

ParkingSessionFeePreview.propTypes = {
	siteId: PropTypes.number.isRequired,
	parkingSessionId: PropTypes.number.isRequired,
	onChange: PropTypes.func,
	fee: PropTypes.number,
	setState: PropTypes.func,
};

function ParkingSessionFeePreview(props) {
	const [loading, setLoading] = useState(false);

	async function fetchSessionData() {
		setLoading(true);

		try {
			let data = await sessionApi.getSession(
				props.siteId,
				props.parkingSessionId
			);
			props.onChange(String(data.CachedFee));
		} catch (error) {
			props.onChange(null);
		}

		setLoading(false);
	}

	return (
		<FetchButton onClick={fetchSessionData}>
			{loading && (
				<div>
					<Loader size={12} thickness={2} />
				</div>
			)}
			{!loading &&
				(props.fee === null ? (
					<span>Load fee</span>
				) : (
					<span style={{ verticalAlign: "middle" }}>
						{format.money(props.fee)}
					</span>
				))}
			{!loading && props.fee !== null && (
				<RefreshCw
					size="14"
					style={{ marginLeft: 8, verticalAlign: "middle" }}
				/>
			)}
		</FetchButton>
	);
}

export default ParkingSessionFeePreview;
