import Button from "../../layout/Button";
import DatePicker from "../../layout/DatePicker";
import React from "react";
import { StepText } from "../WizardLayout";
import WizardNavigation from "../WizardNavigation";

function getFooter(
	handleSubmit,
	isSubmitting,
	wizardProps,
	confirmText,
	mode,
	keyStrokeHandler
) {
	return (
		<WizardNavigation
			leftItems={
				mode === "remove"
					? [
							<Button key="cancel" color="blue" onClick={wizardProps.close}>
								Cancel
							</Button>,
					  ]
					: []
			}
			rightItems={[
				<Button
					key="submit"
					color={mode === "remove" ? "red" : "blue"}
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					{confirmText}
				</Button>,
			]}
		/>
	);
}

export const removeStep = ({
	handleSubmit,
	isSubmitting,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "remove",
	label: "Remove",
	render: () => (
		<div>
			<StepText>Are you sure you want to delete this custom date?</StepText>
		</div>
	),
	footer: () =>
		getFooter(
			handleSubmit,
			isSubmitting,
			wizardProps,
			"Remove",
			"remove",
			keyStrokeHandler
		),
});

export const addCustomStep = ({
	handleSubmit,
	isSubmitting,
	wizardProps,
	values,
	setFieldValue,
	keyStrokeHandler,
}) => ({
	id: "addCustom",
	label: "AddCustom",
	render: () => (
		<div>
			<DatePicker
				options={{
					pickerMode: "datePicker",
					inline: "true",
					minDate: values.minDate,
					disable: values.isDisabled,
				}}
				value={values.dateValue}
				onChange={(value) => {
					setFieldValue("dateValue", value[0]);
				}}
			/>
		</div>
	),
	footer: () =>
		getFooter(
			handleSubmit,
			isSubmitting,
			wizardProps,
			"Add",
			"addCustom",
			keyStrokeHandler
		),
});
