import WizardLayout, { StepText } from "../WizardLayout";
import Alert from "react-s-alert";
import Button from "../../layout/Button";
import React from "react";
import WizardNavigation from "../WizardNavigation";

function _editStep({ close, handleSubmit, isSubmitting, keyStrokeHandler }) {
	return {
		id: "edit",
		label: "Void Session",
		render: () => {
			return (
				<div>
					<StepText>
						<div style={{ textAlign: "center" }}>
							Voiding this session will permanently mark the status as Voided
							and will not show in enforcement reports.
						</div>
					</StepText>
				</div>
			);
		},
		footer: () => {
			return (
				<WizardNavigation
					leftItems={[
						<Button key="previous" onClick={close} color="red">
							Cancel
						</Button>,
					]}
					rightItems={[
						<Button
							key="submit"
							color="green"
							onClick={handleSubmit}
							disabled={isSubmitting}
							keyStrokeHandler={keyStrokeHandler}
						>
							Mark as Void
						</Button>,
					]}
				/>
			);
		},
	};
}

export default function VoidSessionWizard(props) {
	return (
		<WizardLayout
			close={props.close}
			title={"Void Session"}
			values={[]}
			onSubmit={async ({ setSubmitting }) => {
				try {
					await props.voidSession({
						variables: {
							siteIds: props.sites,
							startEventId: props.session.StartEventId,
							endEventId: props.session.EndEventId,
						},
					});
					props.refetch();
					Alert.success("Session updated");
					props.close(true);
				} catch (error) {
					Alert.error("Something went wrong. Please try again.");
				} finally {
					setSubmitting(false);
				}
			}}
			steps={[_editStep]}
			initialStep={0}
			wizardProps={props}
		/>
	);
}
