import * as Yup from "yup";
import * as billingApi from "../../../api/billing";
import WizardLayout, { StepTitle } from "../WizardLayout";
import Alert from "react-s-alert";
import Button from "../../layout/Button";
import Input from "../../layout/Input";
import React from "react";
import WizardNavigation from "../WizardNavigation";

const companyInfo = ({
	values,
	setFieldValue,
	handleSubmit,
	isSubmitting,
	keyStrokeHandler,
}) => ({
	id: "companyInfo",
	label: "Company Info",
	render: () => (
		<div>
			<StepTitle>Company Info</StepTitle>
			<h3>Company Name</h3>
			<Input
				type="text"
				name="CompanyName"
				onChange={(event) => setFieldValue("CompanyName", event.target.value)}
				value={values.CompanyName || ""}
			/>
			<h3>Tax Registration</h3>
			<Input
				type="text"
				name="TaxRegistration"
				onChange={(event) =>
					setFieldValue("TaxRegistration", event.target.value)
				}
				value={values.TaxRegistration || ""}
			/>
			<h3>Billing Address</h3>
			<Input
				type="text"
				name="BillingAddress"
				onChange={(event) =>
					setFieldValue("BillingAddress", event.target.value)
				}
				value={values.BillingAddress || ""}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					Update Billing Details
				</Button>,
			]}
		/>
	),
});

export default function SiteAdminBillingWizard(props) {
	return (
		<WizardLayout
			close={props.close}
			title={"Edit Billing Details"}
			values={[
				{
					name: "BillingDetailsID",
					value: props.billingDetails
						? props.billingDetails.BillingDetailsID
						: null,
					validator: Yup.number().integer().nullable(),
				},
				{
					name: "OrganizationID",
					value: props.organizationId,
					validator: Yup.number().integer(),
				},
				{
					name: "CompanyName",
					value: props.billingDetails ? props.billingDetails.CompanyName : null,
					validator: Yup.string(),
				},
				{
					name: "TaxRegistration",
					value: props.billingDetails
						? props.billingDetails.TaxRegistration
						: null,
					validator: Yup.string(),
				},
				{
					name: "BillingAddress",
					value: props.billingDetails
						? props.billingDetails.BillingAddress
						: null,
					validator: Yup.string(),
				},
			]}
			onSubmit={async (values, { setSubmitting }) => {
				setSubmitting(true);

				await billingApi.updateInsensitiveBillingDetails(
					props.organizationId,
					props.billingDetails.BillingDetailsID,
					{
						CompanyName: values.CompanyName,
						BillingAddress: values.BillingAddress,
						TaxRegistration: values.TaxRegistration,
					}
				);

				setSubmitting(false);
				Alert.success("Billing details updated");
				props.close(true);
			}}
			steps={[companyInfo]}
			initialStep={0}
		/>
	);
}
