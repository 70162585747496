import * as Yup from "yup";
import WizardLayout from "../WizardLayout";
import Alert from "react-s-alert";
import PropTypes from "prop-types";
import React from "react";
import { useMutateData } from "../../../hooks";
import gql from "graphql-tag";
import { constants } from "../../../helpers";
import {
	nameStep,
	defaultsStep,
	taxDefaultsStep,
} from "./create-organization-steps";

import moment from "moment-timezone";

const currencyCodes = constants.CURRENCIES.map((currency) => currency.value);

CreateOrganizationWizard.propTypes = {
	close: PropTypes.func.isRequired,
};

CreateOrganizationWizard.defaultProps = {
	close: () => {},
};

const constructWizard = () => {
	return {
		initialStep: 0,
		steps: [nameStep, defaultsStep, taxDefaultsStep],
	};
};

export default function CreateOrganizationWizard(props) {
	const wizard = constructWizard();

	const createOrganization = useMutateData(gql`
		mutation (
			$name: String!
			$timezone: String!
			$currency: String!
			$defaultTaxRates: [DefaultTaxRateInput]
		) {
			createOrganization(
				name: $name
				timezone: $timezone
				currency: $currency
				defaultTaxRates: $defaultTaxRates
			) {
				OrganizationID
				Name
				DefaultTimezone
				DefaultCurrency
				DefaultTaxRates {
					Name
					Percentage
				}
			}
		}
	`);

	const onSubmit = async (values, { setSubmitting }) => {
		setSubmitting(true);

		try {
			const response = await createOrganization({
				variables: {
					name: values.Name,
					timezone: values.Timezone,
					currency: values.Currency,
					defaultTaxRates: values.DefaultTaxRates,
				},
			});

			const organizationId = response?.data?.createOrganization?.OrganizationID;

			if (organizationId) {
				Alert.success("Client created");

				//wait a second before going to the new org so that the user can see Alert
				return new Promise(() =>
					setTimeout(() => {
						window.location.href = `/operator/${props.selectedOperator.OperatorID}/organization/${organizationId}`;
					}, 1000)
				);
			}
		} catch (error) {
			setSubmitting(false);
			Alert.error("Something went wrong. Please try again.");
		}
	};

	return (
		<WizardLayout
			close={props.close}
			title="Create Client"
			values={[
				{
					name: "Name",
					validator: Yup.string(),
				},
				{
					name: "Timezone",
					validator: Yup.string().oneOf(moment.tz.names()),
				},
				{
					name: "Currency",
					validator: Yup.string().oneOf(currencyCodes),
				},
				{
					name: "DefaultTaxRates",
					value: [{}],
					validator: Yup.array(Yup.object()).default([{}]),
				},
			]}
			onSubmit={onSubmit}
			steps={wizard.steps}
			initialStep={wizard.initialStep}
			wizardProps={props}
			hideStepIndicator={true}
		/>
	);
}
