import AlertMessage from "../../../layout/AlertMessage";
import DatePicker from "../../../layout/DatePicker";
import React from "react";
import { StepTitle } from "../../WizardLayout";
import { colours } from "../../../../styles";
import moment from "moment";
import styled from "styled-components";

const Wrapper = styled.div`
	display: flex;
	label {
		color: ${colours.midGrey};
		font-size: 18px;
		font-weight: 400;
		pointer-events: none;
		transform: scale(1);
		transform-origin: left top;
		transition: 0.2s ease;
		margin-bottom: 10px;
	}

	div {
		margin-right: 10px;
	}
`;

export default ({ values, setFieldValue, goTo }) => {
	const format = "d M Y h:iK";
	return {
		id: "oneOff",
		summary: [
			{
				id: "oneOff",
				label: "Hours",
				value: (
					<React.Fragment>
						<span>
							Starts at:{" "}
							{moment(values.startTimestamp).format("DD MMM YYYY hh:mm A")}
						</span>
						<br />
						<span>
							Ends at:{" "}
							{moment(values.endTimestamp).format("DD MMM YYYY hh:mm A")}
						</span>
					</React.Fragment>
				),
				edit: () => {
					setFieldValue("editFromSummary", true);
					setFieldValue("configuration", "oneOff");
					goTo("configuration");
				},
			},
		],
		render: () => (
			<React.Fragment>
				{values.conflictRateErrorMessage &&
					values.conflictRateErrorMessage.length > 0 && (
						<AlertMessage
							key={values.conflictRateErrorMessage[0]}
							text={`${values.conflictRateErrorMessage[0]} (and ${values.conflictRateErrorMessage.length} more conflicts)`}
							borderColor="red"
							backgroundColor="red"
							textColor="white"
						/>
					)}
				<StepTitle>When will this one-off rate be available?</StepTitle>
				<Wrapper>
					<div>
						<label>Start time</label>
						<DatePicker
							options={{
								pickerMode: "datePicker",
								enableTime: true,
								altFormat: format,
								dateFormat: format,
							}}
							style={{ width: "220px", marginTop: "10px" }}
							value={values.startTimestamp}
							onChange={([startTimestamp]) => {
								setFieldValue("startTimestamp", startTimestamp);
							}}
						/>
					</div>
					<div>
						<label>End time</label>
						<DatePicker
							options={{
								pickerMode: "datePicker",
								enableTime: true,
								altFormat: format,
								dateFormat: format,
							}}
							style={{ width: "220px", marginTop: "10px" }}
							value={values.endTimestamp}
							onChange={([endTimestamp]) => {
								setFieldValue("endTimestamp", endTimestamp);
							}}
						/>
					</div>
				</Wrapper>
			</React.Fragment>
		),
		isValid: values.startTimestamp < values.endTimestamp,
	};
};
