import { AlertTriangle, PlusCircle, XCircle } from "react-feather";
import Button from "../layout/Button";
import React from "react";
import TimeRangePicker from "../../components/layout/TimeRangePicker";
import Toggle from "../../components/layout/Toggle";
import Tooltip from "../../components/layout/Tooltip";
import { colours } from "../../styles";
import moment from "moment";
import styled from "styled-components";

const Wrapper = styled.div`
	display: flex;
	position: relative;

	> * {
		margin: 0 10px;
	}
`;

const Label = styled.div`
	font-size: 18px;
	font-weight: 700;
	position: relative;
	width: 100px;

	> div {
		position: absolute;
		transform: translateY(-50%);
		top: 50%;
	}
`;

const OptionWrapper = styled.div`
	width: 60px;
	cursor: pointer;

	> div {
		position: absolute;
		transform: translateY(-50%);
		top: 50%;
	}

	.tooltip-icon {
		margin-top: 4px;
	}
`;

export default function HoursBlock(props) {
	const {
		block,
		label,
		isFirstBlock,
		isLastBlock,
		isOpen,
		isCustom,
		isConflict,
		onUpdate,
		onToggle,
		onRemove,
		onAdd,
		onDelete,
		hasEditPermissions,
	} = props;

	return (
		<Wrapper>
			<Label range={isCustom}>
				<div>{isFirstBlock ? label : ""}</div>
			</Label>
			<TimeRangePicker
				startTime={moment(block.from.time, "HH:mm").toDate()}
				endTime={moment(block.to.time, "HH:mm").toDate()}
				disabled={!isOpen || !hasEditPermissions}
				onChange={onUpdate}
			/>
			{isFirstBlock ? (
				<OptionWrapper>
					<Toggle
						onChange={onToggle}
						checked={isOpen}
						disabled={!hasEditPermissions}
					/>
				</OptionWrapper>
			) : (
				<OptionWrapper onClick={onRemove} hidden={!hasEditPermissions}>
					<Tooltip
						tooltipContent={"Remove this time block"}
						render={<XCircle color={colours.red} />}
					/>
				</OptionWrapper>
			)}
			{isLastBlock && isOpen ? (
				<OptionWrapper hidden={!hasEditPermissions} onClick={onAdd}>
					<Tooltip
						tooltipContent={"Add another time block"}
						render={<PlusCircle color={colours.blue} />}
					/>
				</OptionWrapper>
			) : (
				<OptionWrapper />
			)}
			{isConflict && isFirstBlock ? (
				<OptionWrapper hidden={!hasEditPermissions}>
					<Tooltip
						tooltipContent={"This time block overlaps with another one"}
						render={<AlertTriangle color={colours.red} />}
					/>
				</OptionWrapper>
			) : (
				<OptionWrapper />
			)}
			{isCustom && (isLastBlock || !isOpen) && (
				<Button
					color="red"
					onClick={onDelete}
					style={{ flex: "1 0 auto", marginTop: 12 }}
					disabled={!hasEditPermissions}
				>
					Remove
				</Button>
			)}
		</Wrapper>
	);
}
