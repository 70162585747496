import React from "react";
import WizardLayout from "../WizardLayout"; // Assuming similar layout component exists
import LoadingPlaceholder from "../../report/LoadingPlaceholder";
import { useGetEventById } from "../../../containers/organization/queries/eventsApiHooks";
import { ViewEventStep } from "./steps";

const constructMenuWizard = () => {
	return {
		initialStep: 0,
		steps: [ViewEventStep],
	};
};

export default function ViewEventWizard(props) {
	const { eventData, isLoading, isError } = useGetEventById(props.eventId);

	const wizard = constructMenuWizard();

	if (isLoading) {
		return <LoadingPlaceholder />;
	}

	if (isError) {
		return <div>Error...</div>;
	}

	const {
		EventSeries,
		Brand,
		Name,
		EndTimestamp,
		StartTimestamp,
		Sites,
		Groups,
		EventID,
	} = eventData;

	return (
		<WizardLayout
			close={props.close}
			title={props.title}
			steps={wizard.steps}
			initialStep={wizard.initialStep}
			onSubmit={props.onSubmit}
			values={[
				{
					name: "eventSeries",
					value: EventSeries,
				},
				{
					name: "event",
					value: { Name, StartTimestamp, EndTimestamp },
				},
				{
					name: "brand",
					value: Brand,
				},
				{
					name: "sites",
					value: Sites,
				},
				{
					name: "groups",
					value: Groups,
				},
				{
					name: "eventId",
					value: EventID,
				},
				{
					name: "isTryingToEdit",
					value: false,
				},
				{
					name: "isTryingToDelete",
					value: false,
				},
			]}
			wizardProps={props}
		/>
	);
}
