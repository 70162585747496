import React, { useState } from "react";
import SessionsTable from "./SessionsTable";
import UsersTable from "./UsersTable";
import ContextMenu from "../../components/menu/ContextMenu";
import ContextMenuItem from "../../components/menu/ContextMenuItem";

export function SearchTabs() {
	const [activeTab, setActiveTab] = useState("transactions");

	return (
		<>
			<ContextMenu>
				<ContextMenuItem
					selected={activeTab === "transactions"}
					itemName={"Find Transactions"}
					onClick={() => setActiveTab("transactions")}
				/>
				<ContextMenuItem
					selected={activeTab === "users"}
					itemName={"Find Users"}
					onClick={() => setActiveTab("users")}
				/>
			</ContextMenu>

			{activeTab === "transactions" && <SessionsTable />}
			{activeTab === "users" && <UsersTable />}
		</>
	);
}
