import { intersection } from "lodash";

export function plate(text) {
	return !!text && text.match(/^[A-Z0-9]{1,10}/i);
}

export function validateCSV(
	file,
	csvSchema,
	setSuccess,
	setError,
	callback,
	opts = {}
) {
	let ext = file.name.split(".").pop();

	// Need to check extension when dropping a file
	if (ext === "csv") {
		let reader = new FileReader();

		// Validate that at least the required headers are present
		reader.onload = () => {
			let headers = reader.result.split(/\r\n|\n/gm)[0].split(",");

			let required = csvSchema
				.filter((column) => column.required)
				.map((column) => column.name);

			let intersect = intersection(headers, required);

			// If intersect and required differ at all, then not all required fields have been provided
			if (intersect.length !== required.length) {
				return setError();
			}
			callback(file, setSuccess, setError, opts);
		};

		reader.onerror = () => setError();

		reader.readAsText(file);
	} else {
		setError();
	}
}
