import { useEffect } from "react";
import { useGetSeriesById } from "../../../../containers/organization/queries/eventsApiHooks";
import { formatEvents } from "../EventWizard.helpers";

export const useEventSeriesData = (
	seriesId,
	eventIdToCancel,
	setEvents,
	setEventToCancel,
	setIsLoadingEvents
) => {
	const { seriesData, isLoading, isError } = useGetSeriesById(seriesId);

	useEffect(() => {
		if (seriesData?.Events) {
			const Events = seriesData.Events;
			const Timezone = seriesData.Sites[0]?.Timezone;
			let formattedEvents = formatEvents({ Events, Timezone }) || [{}];

			if (eventIdToCancel) {
				const event = Events.find((e) => e.EventID === eventIdToCancel);
				setEventToCancel(event || {});

				formattedEvents = formattedEvents.filter(
					(e) => e.EventID !== eventIdToCancel
				);
			}

			setEvents(formattedEvents);
		}
		if (!isLoading) {
			setIsLoadingEvents(false);
		}
	}, [seriesData, eventIdToCancel, isLoading]);

	return { seriesData, isLoading, isError };
};
