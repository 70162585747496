import React, { useCallback, useState } from "react";
import EditableInputField from "../layout/EditableInputField";
import Input from "../layout/Input";
import PropTypes from "prop-types";
import { colours } from "../../styles";
import { debounce } from "lodash";
import { geocodeFinder } from "../../api/google";
import styled from "styled-components";

GeoCodeFinder.propTypes = {
	address: PropTypes.string,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	mode: PropTypes.string,
};

GeoCodeFinder.defaultProps = {
	address: "",
	onChange: () => {},
	disabled: false,
	mode: "EditableInputField",
};

const Suggestion = styled.div`
	position: absolute;
	clear: both;
	cursor: pointer;
	background-color: ${colours.background};
	border-style: solid;
	border-width: 1px;
	border-color: ${colours.borderGrey};
	margin-top: ${(props) =>
		props.mode === "EditableInputField" ? "-46px" : "2px"};
	padding: 0 20px;
	height: 35px;
	line-height: 30px;
	font-size: 16px;
	z-index: 2;
`;

function GeoCodeFinder(props) {
	const [state, setState] = useState({
		address: props.address,
		suggestions: [],
		isDirty: false,
	});

	if (!state.isDirty && props.address !== state.address) {
		setState((_state) => ({ ..._state, address: props.address }));
	}

	function onChange(address = "") {
		setState((_state) => ({ ..._state, address, isDirty: true }));
		props.onChange({ isDirty: true });

		onGeoCodeFetchRequested(address);
	}

	const onGeoCodeFetchRequested = useCallback(
		debounce(
			(value) =>
				geocodeFinder(value)
					.then((suggestions) => {
						setState((_state) => ({ ..._state, suggestions }));
					})
					.catch((error) => {
						// eslint-disable-next-line no-console
						console.log(error);
						setState((_state) => ({ ..._state, suggestions: [] }));
					}),
			1000
		),
		[]
	);

	function chooseAddress(suggestion) {
		setState((_state) => ({
			..._state,
			address: suggestion.Name,
			suggestions: [],
			isDirty: false,
		}));
		props.onChange({ suggestion, isDirty: false });
	}

	return (
		<div mode={props.mode}>
			{props.mode === "EditableInputField" && (
				<EditableInputField
					onChange={(event) => onChange(event.target.value)}
					name="address"
					value={state.address || ""}
					error={state.isDirty ? "Please supply a valid address." : null}
					disabled={props.disabled}
				/>
			)}
			{props.mode === "Input" && (
				<Input
					onChange={(event) => onChange(event.target.value)}
					name="address"
					value={state.address || ""}
				/>
			)}
			{state.suggestions[0] ? (
				<Suggestion
					mode={props.mode}
					key={state.suggestions[0].Name}
					onClick={() => chooseAddress(state.suggestions[0])}
				>
					{state.suggestions[0].Name}
				</Suggestion>
			) : null}
		</div>
	);
}

export default GeoCodeFinder;
