export const LANGUAGE_MODES = {
	JSON: "json",
	XML: "xml",
	MULTIPART_FORM_DATA: "multipart-form-data",
	URL_ENCODED: "url-encoded-form",
	SOAP: "soap",
};

export const VARIABLE_TYPE_OPTIONS = [
	{ value: "String", label: "String" },
	{ value: "Number", label: "Number" },
];

export const TERNARY_CONDITION_OPTIONS = [
	{ value: "IsDefined", label: "Is defined" },
	{ value: "IsTruthy", label: "Is truthy" },
];

export const TIMESTAMP_BUILDER_FROM_OPTIONS = [{ value: "now", label: "Now" }];

export const TIMESTAMP_BUILDER_OPERATIONS_OPTIONS = [
	{ value: "Add", label: "add" },
];

export const TIMESTAMP_BUILDER_UNIT_OPTIONS = [
	{ value: "Days", label: "days" },
	{ value: "Hours", label: "hours" },
];

export const OPERAND_TYPES = {
	PATH: "Path",
	VARIABLE: "Variable",
};

export const RESPONSE_METHODS = {
	NONE: "None",
	MAP_TO_VARIABLE: "MapToVariable",
	RETURN_RESPONSE: "ReturnResponse",
	FILTER: "Filter",
};

export const RESPONSE_METHOD_LABELS = {
	[RESPONSE_METHODS.NONE]: "Do Nothing",
	[RESPONSE_METHODS.MAP_TO_VARIABLE]: "Map to Variables",
	[RESPONSE_METHODS.RETURN_RESPONSE]: "Use Response",
};

export const USE_RESPONSE_OPTIONS = [
	{ value: null, label: "Original" },
	{ value: "externalSessions", label: "External Sessions" },
];

export const REPEATER_TYPES = {
	SELECT: "Select",
	REPEATER: "Repeater",
	LITERAL: "Literal",
};
export const OPERATORS = {
	AND: "And",
};

export const AST_NODE = {
	BINARY_EXPRESSION: "BinaryExpression",
	PATH: "Path",
	VARIABLE: "Variable",
};
