import * as Yup from "yup";
import * as installationApi from "../../../api/installation";
import * as steps from "./steps";
import Alert from "react-s-alert";
import ErrorBoundary from "../../../containers/ErrorBoundary";
import PropTypes from "prop-types";
import React from "react";
import WizardLayout from "../WizardLayout";
import moment from "moment";

function getWizard(mode) {
	switch (mode) {
		case "add":
			return {
				title: "Add Promotion",
				initialStep: 0,
				steps: [
					steps.name,
					steps.sites,
					steps.spacesStep,
					steps.promotionCode,
					steps.promotionType,
					steps.promotionValue,
					// steps.appliesTo,
					steps.availability,
					steps.limits,
					steps.summary,
				],
			};
		case "update":
		case "disable":
		case "enable": {
			const _steps = [
				steps.name,
				steps.sites,
				steps.spacesStep,
				steps.promotionCode,
				steps.promotionType,
				steps.promotionValue,
				// steps.appliesTo,
				steps.availability,
				steps.limits,
				steps.summary,
			];
			return {
				title: `${mode.charAt(0).toUpperCase() + mode.slice(1)} Promotion`,
				initialStep: _steps.length - 1,
				steps: _steps,
			};
		}
		case "delete":
			return {
				title: "Delete Promotion",
				initialStep: 0,
				steps: [steps.summary],
			};
	}
}

PromotionsWizard.propTypes = {
	mode: PropTypes.oneOf(["add", "update", "disable", "enable", "delete"])
		.isRequired,
	availableSites: PropTypes.arrayOf(PropTypes.object).isRequired,
	close: PropTypes.func.isRequired,
	promotion: PropTypes.object,
};

PromotionsWizard.defaultProps = {
	promotion: {},
};

function PromotionsWizard(props) {
	const wizard = getWizard(props.mode);

	const fields = [
		{
			name: "organizationId",
			value: props.organizationId,
			validator: Yup.number(),
		},
		{
			name: "promotionId",
			value: props.promotion.PromotionID,
			validator: Yup.number(),
		},
		{
			name: "name",
			value: props.promotion.Name,
			validator: Yup.string(),
		},
		{
			name: "promotionCode",
			value: props.promotion.PromotionCode,
			validator: Yup.string(),
		},
		{
			name: "promotionValue",
			value: props.promotion.PromotionValue,
			validator: Yup.mixed().when("promotionType", {
				is: "Rate",
				then: Yup.mixed().notRequired(),
				otherwise: Yup.number()
					.positive()
					.required("Please supply a value for this promotion")
					.typeError("Please specify a number"),
			}),
		},
		{
			name: "promotionType",
			value: props.promotion.PromotionType,
			validator: Yup.string(),
		},
		{
			name: "promotionTypeOptions",
			value: props.promotionTypeOptions,
			validator: Yup.array(),
		},
		{
			name: "onAllSites",
			value: props.promotion.OnAllSites,
			validator: Yup.boolean(),
		},
		{
			name: "promotionSites",
			value: props.promotion.Sites || [],
			validator: Yup.array().nullable(true),
		},
		{
			name: "initialRates",
			value: props.promotion.Rates || [],
			validator: Yup.array().nullable(true),
		},
		{
			name: "promotionLeaseRates",
			value: props.promotion.leaseRates || [],
			validator: Yup.array().nullable(true),
		},
		{
			name: "startDate",
			value: props.promotion.StartDate || null,
			validator: Yup.string().nullable(true),
		},
		{
			name: "endDate",
			value: props.promotion.EndDate || null,
			validator: Yup.string().nullable(true),
		},
		{
			name: "userLimit",
			value: props.promotion.UserLimit || null,
			validator: Yup.number()
				.positive()
				.typeError("Please specify a number")
				.nullable(true),
		},
		{
			name: "codeLimit",
			value: props.promotion.CodeLimit || null,
			validator: Yup.number()
				.positive()
				.typeError("Please specify a number")
				.nullable(true),
		},
		{
			name: "availableSites",
			value: props.availableSites,
			validator: Yup.array(),
		},
		{
			name: "availableSpaces",
			value: props.availableSpaces,
			validator: Yup.array(),
		},
		{
			name: "promotionCodeValid",
			value: true,
		},
		{
			name: "leaseParkIds",
			value:
				props.promotion?.LeaseParks?.map((lp) => ({
					lp,
					key: props.availableSpaces.find((slp) =>
						slp.Spaces.some((s) => s.LeaseParkID === lp.LeaseParkID)
					)?.SiteID,
				}))
					.filter((item) => item.key !== undefined)
					.reduce((acc, item) => {
						acc[item.key] = item.lp.LeaseParkID;
						return acc;
					}, {}) || {},
			validator: Yup.array().nullable(true),
		},
	];

	const onSubmit = async (values, { setSubmitting }) => {
		setSubmitting(true);

		const promotion = {
			name: values.name,
			promotionType: values.promotionType,
			startDate: values.startDate
				? moment(values.startDate).startOf("day").unix()
				: null,
			endDate: values.endDate
				? moment(values.endDate).endOf("day").unix()
				: null,
			userLimit: values.userLimit,
			codeLimit: values.codeLimit,
			onAllSites: values.onAllSites,
			leaseParkIds: Object.values(values.leaseParkIds).filter((v) => !!v),
		};

		let message = "";
		if (values.promotionType === "Rate") {
			const sites = values.promotionSites;
			const rates = values.initialRates;

			const siteRatePairs = sites.flatMap((site) => {
				const siteId = site.id || site.SiteID;

				if (site.rateId) {
					return [
						{
							id: siteId,
							rateId: site.rateId,
						},
					];
				} else {
					const matchingRates = rates.filter((rate) => rate.SiteID === siteId);
					return matchingRates.map((rate) => ({
						id: siteId,
						rateId: rate.RateID,
					}));
				}
			});
			promotion.sitesWithRates = siteRatePairs;
			promotion.promotionValue = 0;
		} else {
			promotion.promotionValue = values.promotionValue;
			promotion.siteIds = values.promotionSites.map((site) => site.SiteID);
		}

		// TODO: Add Lease Rates into the promotions system.
		// Lease Rates
		// promotion.sitesWithLeaseRates = [];
		// values.promotionLeaseRates.map((site) => {
		// 	promotion.sitesWithLeaseRates.push({});
		// });

		try {
			if (props.mode === "add") {
				promotion.promotionCode = values.promotionCode;
				message = "added";

				await installationApi.createPromotion(values.organizationId, promotion);
			}

			if (props.mode === "update") {
				promotion.promotionCode = values.promotionCode;
				message = "updated";

				await installationApi.updatePromotion(
					values.organizationId,
					values.promotionId,
					promotion
				);
			}

			if (props.mode === "delete") {
				message = "deleted";
				const deleteData = {
					promotionId: values.promotionId,
				};

				await installationApi.deletePromotion(
					values.organizationId,
					deleteData
				);
			}

			if (props.mode === "enable" || props.mode === "disable") {
				message = `${props.mode}d`; // Adds a "d" to the end of the mode to make it Enabled/Disabled.
				const toggleData = {
					toggle: props.mode,
				};

				await installationApi.togglePromotion(
					values.organizationId,
					values.promotionId,
					toggleData
				);
			}

			setSubmitting(false);
			Alert.success(`Promotion ${message}`);
			props.close(true);
		} catch (error) {
			setSubmitting(false);
			Alert.error("Something went wrong. Please try again.");
		}
	};

	return (
		<ErrorBoundary>
			<WizardLayout
				close={props.close}
				title={wizard.title}
				values={fields}
				onSubmit={onSubmit}
				steps={wizard.steps}
				initialStep={wizard.initialStep}
				wizardProps={props}
			/>
		</ErrorBoundary>
	);
}

export default PromotionsWizard;
