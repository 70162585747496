import React from "react";
import Tooltip from "rc-tooltip";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
	.rc-tooltip {
		z-index: 10;
	}

	.rc-tooltip-inner {
		max-width: 300px;
		text-align: center;
	}
`;

export default function TooltipComponent(props) {
	return (
		<div>
			<GlobalStyle />
			<Tooltip overlay={props.tooltipContent} placement="top">
				<div className="tooltip-icon">{props.render}</div>
			</Tooltip>
		</div>
	);
}
