import * as sessionApi from "../../../api/session";
import PageActions, {
	LeftActions,
	RightActions,
} from "../../../components/layout/PageActions";
import React, { useContext, useState } from "react";
import TableLayout, { highlightedCell } from "../../layout/TableLayout";
import { filter, forEach } from "lodash";
import { useFetchData, usePermissions, useSearchFilter } from "../../../hooks";
import { AppContext } from "../../../context/app-context";
import Button from "../../layout/Button";
import Card from "../../layout/Card";
import DatePicker from "../../../components/layout/DatePicker";
import DeleteUserWizard from "../../wizards/user-wizard/DeleteUserWizard";
import DetailsPageWrapper from "../DetailsPageWrapper";
import DropdownMenu from "../../layout/DropdownMenu";
import Label from "../../layout/Label";
import LeaseWizard from "../../wizards/lease-wizard/LeaseWizard";
import { MoreHorizontal } from "react-feather";
import ParkingSessionFeePreview from "../../report/ParkingSessionFeePreview";
import PropTypes from "prop-types";
import SearchBox from "../../../components/layout/SearchBox";
import SessionWizard from "../../wizards/session-wizard/SessionWizard";
import TransactionDetails from "./TransactionDetails";
import { colours } from "../../../styles";
import { format } from "../../../helpers";
import moment from "moment";
import styled from "styled-components";

UserDetails.propTypes = {
	user: PropTypes.object.isRequired,
	organizationId: PropTypes.number,
	availableSites: PropTypes.array,
	global: PropTypes.bool,
	close: PropTypes.func.isRequired,
};

UserDetails.defaultProps = {
	global: false,
};

const NoData = styled.div`
	margin-top: 40px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	background-color: ${colours.offWhite};
`;

const getColor = (value) => {
	if (value === "Cancelled") {
		return "red";
	} else if (value === "Active") {
		return "green";
	}

	return "blue";
};

function UserDetails(props) {
	const {
		dispatch: { setReportDateRange },
		state: { reportDateRange },
	} = useContext(AppContext);

	const [state, setState] = useState({
		dateRange: reportDateRange || [
			moment().startOf("month").toDate(),
			moment().endOf("month").startOf("day").toDate(),
		],
		search: "",
		sessionWizardOpen: false,
		leaseWizardOpen: false,
		deleteUserWizardOpen: false,
		transactionDetailsOpen: false,
		sessionPreviewFees: {},
	});

	const userId = props.user.UserID;

	const { data: sessionsData } = useFetchData(
		{},
		props.global
			? sessionApi.getSessionsByUserGlobally
			: sessionApi.getSessionsByUserForOrg,
		props.global
			? [
					userId,
					{
						start: moment(state.dateRange[0]).startOf("day").unix(),
						end: moment(state.dateRange[1]).endOf("day").unix(),
					},
			  ]
			: [
					props.organizationId,
					userId,
					{
						start: moment(state.dateRange[0]).startOf("day").unix(),
						end: moment(state.dateRange[1]).endOf("day").unix(),
					},
			  ],
		[state.sessionWizardOpen, state.transactionDetailsOpen, state.dateRange]
	);

	forEach(sessionsData, (data) => {
		if (data && state.sessionPreviewFees[data.ParkingSessionID] !== undefined) {
			data.Fee = state.sessionPreviewFees[data.ParkingSessionID];
		} else {
			data.Fee = data.FinalFee;
		}
	});

	const { data: leasesData } = useFetchData(
		{},
		props.global
			? sessionApi.getLeasesByUserGlobally
			: sessionApi.getLeasesByUserForOrg,
		props.global
			? [
					userId,
					{
						"start-date-time": moment(state.dateRange[0]).format("YYYYMMDD"),
						"end-date-time": moment(state.dateRange[1]).format("YYYYMMDD"),
					},
			  ]
			: [
					props.organizationId,
					userId,
					{
						"start-date-time": moment(state.dateRange[0]).format("YYYYMMDD"),
						"end-date-time": moment(state.dateRange[1]).format("YYYYMMDD"),
					},
			  ],
		[state.leaseWizardOpen, state.transactionDetailsOpen, state.dateRange]
	);

	const canManageSessions = usePermissions(null, "SessionAdmin", true);

	const filteredSessions = useSearchFilter(sessionsData, state.search, [
		"Numberplate",
		"SiteName",
	]);

	const filteredLeases = useSearchFilter(leasesData, state.search, [
		"Numberplate",
		"SiteName",
	]);

	function openSessionWizard(session, mode) {
		setState((_state) => ({
			..._state,
			sessionWizardOpen: true,
			sessionWizardSession: session,
			sessionWizardMode: mode,
		}));
	}

	function openLeaseWizard(lease, mode) {
		setState((_state) => ({
			..._state,
			leaseWizardOpen: true,
			leaseWizardLease: lease,
			leaseWizardMode: mode,
		}));
	}

	function showTransactionDetails(siteId, session, lease) {
		setState((_state) => ({
			..._state,
			transactionDetailsOpen: true,
			selectedSiteId: siteId,
			transactionDetailsSession: session,
			transactionDetailsLease: lease,
		}));
	}

	function openDeleteUserWizard() {
		setState((_state) => ({
			..._state,
			deleteUserWizardOpen: true,
		}));
	}

	async function onDateRangeChange(dateRange) {
		if (dateRange && dateRange.length > 1) {
			setState((_state) => ({ ..._state, dateRange }));
			setReportDateRange(dateRange);
		}
	}

	async function onSearchFilterChange(search) {
		setState((_state) => ({ ..._state, search: search }));
	}

	if (state.sessionWizardOpen) {
		return (
			<SessionWizard
				close={() => {
					setState((_state) => ({ ..._state, sessionWizardOpen: false }));
				}}
				mode={state.sessionWizardMode}
				user={props.user}
				session={state.sessionWizardSession}
				sites={filter(
					props.availableSites,
					(site) =>
						site.HasCasualParking &&
						!site.HasSessionlessAccess &&
						site.SiteType === "Gated"
				)}
			/>
		);
	}

	if (state.leaseWizardOpen) {
		return (
			<LeaseWizard
				close={() => {
					setState((_state) => ({ ..._state, leaseWizardOpen: false }));
				}}
				mode={state.leaseWizardMode}
				lease={state.leaseWizardLease}
				sites={filter(props.availableSites, (site) => site.HasLeaseParking)}
			/>
		);
	}

	if (state.transactionDetailsOpen) {
		return (
			<TransactionDetails
				{...props}
				siteId={state.selectedSiteId}
				session={state.transactionDetailsSession}
				lease={state.transactionDetailsLease}
				onClose={() => {
					setState((_state) => ({
						..._state,
						transactionDetailsOpen: false,
					}));
				}}
			/>
		);
	}

	if (state.deleteUserWizardOpen) {
		return (
			<DeleteUserWizard
				{...props}
				onClose={() => {
					setState((_state) => ({
						..._state,
						deleteUserWizardOpen: false,
					}));
				}}
				close={props.close}
				refetch={props.refetch}
			/>
		);
	}

	const SessionColumns = (_props) => [
		{
			id: "Status",
			Header: "Status",
			accessor: (d) => d.IsActive,
			Cell: (cellProps) => (
				<Label
					color={
						cellProps.original.IsActive
							? "green"
							: cellProps.original.WasVoided
							? "blue"
							: "red"
					}
				>
					{cellProps.original.IsActive
						? "Active"
						: cellProps.original.WasVoided
						? "Void"
						: "Closed"}
				</Label>
			),
			width: 100,
		},
		{
			id: "Plate",
			Header: "Vehicle",
			accessor: "Numberplate",
			Cell: (cellProps) =>
				highlightedCell(
					String(cellProps.original.Numberplate || ""),
					state.search
				),
		},
		{
			id: "Site",
			Header: "Site",
			accessor: "SiteName",
			Cell: (cellProps) =>
				highlightedCell(
					String(cellProps.original.SiteName || ""),
					state.search
				),
		},
		{
			id: "StartTime",
			Header: "Start Time",
			accessor: "EntryTimestamp",
			Cell: (cellProps) => cellProps.original._startDate,
		},
		{
			id: "Duration",
			Header: "Duration",
			accessor: (d) =>
				d.ExitTimestamp
					? format.duration(d.ExitTimestamp - d.EntryTimestamp, true, true)
					: format.duration(moment().unix() - d.EntryTimestamp, true, true),
		},
		{
			id: "Fee",
			Header: "Fee",
			accessor: (d) => d.FinalFee,
			Cell: (cellProps) => {
				if (!cellProps.original.IsActive) {
					return format.money(cellProps.original.Fee);
				} else {
					return (
						<ParkingSessionFeePreview
							key={cellProps.original.ParkingSessionID}
							parkingSessionId={cellProps.original.ParkingSessionID}
							siteId={cellProps.original.SiteID}
							fee={cellProps.original.Fee || null}
							onChange={(fee) => {
								_props.setState((_state) => ({
									..._state,
									sessionPreviewFees: {
										..._state.sessionPreviewFees,
										[cellProps.original.ParkingSessionID]: fee,
									},
								}));
							}}
						/>
					);
				}
			},
		},
		{
			id: "tasks",
			Header: "",
			accessor: null,
			Cell: (cellProps) =>
				_props.canManageSessions && cellProps.original.IsActive ? (
					<DropdownMenu
						triggerContent={<MoreHorizontal size={24} />}
						items={[
							<div
								key="end-session"
								onClick={() =>
									_props.openSessionWizard(cellProps.original, "end-session")
								}
							>
								End Session
							</div>,
						]}
					/>
				) : !cellProps.original.IsActive ? (
					<DropdownMenu
						triggerContent={<MoreHorizontal size={24} />}
						items={[
							<div
								key="session-details"
								onClick={() =>
									_props.showTransactionDetails(
										cellProps.original.SiteID,
										cellProps.original,
										null
									)
								}
							>
								Transaction details
							</div>,
						]}
					/>
				) : null,
			resizable: false,
			width: 50,
		},
	];

	const leaseColumns = (_props) => [
		{
			id: "Status",
			Header: "Status",
			accessor: (d) => d.Status,
			Cell: (cellProps) => (
				<Label color={getColor(cellProps.original.Status)}>
					{cellProps.original.Status}
				</Label>
			),
			width: 100,
		},
		{
			id: "Plate",
			Header: "Vehicle",
			accessor: "Numberplate",
			Cell: (cellProps) =>
				highlightedCell(
					String(cellProps.original.Numberplate || ""),
					state.search
				),
		},
		{
			id: "Site",
			Header: "Site",
			accessor: "SiteName",
			Cell: (cellProps) =>
				highlightedCell(
					String(cellProps.original.SiteName || ""),
					state.search
				),
		},
		{
			id: "ParkingSpace",
			Header: "Parking Space",
			accessor: "ParkingSpace",
		},
		{
			id: "StartDate",
			Header: "Start Date",
			accessor: "_startDate",
		},
		{
			id: "EndDate",
			Header: "End Date",
			accessor: "_endDate",
		},
		{
			id: "Paid",
			Header: "Paid",
			accessor: (d) => format.money(d.TotalFeePaid),
		},
		{
			id: "tasks",
			Header: "",
			accessor: null,
			Cell: (cellProps) => (
				<DropdownMenu
					triggerContent={<MoreHorizontal size={24} />}
					items={[
						<div
							key="view-lease"
							onClick={() =>
								_props.showTransactionDetails(
									cellProps.original.SiteID,
									null,
									cellProps.original
								)
							}
						>
							Transaction Details
						</div>,
						_props.canManageSessions &&
						cellProps.original.Status === "Active" &&
						cellProps.original.DurationType === "Months" ? (
							<div
								key="cancel-lease"
								onClick={() =>
									_props.openLeaseWizard(cellProps.original, "cancel-lease")
								}
							>
								Cancel Lease
							</div>
						) : null,
					]}
				/>
			),
			resizable: false,
			width: 50,
		},
	];

	const content = (
		<React.Fragment>
			<PageActions>
				<LeftActions>
					<SearchBox
						onChange={onSearchFilterChange}
						value={state.searchFilter}
					/>
				</LeftActions>
				<RightActions>
					<DatePicker
						options={{ pickerMode: "dateRangePicker" }}
						value={state.dateRange}
						onChange={onDateRangeChange}
					/>
				</RightActions>
			</PageActions>
			<Card>
				<h2>User Information</h2>
				<TableLayout
					data={[props.user]}
					columns={[
						{
							id: "Name",
							Header: "Name",
							accessor: (d) => d.Name ?? `${d.FirstName} ${d.LastName}`,
							resizable: false,
						},
						{
							id: "Email",
							Header: "Email",
							accessor: "Email",
							resizable: false,
						},
					]}
					sortable={false}
				/>
			</Card>
			<Card>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<h2>Sessions</h2>
					{canManageSessions && (
						<div style={{ marginTop: "12px" }}>
							<Button
								color="blue"
								onClick={() =>
									props.global
										? openSessionWizard(null, "start-session-for-global-user")
										: openSessionWizard(null, "start-session-for-user")
								}
							>
								Start Session
							</Button>
						</div>
					)}
				</div>
				{filteredSessions.length ? (
					<TableLayout
						data={filteredSessions}
						columns={SessionColumns({
							openSessionWizard: openSessionWizard,
							showTransactionDetails: showTransactionDetails,
							availableSites: props.availableSites,
							canManageSessions: canManageSessions,
							setState: setState,
						})}
						showPagination={true}
						defaultPageSize={10}
						defaultSorted={[{ id: "EntryTimestamp", desc: true }]}
					/>
				) : (
					<NoData>No sessions found within the selected period.</NoData>
				)}
			</Card>
			<Card>
				<h2>Leases</h2>
				{filteredLeases.length ? (
					<TableLayout
						data={filteredLeases}
						columns={leaseColumns({
							openLeaseWizard: openLeaseWizard,
							showTransactionDetails: showTransactionDetails,
							availableSites: props.availableSites,
							canManageSessions: canManageSessions,
						})}
						showPagination={true}
						defaultPageSize={10}
					/>
				) : (
					<NoData>No leases found within the selected period.</NoData>
				)}
			</Card>
			{props.global && !props.user.IsDeleted ? (
				<div style={{ height: 40 }}>
					<div style={{ float: "right" }}>
						<Button color="red" onClick={openDeleteUserWizard}>
							Delete User
						</Button>
					</div>
				</div>
			) : null}
		</React.Fragment>
	);

	return (
		<DetailsPageWrapper
			close={props.close}
			content={content}
			title="User Details"
		/>
	);
}

export default UserDetails;
