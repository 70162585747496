import * as Yup from "yup";
import WizardLayout, { StepText, StepTitle } from "../WizardLayout";
import Alert from "react-s-alert";
import Button from "../../layout/Button";
import React from "react";
import WizardNavigation from "../WizardNavigation";
import { useMutateData } from "../../../hooks";
import gql from "graphql-tag";
import Dropdown from "../../layout/Dropdown";

export const addCameraToLaneStep = ({
	close,
	setFieldValue,
	handleSubmit,
	values,
	wizardProps,
}) => ({
	id: "camera",
	label: "Camera",
	render: () => {
		const cameras = wizardProps.cameras.map((camera) => ({
			label: `${camera.SerialNumber} - ${
				camera.Site ? `Installed on site ${camera.Site.Name}` : "Uninstalled"
			}`,
			value: camera.CameraID,
		}));

		return (
			<div>
				<StepTitle>Choose an existing camera to add to site</StepTitle>
				<Dropdown
					options={cameras}
					value={values.CameraID}
					onChange={(value) => {
						setFieldValue("cameraId", value.value);
					}}
				/>
				<WizardNavigation
					leftItems={[
						<Button key="previous" onClick={close} color="blue">
							Cancel
						</Button>,
					]}
					rightItems={[
						<Button key="submit" onClick={handleSubmit} color="blue">
							Add Camera to Node
						</Button>,
					]}
				/>
			</div>
		);
	},
});

const removeCameraFromLaneStep = ({ close, handleSubmit }) => ({
	id: "camera",
	label: "Camera",
	render: () => {
		return (
			<div>
				<StepText>
					Are you sure you want to remove this camera from this node?
				</StepText>

				<WizardNavigation
					leftItems={[
						<Button key="previous" onClick={close} color="blue">
							Cancel
						</Button>,
					]}
					rightItems={[
						<Button
							key="submit"
							color="red"
							onClick={() => {
								handleSubmit();
							}}
						>
							Remove
						</Button>,
					]}
				/>
			</div>
		);
	},
});

export default function UpdateCameraOnNode(props) {
	let wizard = {};

	const addCameraToNode = useMutateData(gql`
		mutation ($cameraId: Int!, $nodeId: Int!) {
			addCameraToNode(cameraId: $cameraId, nodeId: $nodeId)
		}
	`);

	const removeCameraFromNode = useMutateData(gql`
		mutation ($cameraId: Int!) {
			removeCameraFromNode(cameraId: $cameraId)
		}
	`);

	if (props.mode === "add-camera-to-node") {
		wizard = {
			title: `Add camera to node`,
			initialStep: 0,
			steps: [addCameraToLaneStep],
		};
	}

	if (props.mode === "remove-camera-from-node") {
		wizard = {
			title: `Remove camera to node`,
			initialStep: 0,
			steps: [removeCameraFromLaneStep],
		};
	}

	const onSubmit = async (values, { setSubmitting }) => {
		setSubmitting(true);

		if (values.mode === "add-camera-to-node") {
			try {
				await addCameraToNode({
					variables: {
						cameraId: parseInt(values.cameraId),
						nodeId: values.nodeId,
					},
				});

				Alert.success("Camera added to the node");
				props.onChange();
				props.close();
			} catch (error) {
				setSubmitting(false);
				Alert.error("Something went wrong. Please try again.");
			}
		}

		if (values.mode === "remove-camera-from-node") {
			try {
				await removeCameraFromNode({
					variables: {
						cameraId: props.node.CameraID,
					},
				});

				setSubmitting(false);
				Alert.success("Camera removed from the node");
				props.onChange();
				props.close();
			} catch (error) {
				setSubmitting(false);
				Alert.error("Something went wrong. Please try again.");
			}
		}
	};

	return (
		<WizardLayout
			close={props.close}
			title={wizard.title}
			values={[
				{ name: "mode", value: props.mode, validator: Yup.string() },
				{
					name: "cameraId",
					value: null,
					validator: Yup.string().optional().nullable(),
				},
				{ name: "nodeId", value: props.node.NodeID, validator: Yup.string() },
			]}
			onSubmit={onSubmit}
			steps={wizard.steps}
			initialStep={wizard.initialStep}
			wizardProps={props}
		/>
	);
}
