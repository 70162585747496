import React from "react";
import { StepTitle } from "../WizardLayout";
import EditableInputField from "../../layout/EditableInputField";
import WizardNavigation from "../WizardNavigation";
import Button from "../../layout/Button";
import Dropdown from "../../layout/Dropdown";
import { trimEnd } from "lodash";
import { constants } from "../../../helpers";
import Repeater from "../../layout/Repeater";
import Input from "../../layout/Input";
import { TAX_TYPES } from "../../../helpers/constants";

export const nameStep = ({
	close,
	setFieldValue,
	next,
	values,
	keyStrokeHandler,
}) => ({
	id: "name",
	label: "Name",
	render: () => (
		<div>
			<StepTitle>What is the name of the client?</StepTitle>
			<EditableInputField
				type="text"
				name="Name"
				value={values.Name || ""}
				useLabel="Client Name"
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				<Button key="name" color="red" onClick={close}>
					Cancel
				</Button>
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						setFieldValue("Name", trimEnd(values.Name));
						next();
					}}
					disabled={!values.Name}
					keyStrokeHandler={keyStrokeHandler}
				>
					Next
				</Button>,
			]}
		/>
	),
});

export const defaultsStep = ({
	values,
	setFieldValue,
	previous,
	next,
	keyStrokeHandler,
}) => ({
	id: "defaults",
	label: "Defaults",
	render: () => (
		<div>
			<StepTitle>Select default Timezone and Currency</StepTitle>
			<h3>Timezone</h3>
			<Dropdown
				options={constants.TIME_ZONES}
				value={constants.TIME_ZONES.find((e) => e.value === values.Timezone)}
				onChange={(value) => setFieldValue("Timezone", value.value)}
			/>
			<h3>Currency</h3>
			<Dropdown
				options={constants.CURRENCIES}
				value={constants.CURRENCIES.find((e) => e.value === values.Currency)}
				onChange={(value) => setFieldValue("Currency", value.value)}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="cancel" onClick={previous} color="blue">
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={next}
					disabled={!values.Timezone || !values.Currency}
					keyStrokeHandler={keyStrokeHandler}
				>
					Next
				</Button>,
			]}
		/>
	),
});

export const taxDefaultsStep = ({
	values,
	setFieldValue,
	handleSubmit,
	previous,
	keyStrokeHandler,
}) => ({
	id: "taxDefaults",
	label: "Tax Defaults",
	render: () => {
		return (
			<div>
				<StepTitle>Select default tax rates</StepTitle>
				<Repeater
					style={{ width: "100%" }}
					items={values.DefaultTaxRates || [{}]}
					add={() => {
						const items = values.DefaultTaxRates
							? [...values.DefaultTaxRates]
							: [];
						//Retrieve the last existing varied rate
						items.push({});
						setFieldValue("DefaultTaxRates", items);
					}}
					subtract={() => {
						const items = [...values.DefaultTaxRates];
						items.pop();
						setFieldValue("DefaultTaxRates", items);
					}}
					template={(value, index) => {
						return (
							<div style={{ display: "flex", width: "90%" }}>
								<Dropdown
									style={{ width: "70%", marginRight: "20px" }}
									options={TAX_TYPES.filter(
										(dl) =>
											!values.DefaultTaxRates?.map((dtr) => dtr.Name).includes(
												dl.value
											)
									)}
									value={TAX_TYPES.find((dl) => dl.value === value.Name)}
									onChange={(event) => {
										const items = [...values.DefaultTaxRates];
										items[index].Name = event.value;
										setFieldValue("DefaultTaxRates", items);
									}}
								/>
								<div style={{ display: "flex", width: "100%" }}>
									<Input
										type="number"
										min="0"
										max="100"
										value={value.Percentage}
										onChange={(event) => {
											const items = [...values.DefaultTaxRates];
											let val = parseFloat(event.target.value);

											if (val > 100) {
												val = 100;
											} else if (val < 0) {
												val = 0;
											}

											items[index].Percentage = val;
											setFieldValue("DefaultTaxRates", items);
										}}
									/>
								</div>
							</div>
						);
					}}
				/>
			</div>
		);
	},
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="cancel" onClick={previous} color="blue">
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="create"
					color="green"
					onClick={handleSubmit}
					disabled={!values.Timezone || !values.Currency}
					keyStrokeHandler={keyStrokeHandler}
				>
					Create
				</Button>,
			]}
		/>
	),
});
