import { isNil } from "lodash";
import Button from "../../layout/Button";
import Dropdown from "../../layout/Dropdown";
import MultiChoiceButton from "../MultiChoiceButton";
import { StepText, StepTitle } from "../WizardLayout";
import WizardNavigation from "../WizardNavigation";

export const eventSeriesStep = ({
	values,
	setFieldValue,
	handleSubmit,
	isSubmitting,
}) => {
	// Function to determine the submit button label

	let isDisabled = () => {
		if (values.isEditingEventSeries === true) {
			return isNil(values.selectedEventSeries);
		} else {
			return false;
		}
	};

	return {
		id: "eventSeries",
		label: "Event Series",
		render: () => (
			<div>
				<StepTitle>Select Event Series</StepTitle>
				<MultiChoiceButton
					selected={!values.isEditingEventSeries}
					onClick={() => {
						setFieldValue("buttonLabel", "Create Event Series");
						setFieldValue("isEditingEventSeries", false);
						setFieldValue("selectedEventSeries", null);
					}}
				>
					Create new event series
				</MultiChoiceButton>
				<MultiChoiceButton
					selected={values.isEditingEventSeries}
					onClick={() => {
						setFieldValue("buttonLabel", "Edit Event Series");
						setFieldValue("isEditingEventSeries", true);
					}}
				>
					Add events to series
				</MultiChoiceButton>
				{values.isEditingEventSeries && (
					<>
						<StepText>Event Series</StepText>
						<Dropdown
							options={values.eventSeries}
							value={values.eventSeries.find(
								(option) => option.value === values.selectedEventSeries
							)}
							onChange={(option) => {
								setFieldValue("buttonLabel", `Edit ${option.label}`);
								setFieldValue("selectedEventSeries", option.value);
							}}
						/>
					</>
				)}
			</div>
		),
		footer: () => (
			<WizardNavigation
				rightItems={[
					<Button
						key="submit"
						color="green"
						onClick={handleSubmit}
						disabled={isSubmitting || isDisabled()}
					>
						{values.buttonLabel}
					</Button>,
				]}
			/>
		),
	};
};
