import * as Yup from "yup";
import WizardLayout from "../WizardLayout";
import Alert from "react-s-alert";
import React, { useState } from "react";
import { useMutateData } from "../../../hooks";
import * as steps from "./steps";
import gql from "graphql-tag";

export default function EventPassWizard(props) {
	const [mode, setMode] = useState(props.mode);
	const [uploadResults, setUploadResults] = useState();

	let _steps = [
		steps.importTypeStep,
		steps.actionTypeStep,
		steps.usersImportStep,
		steps.summaryStep,
	];

	if (mode === "plates") {
		_steps = [steps.importTypeStep, steps.platesImportStep, steps.summaryStep];
	}

	if (mode === "remove") {
		_steps = [steps.removeStep];
	}

	const importUsersToEvent = useMutateData(gql`
		mutation (
			$file: Upload!
			$actionType: String!
			$eventId: ID!
			$groupId: ID!
		) {
			importUsersToEvent(
				file: $file
				actionType: $actionType
				eventId: $eventId
				groupId: $groupId
			) {
				emails
				invalidRows
			}
		}
	`);

	const importPlatesToEvent = useMutateData(gql`
		mutation ($file: Upload!, $eventId: ID!, $groupId: ID!) {
			importPlatesToEvent(file: $file, eventId: $eventId, groupId: $groupId) {
				plates
				invalidRows
			}
		}
	`);

	const removeEventPass = useMutateData(gql`
		mutation ($eventPassId: ID!) {
			removeEventPass(eventPassId: $eventPassId)
		}
	`);

	return (
		<WizardLayout
			close={props.close}
			title={mode === "remove" ? "Remove Pass" : "Import and Invite"}
			values={[
				{ name: "file", value: null, validator: Yup.mixed() },
				{ name: "actionType", value: "add", validator: Yup.string() },
				{
					name: "eventSeries",
					value: null,
					validator: Yup.object().nullable(),
				},
				{
					name: "event",
					value: null,
					validator: Yup.object().nullable(),
				},
				{
					name: "eventGroup",
					value: null,
					validator: Yup.object().nullable(),
				},
			]}
			onSubmit={async (values, { setSubmitting }) => {
				setSubmitting(true);

				if (mode === "users") {
					Alert.success(
						"Users importing. If users are not currently shown in the list, please refresh the page later."
					);

					try {
						const users = await importUsersToEvent({
							variables: {
								file: values.file,
								actionType: values.actionType,
								eventId: values.event.value,
								groupId: values.eventGroup.value,
							},
						});
						setUploadResults(users.data.importUsersToEvent);

						setSubmitting(false);
						Alert.success("Users imported.");
					} catch (error) {
						if (error?.message?.includes("invalid input")) {
							Alert.error("Invalid CSV file.");
						} else {
							Alert.error("Something went wrong. Please try again.");
						}
					}
				} else if (mode === "plates") {
					Alert.success(
						"Plates importing. If plates are not currently shown in the list, please refresh the page later."
					);
					try {
						const plates = await importPlatesToEvent({
							variables: {
								file: values.file,
								eventId: values.event.value,
								groupId: values.eventGroup.value,
							},
						});
						setUploadResults(plates.data.importPlatesToEvent);

						setSubmitting(false);
						Alert.success("Plates imported.");
					} catch (error) {
						if (error?.message?.includes("invalid input")) {
							Alert.error("Invalid CSV file.");
						} else {
							Alert.error("Something went wrong. Please try again.");
						}
					}
				} else if (mode === "remove") {
					try {
						await removeEventPass({
							variables: {
								eventPassId: props.eventPass.EventPassID,
							},
						});

						setSubmitting(false);
						Alert.success("Event pass removed.");
						props.close(true);
					} catch (error) {
						Alert.error("Something went wrong. Please try again.");
					}
				}

				setSubmitting(false);
			}}
			steps={_steps}
			wizardProps={{ ...props, mode, setMode, uploadResults }}
		/>
	);
}
