import * as Yup from "yup";
import * as api from "./api";
import * as leaseSteps from "./lease-steps";
import React from "react";
import WizardLayout from "../WizardLayout";
import gql from "graphql-tag";
import { useMutateData } from "../../../hooks";

const constructWizard = (props) => {
	if (props.mode === "add-external-booking") {
		return {
			initialStep: 0,
			steps: [
				leaseSteps.chooseSpaceStep,
				leaseSteps.enterLeaseUserStep,
				leaseSteps.selectNumberplateStep,
				leaseSteps.setLeaseDateStep,
				leaseSteps.notifyStep,
				leaseSteps.summaryStep,
			],
		};
	} else if (props.mode === "cancel-lease") {
		return {
			initialStep: 0,
			steps: [leaseSteps.cancelLeaseStep],
		};
	}
};

export default function LeaseWizard(props) {
	const wizard = constructWizard(props);

	const createExternalUserLease = useMutateData(gql`
		mutation (
			$userId: Int
			$organizationId: Int!
			$leaseParkId: Int!
			$unregisteredEmail: Email
			$unregisteredFirstName: String
			$unregisteredLastName: String
			$startDate: Timestamp!
			$endDate: Timestamp!
			$numberplate: NumberplateValue
			$notifyUser: Boolean
		) {
			createExternalUserLease(
				lease: {
					UserID: $userId
					OrganizationID: $organizationId
					LeaseParkID: $leaseParkId
					UnregisteredEmail: $unregisteredEmail
					UnregisteredFirstName: $unregisteredFirstName
					UnregisteredLastName: $unregisteredLastName
					StartDate: $startDate
					EndDate: $endDate
					Numberplate: $numberplate
					NotifyUser: $notifyUser
				}
			) {
				Sent
			}
		}
	`);

	const cancelExternalUserLease = useMutateData(gql`
		mutation ($userLeaseId: Int!) {
			cancelExternalUserLease(userLeaseId: $userLeaseId)
		}
	`);

	return (
		<WizardLayout
			close={props.close}
			title={(values) => {
				let _title = values.mode.replace(/-/g, " ");
				//capitalize the 1st letter of each word
				_title = _title.replace(/\b[a-z](?=[a-z]{2})/g, (letter) =>
					letter.toUpperCase()
				);

				return _title;
			}}
			values={[
				{ name: "mode", value: props.mode, validator: Yup.string() },
				{
					name: "organizationId",
					value: props.organizationId,
					validator: Yup.number().integer(),
				},
				{
					name: "spaces",
					value: props.spaces,
					validator: Yup.array(),
				},
				{
					name: "lease",
					value: props.lease,
					validator: Yup.object().nullable(),
				},
			]}
			onSubmit={(values, { setSubmitting }) => {
				setSubmitting(true);

				if (values.mode === "add-external-booking") {
					api.addUserLease(
						props,
						values,
						setSubmitting,
						createExternalUserLease
					);
				}
				if (values.mode === "cancel-lease") {
					api.cancelUserLease(
						props,
						values,
						setSubmitting,
						cancelExternalUserLease
					);
				}
			}}
			steps={wizard.steps}
			initialStep={wizard.initialStep}
			wizardProps={props}
		/>
	);
}
