import { includes, isEmpty } from "lodash";
import Card from "../../layout/Card";
import DetailsPageWrapper from "../DetailsPageWrapper";
import React from "react";
import TableLayout from "../../layout/TableLayout";
import { colours } from "../../../styles";
import { logType } from "./logType";
import moment from "moment";
import styled from "styled-components";

const Title = styled.div`
	font-size: 1.5em;
	font-weight: 400;
	margin: 32px 0 16px 0;
`;

/**
 * Returns a formatted summary for universal use
 *
 * @param {object} originalData
 * @param {object} finalData
 * @param {String} ObjectType
 * @returns {object} original and final data
 */
function getUniversalSummary(originalData, finalData, ObjectType) {
	let _originalData = [];
	let _finalData = [];

	const getSummary = (data) => {
		const summary = logType[ObjectType].fields
			.map((field) => {
				field = Object.assign({}, field);

				const needDollarSign =
					(includes(field.name, "fee") || includes(field.name, "amount")) &&
					!includes(field.name, "Amounts") &&
					field.name !== "FeeCapType" &&
					field.name !== "FeeCalculationType" &&
					field.name !== "MaxFee";

				if (includes(field.name, "Has")) {
					if (data[field.name] == 1) {
						field.value = "true";
					}
					if (data[field.name] == 0) {
						field.value = "false";
					}
				} else if (needDollarSign) {
					if (data[field.name] !== undefined) {
						if (data[field.name] !== null) {
							field.value = "$ " + data[field.name];
						} else {
							field.value = "$ 0";
						}
					}
				} else if (field.name === "ProRataDay") {
					if (data[field.name]) {
						field.value = "Day " + data[field.name];
					} else {
						field.value = "No pro rata";
					}
				} else if (field.name === "StartDateTime") {
					field.value = moment(data[field.name], "YYYYMMDDHHmm").format(
						"DD MMM YYYY hh:mm A"
					);
				} else if (field.name === "EndDateTime") {
					field.value = moment(data[field.name], "YYYYMMDDHHmm")
						.add(1, "minutes")
						.format("DD MMM YYYY hh:mm A");
				} else if (typeof data[field.name] === "boolean") {
					field.value = data[field.name] ? "Yes" : "No";
				} else if (typeof data[field.name] === "object") {
					field.value = JSON.stringify(data[field.name]);
				} else {
					field.value = data[field.name];
				}

				return field;
			})
			// filter out fields with null values
			.filter((field) => field.value != null);

		return summary || [];
	};

	if (Object.keys(originalData).length) {
		_originalData = getSummary(originalData);
	}

	if (Object.keys(finalData).length) {
		_finalData = getSummary(finalData);
	}

	return compareAndMarkItemsWithChanges(_originalData, _finalData);
}

/**
 * Returns original and final data. It does simple comparison of data items.
 * Final data item(s) will have `hasChanges` set to true if it differs against original data
 *
 * @param {object} originalData
 * @param {object} finalData
 * @returns {object} origin and finalData
 */
function compareAndMarkItemsWithChanges(originalData, finalData) {
	// loop through each item and do a simple comparison against the original data items
	finalData = finalData.map((_finalData) => {
		// toggle field for changes
		_finalData._hasChanges = originalData.some(
			(_originalData) =>
				_finalData.name === _originalData.name &&
				_finalData.value !== _originalData.value
		);

		_finalData.isNew = !originalData.some(
			(_originalData) => _finalData.name === _originalData.name
		);

		_finalData.hasChanges = _finalData._hasChanges || _finalData.isNew;

		return _finalData;
	});

	return {
		originalData: originalData,
		finalData: finalData,
	};
}

function SummaryTable(props) {
	return (
		<div style={{ margin: "0 40px", flex: 1 }}>
			<Title>{props.title}</Title>
			{props.data.length ? (
				<React.Fragment>
					<TableLayout
						{...props}
						data={props.data}
						columns={[
							{
								id: "Label",
								Header: "Field Name",
								accessor: "label",
								resizable: false,
							},
							{
								id: "Value",
								Header: "Data",
								accessor: "value",
								resizable: false,
								style: { whiteSpace: "pre-wrap", wordBreak: "break-all" },
							},
						]}
						sortable={false}
						getTrProps={(state, rowInfo) => {
							// hack the row colour when zone is selected
							if (rowInfo && rowInfo.original) {
								return {
									style: {
										background: !rowInfo.original._hasChanges
											? rowInfo.index % 2 === 0
												? `${colours.offWhite}`
												: `${colours.white}`
											: `${colours.green}`,
										color: !rowInfo.original._hasChanges
											? `${colours.darkGrey}`
											: `${colours.white}`,
									},
								};
							}
						}}
					/>
				</React.Fragment>
			) : null}
		</div>
	);
}

/**
 * Returns a summary and formatted data
 *
 * @param {object} data - raw history data
 * @returns {object} summary - original and final data
 */
function getLogSummary(data) {
	const { ObjectType, OriginalData, FinalData } = data;

	return getUniversalSummary(OriginalData, FinalData, ObjectType);
}

export default function HistoryDetails(props) {
	const summary = getLogSummary(props.log);

	const { originalData, finalData } = summary;

	const content = (
		<React.Fragment>
			{!(isEmpty(originalData) && isEmpty(finalData)) ? (
				<Card style={{ paddingBottom: "30px" }}>
					<div style={{ marginLeft: "40px" }}>
						{props.log.siteName !== "-" ? (
							<h2>
								{props.log.siteName} - {props.log.FriendlyMessage.message}
							</h2>
						) : (
							<h2>{props.log.FriendlyMessage.message}</h2>
						)}
					</div>
					<div style={{ display: "flex" }}>
						<SummaryTable title={"Old Value"} data={originalData} />
						<SummaryTable title={"New Value"} data={finalData} />
					</div>
				</Card>
			) : (
				<Card>
					<h2>No Data Available</h2>
				</Card>
			)}
		</React.Fragment>
	);

	return (
		<DetailsPageWrapper
			close={props.close}
			content={content}
			title="History Details"
		/>
	);
}
