import React from "react";
import { FieldContent } from "../../../styles";
import Dropdown from "../../../../../layout/Dropdown";
import { useGetLeaseParksForSiteMaps } from "../../../../../../containers/organization/queries/eventsApiHooks";
import FieldError from "../../../../../layout/FieldError";

export const Spaces = ({ selectedSite, selectedSpaces, onChange, error }) => {
	const { leaseParks, isLoading } = useGetLeaseParksForSiteMaps(selectedSite);

	if (isLoading) {
		return <div>Loading Spaces...</div>;
	}

	const spaceOptions = leaseParks?.map((b) => ({
		value: b.LeaseParkID,
		label: b.Name,
	}));

	return (
		<div>
			<FieldContent>
				<Dropdown
					isMulti={true}
					options={spaceOptions}
					value={selectedSpaces}
					onChange={onChange}
					placeholder="Select Space"
				/>
			</FieldContent>
			<FieldError showError={error} errorText={error} />
		</div>
	);
};
