import { boxShadows, colours, mediaSizes } from "../../styles";
import Media from "react-media";
import { Menu } from "react-feather";
import MenuLogo from "./MenuLogo";
import ContextSelector from "./ContextSelector";
import React from "react";
import styled from "styled-components";
import { routing } from "../../helpers";

const Wrapper = styled.div``;

const TopMenu = styled.div`
	background-color: ${colours.darkerBlue};
	box-shadow: ${boxShadows.right};
	cursor: pointer;
	height: 64px;
	max-height: calc(100vh - 64px);
	position: fixed;
	top: 0;
	transition: 0.2s ease;
	user-select: none;
	width: 100%;
	z-index: 3;
`;

const TopMenuDrawer = styled.div`
	background-color: ${colours.darkerBlue};
	height: ${(props) => (props.menuCollapsed ? 0 : 500)}px;
	overflow-y: auto;
	position: fixed;
	top: 64px;
	transition: 0.2s ease;
	width: 100%;
	z-index: 3;
`;

const MenuIcon = styled(Menu)`
	position: absolute;
	right: 16px;
	top: 12px;
	transition: 0.2s ease;

	&:hover {
		cursor: pointer;
		opacity: 0.8;
	}
`;

const SideMenu = styled.div`
	background-color: ${colours.darkerBlue};
	bottom: 0;
	box-shadow: ${boxShadows.right};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow-y: auto;
	position: fixed;
	top: 0;
	transition: 0.2s ease;
	user-select: none;
	width: ${(props) => (props.menuCollapsed ? 56 : 256)}px;
	z-index: 3;

	.resize {
		bottom: 0;
		cursor: ew-resize;
		left: ${(props) => (props.menuCollapsed ? 54 : 254)}px;
		position: fixed;
		top: 0;
		transition: 0.2s ease;
		width: 4px;
		z-index: 2;

		&:hover {
			::after {
				background-color: ${colours.green};
				bottom: 0;
				content: "";
				left: ${(props) => (props.menuCollapsed ? 56 : 256)}px;
				position: fixed;
				top: 0;
				transition: 0.2s ease;
				width: 2px;
			}
		}
	}
`;

export default function AppMenu(props) {
	const OperatorContextSelector = (collapsed) =>
		!props.operatorMenu.hide && (
			<div style={{ marginBottom: "40px" }}>
				<ContextSelector
					title="Operator"
					hasEditPermission={props.hasEditPermission}
					noContextMessage="You have no operators"
					item={props.operatorMenu.selected}
					itemName={props.operatorMenu.selected?.Name ?? ""}
					route={{
						key: "/operator-settings",
						to: routing.getOperatorRoute(
							props.operatorMenu.selected,
							"settings"
						),
					}}
					subItems={props.operatorMenu.items}
					expand={props.operatorMenu.expand}
					menuCollapsed={collapsed}
					onClick={props.operatorMenu.select}
				/>
				{props.operatorMenu.submenu}
			</div>
		);

	const ClientContextSelector = (collapsed) =>
		props.clientMenu.selected && (
			<ContextSelector
				title="Client"
				hasEditPermission={props.hasEditPermission}
				item={props.clientMenu.selected}
				itemName={props.clientMenu.selected?.Name ?? ""}
				route={{
					key: "/settings",
					to: routing.getOrganizationRoute(
						props.operatorMenu.selected,
						props.clientMenu.selected,
						"settings"
					),
				}}
				subItems={props.clientMenu.items}
				expand={props.clientMenu.expand}
				menuCollapsed={collapsed}
				onClick={props.clientMenu.select}
			/>
		);

	return (
		<Wrapper>
			<Media
				query={`(max-width: ${mediaSizes.medium}px)`}
				onChange={(matches) => {
					if (
						(matches && !props.menuCollapsed) ||
						(!matches && props.menuCollapsed)
					) {
						props.collapse();
					}
				}}
			>
				{(matches) =>
					matches ? (
						<div>
							<TopMenu
								className="top-menu"
								menuCollapsed={props.menuCollapsed}
								onClick={props.collapse}
							>
								<MenuLogo topMenu={true} />

								<MenuIcon size={40} color={colours.white} />
							</TopMenu>

							<TopMenuDrawer menuCollapsed={props.menuCollapsed}>
								{OperatorContextSelector()}
								{ClientContextSelector()}
								<div onClick={props.itemOnClick}>{props.topItems}</div>
								<div onClick={props.itemOnClick}>{props.bottomItems}</div>
							</TopMenuDrawer>
						</div>
					) : (
						<SideMenu className="side-menu" menuCollapsed={props.menuCollapsed}>
							<div>
								<MenuLogo
									onClick={props.collapse}
									menuCollapsed={props.menuCollapsed}
								/>
								{OperatorContextSelector(props.menuCollapsed)}
								{ClientContextSelector(props.menuCollapsed)}
								<div onClick={props.itemOnClick}>{props.topItems}</div>
							</div>
							<div onClick={props.itemOnClick}>{props.bottomItems}</div>
							<div className="resize" onClick={props.collapse} />
						</SideMenu>
					)
				}
			</Media>
		</Wrapper>
	);
}
