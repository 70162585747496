import Button from "./Button";
import { colours } from "../../styles";
import styled from "styled-components";

const Item = styled(Button)`
	display: inline-block;
	font-size: 14px;
	font-weight: 600;
	height: 24px;
	line-height: 12px;
	min-width: 80px;
	outline: 0;
	padding: 2px 10px;
	position: absolute;

	&:hover {
		background-color: ${colours.blue};
		color: ${colours.white};
	}
`;

export default Item;
