import FlexWrapper from "../../layout/FlexWrapper";
import { Info } from "react-feather";
import React from "react";
import Toggle from "../../layout/Toggle";
import Tooltip from "../../layout/Tooltip";
import { colours } from "../../../styles";
import styled from "styled-components";

const Wrapper = styled.div`
	border-bottom: 1px solid ${colours.borderGrey};
	margin: 0 auto;
	max-width: 800px;
`;

const Flex = styled(FlexWrapper)`
	margin: 0;
	padding: 0;
`;

const RowItem = styled.div`
	margin: 0;
	padding: 8px;
`;

const Icon = styled(RowItem)`
	flex-grow: 1;
	max-width: 40px;
	position: relative;

	.tooltip-icon {
		position: absolute;
		right: 8px;
		top: 20px;
	}
`;

const Label = styled(RowItem)`
	flex-grow: 10;
	font-size: 20px;
	font-weight: 600;
	line-height: 40px;
`;

const Value = styled(RowItem)`
	flex-grow: 1;
`;

const Options = styled(RowItem)`
	padding: 16px;
`;

export default function PermissionToggle(props) {
	return (
		<Wrapper>
			<Flex>
				<Icon>
					{props.tooltip && (
						<Tooltip
							tooltipContent={props.tooltip}
							render={<Info size={18} />}
						/>
					)}
				</Icon>
				<Label>{props.title}</Label>
				<Value>
					<Toggle
						onChange={props.onChange}
						checked={props.value}
						disabled={props.disabled}
					/>
				</Value>
			</Flex>
			{props.children && <Options>{props.children}</Options>}
		</Wrapper>
	);
}
