import MenuItem from "./MenuItem";
import React from "react";
import { colours } from "../../styles";
import styled from "styled-components";
import { Edit } from "react-feather";
import { NavLink } from "react-router-dom";

const ContextMenuItem = styled(MenuItem)`
	margin: ${(props) => (props.menuCollapsed ? "5px 0 16px 0" : "16px 0")};

	.item {
		background-color: ${colours.highlightBlue};
		font-weight: 700;
		text-align: center;
		height: 64px;
	}

	.name {
		font-size: ${(props) => (props.menuCollapsed ? "36px" : "20px")};
		margin: 8px auto 0;
		padding: ${(props) =>
			props.menuCollapsed ? "0" : props.item ? "0 50px" : "0 20px"};
		text-overflow: ellipsis;
		white-space: nowrap;
		text-align: ${(props) =>
			props.item || props.menuCollapsed ? "center" : "left"};
	}

	.left-icon {
		top: 20px;
	}

	.right-icon {
		top: 20px;
	}
`;

const ContextMenuItemTitle = styled(MenuItem)`
	margin: 0;
	padding: 0;
	.item {
		font-weight: 700;
		font-size: ${(props) => (props.menuCollapsed ? "5px" : "14px")};
		height: 20px;
	}

	.name {
		font-size: ${(props) => (props.menuCollapsed ? "10px" : "14px")};
		text-overflow: ellipsis;
		white-space: nowrap;
		text-align: ${(props) => (props.menuCollapsed ? "center" : "left")};
		padding: ${(props) => (props.menuCollapsed ? "0 0 0 0" : "0 0 0 30px")};
		margin: 0;
		line-height: 1;
	}
`;

const ContextEditButton = styled(Edit)`
	margin-top: 2px;
	z-index: 99999;
	position: absolute;

	&:hover {
		color: #1bc88e;
	}
`;

export default function ContextSelector(props) {
	let itemName = props.item ? props.itemName : props.noContextMessage;
	return (
		<>
			<ContextMenuItemTitle
				menuCollapsed={props.menuCollapsed}
				itemName={props.title}
			/>

			<ContextMenuItem
				{...props}
				leftIcon={
					<NavLink
						key={props.route.key}
						to={props.route.to}
						onClick={(event) => event.stopPropagation()}
					>
						<ContextEditButton size={20} />
					</NavLink>
				}
				item={props.item}
				hideLeftIcon={
					props.menuCollapsed || !props.item || !props.hasEditPermission
				}
				itemName={props.menuCollapsed ? itemName.substring(0, 1) : itemName}
			/>
		</>
	);
}
