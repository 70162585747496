import * as billingApi from "../../api/billing";
import * as installationApi from "../../api/installation";
import { MoreHorizontal, Plus } from "react-feather";
import React, { useState } from "react";
import { groupBy, map } from "lodash";
import {
	useFetchData,
	usePermissions,
	useQueryData,
	useHasClientAdmin,
} from "../../hooks";
import BillingWizard from "../../components/wizards/billing-wizard/BillingWizard";
import Button from "../../components/layout/Button";
import { CREDIT_CARD_TYPES } from "../../helpers/constants";
import DropdownMenuBeta from "../../components/layout/DropdownMenuBeta";
import ReportWrapperBeta from "../../components/report/ReportWrapperBeta";
import SiteAdminBillingWizard from "../../components/wizards/site-admin-billing-wizard/SiteAdminBillingWizard";
import { booleanValueFormatter } from "../../components/layout/TableLayout";
import gql from "graphql-tag";

const getItems = (props, original) => {
	if (props.isAdmin || props.isClientAdmin) {
		const items = [
			<div key="edit" onClick={() => props.openBillingWizard(original, "edit")}>
				Edit
			</div>,
			<div
				key="delete"
				onClick={() => props.openBillingWizard(original, "delete")}
			>
				Delete
			</div>,
		];

		return items;
	}
	return [
		<div key="edit" onClick={() => props.openSiteAdminBillingWizard(original)}>
			Edit
		</div>,
	];
};

const columns = (props) => [
	{
		Header: "Organization Default",
		accessor: "IsDefault",
		Cell: (cellProps) => booleanValueFormatter(cellProps, true),
	},
	{
		Header: "Company Name",
		accessor: "CompanyName",
	},
	{
		Header: "Tax Registration",
		accessor: "TaxRegistration",
	},
	{
		Header: "Billing Address",
		accessor: "BillingAddress",
	},
	{
		Header: "Payment Provider",
		accessor: (d) => d.PaymentProvider || "None",
	},
	{
		Header: "PX Post Username",
		accessor: (d) => d.PXPostUsername || "",
	},
	{
		Header: "PX Pay Username",
		accessor: (d) => d.PxPayUsername || "",
	},
	{
		Header: "PX Settlement",
		accessor: (d) =>
			d.PaymentProvider === "PaymentExpress"
				? d.MerchantAccountSettlement || "Non-Inugo"
				: "",
	},
	{
		Header: "QR Code",
		accessor: (d) => (d.WebPayConfigID ? "Enabled" : "Disabled"),
	},
	{
		Header: "Sites",
		accessor: (d) =>
			d.Sites
				? map(d.Sites, (site) =>
						site.IsDeactivated ? `${site.Name} (Deactivated)` : site.Name
				  ).join(", ")
				: "",
	},
	{
		Header: "Spaces",
		accessor: (d) =>
			d.Sites
				? map(d.LeaseSpaces, (leaseSpace) => leaseSpace.Name).join(", ")
				: "",
	},
	{
		Header: "Accepted Credit Card types",
		accessor: (d) =>
			map(d.AcceptedCreditCardTypes, (cardType) =>
				cardType === CREDIT_CARD_TYPES.MASTERCARD ? "Mastercard" : cardType
			).join(", "),
	},
	{
		id: "tasks",
		Header: "",
		accessor: null,
		Cell: ({ row: { original } }) => (
			<DropdownMenuBeta
				triggerContent={<MoreHorizontal size={24} />}
				items={getItems(props, original)}
			/>
		),
		fixedWidth: 50,
	},
];

export default function BillingContainer(props) {
	const [state, setState] = useState({
		billingWizardOpen: false,
		siteAdminBillingWizardOpen: false,
		billingWizardContent: null,
		billingWizardMode: "add",
	});

	const organizationId = props.selectedOrganization.OrganizationID;
	const isAdmin = usePermissions("IsAdmin");
	const isClientAdmin = useHasClientAdmin(props.selectedOperator.ClientID);

	const {
		data: billingDetails,
		isLoading: billingDetailsLoading,
		initialFetchComplete: billingDetailsLoaded,
	} = useFetchData(
		[],
		isAdmin || isClientAdmin
			? billingApi.getBillingDetailsForOrganization
			: billingApi.getInsensitiveBillingDetailsForOrganization,
		[organizationId],
		[state.billingWizardOpen, state.siteAdminBillingWizardOpen, organizationId]
	);

	const {
		data: sites,
		isLoading: sitesLoading,
		initialFetchComplete: sitesLoaded,
	} = useFetchData(
		[],
		billingApi.getSitesAndBillingDetailsMapping,
		[organizationId],
		[state.billingWizardOpen, state.siteAdminBillingWizardOpen, organizationId]
	);

	const {
		data: leaseSpaces,
		isLoading: leaseSpacesLoading,
		initialFetchComplete: leaseSpacesLoaded,
	} = useFetchData(
		[],
		installationApi.getLeaseParksForOrganization,
		[organizationId],
		[state.billingWizardOpen, state.siteAdminBillingWizardOpen, organizationId]
	);

	let billingData = [];

	if (billingDetailsLoaded && sitesLoaded && leaseSpacesLoaded) {
		const sitesMapping = groupBy(sites, "BillingDetailsID");
		const leaseSpacesMapping = groupBy(leaseSpaces, "BillingDetailsID");

		billingData = map(billingDetails, (billing) => ({
			...billing,
			Sites: sitesMapping[billing.BillingDetailsID] || [],
			LeaseSpaces: leaseSpacesMapping[billing.BillingDetailsID] || [],
		}));
	}

	const {
		data: { getWebPayTypes },
	} = useQueryData(
		gql`
			query {
				getWebPayTypes {
					Type
					Domain
				}
			}
		`,
		{}
	);

	const {
		data: { getSiteTypes },
	} = useQueryData(
		gql`
			query {
				getSiteTypes {
					Type
					Name
				}
			}
		`,
		{}
	);

	const {
		data: { getOrganizationById },
	} = useQueryData(
		gql`
			query ($organizationId: Int!) {
				getOrganizationById(organizationId: $organizationId) {
					Brands {
						BrandID
						LogoDark
						CompanyName
						MainColor
					}
				}
			}
		`,
		{ organizationId }
	);

	function openBillingWizard(details, mode) {
		setState((_state) => ({
			..._state,
			billingWizardOpen: true,
			billingWizardDetails: details,
			billingWizardMode: mode,
		}));
	}

	function openSiteAdminBillingWizard(details) {
		setState((_state) => ({
			..._state,
			siteAdminBillingWizardOpen: true,
			billingWizardDetails: details,
		}));
	}

	if (state.billingWizardOpen) {
		return (
			<BillingWizard
				close={() => {
					setState((_state) => ({ ..._state, billingWizardOpen: false }));
				}}
				mode={state.billingWizardMode}
				billingDetails={state.billingWizardDetails}
				isAdmin={isAdmin}
				organizationId={organizationId}
				sites={sites}
				webPayTypes={getWebPayTypes}
				brands={getOrganizationById ? getOrganizationById.Brands : []}
				siteTypes={getSiteTypes}
			/>
		);
	}

	if (state.siteAdminBillingWizardOpen) {
		return (
			<SiteAdminBillingWizard
				close={() => {
					setState((_state) => ({
						..._state,
						siteAdminBillingWizardOpen: false,
					}));
				}}
				billingDetails={state.billingWizardDetails}
				organizationId={organizationId}
				sites={sites}
			/>
		);
	}

	return (
		<div>
			<ReportWrapperBeta
				{...props}
				title="Billing Details"
				data={billingData}
				columns={columns({
					openBillingWizard: openBillingWizard,
					openSiteAdminBillingWizard: openSiteAdminBillingWizard,
					isAdmin: isAdmin,
					isClientAdmin,
				})}
				filterOptions={[
					"CompanyName",
					"TaxRegistration",
					"BillingAddress",
					"PaymentProvider",
					"PXPostUsername",
				]}
				defaultSortBy={[
					{
						id: "CompanyName",
					},
				]}
				availableSites={props.availableSites}
				loading={billingDetailsLoading && sitesLoading && leaseSpacesLoading}
				showDateRangePicker={false}
				showSitePicker={false}
				updateOptions={() => {}}
				rightActions={
					isAdmin || isClientAdmin ? (
						<Button color="blue" onClick={() => openBillingWizard(null, "add")}>
							<Plus size={20} /> Add Billing Details
						</Button>
					) : null
				}
			/>
		</div>
	);
}
