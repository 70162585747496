import Tooltip from "rc-tooltip";
import React, { useState } from "react";
import {
	DownloadCloud,
	FileText,
	MoreHorizontal,
	Plus,
	UploadCloud,
} from "react-feather";
import { Redirect, Route, Switch } from "react-router-dom";
import reactStringReplace from "react-string-replace";
import Button from "../../components/layout/Button";
import DropdownMenuBeta from "../../components/layout/DropdownMenuBeta";
import Label from "../../components/layout/Label";
import ReportWrapperBeta from "../../components/report/ReportWrapperBeta";
import IntegrationWizard from "../../components/wizards/integration-wizard";
import RequestWizard from "../../components/wizards/request-wizard";
import VariableWizard from "../../components/wizards/variable-wizard";
import {
	COLORS_BY_REQUEST_METHOD,
	EVENT_TYPES_TRANSLATIONS,
	INTEGRATION_TYPES,
	INTEGRATION_TYPES_TRANSLATIONS,
	API_KEY_SCOPE,
	REQUEST_METHODS,
	API_KEY_SCOPE_TRANSLATIONS,
} from "../../helpers/constants";
import { usePermissions, useHasClientAdmin } from "../../hooks";
import ErrorBoundary from "../ErrorBoundary";
import { useGetCamerasForOrg } from "./queries/cameras";
import {
	useGetApiKeys,
	useGetIntegrations,
	useGetRequests,
	useGetTemplates,
	useGetVariables,
} from "./queries/integrations";
import { useGetAvailableSitesForOrg } from "./queries/sites";
import ApiKeyWizard from "../../components/wizards/api-key-wizard";
import styled from "styled-components";
import TemplateWizard from "../../components/wizards/template-wizard";

const contextDescriptions = {
	Account: "Applies to all operators, clients and sites",
	Operator: "Applies to all clients and sites within the current operator",
	Client: "Applies to all sites",
};

const Block = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

const columns = (props) => [
	{
		id: "IntegrationTypeIcon",
		fixedWidth: 50,
		Cell: ({ row: { original: integration } }) => {
			switch (integration.IntegrationType) {
				case INTEGRATION_TYPES.WEBHOOK:
					return <UploadCloud style={{ marginTop: "5px" }} />;
				case INTEGRATION_TYPES.POLLING:
					return <DownloadCloud style={{ marginTop: "5px" }} />;
				case INTEGRATION_TYPES.FILE_TRANSFER:
					return <FileText style={{ marginTop: "5px" }} />;
				default:
					break;
			}
		},
	},
	{
		id: "IntegrationID",
		fixedWidth: 50,
		Header: "ID",
		accessor: (d) => d.IntegrationID,
	},
	{
		id: "IsEnabled",
		Header: "Status",
		fixedWidth: 100,
		accessor: (d) =>
			d?.IsEnabled ? (
				<Label style={{ fontFamily: "Arial" }} color="green">
					Enabled
				</Label>
			) : (
				<Label style={{ fontFamily: "Arial" }} color="yellow">
					Disabled
				</Label>
			),
	},
	{
		id: "LoggingEnabled",
		Header: "Logging",
		fixedWidth: 100,
		accessor: (d) =>
			d?.LoggingEnabled ? (
				<Label style={{ fontFamily: "Arial" }} color="green">
					Enabled
				</Label>
			) : (
				<Label style={{ fontFamily: "Arial" }} color="yellow">
					Disabled
				</Label>
			),
	},
	{
		id: "Queued",
		Header: "Queued",
		fixedWidth: 100,
		accessor: (d) => (d?.IsSynchronous ? <div>No</div> : <div>Yes</div>),
	},
	{
		id: "IntegrationType",
		Header: "Type",
		accessor: (d) => INTEGRATION_TYPES_TRANSLATIONS[d.IntegrationType],
		fixedWidth: 120,
	},
	{
		id: "EventType",
		Header: "Event",
		fixedWidth: 200,
		accessor: (d) => EVENT_TYPES_TRANSLATIONS[d.EventType]?.toLowerCase() || "",
	},
	{
		id: "Request",
		Header: "Request",
		fixedWidth: 400,
		accessor: (d) => (
			<>
				<Label
					style={{ marginRight: "10px", fontFamily: "Arial" }}
					color={COLORS_BY_REQUEST_METHOD[d.FetchRequest?.Method]}
				>
					{d.FetchRequest?.Method}
				</Label>{" "}
				{d.FetchRequest?.Name}
			</>
		),
	},
	{
		id: "Context",
		Header: "Context",
		fixedWidth: 600,
		accessor: (d) => (
			<>
				{props.context === "Client"
					? [{ property: "Sites", display: "Name", data: props.sites }].map(
							(model, mId) =>
								d[model.property]?.length ? (
									<div key={`model${mId}`}>
										<strong>{model.property}: </strong>
										<span>
											{model.data.length === d[model.property].length
												? `All ${model.property}`
												: d[model.property]
														?.map((item) => item[model.display])
														?.join(", ")}
										</span>
									</div>
								) : (
									""
								)
					  )
					: contextDescriptions[props.context]}
			</>
		),
	},
	{
		id: "tasks",
		Header: "",
		accessor: null,
		Cell: ({ row: { original: integration } }) => (
			<DropdownMenuBeta
				triggerContent={<MoreHorizontal size={24} />}
				items={[
					<div
						key="edit-integration"
						onClick={() => props.openIntegrationWizard("edit", integration)}
					>
						Edit
					</div>,
					props.isAdmin || props.isClientAdmin ? (
						<div
							key="toggle-integration"
							onClick={() => props.openIntegrationWizard("toggle", integration)}
						>
							{integration?.IsEnabled ? "Disable" : "Enable"}
						</div>
					) : null,
					props.isAdmin || props.isClientAdmin ? (
						<div
							key="export-integration"
							onClick={() => props.openIntegrationWizard("export", integration)}
						>
							Export
						</div>
					) : null,
					props.isAdmin || props.isClientAdmin ? (
						<div
							key="delete-integration"
							onClick={() => props.openIntegrationWizard("delete", integration)}
						>
							Delete
						</div>
					) : null,
				]}
			/>
		),
		resizable: false,
		fixedWidth: 50,
		sortable: false,
	},
];

const columnsForRequests = (props) => [
	{
		id: "Method",
		Header: "Method",
		fixedWidth: 100,
		accessor: (request) => (
			<Label
				style={{ marginRight: "10px", fontFamily: "Arial" }}
				color={COLORS_BY_REQUEST_METHOD[request.Method]}
			>
				{request.Method}
			</Label>
		),
	},
	{
		id: "Name",
		Header: "Name",
		fixedWidth: 400,
		accessor: (request) => request.Name,
	},
	{
		id: "URL",
		Header: "URL",
		accessor: (request) => (
			<>
				{reactStringReplace(request.URL, /{{{(.*?)}}}/, (match) => (
					<Tooltip
						key={`url${request.RequestID}`}
						overlay={
							props.variables?.find((variable) => variable.Name === match)
								?.Value
						}
						placement="top"
					>
						<Label
							style={{
								marginRight: "10px",
								fontFamily: "Arial",
								cursor: "pointer",
							}}
							color="green"
						>
							{match}
						</Label>
					</Tooltip>
				))}
			</>
		),
	},
	{
		id: "Rules",
		Header: "Rules",
		accessor: (request) => {
			const rules = request.Rules;
			const ruleCount =
				(rules?.ValidateRequest?.length || 0) +
				(rules?.PreRequest?.length || 0) +
				(rules?.PostRequest?.length || 0);

			return ruleCount
				? `${ruleCount} Rule${ruleCount === 1 ? "" : "s"} Set`
				: "None";
		},
	},
	{
		id: "tasks",
		Header: "",
		accessor: null,
		Cell: ({ row: { original: request } }) => (
			<DropdownMenuBeta
				triggerContent={<MoreHorizontal size={24} />}
				items={[
					<div
						key="edit-request"
						onClick={() => props.openRequestWizard("edit", request)}
					>
						Edit
					</div>,
					props.isAdmin || props.isClientAdmin ? (
						<div
							key="delete-request"
							onClick={() => props.openRequestWizard("delete", request)}
						>
							Delete
						</div>
					) : null,
					<div
						key="duplicate-request"
						onClick={() => props.openRequestWizard("duplicate", request)}
					>
						Duplicate
					</div>,
				]}
			/>
		),
		resizable: false,
		fixedWidth: 50,
		sortable: false,
	},
];

const columnsForVariables = (props) => [
	{
		id: "Name",
		Header: "Name",
		accessor: (variable) => variable.Name,
	},
	{
		id: "Label",
		Header: "Label",
		accessor: (variable) => variable.Label,
	},
	{
		id: "Value",
		Header: "Value",
		accessor: (variable) =>
			variable.IsSecret ? (
				<strong>••••••••••••••••••••</strong>
			) : (
				variable.Value
			),
	},
	{
		id: "Type",
		Header: "Type",
		accessor: (variable) => variable.Type,
	},
	{
		id: "Description",
		Header: "Description",
		accessor: (variable) => variable.Description,
	},
	{
		id: "tasks",
		Header: "",
		accessor: null,
		Cell: ({ row: { original: variable } }) => (
			<DropdownMenuBeta
				triggerContent={<MoreHorizontal size={24} />}
				items={[
					<div
						key="edit-variable"
						onClick={() => props.openVariableWizard("edit", variable)}
					>
						Edit
					</div>,
					props.isAdmin || props.isClientAdmin ? (
						<div
							key="delete-variable"
							onClick={() => props.openVariableWizard("delete", variable)}
						>
							Delete
						</div>
					) : null,
				]}
			/>
		),
		resizable: false,
		fixedWidth: 50,
		sortable: false,
	},
];

const columnsForApiKeys = (props) => [
	{
		id: "ApiKeyTypeIcon",
		fixedWidth: 50,
		Cell: () => <UploadCloud style={{ marginTop: "5px" }} />,
	},
	{
		id: "IsEnabled",
		Header: "Status",
		fixedWidth: 100,
		accessor: (apiKey) =>
			apiKey?.IsEnabled ? (
				<Label style={{ fontFamily: "Arial" }} color="green">
					Enabled
				</Label>
			) : (
				<Label style={{ fontFamily: "Arial" }} color="yellow">
					Disabled
				</Label>
			),
	},
	{
		id: "Name",
		Header: "Name",
		accessor: (apiKey) => apiKey.Name,
	},
	{
		id: "Scope",
		Header: "Scope",
		accessor: (apiKey) => {
			const getList = [];
			const postList = [];
			const putList = [];
			const multiList = [];

			for (const scope of apiKey.Scope) {
				switch (scope) {
					case API_KEY_SCOPE.GET_PLACES:
					case API_KEY_SCOPE.GET_RIGHTS_FOR_PLACES:
					case API_KEY_SCOPE.GET_GROUPS:
					case API_KEY_SCOPE.GET_SUB_PLACES:
					case API_KEY_SCOPE.GET_EVENTS:
					case API_KEY_SCOPE.GET_EVENT_PASSES:
					case API_KEY_SCOPE.GET_OCCUPANCY:
					case API_KEY_SCOPE.GET_OBSERVATIONS:
					case API_KEY_SCOPE.GET_SESSIONS:
					case API_KEY_SCOPE.GET_BOOKINGS:
					case API_KEY_SCOPE.GET_BOOKING_PAYMENTS:
						getList.push(scope);
						break;
					case API_KEY_SCOPE.UPDATE_RIGHTS_FOR_PLACES:
						multiList.push({
							methods: [
								REQUEST_METHODS.POST,
								REQUEST_METHODS.PUT,
								REQUEST_METHODS.DELETE,
							],
							scope,
						});
						break;
					case API_KEY_SCOPE.POST_OBSERVATION:
					case API_KEY_SCOPE.MILESIGHT_POST_OBSERVATION:
						postList.push(scope);
						break;
				}
			}

			function MethodWrapper({ method }) {
				return (
					<div>
						<Label color={COLORS_BY_REQUEST_METHOD[method]}>{method}</Label>
					</div>
				);
			}

			function Get() {
				return <MethodWrapper method={REQUEST_METHODS.GET} />;
			}

			function Post() {
				return <MethodWrapper method={REQUEST_METHODS.POST} />;
			}

			function Put() {
				return <MethodWrapper method={REQUEST_METHODS.PUT} />;
			}

			function Delete() {
				return <MethodWrapper method={REQUEST_METHODS.DELETE} />;
			}

			function Methods({ methods, scopes }) {
				return (
					<div style={{ display: "flex", gap: "8px" }}>
						{methods.map((method) => {
							switch (method) {
								case REQUEST_METHODS.GET:
									return <Get />;
								case REQUEST_METHODS.POST:
									return <Post />;
								case REQUEST_METHODS.PUT:
									return <Put />;
								case REQUEST_METHODS.DELETE:
									return <Delete />;
							}
						})}
						<div>
							{scopes.map((s) => API_KEY_SCOPE_TRANSLATIONS[s]).join(", ")}
						</div>
					</div>
				);
			}

			return (
				<Block>
					{getList.length > 0 && (
						<Methods methods={[REQUEST_METHODS.GET]} scopes={getList} />
					)}
					{postList.length > 0 && (
						<Methods methods={[REQUEST_METHODS.POST]} scopes={postList} />
					)}
					{putList.length > 0 && (
						<Methods methods={[REQUEST_METHODS.PUT]} scopes={putList} />
					)}
					{multiList.map((group) => (
						<div key={group.scope}>
							<Methods methods={group.methods} scopes={[group.scope]} />
						</div>
					))}
				</Block>
			);
		},
	},
	{
		id: "Places",
		Header: "Places",
		accessor: (apiKey) => {
			const apiKeySiteIds = apiKey.SiteIDs;
			if (!apiKeySiteIds && apiKey.OrganizationID) {
				return "All Places";
			}

			return props.sites
				?.filter((site) => apiKeySiteIds?.includes(site.SiteID))
				?.map((site) => site.Name)
				.join(", ");
		},
	},
	{
		id: "tasks",
		Header: "",
		accessor: null,
		Cell: ({ row: { original: apiKey } }) => (
			<DropdownMenuBeta
				triggerContent={<MoreHorizontal size={24} />}
				items={[
					<div
						key="edit-api-key"
						onClick={() => props.openApiKeyWizard("edit", apiKey)}
					>
						Edit
					</div>,
					props.isAdmin ? (
						<div
							key="toggle-api-key"
							onClick={() => props.openApiKeyWizard("toggle", apiKey)}
						>
							{apiKey?.IsEnabled ? "Disable" : "Enable"}
						</div>
					) : null,
					props.isAdmin || props.isClientAdmin ? (
						<div
							key="delete-api-key"
							onClick={() => props.openApiKeyWizard("delete", apiKey)}
						>
							Delete
						</div>
					) : null,
					<div
						key="download-documentation"
						onClick={() =>
							props.openApiKeyWizard("downloadDocumentation", apiKey)
						}
					>
						Download documentation
					</div>,
				]}
			/>
		),
		resizable: false,
		fixedWidth: 50,
		sortable: false,
	},
];

const columnsForTemplates = (props) => [
	{
		id: "Name",
		Header: "Name",
		accessor: (template) => template.Name,
	},
	{
		id: "Method",
		Header: "Method",
		fixedWidth: 100,
		accessor: (template) => (
			<Label
				style={{ marginRight: "10px", fontFamily: "Arial" }}
				color={COLORS_BY_REQUEST_METHOD[template.Request?.Method]}
			>
				{template.Request?.Method}
			</Label>
		),
	},
	{
		id: "Request",
		Header: "Request",
		accessor: (template) => template.Request?.Name,
	},
	{
		id: "RequestURL",
		Header: "URL",
		accessor: (template) => template.Request?.URL,
	},
	{
		id: "tasks",
		Header: "",
		accessor: null,
		Cell: ({ row: { original: template } }) => (
			<DropdownMenuBeta
				triggerContent={<MoreHorizontal size={24} />}
				items={[
					props.isAdmin || props.isClientAdmin ? (
						<div
							key="delete-template"
							onClick={() => props.openTemplateWizard("delete", template)}
						>
							Delete
						</div>
					) : null,
				]}
			/>
		),
		resizable: false,
		fixedWidth: 50,
		sortable: false,
	},
];

function IntegrationContainer(props) {
	const [state, setState] = useState({
		initialLoad: true,
		search: "",
		wizardMode: "",
		integrationWizardOpen: false,
		requestWizardOpen: false,
		variableWizardOpen: false,
		templateWizardOpen: false,
		apiKeyWizardOpen: false,
		integration: null,
		request: null,
		variable: null,
		template: null,
	});

	const organizationId =
		props.context === "Client"
			? props.selectedOrganization?.OrganizationID
			: null;

	const isAdmin = usePermissions("IsAdmin");
	const isClientAdmin = useHasClientAdmin(props.selectedOperator?.ClientID);

	async function updateOptions({ search, ...options }) {
		setState((_state) => ({ ..._state, initialLoad: false, search, options }));
	}

	const openIntegrationWizard = (mode, integration) => {
		setState((_state) => ({
			..._state,
			integration,
			wizardMode: mode,
			integrationWizardOpen: true,
		}));
	};

	const openRequestWizard = (mode, request) => {
		setState((_state) => ({
			..._state,
			request,
			wizardMode: mode,
			requestWizardOpen: true,
		}));
	};

	const openVariableWizard = (mode, variable) => {
		setState((_state) => ({
			..._state,
			variable,
			wizardMode: mode,
			variableWizardOpen: true,
		}));
	};

	const openApiKeyWizard = (mode, apiKey) => {
		setState((_state) => ({
			..._state,
			apiKey,
			wizardMode: mode,
			apiKeyWizardOpen: true,
		}));
	};

	const openTemplateWizard = (mode, template) => {
		setState((_state) => ({
			..._state,
			template,
			wizardMode: mode,
			templateWizardOpen: true,
		}));
	};

	const { cameras, isLoading: camerasAreLoading } =
		useGetCamerasForOrg(organizationId);

	const { sites, isLoading: sitesAreLoading } =
		useGetAvailableSitesForOrg(organizationId);

	const {
		integrations,
		isLoading: integrationsAreLoading,
		refetch: refetchIntegrations,
	} = useGetIntegrations(props.context, organizationId);

	const {
		requests,
		isLoading: requestsAreLoading,
		refetch: refetchRequests,
	} = useGetRequests(props.context, organizationId);

	const {
		variableGroups,
		isLoading: variablesAreLoading,
		refetch: refetchVariables,
	} = useGetVariables(props.context, organizationId);

	const {
		apiKeys,
		isLoading: apiKeysAreLoading,
		refetch: refetchApiKeys,
	} = useGetApiKeys(props.context, organizationId);

	const {
		templates,
		isLoading: templatesAreLoading,
		refetch: refetchTemplates,
	} = useGetTemplates();

	if (state.integrationWizardOpen) {
		return (
			<IntegrationWizard
				{...props}
				mode={state.wizardMode}
				close={() => {
					refetchIntegrations();
					refetchRequests();
					refetchVariables();
					refetchTemplates();
					setState((_state) => ({
						..._state,
						integrationWizardOpen: false,
					}));
				}}
				organizationId={organizationId}
				cameras={cameras}
				sites={sites}
				requests={requests}
				integration={state.integration}
				templates={templates}
			/>
		);
	}

	if (state.requestWizardOpen) {
		return (
			<RequestWizard
				{...props}
				mode={state.wizardMode}
				close={() => {
					refetchRequests();
					refetchVariables();
					setState((_state) => ({
						..._state,
						requestWizardOpen: false,
					}));
				}}
				organizationId={organizationId}
				request={state.request}
				requests={requests}
				variableGroups={variableGroups}
			/>
		);
	}

	if (state.variableWizardOpen) {
		return (
			<VariableWizard
				{...props}
				mode={state.wizardMode}
				close={() => {
					refetchVariables();
					setState((_state) => ({
						..._state,
						variableWizardOpen: false,
					}));
				}}
				organizationId={organizationId}
				variableGroups={variableGroups}
				requests={requests}
				variable={state.variable}
			/>
		);
	}

	if (state.apiKeyWizardOpen) {
		return (
			<ApiKeyWizard
				{...props}
				mode={state.wizardMode}
				close={() => {
					refetchApiKeys();
					setState((_state) => ({
						..._state,
						apiKeyWizardOpen: false,
					}));
				}}
				showApiKey={(apiKey) => {
					setState((_state) => ({
						..._state,
						apiKeyWizardOpen: true,
						rawApiKey: apiKey,
						wizardMode: "show-key",
					}));
				}}
				organizationId={organizationId}
				sites={sites}
				apiKey={state.apiKey}
				rawApiKey={state.rawApiKey}
			/>
		);
	}

	if (state.apiKeyPopoverOpen) {
		return (
			<div>
				<Label color="green">API Key</Label>
				<div style={{ marginTop: "10px" }}>{state.apiKeyValue}</div>

				<div style={{ marginTop: "20px" }}>
					<Button
						color="blue"
						onClick={() => {
							setState((_state) => ({
								..._state,
								apiKeyPopoverOpen: false,
							}));
						}}
					>
						Close
					</Button>
				</div>
			</div>
		);
	}

	if (state.templateWizardOpen) {
		return (
			<TemplateWizard
				{...props}
				mode={state.wizardMode}
				close={() => {
					refetchTemplates();
					setState((_state) => ({
						..._state,
						templateWizardOpen: false,
					}));
				}}
				organizationId={organizationId}
				template={state.template}
			/>
		);
	}

	const variables =
		variableGroups?.find((group) => group.Name === "Custom")?.Variables || [];

	const dataIsLoading =
		camerasAreLoading ||
		sitesAreLoading ||
		integrationsAreLoading ||
		requestsAreLoading ||
		variablesAreLoading ||
		apiKeysAreLoading ||
		templatesAreLoading ||
		false;

	return (
		<ErrorBoundary>
			<Switch>
				<Route
					exact={true}
					path={`${props.match.url}`}
					render={() => (
						<div>
							<ReportWrapperBeta
								{...props}
								title={`${props.context} Integrations`}
								data={integrations}
								columns={columns({
									isAdmin,
									isClientAdmin,
									openIntegrationWizard,
									context: props.context,
									sites,
									cameras,
								})}
								defaultSortBy={[
									{
										id: "IntegrationID",
										desc: false,
									},
								]}
								updateOptions={updateOptions}
								loading={dataIsLoading}
								showDateRangePicker={false}
								showSitePicker={false}
								showSearchBox={false}
								rightActions={
									<Button
										color="blue"
										style={{ marginLeft: 8 }}
										onClick={() => openIntegrationWizard("add")}
									>
										<Plus size={20} /> Add Integration
									</Button>
								}
							/>
						</div>
					)}
				/>
				<Route
					path={`${props.match.url}/api-keys`}
					render={() => (
						<div>
							<ReportWrapperBeta
								{...props}
								title={`${props.context} API Keys`}
								data={apiKeys}
								columns={columnsForApiKeys({
									...props,
									isAdmin,
									isClientAdmin,
									sites,
									openApiKeyWizard,
								})}
								defaultSortBy={[
									{
										id: "Name",
										desc: false,
									},
								]}
								updateOptions={updateOptions}
								loading={dataIsLoading}
								showDateRangePicker={false}
								showSitePicker={false}
								showSearchBox={false}
								rightActions={
									<Button
										color="blue"
										style={{ marginLeft: 8 }}
										onClick={() => openApiKeyWizard("add")}
									>
										<Plus size={20} /> Generate API Key
									</Button>
								}
							/>
						</div>
					)}
				/>
				<Route
					path={`${props.match.url}/requests`}
					render={() => (
						<div>
							<ReportWrapperBeta
								{...props}
								title={`${props.context} Requests`}
								data={requests}
								columns={columnsForRequests({
									isAdmin,
									isClientAdmin,
									openRequestWizard,
									variables,
								})}
								defaultSortBy={[
									{
										id: "RequestID",
										desc: false,
									},
								]}
								updateOptions={updateOptions}
								loading={dataIsLoading}
								showDateRangePicker={false}
								showSitePicker={false}
								showSearchBox={false}
								rightActions={
									<Button
										color="blue"
										style={{ marginLeft: 8 }}
										onClick={() => openRequestWizard("add")}
									>
										<Plus size={20} /> Add Request
									</Button>
								}
							/>
						</div>
					)}
				/>
				<Route
					path={`${props.match.url}/variables`}
					render={() => (
						<div>
							<ReportWrapperBeta
								{...props}
								title={`${props.context} Variables`}
								data={variables}
								columns={columnsForVariables({
									...props,
									isAdmin,
									isClientAdmin,
									openVariableWizard,
								})}
								defaultSortBy={[
									{
										id: "Name",
										desc: false,
									},
								]}
								updateOptions={updateOptions}
								loading={dataIsLoading}
								showDateRangePicker={false}
								showSitePicker={false}
								showSearchBox={false}
								rightActions={
									<Button
										color="blue"
										style={{ marginLeft: 8 }}
										onClick={() => openVariableWizard("add")}
									>
										<Plus size={20} /> Add Variable
									</Button>
								}
							/>
						</div>
					)}
				/>
				{props.context === "Account" && (
					<Route
						path={`${props.match.url}/templates`}
						render={() => (
							<div>
								<ReportWrapperBeta
									{...props}
									title="Templates"
									data={templates}
									columns={columnsForTemplates({
										...props,
										isAdmin,
										isClientAdmin,
										openTemplateWizard,
									})}
									defaultSortBy={[
										{
											id: "Name",
											desc: false,
										},
									]}
									updateOptions={updateOptions}
									loading={dataIsLoading}
									showDateRangePicker={false}
									showSitePicker={false}
									showSearchBox={false}
								/>
							</div>
						)}
					/>
				)}

				<Redirect to={`${props.match.url}`} />
			</Switch>
		</ErrorBoundary>
	);
}

export default IntegrationContainer;
