import { boxShadows, colours } from "../../styles";
import Alert from "react-s-alert";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
	.s-alert-box {
		border-radius: 4px;
		border-style: solid;
		border-width: 1px;
		box-shadow: ${boxShadows.standard};
		color: ${colours.darkGrey};
		font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
		font-size: 16px;
		opacity: ${(props) => (props.hidden ? 0 : 1)};
		line-height: 24px;
		max-width: 512px;
		min-width: 256px;
		padding: 16px 32px 16px 16px;

		&.s-alert-info {
			color: #004085;
			background-color: #cce5ff;
			border-color: #b8daff;
		}

		&.s-alert-success {
			color: #155724;
			background-color: #d4edda;
			border-color: #c3e6cb;
		}

		&.s-alert-warning {
			color: #856404;
			background-color: #fff3cd;
			border-color: #ffeeba;
		}

		&.s-alert-error {
			color: #721c24;
			background-color: #f8d7da;
			border-color: #f5c6cb;
		}

		.s-alert-close {
			position: absolute;
			right: 8px;
			top: 50%;
			transform: translateY(-50%);

			&:hover {
				opacity: 0.8;
			}

			&::before,
			&::after {
				background-color: ${colours.darkGrey};
			}
		}
	}
`;

export default function AlertStack(props) {
	return (
		<Wrapper>
			<Alert
				stack={{ limit: 3, spacing: 16 }}
				position="top-right"
				effect="stackslide"
				offset={64}
				timeout={4000}
				{...props}
			/>
		</Wrapper>
	);
}
