import React, { useState } from "react";
import { fetchDataCallback, paginatedState } from "../../../helpers/pagination";
import ReportWrapperBeta from "../ReportWrapperBeta";
import gql from "graphql-tag";
import moment from "moment";
import FlexWrapper from "../../layout/FlexWrapper";
import Card from "../../layout/Card";
import StatCard from "../../layout/StatCard";
import { useQueryData } from "../../../hooks";
import { defaultTimeFormat } from "../../../helpers/constants";

const defaultSorting = [
	{
		id: "CreatedOn",
		desc: false,
	},
];

export default function SignageQRCodesHistory(props) {
	const [state, setState] = useState({
		initialLoad: true,
		options: {
			"start-date-time": null,
			"end-date-time": null,
		},
		search: "",
		wizardOpen: false,
	});

	const [paginate, setPaginate] = useState(paginatedState);
	const fetchPaginatedData = fetchDataCallback(setPaginate);

	async function updateOptions({ search, ...options }) {
		setState((_state) => ({ ..._state, initialLoad: false, search, options }));
	}

	const columns = [
		{
			id: "UniqueCode",
			Header: "Unique Code",
			accessor: (d) => d.UniqueCode,
		},
		{
			id: "CreatedOn",
			Header: "Scanned",
			accessor: (d) =>
				d.CreatedOn ? moment(d.CreatedOn, "X").format(defaultTimeFormat) : "",
		},
		{
			id: "Type",
			Header: "Type",
			accessor: (d) => d.Type,
		},
		{
			id: "SiteName",
			Header: "Site",
			Cell: (cellProps) =>
				cellProps.row.original.Sites.map((site) => site.Name).join(", "),
		},
	];

	const skipQuery =
		!paginate.pageSize ||
		!paginate.sortBy ||
		!paginate.sortOrder ||
		!state.options["start-date-time"] ||
		!state.options["end-date-time"] ||
		!state.options.sites;

	const {
		data: { getQRCodeHistoryForOrganization },
		isLoading,
	} = useQueryData(
		gql`
			query (
				$siteIds: [Int!]
				$startTimestamp: Timestamp!
				$endTimestamp: Timestamp!
				$page: PageInfo!
				$cursor: Int
			) {
				getQRCodeHistoryForOrganization(
					siteIds: $siteIds
					startTimestamp: $startTimestamp
					endTimestamp: $endTimestamp
					page: $page
					cursor: $cursor
				) {
					previousEvent
					totalScans
					totalCodesScanned
					qrCodes {
						UniqueCode
						Type
						CreatedOn
						Sites {
							Name
						}
					}
				}
			}
		`,
		{
			siteIds: state.options.sites || [],
			startTimestamp: moment(state.options["start-date-time"])
				.startOf("day")
				.unix(),
			endTimestamp: moment(state.options["end-date-time"]).endOf("day").unix(),
			page: {
				pageOffset: paginate.pageOffset,
				pageSize: paginate.pageSize,
				sortBy: paginate.sortBy,
				sortOrder: paginate.sortOrder,
				searchTokens: paginate.searchTokens,
				eventType: paginate.eventType,
				sortedVal: paginate.cursor.sortedVal,
			},
			cursor: paginate.cursor.cursor,
		},
		skipQuery
	);

	const qrCodes = getQRCodeHistoryForOrganization?.qrCodes || [];
	const totalScans = getQRCodeHistoryForOrganization?.totalScans || 0;
	const totalCodesScanned =
		getQRCodeHistoryForOrganization?.totalCodesScanned || 0;

	return (
		<ReportWrapperBeta
			{...props}
			title="Scan History"
			data={qrCodes}
			columns={columns}
			defaultSortBy={defaultSorting}
			updateOptions={updateOptions}
			loading={state.submitting}
			cursorColumn="CreatedOn"
			paginationIsLoading={isLoading || skipQuery || props.isLoading}
			pageSize={state.pageSize}
			dataTrueLength={qrCodes.length}
			previousEvent={getQRCodeHistoryForOrganization?.previousEvent}
			searchTokens={paginate.searchTokens}
			fetchPaginatedData={fetchPaginatedData}
			stats={
				<FlexWrapper style={{ marginBottom: -16 }}>
					<Card style={{ flex: 0.3 }}>
						<StatCard
							value={totalCodesScanned}
							title="QR Codes Scanned"
							subTitle="Within Selected Dates"
							size="medium"
						/>
					</Card>
					<Card style={{ flex: 0.3 }}>
						<StatCard
							value={totalScans}
							title="Total Scans"
							subTitle="Within Selected Dates"
							size="medium"
						/>
					</Card>
				</FlexWrapper>
			}
		/>
	);
}
