import { MinusCircle, PlusCircle } from "react-feather";
import React, { useEffect, useState } from "react";
import { StepText, StepTitle } from "../WizardLayout";
import Button from "../../layout/Button";
import Dropdown from "../../layout/Dropdown";
import EditableInputField from "../../layout/EditableInputField";
import EmailLookup from "../EmailLookup";
import SummaryTable from "../SummaryTable";
import WizardNavigation from "../WizardNavigation";
import { colours } from "../../../styles";
import styled from "styled-components";

const Touchable = styled.div`
	:hover {
		cursor: pointer;
		opacity: 0.6;
	}
`;

const Placeholder = styled.div`
	width: 20px;
`;
// { id: "A", startRange: 1, endRange: maxSpaces }
const BayNumbering = ({ state, maxSpaces, onNumberingChange }) => {
	const [numbering, setNumbering] = useState(state);
	const [spacesLeft, setSpacesLeft] = useState(0);

	useEffect(() => {
		let tot = 0;
		numbering.forEach((n) => {
			tot += n.endRange + 1 - n.startRange;
		});
		setSpacesLeft(maxSpaces - tot);

		onNumberingChange(numbering);
	}, [numbering]);

	const onChange = (rowNum, newState) => {
		const newNumbering = [...numbering];
		newNumbering[rowNum] = { ...numbering[rowNum], ...newState };

		let tot = 0;
		newNumbering.forEach((n) => {
			tot += n.endRange + 1 - n.startRange;
		});

		const left = maxSpaces - tot;
		const { startRange, endRange } = newNumbering[rowNum];

		if (left < 0) {
			// normalize range.
			if (newState.startRange)
				newNumbering[rowNum].endRange = endRange - Math.abs(left);
			else newNumbering[rowNum].startRange = startRange + Math.abs(left);
		} else if (endRange - startRange < 0) {
			if (newState.startRange) newNumbering[rowNum].endRange = startRange;
			else if (newState.endRange) newNumbering[rowNum].startRange = endRange;
		}

		setNumbering(newNumbering);
	};

	const onAddRow = () => {
		const lastId = numbering[numbering.length - 1].id;
		const idLen = lastId.length;
		const newId =
			lastId.substring(0, idLen - 1) +
			String.fromCharCode(lastId.charCodeAt(idLen - 1) + 1);

		setNumbering((_existing) => {
			return _existing.concat({
				id: newId,
				startRange: 1,
				endRange: 1,
			});
		});
	};

	const removeRow = () => {
		setNumbering(numbering.slice(0, -1));
	};

	return numbering.map((n, index) => (
		<BayRow
			key={index}
			rowNum={index}
			{...n}
			displayPlusBtn={index === numbering.length - 1}
			disabled={spacesLeft <= 0}
			maxSpaces={maxSpaces}
			onChange={onChange}
			onAddRow={onAddRow}
			removeRow={removeRow}
		/>
	));
};

const BayRow = ({
	disabled,
	displayPlusBtn,
	endRange,
	id,
	onAddRow,
	onChange,
	removeRow,
	rowNum,
	startRange,
}) => {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "flex-start",
				alignItems: "baseline",
				gap: "20px",
				flexDirection: "row",
			}}
		>
			<p style={{ width: 80, textAlign: "center" }}>ID (Optional)</p>
			<EditableInputField
				type="string"
				name="id"
				useLabel=" "
				onChange={(event) => onChange(rowNum, { id: event.target.value })}
				style={{ backgroundColor: colours.background }}
				value={id}
			/>
			<div>Range</div>
			<EditableInputField
				type="number"
				name="rangeStart"
				useLabel=" "
				min={1}
				max={Number.MAX_SAFE_INTEGER}
				onChange={(event) => {
					const val = +event.target.value;
					onChange(rowNum, {
						startRange: val,
					});
				}}
				value={startRange}
				style={{ backgroundColor: colours.background }}
			/>
			<div>to</div>
			<EditableInputField
				type="number"
				name="rangeEnd"
				useLabel=" "
				min={1}
				max={Number.MAX_SAFE_INTEGER}
				onChange={(event) => {
					const val = +event.target.value;
					onChange(rowNum, {
						endRange: val,
					});
				}}
				value={endRange}
				style={{ backgroundColor: colours.background }}
			/>
			{rowNum > 0 && displayPlusBtn ? (
				<Touchable onClick={removeRow}>
					<MinusCircle size={20} color={`${colours.red}`} />
				</Touchable>
			) : (
				<Placeholder />
			)}
			{displayPlusBtn && !disabled ? (
				<Touchable onClick={onAddRow}>
					<PlusCircle size={20} color={`${colours.blue}`} />
				</Touchable>
			) : (
				<Placeholder />
			)}
		</div>
	);
};

export const initial = ({ values, wizardProps, setFieldValue, next, goTo }) => {
	const availableSpaces = values.spaces.filter(
		(space) => space.AvailableSpaces > 0
	);
	const options = availableSpaces.map((s) => ({
		label: s.Name,
		value: s.LeaseParkID,
	}));

	const selected = options.find(
		({ value }) => values.selectedSpace.leaseParkId === value
	);

	return {
		id: "initial",
		label: "Applies to",
		render: () => (
			<div>
				<StepTitle>
					Please choose the space that these bays will be associated with
				</StepTitle>
				<Dropdown
					isMulti={false}
					options={options}
					value={selected}
					onChange={(option) => {
						setFieldValue("selectedSpace", {
							leaseParkId: option.value,
							name: option.label,
						});
					}}
				/>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={[
					<Button key="cancel" color="blue" onClick={wizardProps.close}>
						Cancel
					</Button>,
				]}
				rightItems={[
					<Button
						disabled={!selected}
						key="submit"
						color="blue"
						onClick={() => {
							if (values.editFromSummary) {
								goTo("summary");
							} else {
								next();
							}
						}}
					>
						Next
					</Button>,
				]}
			/>
		),
	};
};

// And I cannot enter a number larger than the number of spaces minus the number of bays already existing for this space.

export const bayAmount = ({ values, setFieldValue, next, previous, goTo }) => {
	const selectedSpace = values.spaces.find(
		(s) => s.LeaseParkID === values.selectedSpace.leaseParkId
	);

	const minSpaces = 1;
	const maxSpaces = selectedSpace?.AvailableSpaces || minSpaces;

	return {
		id: "amount",
		label: "Bays",
		render: () => (
			<div>
				<StepTitle>How many bays are in this space?</StepTitle>
				<EditableInputField
					type="number"
					name="BayAmount"
					min={minSpaces}
					max={maxSpaces}
					useLabel=" "
					onChange={(event) => {
						let val = +event.target.value;
						if (val > maxSpaces) {
							val = maxSpaces;
						} else if (val < minSpaces) {
							val = minSpaces;
						}
						setFieldValue("numberOfBays", val);
						setFieldValue("numbering", []);
					}}
					value={values.numberOfBays == null ? "" : values.numberOfBays}
				/>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={[
					<Button key="cancel" color="blue" onClick={previous}>
						Back
					</Button>,
				]}
				rightItems={[
					<Button
						key="submit"
						color="blue"
						onClick={() => {
							if (values.editFromSummary) {
								goTo("summary");
							} else {
								next();
							}
						}}
					>
						Next
					</Button>,
				]}
			/>
		),
	};
};

export const bayNumbering = ({
	values,
	setFieldValue,
	next,
	previous,
	goTo,
}) => {
	const onNumberingChange = (numbering) => {
		setFieldValue("numbering", numbering);
	};

	const maxSpaces = values.numberOfBays;
	const state = values.numbering.length
		? values.numbering
		: [
				{
					id: "A",
					startRange: 1,
					endRange: maxSpaces,
				},
		  ];

	return {
		id: "numbering",
		label: "Numbering",
		render: () => (
			<div>
				<StepTitle>What is the numbering for these bays?</StepTitle>
				<BayNumbering
					state={state}
					maxSpaces={+maxSpaces}
					onNumberingChange={onNumberingChange}
				/>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={[
					<Button key="cancel" color="blue" onClick={previous}>
						Back
					</Button>,
				]}
				rightItems={[
					<Button
						key="submit"
						color="blue"
						onClick={() => {
							if (values.editFromSummary) {
								goTo("summary");
							} else {
								next();
							}
						}}
					>
						Next
					</Button>,
				]}
			/>
		),
	};
};

const formatBayNumbering = (numbering) => {
	let result = "";
	numbering.forEach(({ id, startRange, endRange }) => {
		result +=
			endRange - startRange === 0
				? id
					? `${id}-${startRange}, `
					: `${startRange}, `
				: id
				? `${id}-${startRange}-${id}-${endRange}, `
				: `${startRange}-${endRange}, `;
	});
	return result.substring(0, result.length - 2);
};

export const summaryStep = ({
	goTo,
	handleSubmit,
	isSubmitting,
	setFieldValue,
	values,
	keyStrokeHandler,
}) => ({
	id: "summary",
	label: "Summary",
	render: () => (
		<div>
			<StepTitle>Summary</StepTitle>
			<SummaryTable
				items={[
					{
						title: "Space",
						value: values.selectedSpace.name,
						key: "appliesTo",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("initial");
						},
					},
					{
						title: "Number of bays",
						value: values.numberOfBays,
						key: "amount",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("amount");
						},
					},
					{
						title: "Bay numbering",
						value: formatBayNumbering(values.numbering),
						key: "numbering",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("numbering");
						},
					},
				]}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					Create Bays
				</Button>,
			]}
		/>
	),
});

export function editNameStep({
	close,
	values,
	handleSubmit,
	isSubmitting,
	keyStrokeHandler,
	setFieldValue,
}) {
	return {
		id: "editName",
		label: "Edit Bay",
		render: () => {
			return (
				<div>
					<StepTitle>Enter new bay name</StepTitle>
					<EditableInputField
						type="string"
						name="id"
						useLabel=" "
						onChange={(event) => setFieldValue("name", event.target.value)}
						style={{ backgroundColor: colours.background }}
						value={values.name}
					/>
				</div>
			);
		},
		footer: () => {
			return (
				<WizardNavigation
					leftItems={[
						<Button key="previous" onClick={close} color="blue">
							Cancel
						</Button>,
					]}
					rightItems={[
						<Button
							key="submit"
							color="green"
							onClick={handleSubmit}
							disabled={isSubmitting || !values.name}
							keyStrokeHandler={keyStrokeHandler}
						>
							Edit
						</Button>,
					]}
				/>
			);
		},
	};
}

export function deleteStep({
	close,
	handleSubmit,
	isSubmitting,
	keyStrokeHandler,
}) {
	return {
		id: "edit",
		label: "Remove Bay",
		render: () => {
			return (
				<div>
					<StepText>
						<div style={{ textAlign: "center" }}>
							Are you sure you want to delete this bay. This action CANNOT be
							undone.
						</div>
					</StepText>
				</div>
			);
		},
		footer: () => {
			return (
				<WizardNavigation
					leftItems={[
						<Button key="previous" onClick={close} color="blue">
							Cancel
						</Button>,
					]}
					rightItems={[
						<Button
							key="submit"
							color="red"
							onClick={handleSubmit}
							disabled={isSubmitting}
							keyStrokeHandler={keyStrokeHandler}
						>
							Delete
						</Button>,
					]}
				/>
			);
		},
	};
}

export const assignUserStep = ({
	values,
	setFieldValue,
	goTo,
	next,
	isSubmitting,
	close,
	keyStrokeHandler,
}) => ({
	id: "assignToUser",
	label: "User",
	render: () => (
		<>
			<div>
				<StepTitle>What is the email address of the user?</StepTitle>
				<EmailLookup
					email={values.email}
					user={values.user}
					allowNew={false}
					onChange={(value) => {
						if (value.user && value.user.FirstName && value.user.LastName) {
							value.user.FirstName = value.user.FirstName.trim();
							value.user.LastName = value.user.LastName.trim();
						}

						setFieldValue("email", value.email);
						setFieldValue("user", value.user);

						if (value.user && value.user.UserID) {
							setFieldValue("userExists", true);
						} else {
							setFieldValue("userExists", false);
						}
					}}
				/>
			</div>
		</>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="previous" onClick={close} color="blue">
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					disabled={isSubmitting || !values.userExists}
					keyStrokeHandler={keyStrokeHandler}
				>
					Next
				</Button>,
			]}
		/>
	),
});

export const assignSummaryStep = ({
	goTo,
	handleSubmit,
	isSubmitting,
	setFieldValue,
	values,
	keyStrokeHandler,
}) => ({
	id: "summary",
	label: "Summary",
	render: () => (
		<div>
			<StepTitle>Summary</StepTitle>
			<SummaryTable
				items={[
					{
						title: "User",
						value: values.email,
						key: "user",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("assignToUser");
						},
					},
					{
						title: "Space",
						value: values.bay.ParkName,
						key: "space",
					},
					{
						title: "Bay",
						value: values.bay.Name,
						key: "bayName",
					},
				]}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					Assign Bay
				</Button>,
			]}
		/>
	),
});

export function unassignStep({
	values,
	close,
	handleSubmit,
	isSubmitting,
	keyStrokeHandler,
}) {
	return {
		id: "edit",
		label: "Remove Bay",
		render: () => {
			const {
				User: { FirstName, LastName, Email },
				Name,
			} = values.bay;
			return (
				<div>
					<StepText>
						<div style={{ textAlign: "center" }}>
							{`Are you sure you want to unassign ${FirstName} ${LastName} (${Email}) from this bay (${Name})?`}
						</div>
					</StepText>
				</div>
			);
		},
		footer: () => {
			return (
				<WizardNavigation
					leftItems={[
						<Button key="previous" onClick={close} color="blue">
							Cancel
						</Button>,
					]}
					rightItems={[
						<Button
							key="submit"
							color="red"
							onClick={handleSubmit}
							disabled={isSubmitting}
							keyStrokeHandler={keyStrokeHandler}
						>
							Unassign
						</Button>,
					]}
				/>
			);
		},
	};
}
