import * as Yup from "yup";
import * as api from "../../api";
import * as authenticationApi from "../../api/authentication";
import React, { useState } from "react";
import { useFetchData, useMutateData } from "../../hooks";
import AddressLookupField from "../../components/wizards/AddressLookupField";
import Alert from "react-s-alert";
import Button from "../../components/layout/Button";
import EditableInputField from "../../components/layout/EditableInputField";
import { Form } from "formik";
import FormLayout from "../../components/layout/FormLayout";
import Loader from "../../components/layout/Loader";
import LocalLink from "../../components/layout/LocalLink";
import PhoneNumberInput from "../../components/layout/PhoneNumberInput";
import Toggle from "../../components/layout/Toggle";
import gql from "graphql-tag";
import qs from "qs";
import { startsWith } from "lodash";
import styled from "styled-components";

function canSubmit(isSubmitting, errors, values) {
	return !(
		isSubmitting ||
		errors.email ||
		errors.firstName ||
		errors.lastName ||
		errors.password ||
		!values.email ||
		!values.firstName ||
		!values.lastName ||
		!values.password ||
		!values.isPhoneValid
	);
}

const InfoText = styled.div`
	text-align: justify;
	font-size: 18px;
	line-height: 1.3em;
`;

const schema = Yup.object().shape({
	email: Yup.string()
		.email("Please supply a valid email.")
		.required("Please supply an email."),
	firstName: Yup.string().required("Please supply a first name."),
	lastName: Yup.string().required("Please supply a last name."),
	password: Yup.string().required("Please supply a password."),
	phone: Yup.string().required("Please supply a phone number."),
});

const LinkWrapper = styled.div`
	display: block;
	margin: 64px 0 32px;
	text-align: center;
`;

const AddressWrapper = styled.div`
	margin: 16px 0;
`;

const ButtonWrapper = styled.div`
	margin: 16px 0;
`;

export default function RegisterForm(props) {
	const [state, setState] = useState({
		redeemInvite: false,
		useBillingAddress: false,
	});

	const registerAndLoginMutation = useMutateData(gql`
		mutation (
			$email: Email!
			$firstName: String!
			$lastName: String!
			$phone: String!
			$password: Password
			$passwordForLogin: String
			$countryCode: String!
			$invitationCode: String
		) {
			register(
				user: {
					email: $email
					firstName: $firstName
					lastName: $lastName
					phone: $phone
					password: $password
					countryCode: $countryCode
					invitationCode: $invitationCode
				}
			) {
				UserID
			}

			login(email: $email, password: $passwordForLogin) {
				SessionKey
			}
		}
	`);

	const options = qs.parse(props.location.search, { ignoreQueryPrefix: true });

	if (!options.email || !options.code) {
		props.history.push("/login");
	}

	const { isLoading: userInvitationLoading, isError: userInvitationError } =
		useFetchData({}, authenticationApi.getUserInvitation, [options], []);

	const { data: existingUserData, isLoading: existingUserLoading } =
		useFetchData(
			{},
			authenticationApi.userExistsForEmailAndCode,
			[options.email, options.code],
			[]
		);

	// redeem the permissions if there is an existing account already
	if (!state.redeemInvite && existingUserData.accountExists) {
		setState((_state) => ({ ..._state, redeemInvite: true }));

		authenticationApi
			.redeemInvite({ email: options.email, code: options.code })
			.then(() => {
				Alert.success(
					"Your Inugo invitation has been redeemed. Redirecting you to login..."
				);

				setTimeout(() => {
					props.history.push("/login");
				}, 1000);
			})
			.catch((error) => {
				if (error && error.errors) {
					if (error && error.errors[0].code === "CodeAlreadyRedeemed") {
						Alert.error("This invitation has already been redeemed.");
					}

					setTimeout(() => {
						props.history.push("/login");
					}, 1000);

					return;
				}

				Alert.error("Something went wrong. Please try again.");
			});
	}

	const onSubmit = async (values, { setSubmitting }) => {
		try {
			setSubmitting(true);
			const registerVariables = {
				firstName: values.firstName,
				lastName: values.lastName,
				email: values.email,
				password: values.password,
				passwordForLogin: values.password,
				phone: values.phoneWithoutCountryCode,
				countryCode: values.countryCode,
				invitationCode: options.code,
			};

			const { data } = await registerAndLoginMutation({
				variables: registerVariables,
			});
			const sessionKey = data.login.SessionKey;

			if (sessionKey) {
				api.setSessionKey(sessionKey);
			}

			window.location.href = "/";
		} catch (error) {
			setSubmitting(false);

			Alert.error(
				"The email and password you provided doesn't match an existing account."
			);
		}
	};

	if (userInvitationLoading || existingUserLoading || state.redeemInvite) {
		return (
			<div style={{ margin: "64px 0" }}>
				<Loader />
			</div>
		);
	}

	if (userInvitationError) {
		return (
			<div style={{ margin: "64px 64px" }}>
				<InfoText>
					This invitation link can no longer be used as it has expired. Please
					contact the inugo admin for further assistance.
				</InfoText>
				<LinkWrapper>
					<LocalLink to="/login">Go to login</LocalLink>
				</LinkWrapper>
			</div>
		);
	}

	return (
		<FormLayout
			initialValues={{
				email: options.email,
				firstName: "",
				lastName: "",
				password: "",
				phone: "",
				phoneWithoutCountryCode: "",
				isPhoneValid: false,
				countryCode: "us",
				accountName: "",
				accountAddress: "",
				accountBillingAddress: "",
			}}
			validationSchema={schema}
			onSubmit={onSubmit}
			render={({ values, errors, touched, isSubmitting, setValues }) => (
				<Form className="form">
					<EditableInputField
						type="email"
						name="email"
						useLabel="Email"
						error={errors.email && touched.email ? errors.email : null}
						disabled={true}
					/>

					<EditableInputField
						name="firstName"
						useLabel="First name"
						error={
							errors.firstName && touched.firstName ? errors.firstName : null
						}
					/>

					<EditableInputField
						name="lastName"
						useLabel="Last name"
						error={errors.lastName && touched.lastName ? errors.lastName : null}
					/>

					<EditableInputField
						type="password"
						name="password"
						useLabel="Password"
						error={errors.password && touched.password ? errors.password : null}
					/>

					<PhoneNumberInput
						style={{ marginBottom: "50px" }}
						name="phone"
						country={"us"}
						value={values.phone}
						onlyCountries={["us", "nz", "au", "ca", "gb"]}
						isValid={(inputNumber, country, onlyCountries) => {
							return onlyCountries.some((_country) =>
								startsWith(inputNumber, _country.dialCode)
							);
						}}
						onChange={(phone, country) => {
							let phoneWithoutCountryCode = "";
							let isPhoneValid =
								startsWith(phone.replace("+", ""), country.dialCode) &&
								phone.replace("+", "").length > country.dialCode.length;

							if (isPhoneValid) {
								phoneWithoutCountryCode = phone
									.replace(/[^0-9]+/g, "")
									.slice(country.dialCode.length);
							}

							setValues({
								...values,
								phone: phone,
								phoneWithoutCountryCode: phoneWithoutCountryCode,
								countryCode: country.countryCode,
								isPhoneValid: isPhoneValid,
							});
						}}
					/>

					{options.validate === "1" && (
						<AddressWrapper>
							<EditableInputField
								name="accountName"
								useLabel="Account Name"
								error={
									errors.accountName && touched.accountName
										? errors.accountName
										: null
								}
							/>

							<AddressWrapper>
								<AddressLookupField name="accountAddress" />
							</AddressWrapper>

							<Toggle
								label={"Use Separate Billing Address"}
								checked={state.useBillingAddress}
								onChange={(event) =>
									setState((_state) => ({
										..._state,
										useBillingAddress: event,
									}))
								}
							/>

							{state.useBillingAddress && (
								<AddressWrapper>
									<AddressLookupField name="accountBillingAddress" />
								</AddressWrapper>
							)}
						</AddressWrapper>
					)}

					<ButtonWrapper>
						<Button
							type="submit"
							color="green"
							disabled={!canSubmit(isSubmitting, errors, values)}
						>
							Register
						</Button>
					</ButtonWrapper>

					<LinkWrapper>
						<LocalLink to="/login">Go to login</LocalLink>
					</LinkWrapper>
				</Form>
			)}
		/>
	);
}
