import { Formik } from "formik";
import React from "react";

export default function FormLayout(props) {
	const { render, ...rest } = props;
	return (
		<div>
			<Formik {...rest}>{render}</Formik>
		</div>
	);
}
