import { StepText, StepTitle } from "../WizardLayout";
import BeaconLookup from "../../wizards/BeaconLookup";
import Button from "../../layout/Button";
import Dropdown from "../../layout/Dropdown";
import React from "react";
import TableLayout from "../../layout/TableLayout";
import WizardNavigation from "../WizardNavigation";
import { colours } from "../../../styles";
import styled from "styled-components";

const Wrapper = styled.div`
	a {
		color: ${colours.blue};
		text-decoration: none;
		transition: 0.2s ease;

		&:hover {
			cursor: pointer;
			opacity: 0.8;
		}
	}
`;

const FieldWrapper = styled.div`
	margin: 32px 0;
`;

const FieldContent = styled.div`
	> * {
		margin: 0;
		max-width: 600px;
		min-height: 32px;
	}
`;

const Label = styled.div`
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 4px;
`;

function BeaconSites(props) {
	const data = props.beacon.Sites || [];

	return (
		<Wrapper>
			{data.length > 0 && (
				<TableLayout
					data={data}
					columns={[
						{
							id: "Site",
							Header: "Site",
							accessor: "Name",
							resizable: false,
						},
						{
							id: "Edit",
							Header: "",
							Cell: (d) => (
								<a
									href={`/operator/${props.operator.OperatorID}/organization/${d.original.OrganizationID}/site/${d.original.SiteID}/beacons`}
								>
									Edit Settings
								</a>
							),
							resizable: false,
						},
					]}
					sortable={false}
				/>
			)}
			{!data.length && <StepText>This beacon is not in use.</StepText>}
		</Wrapper>
	);
}

export const deleteBeaconStep = ({
	handleSubmit,
	isSubmitting,
	values,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "deleteBeacon",
	label: "Delete Beacon",
	render: () => (
		<div>
			<StepText>
				Are you sure you want to delete beacon <b>{values.uniqueId}</b>?
			</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					Delete
				</Button>,
			]}
		/>
	),
});

export const addBeaconStep = ({
	handleSubmit,
	isSubmitting,
	values,
	setFieldValue,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "addBeacon",
	label: "Add Beacon",
	render: () => (
		<div>
			<StepTitle>What is the Unique ID of the beacon?</StepTitle>
			<BeaconLookup
				uniqueId={values.uniqueId || ""}
				siteId={wizardProps.siteId}
				mode={"beaconSite"}
				onChange={(value) => {
					setFieldValue("uniqueId", value.uniqueId);
					setFieldValue("uniqueIdValid", value.uniqueIdValid);
					setFieldValue("uniqueIdDuplicate", value.uniqueIdDuplicate);
				}}
			/>
			{values.uniqueIdDuplicate && (
				<div style={{ color: colours.red, marginTop: 16 }}>
					This beacon has already been added to this site.
				</div>
			)}
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={
						isSubmitting || !values.uniqueIdValid || values.uniqueIdDuplicate
					}
					keyStrokeHandler={keyStrokeHandler}
				>
					Add
				</Button>,
			]}
		/>
	),
});

export const addAdminBeaconStep = ({
	handleSubmit,
	isSubmitting,
	values,
	setFieldValue,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "addAdminBeacon",
	label: "Add Beacon",
	render: () => (
		<div>
			<StepTitle>What is the Unique ID of the beacon?</StepTitle>
			<BeaconLookup
				uniqueId={values.uniqueId || ""}
				mode={"adminBeacon"}
				onChange={(value) => {
					setFieldValue("uniqueId", value.uniqueId);
					setFieldValue("uniqueIdValid", value.uniqueIdValid);
					setFieldValue("uniqueIdDuplicate", value.uniqueIdDuplicate);
				}}
			/>
			{values.uniqueIdDuplicate && (
				<div style={{ color: colours.red, marginTop: 16 }}>
					This beacon has already been added.
				</div>
			)}
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={
						isSubmitting || !values.uniqueIdValid || values.uniqueIdDuplicate
					}
					keyStrokeHandler={keyStrokeHandler}
				>
					Add
				</Button>,
			]}
		/>
	),
});

export const adminEditBeaconStep = ({
	handleSubmit,
	isSubmitting,
	setFieldValue,
	values,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "adminEditBeacon",
	label: "Edit Beacon",
	render: () => (
		<div>
			<StepTitle>
				{wizardProps.beacon.BeaconDeviceID}
				{wizardProps.beacon.UniqueID && ` (${wizardProps.beacon.UniqueID})`}
			</StepTitle>

			<FieldWrapper>
				<Label>Status</Label>
				<FieldContent>
					<Dropdown
						options={Object.keys(wizardProps.hardwareStatuses).map(
							(status) => ({
								value: status,
								label: wizardProps.hardwareStatuses[status],
							})
						)}
						value={{
							value: values.status,
							label: wizardProps.hardwareStatuses[values.status],
						}}
						onChange={(value) => {
							setFieldValue("status", value.value);
						}}
					/>
				</FieldContent>
			</FieldWrapper>

			{wizardProps.currentUserIsAdmin && (
				<FieldWrapper>
					<Label>Client Ownership</Label>

					<FieldContent>
						<Dropdown
							isClearable={true}
							onChange={(value) =>
								setFieldValue("clientId", value ? value.value : null)
							}
							options={wizardProps.clients}
							value={wizardProps.clients.find(
								(e) => e.value === values.clientId
							)}
						/>
					</FieldContent>
				</FieldWrapper>
			)}

			<BeaconSites
				operator={wizardProps.operator}
				beacon={wizardProps.beacon}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					Update
				</Button>,
			]}
		/>
	),
});
