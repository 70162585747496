import React, { useCallback } from "react";
import AsyncSelect from "react-select/async";
// import { debounce } from "lodash";
import gql from "graphql-tag";
import { useLazyQueryData } from "../../hooks";

export default function InstallationLookup(props) {
	const getInstallations = useLazyQueryData(
		gql`
			query ($filter: String!) {
				getInstallationsByFilter(filter: $filter) {
					InstallationID
					Name
					Address
				}
			}
		`
	);

	const getOptions = async (filter) => {
		const {
			data: { getInstallationsByFilter },
		} = await getInstallations({ filter });

		return getInstallationsByFilter;
	};

	const loadOptions = async (inputValue) => {
		const options = await getOptions(inputValue);

		return options.map((i) => ({
			value: i.InstallationID,
			label: `${i.Name} - ${i.Address} (${i.InstallationID})`,
		}));
	};

	const loadSuggestions = useCallback(loadOptions, []);

	const handleChange = (installation) => {
		props.onChange(installation.value);
	};

	return (
		<div>
			<AsyncSelect
				loadOptions={loadSuggestions}
				placeholder={"Search by name or address..."}
				onChange={handleChange}
				defaultOptions={[]}
				defaultValue={[]}
			/>
		</div>
	);
}
