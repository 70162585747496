import * as systemApi from "../../../api/system";
import React, { useState } from "react";
import { fetchDataCallback, paginatedState } from "../../../helpers/pagination";
import { usePermissions, useQueryData } from "../../../hooks";
import Alert from "react-s-alert";
import Button from "../../layout/Button";
import { RefreshCw } from "react-feather";
import ReportWrapperBeta from "../ReportWrapperBeta";
import { booleanValueFormatter } from "../../../components/layout/TableLayout";
import { format } from "../../../helpers";
import gql from "graphql-tag";
import { map } from "lodash";
import styled from "styled-components";

const Wrapper = styled.div``;

const defaultSorting = [
	{
		id: "UpdatedOn",
		desc: true,
	},
];

export default function Installations(props) {
	let installations = [];
	const [state, setState] = useState({
		initialLoad: true,
		search: "",
	});

	const [paginate, setPaginate] = useState(paginatedState);

	const fetchPaginatedData = fetchDataCallback(setPaginate);

	async function syncDeviceService() {
		setState((_state) => ({ ..._state, submitting: true }));

		try {
			await systemApi.syncDeviceService();

			Alert.success("Device Service Successfully Synced");
			setState((_state) => ({ ..._state, submitting: false }));
		} catch (error) {
			Alert.error("Something went wrong. Please try again.");
			setState((_state) => ({ ..._state, submitting: false }));
		}
	}

	const isAdmin = usePermissions("IsAdmin");
	const isCSAdmin = usePermissions("IsCSAdmin");
	const isSuperAdmin = isAdmin && !isCSAdmin;

	const skipQuery =
		!paginate.pageSize || !paginate.sortBy || !paginate.sortOrder;

	const {
		data: { getInstallationsPaginated },
		isLoading,
	} = useQueryData(
		gql`
			query ($page: PageInfo!, $cursor: Int) {
				getInstallationsPaginated(page: $page, cursor: $cursor) {
					trueLength
					installations {
						InstallationID
						Name
						Description
						Address
						CreatedOn
						UpdatedOn
						TicketManufacturer
						GateManufacturer
						PreAuthEnabled
						InugoPublicECKeyID
						InstallationECKeyID
						SiteID
						ClientID
						RankingScore
						Sites {
							SiteID
							OrganizationID
							Name
							IsDeleted
						}
					}
					previousEvent
				}
			}
		`,
		{
			page: {
				next: paginate.next,
				pageOffset: paginate.pageOffset,
				pageSize: paginate.pageSize,
				sortBy: paginate.sortBy,
				sortOrder: paginate.sortOrder,
				searchTokens: paginate.searchTokens,
				eventType: paginate.eventType,
				sortedVal: paginate.cursor.sortedVal,
			},
			cursor: paginate.cursor.cursor,
		},
		skipQuery
	);

	async function updateOptions({ search, ...options }) {
		setState((_state) => ({ ..._state, initialLoad: false, search, options }));
	}

	const clientMap = isAdmin
		? props.clients.reduce((res, val) => {
				res[val.value] = val.label;
				return res;
		  }, {})
		: {};

	const columns = [
		{
			id: "Name",
			Header: "Name",
			accessor: (d) => d.Name || "",
			highlightCell: (cellProps) => cellProps.row.original.Name || "",
			className: "bold",
		},
		{
			id: "Address",
			Header: "Address",
			accessor: (d) => d.Address || "",
			highlightCell: (cellProps) => cellProps.row.original.Address || "",
		},
		{
			id: "TicketManufacturer",
			Header: "Ticket Manufacturer",
			accessor: (d) => d.TicketManufacturer || "",
			highlightCell: (cellProps) =>
				cellProps.row.original.TicketManufacturer || "",
		},
		{
			id: "GateManufacturer",
			Header: "Gate Manufacturer",
			accessor: (d) => d.GateManufacturer || "",
			highlightCell: (cellProps) =>
				cellProps.row.original.GateManufacturer || "",
		},
		{
			id: "PreAuthEnabled",
			Header: "Pre-auth Enabled",
			accessor: (d) => d.PreAuthEnabled,
			Cell: booleanValueFormatter,
		},

		isAdmin
			? {
					id: "ClientName",
					Header: "Client",
					accessor: (d) => clientMap[d.ClientID] || "",
					highlightCell: (cellProps) =>
						clientMap[cellProps.row.original.ClientID] || "",
					className: "bold",
			  }
			: null,
		{
			id: "Sites",
			Header: "Sites",
			accessor: (d) => d.siteNamesAccessor || "",
			Cell: (cellProps) => cellProps.row.original.siteNamesDisplay,
		},
		{
			id: "UpdatedOn",
			Header: "Updated On",
			accessor: (d) => d.UpdatedOn || "",
			Cell: (cellProps) =>
				cellProps.row.original.UpdatedOn
					? format.localDate(cellProps.row.original.UpdatedOn)
					: "",
		},
	].filter((e) => e);

	if (getInstallationsPaginated && getInstallationsPaginated.installations) {
		installations = getInstallationsPaginated.installations.map(
			(installation) => {
				return {
					...installation,
					siteNamesDisplay: map(installation.Sites, (site) =>
						site.IsDeleted ? `${site.Name} (Deleted)` : site.Name
					).join(", "),
					siteNamesAccessor: map(installation.Sites, (site) => site.Name).join(
						", "
					),
				};
			}
		);
	}

	const trueLength = getInstallationsPaginated?.trueLength || 0;

	return (
		<Wrapper>
			<ReportWrapperBeta
				{...props}
				title="Installations"
				data={installations}
				columns={columns}
				fetchPaginatedData={fetchPaginatedData}
				defaultSortBy={defaultSorting}
				updateOptions={updateOptions}
				loading={false}
				showDateRangePicker={false}
				showSitePicker={false}
				rightActions={
					isSuperAdmin ? (
						<Button color="blue" onClick={syncDeviceService}>
							<RefreshCw size={20} /> Sync Device Service
						</Button>
					) : null
				}
				dataTrueLength={trueLength}
				cursorColumn="InstallationID"
				paginationIsLoading={isLoading || skipQuery || props.isLoading}
				pageSize={state.pageSize}
				previousEvent={getInstallationsPaginated?.previousEvent}
				searchTokens={paginate.searchTokens}
			/>
		</Wrapper>
	);
}
