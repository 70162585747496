import * as billingApi from "../../api/billing";
import * as installationApi from "../../api/installation";
import { MoreHorizontal, Plus } from "react-feather";
import React, { useState } from "react";
import { map, sortBy } from "lodash";
import Accordion from "../../components/layout/Accordion";
import Alert from "react-s-alert";
import Button from "../../components/layout/Button";
import Card from "../../components/layout/Card";
import DropdownMenu from "../../components/layout/DropdownMenu";
import EditableInputField from "../../components/layout/EditableInputField";
import FormLayout from "../../components/layout/FormLayout";
import InvoicesWizard from "../../components/wizards/invoices-wizard/InvoicesWizard";
import Label from "../../components/layout/Label";
import TableLayout from "../../components/layout/TableLayout";
import Toggle from "../../components/layout/Toggle";
import { colours } from "../../styles";
import moment from "moment";
import styled from "styled-components";
import { useFetchData } from "../../hooks";
import { user as userHelpers } from "../../helpers";

const FieldLabel = styled.div`
	flexgrow: 1;
	font-size: 18px;
	font-weight: 700;
	margin: 20px 15px 0 0;
`;

const Year = styled.div`
	font-size: 2.5em;
	font-weight: 100;
	margin: 0 0 10px 13px;
`;

const FieldWrapper = styled.div`
	margin: 16px 32px;

	textarea {
		margin-top: 20px;
	}
`;

const FieldContent = styled.div`
	> * {
		margin: 0;
		max-width: 600px;
		min-height: 32px;
	}
`;

const ToggleWrapper = styled.div`
	margin-left: 0;
	margin-top: 10px;
`;

const columns = (props) => [
	{
		id: "Month",
		Header: "Month",
		accessor: "Month",
	},
	{
		id: "Type",
		Header: "Type",
		accessor: "_invoiceType",
	},
	{
		id: "TotalFee",
		Header: "Total Fee",
		accessor: "_totalFee",
	},
	{
		id: "CreatedOn",
		Header: "Created On",
		accessor: "_createdOn",
	},
	{
		id: "Sent On",
		Header: "Sent On",
		accessor: "_sentOn",
	},
	{
		id: "HasConsolidated",
		Header: "",
		accessor: null,
		Cell: (cellProps) =>
			cellProps.original.HasConsolidated ? (
				<Label color="green">Consolidated</Label>
			) : null,
	},
	{
		id: "tasks",
		Header: "",
		accessor: null,
		style: { color: `${colours.darkGrey}` },
		Cell: (cellProps) =>
			!cellProps.original._sentOn ? (
				<DropdownMenu
					triggerContent={<MoreHorizontal size={24} />}
					items={[
						<div
							key="invoice"
							onClick={async () => {
								const url = await billingApi.getInvoicePdfUrl(
									cellProps.original.SiteID || 0,
									cellProps.original.InvoiceID,
									cellProps.original.InvoiceAccessKey
								);
								window.open(url, "_blank");
							}}
						>
							Download Invoice
						</div>,
						<div
							key="trabsaction"
							onClick={async () => {
								const url = await billingApi.getTransactionPdfUrl(
									cellProps.original.SiteID,
									cellProps.original.InvoiceID,
									cellProps.original.InvoiceAccessKey
								);
								window.open(url, "_blank");
							}}
						>
							Download Transaction Details
						</div>,
						<div
							key="send"
							onClick={() => {
								if (props.invoicingEmail) {
									props.openInvoicesWizard(cellProps.original, "send-invoice");
								} else {
									Alert.error("Please set a valid email address first.");
								}
							}}
						>
							Send to Operator
						</div>,
						<div
							key="delete"
							onClick={() =>
								props.openInvoicesWizard(cellProps.original, "delete-invoice")
							}
						>
							Delete
						</div>,
					]}
				/>
			) : (
				<DropdownMenu
					triggerContent={<MoreHorizontal size={24} />}
					items={[
						<div
							key="invoice"
							onClick={async () => {
								window.location.href = await billingApi.getInvoicePdfUrl(
									cellProps.original.SiteID,
									cellProps.original.InvoiceID,
									cellProps.original.InvoiceAccessKey
								);
							}}
						>
							Download Invoice
						</div>,
						<div
							key="trabsaction"
							onClick={async () => {
								window.location.href = await billingApi.getTransactionPdfUrl(
									cellProps.original.SiteID,
									cellProps.original.InvoiceID,
									cellProps.original.InvoiceAccessKey
								);
							}}
						>
							Download Transaction Details
						</div>,
						<div
							key="send"
							onClick={() => {
								if (props.invoicingEmail) {
									props.openInvoicesWizard(cellProps.original, "send-invoice");
								} else {
									Alert.error("Please set a valid email address first.");
								}
							}}
						>
							Send to Operator
						</div>,
					]}
				/>
			),
		resizable: false,
		width: 50,
	},
];

export default function InvoiceContainer(props) {
	const siteId = props.selectedSite.SiteID;

	const [state, setState] = useState({
		invoicesWizardOpen: false,
		invoiceToggle: false,
	});

	const { data: site } = useFetchData(
		[],
		installationApi.getSite,
		[siteId],
		[state.invoiceToggle, siteId]
	);

	const { data: invoicesData, isLoading: invoicesLoading } = useFetchData(
		[],
		billingApi.getInvoicesForSite,
		[siteId],
		[state.invoicesWizardOpen, state.invoiceToggle, siteId]
	);

	let invoicesGrouped = [];

	if (invoicesData.length) {
		//Resort invoices by start date
		let invoices = sortBy(invoicesData, (invoice) => {
			return invoice.StartDate;
		});

		//Iterate over invoices and get their start/end months as text
		let insertIndex = 0;
		let lastYear = String(invoices[0].StartDate).substring(0, 4);

		for (let index in invoices) {
			const invoice = invoices[index];
			const year = String(invoice.StartDate).substring(0, 4);

			invoice.Month = moment(invoice.StartDate, "YYYYMMDD").format("MMMM");

			if (invoice.InvoiceType == "ServiceFees") {
				invoice._invoiceType = "Transaction Fees";
			} else {
				invoice._invoiceType = "Disbursement";
			}

			if (year !== lastYear) {
				insertIndex++;
				lastYear = year;
			}

			invoice._year = year;

			if (typeof invoicesGrouped[insertIndex] === "undefined") {
				invoicesGrouped.push({ year: year, invoices: [] });
			}

			invoicesGrouped[insertIndex].invoices.push(invoice);
		}
	}

	async function createInvoices() {
		Alert.info("Creating...");

		const invoices = await billingApi.createInvoicesForSite(
			siteId,
			props.selectedOrganization.AccountID
		);

		if (invoices.filter((invoice) => invoice.created).length) {
			Alert.success("Invoices created");
			setState((_state) => ({
				..._state,
				invoiceToggle: !state.invoiceToggle,
			}));
		} else {
			Alert.closeAll();
			Alert.warning("No new invoices created");
		}
	}

	function openInvoicesWizard(invoice, mode) {
		setState((_state) => ({
			..._state,
			invoicesWizardOpen: true,
			invoice: invoice,
			mode: mode,
		}));
	}

	if (state.invoicesWizardOpen) {
		return (
			<InvoicesWizard
				close={() => {
					setState((_state) => ({ ..._state, invoicesWizardOpen: false }));
				}}
				invoice={state.invoice}
				mode={state.mode}
				invoiceEmail={site.InvoicingEmail}
				siteId={siteId}
			/>
		);
	}
	return (
		<React.Fragment>
			<FormLayout
				enableReinitialize={true}
				initialValues={{
					billingAddress: props.selectedOrganization.BillingAddress,
					invoicingEmail: props.selectedSite.InvoicingEmail, // TODO: this isn't fetched?
					consolidateInvoices: !!props.selectedOrganization.ConsolidateInvoices,
				}}
				validationSchema={{}}
				onSubmit={async (values, { setSubmitting }) => {
					setSubmitting(true);
					try {
						const updateData = {
							AccountID: props.selectedOrganization.AccountID,
							InvoicingEmail: values.invoicingEmail || null,
							OrganizationBillingAddress: values.billingAddress || null,
							ConsolidateInvoices: values.consolidateInvoices,
						};

						if (
							updateData.InvoicingEmail &&
							!userHelpers.emailValidator(updateData.InvoicingEmail)
						) {
							Alert.error("Please enter a valid email address");
							setSubmitting(false);
							return;
						}

						if (
							updateData.ConsolidateInvoices &&
							!updateData.OrganizationBillingAddress
						) {
							Alert.error("Please enter a valid billing address address");
							setSubmitting(false);
							return;
						}

						await installationApi.updateSite(siteId, updateData);

						setSubmitting(false);
						Alert.success("Invoice settings saved");
						setState((_state) => ({
							..._state,
							invoiceToggle: !state.invoiceToggle,
						}));
					} catch (error) {
						setSubmitting(false);
					}
				}}
				render={({
					values,
					handleBlur,
					handleChange,
					handleSubmit,
					isSubmitting,
					setFieldValue,
				}) => (
					<form
						className="form"
						onSubmit={(event) => {
							event.preventDefault();
						}}
					>
						<Card>
							<Accordion title="Settings" expanded={false}>
								<FieldWrapper>
									<FieldLabel>Invoice Email</FieldLabel>
									<FieldContent>
										<EditableInputField
											type="text"
											name="invoicingEmail"
											value={values.invoicingEmail || ""}
											onChange={handleChange}
											error={
												values.invoicingEmail &&
												!userHelpers.emailValidator(values.invoicingEmail)
													? "Invalid email"
													: null
											}
										/>
									</FieldContent>
								</FieldWrapper>
								<FieldWrapper>
									<FieldLabel>Billing Address</FieldLabel>
									<FieldContent>
										<EditableInputField
											textArea={true}
											name="billingAddress"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.billingAddress || ""}
											useLabel=" "
											error={
												values.consolidateInvoices && !values.billingAddress
													? "This field is required when consolidating invoices"
													: null
											}
											disabled={!values.consolidateInvoices}
										/>
									</FieldContent>
								</FieldWrapper>
								<FieldWrapper>
									<FieldLabel>Consolidate Invoices</FieldLabel>
									<FieldContent>
										<ToggleWrapper>
											<Toggle
												onChange={(value) => {
													setFieldValue("consolidateInvoices", value);
												}}
												checked={values.consolidateInvoices}
											/>
										</ToggleWrapper>
									</FieldContent>
								</FieldWrapper>
								<FieldWrapper>
									<Button
										color="blue"
										onClick={handleSubmit}
										disabled={isSubmitting}
									>
										Save
									</Button>
								</FieldWrapper>
							</Accordion>
						</Card>
					</form>
				)}
			/>

			<Card style={{ padding: "16px 50px 50px 50px" }}>
				<div style={{ textAlign: "right", margin: "10px 0px 50px 0px" }}>
					<Button color="blue" onClick={createInvoices}>
						<Plus style={{ marginRight: 4, verticalAlign: "sub" }} size={20} />
						Create Invoices
					</Button>
				</div>
				{invoicesData.length ? (
					<div style={{ marginTop: "45px" }}>
						{map(invoicesGrouped, (group) => (
							<div key={group.year}>
								<Year>{group.year}</Year>
								<TableLayout
									{...props}
									style={{ marginLeft: "15px" }}
									data={group.invoices}
									loading={invoicesLoading}
									columns={columns({
										openInvoicesWizard: openInvoicesWizard,
										invoicingEmail: site.InvoicingEmail,
									})}
									sortable={false}
									selectedZone={state.selectedZone}
								/>
							</div>
						))}
					</div>
				) : (
					<div style={{ marginTop: "45px" }}>
						No invoices have been created yet.
					</div>
				)}
			</Card>
		</React.Fragment>
	);
}
