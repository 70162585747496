import { MoreHorizontal, Plus } from "react-feather";
import React, { useState } from "react";
import Button from "../../layout/Button";
import {
	useGetAllEventSeries,
	useSearchEvents,
} from "../../../containers/organization/queries/eventsApiHooks";
import EventsWizard from "../../wizards/events-wizard/EventsWizard";
import { forEach, isEmpty } from "lodash";
import moment from "moment";
import ReportWrapperBeta from "../ReportWrapperBeta";
import { PAGINATION_EVENTS } from "../../../helpers/constants";
import ReportPicker from "../ReportPicker";
import DropdownMenu from "../../layout/DropdownMenuBeta";
import EventCreateOrEditWizard from "../../wizards/event-create-or-edit-wizard/EventCreateOrEditWizard";
import ViewEventWizard from "../../wizards/view-event-wizard/ViewEventWizard";
import EventSeriesDetails from "../../details-pages/eventSeries/EventSeriesDetails";
import { EVENT_WIZARD_MODES } from "../../wizards/events-wizard/EventWizard.constants";

const defaultPageSize = 10;
const defaultDateRangeStart = moment().startOf("day").toDate();
const defaultDateRangeEnd = moment().add(12, "months").startOf("day").toDate();

export default function EventManagement(props) {
	//Search and filters for the base report wrapper page
	const [searchQuery, setSearchQuery] = useState("");
	const [selectedSiteIds, setSelectedSiteIds] = useState([]);
	const [selectedSeriesIds, setSelectedSeriesIds] = useState([]);
	const [startTimestamp, setStartTimestamp] = useState(defaultDateRangeStart);
	const [endTimestamp, setEndTimestamp] = useState(defaultDateRangeEnd);

	//Whats showing on screen
	const [isViewEventMenuOpen, setIsViewEventMenuOpen] = useState(false);
	const [isViewEventSeriesDetailsOpen, setIsViewEventSeriesDetailsOpen] =
		useState(false);
	const [isCreateMenuOpen, setIsCreateMenuOpen] = useState(false);
	const [state, setState] = useState({
		initialLoad: true,
		eventWizardOpen: false,
		eventWizardMode: "update",
	});

	//What to show in the wizard or menu that is open
	const [eventSeriesIdSelected, setEventSeriesIdSelected] = useState(null);
	const [eventIdSelected, setEventIdSelected] = useState(null);

	const [paginationData, setPaginationData] = useState({
		connection: null,
		event: PAGINATION_EVENTS.INIT,
		size: defaultPageSize,
		order: "asc",
		orderBy: "StartTimestamp",
		goto: null,
	});

	const { eventSeries } = useGetAllEventSeries();
	//Convert seriesId array to format value: Id label: Name
	const seriesIds = eventSeries?.map((series) => ({
		value: series.EventSeriesID,
		label: series.Name,
	}));

	const { eventsData, connection, pageIndex, trueLength, isLoading, refetch } =
		useSearchEvents(
			searchQuery,
			selectedSiteIds,
			selectedSeriesIds,
			startTimestamp,
			endTimestamp,
			paginationData
		);

	function handleCreateMenuSubmit(values) {
		setIsCreateMenuOpen(false);
		if (values.isEditingEventSeries === true) {
			openEventWizard(EVENT_WIZARD_MODES.EDIT, values.selectedEventSeries);
		}
		if (values.isEditingEventSeries === false) {
			openEventWizard(EVENT_WIZARD_MODES.ADD);
		}
	}

	function handleViewEventMenuSubmit(values) {
		setIsViewEventMenuOpen(false);
		if (values.isTryingToEdit === true) {
			openEventWizard(
				EVENT_WIZARD_MODES.EDIT,
				values.eventSeries.EventSeriesID
			);
		}
		if (values.isTryingToDelete === true) {
			openEventWizard(
				EVENT_WIZARD_MODES.CANCEL,
				values.eventSeries.EventSeriesID,
				values.eventId
			);
		}
	}

	function handleSelectSeries(arrayOfSelectedSeriesIds) {
		setSelectedSeriesIds(arrayOfSelectedSeriesIds);
	}

	function handleChange(optionsData) {
		if (optionsData.sites && isEmpty(optionsData.sites) === false) {
			setSelectedSiteIds(optionsData.sites);
		} else {
			setSelectedSiteIds([]); //Set blank if no sites
		}

		//TODO series ids!

		if (optionsData["start-date-time"]) {
			const startDateTime = optionsData["start-date-time"];
			//convert to unix timestamp
			const convertedTimestamp = moment(startDateTime, "YYYYMMDD").unix();
			setStartTimestamp(convertedTimestamp);
		}

		if (optionsData["end-date-time"]) {
			const endDateTime = optionsData["end-date-time"];
			//convert to unix timestamp
			const convertedTimestamp = moment(endDateTime, "YYYYMMDD").unix();
			setEndTimestamp(convertedTimestamp);
		}
	}

	function openEventWizard(
		mode,
		seriesIdForEdit = null,
		eventIdForCancelLocal = null
	) {
		setEventSeriesIdSelected(seriesIdForEdit);
		setEventIdSelected(eventIdForCancelLocal);
		setState((_state) => ({
			..._state,
			eventWizardOpen: true,
			eventWizardMode: mode,
		}));
	}

	function openCreateMenuWizard() {
		setIsCreateMenuOpen(true);
	}

	function refetchData() {
		setTimeout(() => {
			refetch();
		}, 300);
	}

	if (state.eventWizardOpen) {
		return (
			<EventsWizard
				mode={state.eventWizardMode}
				seriesId={eventSeriesIdSelected}
				eventIdToCancel={eventIdSelected}
				title="Events"
				{...props}
				close={() => {
					setState((currentState) => ({
						...currentState,
						eventWizardOpen: false,
					}));
					refetchData();
				}}
			/>
		);
	}

	if (isCreateMenuOpen) {
		return (
			<EventCreateOrEditWizard
				title="Events and Event Series Management"
				onSubmit={handleCreateMenuSubmit}
				{...props}
				close={() => {
					setIsCreateMenuOpen(false);
					refetchData();
				}}
			/>
		);
	}

	if (isViewEventMenuOpen && eventIdSelected) {
		return (
			<ViewEventWizard
				title="Events and Event Series Management"
				onSubmit={handleViewEventMenuSubmit}
				eventId={eventIdSelected}
				{...props}
				close={() => {
					setIsViewEventMenuOpen(false);
					refetchData();
				}}
			/>
		);
	}

	if (isViewEventSeriesDetailsOpen && eventSeriesIdSelected) {
		return (
			<EventSeriesDetails
				seriesId={eventSeriesIdSelected}
				close={() => {
					setIsViewEventSeriesDetailsOpen(false);
					refetchData();
				}}
			/>
		);
	}

	const columns = [
		{
			id: "Image",
			Header: "",
			Cell: (cellProps) => {
				const { Brand } = cellProps.row.original;
				return (
					<img
						src={Brand?.IconLogo}
						alt="Brand Logo"
						style={{ width: "50px", height: "50px" }}
					/>
				);
			},
		},
		{
			id: "Series",
			Header: "Series",
			Cell: (cellProps) => {
				const { EventSeries } = cellProps.row.original;
				return <>{EventSeries?.Name}</>;
			},
		},
		{
			id: "Event",
			Header: "Event",
			accessor: "Name",
		},
		{
			id: "StartTimestamp",
			Header: "Start Date",
			accessor: (row) =>
				moment
					.tz(row.StartTimestamp, "X", row.Sites?.[0]?.Timezone)
					.format("HH:mm on DD MMM YYYY"),
		},
		{
			id: "EndTimestamp",
			Header: "End Date",
			accessor: (row) =>
				moment
					.tz(row.EndTimestamp, "X", row.Sites?.[0]?.Timezone)
					.format("HH:mm on DD MMM YYYY"),
		},
		{
			id: "Sites",
			Header: "Sites",
			Cell: (cellProps) => {
				const { Sites } = cellProps.row.original;
				const listOfSites = Sites?.map((site) => site.Name).join(", ");
				return forEach(listOfSites, (site) => {
					return <div>{site}</div>;
				});
			},
		},
		{
			id: "Groups",
			Header: "Groups",
			Cell: (cellProps) => {
				const { Groups } = cellProps.row.original;
				const listOfGroups = Groups?.map((group) => group.Name).join(", ");
				return forEach(listOfGroups, (group) => {
					return <div>{group}</div>;
				});
			},
		},
		{
			id: "tasks",
			Header: "",
			Cell: (cellProps) => (
				<DropdownMenu
					triggerContent={<MoreHorizontal size={24} />}
					items={[
						<div
							key="OpenViewSeriesDetails"
							onClick={() => {
								const { EventSeriesID } = cellProps.row.original.EventSeries;
								setEventSeriesIdSelected(EventSeriesID);
								setIsViewEventSeriesDetailsOpen(true);
							}}
						>
							Event Series Details
						</div>,
						<div
							key="OpenViewEventMenu"
							onClick={() => {
								const { EventID } = cellProps.row.original;
								setEventIdSelected(EventID);
								setIsViewEventMenuOpen(true);
							}}
						>
							Event Details
						</div>,
						<div
							key="EditEvent"
							onClick={() => {
								const { EventSeriesID } = cellProps.row.original.EventSeries;
								openEventWizard(EVENT_WIZARD_MODES.EDIT, EventSeriesID);
							}}
						>
							Edit
						</div>,
						<div
							key="CancelEvent"
							onClick={() => {
								const {
									EventID,
									EventSeries: { EventSeriesID },
								} = cellProps.row.original;
								openEventWizard(
									EVENT_WIZARD_MODES.CANCEL,
									EventSeriesID,
									EventID
								);
							}}
						>
							Cancel
						</div>,
					]}
				/>
			),
			fixedWidth: 50,
		},
	];

	return (
		<ReportWrapperBeta
			{...props}
			title={"Event Management"}
			defaultDateRange={[defaultDateRangeStart, defaultDateRangeEnd]}
			data={eventsData || []}
			columns={columns}
			defaultSortBy={[{ id: "StartTimestamp", desc: false }]}
			updateOptions={(optionsData) => {
				handleChange(optionsData);
			}}
			onChange={(event, data) => {
				setPaginationData((_state) => ({
					..._state,
					connection: connection ?? _state.connection,
					event: event,
					size: data.pageSize ?? _state.size,
					order: data.order ?? _state.order,
					orderBy: data.column ?? _state.orderBy,
					goto: data.goto ?? _state.goto,
				}));
				setSearchQuery(data.search);
			}}
			totalRecords={trueLength}
			pageSize={paginationData.pageSize || defaultPageSize}
			pageIndex={pageIndex ?? 0}
			loading={isLoading}
			showDateRangePicker={true}
			showSitePicker={true}
			paginateV2={true}
			leftActions={
				<ReportPicker
					style={{
						marginLeft: 16,
					}}
					name="Event Series"
					options={seriesIds ?? []}
					selected={selectedSeriesIds}
					onSelectedChanged={handleSelectSeries}
				/>
			}
			rightActions={
				<Button color="blue" onClick={() => openCreateMenuWizard()}>
					<Plus size={20} /> Create Event
				</Button>
			}
		/>
	);
}
