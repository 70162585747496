import { Link } from "react-router-dom";
import React from "react";
import { colours } from "../../styles";
import styled from "styled-components";

const Wrapper = styled.span`
	a {
		color: ${colours.blue};
		text-decoration: none;
		transition: 0.2s ease;

		&:hover {
			cursor: pointer;
			opacity: 0.8;
		}
	}
`;

export default function LocalLink(props) {
	return (
		<Wrapper>
			<Link {...props} />
		</Wrapper>
	);
}
