import { postJSON } from "../index";

const basePath = "/system/api";

export function syncDeviceService() {
	return postJSON(`${basePath}/v1/sync-device-info`);
}

export function exportIntegration(integrationId) {
	return postJSON(
		`${basePath}/v1/admin/export-integration`,
		{ integrationId },
		false
	);
}
