import * as Yup from "yup";
import {
	assignSummaryStep,
	assignUserStep,
	bayAmount,
	bayNumbering,
	deleteStep,
	editNameStep,
	initial,
	summaryStep,
	unassignStep,
} from "./bay-steps";
import Alert from "react-s-alert";
import React from "react";
import WizardLayout from "../WizardLayout";
import { format } from "../../../helpers/";
import gql from "graphql-tag";
import { useMutateData } from "../../../hooks";

const constructWizard = (props) => {
	switch (props.mode) {
		case "add":
			return {
				initialStep: 0,
				steps: [initial, bayAmount, bayNumbering, summaryStep],
			};
		default:
			return {
				initialStep: 0,
				steps: [initial, bayAmount, bayNumbering, summaryStep],
			};
	}
};

export function AddBaysWizard(props) {
	const createBays = useMutateData(gql`
		mutation ($bays: [BayInput]) {
			createBays(bays: $bays)
		}
	`);

	const wizard = constructWizard(props);

	const onSubmit = async (values, { setSubmitting }) => {
		setSubmitting(true);
		const leaseParkId = values.selectedSpace.leaseParkId;
		const bays = [];
		values.numbering.forEach(({ id, startRange, endRange }) => {
			for (let idx = startRange; idx <= endRange; idx++) {
				bays.push({
					Name: id ? `${id}-${idx}` : `${idx}`,
					LeaseParkID: leaseParkId,
				});
			}
		});

		try {
			setSubmitting(false);
			await createBays({ variables: { bays } });
			Alert.success(`Added new bays`);
			props.close(true);
			props.refetchBays();
			props.refetchParks();
		} catch (error) {
			const message = format.parseGqlError(error);
			if (message) {
				Alert.error(message);
			} else {
				Alert.error("Something went wrong. Please try again.");
			}
			setSubmitting(false);
		}
	};

	const values = [
		{ name: "mode", value: props.mode, validator: Yup.string() },
		{
			name: "spaces",
			value: props.leaseParks.filter((lp) => lp.Sites.length === 1),
			validator: Yup.array(Yup.object()),
		},
		{
			name: "selectedSpace",
			value: {},
			validator: Yup.object(),
		},
		{
			name: "numberOfBays",
			value: 1,
			validator: Yup.number().integer(),
		},
		{
			name: "numbering",
			value: [],
			validator: Yup.array(Yup.object()),
		},
	];

	return (
		<WizardLayout
			close={props.close}
			title={({ mode }) => {
				if (mode === "add") return "Create Bays";
			}}
			values={values}
			onSubmit={onSubmit}
			steps={wizard.steps}
			initialStep={wizard.initialStep}
			wizardProps={props}
			hideStepIndicator={false}
		/>
	);
}

export function EditBayWizard(props) {
	const editBay = useMutateData(gql`
		mutation ($bayId: Int!, $name: String!) {
			updateBayName(bayId: $bayId, name: $name)
		}
	`);

	return (
		<WizardLayout
			close={props.close}
			title={"Edit Bay"}
			values={[
				{ name: "name", value: props.bay.Name, validator: Yup.string() },
			]}
			onSubmit={async (values, { setSubmitting }) => {
				try {
					await editBay({
						variables: {
							bayId: props.bay.BayID,
							name: values.name,
						},
					});
					props.refetch();
					Alert.success("Bay updated");
					props.close(true);
				} catch (error) {
					Alert.error("Something went wrong. Please try again.");
				} finally {
					setSubmitting(false);
				}
			}}
			steps={[editNameStep]}
			initialStep={0}
			wizardProps={props}
		/>
	);
}

export function DeleteBayWizard(props) {
	const deleteBay = useMutateData(gql`
		mutation ($bayId: Int!) {
			deleteBay(bayId: $bayId)
		}
	`);

	return (
		<WizardLayout
			close={props.close}
			title={"Delete Bay"}
			values={[]}
			onSubmit={async () => {
				try {
					await deleteBay({
						variables: {
							bayId: props.bayId,
						},
					});
					props.refetchParks();
					props.refetchBays();
					Alert.success("Bay deleted");
					props.close(true);
				} catch (error) {
					const message = format.parseGqlError(error);
					if (message) {
						Alert.error(message);
					} else {
						Alert.error("Something went wrong. Please try again.");
					}
				}
			}}
			steps={[deleteStep]}
			initialStep={0}
			wizardProps={props}
		/>
	);
}

export function AssignBayWizard(props) {
	const assignBay = useMutateData(gql`
		mutation ($bayId: Int!, $userId: Int!) {
			assignBay(bayId: $bayId, userId: $userId)
		}
	`);

	const onSubmit = async (values, { setSubmitting }) => {
		setSubmitting(true);
		try {
			await assignBay({
				variables: {
					bayId: props.bay.BayID,
					userId: values.user.UserID,
				},
			});

			props.refetch();
			Alert.success("Bay assigned");
			props.close(true);
		} catch (error) {
			Alert.error("Something went wrong. Please try again.");
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<WizardLayout
			close={props.close}
			title={"Assign this bay to a user"}
			values={[
				{
					name: "bay",
					value: props.bay,
					validator: Yup.object(),
				},
			]}
			onSubmit={onSubmit}
			steps={[assignUserStep, assignSummaryStep]}
			initialStep={0}
			wizardProps={props}
		/>
	);
}

export function UnassignBayWizard(props) {
	const unassignBay = useMutateData(gql`
		mutation ($bayId: Int!) {
			unassignBay(bayId: $bayId)
		}
	`);

	const onSubmit = async () => {
		try {
			await unassignBay({
				variables: {
					bayId: props.bay.BayID,
				},
			});
			props.refetch();
			Alert.success("Bay assigned");
			props.close(true);
		} catch (error) {
			Alert.error("Something went wrong. Please try again.");
		}
	};

	return (
		<WizardLayout
			close={props.close}
			title={"Unassign user"}
			values={[
				{
					name: "bay",
					value: props.bay,
					validator: Yup.object(),
				},
			]}
			onSubmit={onSubmit}
			steps={[unassignStep]}
			initialStep={0}
			wizardProps={props}
		/>
	);
}
