import * as authenticationApi from "../../../api/authentication";
import * as installationApi from "../../../api/installation";
import { reduce, some } from "lodash";
import Alert from "react-s-alert";
import { errors as errorMessages } from "../../../helpers";

export function restoreRoles(
	systemRoles,
	organizationId,
	availableSites,
	roles
) {
	let keyedRoles = {};
	systemRoles.forEach((role) => {
		keyedRoles[role.RoleID] = role.Name.replace(/ /g, "");
	});

	return reduce(
		roles,
		(result, role) => {
			const roleName = keyedRoles[role.RoleID];

			if (roleName) {
				const site = availableSites.find((e) => e.SiteID === role.SiteID);
				let item = null;

				if (role.OrganizationID === organizationId && role.SiteID === 0) {
					item = { value: 0, label: "All Sites" };
				} else if (site) {
					item = { value: site.SiteID, label: site.Name };
				}

				let sites = result[roleName] ? result[roleName].sites : null;

				if (item && sites) {
					// check for 'all sites' toggle
					if (sites.find((e) => e.value === 0) || role.SiteID === 0) {
						sites = [];
					}

					if (!sites.find((e) => e.value === role.SiteID)) {
						sites.push(item);
					}

					result[roleName].sites = sites;
				} else if (item) {
					result[roleName] = {
						enabled: true,
						sites: [item],
					};
				}
			}

			return result;
		},
		{}
	);
}

export function restoreGroups(organizationId, groups) {
	return groups
		.filter((e) => {
			if (e.OrganizationID) {
				return e.OrganizationID === organizationId;
			}
			return true; //if no organization id was set, assume it was pre-filtered
		})
		.map((e) => ({
			value: e.OrganizationAccessGroupID,
			label: e.Name,
		}));
}

export function mapRoles(systemRoles, roles) {
	return reduce(
		roles,
		(result, role, key) => {
			if (role.enabled && role.sites && role.sites.length) {
				role.sites.forEach((site) => {
					const systemRole = systemRoles.find(
						(e) => e.Name.replace(/ /g, "") === key
					);

					if (systemRole) {
						result.push({
							roleId: systemRole.RoleID,
							siteId: site.value,
						});
					}
				});
			}

			return result;
		},
		[]
	);
}

export function mapGroups(groups, groupsDates) {
	return groups.map((e) => {
		return {
			groupId: e.value,
			expirationDate:
				groupsDates.find((a) => a.OrganizationAccessGroupID === e.value)
					?.ExpirationDate || null,
			expirationTimestamp:
				groupsDates.find((a) => a.OrganizationAccessGroupID === e.value)
					?.ExpirationTimestamp || null,
		};
	});
}

export async function submitUserInvitation(props, values, setSubmitting) {
	const data = {
		email: values.email,
		roles: mapRoles(props.roles, values.roles),
		organizationAccessGroupIds: mapGroups(
			values.groups,
			values.groupsExpirations
		),
		notifyUserByEmail: values.notifyUserByEmail,
		user: values.user,
		description: values.description,
	};

	try {
		await authenticationApi.createUserInvitation(
			props.selectedOrganization.OrganizationID,
			data
		);

		setSubmitting(false);
		Alert.success("User invited");
		props.close(true);
	} catch (error) {
		setSubmitting(false);

		if (
			error &&
			error.errors &&
			some(error.errors, (e) => e.code === "InvalidSharedInstallation")
		) {
			Alert.error(errorMessages.INVALID_SHARED_INSTALLATION_VIA_GROUP);
		} else {
			Alert.error("Something went wrong. Please try again.");
		}
	}
}

export async function submitUpdateUserInvitation(props, values, setSubmitting) {
	const data = {
		email: values.email,
		roles: mapRoles(props.roles, values.roles),
		organizationAccessGroupIds: mapGroups(
			values.groups,
			values.groupsExpirations
		),
		notifyUserByEmail: values.notifyUserByEmail,
		user: values.user,
		description: values.description,
	};

	try {
		await authenticationApi.updateUserInvitation(
			props.selectedOrganization.OrganizationID,
			data
		);

		setSubmitting(false);
		Alert.success("User invitation updated");
		props.close(true);
	} catch (error) {
		setSubmitting(false);

		if (
			error &&
			error.errors &&
			some(error.errors, (e) => e.code === "InvalidSharedInstallation")
		) {
			Alert.error(errorMessages.INVALID_SHARED_INSTALLATION_VIA_GROUP);
		} else {
			Alert.error("Something went wrong. Please try again.");
		}
	}
}

export async function submitUpdateUser(props, values, setSubmitting) {
	const data = {
		userId: values.user.UserID,
		roles: mapRoles(props.roles, values.roles),
		organizationAccessGroupIds: mapGroups(
			values.groups,
			values.groupsExpirations
		),
		notifyUserByEmail: values.notifyUserByEmail,
		description: values.description,
	};

	try {
		await installationApi.setUserAndOrganizationAccessGroups(
			props.selectedOrganization.OrganizationID,
			data
		);

		if (!props.spaceAccountOwnerOnly) {
			await authenticationApi.setRolesForUserOnOrganization(
				props.selectedOrganization.OrganizationID,
				values.user.UserID,
				data
			);
		}

		setSubmitting(false);
		Alert.success("User updated");
		props.close(true);
	} catch (error) {
		setSubmitting(false);

		if (
			error &&
			error.errors &&
			some(error.errors, (e) => e.code === "InvalidSharedInstallation")
		) {
			Alert.error(errorMessages.INVALID_SHARED_INSTALLATION_VIA_GROUP);
		} else {
			Alert.error("Something went wrong. Please try again.");
		}
	}
}

export async function submitRemoveUserInvitation(props, values, setSubmitting) {
	try {
		await authenticationApi.removeUserInvitation(
			props.selectedOrganization.OrganizationID,
			values.user.Email
		);

		setSubmitting(false);
		Alert.success("User invitation removed");
		props.close(true);
	} catch (error) {
		setSubmitting(false);
		Alert.error("Something went wrong. Please try again.");
	}
}

export async function submitRemoveUser(props, values, setSubmitting) {
	const data = {
		userId: values.user.UserID,
		organizationAccessGroupIds: [],
	};

	try {
		await installationApi.setUserAndOrganizationAccessGroups(
			props.selectedOrganization.OrganizationID,
			data
		);

		if (!props.spaceAccountOwnerOnly) {
			await authenticationApi.deleteAllRolesForUser(
				props.selectedOrganization.OrganizationID,
				values.user.UserID
			);
		}

		setSubmitting(false);
		Alert.success("User removed");
		props.close(true);
	} catch (error) {
		setSubmitting(false);
		Alert.error("Something went wrong. Please try again.");
	}
}

export async function submitAdminUser(props, values, setSubmitting, mutation) {
	try {
		await mutation({
			variables: {
				userId: values.user.UserID,
				notifyUserByEmail: values.notifyUserByEmail,
				description: values.description,
				isAdmin: values.superAdmin || values.csAdmin,
				isCSAdmin: values.csAdmin,
				adminDashboardEnabled: values.adminDashboardEnabled,
				featuresPreview: values.featuresPreview,
				debugEnabled: values.debugEnabled,
			},
		});

		setSubmitting(false);
		Alert.success("Admin user updated");
		props.close(true);
	} catch (error) {
		setSubmitting(false);
		Alert.error("Something went wrong. Please try again.");
	}
}

export async function submitRemoveAdminUser(
	props,
	values,
	setSubmitting,
	mutation
) {
	try {
		await mutation({
			variables: {
				userId: values.user.UserID,
			},
		});

		setSubmitting(false);
		Alert.success("Admin user removed");
		props.close(true);
	} catch (error) {
		setSubmitting(false);
		Alert.error("Something went wrong. Please try again.");
	}
}

export async function submitClientAdminUser(
	props,
	values,
	setSubmitting,
	mutation
) {
	try {
		await mutation({
			variables: {
				clientId: values.clientId,
				userId: values.user.UserID,
				notifyUserByEmail: values.notifyUserByEmail,
			},
		});

		setSubmitting(false);
		Alert.success("Admin user updated");
		props.close(true);
	} catch (error) {
		setSubmitting(false);
		Alert.error("Something went wrong. Please try again.");
	}
}

export async function submitRemoveClientAdminUser(
	props,
	values,
	setSubmitting,
	mutation
) {
	try {
		await mutation({
			variables: {
				clientId: values.clientId,
				userId: values.user.UserID,
			},
		});

		setSubmitting(false);
		Alert.success("Admin user removed");
		props.close(true);
	} catch (error) {
		setSubmitting(false);
		Alert.error("Something went wrong. Please try again.");
	}
}
