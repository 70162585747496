import * as api from "../../api";
import { CLIENTS, CLIENT_UI_CONFIG } from "../../helpers/constants";
import React, { useState } from "react";
import { colours, mediaSizes } from "../../styles";
import { useMutateData, useQueryData } from "../../hooks";
import Card from "../../components/layout/Card";
import ForgotPasswordForm from "./ForgotPasswordForm";
import LoadingPlaceholder from "../../components/report/LoadingPlaceholder";
import LoginForm from "./LoginForm";
import Logo from "../../components/layout/Logo";
import RegisterForm from "./RegisterForm";
import ResetPasswordForm from "./ResetPasswordForm";
import gql from "graphql-tag";
import poweredBy from "../../images/powered-by-inugo.png";
import qs from "qs";
import styled from "styled-components";

const ContentWrapper = styled.div`
	border-radius: 4px;
	flex: 1;

	.logo-bar {
		${(props) =>
			props.backgroundColorSecondary
				? `background-image: linear-gradient(to right, ${props.backgroundColor}, ${props.backgroundColorSecondary})`
				: `background: ${props.backgroundColor}`};
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		margin: -16px -16px 32px;
		padding: 32px 0;

		@media (max-width: 399px) {
			display: none;
		}

		&.small {
			display: none;
			padding: 16px 0;

			@media (max-width: 399px) {
				display: block;
			}
		}
	}

	.logo-bar {
		${(props) =>
			props.backgroundColorSecondary
				? `background-image: linear-gradient(to right, ${props.backgroundColor}, ${props.backgroundColorSecondary})`
				: `background: ${props.backgroundColor}`};
		padding: 32px 0;
	}

	a {
		color: ${colours.blue};
		text-decoration: none;
		transition: 0.2s ease;

		&:hover {
			cursor: pointer;
			opacity: 0.8;
		}
	}
`;

const Content = styled.div`
	margin: 0 auto;
	max-width: 600px;
	padding: 64px 16px;

	@media (max-width: ${mediaSizes.medium}px) {
		padding: 0 16px;
	}

	h1 {
		font-size: 28px;
		line-height: 32px;
		text-align: center;
	}

	.form {
		margin: 64px auto 0;
		min-width: 250px;
		max-width: 400px;
	}
`;

const NoAccess = styled.div`
	display: block;
	margin: 64px 0 32px;
	text-align: center;
`;

const PoweredBy = styled.div`
	height: 70px;
	position: relative;
	text-align: center;

	img {
		position: absolute;
		height: 50px;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
	}
`;

export default function AuthContainer(props) {
	const [state, setState] = useState({
		email: "",
	});

	const logoutMutation = useMutateData(gql`
		mutation ($sessionKey: String!) {
			logout(sessionKey: $sessionKey)
		}
	`);

	async function logOut() {
		if (api.hasSessionKey()) {
			await logoutMutation({ variables: { sessionKey: api.getSessionKey() } });
			props.history.push("/login");
			api.clearSessionKey();
		} else {
			props.history.push("/login");
		}
	}

	if (props.noAccess || props.error) {
		// remove the path from the url for no access and error states
		window.history.replaceState(null, null, "/");
	}

	const options = qs.parse(props.location.search, { ignoreQueryPrefix: true });
	let clientCode = "IN";

	const {
		data: { getClientCodeByResetPasswordKey },
		isLoading,
	} = useQueryData(
		gql`
			query ($passwordResetKey: String!) {
				getClientCodeByResetPasswordKey(passwordResetKey: $passwordResetKey)
			}
		`,
		{ passwordResetKey: options.code },
		!props.resetPassword,
		{ auth: false }
	);

	clientCode = getClientCodeByResetPasswordKey || CLIENTS.INUGO;
	let clientConfig = CLIENT_UI_CONFIG[clientCode];

	if (!clientConfig) {
		clientConfig = CLIENT_UI_CONFIG.IN;
		clientCode = CLIENTS.INUGO;
	}

	return (
		<ContentWrapper
			backgroundColor={clientConfig.colours.background}
			backgroundColorSecondary={clientConfig.colours.backgroundSecondary}
		>
			<Content>
				{isLoading ? (
					<LoadingPlaceholder />
				) : (
					<>
						<Card>
							<div className="logo-bar">
								<Logo
									client={clientCode}
									dark={false}
									height={clientConfig.logo.size.large}
									padding={clientConfig.logo.padding}
								/>
							</div>
							<div className="logo-bar small">
								<Logo
									client={clientCode}
									dark={false}
									height={clientConfig.logo.size.small}
									padding={clientConfig.logo.padding}
								/>
							</div>

							{!props.forgotPassword && !props.resetPassword && (
								<h1>Customer Management Platform</h1>
							)}
							{props.login && (
								<LoginForm
									{...props}
									onEmailChange={(value) =>
										setState((_state) => ({ ..._state, email: value }))
									}
								/>
							)}
							{props.forgotPassword && (
								<ForgotPasswordForm {...props} email={state.email} />
							)}
							{props.resetPassword && (
								<ResetPasswordForm client={clientCode} {...props} />
							)}
							{props.register && <RegisterForm {...props} />}
							{props.noAccess && (
								<div>
									<NoAccess>
										<p>
											You do not have operator access to any Inugo
											installations.
											<br />
											Please contact{" "}
											<a href="mailto:support@inugo.com">Inugo</a> if you
											believe this to be an error.
										</p>
									</NoAccess>
									<div
										style={{
											display: "block",
											margin: "64px 0 32px",
											textAlign: "center",
										}}
									>
										<a onClick={logOut}>Log out</a>
									</div>
								</div>
							)}
							{props.error && (
								<div>
									<NoAccess>
										<p style={{ fontSize: 20, fontWeight: 600 }}>
											An error occurred while contacting the Inugo servers
										</p>
										<p style={{ margin: "0 auto", maxWidth: 360 }}>
											Try going back to the home page or you can contact{" "}
											<a href="mailto:support@inugo.com">Inugo support</a> if
											this does not resolve the issue.
										</p>
									</NoAccess>
									<div
										style={{
											display: "block",
											margin: "64px 0 32px",
											textAlign: "center",
										}}
									>
										<a href="/">Go back to home page</a>
									</div>
								</div>
							)}
						</Card>

						{clientCode !== CLIENTS.INUGO && clientCode !== CLIENTS.SECURE && (
							<PoweredBy>
								<a href="https://inugo.com">
									<img src={poweredBy} />
								</a>
							</PoweredBy>
						)}
					</>
				)}
			</Content>
		</ContentWrapper>
	);
}
