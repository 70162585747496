import * as Yup from "yup";
import * as billingApi from "../../../api/billing";
import WizardLayout, { StepText } from "../WizardLayout";
import Alert from "react-s-alert";
import Button from "../../layout/Button";
import React from "react";
import WizardNavigation from "../WizardNavigation";

const deleteInvoiceStep = ({
	handleSubmit,
	isSubmitting,
	wizardProps,
	values,
	keyStrokeHandler,
}) => ({
	id: "delete",
	label: "Delete Invoice",
	render: () => (
		<div>
			<StepText>
				Are you sure you want to delete {values.invoice._invoiceType} invoice{" "}
				for {values.invoice.Month} {values.invoice._year}?
			</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					Delete
				</Button>,
			]}
		/>
	),
});

const sendInvoiceStep = ({
	handleSubmit,
	isSubmitting,
	wizardProps,
	values,
	keyStrokeHandler,
}) => ({
	id: "send",
	label: "Send Invoice",
	render: () => (
		<div>
			<StepText>
				Are you sure you want to send {values.invoice._invoiceType} invoice for{" "}
				{values.invoice.Month} {values.invoice._year} to {values.invoiceEmail}?
			</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					Send
				</Button>,
			]}
		/>
	),
});

export default function InvoiceWizard(props) {
	let wizard = {};

	if (props.mode === "delete-invoice") {
		wizard = {
			title: `Delete Invoice`,
			initialStep: 0,
			steps: [deleteInvoiceStep],
		};
	}

	if (props.mode === "send-invoice") {
		wizard = {
			title: `Send Invoice`,
			initialStep: 0,
			steps: [sendInvoiceStep],
		};
	}

	const values = [
		{ name: "mode", value: props.mode, validator: Yup.string() },
		{
			name: "invoice",
			value: props.invoice,
			validator: Yup.object(),
		},
		{
			name: "invoiceEmail",
			value: props.invoiceEmail,
			validator: Yup.string().email().nullable(),
		},
	];

	const onSubmit = async (_values, { setSubmitting }) => {
		setSubmitting(true);
		if (_values.mode === "delete-invoice") {
			try {
				await billingApi.deleteInvoice(
					props.invoice.SiteID,
					props.invoice.InvoiceID
				);

				setSubmitting(false);
				Alert.success("Invoice deleted");
				props.close(true);
			} catch (error) {
				setSubmitting(false);
				Alert.error("Something went wrong. Please try again.");
			}
		}

		if (_values.mode === "send-invoice") {
			try {
				await billingApi.sendInvoice(
					props.invoice.SiteID,
					props.invoice.InvoiceID
				);

				setSubmitting(false);
				Alert.success("Invoice sent");
				props.close(true);
			} catch (error) {
				setSubmitting(false);
				Alert.error(
					"Invoice could not be sent at this time. Please set a valid email address and try again."
				);
			}
		}
	};

	return (
		<WizardLayout
			close={props.close}
			title={wizard.title}
			values={values}
			onSubmit={onSubmit}
			steps={wizard.steps}
			initialStep={wizard.initialStep}
			wizardProps={props}
		/>
	);
}
