import React from "react";
import { FieldArray } from "formik";
import { Spaces } from "../siteSpaceRates/Spaces";
import { Sites } from "../siteSpaceRates/Sites";
import RatesSelector from "../siteSpaceRates/Rates";
import { Label } from "../../../styles";
import { MinusCircle, PlusCircle } from "react-feather";
import { colours } from "../../../../../../styles";

export const SiteSpaceRates = ({
	availableSites,
	values,
	setFieldValue,
	errors,
}) => {
	const filterSelectedSites = (sites, selectedSiteRates) => {
		const selectedSiteValues = selectedSiteRates
			.map((item) => item.selectedSite?.value)
			.filter((value) => value !== undefined);
		return sites.filter(
			(option) => !selectedSiteValues.includes(option.SiteID)
		);
	};

	const [currentSiteOptions, setCurrentSiteOptions] = React.useState(
		filterSelectedSites(availableSites, values.siteSpaceRates)
	);

	React.useEffect(() => {
		const filtered = filterSelectedSites(availableSites, values.siteSpaceRates);
		setCurrentSiteOptions(filtered);
	}, [availableSites, values.siteSpaceRates]);

	const handleSiteChange = (index, selectedSite) => {
		const items = [...values.siteSpaceRates];
		items[index] = { selectedSite, selectedSpaces: [], selectedRates: [] };
		setFieldValue("siteSpaceRates", items);
	};

	const handleSpacesChange = (index, selectedSpaces) => {
		const items = [...values.siteSpaceRates];
		items[index].selectedSpaces = selectedSpaces;
		setFieldValue("siteSpaceRates", items);
	};

	const handleRatesChange = (index, selectedRates) => {
		const items = [...values.siteSpaceRates];
		items[index].selectedRates = selectedRates;
		setFieldValue("siteSpaceRates", items);
	};

	const handleAdd = (arrayHelpers) => {
		arrayHelpers.push({
			selectedSite: null,
			selectedSpaces: [],
			selectedRates: [],
		});
	};

	const handleRemove = (arrayHelpers, index) => {
		if (index === 0 && values.siteSpaceRates.length === 1) {
			// If only one row and it's the first row, reset it
			setFieldValue("siteSpaceRates", [
				{
					selectedSite: null,
					selectedSpaces: [],
					selectedRates: [],
				},
			]);
		} else {
			arrayHelpers.remove(index);
		}
	};

	return (
		<div>
			<Label>Sites, Spaces, and Rates</Label>
			<FieldArray name="siteSpaceRates">
				{(arrayHelpers) => (
					<div>
						{values.siteSpaceRates.map((item, index) => (
							<div key={index} style={{ marginBottom: 16 }}>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										gap: 16,
										backgroundColor: "#f9f9f9",
										padding: 16,
									}}
								>
									<Sites
										availableSites={currentSiteOptions}
										selectedSites={item.selectedSite}
										onChange={(site) => handleSiteChange(index, site)}
										error={
											errors.siteSpaceRates &&
											errors.siteSpaceRates[index]?.selectedSite
										}
										style={{ width: 250 }}
									/>
									<div
										style={{
											display: "flex",
											alignItems: "center",
											gap: 8,
										}}
									>
										<div style={{ flex: 1 }}>
											<Spaces
												selectedSite={item.selectedSite?.value}
												selectedSpaces={item.selectedSpaces}
												onChange={(spaces) => handleSpacesChange(index, spaces)}
												error={
													errors.siteSpaceRates &&
													errors.siteSpaceRates[index]?.selectedSpaces
												}
												style={{ width: "100%" }}
											/>
										</div>
										<div style={{ flex: 1 }}>
											<RatesSelector
												selectedSite={item.selectedSite?.value}
												selectedRates={item.selectedRates}
												onChange={(rates) => handleRatesChange(index, rates)}
												error={
													errors.siteSpaceRates &&
													errors.siteSpaceRates[index]?.selectedRates
												}
												style={{ width: "100%" }}
											/>
										</div>
										<div style={{ display: "flex", gap: 8 }}>
											{values.siteSpaceRates.length > 1 && (
												<MinusCircle
													onClick={() => handleRemove(arrayHelpers, index)}
													color={colours.red}
													style={{ cursor: "pointer" }}
												/>
											)}

											{index === values.siteSpaceRates.length - 1 && (
												<PlusCircle
													onClick={() => handleAdd(arrayHelpers)}
													color={colours.blue}
													style={{ cursor: "pointer" }}
												/>
											)}
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				)}
			</FieldArray>
		</div>
	);
};
