import * as Yup from "yup";
import * as steps from "./steps";
import { useMutateData } from "../../../hooks";
import Alert from "react-s-alert";
import React from "react";
import WizardLayout from "../WizardLayout";
import gql from "graphql-tag";
import { isNil } from "lodash";

const constructWizard = (props) => {
	switch (props.mode) {
		case "create":
			return {
				initialStep: 0,
				steps: [steps.ruleStep, steps.sitesStep, steps.summaryStep],
			};
		case "edit": {
			const editSteps = [steps.ruleStep, steps.sitesStep, steps.summaryStep];
			return {
				initialStep: editSteps.length - 1,
				steps: editSteps,
			};
		}
		case "delete":
			return {
				initialStep: 0,
				steps: [steps.deleteStep],
			};
	}
};

function secondsToMinutes(durationSeconds) {
	return isNil(durationSeconds) ? null : Math.ceil(durationSeconds / 60);
}

function minutesToSeconds(value) {
	return isNil(value) ? value : value * 60;
}

function parseRuleFromForm(values) {
	return {
		OffenceCode: values.offenceCode,
		OffenceEvent: values.offenceEvent,
		TimeRestriction: minutesToSeconds(values.timeRestriction),
		EntryGracePeriod: minutesToSeconds(values.entryGracePeriod),
		ExitGracePeriod: minutesToSeconds(values.exitGracePeriod),
		Priority: values.priority,
		SiteIDs: values.selectedSites.map((siteOption) => siteOption.value),
	};
}

export default function EnforcementRuleWizard(props) {
	const wizard = constructWizard(props);

	const createRule = useMutateData(gql`
		mutation ($rule: EnforcementRuleInput!) {
			createEnforcementRule(rule: $rule)
		}
	`);

	const updateRule = useMutateData(gql`
		mutation ($enforcementRuleId: Int!, $rule: EnforcementRuleInput!) {
			updateEnforcementRule(enforcementRuleId: $enforcementRuleId, rule: $rule)
		}
	`);

	const deleteRule = useMutateData(gql`
		mutation ($enforcementRuleId: Int!) {
			deleteEnforcementRule(enforcementRuleId: $enforcementRuleId)
		}
	`);

	return (
		<WizardLayout
			close={props.close}
			title={() => {
				if (props.mode === "create") return "Add Enforcement Rule";
				if (props.mode === "edit") return "Edit Enforcement Rule";
				if (props.mode === "delete") return "Delete Enforcement Rule";
			}}
			values={[
				{
					name: "mode",
					value: props.mode,
					validator: Yup.string(),
				},
				{
					name: "offenceCode",
					value: props.enforcementRule?.OffenceCode || "",
					validator: Yup.string(),
				},
				{
					name: "offenceEvent",
					value: props.enforcementRule?.OffenceEvent || "",
					validator: Yup.string(),
				},
				{
					name: "timeRestriction",
					value: props.enforcementRule
						? secondsToMinutes(props.enforcementRule.TimeRestriction)
						: 60,
					validator: Yup.number().nullable(),
				},
				{
					name: "entryGracePeriod",
					value: props.enforcementRule
						? secondsToMinutes(props.enforcementRule.EntryGracePeriod)
						: 10,
					validator: Yup.number().nullable(),
				},
				{
					name: "exitGracePeriod",
					value: props.enforcementRule
						? secondsToMinutes(props.enforcementRule.ExitGracePeriod)
						: 10,
					validator: Yup.number().nullable(),
				},
				{
					name: "availableSites",
					value: props.availableSites.map((site) => ({
						label: site.Name,
						value: site.SiteID,
					})),
					validator: Yup.array(),
				},
				{
					name: "selectedSites",
					value:
						props.enforcementRule?.Sites?.map((site) => ({
							label: site.Name,
							value: site.SiteID,
						})) || [],
					validator: Yup.array(),
				},
				{
					name: "priority",
					value: props.enforcementRule?.Priority || 0,
					validator: Yup.number().nullable(),
				},
			]}
			onSubmit={async (values, { setSubmitting }) => {
				setSubmitting(true);

				try {
					let action = "";
					switch (props.mode) {
						case "create":
							await createRule({
								variables: {
									rule: parseRuleFromForm(values),
								},
							});
							action = "Created";
							break;
						case "edit":
							await updateRule({
								variables: {
									enforcementRuleId: props.enforcementRule.EnforcementRuleID,
									rule: parseRuleFromForm(values),
								},
							});
							action = "Updated";
							break;
						case "delete":
							await deleteRule({
								variables: {
									enforcementRuleId: props.enforcementRule.EnforcementRuleID,
								},
							});
							action = "Deleted";
					}

					Alert.success(`Enforcement Rule ${action}`);

					props.refetchRules?.();
					props.close(true);
				} catch (error) {
					const errorCode = error.graphQLErrors?.[0]?.extensions?.code;

					let message = "Something went wrong. Please try again.";
					switch (errorCode) {
						case "OFFENCE_CODE_IN_USE":
							message = `The offence code '${values.offenceCode}' is already in use for this operator.`;
							break;
						case "INVALID_INPUT":
							// should never happen.
							message = "Rule configuration is invalid";
							break;
					}
					Alert.error(message);
				} finally {
					setSubmitting(false);
				}
			}}
			steps={wizard.steps}
			initialStep={wizard.initialStep}
			wizardProps={props}
			// hideStepIndicator={false}
		/>
	);
}
