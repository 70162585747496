import { Check, Edit } from "react-feather";
import React, { useState } from "react";
import FieldError from "./FieldError";
import Input from "./Input";
import { colours } from "../../styles";
import styled from "styled-components";

const Wrapper = styled.div`
	display: flex;
	flex: 1;
	flex-wrap: wrap;
`;

const Item = styled.div`
	display: flex;
	flex: 1 auto;
	padding-right: ${(props) =>
		props.useLabel || !props.editing ? "0" : "32px"};
	width: 100%;

	input {
		overflow: ${(props) => (props.editing ? "auto" : "hidden")};
		padding-right: ${(props) =>
			!props.useLabel && !props.editing ? "32px" : "0"};
		text-overflow: ${(props) => (props.editing ? "initial" : "ellipsis")};
		white-space: ${(props) => (props.editing ? "normal" : "nowrap")};

		&:disabled {
			background-color: ${(props) =>
				props.disabled ? colours.highlightGrey : "inherit"};
			border-color: ${(props) =>
				props.error ? colours.red : colours.lightGrey};
			border-width: ${(props) => (props.disabled ? 1 : 2)};

			&:hover {
				cursor: ${(props) => (props.disabled ? "no-drop" : "default")};
			}
		}
	}
`;

const EditIcon = styled.div`
	color: ${colours.blue};
	padding-left: 4px;
	position: absolute;
	right: ${(props) => (props.editing ? "4px" : "0")};
	top: ${(props) => (props.editing ? "4px" : "6px")};
	transition: 0.2s opacity ease;
	width: 28px;

	&:hover {
		cursor: pointer;
		opacity: 0.8;
	}
`;

const Descriptor = styled.div`
	padding-top: 5px;
	padding-left: 10px;
`;

export default function EditableInput(props) {
	const [editing, setEditing] = useState(false);
	const toggleEditing = () => setEditing(!editing);
	const { children, ...rest } = props; // eslint-disable-line no-unused-vars

	return (
		<Wrapper>
			<Item
				editing={props.useLabel || (editing && !props.disabled)}
				disabled={props.disabled}
				error={props.error}
				useLabel={props.useLabel}
			>
				<Input
					{...rest}
					onBlur={(e) => {
						setEditing(false);
						if (rest.onBlur) rest.onBlur(e);
					}}
					disabled={props.disabled || (!props.useLabel && !editing)}
				/>
				{!props.useLabel && !props.disabled && (
					<EditIcon editing={editing} onClick={toggleEditing}>
						{editing ? <Check size={24} /> : <Edit size={18} />}
					</EditIcon>
				)}
				{props.descriptor && <Descriptor>{props.descriptor}</Descriptor>}
			</Item>
			{props.error && (
				<FieldError showError={props.error} errorText={props.error} />
			)}
		</Wrapper>
	);
}
