const uuidRegExp = new RegExp(
	/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
);

// from https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
const emailRegExp = new RegExp(
	/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
);

export function fullName(value) {
	if (!value) return "";

	if (value.FirstName) {
		return `${value.FirstName} ${value.LastName}`;
	}

	return "";
}

export function fullNameWithEmail(value) {
	if (!value) return "";

	if (value.FirstName) {
		return `${value.FirstName} ${value.LastName} (${value.Email})`;
	} else if (value.Email) {
		return `(${value.Email})`;
	}

	return "";
}

export function emailValidator(email) {
	return emailRegExp.test(email);
}

export function isAnonymous(email) {
	if (!emailRegExp.test(email)) {
		return false;
	}

	const [id, domain] = email.split("@");
	return uuidRegExp.test(id) && domain === "inugo.com";
}
