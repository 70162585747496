// reworked from implementation at: https://codesandbox.io/embed/102z1pwrz3

import React, { useEffect, useRef, useState } from "react";
import { animated, useSpring } from "react-spring";
import ResizeObserver from "resize-observer-polyfill";
import styled from "styled-components";

const CollapsibleWrapper = styled(animated.div)`
	overflow: ${(props) => (props.open ? "visible" : "hidden")};
`;

const usePrevious = (value) => {
	const ref = useRef();

	useEffect(() => {
		ref.current = value;
	}, [value]);

	return ref.current;
};

const useScrollHeight = () => {
	const ref = useRef();
	const [height, setHeight] = useState(0);

	useEffect(() => {
		const ro = new ResizeObserver(([entry]) => {
			setHeight(entry.target.scrollHeight);
		});

		if (ref.current) {
			ro.observe(ref.current);
		}

		return () => {
			ro.disconnect();
		};
	}, []);

	return [ref, height];
};

export default function ({ children, isOpen = false }) {
	const [ref, height] = useScrollHeight();
	const wasOpen = usePrevious(isOpen);

	const anim = useSpring({
		to: { opacity: isOpen ? 1 : 0, maxHeight: isOpen ? height : 0 },
		config: { tension: 250, friction: 30 },
	});

	const style = {
		...anim,
		maxHeight: (isOpen && wasOpen) || height === 0 ? "none" : anim.maxHeight,
	};

	return (
		<CollapsibleWrapper open={isOpen} ref={ref} style={style}>
			{children}
		</CollapsibleWrapper>
	);
}
