import { CLIENTS, CLIENT_UI_CONFIG } from "../../helpers/constants";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
	height: ${(props) => (props.height ? props.height : "80px")};
	padding: ${(props) => (props.padding ? props.padding : "8px")};
	position: relative;
	text-align: center;

	img {
		position: absolute;
		height: ${(props) => (props.height ? props.height : "80px")};
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
	}
`;

export default function Logo(props) {
	const clientTheme = CLIENT_UI_CONFIG[props.client || CLIENTS.INUGO].image;
	return (
		<Wrapper height={props.height} padding={props.padding}>
			<img src={props.dark ? clientTheme.dark : clientTheme.light} />
		</Wrapper>
	);
}
