import React, { useState } from "react";
import { fetchDataCallback, paginatedState } from "../../../helpers/pagination";
import { usePermissions, useQueryData } from "../../../hooks";
import AddCameraWizard from "../../wizards/camera-wizard/AddCameraWizard";
import Button from "../../layout/Button";
import { MoreHorizontal, Plus } from "react-feather";
import ReportWrapperBeta from "../ReportWrapperBeta";
import { booleanValueFormatter } from "../../../components/layout/TableLayout";
import { format } from "../../../helpers";
import gql from "graphql-tag";
import DropdownMenu from "../../../components/layout/DropdownMenu";
import CameraActionsWizard from "../../wizards/camera-wizard/CameraActionsWizard";

const defaultSorting = [
	{
		id: "SerialNumber",
		desc: true,
	},
];

const columns = (props) => [
	{
		id: "SerialNumber",
		Header: "Serial Number",
		accessor: (d) => d.SerialNumber || "",
		highlightCell: (cellProps) => cellProps.row.original.SerialNumber || "",
		className: "bold",
	},
	{
		id: "Manufacturer",
		Header: "Manufacturer",
		accessor: (d) => d.Manufacturer || "",
		highlightCell: (cellProps) => cellProps.row.original.Manufacturer || "",
	},
	{
		id: "ManufacturerID",
		Header: "Manufacturer ID",
		accessor: (d) => d.ManufacturerID || "",
		highlightCell: (cellProps) => cellProps.row.original.ManufacturerID || "",
	},
	{
		id: "Sites",
		Header: "Sites",
		accessor: (d) => d.Sites || "",
		Cell: (cellProps) => cellProps.row.original.Sites || "",
	},
	{
		id: "LaneAndGate",
		Header: "Lane & Gate",
		accessor: "_laneAndGate",
		Cell: ({
			row: {
				original: { Lane, Node },
			},
		}) => (
			<>
				<div>{`${format.formatLane(Lane, Node)}`}</div>
				<div style={{ fontWeight: 700 }}>{`${format.formatGate(Node)}`}</div>
			</>
		),
	},
	{
		id: "IsActive",
		Header: "Active",
		accessor: (d) => d.IsActive || 0,
		Cell: (cellProps) =>
			booleanValueFormatter({ value: cellProps.row.original.IsActive }, true),
	},
	{
		id: "tasks",
		Header: "",
		accessor: null,
		Cell: (cellProps) =>
			props.canOpenGate &&
			!cellProps.row.original.IsExternal &&
			cellProps.row.original.NodeID && (
				<DropdownMenu
					triggerContent={<MoreHorizontal size={24} />}
					items={[
						<div
							key="open-gate"
							onClick={() =>
								props.openWizard("open-gate", cellProps.row.original)
							}
						>
							Open
						</div>,
					]}
				/>
			),
		resizable: false,
		fixedWidth: 50,
	},
];

export default function Cameras(props) {
	let cameras = [];

	const [state, setState] = useState({
		initialLoad: true,
		search: "",
		node: "",
		wizardOpen: false,
		submitting: false,
		wizardMode: null,
		selectedCamera: null,
	});

	const canOpenGate = usePermissions(null, "RemoteOpenGate");
	const isAdmin = usePermissions("IsAdmin");
	const isCSAdmin = usePermissions("IsCSAdmin");
	const isSuperAdmin = isAdmin && !isCSAdmin;

	async function updateOptions({ search, ...options }) {
		setState((_state) => ({ ..._state, initialLoad: false, search, options }));
	}

	const openWizard = (mode, camera) => {
		setState((_state) => ({
			..._state,
			wizardOpen: true,
			wizardMode: mode,
			selectedCamera: camera,
		}));
	};

	const [paginate, setPaginate] = useState(paginatedState);

	const fetchPaginatedData = fetchDataCallback(setPaginate);

	const skipQuery =
		!paginate.pageSize || !paginate.sortBy || !paginate.sortOrder;

	const { data, isLoading, refetch } = useQueryData(
		gql`
			query ($page: PageInfo!, $cursor: Int) {
				getAdminCameras(page: $page, cursor: $cursor) {
					trueLength
					previousEvent
					Cameras {
						CameraID
						SerialNumber
						Manufacturer
						ManufacturerID
						IsActive
						Sites
						RankingScore
						NodeID
						IsExternal
						Lane {
							Name
							GroupName
							GroupOrder
						}
						Node {
							Name
							SerialNumber
							AccessType
						}
					}
				}
			}
		`,
		{
			page: {
				next: paginate.next,
				pageOffset: paginate.pageOffset,
				pageSize: paginate.pageSize,
				sortBy: paginate.sortBy,
				sortOrder: paginate.sortOrder,
				searchTokens: paginate.searchTokens,
				eventType: paginate.eventType,
				sortedVal: paginate.cursor.sortedVal,
			},
			cursor: paginate.cursor.cursor,
		},
		skipQuery
	);

	if (data.getAdminCameras?.Cameras) {
		cameras = data.getAdminCameras.Cameras.map((_camera) => {
			return {
				..._camera,
				_laneAndGate: `${format.formatLane(
					_camera.Lane,
					_camera.Node
				)} ${format.formatGate(_camera.Node)}`,
			};
		});
	}

	const trueLength = data?.getAdminCameras?.trueLength || 0;

	if (state.wizardOpen) {
		if (state.wizardMode === "add-cameras") {
			return (
				<AddCameraWizard
					close={() => {
						refetch();
						setState((_state) => ({
							..._state,
							wizardOpen: false,
						}));
					}}
				/>
			);
		} else if (state.wizardMode === "open-gate") {
			return (
				<CameraActionsWizard
					camera={state.selectedCamera}
					close={() => {
						setState((_state) => ({
							..._state,
							wizardOpen: false,
						}));
					}}
				/>
			);
		}
	}

	return (
		<div>
			{state.wizardOpen && (
				<AddCameraWizard
					close={() => {
						refetch();
						setState((_state) => ({
							..._state,
							wizardOpen: false,
						}));
					}}
				/>
			)}
			<ReportWrapperBeta
				{...props}
				title="Cameras"
				data={cameras}
				columns={columns({ openWizard, canOpenGate })}
				defaultSortBy={defaultSorting}
				updateOptions={updateOptions}
				loading={props.isLoading}
				showDateRangePicker={false}
				showSitePicker={false}
				rightActions={
					isSuperAdmin ? (
						<Button
							color="blue"
							style={{ marginLeft: 8 }}
							onClick={() => openWizard("add-cameras")}
						>
							<Plus size={20} /> Add Cameras
						</Button>
					) : null
				}
				dataTrueLength={trueLength}
				cursorColumn="CameraID"
				paginationIsLoading={isLoading || skipQuery || props.isLoading}
				pageSize={state.pageSize}
				previousEvent={data?.getAdminCameras?.previousEvent}
				searchTokens={paginate.searchTokens}
				fetchPaginatedData={fetchPaginatedData}
			/>
		</div>
	);
}
