import { AlertTriangle, Check, Info, X } from "react-feather";
import { StepText, StepTitle } from "../WizardLayout";
import Button from "../../layout/Button";
import Dropdown from "../../layout/Dropdown";
import EditableInputField from "../../layout/EditableInputField";
import Input from "../../../components/layout/Input";
import MultiChoiceButton from "../MultiChoiceButton";
import React from "react";
import SummaryTable from "../SummaryTable";
import WizardNavigation from "../WizardNavigation";

const iconStyle = {
	float: "left",
	margin: "0 8px 0 24px",
};

export const typeStep = ({
	values,
	setFieldValue,
	goTo,
	wizardProps,
	next,
	keyStrokeHandler,
}) => ({
	id: "type",
	label: "Type",
	render: () => (
		<div>
			<StepTitle>What is the instruction type?</StepTitle>
			<MultiChoiceButton
				selected={values.type === "Do"}
				onClick={() => {
					setFieldValue("type", "Do");
				}}
			>
				<div>
					<Check style={iconStyle} size={24} />
					Do
				</div>
			</MultiChoiceButton>
			<MultiChoiceButton
				selected={values.type === "DoNot"}
				onClick={() => {
					setFieldValue("type", "DoNot");
				}}
			>
				<div>
					<X style={iconStyle} size={24} /> Do Not
				</div>
			</MultiChoiceButton>
			<MultiChoiceButton
				selected={values.type === "Info"}
				onClick={() => {
					setFieldValue("type", "Info");
				}}
			>
				<div>
					<Info style={iconStyle} size={24} /> Info
				</div>
			</MultiChoiceButton>
			<MultiChoiceButton
				selected={values.type === "Warning"}
				onClick={() => {
					setFieldValue("type", "Warning");
				}}
			>
				<div>
					<AlertTriangle style={iconStyle} size={24} /> Warning
				</div>
			</MultiChoiceButton>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="cancel" onClick={wizardProps.close} color="blue">
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					disabled={!values.type}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const titleStep = ({
	values,
	setFieldValue,
	next,
	previous,
	goTo,
	keyStrokeHandler,
}) => ({
	id: "title",
	label: "Title",
	render: () => (
		<div>
			<StepTitle>What is the title of the instruction?</StepTitle>
			<EditableInputField
				type="text"
				name={"title"}
				value={values.title || ""}
				useLabel={"Title"}
				onChange={(event) => {
					setFieldValue("title", event.target.value);
				}}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="back" color="blue" onClick={previous}>
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					disabled={!values.title}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const instructionStep = ({
	values,
	setFieldValue,
	next,
	previous,
	goTo,
	keyStrokeHandler,
}) => ({
	id: "instruction",
	label: "Instruction",
	render: () => (
		<div>
			<StepTitle>Instruction:</StepTitle>
			<Input
				textArea={true}
				name={"instruction"}
				value={values.instruction || ""}
				onChange={(event) => {
					setFieldValue("instruction", event.target.value);
				}}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="back" color="blue" onClick={previous}>
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					disabled={!values.instruction}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const groupStep = ({
	previous,
	goTo,
	values,
	setFieldValue,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "group",
	label: "Group",
	render: () => (
		<div>
			<StepTitle>Select the groups this instruction belongs to</StepTitle>
			<Dropdown
				isMulti={true}
				options={wizardProps.accessGroups.map((e) => ({
					value: e.OrganizationAccessGroupID,
					label: e.Name,
				}))}
				value={values.groups}
				onChange={(value) => {
					setFieldValue("groups", value);
					setFieldValue(
						"groupIds",
						value ? value.map((_value) => _value.value).toString() : ""
					);
					setFieldValue(
						"groupNames",
						value ? value.map((_value) => _value.label).join(", ") : ""
					);
				}}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="previous" onClick={previous} color="blue">
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => goTo("summary")}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const summaryStep = ({
	goTo,
	handleSubmit,
	isSubmitting,
	setFieldValue,
	values,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "summary",
	label: "Summary",
	render: () => (
		<div>
			<StepTitle>Summary</StepTitle>
			<SummaryTable
				items={[
					{
						title: "Type",
						value: values.type,
						key: "type",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("type");
						},
					},
					{
						title: "Title",
						value: values.title,
						key: "title",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("title");
						},
					},
					{
						title: "Instruction",
						value: values.instruction,
						key: "instruction",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("instruction");
						},
					},
					{
						title: "Groups",
						value: values.groupNames,
						key: "group",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("group");
						},
					},
				]}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					{wizardProps.mode === "edit-special-instruction"
						? "Update"
						: "Create"}
				</Button>,
			]}
		/>
	),
});

export const deleteStep = ({
	handleSubmit,
	isSubmitting,
	values,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "delete",
	label: "Delete Special Instruction",
	render: () => (
		<div>
			<StepText>
				Are you sure you want to delete Special Instruction{" "}
				<b>{values.title}</b>? This action CANNOT be undone.
			</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					Delete Special Instruction
				</Button>,
			]}
		/>
	),
});
