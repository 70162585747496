import * as installationApi from "../../../api/installation";
import { constants, errors as errorMessages } from "../../../helpers";
import Alert from "react-s-alert";

export async function addGateController(props, values, mutation) {
	try {
		await mutation({
			variables: {
				nodeId: values.nodeId,
				siteId: props.siteId,
				laneId: props.lane.LaneID,
				name: values.name,
				accessType: values.accessType,
				communicationMethod: values.communicationMethod,
				loopDetection: !!values.loopDetection,
				gateOpenSignal: !!values.gateOpenSignal,
				gateCloseRelay: !!values.gateCloseRelay,
				forceSessionlessAccess: !!values.forceSessionlessAccess,
				restrictedAccess: !!values.restrictedAccess,
				position: props.node.Position,
				gateTxPower: values.gateTxPower,
				txPower: values.txPower,
				attenuation: values.attenuation,
				qrCode: values.qrCode,
				isBoatRamp: values.isBoatRamp,
				showAccessButton: values.showAccessButton,
			},
		});

		Alert.success("Gate controller added");
		props.close();
	} catch (error) {
		Alert.error("Something went wrong. Please try again.");
		props.close(true);
	}
}

export async function updateGateController(props, values, mutation) {
	try {
		await mutation({
			variables: {
				nodeId: values.nodeId,
				siteId: props.siteId,
				laneId: props.lane.LaneID,
				name: values.name,
				accessType: values.accessType,
				communicationMethod: values.communicationMethod,
				loopDetection: !!values.loopDetection,
				gateOpenSignal: !!values.gateOpenSignal,
				gateCloseRelay: !!values.gateCloseRelay,
				forceSessionlessAccess: !!values.forceSessionlessAccess,
				restrictedAccess: !!values.restrictedAccess,
				position: props.node.Position,
				gateTxPower: values.gateTxPower,
				txPower: values.txPower,
				attenuation: values.attenuation,
				qrCode: values.qrCode,
				isBoatRamp: values.isBoatRamp,
				showAccessButton: values.showAccessButton,
			},
		});

		Alert.success("Gate controller updated");
		props.close(true);
	} catch (error) {
		Alert.error("Something went wrong. Please try again.");
		props.close(true);
	}
}

export async function adminRefreshSettings(props, values, mutation) {
	try {
		await mutation({
			variables: {
				nodeId: values.nodeId,
			},
		});

		Alert.success("Gate controller settings will be refreshed shortly");
		props.close(true);
	} catch (error) {
		Alert.error("Something went wrong. Please try again.");
		props.close(true);
	}
}

export async function deleteGateController(props, values, mutation) {
	try {
		await mutation({
			variables: {
				nodeId: props.node.NodeID,
				siteId: props.siteId,
				position: props.node.Position,
			},
		});

		Alert.success("Gate controller deleted");
		props.close();
	} catch (error) {
		Alert.error("Something went wrong. Please try again.");
		props.close(true);
	}
}

export async function toggleGateControllerState(props, values, mutation) {
	try {
		await mutation({
			variables: {
				nodeId: props.node.NodeID,
				siteId: props.siteId,
			},
		});

		Alert.success("State updated");
		props.close();
	} catch (error) {
		Alert.error("Something went wrong. Please try again.");
		props.close(true);
	}
}

export async function pingGate(props, values, query) {
	try {
		const { data } = await query({
			nodeId: values.nodeId,
			siteId: props.siteId,
		});

		if (data.pingDevice === true) {
			Alert.success("Gate pinged successfully");
			props.close();
		} else if (data.pingDevice === false) {
			Alert.warning("Gate is offline. Ping gate again?");
		} else {
			Alert.error("Something went wrong. Please try again.");
			props.close();
		}
	} catch (error) {
		Alert.error("Something went wrong. Please try again.");
		props.close();
	}
}

export async function adminPingGate(props, values, query) {
	try {
		const { data } = await query({ nodeId: values.nodeId });

		if (data.pingDevice === true) {
			Alert.success("Gate pinged successfully");
			props.close();
		} else if (data.pingDevice === false) {
			Alert.warning("Gate is offline. Ping gate again?");
		} else {
			Alert.error("Something went wrong. Please try again.");
			props.close();
		}
	} catch (error) {
		Alert.error("Something went wrong. Please try again.");
		props.close();
	}
}

export async function openGate(props, values) {
	try {
		const response = await installationApi.triggerNode(
			props.siteId,
			values.nodeId
		);

		//If a bluetooth response came back, the opening failed
		if (response.openGateCommand) {
			await installationApi.clientSourcedNoteInteractionResult(
				response.data.openGateCommand.encryptedCommand,
				"Failed"
			);
			var error = new Error();
			error.code = "GateError";
			throw error;
		}

		Alert.success("Gate opened");
		props.close(true);
	} catch (_error) {
		if (
			_error.errors &&
			_error.errors[0] &&
			_error.errors[0].code === "GateError"
		) {
			Alert.error("Gate did not open.");
		} else {
			Alert.error("Something went wrong. Please try again.");
		}
		props.close(true);
	}
}

export async function closeGate(props, values) {
	try {
		await installationApi.closeGate(props.siteId, values.nodeId);

		Alert.success("Gate closed");
		props.close(true);
	} catch (error) {
		Alert.error("Something went wrong. Please try again.");
		props.close(true);
	}
}

export async function addGroup(props, values) {
	try {
		//not actually storing an empty group

		Alert.success("Group added");
		props.close({ Name: values.groupName });
	} catch (error) {
		Alert.error("Something went wrong. Please try again.");
		props.close(true);
	}
}

export async function updateGroup(props, values, mutation) {
	try {
		await mutation({
			variables: {
				laneIds: props.group.Lanes
					? props.group.Lanes.map((lane) => lane.LaneID)
					: [],
				groupName: values.groupName,
			},
		});

		Alert.success("Group updated");
		props.close({ Name: values.groupName, oldName: props.group.Name });
	} catch (error) {
		Alert.error("Something went wrong. Please try again.");
		props.close(true);
	}
}

export async function removeGroup(props, values, mutation) {
	try {
		await mutation({
			variables: {
				laneIds: props.group.Lanes
					? props.group.Lanes.map((lane) => lane.LaneID)
					: [],
			},
		});

		Alert.success("Group deleted");
		props.close(props.group);
	} catch (error) {
		Alert.error("Something went wrong. Please try again.");
		props.close(true);
	}
}

export async function addLane(props, values, mutation) {
	try {
		const lane = {
			installationId: props.installationId,
			name: values.laneName,
			isReversible: values.isReversible,
			groupName: values.groupName,
			groupOrder: values.nextOrder,
		};
		await mutation({
			variables: lane,
		});

		Alert.success("Lane added");
		props.close();
	} catch (error) {
		Alert.error("Something went wrong. Please try again.");
		props.close(true);
	}
}

export async function updateLane(props, values, mutation) {
	try {
		const lane = {
			laneId: props.lane.LaneID,
			name: values.laneName,
			isReversible: values.isReversible,
			groupName: values.groupName,
			groupOrder: values.nextOrder,
		};
		const {
			data: {
				updateLane: { LaneID: laneId },
			},
		} = await mutation({
			variables: lane,
		});

		Alert.success("Lane added");

		const currentLane = props.group.Lanes.find((ln) => ln.LaneID === laneId);
		currentLane.Name = lane.name;
		currentLane.IsReversible = lane.isReversible;
		props.close();
	} catch (error) {
		Alert.error("Something went wrong. Please try again.");
		props.close(true);
	}
}

export async function updateLaneStatus(props, values, mutation) {
	try {
		await mutation({
			variables: {
				laneId: props.lane.LaneID,
				reason: values.didNotInstallReason,
				position: props.node.Position,
				status: constants.LANE_STATUSES.DID_NOT_INSTALL,
			},
		});

		Alert.success("Lane updated");
		props.close();
	} catch (error) {
		Alert.error("Something went wrong. Please try again.");
		props.close(true);
	}
}

export async function removeLaneStatus(props, values, mutation) {
	try {
		await mutation({
			variables: {
				laneId: props.lane.LaneID,
				position: props.node.Position,
				status: null,
			},
		});

		Alert.success("Status removed");
		props.close();
	} catch (error) {
		Alert.error("Something went wrong. Please try again.");
		props.close(true);
	}
}

export async function removeLane(props, values, mutation) {
	try {
		await mutation({
			variables: {
				laneId: props.lane.LaneID,
			},
		});

		Alert.success("Lane deleted");
		props.close();
	} catch (error) {
		Alert.error("Something went wrong. Please try again.");
		props.close(true);
	}
}

export async function updateNode(props, values, mutation) {
	try {
		await mutation({
			variables: {
				nodeId: values.nodeId,
				clientId: values.clientId,
				status: values.status,
				gateTxPower: values.gateTxPower,
				txPower: values.txPower,
				attenuation: values.attenuation,
			},
		});

		Alert.success("Gate Controller updated");
		props.close(true);
	} catch (error) {
		Alert.error("Something went wrong. Please try again.");
		props.close(true);
	}
}

export async function createInstallation(props, values, mutation) {
	try {
		await mutation({
			variables: {
				siteId: props.site.SiteID,
				parentInstallationId: values.createFromExistingInstallation
					? values.parentInstallationId
					: null,
			},
		});
		Alert.success("Installation created");
		props.close(true);
	} catch (error) {
		if (
			error.graphQLErrors &&
			error.graphQLErrors.length &&
			error.graphQLErrors[0].extensions &&
			error.graphQLErrors[0].extensions.exception &&
			error.graphQLErrors[0].extensions.exception.code
		) {
			const code = error.graphQLErrors[0].extensions.exception.code;
			switch (code) {
				case "InvalidSharedInstallation":
					Alert.error(errorMessages.INVALID_SHARED_INSTALLATION);
					break;
				case "InvalidSharedInstallationViaGroup":
					Alert.error(
						"This site has at least one group that gives user's access to multiple sites that are apart of a shared installation. Users can have access to just one site that is apart of a shared installation"
					);
					break;
				case "InvalidSharedInstallationViaPlate":
					Alert.error(errorMessages.INVALID_SHARED_INSTALLATION_VIA_PLATE);
					break;
				default:
					Alert.error("Something went wrong. Please try again.");
					break;
			}
			return;
		} else {
			Alert.error("Something went wrong. Please try again.");
		}

		props.close(true);
	}
}

export async function deleteInstallation(props, values, mutation) {
	try {
		await mutation({
			variables: {
				installationId: props.installationId,
			},
		});
		Alert.success("Installation deleted");
		props.close(true);
	} catch (error) {
		Alert.error("Something went wrong. Please try again.");
		props.close(true);
	}
}

export async function removeInstallationFromSite(props, values, mutation) {
	try {
		await mutation({
			variables: {
				siteId: props.siteId,
			},
		});
		Alert.success("Installation removed");
		props.close(true);
	} catch (error) {
		Alert.error("Something went wrong. Please try again.");
		props.close(true);
	}
}
