import { basePath } from "../.";

async function downloadDocumentation(apiKey) {
	const response = await fetch(`${basePath}/shared/v1/admin/documentation`, {
		method: "GET",
		headers: {
			Authorization: apiKey,
		},
	});

	const filename =
		response.headers
			.get("Content-Disposition")
			?.match(/filename="(.+?)"/)?.[1] || "inugo-api-documentation.pdf";

	const blob = await response.blob();
	const url = window.URL.createObjectURL(blob);

	const a = document.createElement("a");
	a.href = url;
	a.download = filename;

	a.click();

	window.URL.revokeObjectURL(url);
}

export { downloadDocumentation };
