import moment from "moment";
import Button from "../../layout/Button";
import { StepTitle } from "../WizardLayout";
import WizardNavigation from "../WizardNavigation";
import styled from "styled-components";
import { defaultTimeFormat } from "../../../helpers/constants";

export const Label = styled.strong`
	padding-right: 1rem;
	padding-bottom: 1rem;
`;

const StepText = styled.div`
	font-size: 20px;
	font-weight: 600;
	line-height: 1.3em;
	padding-bottom: 1rem;
	word-wrap: break-word; /* Allows long words to be able to break and wrap onto the next line */
`;
const BrandDetails = styled.div`
	display: flex;
	align-items: center;
`;

const DetailLabel = styled.div`
	margin-right: 10px;
`;

export const ColorCircle = styled.div`
	width: 20px;
	height: 20px;
	border-radius: 50%;
	margin-right: 20px;
`;

export const ColorValue = styled.div`
	margin-left: 10px;
	margin-right: 20px;
`;

const LogoImage = styled.img`
	width: 50px;
	height: 50px;
	margin-left: 10px;
`;

export const ViewEventStep = ({
	values,
	setFieldValue,
	handleSubmit,
	// isSubmitting,
}) => {
	const {
		event,
		eventSeries,
		brand: { SecondaryColor, MainColor, IconLogo },
		sites,
		groups,
	} = values;

	return {
		id: "eventDetails",
		label: "Event Details",
		render: () => (
			<div>
				<StepTitle>Event Details</StepTitle>
				<StepText>
					<Label>Event Name:</Label> {event.name}
				</StepText>
				<StepText>
					<Label>Start Date:</Label>{" "}
					{moment
						.unix(event.StartTimestamp)
						.tz(sites?.[0]?.Timezone)
						.format(defaultTimeFormat)}
				</StepText>
				<StepText>
					<Label>End Date:</Label>{" "}
					{moment
						.unix(event.EndTimestamp)
						.tz(sites?.[0]?.Timezone)
						.format(defaultTimeFormat)}
				</StepText>
				<StepText>
					<Label>Event Series:</Label> {eventSeries.Name}
				</StepText>
				<StepText>
					<Label>Brand:</Label>
					<BrandDetails>
						<DetailLabel>Primary Colour:</DetailLabel>
						<ColorCircle style={{ backgroundColor: MainColor }} />
						<ColorValue>{values.PrimaryColour}</ColorValue>
						<DetailLabel>Secondary Colour:</DetailLabel>
						<ColorCircle style={{ backgroundColor: SecondaryColor }} />
						<ColorValue>{values.SecondaryColour}</ColorValue>
						<DetailLabel>Logo:</DetailLabel>
						<LogoImage src={IconLogo} alt="Brand Logo" />
					</BrandDetails>
				</StepText>
				<StepText>
					<Label>Sites:</Label> {sites.map((site) => site.Name).join(", ")}
				</StepText>
				<StepText>
					<Label>Groups:</Label> {groups.map((group) => group.Name).join(", ")}
				</StepText>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={[
					<Button
						key="delete"
						color="red"
						onClick={() => {
							setFieldValue("isTryingToDelete", true);
							handleSubmit();
						}}
					>
						Cancel Event
					</Button>,
				]}
				rightItems={[
					<Button
						key="edit"
						color="green"
						onClick={() => {
							setFieldValue("isTryingToEdit", true);
							handleSubmit();
						}}
					>
						Edit Event
					</Button>,
				]}
			/>
		),
	};
};
