import * as Yup from "yup";
import {
	createStep,
	deleteBlueprintStep,
	deleteLevelStep,
	editStep,
	selectFloorStep,
	uploadImageStep,
} from "./floor-plan-steps";
import Alert from "react-s-alert";
import React from "react";
import WizardLayout from "../WizardLayout";

export function CreateLevelWizard(props) {
	return (
		<WizardLayout
			close={props.close}
			title={"Create Level"}
			values={[]}
			onSubmit={async (values) => {
				const { levelName } = values;
				const newFloor = { value: levelName, label: levelName };
				const duplicate = props.floors.find(
					(floor) => floor.label === newFloor.label
				);
				// should move this server side
				if (!duplicate) {
					props.onSubmit(newFloor);
					props.close();
				} else {
					Alert.error(
						"Cannot add multiple floors with the same name for a site."
					);
				}
			}}
			steps={[createStep]}
			initialStep={0}
			wizardProps={props}
		/>
	);
}

export function DeleteLevelWizard(props) {
	return (
		<WizardLayout
			close={props.close}
			title={"Delete Level"}
			values={[
				{ name: "name", value: props.floorName || "", validator: Yup.string() },
			]}
			onSubmit={async () => {
				props.onSubmit();
				props.close();
			}}
			steps={[deleteLevelStep]}
			initialStep={0}
			wizardProps={props}
		/>
	);
}

export function UpdateLevelWizard(props) {
	return (
		<WizardLayout
			close={props.close}
			title={"Update Level"}
			values={[
				{ name: "name", value: props.floorName || "", validator: Yup.string() },
			]}
			onSubmit={async (values) => {
				props.onSubmit(values.name);
				props.close();
			}}
			steps={[editStep]}
			initialStep={0}
			wizardProps={props}
		/>
	);
}

export function UploadBlueprint(props) {
	return (
		<WizardLayout
			close={props.close}
			title={"Upload Floor Plan"}
			values={[
				{
					name: "availableFloors",
					value: props.floors || [],
					validator: Yup.array(),
				},
				{
					name: "selectedFloor",
					value: props.floors[0],
					validator: Yup.object(),
				},
			]}
			onSubmit={async (values) => {
				await props.onSubmit(values.selectedFloor, values.floorPlan);
				props.close();
			}}
			steps={[selectFloorStep, uploadImageStep]}
			initialStep={0}
			wizardProps={props}
		/>
	);
}

export function DeleteBlueprintWizard(props) {
	return (
		<WizardLayout
			close={props.close}
			title={"Delete Floor Plan Reference"}
			values={[]}
			onSubmit={async () => {
				props.onSubmit();
				props.close();
			}}
			steps={[deleteBlueprintStep]}
			initialStep={0}
			wizardProps={props}
		/>
	);
}
