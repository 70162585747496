import { ChevronDown, ChevronUp } from "react-feather";
import styled, { createGlobalStyle } from "styled-components";
import React from "react";
import { colours } from "../../styles";

const GlobalStyle = createGlobalStyle`
	.active {
		.menu-item {
			position: relative;
			transition: 0.2s ease;
		}

		.menu-item::after {
			content: '';
			background: ${colours.green};
			height: 100%;
			position: absolute;
			top: 0;
			width: 4px;
		}

		.menu-item .left-icon,
		.menu-item .name {
			color: ${colours.green} !important;
			font-weight: 700 !important;
		}
	}
`;

const Item = styled.div`
	color: ${colours.white};
	font-size: 18px;
	font-weight: 600;
	height: 48px;
	overflow-x: hidden;
	position: relative;
	white-space: nowrap;
	width: 100%;

	.name {
		line-height: 48px;
		overflow: hidden;
		padding: 0 56px;
	}

	.content {
		transition: 0.2s ease;

		&:hover {
			cursor: pointer;
			opacity: 0.8;
		}
	}

	.left-icon {
		position: absolute;
		left: 16px;
		top: 12px;
	}

	.right-icon {
		padding-left: 256px;
		position: absolute;
		right: ${(props) => (props.menuCollapsed ? "-30px" : "10px")};
		top: 12px;
		transition: 0.2s ease;
	}
`;

const SubItems = styled.div`
	background-color: ${colours.highlightBlue};
	height: ${(props) =>
		props.subItems && props.expand
			? `${props.subItems.length * 36 + 16}px`
			: 0};
	overflow: hidden;
	padding-top: ${(props) => (props.subItems && props.expand ? `8px` : 0)};
	transition: 0.2s ease;
`;

export default function MenuItem(props) {
	return (
		<div className={`menu-item ${props.className || ""} `}>
			<GlobalStyle />
			<Item
				className="item"
				onClick={props.onClick}
				menuCollapsed={props.menuCollapsed}
			>
				<div className="content">
					{!props.hideLeftIcon && (
						<div className="left-icon">{props.leftIcon}</div>
					)}
					<div className="name">{props.itemName}</div>
					{props.subItems && (
						<div className="right-icon">
							{props.expand ? <ChevronUp /> : <ChevronDown />}
						</div>
					)}
				</div>
			</Item>
			{!props.menuCollapsed && props.subItems && (
				<SubItems subItems={props.subItems} expand={props.expand}>
					{props.subItems}
				</SubItems>
			)}
		</div>
	);
}
