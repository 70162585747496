import Button from "../../layout/Button";
import Card from "../../../components/layout/Card";
import DatePicker from "../../layout/DatePicker";
import Dropdown from "../../layout/Dropdown";
import LoadingPlaceholder from "../../report/LoadingPlaceholder";
import React from "react";
import TableLayout from "../../../components/layout/TableLayout";
import { colours } from "../../../styles";
import styled from "styled-components";

const Wrapper = styled.div`
	display: flex;
	label {
		color: ${colours.midGrey};
		font-size: 18px;
		font-weight: 400;
		pointer-events: none;
		transform: scale(1);
		transform-origin: left top;
		transition: 0.2s ease;
		margin-bottom: 10px;
	}
`;
const columns = () => [
	{
		Header: "Rate",
		id: "Rate",
		accessor: (d) => d.Rate,
	},
	{
		Header: "Duration",
		id: "Duration",
		accessor: (d) => d.Duration,
	},
	{
		Header: "Start",
		id: "Start",
		accessor: (d) => d.Start,
	},
	{
		Header: "End",
		id: "End",
		accessor: (d) => d.End,
	},
	{
		Header: "Fee",
		id: "Fee",
		accessor: (d) => d.Fee,
	},
];

export const testStep = ({
	values,
	setFieldValue,
	wizardProps,
	handleSubmit,
	isSubmitting,
	keyStrokeHandler,
}) => {
	const format = "d M Y h:i:SK";
	return {
		id: "tester",
		render: () => (
			<React.Fragment>
				<Wrapper>
					<div style={{ paddingRight: "10px" }}>
						<label>Entry time</label>
						<DatePicker
							options={{
								pickerMode: "datePicker",
								enableTime: true,
								enableSeconds: true,
								minuteIncrement: 1,
								altFormat: format,
								dateFormat: format,
							}}
							style={{ width: "220px", marginTop: "10px" }}
							value={values.entryTime}
							onChange={([entryTime]) => {
								setFieldValue("entryTime", entryTime);
							}}
						/>
					</div>
					<div>
						<label>Exit time</label>
						<DatePicker
							options={{
								pickerMode: "datePicker",
								enableTime: true,
								enableSeconds: true,
								minuteIncrement: 1,
								altFormat: format,
								dateFormat: format,
							}}
							style={{ width: "220px", marginTop: "10px" }}
							value={values.exitTime}
							onChange={([exitTime]) => {
								setFieldValue("exitTime", exitTime);
							}}
						/>
					</div>
				</Wrapper>
				<Wrapper>
					<div style={{ width: "100%", marginTop: "20px" }}>
						<label>In Access Group</label>
						<div style={{ marginTop: "10px" }}>
							<Dropdown
								isMulti={true}
								options={wizardProps.groups}
								value={values.groups}
								onChange={(groups) => {
									setFieldValue("groups", groups);
								}}
							/>
						</div>
					</div>
				</Wrapper>
				<Wrapper>
					<div style={{ width: "120%", marginTop: "20px" }}>
						<label>Validation Beacons</label>
						<div style={{ marginTop: "10px" }}>
							<Dropdown
								isMulti={true}
								options={wizardProps.beacons}
								value={values.beacons}
								onChange={(beacons) => {
									setFieldValue("beacons", beacons);
								}}
							/>
						</div>
					</div>
				</Wrapper>
				<Wrapper>
					<Button
						style={{ width: "100%", marginTop: "20px" }}
						key="calculate"
						color="blue"
						disabled={isSubmitting}
						onClick={handleSubmit}
						keyStrokeHandler={keyStrokeHandler}
					>
						Calculate
					</Button>
				</Wrapper>
				{isSubmitting && <LoadingPlaceholder />}
				{!isSubmitting && values.results && values.results.length && (
					<React.Fragment>
						<Wrapper>
							<div style={{ width: "100%", marginTop: "-10px" }}>
								<Card
									style={{
										backgroundColor: colours.green,
										color: colours.white,
									}}
								>
									<h2 style={{ margin: 0 }}>{values.finalFee}</h2>
									<p
										style={{
											fontSize: "10pt",
											margin: 0,
											color: colours.lightGrey,
										}}
									>
										TOTAL COST
									</p>
								</Card>
							</div>
						</Wrapper>
						<Wrapper>
							<div style={{ width: "100%" }}>
								<TableLayout
									columns={columns({})}
									showPagination={false}
									data={values.results}
									sortable={false}
									getTrProps={(state, rowInfo) => {
										if (rowInfo && rowInfo.original) {
											if (rowInfo.original.isSubcharge) {
												return { style: { fontSize: 12, fontWeight: 700 } };
											}

											return {};
										}
									}}
								/>
							</div>
						</Wrapper>
					</React.Fragment>
				)}
			</React.Fragment>
		),
	};
};
