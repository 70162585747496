import * as Yup from "yup";
import * as api from "./api";
import * as sessionSteps from "./session-steps";
import React from "react";
import WizardLayout from "../WizardLayout";

const constructWizard = (props) => {
	switch (props.mode) {
		case "start-session":
			return {
				initialStep: 0,
				steps: [sessionSteps.chooseSiteStep, sessionSteps.enterUserStep],
			};
		case "end-session":
			return {
				initialStep: 0,
				steps: [sessionSteps.endSessionStep],
			};
		case "open-gate":
			return {
				initialStep: 0,
				steps: [
					sessionSteps.chooseSiteStep,
					sessionSteps.chooseGateStep,
					sessionSteps.reasonStep,
				],
			};
		case "start-session-for-user":
			return {
				initialStep: 0,
				steps: [sessionSteps.chooseSiteStep],
			};
		case "start-session-for-global-user":
			return {
				initialStep: 0,
				steps: [sessionSteps.chooseOrgAndSiteStep],
			};
	}
};

export default function SessionWizard(props) {
	const wizard = constructWizard(props);

	const chargeEnum = { NO_CHARGE: 0, PARTIAL_CHARGE: 1, FULL_CHARGE: 2 };

	return (
		<WizardLayout
			close={props.close}
			title={(values) => {
				let _title = values.mode.replace(/-/g, " ");
				//capitalize the 1st letter of each word
				_title = _title.replace(/\b[a-z](?=[a-z]{2})/g, (letter) =>
					letter.toUpperCase()
				);

				return _title;
			}}
			values={[
				{ name: "mode", value: props.mode, validator: Yup.string() },
				{
					name: "session",
					value: props.session,
					validator: Yup.object().nullable(),
				},
				{
					name: "sites",
					value: props.sites,
					validator: Yup.array(),
				},
				{
					name: "user",
					value: props.user,
					validator: Yup.object().nullable(),
				},
				{
					name: "chargeEnum",
					value: chargeEnum,
					validator: Yup.object().shape({
						NO_CHARGE: Yup.number(),
						PARTIAL_CHARGE: Yup.number(),
						FULL_CHARGE: Yup.number(),
					}),
				},
				{
					name: "chargeType",
					value: chargeEnum.FULL_CHARGE,
					validator: Yup.number(),
				},
				{
					name: "manualEndTime",
					value: null,
					validator: Yup.number().nullable(),
				},
			]}
			onSubmit={(values, { setSubmitting }) => {
				setSubmitting(true);
				if (
					values.mode === "start-session" ||
					values.mode === "start-session-for-user" ||
					values.mode === "start-session-for-global-user"
				) {
					api.startSessionManually(props, values, setSubmitting);
				} else if (
					values.mode === "end-session" &&
					values.chargeType !== chargeEnum.NO_CHARGE
				) {
					if (values.chargeType === chargeEnum.FULL_CHARGE) {
						values.manualEndTime = null;
					}
					api.endSessionManually(props, values, setSubmitting);
				} else if (
					values.mode === "end-session" &&
					values.chargeType === chargeEnum.NO_CHARGE
				) {
					api.forceCloseSession(props, values, setSubmitting);
				} else if (values.mode === "open-gate") {
					api.openGateManually(props, values, setSubmitting);
				}
			}}
			steps={wizard.steps}
			initialStep={wizard.initialStep}
			wizardProps={props}
		/>
	);
}
