import { boxShadows, colours } from "../../styles";
import Loader from "./Loader";
import React from "react";
import { Search } from "react-feather";
import styled from "styled-components";

const Wrapper = styled.div`
	background: ${colours.background} !important;
	border-radius: 4px;
	box-shadow: ${boxShadows.light};
	height: 40px;
	padding: ${(props) => (props.showLoader ? "8px 32px 8px 8px" : "8px")};
	position: relative;

	input {
		background-color: inherit;
		border: 0;
		color: ${colours.darkGrey};
		font-size: 18px;
		outline: 0;
		padding-left: 32px;
		width: 100%;
		text-transform: ${(props) =>
			props.forceUppercase ? "uppercase" : "unset"};
	}

	svg {
		position: absolute;
		left: 8px;
		top: 10px;
	}
`;

const InlineLoader = styled(Loader)`
	position: absolute;
	right: 12px;
	top: 12px;
`;

export default function SearchBox(props) {
	const { onChange, onKeyUp, ...rest } = props;
	return (
		<Wrapper {...rest} showLoader={props.showLoader}>
			<Search size={20} />
			<input
				onChange={(event) => onChange(event.target.value)}
				onKeyUp={(event) => onKeyUp?.(event)}
				placeholder={props.placeholder || "Search"}
				value={props.value}
			/>
			{props.showLoader && <InlineLoader size={16} thickness={2} />}
		</Wrapper>
	);
}
