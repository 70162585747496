import React from "react";
import { FieldContent } from "./styles";
import Dropdown from "../../layout/Dropdown";
import { useGetLeaseParksForSiteMaps } from "../../../containers/organization/queries/eventsApiHooks";
import LoadingPlaceholder from "../../report/LoadingPlaceholder";
import FieldError from "../../layout/FieldError";

export const SpaceSelect = ({
	selectedSite,
	existingSpaceForSite,
	selectedSpace,
	onChange,
	error,
}) => {
	const { leaseParks, isLoading } = useGetLeaseParksForSiteMaps([selectedSite]);

	if (isLoading) {
		return <LoadingPlaceholder />;
	}

	const spaceOptions = leaseParks
		?.filter((lp) => lp.LeaseParkID !== existingSpaceForSite?.value)
		.map((b) => ({
			value: b.LeaseParkID,
			label: b.Name,
		}));

	return (
		<div style={{ display: "flex", flexDirection: "column" }}>
			<FieldContent>
				<Dropdown
					isMulti={false}
					options={spaceOptions}
					value={selectedSpace}
					onChange={onChange}
					style={{ width: 250 }}
					disabled={!selectedSite}
					placeholder="Select a space"
				/>
			</FieldContent>
			<FieldError showError={error} errorText={error} />
		</div>
	);
};
