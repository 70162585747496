import { booleanValueFormatter } from "../../../../components/layout/TableLayout";

export const usersColumns = (props) =>
	[
		{
			id: "UserID",
			Header: "Status",
			accessor: (d) => d.status,
			width: 200,
		},
		{
			id: "FirstName",
			Header: "First Name",
			accessor: (d) => d.FirstName || "",
			highlightCell: (cellProps) => cellProps.original.FirstName || "",
			className: "bold",
		},
		{
			id: "LastName",
			Header: "Last Name",
			accessor: (d) => d.LastName || "",
			highlightCell: (cellProps) => cellProps.original.LastName || "",
			className: "bold",
		},
		{
			id: "Email",
			Header: "Email",
			accessor: (d) => d.Email,
			highlightCell: (cellProps) => cellProps.original.Email,
		},
		{
			id: "displayOrganizationAccessGroups",
			Header: "Assigned Groups",
			accessor: (d) => d.displayOrganizationAccessGroups,
			highlightCell: (cellProps) =>
				cellProps.original.displayOrganizationAccessGroups,
		},
		!props.spaceAccountOwnerOnly && {
			id: "hasPermissions",
			Header: "Special Permissions",
			accessor: (d) => d.hasPermissions,
			Cell: booleanValueFormatter,
			filterMethod: (_filter, row) => {
				if (_filter.value === "all") {
					return true;
				}
				if (_filter.value === "true") {
					return row[_filter.id];
				}
				return !row[_filter.id];
			},
			width: 200,
		},
	].filter((e) => e);

export const usersDefaultSorting = [
	{
		id: "Email",
		desc: false,
	},
];
