import { getJSON, postJSON, putJSON } from "../index";
import qs from "qs";

const basePath = "/session/api";

export function updateUserLeaseTimeframes(organizationId, userLeaseId, data) {
	return putJSON(
		`${basePath}/v1/admin/organization/${organizationId}/user-lease/${userLeaseId}/timeframes`,
		data
	);
}

export function createRefundForParkingSession(siteId, parkingSessionId, data) {
	return postJSON(
		`${basePath}/admin/sites/${siteId}/parking-session/${parkingSessionId}/refund`,
		data
	);
}

export function getSession(siteId, sessionId, options) {
	const params = qs.stringify(options);
	const query = params ? `?${params}` : "";

	return getJSON(
		`${basePath}/admin/site/${siteId}/parking-session/${sessionId}${query}`
	);
}

export function getSessionReport(organizationId, options) {
	const params = qs.stringify(options);

	return getJSON(
		`${basePath}/v1/admin/organization/${organizationId}/sessions/report?${params}`
	);
}

export function getSessions(organizationId, options) {
	const params = qs.stringify(options);

	return getJSON(
		`${basePath}/v1/admin/organization/${organizationId}/parking-sessions?${params}`
	);
}

export function getSite(siteId) {
	return getJSON(`${basePath}/site/${siteId}`);
}

export function getValidatedSessionsByOrganizationId(organizationId, options) {
	const params = qs.stringify(options);

	return getJSON(
		`${basePath}/v1/admin/organization/${organizationId}/validated-sessions?${params}`
	);
}

export function startSession(siteId, data) {
	return postJSON(`${basePath}/v1/admin/site/${siteId}/parking-session`, data);
}

export function endSession(siteId, data) {
	return putJSON(`${basePath}/v1/admin/site/${siteId}/parking-session`, data);
}

export function forceCloseSession(siteId, parkingSessionId) {
	return putJSON(
		`${basePath}/v1/admin/site/${siteId}/parking-session/${parkingSessionId}/force-close`
	);
}

export function createExternalUserLease(siteId, leaseData) {
	return postJSON(
		`${basePath}/v0/admin/site/${siteId}/user-lease/external`,
		leaseData
	);
}

export function cancelUserLease(siteId, userLeaseId) {
	return putJSON(
		`${basePath}/v1/admin/site/${siteId}/user-lease/${userLeaseId}/close`
	);
}

export function cancelUserLeaseAndReleaseSpace(siteId, userLeaseId) {
	return putJSON(
		`${basePath}/v1/admin/site/${siteId}/user-lease/${userLeaseId}/close-with-release`
	);
}

export function getUserLease(siteId, userLeaseId) {
	return getJSON(
		`${basePath}/v1/admin/site/${siteId}/user-lease/${userLeaseId}/?include=user,lease-payments`
	);
}

export function getCurrentActiveSessionByUser(userId, siteId) {
	const data = {
		userId: userId,
		siteId: siteId,
	};

	return postJSON(`${basePath}/v1/admin/sessions/user`, data);
}

export function getIntersectingUserLeases(siteId, options) {
	const params = qs.stringify(options);

	return getJSON(
		`${basePath}/v0/admin/site/${siteId}/intersecting-user-leases?${params}`
	);
}

export function getSessionsByUserForOrg(organizationId, userId, options) {
	const params = qs.stringify(options);
	const query = params ? `?${params}` : "";

	return getJSON(
		`${basePath}/v1/admin/organization/${organizationId}/user/${userId}/sessions${query}`
	);
}

export function getLeasesByUserForOrg(organizationId, userId, options) {
	const params = qs.stringify(options);
	const query = params ? `?${params}` : "";

	return getJSON(
		`${basePath}/v1/admin/organization/${organizationId}/user/${userId}/leases${query}`
	);
}

export function getSessionsByUserGlobally(userId, options) {
	const params = qs.stringify(options);
	const query = params ? `?${params}` : "";

	return getJSON(`${basePath}/v1/admin/global/user/${userId}/sessions${query}`);
}

export function getLeasesByUserGlobally(userId, options) {
	const params = qs.stringify(options);
	const query = params ? `?${params}` : "";

	return getJSON(`${basePath}/v1/admin/global/user/${userId}/leases${query}`);
}
