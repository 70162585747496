import React from "react";
import EditableInputField from "../../../../layout/EditableInputField";
import DatePicker from "../../../../layout/DatePicker";
import { Label, FieldContent } from "../../styles";
import moment from "moment";

export const SingleEvent = ({
	event,
	index,
	values,
	setFieldValue,
	errors,
}) => {
	event.startTime = event.startTime ? new Date(event.startTime) : null;
	event.endTime = event.endTime ? new Date(event.endTime) : null;

	const handleDateChange = (date, propName) => {
		let items = [...values.events];
		if (date) {
			items[index][propName] = date.getTime();
			setFieldValue("events", items);
		} else {
			items[index][propName] = null;
			setFieldValue("events", items);
		}
	};

	const handleStartDateChange = ([date]) => handleDateChange(date, "startTime");
	const handleEndDateChange = ([date]) => handleDateChange(date, "endTime");

	return (
		<div
			style={{
				display: "flex",
				alignItems: "stretch",
				gap: 15,
				flexDirection: "column",
				marginTop: 15,
				flexGrow: 0.015,
			}}
		>
			<div>
				<FieldContent>
					<EditableInputField
						type="text"
						name={`events.${index}.name`}
						useLabel="Event Name"
						value={event.name || ""}
						onChange={({ target: { value } }) => {
							let items = [...values.events];
							items[index].name = value || "";
							setFieldValue("events", items);
						}}
						error={
							errors.events && errors.events[index] && errors.events[index].name
						}
					/>
				</FieldContent>
			</div>
			<div
				style={{
					display: "flex",
					gap: 4,
				}}
			>
				<div
					style={{
						flexGrow: 1,
					}}
				>
					<Label>Start Date</Label>
					<FieldContent style={{ display: "flex" }}>
						<DatePicker
							name={`events.${index}.startTime`}
							options={{
								pickerMode: "datePicker",
								enableTime: true,
								enableSeconds: true,
								minuteIncrement: 1,
								altFormat: "d M Y h:i:SK",
								dateFormat: "d M Y h:i:SK",
							}}
							value={event.startTime}
							onChange={handleStartDateChange}
							error={
								errors.events &&
								errors.events[index] &&
								errors.events[index].startTime
							}
							style={{ width: "100%" }}
						/>
					</FieldContent>
				</div>
				<div
					style={{
						flexGrow: 1,
					}}
				>
					<Label>End Date</Label>
					<FieldContent style={{ display: "flex" }}>
						<DatePicker
							name={`events.${index}.endTime`}
							options={{
								pickerMode: "datePicker",
								enableTime: true,
								enableSeconds: true,
								minuteIncrement: 1,
								altFormat: "d M Y h:i:SK",
								dateFormat: "d M Y h:i:SK",
								minDate: event.startTime
									? moment(event.startTime).add(1, "hours").toDate()
									: undefined,
							}}
							value={event.endTime}
							onChange={handleEndDateChange}
							error={
								errors.events &&
								errors.events[index] &&
								errors.events[index].endTime
							}
							style={{ width: "100%" }}
						/>
					</FieldContent>
				</div>
			</div>
		</div>
	);
};
