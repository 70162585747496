import { StepTitle, StepText } from "../WizardLayout";
import Button from "../../layout/Button";
import Input from "../../layout/Input";
import React from "react";
import SummaryTable from "../SummaryTable";
import WizardNavigation from "../WizardNavigation";
import Dropdown from "../../layout/Dropdown";
import {
	OFFENCE_EVENTS,
	OFFENCE_EVENTS_TRANSLATIONS,
} from "../../../helpers/constants";
import { isNil, isNumber } from "lodash";
import InfoBox from "../../layout/InfoBox";

const offenceEventOptions = Object.values(OFFENCE_EVENTS).map((event) => ({
	label: OFFENCE_EVENTS_TRANSLATIONS[event] ?? "",
	value: event,
}));

function WizardFooter({
	wizardProps,
	values,
	keyStrokeHandler,
	disabled,
	goTo,
	next,
}) {
	return (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="cancel" onClick={wizardProps.close} color="blue">
						Cancel
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					disabled={disabled}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	);
}

function getMinGracePeriod(offenceEvent) {
	switch (offenceEvent) {
		case OFFENCE_EVENTS.PARKING_OVERSTAY:
		case OFFENCE_EVENTS.NOT_PAID:
			return 0;
		case OFFENCE_EVENTS.PERMIT_OVERSTAY:
			return 10;
		default:
			return null;
	}
}

function MinNumberInput({ values, field, min, max, value, setFieldValue }) {
	return (
		<Input
			type="number"
			min={min()}
			max={max()}
			onChange={(event) => {
				const val = event.target.value;
				setFieldValue(field, isNil(val) ? null : parseInt(val));
			}}
			value={value}
			onBlur={() => {
				const minValue = min();
				if (!values[field] || values[field] < minValue) {
					setFieldValue(field, minValue);
				}
			}}
		/>
	);
}

export const ruleStep = (props) => {
	const { values, setFieldValue } = props;

	const isDisabled = () => {
		if (!values.offenceCode || !values.offenceEvent) {
			return true;
		}

		switch (values.offenceEvent) {
			case OFFENCE_EVENTS.NOT_PAID:
				return (
					!isNumber(values.entryGracePeriod) ||
					!isNumber(values.exitGracePeriod)
				);
			case OFFENCE_EVENTS.PARKING_OVERSTAY:
				return (
					!values.timeRestriction ||
					!isNumber(values.entryGracePeriod) ||
					!isNumber(values.exitGracePeriod)
				);
			case OFFENCE_EVENTS.PERMIT_OVERSTAY:
				return !values.entryGracePeriod || !values.exitGracePeriod;
			default:
				return false;
		}
	};

	return {
		id: "rule",
		label: "Rule",
		render: () => (
			<div>
				<StepTitle>What is the Enforcement Rule?</StepTitle>
				<h3>Offence Code</h3>
				<Input
					type="text"
					onChange={(event) => setFieldValue("offenceCode", event.target.value)}
					value={values.offenceCode || ""}
				/>
				<h3>Offence Event</h3>
				<Dropdown
					options={offenceEventOptions}
					value={offenceEventOptions.find(
						(o) => o.value === values.offenceEvent
					)}
					onChange={(option) => {
						const offenceEvent = option.value;
						setFieldValue("entryGracePeriod", getMinGracePeriod(offenceEvent));
						setFieldValue("exitGracePeriod", getMinGracePeriod(offenceEvent));
						setFieldValue("offenceEvent", option.value);
					}}
				/>

				<h3>Priority</h3>
				<InfoBox
					style={{ marginBottom: "8px" }}
					text="Rules are run in order of priority, where a higher priority takes precedence."
				/>
				<MinNumberInput
					min={() => 0}
					max={() => 100}
					value={values.priority}
					values={values}
					setFieldValue={setFieldValue}
					field={"priority"}
				/>

				{values.offenceEvent === OFFENCE_EVENTS.PARKING_OVERSTAY && (
					<>
						<h3>Time Restriction (mins)</h3>
						<MinNumberInput
							min={() => 10}
							max={() => 60 * 24}
							value={values.timeRestriction}
							values={values}
							setFieldValue={setFieldValue}
							field={"timeRestriction"}
						/>
					</>
				)}

				{[
					OFFENCE_EVENTS.PARKING_OVERSTAY,
					OFFENCE_EVENTS.PERMIT_OVERSTAY,
					OFFENCE_EVENTS.NOT_PAID,
				].includes(values.offenceEvent) && (
					<>
						<h3>Entry Grace Period (mins)</h3>
						<MinNumberInput
							min={() => getMinGracePeriod(values.offenceEvent)}
							max={() => 60}
							value={values.entryGracePeriod}
							values={values}
							setFieldValue={setFieldValue}
							field={"entryGracePeriod"}
						/>
						<h3>Exit Grace Period (mins)</h3>
						<MinNumberInput
							min={() => getMinGracePeriod(values.offenceEvent)}
							max={() => 60}
							value={values.exitGracePeriod}
							values={values}
							setFieldValue={setFieldValue}
							field={"exitGracePeriod"}
						/>
					</>
				)}
			</div>
		),
		footer: () => <WizardFooter {...props} disabled={isDisabled()} />,
	};
};

export const sitesStep = (props) => {
	const { values, setFieldValue } = props;

	return {
		id: "sites",
		label: "Sites",
		render: () => (
			<div>
				<StepTitle>What sites does this rule apply to?</StepTitle>
				<Dropdown
					isMulti={true}
					value={values.selectedSites}
					options={values.availableSites}
					onChange={(value) => {
						setFieldValue("selectedSites", value);
					}}
				/>
			</div>
		),
		footer: () => (
			<WizardFooter {...props} disabled={!values.selectedSites.length} />
		),
	};
};

function displayGrace(grace) {
	if (!grace) {
		return "None";
	}

	return `${grace} ${grace > 1 ? "minutes" : "minute"}`;
}

export const summaryStep = ({
	values,
	setFieldValue,
	handleSubmit,
	isSubmitting,
	keyStrokeHandler,
	goTo,
}) => {
	return {
		id: "summary",
		label: "Sites",
		render: () => {
			const items = [
				{
					title: "Offence Code",
					value: values.offenceCode,
					key: "code",
					edit: () => {
						setFieldValue("editFromSummary", true);
						goTo("rule");
					},
				},
				{
					title: "Offence Event",
					value: OFFENCE_EVENTS_TRANSLATIONS[values.offenceEvent],
					key: "event",
					edit: () => {
						setFieldValue("editFromSummary", true);
						goTo("rule");
					},
				},
				{
					title: "Priority",
					value: values.priority,
					key: "priority",
					edit: () => {
						setFieldValue("editFromSummary", true);
						goTo("rule");
					},
				},
			];

			if (values.offenceEvent === OFFENCE_EVENTS.PARKING_OVERSTAY) {
				items.push({
					title: "Time Restriction",
					value: `P${values.timeRestriction}`,
					key: "timeRestriction",
					edit: () => {
						setFieldValue("editFromSummary", true);
						goTo("rule");
					},
				});
			}

			if (
				[
					OFFENCE_EVENTS.PARKING_OVERSTAY,
					OFFENCE_EVENTS.PERMIT_OVERSTAY,
					OFFENCE_EVENTS.NOT_PAID,
				].includes(values.offenceEvent)
			) {
				items.push(
					{
						title: "Entry Grace Period",
						value: displayGrace(values.entryGracePeriod),
						key: "entryGracePeriod",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("rule");
						},
					},
					{
						title: "Exit Grace Period",
						value: displayGrace(values.exitGracePeriod),
						key: "exitGracePeriod",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("rule");
						},
					}
				);
			}

			items.push({
				title: "Sites",
				value: values.selectedSites.map((site) => site.label).join(", "),
				key: "sites",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("sites");
				},
			});

			return (
				<div>
					<StepTitle>Summary</StepTitle>
					<SummaryTable items={items} />
				</div>
			);
		},
		footer: () => (
			<WizardNavigation
				rightItems={[
					<Button
						key="submit"
						color="green"
						onClick={handleSubmit}
						disabled={isSubmitting}
						keyStrokeHandler={keyStrokeHandler}
					>
						{values.mode === "edit" ? "Update" : "Create"} Rule
					</Button>,
				]}
			/>
		),
	};
};

export const deleteStep = ({
	handleSubmit,
	isSubmitting,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "delete",
	label: "Delete",
	render: () => {
		return (
			<div>
				<StepText>Are you sure you want to delete this rule?</StepText>
			</div>
		);
	},
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					Delete
				</Button>,
			]}
		/>
	),
});
