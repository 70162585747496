import * as Yup from "yup";
import { capitalize, includes, isEqual, isFunction } from "lodash";
import * as steps from "./steps";
import * as genericSteps from "../Generics";
import React from "react";
import WizardLayout from "../WizardLayout";
import gql from "graphql-tag";
import Alert from "react-s-alert";
import {
	API_KEY_SCOPE_OPTIONS,
	GENERIC_INPUT_TYPES,
	API_KEY_SCOPE,
	API_KEY_SCOPE_TRANSLATIONS,
	AUTHORIZATION_TYPES,
	AUTHORIZATION_TYPE_TRANSLATIONS,
} from "../../../helpers/constants";
import { useMutateData } from "../../../hooks";
import { downloadDocumentation } from "../../../api/shared-api";

const constructWizard = (props) => {
	const apiKey = props.apiKey;
	const rawApiKey = props.rawApiKey;
	const isAdding = props.mode === "add";
	const isEnabled = apiKey?.IsEnabled ? true : false;

	const publicApiKeyIdSchema = {
		name: "publicApiKeyId",
		value: apiKey?.PublicAPIKeyID,
		validator: Yup.number(),
	};

	const isEnabledSchema = {
		name: "isEnabled",
		value: isEnabled,
		validator: Yup.boolean(),
	};

	const rawApiKeySchema = {
		name: "rawApiKey",
		value: rawApiKey,
		validator: Yup.string(),
	};

	if (props.mode === "show-key") {
		return {
			initialStep: 0,
			steps: [steps.showApiKey],
			values: [rawApiKeySchema],
			title: "API Key",
		};
	}

	if (props.mode === "delete") {
		return {
			initialStep: 0,
			steps: [steps.deleteStep],
			values: [publicApiKeyIdSchema],
			title: "Delete API Key",
		};
	}

	if (props.mode === "toggle") {
		return {
			initialStep: 0,
			steps: [steps.toggleStep(isEnabled)],
			values: [publicApiKeyIdSchema, isEnabledSchema],
			title: `${isEnabled ? "Disable" : "Enable"} API Key`,
		};
	}

	if (props.mode === "downloadDocumentation") {
		return {
			initialStep: 0,
			steps: [steps.downloadDocumentationStep],
			values: [],
			title: "Download Documentation",
		};
	}

	const questions = [
		{
			key: "Name",
			question: "What is the name of this api key?",
			validate: (value) => !!value,
			type: GENERIC_INPUT_TYPES.TEXT,
		},
		{
			key: "Scope",
			question: "What scope should this api key have access to?",
			validate: (value) => !!value,
			options: API_KEY_SCOPE_OPTIONS,
			type: GENERIC_INPUT_TYPES.MULTI_SELECT,
			onChange: ({ values, value, setFieldValue }) => {
				const independentScopes = [API_KEY_SCOPE.MILESIGHT_POST_OBSERVATION];
				const newScope = value.filter(
					(v) => !values.scope.some((scope) => scope.value === v.value)
				)?.[0];

				if (
					newScope &&
					(independentScopes.includes(newScope.value) ||
						values.scope.some((scope) =>
							independentScopes.includes(scope.value)
						))
				) {
					setFieldValue("scope", [newScope]);
					return;
				}

				setFieldValue("scope", value || []);
			},
			next: ({ values, skip, next }) => {
				if (
					values.scope.some(
						({ value }) =>
							value === API_KEY_SCOPE.POST_OBSERVATION ||
							value === API_KEY_SCOPE.MILESIGHT_POST_OBSERVATION
					)
				) {
					next();
				} else {
					skip(1);
				}
			},
		},
		{
			key: "CachePeriod",
			question: "How long should the observation be cached for?",
			validate: (value) => !!value,
			type: GENERIC_INPUT_TYPES.NUMBER,
			min: 1,
			max: 1000000,
			useLabel: "Minutes",
			getSummaryValue: ({ values }) => `${values.cachePeriod} minutes`,
			hideInSummary: (values) => {
				return !values.scope?.some(
					({ value }) =>
						value === API_KEY_SCOPE.POST_OBSERVATION ||
						value === API_KEY_SCOPE.MILESIGHT_POST_OBSERVATION
				);
			},
		},
		{
			key: "AuthorizationType",
			component: steps.authStep,
			getSummaryValue: ({ values }) =>
				AUTHORIZATION_TYPE_TRANSLATIONS[values.authorizationType],
			hideInSummary: (values) => {
				return !values.scope?.some(
					({ value }) => value === API_KEY_SCOPE.MILESIGHT_POST_OBSERVATION
				);
			},
		},
		{
			key: "Places",
			question: "What places should this api key have access to?",
			validate: (value) => !!value,
			options: [{ value: 0, label: "All Places" }].concat(
				props.sites?.map((site) => ({
					value: site.SiteID,
					label: site.Name,
				}))
			),
			onChange: ({ values, value, setFieldValue }) => {
				const newSiteIds = (value || []).map((v) => v.value);

				let update = value;
				if (values.places.some((v) => v.value === 0)) {
					update = value.filter((v) => v.value !== 0);
				} else if (includes(newSiteIds, 0)) {
					update = value.filter((v) => v.value === 0);
				}

				setFieldValue("places", update);
			},
			type: GENERIC_INPUT_TYPES.MULTI_SELECT,
		},
	].filter((question) => {
		if (props.context !== "Client") return !["Places"].includes(question.key);

		if (props.mode !== "add" && question.key === "AuthorizationType") {
			return false;
		}

		return true;
	});

	const currentSteps = [
		...questions.map((question) => {
			if (isFunction(question.component)) {
				return question.component;
			}
			return genericSteps.inputStep(question);
		}),
		genericSteps.summaryStep(questions),
	];

	const cachePeriod = apiKey?.Rules?.find(
		(rule) => rule.Variable === "Observation"
	)?.Duration;

	return {
		initialStep: isAdding ? 0 : currentSteps.length - 1,
		steps: currentSteps,
		values: [
			publicApiKeyIdSchema,
			{
				name: "name",
				value: apiKey?.Name,
				validator: Yup.string(),
			},
			{
				name: "scope",
				value:
					apiKey?.Scope?.map((endpoint) => ({
						value: endpoint,
						label: API_KEY_SCOPE_TRANSLATIONS[endpoint],
					})) ?? [],
				validator: Yup.array(Yup.object()),
			},
			{
				name: "places",
				value: !apiKey?.SiteIDs
					? [{ value: 0, label: "All Places" }]
					: props.sites
							?.filter((site) => apiKey?.SiteIDs?.includes(site.SiteID))
							.map((site) => ({
								value: site.SiteID,
								label: site.Name,
							})),
				validator: Yup.array(Yup.object()),
			},
			{
				name: "cachePeriod",
				value: cachePeriod ? Math.floor(cachePeriod / 60) : 60,
			},
			{
				name: "authorizationType",
				value: AUTHORIZATION_TYPES.API_KEY,
			},
		],
		title: capitalize(props.mode) + " API Key",
	};
};

export default function ApiKeyWizard(props) {
	const isAdding = props.mode === "add";

	const glizzardWizard = constructWizard(props);

	const createApiKey = useMutateData(gql`
		mutation ($context: IntegrationContext!, $apiKey: PublicAPIKeyInput!) {
			createApiKey(context: $context, apiKey: $apiKey)
		}
	`);

	const updateApiKey = useMutateData(gql`
		mutation ($apiKey: PublicAPIKeyInput!) {
			updateApiKey(apiKey: $apiKey)
		}
	`);

	const deleteApiKey = useMutateData(gql`
		mutation ($publicApiKeyId: Int!) {
			deleteApiKey(publicApiKeyId: $publicApiKeyId)
		}
	`);

	const disableApiKey = useMutateData(gql`
		mutation ($publicApiKeyId: Int!) {
			disableApiKey(publicApiKeyId: $publicApiKeyId)
		}
	`);

	const enableApiKey = useMutateData(gql`
		mutation ($publicApiKeyId: Int!) {
			enableApiKey(publicApiKeyId: $publicApiKeyId)
		}
	`);

	const onSubmit = async (data) => {
		try {
			if (props.mode === "show-key") {
				props.close();
			}

			if (props.mode === "delete") {
				await deleteApiKey({
					variables: {
						publicApiKeyId: data.publicApiKeyId,
					},
				});

				Alert.success("API Key has been removed.");
				props.close();
				return;
			}

			if (props.mode === "toggle") {
				if (data.isEnabled) {
					await disableApiKey({
						variables: {
							publicApiKeyId: data.publicApiKeyId,
						},
					});
					Alert.success("Disabled API Key.");
				} else {
					await enableApiKey({
						variables: {
							publicApiKeyId: data.publicApiKeyId,
						},
					});
					Alert.success("Enabled API Key.");
				}

				props.close();
				return;
			}

			if (props.mode === "downloadDocumentation") {
				await downloadDocumentation(data.apiKey);
				props.close();
				return;
			}

			const siteIds = data.places?.map((place) => place.value);
			const apiKeyInput = {
				organizationId: props.organizationId,
				name: data.name,
				scope: data.scope?.map((endpoint) => endpoint.value),
				siteIds: isEqual(siteIds, [0]) ? null : siteIds,
				authorizationType: data.authorizationType,
				basicAuthUsername: data.basicAuthUsername,
				basicAuthPassword: data.basicAuthPassword,
			};

			if (
				apiKeyInput.scope.some(
					(s) =>
						s === API_KEY_SCOPE.POST_OBSERVATION ||
						s === API_KEY_SCOPE.MILESIGHT_POST_OBSERVATION
				)
			) {
				apiKeyInput.rules = [
					{
						Type: "Cache",
						Variable: "Observation",
						Duration: parseInt(data.cachePeriod) * 60,
					},
				];
			}

			if (props.mode === "add") {
				try {
					await createApiKey({
						variables: {
							context: props.context,
							apiKey: apiKeyInput,
						},
						onCompleted: (result) => {
							if (
								apiKeyInput.authorizationType === AUTHORIZATION_TYPES.API_KEY &&
								result.createApiKey
							) {
								props.showApiKey(result.createApiKey);
							} else {
								Alert.success("Added API Key.");
								props.close();
								return;
							}
						},
					});
				} catch (error) {
					if (
						error.graphQLErrors?.[0]?.extensions?.code === "DUPLICATE_USERNAME"
					) {
						Alert.error(
							"This username is already taken, please choose another one."
						);
					} else {
						Alert.error("Something went wrong. Please try again.");
					}
				}
				return;
			} else if (props.mode === "edit") {
				await updateApiKey({
					variables: {
						apiKey: {
							publicApiKeyId: data.publicApiKeyId,
							...apiKeyInput,
						},
					},
				});
			}

			Alert.success(`${isAdding ? "Added" : "Updated"} API Key.`);
		} catch (error) {
			console.log(error);
			Alert.error("Something went wrong.");
		}
		props.close();
	};

	return (
		<WizardLayout
			rootTabIndex={null}
			close={props.close}
			title={glizzardWizard.title}
			onSubmit={onSubmit}
			values={glizzardWizard.values}
			steps={glizzardWizard.steps}
			initialStep={glizzardWizard.initialStep}
			wizardProps={props}
		/>
	);
}
