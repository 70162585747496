import React from "react";
import { colours } from "../../styles";
import styled from "styled-components";

const Item = styled.div`
	height: ${(props) => (props.size ? props.size : 64)}px;
	margin: 0 auto;
	width: ${(props) => (props.size ? props.size : 64)}px;
	position: relative;

	.loader {
		div {
			animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
			border: ${(props) => (props.size ? props.thickness : 6)}px solid
				${colours.darkGrey};
			border-color: ${colours.darkGrey} transparent transparent transparent;
			border-radius: 50%;
			display: block;
			height: ${(props) => (props.size ? props.size : 64)}px;
			position: absolute;
			width: ${(props) => (props.size ? props.size : 64)}px;
		}

		div:nth-child(1) {
			animation-delay: -0.45s;
		}

		div:nth-child(2) {
			animation-delay: -0.3s;
		}

		div:nth-child(3) {
			animation-delay: -0.15s;
		}
	}

	@keyframes loader {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;

export default function Loader(props) {
	return (
		<Item {...props}>
			<div className="loader">
				<div />
				<div />
				<div />
				<div />
			</div>
		</Item>
	);
}
