import { Plus } from "react-feather";
import React, { useContext, useState } from "react";
import { AppContext } from "../context/app-context";
import Card from "../components/layout/Card";
import styled from "styled-components";
import Button from "../components/layout/Button";
import CreateOrganizationWizard from "../components/wizards/create-organization-wizard/CreateOrganizationWizard";

const Message = styled.div`
	font-size: 20px;
	font-weight: 600;
	margin: 64px 0;
	text-align: center;
`;

const Wrapper = styled.div`
	margin: 16px 32px;
	position: relative;
`;

export default function OperatorContainer() {
	const [state, setState] = useState({
		createOrganizationWizardOpen: false,
	});

	const {
		state: { selectedOperator, contextLoading },
	} = useContext(AppContext);

	return (
		!contextLoading && (
			<>
				{state.createOrganizationWizardOpen && (
					<CreateOrganizationWizard
						selectedOperator={selectedOperator}
						close={() =>
							setState((_state) => ({
								..._state,
								createOrganizationWizardOpen: false,
							}))
						}
					/>
				)}
				<Card>
					<Message>You have no clients</Message>
					<Wrapper style={{ textAlign: "center" }}>
						<Button
							style={{ marginRight: "10px" }}
							color="blue"
							onClick={() =>
								setState((_state) => ({
									..._state,
									createOrganizationWizardOpen: true,
								}))
							}
						>
							<Plus
								style={{ marginRight: 4, verticalAlign: "sub" }}
								size={20}
							/>
							Create Client
						</Button>
					</Wrapper>
				</Card>
			</>
		)
	);
}
