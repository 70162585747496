import * as Yup from "yup";
import { setIn } from "formik";

export const EventSchema = Yup.object()
	.shape({
		name: Yup.string()
			.trim()
			.max(100, "Event Name should be no more than 100 characters long.")
			.required("Event Name is required"),
		startTime: Yup.date()
			.required("Start Date is required")
			.transform(function (castValue, originalValue) {
				if (this.isType(castValue)) {
					return castValue;
				}
				if (originalValue) {
					return new Date(originalValue);
				}
				return castValue;
			})
			.typeError("Start Date must be a valid date"),
		endTime: Yup.date()
			.required("End Date is required")
			.transform(function (castValue, originalValue) {
				if (this.isType(castValue)) {
					return castValue;
				}
				if (originalValue) {
					return new Date(originalValue);
				}
				return castValue;
			})
			.typeError("End Date must be a valid date")
			.min(Yup.ref("startTime"), "End Date cannot be before Start Date"),
	})
	.nullable();

export const validateEvents = async (events) => {
	let errors = {};
	for (let i = 0; i < events.length; i++) {
		try {
			await EventSchema.validate(events[i], { abortEarly: false });
		} catch (err) {
			err.inner.forEach((validationError) => {
				errors = setIn(
					errors,
					`events.${i}.${validationError.path}`,
					validationError.message
				);
			});
		}
	}
	return errors;
};
