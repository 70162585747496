import { durationTypeToUnitLabel, getUnitLabel } from "./helper";
import AlertMessage from "../../layout/AlertMessage";
import Dropdown from "../../layout/Dropdown";
import EditableInputField from "../../layout/EditableInputField";
import MultiChoiceButton from "../../../components/wizards/MultiChoiceButton";
import React from "react";
import { StepTitle } from "../WizardLayout";
import { XCircle } from "react-feather";
import styled from "styled-components";
import { filter } from "lodash";

const Wrapper = styled.div`
	display: flex;
	position: relative;
	margin-top: 20px;

	> * {
		margin-right: 10px;
		width: 180px;
	}
`;

export function RateTypeStep(props) {
	const { durationType, setFieldValue, title } = props;

	return (
		<div>
			<StepTitle>{title}</StepTitle>

			<MultiChoiceButton
				selected={durationType === "Months"}
				onClick={() => {
					setFieldValue("durationType", "Months");
				}}
			>
				Months
			</MultiChoiceButton>

			<MultiChoiceButton
				selected={durationType === "Days"}
				onClick={() => {
					setFieldValue("durationType", "Days");
				}}
			>
				Days
			</MultiChoiceButton>

			<MultiChoiceButton
				selected={durationType === "Hours"}
				onClick={() => {
					setFieldValue("durationType", "Hours");
				}}
			>
				Hours
			</MultiChoiceButton>
			<MultiChoiceButton
				selected={durationType === "Event"}
				onClick={() => {
					setFieldValue("durationType", "Event");
				}}
			>
				Event
			</MultiChoiceButton>
		</div>
	);
}

export function MinimumDurationStep(props) {
	const {
		durationType,
		minimumDurationAmount,
		maximumDurationAmount,
		setFieldValue,
		errors,
		resetForm,
		values,
	} = props;
	return (
		<div>
			<StepTitle>
				What is the minimum unit of this{" "}
				{getUnitLabel(durationType, "period", "lower")} booking?
			</StepTitle>
			<EditableInputField
				type="number"
				name="minimumDurationAmount"
				onChange={async (event) => {
					if (errors) {
						const {
							minimumDurationAmount,
							exceedsMaxDuration,
							minimumDurationMonths,
							...rest
						} = errors || {};
						resetForm({
							values: values,
							errors: { ...rest },
						});
					}
					return setFieldValue("minimumDurationAmount", event.target.value);
				}}
				value={minimumDurationAmount}
				useLabel="Minimum"
				rightUnit={durationTypeToUnitLabel(durationType)}
				error={
					errors.minimumDurationAmount ||
					errors.exceedsMaxDuration ||
					errors.minimumDurationMonths ||
					null
				}
			/>

			<StepTitle>
				What is the maximum duration of this{" "}
				{getUnitLabel(durationType, "period", "lower")} booking?
			</StepTitle>
			<div>
				Leave the field empty if you don&apos;t want a maximum duration.
			</div>
			<EditableInputField
				type="number"
				name="maximumDurationAmount"
				onChange={(event) => {
					if (errors) {
						const { exceedsMaxDuration, maximumDurationAmount, ...rest } =
							errors || {};
						resetForm({
							values: values,
							errors: {
								...rest,
							},
						});
					}
					setFieldValue("maximumDurationAmount", event.target.value);
					setFieldValue("maximumDurationType", durationType);
				}}
				value={maximumDurationAmount}
				useLabel="Maximum"
				rightUnit={durationTypeToUnitLabel(durationType)}
				error={
					errors.maximumDurationAmount || errors.exceedsMaxDuration || null
				}
			/>
		</div>
	);
}

export function DurationStep(props) {
	const {
		minimumDurationAmount,
		maximumDurationAmount,
		hourlyBillableDuration = 60,
		durationType,
		maximumDurationType,
		setFieldValue,
		errors,
	} = props;

	const hasHalfHourBookings = hourlyBillableDuration === 30;
	const options = [
		{ value: -1, label: "No limit" },
		// { value: 15, label: "15 Minutes" },
		{ value: 60, label: "1 Hour" },
		{ value: 120, label: "2 Hours" },
		{ value: 720, label: "12 Hours" },
		{ value: 1440, label: "24 Hours" },
	];

	if (hasHalfHourBookings) {
		options.splice(1, 0, { value: 30, label: "30 minutes" });
	}

	function convertDuration(value) {
		const hoursValues = [60, 120, 720, 1440];

		if (hasHalfHourBookings) {
			return { amount: parseInt(value.value / 30), unit: "Hours" };
		} else if (hoursValues.includes(value.value)) {
			//convert to hourly value
			return { amount: parseInt(value.value / 60), unit: "Hours" };
		} else {
			return { amount: value.value, unit: "Minutes" };
		}
	}

	function getValue(amount, unit) {
		if (unit === "Minutes") {
			return amount;
		} else if (unit === "Hours" && hasHalfHourBookings) {
			return amount * 30;
		} else if (unit === "Hours") {
			return amount * 60;
		}
	}

	function getLabel(amount, unit) {
		//Make sure it covers the '1 Day' and '1 Hour' situation
		if (unit === "Days") {
			return "1 Day";
		} else if (unit === "Hours" && amount === 1 && hasHalfHourBookings) {
			return "30 minutes";
		} else if (unit === "Hours" && amount === 1) {
			return "1 Hour";
		} else if (hasHalfHourBookings) {
			return `${parseInt(amount / 2)} ${unit}`;
		} else {
			return `${amount} ${unit}`;
		}
	}

	return (
		<div>
			<StepTitle>What is the minimum duration?</StepTitle>
			<Dropdown
				options={options.filter((d) => d.value !== -1)}
				value={
					minimumDurationAmount
						? {
								value: getValue(minimumDurationAmount, durationType),
								label: getLabel(minimumDurationAmount, durationType),
						  }
						: null
				}
				onChange={(value) => {
					const result = convertDuration(value);
					setFieldValue("minimumDurationAmount", result.amount);
					setFieldValue("durationType", result.unit);
				}}
			/>
			<StepTitle style={{ marginTop: 25 }}>
				What is the maximum duration?
			</StepTitle>
			<Dropdown
				options={options}
				value={
					maximumDurationAmount
						? {
								value: getValue(maximumDurationAmount, maximumDurationType),
								label: getLabel(maximumDurationAmount, maximumDurationType),
						  }
						: null
				}
				onChange={(value) => {
					let result = convertDuration(value);
					if (value.value === -1) {
						result.amount = null;
						result.unit = null;
					}
					setFieldValue("maximumDurationAmount", result.amount);
					setFieldValue("maximumDurationType", result.unit);
				}}
			/>
			{errors && (
				<AlertMessage
					text={errors}
					borderColor="red"
					backgroundColor="red"
					textColor="white"
					top="30px"
				/>
			)}
		</div>
	);
}

export function GroupStep(props) {
	const {
		organizationAccessGroups,
		selectGroups,
		setFieldValue,
		durationType,
		errors,
		isPrivate,
	} = props;

	//filter out groups with EventGroup true, event pass groups are not allowed to have a booking rate
	const filteredGroups = filter(
		organizationAccessGroups,
		(group) => !group.EventGroup
	);

	let options = filteredGroups.map((group) => ({
		label: group.Name,
		value: group.OrganizationAccessGroupID,
	}));

	const noGroupOption = isPrivate
		? { label: "No Group", value: -1, isFixed: true }
		: { label: "No Group (Public)", value: -1, isFixed: true };

	//Add public as an option - will be defaulted 'on' by logic in the formik data model for non-grouped rates
	options.unshift(noGroupOption);

	return (
		<div>
			<StepTitle>
				Please choose the groups that apply to this{" "}
				{getUnitLabel(durationType, "period", "lower")} booking
			</StepTitle>
			<Dropdown
				isMulti={true}
				options={options}
				value={selectGroups}
				onChange={(value, event) => {
					//If clearing, populate the placeholder group
					if (value === null || event.action === "clear") {
						setFieldValue("organizationAccessGroups", [noGroupOption]);
					}
					//If removing, just allow it
					else if (event.action === "remove-value") {
						setFieldValue("organizationAccessGroups", value);
					}
					//If adding public group, remove all other groups
					else if (event.option.value === -1) {
						setFieldValue("organizationAccessGroups", [noGroupOption]);
					}
					//If adding any other group, remove public if it's selected
					else {
						const privateGroups = value.reduce((arr, val) => {
							if (val.value !== -1) {
								arr.push(val);
							}

							return arr;
						}, []);

						setFieldValue("organizationAccessGroups", privateGroups);
					}
				}}
			/>
			{errors && (
				<AlertMessage
					text={errors}
					borderColor="red"
					backgroundColor="red"
					textColor="white"
					top="30px"
				/>
			)}
		</div>
	);
}

export function BookInAdvanceStep(props) {
	const {
		setFieldValue,
		bookInAdvanceDuration,
		bookInAdvanceUnitType,
		errors,
	} = props;
	const unitOptions = [
		{ value: "Hours", label: "Hour(s)" },
		{ value: "Days", label: "Day(s)" },
		{ value: "Months", label: "Month(s)" },
	];

	return (
		<div>
			<StepTitle>How far in advance can this space be booked?</StepTitle>
			<div>
				Leave the field empty if you don&apos;t want to supply any limitation
			</div>

			<Wrapper>
				<EditableInputField
					type="number"
					name="bookInAdvanceDuration"
					onChange={(event) => {
						let _bookInAdvanceDuration = event.target.value
							.replace(/[^0-9.]/g, "")
							.replace(/(\..*)\./g, "$1");
						setFieldValue("bookInAdvanceDuration", _bookInAdvanceDuration);
					}}
					value={bookInAdvanceDuration || ""}
					useLabel="Book In Advance"
					error={
						errors.bookInAdvanceDuration ? errors.bookInAdvanceDuration : ""
					}
				/>

				<div style={{ marginTop: 10 }}>
					<Dropdown
						placeholder="Select unit..."
						options={unitOptions}
						value={
							unitOptions.find(
								(value) => value.value === bookInAdvanceUnitType
							) || ""
						}
						onChange={(value) =>
							setFieldValue("bookInAdvanceUnitType", value.value)
						}
					/>
				</div>

				<div style={{ marginTop: 16, marginLeft: 24 }}>
					<XCircle
						size={26}
						onClick={() => {
							setFieldValue("bookInAdvanceDuration", null);
							setFieldValue("bookInAdvanceUnitType", null);
						}}
					/>
				</div>
			</Wrapper>
		</div>
	);
}
