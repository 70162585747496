import { DEFAULT_DATES, DEFAULT_TIMES } from "../../../../helpers/organization";
import Dropdown from "../../../layout/Dropdown";
import React from "react";
import { StepTitle } from "../../WizardLayout";
import footer from "../helpers/footer";
import { isEqual } from "lodash";
export default ({
	values,
	setFieldValue,
	goTo,
	wizardProps,
	previous,
	keyStrokeHandler,
}) => ({
	id: "type",
	label: "Type",
	render: () => {
		return (
			<div>
				<StepTitle>What best describes your new rate?</StepTitle>
				<Dropdown
					options={wizardProps.rateTypes}
					value={values.type}
					onChange={(type) => {
						setFieldValue("configuration", null);
						// clear the error message if type changed
						if (!isEqual(values.type, type)) {
							setFieldValue("conflictRateErrorMessage", null);
						}
						setFieldValue("type", type);

						const defaultTimes = DEFAULT_TIMES[type.value];
						if (defaultTimes) {
							setFieldValue("startTime", defaultTimes.startTime.toDate());
							setFieldValue("endTime", defaultTimes.endTime.toDate());
						}

						const defaultDates = DEFAULT_DATES[type.value];
						if (defaultDates) {
							setFieldValue("entryLaterThan", defaultDates.EntryLaterThan);
							setFieldValue("entryEarlierThan", defaultDates.EntryEarlierThan);
							setFieldValue("exitLaterThan", defaultDates.ExitLaterThan);
							setFieldValue("exitEarlierThan", defaultDates.ExitEarlierThan);
						}
					}}
				/>
			</div>
		);
	},
	footer: () =>
		footer(
			values,
			previous,
			goTo,
			"isValidation",
			wizardProps,
			!!(values.name || "").trim(),
			false,
			setFieldValue,
			false,
			keyStrokeHandler
		),
});
