import ContextMenuItem from "./ContextMenuItem";
import { Link } from "react-router-dom";
import React from "react";

export default function ContextMenuLink(props) {
	return (
		<Link to={props.path}>
			<ContextMenuItem
				itemName={props.name}
				selected={props.location.pathname === props.path}
			/>
		</Link>
	);
}
