import React, { useState } from "react";
import { usePermissions, useQueryData, useSearchFilter } from "../../../hooks";
import AddControlBoxWizard from "../../wizards/control-box-wizard/AddControlBoxWizard";
import Button from "../../layout/Button";
import { Plus } from "react-feather";
import ReportWrapper from "../ReportWrapper";
import gql from "graphql-tag";

const defaultSorting = [
	{
		id: "SerialNumber",
		desc: false,
	},
];

export default function ControlBoxes(props) {
	const [state, setState] = useState({
		initialLoad: true,
		search: "",
		wizardOpen: false,
	});

	const isAdmin = usePermissions("IsAdmin");
	const isCSAdmin = usePermissions("IsCSAdmin");
	const isSuperAdmin = isAdmin && !isCSAdmin;

	async function updateOptions({ search, ...options }) {
		setState((_state) => ({ ..._state, initialLoad: false, search, options }));
	}

	const openWizard = () => {
		setState((_state) => ({
			..._state,
			wizardOpen: true,
		}));
	};

	const columns = [
		{
			id: "SerialNumber",
			Header: "Serial Number",
			accessor: (d) => d.SerialNumber || "",
			highlightCell: (cellProps) => cellProps.original.SerialNumber || "",
		},
		{
			id: "HardwareVersion",
			Header: "Hardware Version",
			accessor: (d) => d.HardwareVersion || "",
			highlightCell: (cellProps) => cellProps.original.HardwareVersion || "",
		},
		{
			id: "Manufacturer",
			Header: "Manufacturer",
			accessor: (d) => d.Manufacturer || "",
			highlightCell: (cellProps) =>
				cellProps.original.HardwareManufacturer || "",
		},
		{
			id: "RouterModel",
			Header: "Router Model",
			accessor: (d) => d.RouterModel || "",
			highlightCell: (cellProps) => cellProps.original.RouterModel || "",
		},
		{
			id: "RouterSerialNumber",
			Header: "Router Serial Number",
			accessor: (d) => d.RouterSerialNumber || "",
			highlightCell: (cellProps) => cellProps.original.RouterSerialNumber || "",
		},
		{
			id: "CellNetwork",
			Header: "CellNetwork",
			accessor: (d) => d.CellNetwork || "",
			highlightCell: (cellProps) => cellProps.original.CellNetwork || "",
		},
		{
			id: "SimNumber",
			Header: "SimNumber",
			accessor: (d) => d.SimNumber || "",
			highlightCell: (cellProps) => cellProps.original.SimNumber || "",
		},
		{
			id: "Site",
			Header: "Site",
			accessor: (d) => d.Site?.Name || "",
			highlightCell: (cellProps) => cellProps.original.Site?.Name || "",
		},
	];

	const {
		data: { getAllControlBoxes: controlBoxes },
		isLoading,
		refetch,
	} = useQueryData(
		gql`
			query {
				getAllControlBoxes {
					SerialNumber
					HardwareVersion
					HardwareID
					HardwareManufacturer
					RouterModel
					RouterSerialNumber
					Connectivity
					CellNetwork
					SimNumber
				}
			}
		`
	);

	const filteredData = useSearchFilter(controlBoxes, state.search, [
		"SerialNumber",
		"HardwareManufacturer",
		"ManufacturerID",
		"Connectivity",
		"SoftwareVersion",
		"Site.Name",
		"Client.Name",
	]);

	return (
		<div>
			{state.wizardOpen && (
				<AddControlBoxWizard
					close={() => {
						refetch();
						setState((_state) => ({
							..._state,
							wizardOpen: false,
						}));
					}}
				/>
			)}
			<ReportWrapper
				{...props}
				title="Control Boxes"
				data={filteredData}
				columns={columns}
				defaultSortBy={defaultSorting}
				updateOptions={updateOptions}
				loading={isLoading}
				showDateRangePicker={false}
				showSitePicker={false}
				rightActions={
					isSuperAdmin ? (
						<Button
							color="blue"
							style={{ marginLeft: 8 }}
							onClick={() => openWizard()}
						>
							<Plus size={20} /> Add Control Box
						</Button>
					) : null
				}
				cursorColumn="ControlBoxID"
			/>
		</div>
	);
}
