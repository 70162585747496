import * as installationApi from "../../api/installation";
import React, { useContext, useState } from "react";
import { format, user } from "../../helpers";
import { useFetchData, usePermissions, useSearchFilter } from "../../hooks";
import { AppContext } from "../../context/app-context";
import DropdownMenu from "../../components/layout/DropdownMenu";
import HistoryDetails from "../../components/details-pages/history/HistoryDetails";
import { MoreHorizontal } from "react-feather";
import ReportWrapper from "../../components/report/ReportWrapper";
import { booleanValueFormatterColour } from "../../components/layout/TableLayout";
import { logType } from "../../components/details-pages/history/logType";
import moment from "moment";

const columns = (props) => [
	{
		id: "Status",
		Header: "Status",
		accessor: (d) => d.Status,
		Cell: (cellProps) =>
			booleanValueFormatterColour({ value: cellProps.value === 200 }),
		width: 80,
	},
	{
		id: "createdDate",
		Header: "Date",
		accessor: (d) => d.CreatedOn,
		highlightCell: (cellProps) => cellProps.original.date,
	},
	{
		id: "Name",
		Header: "Name",
		accessor: (d) => d.userName,
		highlightCell: (cellProps) => cellProps.original.userName,
	},
	{
		id: "Email",
		Header: "Email",
		accessor: (d) => d.email,
		highlightCell: (cellProps) => cellProps.original.email,
	},
	{
		id: "Description",
		Header: "Description",
		accessor: (d) => d.description,
		highlightCell: (cellProps) => cellProps.original.description,
	},
	{
		id: "Site",
		Header: "Site",
		accessor: (d) => d.siteName,
		highlightCell: (cellProps) => cellProps.original.siteName,
	},
	{
		id: "tasks",
		Header: "",
		accessor: null,
		Cell: (cellProps) =>
			logType[cellProps.original.ObjectType] ? (
				<DropdownMenu
					triggerContent={<MoreHorizontal size={24} />}
					items={[
						<div
							key="info"
							onClick={() => props.openHistoryWizard(cellProps.original)}
						>
							More Info
						</div>,
					]}
				/>
			) : null,
		filterable: false,
		resizable: false,
		width: 50,
		sortable: false,
	},
];

const defaultSorting = [
	{
		id: "createdDate",
		desc: true,
	},
];

export default function HistoryContainer(props) {
	const [state, setState] = useState({
		initialLoad: true,
		options: {
			sites: [],
			"start-date-time": null,
			"end-date-time": null,
		},
		search: "",
		historyWizardOpen: false,
	});

	const operatorId = props.selectedOperator
		? props.selectedOperator.OperatorID
		: null;

	const organizationId = props.selectedOrganization
		? props.selectedOrganization.OrganizationID
		: null;

	const isAdmin = usePermissions("IsAdmin");
	const viewHistory = usePermissions(null, "ViewHistory", true);

	const {
		dispatch: { redirectToDefaultPage },
	} = useContext(AppContext);

	if (!isAdmin && !viewHistory) {
		redirectToDefaultPage(
			operatorId,
			organizationId,
			props.selectedOrganization.IsValidation
		);
	}

	async function updateOptions({ search, ...options }) {
		setState((_state) => ({ ..._state, initialLoad: false, search, options }));
	}

	const options = props.admin
		? {
				"local-start-timestamp": moment(
					state.options["start-date-time"],
					"YYYYMMDD"
				).unix(),
				"local-end-timestamp": moment(
					state.options["end-date-time"],
					"YYYYMMDD"
				)
					.endOf("day")
					.unix(),
		  }
		: state.options;

	const { data, isLoading } = useFetchData(
		[],
		props.admin
			? installationApi.getAdminAuditLog
			: installationApi.getAuditLogByOrganizationId,
		!state.initialLoad
			? props.admin
				? [options]
				: [organizationId, options]
			: null,
		Object.values(options)
	);

	const mappedData = (data || []).map((d) => ({
		...d,
		date: format.localDate(d.CreatedOn),
		userName: d.UserData ? user.fullName(d.UserData) : "",
		email: d.UserData ? `${d.UserData.Email}` : "",
		description: d.ObjectType
			? d.ObjectType.match(/[A-Z][a-z]+|[0-9]+/g).join(" ")
			: "",
		siteName:
			d.ExtraDetail && d.ExtraDetail.SiteData
				? d.ExtraDetail.SiteData.Name
				: "-",
	}));

	const filteredData = useSearchFilter(mappedData || [], state.search, [
		"date",
		"userName",
		"email",
		"description",
		"siteName",
	]);

	function openHistoryWizard(log) {
		setState((_state) => ({
			..._state,
			historyWizardOpen: true,
			log: log,
		}));
	}

	if (state.historyWizardOpen) {
		return (
			<HistoryDetails
				close={() => {
					setState((_state) => ({ ..._state, historyWizardOpen: false }));
				}}
				log={state.log}
			/>
		);
	}

	return (
		<div>
			<ReportWrapper
				{...props}
				title={props.admin ? "Admin Audit History" : "Audit History"}
				data={filteredData}
				columns={columns({ openHistoryWizard: openHistoryWizard })}
				defaultSorted={defaultSorting}
				updateOptions={updateOptions}
				loading={isLoading}
				showSitePicker={!props.admin}
			/>
		</div>
	);
}
