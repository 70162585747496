import { StepTitle } from "../WizardLayout";
import { cloneDeep, find, map, _ } from "lodash";
import BeaconLookup from "../BeaconLookup";
import Button from "../../layout/Button";
import Dropdown from "../../layout/Dropdown";
import EditableInputField from "../../layout/EditableInputField";
import MultiChoiceButton from "../MultiChoiceButton";
import React from "react";
import SummaryTable from "../SummaryTable";
import WizardNavigation from "../WizardNavigation";

import { VALIDATION_WIZARD_TYPE } from "../../../helpers/constants";
import FlexWrapperCol from "../../layout/FlexWrapperCol";
import { ChooseValidationRate } from "./ValidationRate";
import { ChooseSpaces } from "./ValidationSpace";

const getTypeDisplay = (type) => {
	const isBeacon = type === VALIDATION_WIZARD_TYPE.BEACON;
	return isBeacon ? "beacon" : "qr code";
};

function getButton(
	name,
	color,
	onClick,
	isDisabled,
	isEditing,
	goTo,
	isValidationType,
	keyStrokeHandler
) {
	let label = isEditing
		? isValidationType
			? "Next"
			: "Review"
		: `${name.charAt(0).toUpperCase()}${name.slice(1)}`;
	return (
		<Button
			key={name}
			color={isEditing ? "blue" : color}
			onClick={
				isEditing
					? () => (isValidationType ? goTo("validationValue") : goTo("summary"))
					: onClick
			}
			disabled={isDisabled}
			keyStrokeHandler={keyStrokeHandler}
		>
			{label}
		</Button>
	);
}

export function location({
	close,
	next,
	isSubmitting,
	values,
	goTo,
	keyStrokeHandler,
}) {
	const isIncomplete = isSubmitting || !values.location;
	const typeDisplay = getTypeDisplay(values.type);

	return {
		id: "location",
		label: "Location",
		render: () => (
			<div>
				<StepTitle>What is the location of the {typeDisplay}?</StepTitle>
				<EditableInputField
					type="text"
					name="location"
					value={values.location || ""}
					useLabel="Location"
				/>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [getButton("cancel", "red", close)]
				}
				rightItems={[
					getButton(
						"next",
						"blue",
						next,
						isIncomplete,
						values.editFromSummary,
						goTo,
						undefined,
						keyStrokeHandler
					),
				]}
			/>
		),
	};
}

export function uniqueId({
	previous,
	next,
	isSubmitting,
	values,
	goTo,
	wizardProps,
	setFieldValue,
	keyStrokeHandler,
}) {
	const isIncomplete =
		isSubmitting ||
		!values.uniqueId ||
		!values.uniqueId === "" ||
		!values.uniqueIdValid;

	return {
		id: "uniqueId",
		label: "Unique ID",
		render: () => (
			<div>
				<StepTitle>What is the Unique ID of the beacon?</StepTitle>
				<BeaconLookup
					uniqueId={values.uniqueId || ""}
					organizationId={wizardProps.organizationId}
					mode={"validationBeacon"}
					onChange={(value) => {
						setFieldValue("uniqueId", value.uniqueId);
						setFieldValue("uniqueIdValid", value.uniqueIdValid);
					}}
				/>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [getButton("back", "blue", previous)]
				}
				rightItems={[
					getButton(
						"next",
						"blue",
						next,
						isIncomplete,
						values.editFromSummary,
						goTo,
						undefined,
						keyStrokeHandler
					),
				]}
			/>
		),
	};
}

export function selfValidation({
	previous,
	next,
	isSubmitting,
	setFieldValue,
	values,
	goTo,
	keyStrokeHandler,
}) {
	const isIncomplete = isSubmitting;
	const typeDisplay = getTypeDisplay(values.type);

	return {
		id: "selfValidation",
		label: " Self Validation",
		render: () => (
			<div>
				<StepTitle>Is this {typeDisplay} for self validation?</StepTitle>
				<div>
					If this validation should not stack with other validations then choose
					Yes. <br />
					<br />
					Note that this will only stop it stacking with other {typeDisplay}s
					with this setting turned on and that stacking rules only apply if the
					site has stacking validation enabled.
				</div>
				<MultiChoiceButton
					selected={!values.selfValidation}
					onClick={() => {
						setFieldValue("selfValidation", false);
					}}
				>
					No
				</MultiChoiceButton>
				<MultiChoiceButton
					selected={values.selfValidation}
					onClick={() => {
						setFieldValue("selfValidation", true);
					}}
				>
					Yes
				</MultiChoiceButton>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [getButton("back", "blue", previous)]
				}
				rightItems={[
					getButton(
						"next",
						"blue",
						next,
						isIncomplete,
						values.editFromSummary,
						goTo,
						undefined,
						keyStrokeHandler
					),
				]}
			/>
		),
	};
}

export function beaconType({
	previous,
	next,
	isSubmitting,
	setFieldValue,
	values,
	goTo,
	keyStrokeHandler,
}) {
	const isGroupAccess = values.validationType === "GroupAccess";
	return {
		id: "beaconType",
		label: "Type",
		render: () => (
			<div>
				<StepTitle>Is this beacon to add users to a group?</StepTitle>
				<div>
					Users will be able to scan this beacon to be automatically added to a
					group, granting them access to private sites or special pricing.
				</div>
				<MultiChoiceButton
					selected={!isGroupAccess}
					onClick={() => {
						setFieldValue("validationType", "");
					}}
				>
					No
				</MultiChoiceButton>
				<MultiChoiceButton
					selected={isGroupAccess}
					onClick={() => {
						setFieldValue("validationType", "GroupAccess");
					}}
				>
					Yes
				</MultiChoiceButton>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [getButton("back", "blue", previous)]
				}
				rightItems={[
					getButton(
						"next",
						"blue",
						() => {
							if (isGroupAccess) {
								next();
							} else {
								goTo("selfValidation");
							}
						},
						isSubmitting,
						values.editFromSummary,
						goTo,
						undefined,
						keyStrokeHandler
					),
				]}
			/>
		),
	};
}

export function groupAccess({
	previous,
	isSubmitting,
	setFieldValue,
	values,
	wizardProps,
	goTo,
	keyStrokeHandler,
}) {
	return {
		id: "beaconGroupAccess",
		label: "Group Access",
		render: () => (
			<div>
				<StepTitle>Select a group</StepTitle>
				<Dropdown
					options={wizardProps.organizationAccessGroups.map((group) => ({
						label: group.Name,
						value: group.OrganizationAccessGroupID,
					}))}
					value={values.accessGroup}
					onChange={(value) => {
						setFieldValue("accessGroup", value);
					}}
					isClearable
					isDisabled={values.availableSites.length}
				/>
				<StepTitle style={{ marginTop: "24px" }}>
					Or a list of sites to include
				</StepTitle>
				<Dropdown
					isMulti={true}
					options={wizardProps.availableSites.map((site) => ({
						label: site.name,
						value: site.id,
					}))}
					value={values.validationSites.map((site) => ({
						value: site.id,
						label: site.name,
					}))}
					onChange={(value) => {
						setFieldValue(
							"validationSites",
							value.map((option) => ({ id: option.value, name: option.label }))
						);
					}}
					isDisabled={!!values.accessGroup}
				/>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [getButton("back", "blue", previous)]
				}
				rightItems={[
					getButton(
						"next",
						"blue",
						() => goTo("summary"),
						isSubmitting ||
							(!values.accessGroup && !values.availableSites.length),
						values.editFromSummary,
						goTo,
						undefined,
						keyStrokeHandler
					),
				]}
			/>
		),
	};
}

export function sites({
	next,
	previous,
	setFieldValue,
	isSubmitting,
	values,
	goTo,
	keyStrokeHandler,
}) {
	const availableOptions = [{ label: "All Sites", value: 0 }].concat(
		values.availableSites.map((site) => ({
			label: site.name,
			value: site.id,
		}))
	);

	const isIncomplete = isSubmitting || !values.validationSites?.length;

	const getBeaconSiteWithRateId = (value) => {
		const validationSites = cloneDeep(values.validationSites);
		let result = [];

		value.forEach((d) => {
			validationSites.forEach((beaconSite) => {
				if (beaconSite.id === d.value && beaconSite.rateId) {
					d.rateId = beaconSite.rateId;
				}
			});
			result.push({
				id: d.value || d.id,
				name: d.label || d.name,
				rateId: d.rateId,
			});
		});

		return result;
	};

	return {
		id: "sites",
		label: `Select Sites`,
		render: () => (
			<div>
				<StepTitle>Select sites</StepTitle>

				<Dropdown
					isMulti={true}
					options={availableOptions}
					value={values.validationSites.map((d) => ({
						label: d.name,
						value: d.id,
					}))}
					onChange={(options) => {
						values.leaseParkIds = [];
						find(options, ({ value }) => value === 0)
							? setFieldValue(
									"validationSites",
									getBeaconSiteWithRateId(values.availableSites)
							  )
							: setFieldValue(
									"validationSites",
									options ? getBeaconSiteWithRateId(options) : []
							  );
					}}
				/>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [getButton("back", "blue", previous)]
				}
				rightItems={[
					<Button
						key="next"
						color="blue"
						onClick={() => (values.editFromSummary ? goTo("spaces") : next())}
						disabled={isSubmitting || isIncomplete}
						keyStrokeHandler={keyStrokeHandler}
					>
						Next
					</Button>,
				]}
			/>
		),
	};
}

export function spaces({
	next,
	previous,
	setFieldValue,
	wizardProps,
	values,
	goTo,
	keyStrokeHandler,
	handleChange,
}) {
	return {
		id: "spaces",
		label: "Select Spaces",
		render: () => (
			<div>
				<StepTitle>Select spaces</StepTitle>
				<ChooseSpaces
					setFieldValue={setFieldValue}
					wizardProps={wizardProps}
					values={values}
					handleChange={handleChange}
				/>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [getButton("back", "blue", previous)]
				}
				rightItems={[
					<Button
						key="next"
						color="blue"
						onClick={() =>
							values.editFromSummary ? goTo("validationType") : next()
						}
						keyStrokeHandler={keyStrokeHandler}
					>
						Next
					</Button>,
				]}
			/>
		),
	};
}

export function validationType({
	previous,
	next,
	isSubmitting,
	handleChange,
	values,
	goTo,
	keyStrokeHandler,
}) {
	const isIncomplete = isSubmitting || !values.validationType;

	return {
		id: "validationType",
		label: "Validation Type",
		render: () => (
			<div>
				<StepTitle>What is the validation type?</StepTitle>
				<Dropdown
					options={values.validationTypeOptions}
					value={
						values.validationTypeOptions.find(
							(e) => e.value === values.validationType
						) || ""
					}
					onChange={(value) =>
						handleChange({
							target: {
								name: "validationType",
								value: value.value,
							},
						})
					}
				/>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [getButton("back", "blue", previous)]
				}
				rightItems={[
					getButton(
						"next",
						"blue",
						next,
						isIncomplete,
						values.editFromSummary,
						goTo,
						true,
						keyStrokeHandler
					),
				]}
			/>
		),
	};
}

export function validationValue({
	values,
	errors,
	touched,
	previous,
	next,
	handleChange,
	isSubmitting,
	goTo,
	keyStrokeHandler,
}) {
	let isIncomplete;

	if (values.validationType === "Rate") {
		let isCompleted = null;

		map(values.validationSites, (beaconSite) => {
			if (isCompleted === null) {
				isCompleted = !isNaN(beaconSite.rateId);
			} else {
				isCompleted = isCompleted && !isNaN(beaconSite.rateId);
			}
		});

		isIncomplete = isSubmitting || !isCompleted;

		const availableSite = values.availableSites.find(
			(site) => site.id === values.validationSite
		);

		return {
			id: "validationValue",
			label: "Validation Rate",
			render: () => (
				<div>
					<StepTitle>
						Which validation rate should be applied to each site?
					</StepTitle>
					<ChooseValidationRate
						validationSites={values.validationSites}
						validationSite={{ ...availableSite, rateId: values.rateId }}
						handleChange={handleChange}
						organizationId={values.organizationId}
					/>
				</div>
			),
			footer: () => (
				<WizardNavigation
					leftItems={getButton("back", "blue", previous)}
					rightItems={[
						getButton(
							"next",
							"blue",
							next,
							isIncomplete,
							values.editFromSummary,
							goTo,
							undefined,
							keyStrokeHandler
						),
					]}
				/>
			),
		};
	} else {
		isIncomplete =
			isSubmitting || !values.validationValue || values.validationValue < 0.1;

		return {
			id: "validationValue",
			label: `${
				values.validationType === "GroupAccess"
					? "Group Access"
					: values.validationType || "Value"
			}`,
			render: () => (
				<div>
					<StepTitle>
						What is the {values.validationType.toLowerCase() + " amount"}?
					</StepTitle>
					<EditableInputField
						type="number"
						min={0.1}
						name="validationValue"
						value={values.validationValue || ""}
						useLabel={values.validationType}
						error={
							errors.validationValue && touched.validationValue
								? errors.validationValue
								: null
						}
					/>
				</div>
			),
			footer: () => (
				<WizardNavigation
					leftItems={
						!values.editFromSummary && [getButton("back", "blue", previous)]
					}
					rightItems={[
						getButton(
							"next",
							"blue",
							next,
							isIncomplete,
							values.editFromSummary,
							goTo,
							undefined,
							keyStrokeHandler
						),
					]}
				/>
			),
		};
	}
}

export function summary({
	goTo,
	handleSubmit,
	isSubmitting,
	setFieldValue,
	values,
	wizardProps: { mode, availableSpaces },
	keyStrokeHandler,
}) {
	const isGroupAccess = values.validationType === "GroupAccess";
	const isBeacon = values.type === VALIDATION_WIZARD_TYPE.BEACON;
	const items = [
		{
			title: "Location",
			value: values.location,
			key: "location",
			edit: () => {
				setFieldValue("editFromSummary", true);
				goTo("location");
			},
		},
		isBeacon
			? {
					title: "Unique ID",
					value: values.uniqueId,
					key: "uniqueId",
			  }
			: null,
		{
			title: `Validation type`,
			value:
				values.validationType === "GroupAccess"
					? "Group Access"
					: values.validationType,
			key: "validationType",
			edit: () => {
				setFieldValue("editFromSummary", true);
				if (isGroupAccess) {
					goTo("beaconType");
				} else {
					goTo("validationType");
				}
			},
		},
		{
			title: "Sites",
			value: values.validationSites?.map((site) => site.name).join(", "),
			key: "sites",
			edit: () => {
				setFieldValue("editFromSummary", true);
				if (isGroupAccess) {
					goTo("beaconGroupAccess");
				} else {
					goTo("sites");
				}
			},
		},
		!isBeacon && {
			title: "Spaces",
			value: _.uniqBy(
				Object.values(values.leaseParkIds).flatMap((id) => {
					let space = availableSpaces.flatMap((lp) => {
						return lp.Spaces.find((s) => s.LeaseParkID === id);
					});

					return space.map((s) => s?.Name).filter((n) => !!n);
				})
			).join(", "),
			key: "spaces",
			edit: () => {
				setFieldValue("editFromSummary", true);
				goTo("spaces");
			},
		},
	].filter((item) => item);

	if (isGroupAccess) {
		items.push({
			title: "Group",
			value: values.accessGroup?.label,
			key: "accessGroup",
			edit: () => {
				setFieldValue("editFromSummary", true);
				goTo("beaconGroupAccess");
			},
		});
	} else {
		const getValidationValue = () => {
			if (values.validationType === "Rate") {
				return values.validationSites
					.filter((site) => site.rateId)
					.map((site) => `${site.name} - ${site.rateId}`)
					.join(", ");
			}

			if (values.validationType === "Fixed") {
				return `-$${values.validationValue}`;
			}

			return `-${values.validationValue}%`;
		};

		items.push(
			{
				title: "Self Validation",
				value: values.selfValidation ? "Yes" : "No",
				key: "selfValidation",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("selfValidation");
				},
			},
			{
				title:
					values.validationType === "Rate"
						? "Rate"
						: `${values.validationType} Amount`,
				value: getValidationValue(),

				key: "validationValue",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("validationValue");
				},
			}
		);
	}

	let button = getButton(
		"submit",
		"green",
		handleSubmit,
		isSubmitting,
		undefined,
		undefined,
		undefined,
		keyStrokeHandler
	);

	if (mode === "delete") {
		items.forEach((item) => delete item.edit);
		button = getButton("remove", "red", handleSubmit, isSubmitting);
	}

	return {
		id: "summary",
		label: "Summary",
		render: () => (
			<div>
				<StepTitle>Summary</StepTitle>
				<SummaryTable items={items} />
			</div>
		),
		footer: () => <WizardNavigation rightItems={[button]} />,
	};
}

export function download({
	handleSubmit,
	isSubmitting,
	setFieldValue,
	values,
	keyStrokeHandler,
	close,
}) {
	const button = getButton(
		"download",
		"green",
		handleSubmit,
		isSubmitting || !values.format || !values.security,
		undefined,
		undefined,
		undefined,
		keyStrokeHandler
	);

	const options = [
		{ label: "PDF", value: "pdf" },
		{ label: "SVG", value: "svg" },
	];

	const security = [
		{ label: "Color", value: "color" },
		{ label: "Logo", value: "logo" },
	];

	return {
		id: "download",
		label: "Download",
		render: () => (
			<FlexWrapperCol>
				<div>
					<StepTitle>What file format would you like to download?</StepTitle>
					<Dropdown
						options={options}
						value={options.find((e) => e.value === values.format) || ""}
						onChange={(value) => {
							setFieldValue("format", value.value);
						}}
					/>
				</div>
				<div>
					<StepTitle>
						Which security option would you like to display?
					</StepTitle>

					<Dropdown
						isMulti={true}
						value={values.security}
						options={security}
						onChange={(value) => {
							setFieldValue("security", value);
						}}
					/>
				</div>
			</FlexWrapperCol>
		),
		footer: () => (
			<WizardNavigation
				leftItems={[getButton("cancel", "red", close)]}
				rightItems={[button]}
			/>
		),
	};
}
