import { Redirect, Route, Switch } from "react-router-dom";
import { usePermissions, useQueryData } from "../../hooks";
import Beacons from "../../components/report/hardware/Beacons";
import Cameras from "../../components/report/hardware/Cameras";
import ErrorBoundary from "../ErrorBoundary";
import GateControllers from "../../components/report/hardware/GateControllers";
import Installations from "../../components/report/hardware/Installations";
import Kiosks from "../../components/report/hardware/Kiosks";
import ControlBoxes from "../../components/report/hardware/ControlBoxes";
import React from "react";
import gql from "graphql-tag";

export default function AdminHardwareContainer(props) {
	let clients = [];
	const isAdmin = usePermissions("IsAdmin");

	const {
		data: { getClients },
		isLoading,
	} = useQueryData(
		gql`
			query {
				getClients {
					ClientID
					Name
				}
			}
		`,
		{},
		!isAdmin
	);

	if (getClients) {
		clients = (getClients || []).map((client) => ({
			value: client.ClientID,
			label: client.Name,
		}));
	}

	return (
		<ErrorBoundary>
			<Switch>
				<Route
					path={`${props.match.url}/installations`}
					render={(_props) => (
						<Installations
							{...props}
							{..._props}
							clients={clients}
							isLoading={isLoading}
						/>
					)}
				/>
				<Route
					path={`${props.match.url}/gate-controllers`}
					render={(_props) => (
						<GateControllers
							{...props}
							{..._props}
							clients={clients}
							isLoading={isLoading}
						/>
					)}
				/>
				<Route
					path={`${props.match.url}/beacons`}
					render={(_props) => (
						<Beacons
							{...props}
							{..._props}
							clients={clients}
							isLoading={isLoading}
						/>
					)}
				/>
				<Route
					path={`${props.match.url}/cameras`}
					render={(_props) => (
						<Cameras
							{...props}
							{..._props}
							clients={clients}
							isLoading={isLoading}
						/>
					)}
				/>
				<Route
					path={`${props.match.url}/kiosks`}
					render={(_props) => (
						<Kiosks
							{...props}
							{..._props}
							clients={clients}
							isLoading={isLoading}
						/>
					)}
				/>
				<Route
					path={`${props.match.url}/control-boxes`}
					render={(_props) => (
						<ControlBoxes
							{...props}
							{..._props}
							clients={clients}
							isLoading={isLoading}
						/>
					)}
				/>
				<Redirect to={`${props.match.url}/installations`} />
			</Switch>
		</ErrorBoundary>
	);
}
