import { StepText, StepTitle } from "../WizardLayout";
import Button from "../../layout/Button";
import Dropdown from "../../layout/Dropdown";
import EditableInputField from "../../layout/EditableInputField";
import InputWithIcon from "../../layout/InputWithIcon";
import MultiTextInput from "../../layout/MultiTextInput";
import React from "react";
import SummaryTable from "../SummaryTable";
import Toggle from "../../layout/Toggle";
import WizardNavigation from "../WizardNavigation";
import styled from "styled-components";

const Wrapper = styled.div`
	display: flex;
`;

const Label = styled.div`
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 4px;
`;

const RowWrapper = styled.div`
	margin: 16px 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 20px;
`;

export const detailsStep = ({
	goTo,
	next,
	setFieldValue,
	values,
	wizardProps,
}) => ({
	id: "details",
	label: "Details",
	render: () => (
		<div>
			<StepTitle>Product Details</StepTitle>
			<EditableInputField
				type="text"
				name="Name"
				value={values.Name || ""}
				useLabel="Name"
				onChange={(event) => {
					setFieldValue("Name", event.target.value);
				}}
			/>

			<Label>Description</Label>

			<EditableInputField
				type="text"
				name="Description"
				value={values.Description || ""}
				textArea={true}
				useLabel=" "
				onChange={(event) => {
					setFieldValue("Description", event.target.value);
				}}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="cancel" onClick={wizardProps.close} color="blue">
						Cancel
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					disabled={!values.Name || !values.Description}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const feesStep = ({
	goTo,
	keyStrokeHandler,
	next,
	previous,
	setFieldValue,
	values,
}) => ({
	id: "fees",
	label: "Fees",
	render: () => (
		<div>
			<StepTitle>Product Fees</StepTitle>
			<Wrapper>
				<Toggle
					label="Donation?"
					onChange={(value) => setFieldValue("IsDonation", value)}
					checked={values.IsDonation}
				/>
			</Wrapper>

			{!values.IsDonation ? (
				<RowWrapper>
					<Label>Fixed Fee</Label>
					<InputWithIcon
						allowNull={true}
						value={values.Fee}
						onFormat={(value) => {
							setFieldValue("Fee", value);
						}}
						icon="DollarSign"
						align="left"
					/>
				</RowWrapper>
			) : (
				<>
					<RowWrapper>
						<Label>Minimum Fee</Label>
						<InputWithIcon
							allowNull={true}
							value={values.MinFee}
							onFormat={(value) => {
								setFieldValue("MinFee", value);
							}}
							icon="DollarSign"
							align="left"
						/>
					</RowWrapper>

					<RowWrapper>
						<Label>Maximum Fee</Label>
						<InputWithIcon
							allowNull={true}
							value={values.MaxFee}
							onFormat={(value) => {
								setFieldValue("MaxFee", value);
							}}
							icon="DollarSign"
							align="left"
						/>
					</RowWrapper>
				</>
			)}
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="back" color="blue" onClick={previous}>
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					disabled={
						values.IsDonation
							? values.MinFee > values.MaxFee ||
							  !(values.MinFee > 0 && values.MinFee <= 10000) ||
							  !(values.MaxFee > 0 && values.MaxFee <= 10000)
							: !(values.Fee > 0 && values.Fee <= 10000)
					}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const metadataStep = ({
	goTo,
	next,
	previous,
	setFieldValue,
	values,
}) => ({
	id: "metadata",
	label: "Metadata",
	render: () => (
		<div>
			<StepTitle>What metadata fields should be captured?</StepTitle>
			<MultiTextInput
				isMulti={true}
				value={values.MetadataFields?.map((field) => ({
					value: field,
					label: field,
				}))}
				isClearable={true}
				onChange={(value) =>
					setFieldValue(
						"MetadataFields",
						value?.map((field) => field.value) || []
					)
				}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="back" color="blue" onClick={previous}>
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const sitesStep = ({
	goTo,
	next,
	previous,
	setFieldValue,
	values,
	wizardProps,
}) => ({
	id: "sites",
	label: "Sites",
	render: () => (
		<div>
			<StepTitle>Which sites is this product available on?</StepTitle>
			<Dropdown
				isMulti={true}
				options={wizardProps.availableSites.map((site) => ({
					value: site.SiteID,
					label: site.Name,
					SiteID: site.SiteID,
					Name: site.Name,
				}))}
				value={values.Sites}
				onChange={(value) => {
					setFieldValue("Sites", value || []);
				}}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="back" color="blue" onClick={previous}>
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const successTextStep = ({
	goTo,
	next,
	previous,
	setFieldValue,
	values,
}) => ({
	id: "successText",
	label: "Success Text",
	render: () => (
		<div>
			<StepTitle>Success Message after Purchase</StepTitle>
			<EditableInputField
				type="text"
				name="SuccessText"
				value={values.SuccessText || ""}
				textArea={true}
				useLabel=" "
				onChange={(event) => {
					setFieldValue("SuccessText", event.target.value);
				}}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="back" color="blue" onClick={previous}>
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const summaryStep = ({
	goTo,
	handleSubmit,
	isSubmitting,
	setFieldValue,
	values,
	wizardProps,
}) => ({
	id: "summary",
	label: "Summary",
	render: () => (
		<div>
			<StepTitle>Summary</StepTitle>
			<SummaryTable
				items={[
					{
						title: "Name",
						value: values.Name,
						key: "name",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("details");
						},
					},
					{
						title: "Description",
						value: values.Description,
						key: "description",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("details");
						},
					},
					{
						title: "Donation",
						value: values.IsDonation ? "Yes" : "No",
						key: "donation",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("fees");
						},
					},
					!values.IsDonation && {
						title: "Fixed Fee",
						value: values.Fee,
						key: "fee",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("fees");
						},
					},
					values.IsDonation && {
						title: "Minimum Fee",
						value: values.MinFee,
						key: "minFee",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("fees");
						},
					},
					values.IsDonation && {
						title: "Maximum Fee",
						value: values.MaxFee,
						key: "maxFee",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("fees");
						},
					},
					{
						title: "Metadata Fields",
						value: values.MetadataFields?.join(", "),
						key: "metadata",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("metadata");
						},
					},
					{
						title: "Success Text",
						value: values.SuccessText,
						key: "successText",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("successText");
						},
					},
					{
						title: "Sites",
						value: values.Sites.map((site) => site.Name).join(", "),
						key: "sites",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("sites");
						},
					},
				].filter((e) => e)}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={isSubmitting}
				>
					{wizardProps.mode === "edit" ? "Update" : "Create"}
				</Button>,
			]}
		/>
	),
});

export const deleteStep = ({ handleSubmit, isSubmitting, wizardProps }) => ({
	id: "delete",
	label: "Delete Product",
	render: () => (
		<div>
			<StepText>
				Are you sure you want to delete the product{" "}
				<span style={{ fontWeight: "bold" }}>{wizardProps.product.Name}?</span>
				<br />
				This action CANNOT be undone.
			</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={isSubmitting}
				>
					Delete
				</Button>,
			]}
		/>
	),
});
