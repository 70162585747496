import React from "react";
import { StepText } from "../../../WizardLayout";
import WizardNavigation from "../../../WizardNavigation";
import Button from "../../../../layout/Button";

const STEP_TEXT_CONSTANTS = {
	CONFIRM_DELETE: "Are you sure you want to delete this event?",
	LABEL: "Delete Event",
	BACK_BUTTON: "Back",
	DELETE_BUTTON: "Cancel Event",
};

export function CANCEL_EVENT_STEP({
	wizardProps,
	isSubmitting,
	values,
	keyStrokeHandler,
	handleSubmit,
}) {
	const name = values?.eventToCancel?.name;
	const seriesName = values.seriesName;
	const isLastEventInSeries = (values.events.length ?? 0) === 0;
	return {
		id: "delete-event",
		label: STEP_TEXT_CONSTANTS.LABEL,
		render: function () {
			return isLastEventInSeries ? (
				<StepText>
					Unable to cancel {name} as it is the last event for {seriesName}.
				</StepText>
			) : (
				<StepText>Cancel the event for {name}?</StepText>
			);
		},
		footer: function () {
			return (
				<WizardNavigation
					leftItems={[
						<Button key="cancel" color="blue" onClick={wizardProps.close}>
							{STEP_TEXT_CONSTANTS.BACK_BUTTON}
						</Button>,
					]}
					rightItems={
						!isLastEventInSeries && [
							<Button
								key="submit"
								onClick={handleSubmit}
								color={"red"}
								disabled={isSubmitting || isLastEventInSeries}
								keyStrokeHandler={keyStrokeHandler}
							>
								{STEP_TEXT_CONSTANTS.DELETE_BUTTON}
							</Button>,
						]
					}
				/>
			);
		},
	};
}
