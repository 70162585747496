import styled from "styled-components";

export const Label = styled.div`
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 16px;
	margin-top: 16px;
`;

export const DayLabel = styled.div`
	font-size: 18px;
	font-weight: 700;
	position: relative;
	width: 100px;

	> div {
		position: absolute;
		transform: translateY(-50%);
		top: 50%;
	}
`;

export const Wrapper = styled.div`
	display: flex;
	position: relative;
	margin-left: 30px;

	> * {
		margin: 0 10px;
	}

	> div {
		.tooltip-icon {
			margin-top: 17px;
			cursor: pointer;
		}
	}
`;

export const FieldContent = styled.div`
	> * {
		margin: 0;
		width: 100%;
		min-height: 32px;
	}
`;
