import { StepText, StepTitle } from "../WizardLayout";
import { map, sortBy } from "lodash";
import Button from "../../layout/Button";
import Dropdown from "../../layout/Dropdown";
import EditableInputField from "../../layout/EditableInputField";
import React from "react";
import SummaryTable from "../SummaryTable";
import WizardNavigation from "../WizardNavigation";
import gql from "graphql-tag";
import styled from "styled-components";
import { useQueryData } from "../../../hooks";

const Label = styled.div`
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 4px;
`;

const FieldWrapper = styled.div`
	margin: 32px 0;
`;

const FieldContent = styled.div`
	> * {
		margin: 0;
		max-width: 600px;
		min-height: 32px;
	}
`;

export const payStationStep = ({
	handleSubmit,
	isSubmitting,
	values,
	wizardProps,
	setFieldValue,
}) => {
	return {
		id: "lane",
		label: "Lane",
		render: () => {
			return (
				<div>
					<StepText>What is the name of this Pay Station?</StepText>
					<EditableInputField
						type="text"
						name="payStationName"
						value={values.payStationName || ""}
						useLabel={"Pay Station Name"}
						onChange={(event) =>
							setFieldValue("payStationName", event.target.value)
						}
					/>

					<FieldWrapper>
						<Label>Pay Station group:</Label>

						<FieldContent>
							<Dropdown
								isClearable={true}
								onChange={(value) =>
									setFieldValue("groupName", value ? value.value : null)
								}
								options={wizardProps.groupOptions}
								value={
									wizardProps.groupOptions.find(
										(e) => e.value === values.groupName
									) ?? null
								}
							/>
						</FieldContent>
					</FieldWrapper>
				</div>
			);
		},
		footer: () => (
			<WizardNavigation
				leftItems={[
					<Button key="cancel" color="blue" onClick={wizardProps.close}>
						Cancel
					</Button>,
				]}
				rightItems={[
					<Button
						key="submit"
						color="green"
						onClick={handleSubmit}
						disabled={isSubmitting}
					>
						{values.mode === "add-pay-station" ? "Add" : "Update"}
					</Button>,
				]}
			/>
		),
	};
};

export const removePayStationStep = ({
	handleSubmit,
	isSubmitting,
	values,
	wizardProps,
}) => ({
	id: "removePayStation",
	label: "Remove Pay Station",
	render: () => (
		<div>
			<StepText>
				Are you sure you want to remove Pay Station{" "}
				<b>{values.payStationName}</b>? This will delete any kiosks mappings on
				this Pay Station as well. This action CANNOT be undone.
			</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={isSubmitting}
				>
					Remove
				</Button>,
			]}
		/>
	),
});

export const removeKioskStep = ({
	handleSubmit,
	isSubmitting,
	values,
	wizardProps,
}) => ({
	id: "removeKiosk",
	label: "Remove Kiosk",
	render: () => (
		<div>
			<StepText>
				Are you sure you want to remove kiosk {values.kioskName}?
			</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={isSubmitting}
				>
					Remove
				</Button>,
			]}
		/>
	),
});

function KioskSerialNumberSelector(props) {
	const {
		data: { getAvailableKiosks: availableKiosks },
	} = useQueryData(
		gql`
			query {
				getAvailableKiosks {
					HardwareID
					SerialNumber
				}
			}
		`
	);

	return (
		<div>
			<StepTitle>Please choose a kiosk</StepTitle>
			<Dropdown
				options={sortBy(
					map(availableKiosks, (k) => ({
						value: k.HardwareID,
						label: k.SerialNumber,
					})),
					"label"
				)}
				value={
					props.hardwareId
						? { value: props.hardwareId, label: props.serialNumber }
						: null
				}
				onChange={(k) => {
					props.setFieldValue("hardwareId", k.value);
					props.setFieldValue("serialNumber", k.label);
				}}
			/>
		</div>
	);
}

export const kioskSerialNumberStep = ({
	values,
	setFieldValue,
	wizardProps,
	next,
	keyStrokeHandler,
}) => ({
	id: "serialNumber",
	label: "Serial Number",
	render: () => (
		<KioskSerialNumberSelector
			setFieldValue={setFieldValue}
			hardwareId={values.hardwareId}
			serialNumber={values.serialNumber}
		/>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="cancel" onClick={wizardProps.close} color="blue">
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={next}
					disabled={!values.hardwareId}
					keyStrokeHandler={keyStrokeHandler}
				>
					Next
				</Button>,
			]}
		/>
	),
});

export const kioskNameStep = ({
	values,
	setFieldValue,
	next,
	previous,
	keyStrokeHandler,
}) => ({
	id: "name",
	label: "Name",
	render: () => (
		<div>
			<StepTitle>What is the name of the kiosk?</StepTitle>
			<EditableInputField
				type="text"
				name={"name"}
				value={values.kioskName || ""}
				useLabel={"Name"}
				onChange={(event) => {
					setFieldValue("kioskName", event.target.value);
				}}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="back" color="blue" onClick={previous}>
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={next}
					disabled={!values.kioskName}
					keyStrokeHandler={keyStrokeHandler}
				>
					Next
				</Button>,
			]}
		/>
	),
});

export const kioskSummaryStep = ({
	goTo,
	handleSubmit,
	isSubmitting,
	values,
	keyStrokeHandler,
}) => ({
	id: "summary",
	label: "Summary",
	render: () => {
		const items = [
			{
				title: "Pay Station",
				value: values.payStationName,
				key: "payStationName",
			},
			{
				title: "Serial Number",
				value: values.serialNumber,
				key: "serialNumber",
				edit: () => {
					goTo("serialNumber");
				},
			},
			{
				title: "Name",
				value: values.kioskName,
				key: "name",
				edit: () => {
					goTo("name");
				},
			},
		];

		return (
			<div>
				<StepTitle>Summary</StepTitle>
				<SummaryTable items={items} />
			</div>
		);
	},
	footer: () => (
		<WizardNavigation
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					Add
				</Button>,
			]}
		/>
	),
});
