import gql from "graphql-tag";
import { useQueryData } from "../../../hooks";
export const useGetCamerasForOrg = (organizationId) => {
	const {
		data: { getCamerasForOrganization: cameras },
		isLoading,
	} = useQueryData(
		gql`
			query ($organizationId: Int!) {
				getCamerasForOrganization(organizationId: $organizationId) {
					CameraID
					SerialNumber
				}
			}
		`,
		{
			organizationId,
		},
		!organizationId
	);

	return { cameras, isLoading };
};
