import * as Yup from "yup";
import * as productSteps from "./product-steps";
import Alert from "react-s-alert";
import React from "react";
import WizardLayout from "../WizardLayout";
import gql from "graphql-tag";
import { useMutateData } from "../../../hooks";

const constructWizard = (props) => {
	if (props.mode === "add") {
		return {
			title: "Add Product",
			initialStep: 0,
			steps: [
				productSteps.detailsStep,
				productSteps.feesStep,
				productSteps.metadataStep,
				productSteps.successTextStep,
				productSteps.sitesStep,
				productSteps.summaryStep,
			],
		};
	} else if (props.mode === "edit") {
		return {
			title: "Edit Product",
			initialStep: 5,
			steps: [
				productSteps.detailsStep,
				productSteps.feesStep,
				productSteps.metadataStep,
				productSteps.successTextStep,
				productSteps.sitesStep,
				productSteps.summaryStep,
			],
		};
	} else if (props.mode === "delete") {
		return {
			title: "Delete Product",
			initialStep: 0,
			steps: [productSteps.deleteStep],
		};
	}
};

export default function ProductWizard(props) {
	const wizard = constructWizard(props);

	const createProduct = useMutateData(gql`
		mutation ($organizationId: Int!, $product: CreateProductInput!) {
			createProduct(organizationId: $organizationId, product: $product)
		}
	`);

	const updateProduct = useMutateData(gql`
		mutation (
			$organizationId: Int!
			$productId: Int!
			$product: UpdateProductInput!
		) {
			updateProduct(
				organizationId: $organizationId
				productId: $productId
				product: $product
			)
		}
	`);

	const deleteProduct = useMutateData(gql`
		mutation ($organizationId: Int!, $productId: Int!) {
			deleteProduct(organizationId: $organizationId, productId: $productId)
		}
	`);

	return (
		<WizardLayout
			close={props.close}
			title={wizard.title}
			values={[
				{ name: "mode", value: props.mode, validator: Yup.string() },
				{
					name: "Name",
					value: props.product ? props.product.Name : null,
					validator: Yup.string(),
				},
				{
					name: "Description",
					value: props.product ? props.product.Description : null,
					validator: Yup.string(),
				},
				{
					name: "IsDonation",
					value: props.product ? props.product.IsDonation : false,
					validator: Yup.boolean(),
				},
				{
					name: "Fee",
					value: props.product?.IsDonation === false ? props.product.Fee : null,
					validator: Yup.number().min(0).max(10000).nullable(),
				},
				{
					name: "MinFee",
					value: props.product?.IsDonation ? props.product.MinFee : null,
					validator: Yup.number().min(0).max(10000).nullable(),
				},
				{
					name: "MaxFee",
					value: props.product?.IsDonation ? props.product.MaxFee : null,
					validator: Yup.number().min(0).max(10000).nullable(),
				},
				{
					name: "MetadataFields",
					value: props.product ? props.product?.MetadataFields || [] : [],
					validator: Yup.array(Yup.string()),
				},
				{
					name: "SuccessText",
					value: props.product ? props.product.SuccessText : null,
					validator: Yup.string().nullable(),
				},
				{
					name: "Sites",
					value:
						props.product?.Sites?.map((site) => ({
							value: site.SiteID,
							label: site.Name,
							SiteID: site.SiteID,
							Name: site.Name,
						})) || [],
					validator: Yup.array(Yup.object()),
				},
			]}
			onSubmit={async (values, { setSubmitting }) => {
				setSubmitting(true);
				let message = "";

				try {
					if (values.mode === "add") {
						message = "added";
						await createProduct({
							variables: {
								organizationId: props.organizationId,
								product: {
									Name: values.Name,
									Description: values.Description,
									IsDonation: values.IsDonation,
									Fee: !values.IsDonation ? values.Fee : null,
									MinFee: values.IsDonation ? values.MinFee : null,
									MaxFee: values.IsDonation ? values.MaxFee : null,
									MetadataFields: values.MetadataFields,
									SuccessText: values.SuccessText,
									SiteIDs: values.Sites.map((site) => site.SiteID),
								},
							},
						});
					} else if (values.mode === "edit") {
						message = "updated";
						await updateProduct({
							variables: {
								organizationId: props.organizationId,
								productId: props.product.ProductID,
								product: {
									Name: values.Name,
									Description: values.Description,
									IsDonation: values.IsDonation,
									Fee: !values.IsDonation ? values.Fee : null,
									MinFee: values.IsDonation ? values.MinFee : null,
									MaxFee: values.IsDonation ? values.MaxFee : null,
									MetadataFields: values.MetadataFields,
									SuccessText: values.SuccessText,
									SiteIDs: values.Sites.map((site) => site.SiteID),
								},
							},
						});
					} else if (values.mode === "delete") {
						message = "deleted";
						await deleteProduct({
							variables: {
								organizationId: props.organizationId,
								productId: props.product.ProductID,
							},
						});
					}

					setSubmitting(false);
					Alert.success(`Product ${message}`);
					props.close(true);
				} catch {
					setSubmitting(false);
					Alert.error("Something went wrong. Please try again.");
				}
			}}
			steps={wizard.steps}
			initialStep={wizard.initialStep}
			wizardProps={props}
		/>
	);
}
