import {
	CreateLevelWizard,
	DeleteBlueprintWizard,
	DeleteLevelWizard,
	UpdateLevelWizard,
	UploadBlueprint,
} from "../../../components/wizards/floor-plan-wizard/FloorPlanWizard";
import {
	Image,
	Layer,
	Line,
	Rect,
	Stage,
	Text,
	Transformer,
} from "react-konva";
import React, { useEffect, useRef, useState } from "react";
import { boxShadows, colours } from "../../../styles";
import Button from "../../../components/layout/Button";
import Dropdown from "../../../components/layout/Dropdown";
import DropdownMenu from "../../../components/layout/DropdownMenu";
import { MoreHorizontal } from "react-feather";
// import { Html } from "react-konva-utils";
import Toggle from "../../../components/layout/Toggle";
import _ from "lodash";
import circleIcon from "../../../images/streamline-icon-interface-geometric-circle.svg";
import doorIcon from "../../../images/streamline-icon-login-3.svg";
import elevatorIcon from "../../../images/streamline-icon-lift-two-people.svg";
import gateIcon from "../../../images/parking-arm.svg";
import leftArrowIcon from "../../../images/streamline-icon-arrow-thick-left.svg";
import parkingBayIcon from "../../../images/streamline-icon-process.svg";
import rampDown from "../../../images/ramp-down.svg";
import rampUp from "../../../images/ramp-up.svg";
import rightArrowIcon from "../../../images/streamline-icon-arrow-thick-right.svg";
import squareIcon from "../../../images/streamline-icon-interface-geometric-square.svg";
import styled from "styled-components";
import subtractIcon from "../../../images/streamline-icon-subtract.svg";
import { useImage } from "../../../hooks";
import { v4 } from "uuid";

const CanvasContainer = styled.div`
	*:focus {
		outline: none;
	}
	display: flex;
	flex-direction: row;
`;

const MapView = styled.div`
	background-color: ${colours.background};
	display: flex;
	align-items: center;
	justify-content: center;
`;

const FlexColumn = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-left: 10;
`;

const FlexRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: baseline;
`;

const HeaderWrapper = styled.div`
	display: flex;
	flex-direction: row;
	margin-top: 16px;
	margin-bottom: 30px;
	gap: 32px;
`;

const BayCard = styled.div`
	background-color: ${colours.midGrey};
	border-radius: 4px;
	box-shadow: ${boxShadows.standard};
	padding: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: max-content;
`;

const IconHeader = styled.div`
	text-align: center;
	margin-bottom: 16px;
	font-weight: bold;
`;

const BayGrid = styled.div`
	display: grid;
	grid-template-rows: 50px 50px 50px;
	grid-template-columns: fit-content(8ch) fit-content(8ch) fit-content(8ch);
	grid-gap: 10px;
`;

const ITEM_WIDTH = 48;
// const SCENE_HEIGHT = window.innerHeight - 200;

const icons = {
	parkingBay: { name: "parkingBay", src: parkingBayIcon },
	door: { name: "door", src: doorIcon },
	gate: { name: "gate", src: gateIcon },
	elevator: { name: "elevator", src: elevatorIcon },
	leftArrow: { name: "leftArrow", src: leftArrowIcon },
	rightArrow: { name: "rightArrow", src: rightArrowIcon },
	rampDown: { name: "rampDown", src: rampDown },
	rampUp: { name: "rampUp", src: rampUp },
	circle: { name: "circle", src: circleIcon },
	square: { name: "square", src: squareIcon },
	subtract: { name: "subtract", src: subtractIcon },
};

const directionalIcons = [icons.leftArrow, icons.rightArrow];
const entryAndExitsIcons = [icons.door, icons.gate, icons.elevator];
const parkingBayIcons = [icons.parkingBay];
const rampIcons = [icons.rampUp, icons.rampDown];
const shapeIcons = [icons.subtract, icons.circle, icons.square];

const STAGE_WIDTH = window.innerWidth - 900;
const STAGE_HEIGHT = window.innerHeight - 300;

const DEFAULT_MAP_STATE = {
	stage: {
		x: 0,
		y: 0,
		width: STAGE_WIDTH,
		height: STAGE_HEIGHT,
		scaleX: 1,
		scaleY: 1,
	},
	elements: [],
};

const haveIntersection = (r1, r2) => {
	return !(
		r2.x > r1.x + r1.width ||
		r2.x + r2.width < r1.x ||
		r2.y > r1.y + r1.height ||
		r2.y + r2.height < r1.y
	);
};

const scaleDimensions = (dimensions, stage) => {
	const result = { ...dimensions };
	const keys = Object.keys(dimensions);

	keys.forEach((key) => {
		switch (key) {
			case "x":
				result.x = Math.round((result.x - stage.x) / stage.scaleX);
				break;
			case "y":
				result.y = Math.round((result.y - stage.y) / stage.scaleY);
				break;
			case "width":
				result.width = Math.round(result.width / stage.scaleX);
				break;
			case "height":
				result.height = Math.round(result.height / stage.scaleY);
				break;
			default:
				break;
		}
	});

	return result;
};

const snapBayName = (layerRef, bayBox, stage) => {
	const result = { ...bayBox };

	const bays = layerRef.current
		.getChildren(({ attrs }) => attrs.name)
		.filter(({ attrs }) => icons[attrs.name] === icons.parkingBay);

	for (const bay of bays) {
		const parkingBay = scaleDimensions(bay.getClientRect(), stage);
		if (haveIntersection(result, parkingBay)) {
			const scale = (parkingBay.width - parkingBay.width * 0.2) / result.width;
			result.scaleX = scale;
			result.scaleY = scale;

			result.x =
				parkingBay.x -
				Math.round((result.width * result.scaleX - parkingBay.width) / 2);
			result.y =
				parkingBay.y -
				Math.round((result.height * result.scaleY - parkingBay.height) / 2) +
				(result.height * result.scaleY) / 2;
			break;
		}
	}

	return result;
};

const GRID = 16;
const scaleToGrid = (item) => {
	const _scale = (x) => Math.round(x / GRID) * GRID;

	if (Array.isArray(item)) {
		const res = [];
		for (let idx = 0; idx < item.length; idx++) {
			res.push(_scale(item[idx]));
		}

		return res;
	}
	return _scale(item);
};

const getGridLines = (stageRef) => {
	let width = STAGE_WIDTH;
	let height = STAGE_HEIGHT;
	let marginX = 0;
	let marginY = 0;
	const color = "#C4DCFF";
	const verticalLines = [];
	const horizontalLines = [];

	if (stageRef.current) {
		const {
			scaleX,
			scaleY,
			x,
			y,
			width: stageWidth,
			height: stageHeight,
		} = stageRef.current.attrs;

		marginX = (x / scaleX) * -1;
		marginY = (y / scaleY) * -1;

		width = stageWidth / scaleX;
		height = stageHeight / scaleX;
	}

	for (let i = 0; i < width / GRID; i++) {
		verticalLines.push(
			<Line
				strokeWidth={2}
				stroke={color}
				points={scaleToGrid([
					i * GRID + marginX,
					marginY,
					i * GRID + marginX,
					height + marginY,
				])}
				key={`v-${i}`}
			/>
		);

		horizontalLines.push(
			<Line
				strokeWidth={2}
				stroke={color}
				points={scaleToGrid([
					marginX,
					i * GRID + marginY,
					width + marginX,
					i * GRID + marginY,
				])}
				key={`h-${i}`}
			/>
		);
	}

	return [verticalLines, horizontalLines];
};

const URLImage = ({
	image,
	draggable = true,
	sceneWidth = 0,
	sceneHeight = 0,
}) => {
	const [img] = useImage(icons[image.name].src, {
		height: GRID * 4,
		width: GRID * 4,
	});
	const imageRef = useRef(null);

	if (image.name === "parkingBay") {
		return (
			<Rect
				onDragEnd={(e) => {
					e.target.to({
						x: scaleToGrid(e.target.x()),
						y: scaleToGrid(e.target.y()),
					});
				}}
				id={image.id}
				name={image.name}
				x={image.x}
				y={image.y}
				width={image.width || GRID * 2}
				height={image.height || GRID * 4}
				scaleX={image.scaleX}
				scaleY={image.scaleY}
				rotation={image.rotation}
				stroke={"#000000"}
				strokeWidth={2}
				draggable
			/>
		);
	}

	return (
		<Image
			image={img}
			x={image.x || Math.round(sceneWidth / 2)}
			y={image.y || Math.round(sceneHeight / 2)}
			ref={imageRef}
			// offsetX={img ? Math.round(img.width / 2) : 0}
			// offsetY={img ? Math.round(img.height / 2) : 0}
			draggable={draggable}
			id={image.id}
			name={image.name}
			rotation={image.rotation}
			scaleX={image.scaleX}
			scaleY={image.scaleY}
			skewX={image.skewX}
			skewY={image.skewY}
			onDragEnd={(e) => {
				e.target.to({
					x: scaleToGrid(e.target.x()),
					y: scaleToGrid(e.target.y()),
				});
			}}
		/>
	);
};

const Blueprint = ({ url, x, y }) => {
	const [img] = useImage(url);
	const imageRef = useRef(null);

	return (
		<Image
			image={img}
			x={x}
			y={y}
			ref={imageRef}
			draggable={false}
			opacity={0.4}
		/>
	);
};

// const Attributes = ({ containerRect, attributes }) => {
// 	if (!containerRect.width) {
// 		return <></>;
// 	}
// 	return (
// 		<Html
// 			divProps={{
// 				style: {
// 					width: `${containerRect.width || 0}px`,
// 					display: "flex",
// 					flexDirection: "row",
// 					alignItems: "center",
// 					justifyContent: "center",
// 					top: `${containerRect.height - 40 || 0}px`,
// 					flexWrap: "wrap",
// 					// padding: "10px",
// 					transformOrigin: "center",
// 					gap: "10px",
// 				},
// 			}}
// 		>
// 			{attributes.map((ele, index) => (
// 				<img
// 					key={index}
// 					src={ele.src}
// 					style={{ objectFit: "cover", width: 36, height: 36 }}
// 					// style={{ flex: 0.1 }}
// 					draggable={false}
// 				/>
// 			))}
// 		</Html>
// 	);
// };

// const Label = ({ text = "label" }) => {
// 	return (
// 		<Text
// 			text={text}
// 			width={Math.round(ITEM_WIDTH / 2)}
// 			height={20}
// 			fontSize={18}
// 		/>
// 	);
// };

// const BayStatus = ({ src }) => {
// 	const [img] = useImage(src);

// 	return (
// 		<Image
// 			image={img}
// 			width={ITEM_WIDTH}
// 			height={ITEM_WIDTH}
// 			// offsetY={-1 * Math.round(ITEM_WIDTH / 2)}
// 		/>
// 	);
// };

// const ParkingBay = ({ bay }) => {
// 	const imageRef = useRef(null);

// 	return (
// 		<Group
// 			draggable
// 			x={bay.x}
// 			y={bay.y}
// 			name={bay.type}
// 			ref={imageRef}
// 			id={bay.id}
// 			width={ITEM_WIDTH}
// 			height={ITEM_WIDTH}
// 		>
// 			<Label text={bay.label} />
// 			<BayStatus src={baySymbol} />
// 			{/* <Attributes
// 				containerRect={containerRect}
// 				bayRef={imageRef}
// 				attributes={bay.attributes}
// 			/> */}
// 		</Group>
// 	);
// };

const BayName = ({ bay, layerRef, stage }) => {
	return (
		<Text
			x={bay.x}
			y={bay.y}
			draggable
			name={bay.name}
			text={bay.text}
			id={bay.id}
			type={bay.type}
			fontSize={16}
			rotation={bay.rotation}
			scaleX={bay.scaleX}
			scaleY={bay.scaleY}
			skewX={bay.skewX}
			skewY={bay.skewY}
			width={bay.width}
			onDragEnd={(e) => {
				const box = {
					x: e.target.x(),
					y: e.target.y(),
					width: bay.width,
					height: bay.height,
				};

				const dims = snapBayName(layerRef, box, stage, bay.name);

				e.target.to({
					x: dims.x,
					y: dims.y,
				});

				if (dims.scaleX && dims.scaleY) {
					e.target.scale({
						x: dims.scaleX,
						y: dims.scaleY,
					});
				}
			}}
		/>
	);
};

const ElementSection = ({ header, onDragStart, draggables, draggable }) => (
	<>
		<IconHeader>{header}</IconHeader>
		<FlexRow style={{ marginBottom: 48 }}>
			{draggables.map((element, index) => (
				<img
					key={index}
					alt="ms"
					src={element.src}
					draggable={draggable}
					onDragStart={(e) => {
						onDragStart({
							src: e.target.src,
							name: element.name,
							id: v4(),
						});
					}}
					width={ITEM_WIDTH}
					height={ITEM_WIDTH}
				/>
			))}
		</FlexRow>
	</>
);

const AvailableElements = (props) => (
	<FlexColumn
		style={{
			justifyContent: "flex-start",
			backgroundColor: props.draggable ? colours.background : colours.lightGrey,
			marginLeft: 30,
			padding: 10,
			paddingTop: 40,
			width: 200,
		}}
	>
		<ElementSection header="Shapes" draggables={shapeIcons} {...props} />
		<ElementSection
			header="Parking Bays"
			draggables={parkingBayIcons}
			{...props}
		/>
		<ElementSection
			header="Entry/Exits"
			draggables={entryAndExitsIcons}
			{...props}
		/>
		<ElementSection
			header="Directionals"
			draggables={directionalIcons}
			{...props}
		/>
		<ElementSection header="Ramps" draggables={rampIcons} {...props} />
	</FlexColumn>
);

const BaysContainer = ({
	onDragStart,
	availableBays,
	spaces,
	selectedSpace,
	onLevelChange,
	draggable = true,
}) => {
	return (
		<div
			style={{
				// display: "flex",
				// alignItems: "center",
				// justifyContent: "center",
				padding: 32,
				marginRight: "16px",
				backgroundColor: draggable ? colours.background : colours.lightGrey,
			}}
		>
			<Dropdown
				options={spaces}
				value={selectedSpace}
				onChange={(value) => {
					onLevelChange(value);
				}}
			/>
			<div style={{ marginTop: 32, marginBottom: 16, fontWeight: "bold" }}>
				Bays
			</div>
			<BayGrid>
				{availableBays
					.filter(
						({ LeaseParkID, AttachedToFloorPlan }) =>
							selectedSpace?.value === LeaseParkID && !AttachedToFloorPlan
					)
					.map(({ BayID, Name, LeaseParkID }) => (
						<BayCard
							key={`${Name}-${LeaseParkID}`}
							draggable={draggable}
							onDragStart={() => {
								onDragStart({
									src: null,
									id: `${BayID}`,
									name: `${BayID}`, // `{'LeaseParkID': ${LeaseParkID}, 'Name': ${Name}}`,
									text: Name,
								});
							}}
						>
							{Name}
						</BayCard>
					))}
			</BayGrid>
		</div>
	);
};

const MapContainer = ({
	bays,
	floorName,
	onBayRemovedFromPanel,
	onDeleteBayFromMap,
	onDeleteLevel,
	onLevelChange,
	onSerializationChange,
	onSave,
	selectedSpace,
	serialization,
	spaces,
	...props
}) => {
	const Konva = window.Konva;
	const dragItem = useRef();
	const stageRef = useRef();
	const layerRef = useRef();
	const trRef = useRef();
	const selectionRef = useRef(null);
	const lastestPointer = useRef(null);
	const [elements, setElements] = useState(serialization?.elements || []);
	const [stage, setStage] = useState(
		serialization?.stage || {
			scaleX: 1,
			scaleY: 1,
			x: 0,
			y: 0,
		}
	);
	const [selectedElements, setSelectedElements] = useState([]);
	const [copiedElements, setCopiedElements] = useState([]);
	const [showGrid, setShowGrid] = useState(false);
	const selection = useRef({
		visible: false,
		x1: 0,
		y1: 0,
		x2: 0,
		y2: 0,
	});

	// TODO: come up with a better solution on ref updates
	useEffect(() => {
		if (serialization?.stage && stage.scaleX !== serialization.stage.scaleX) {
			setStage(serialization.stage);
		}
	}, [serialization]);

	useEffect(() => {
		if (!serialization) return;
		if (serialization.elements) setElements(serialization.elements);
		if (serialization.stage) setStage(serialization.stage);
	}, [floorName]);

	useEffect(() => {
		if (!trRef.current) return;

		trRef.current.nodes(selectedElements);
	}, [selectedElements]);

	const onKeyDown = (e) => {
		if (e.key === "Backspace" || e.key === "Delete") {
			const selectedBays = selectedElements
				.filter(({ attrs }) => !icons[attrs.name])
				.map((b) => b.attrs.name);

			for (let i = 0; i < selectedElements.length; i++) {
				selectedElements[i].destroy();
			}

			setSelectedElements([]);
			onDeleteBayFromMap(selectedBays);
		} else if ((e.metaKey || e.ctrlKey) && e.key === "c") {
			if (!selectedElements) return;

			setCopiedElements(
				selectedElements
					.map(({ attrs }) => attrs)
					.filter(({ name }) => icons[name])
			);
		} else if ((e.metaKey || e.ctrlKey) && e.key === "v") {
			if (!copiedElements) return;

			const topLeft = {
				x: Number.MAX_SAFE_INTEGER,
				y: Number.MAX_SAFE_INTEGER,
			};
			copiedElements.forEach((ele) => {
				if (ele.x <= topLeft.x && ele.y <= topLeft.y) {
					topLeft.x = ele.x;
					topLeft.y = ele.y;
				}
			});

			const diff = {
				x: lastestPointer.current.x - topLeft.x,
				y: lastestPointer.current.y - topLeft.y,
			};

			const toPaste = copiedElements.map((ele) => {
				return { ...ele, x: ele.x + diff.x, y: ele.y + diff.y, id: v4() };
			});

			setElements((_elements) => [..._elements, ...toPaste]);

			const pastedIds = new Set(toPaste.map((p) => p.id));
			_.debounce(() => {
				const selected = layerRef.current
					.getChildren(({ attrs }) => attrs.name)
					.filter(({ attrs }) => pastedIds.has(attrs.id));
				setSelectedElements(selected);
			}, 64)();
		}
	};

	const onDragStart = (current) => {
		dragItem.current = current;
	};

	const updateSelectionRect = () => {
		const node = selectionRef.current;
		node.setAttrs({
			visible: selection.current.visible,
			x: Math.min(selection.current.x1, selection.current.x2),
			y: Math.min(selection.current.y1, selection.current.y2),
			width: Math.abs(selection.current.x1 - selection.current.x2),
			height: Math.abs(selection.current.y1 - selection.current.y2),
			fill: "rgba(0, 161, 255, 0.3)",
		});
		node.getLayer().batchDraw();
	};

	const handleWheel = (e) => {
		e.evt.preventDefault();

		const scaleBy = 1.04;
		const s = e.target.getStage();
		const oldScale = s.scaleX();
		const mousePointTo = {
			x: s.getPointerPosition().x / oldScale - s.x() / oldScale,
			y: s.getPointerPosition().y / oldScale - s.y() / oldScale,
		};

		const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

		if (newScale < 0.1) return;

		setStage((_stage) => ({
			..._stage,
			scaleX: newScale,
			scaleY: newScale,
			x: (s.getPointerPosition().x / newScale - mousePointTo.x) * newScale,
			y: (s.getPointerPosition().y / newScale - mousePointTo.y) * newScale,
		}));
	};

	if (stageRef.current && layerRef.current) {
		_.debounce(
			() =>
				onSerializationChange(stageRef.current, layerRef.current, floorName),
			100
		)();
	}

	const [verticalGridLines, horizontalGridLines] = getGridLines(stageRef);

	return (
		<CanvasContainer>
			<BaysContainer
				spaces={spaces}
				selectedSpace={selectedSpace}
				availableBays={bays}
				onDragStart={onDragStart}
				onLevelChange={onLevelChange}
			/>
			<div style={{ backgroundColor: colours.background }}>
				<MapView
					// style={{
					// 	width: window.innerWidth - 850, // image width
					// 	height: window.innerHeight - 300, // image height.
					// }}
					onDrop={(e) => {
						if (!floorName) return;

						e.preventDefault();
						// register event position
						stageRef.current.setPointersPositions(e);

						const { id, name, src, text } = dragItem.current;
						const pointerPosition = scaleDimensions(
							stageRef.current.getPointerPosition(),
							stage
						);

						const newElement = {
							x: scaleToGrid(pointerPosition.x),
							y: scaleToGrid(pointerPosition.y),
							src,
							id,
							name,
							text,
						};

						if (icons[name]) {
							setElements(elements.concat(newElement));
							return;
						}

						if (elements.some((ele) => ele.id === id)) return;

						const canvas = document.createElement("canvas");
						const context = canvas.getContext("2d");
						context.font = "16px Arial";
						const width = context.measureText(text).width;

						const selectedBox = {
							width,
							height: 20,
						};

						const newBay = snapBayName(
							layerRef,
							{ ...newElement, ...selectedBox },
							stage
						);

						setElements(elements.concat(newBay));
						onBayRemovedFromPanel(name);
					}}
					onDragOver={(e) => e.preventDefault()}
					tabIndex={1}
					onKeyDown={onKeyDown}
				>
					<Stage
						width={STAGE_WIDTH}
						height={STAGE_HEIGHT}
						style={{ backgroundColor: colours.background }}
						ref={stageRef}
						onWheel={handleWheel}
						scaleX={stage.scaleX}
						scaleY={stage.scaleY}
						x={stage.x}
						y={stage.y}
						onMouseDown={(e) => {
							const pos = e.target.getStage().getPointerPosition();
							selection.current = {
								visible: true,
								x1: (pos.x - stage.x) / stage.scaleX,
								y1: (pos.y - stage.y) / stage.scaleY,
								x2: (pos.x - stage.x) / stage.scaleX,
								y2: (pos.y - stage.y) / stage.scaleY,
							};

							updateSelectionRect();
						}}
						onMouseMove={(e) => {
							if (!selection.current.visible || selectedElements.length) {
								return;
							}
							const pos = e.target.getStage().getPointerPosition();
							selection.current.x2 = (pos.x - stage.x) / stage.scaleX;
							selection.current.y2 = (pos.y - stage.y) / stage.scaleY;
							updateSelectionRect();
						}}
						onMouseUp={(e) => {
							lastestPointer.current = scaleDimensions(
								e.target.getStage().getPointerPosition(),
								stage
							);

							const selected = [];
							const selectedBox = selectionRef.current.getClientRect();
							layerRef.current
								.getChildren(({ attrs }) => attrs.name)
								.forEach((element) => {
									const elementBox = element.getClientRect();
									if (Konva.Util.haveIntersection(selectedBox, elementBox)) {
										selected.push(element);
									}
								});

							setSelectedElements(selected);
							selection.current.visible = false;
							updateSelectionRect();
						}}
					>
						<Layer ref={layerRef}>
							{showGrid && (
								<>
									{verticalGridLines}
									{horizontalGridLines}
								</>
							)}
							<Blueprint {...props.blueprint} />
							{elements.map((element, idx) => {
								if (!icons[element.name]) {
									return (
										<BayName
											key={element.id}
											bay={element}
											layerRef={layerRef}
											stage={stage}
										/>
									);
								} else {
									return <URLImage key={idx * -1} image={element} />;
								}
							})}
							<Rect
								ref={selectionRef}
								style={{
									fill: "rgba(0,0,255,0.5)",
									visible: false,
								}}
							/>
							<Transformer
								ref={trRef}
								boundBoxFunc={(oldBox, newBox) => {
									// limit resize
									if (newBox.width < GRID || newBox.height < GRID) {
										return oldBox;
									}

									// newBox.width = scaleToGrid(newBox.width);
									// newBox.height = scaleToGrid(newBox.height);
									return newBox;
								}}
							/>
						</Layer>
					</Stage>
				</MapView>
				<div style={{ marginTop: 32, marginLeft: 16 }}>
					<Toggle
						label={"Show Grid"}
						checked={showGrid}
						onChange={(e) => setShowGrid(e)}
					/>
				</div>
				<Button
					style={{ float: "right", margin: 20 }}
					color={"green"}
					onClick={onSave}
				>
					Save Site Map
				</Button>
				<Button
					style={{ float: "right", margin: 20 }}
					color={"red"}
					onClick={onDeleteLevel}
				>
					Delete Level
				</Button>
			</div>
			<AvailableElements onDragStart={onDragStart} />
		</CanvasContainer>
	);
};

const CreateLevel = ({
	onCreateLevel,
	bays,
	spaces,
	selectedSpace,
	onLevelChange,
}) => (
	<CanvasContainer>
		<BaysContainer
			spaces={spaces}
			selectedSpace={selectedSpace}
			availableBays={bays}
			onLevelChange={onLevelChange}
			onDragStart={() => {
				return;
			}}
			draggable={false}
		/>
		<MapView
			style={{
				width: STAGE_WIDTH,
				height: STAGE_HEIGHT,
			}}
		>
			<FlexColumn style={{ gap: "32px" }}>
				<div style={{ fontWeight: "bold", fontSize: "22px" }}>
					Create a level to start creating your site map
				</div>
				<Button
					color={"blue"}
					onClick={onCreateLevel}
					style={{ alignSelf: "center" }}
				>
					Create A Level
				</Button>
			</FlexColumn>
		</MapView>
		<AvailableElements draggable={false} />
	</CanvasContainer>
);

const Header = ({
	blueprint,
	floors,
	floorLevel,
	onFloorChange,
	onCreateLevel,
	onUpdateLevel,
	onUploadBlueprint,
	onDeleteBlueprint,
}) => {
	return (
		<HeaderWrapper>
			<Button color={"blue"} onClick={onCreateLevel}>
				Create A Level
			</Button>
			<Dropdown
				options={floors}
				value={
					floors.find((e) => e.value === floorLevel.value) || {
						value: null,
						label: null,
					}
				}
				onChange={(value) => onFloorChange(value)}
			/>
			<div>
				{floorLevel?.label && (
					<DropdownMenu
						triggerContent={<MoreHorizontal size={24} />}
						items={[
							<div key="update" onClick={onUpdateLevel}>
								Edit
							</div>,
						]}
					/>
				)}
			</div>
			<div style={{ marginLeft: "auto" }}>
				{blueprint && (
					<Button
						color={"red"}
						onClick={onDeleteBlueprint}
						style={{ marginRight: 24 }}
					>
						Remove Floor Plan
					</Button>
				)}
				<Button
					color={"blue"}
					onClick={onUploadBlueprint}
					disabled={!floors.length}
				>
					Upload Floor Plan
				</Button>
			</div>
		</HeaderWrapper>
	);
};

const Root = ({
	floors,
	exsitingSerials,
	bays,
	spaces,
	onSaveSiteMap,
	...props
}) => {
	const [state, setState] = useState({
		createFloorWizardOpen: false,
		updateFloorWizardOpen: false,
		deleteFloorWizardOpen: false,
		uploadFloorPlanWizardOpen: false,
		deleteBlueprintPlanWizardOpen: false,
		floors,
		bays,
		selectedSpace: spaces[0],
	});
	const serialization = useRef(exsitingSerials);
	const [floorLevel, setFloorLevel] = useState(floors[0]);

	useEffect(() => {
		setState((_state) => ({
			..._state,
			floors,
			bays,
			selectedSpace: _state.selectedSpace || spaces[0],
		}));
	}, [bays, spaces]);

	useEffect(() => {
		setFloorLevel(
			floors.find((f) => f.value === floorLevel?.value) || floors[0]
		);
		setState((_state) => ({
			..._state,
			floors,
		}));
	}, [floors]);

	useEffect(() => {
		serialization.current = exsitingSerials;
	}, [exsitingSerials]);

	const onSerializationChange = (stageRef, layerRef, floorName) => {
		if (!stageRef) return;

		const elements = layerRef.children?.filter(({ attrs }) => attrs.name) || [];
		const newState = {
			stage: {
				...stageRef.attrs,
			},
			elements: elements.map((child) => {
				return { ...child.getClientRect(), ...child.attrs };
			}),
		};

		delete newState.stage.container;
		serialization.current[floorName] = {
			...serialization.current[floorName],
			...newState,
		};
	};

	const onFloorChange = (level) => {
		setFloorLevel(level);
	};

	const onLevelChange = ({ value: spaceId }) => {
		const space = spaces.find((s) => s.value === spaceId);
		setState((_state) => ({
			..._state,
			selectedSpace: space,
		}));
	};

	const removeBayFromPanel = (bayId) => {
		setState((_state) => ({
			..._state,
			bays: _state.bays.map((b) => {
				return b.BayID === +bayId
					? { ...b, AttachedToFloorPlan: true }
					: { ...b };
			}),
		}));
	};

	const deleteBayFromMap = (selectedBays) => {
		if (!selectedBays || !selectedBays.length) return;

		const toDetach = new Set(selectedBays);
		setState((_state) => ({
			..._state,
			bays: _state.bays.map((b) => {
				return toDetach.has(`${b.BayID}`)
					? { ...b, AttachedToFloorPlan: false }
					: { ...b };
			}),
		}));
	};

	const onSave = () => {
		onSaveSiteMap(serialization.current, floors);
	};

	const openDeleteLevelWizard = () => {
		setState((_state) => ({
			..._state,
			deleteFloorWizardOpen: true,
		}));
	};

	const openCreateLevelWizard = () => {
		setState((_state) => ({
			..._state,
			createFloorWizardOpen: true,
		}));
	};

	const openUpdateLevelWizard = () => {
		setState((_state) => ({
			..._state,
			updateFloorWizardOpen: true,
		}));
	};

	const openUploadBlueprintWizard = () => {
		setState((_state) => ({
			..._state,
			uploadFloorPlanWizardOpen: true,
		}));
	};

	const openDeleteBlueprintWizard = () => {
		setState((_state) => ({
			..._state,
			deleteBlueprintPlanWizardOpen: true,
		}));
	};

	if (state.deleteFloorWizardOpen) {
		return (
			<DeleteLevelWizard
				close={() =>
					setState((_state) => ({
						..._state,
						deleteFloorWizardOpen: false,
					}))
				}
				onSubmit={() => {
					props.onDeleteLevel(floorLevel?.value);
				}}
				floorName={floorLevel?.label}
			/>
		);
	}

	if (state.updateFloorWizardOpen) {
		return (
			<UpdateLevelWizard
				close={() =>
					setState((_state) => ({
						..._state,
						updateFloorWizardOpen: false,
					}))
				}
				onSubmit={(name) => {
					props.onUpdateLevel(floorLevel.value, name);
				}}
				floorName={floorLevel?.label}
			/>
		);
	}

	if (state.createFloorWizardOpen) {
		return (
			<CreateLevelWizard
				close={() =>
					setState((_state) => ({
						..._state,
						createFloorWizardOpen: false,
					}))
				}
				onSubmit={(newFloor) => {
					props.onCreateLevel(newFloor.label);
				}}
				floors={state.floors}
			/>
		);
	}

	if (state.uploadFloorPlanWizardOpen) {
		return (
			<UploadBlueprint
				close={() =>
					setState((_state) => ({
						..._state,
						uploadFloorPlanWizardOpen: false,
					}))
				}
				onSubmit={async (selectedFloor, file) => {
					const floorId = selectedFloor.value;
					const floorName = selectedFloor.label;
					await props.onUploadBlueprint(
						file,
						floorId,
						serialization.current[floorName] || DEFAULT_MAP_STATE
					);
				}}
				floors={state.floors}
			/>
		);
	}

	if (state.deleteBlueprintPlanWizardOpen) {
		return (
			<DeleteBlueprintWizard
				close={() =>
					setState((_state) => ({
						..._state,
						deleteBlueprintPlanWizardOpen: false,
					}))
				}
				onSubmit={async () => {
					await props.onDeleteBlueprint(floorLevel.value);
				}}
			/>
		);
	}

	return (
		<div>
			<Header
				blueprint={floorLevel ? props.blueprints[floorLevel.label] : null}
				floors={state.floors}
				floorLevel={floorLevel}
				onFloorChange={onFloorChange}
				onCreateLevel={openCreateLevelWizard}
				onUpdateLevel={openUpdateLevelWizard}
				onUploadBlueprint={openUploadBlueprintWizard}
				onDeleteBlueprint={openDeleteBlueprintWizard}
			/>
			{state.floors.length ? (
				<MapContainer
					blueprint={props.blueprints[floorLevel.label]}
					bays={state.bays}
					floorName={floorLevel.label}
					key={floorLevel.value}
					onBayRemovedFromPanel={removeBayFromPanel}
					onDeleteBayFromMap={deleteBayFromMap}
					onDeleteLevel={openDeleteLevelWizard}
					onLevelChange={onLevelChange}
					onSave={onSave}
					onSerializationChange={onSerializationChange}
					selectedSpace={state.selectedSpace}
					serialization={serialization.current[floorLevel.label]}
					spaces={spaces}
				/>
			) : (
				<CreateLevel
					onCreateLevel={openCreateLevelWizard}
					bays={state.bays}
					spaces={spaces}
					selectedSpace={state.selectedSpace}
					onLevelChange={onLevelChange}
				/>
			)}
		</div>
	);
};

export default Root;
