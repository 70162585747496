import { colours } from "../../styles";

function RadioButton(props) {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "center",
				alignItems: "center",
				gap: "10px",
			}}
		>
			<div
				style={{
					border: `2px solid ${props.on ? colours.green : "black"}`,
					height: props.size,
					width: props.size,
					borderRadius: "50%",
					cursor: "pointer",
					backgroundClip: props.on ? "content-box" : undefined,
					backgroundColor: props.on ? colours.green : undefined,
					padding: props.on ? "4px" : undefined,
				}}
				onMouseUp={() => props.onMouseUp()}
			/>
			<div style={{ userSelect: "none" }}>{props.label}</div>
		</div>
	);
}

export default function RadioGroup(props) {
	return (
		<div style={{ display: "flex", flexDirection: "row", gap: "18px" }}>
			{props.items.map((item, idx) => (
				<RadioButton
					{...item}
					size={props.buttonSize}
					on={props.value === item.value}
					key={idx}
					onMouseUp={() => props.onChange(item)}
				/>
			))}
		</div>
	);
}
