import { MoreHorizontal, Plus } from "react-feather";
import React, { useState } from "react";
import { usePermissions, useSearchFilter } from "../../../hooks";
import AccountWizard from "../../wizards/account-wizard";
import Button from "../../layout/Button";
import DropdownMenu from "../../layout/DropdownMenu";
import ReportWrapper from "../ReportWrapper";
import { map } from "lodash";

const defaultSorting = [
	{
		id: "name",
		desc: true,
	},
];

export default function ValidationAccounts(props) {
	const [state, setState] = useState({
		search: "",
		initialLoad: true,
		wizardOpen: false,
	});

	const filteredAccounts = useSearchFilter(props.accounts, state.search, [
		"name",
		"address",
		"sites",
		"beacons",
	]);

	const openWizard = (mode, account) => {
		setState((_state) => ({
			..._state,
			account,
			wizardOpen: true,
			wizardMode: mode,
		}));
	};

	const isAdmin = usePermissions("IsAdmin");

	const columns = [
		{
			id: "status",
			Header: "Status",
			accessor: (d) => d.status,
			highlightCell: (cellProps) => cellProps.original.status,
			width: 80,
		},
		{
			id: "name",
			Header: "Name",
			accessor: (d) => d.name,
			highlightCell: (cellProps) => cellProps.original.name,
		},
		{
			id: "email",
			Header: "Email",
			accessor: (d) => d.email,
			highlightCell: (cellProps) => cellProps.original.email || "",
		},
		{
			id: "location",
			Header: "Location",
			accessor: (d) => d.Address,
			highlightCell: (cellProps) => cellProps.original.address || "",
		},
		{
			id: "sites",
			Header: "Sites",
			accessor: (d) => d.siteNames,
			highlightCell: (cellProps) => cellProps.original.siteNames,
		},
		{
			id: "beacons",
			Header: "Validation Beacons",
			accessor: (d) => d.beaconNames,
			highlightCell: (cellProps) => cellProps.original.beaconNames,
		},
		{
			id: "tasks",
			Header: "",
			accessor: null,
			Cell: (row) => (
				<DropdownMenu
					triggerContent={<MoreHorizontal size={24} />}
					items={[
						<div
							key="update"
							onClick={() => openWizard("update", row.original)}
						>
							Edit
						</div>,
						<div
							key="delete"
							onClick={() => openWizard("delete", row.original)}
						>
							Remove
						</div>,
					]}
				/>
			),
			resizable: false,
			width: 50,
			sortable: false,
		},
	];

	if (state.wizardOpen) {
		return (
			<AccountWizard
				type="validation"
				mode={state.wizardMode}
				beacons={map(props.beacons, (beacon) => ({
					id: beacon.BeaconID,
					name: beacon.UniqueID,
				}))}
				close={() => {
					setState((_state) => ({
						..._state,
						reloadDataToggle: !state.reloadDataToggle,
						wizardOpen: false,
					}));

					props.refetch();
				}}
				account={state.account}
			/>
		);
	}

	return (
		<ReportWrapper
			{...props}
			title="Validation Accounts"
			data={filteredAccounts}
			columns={columns}
			defaultSorted={defaultSorting}
			updateOptions={({ search, ...options }) =>
				setState((_state) => ({
					..._state,
					initialLoad: false,
					search,
					options,
				}))
			}
			loading={props.isLoading}
			showDateRangePicker={false}
			showSitePicker={false}
			rightActions={
				isAdmin ? (
					<Button key="submit" color="blue" onClick={() => openWizard("add")}>
						<Plus size={20} /> Add New Account
					</Button>
				) : null
			}
		/>
	);
}
