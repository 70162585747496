import { cloneDeep, orderBy } from "lodash";
import Alert from "react-s-alert";
import Button from "../layout/Button";
import HoursBlock from "../hours/HoursBlock";
import React from "react";
import moment from "moment";
import styled from "styled-components";

const Wrapper = styled.div`
	display: flex;
	position: relative;
`;

const Block = styled.div`
	display: flex;
	flex-direction: column;
	margin: 10px 40px;
`;

const And = styled.div`
	text-align: center;

	> p {
		font-size: 14px;
		left: ${(props) => (props.isCustom ? "-130px" : "-60px")};
		margin: 0;
		position: relative;
	}
`;

export default function DrawHoursBlocks(props) {
	const {
		values,
		weekDay,
		operationalIndex,
		setFieldValue,
		applyToAll,
		openHoursWizard,
		isOperationalHours,
		hasInvalidDayBlocks,
		hasInvalidCustomBlocks,
		hasEditPermissions,
	} = props;

	const defaultBlocks = [{ from: { time: "00:00" }, to: { time: "23:59" } }];

	function getBlock(day) {
		if (day.open) {
			if (day.blocks.length) {
				return day.blocks;
			} else if (day.all.length) {
				return day.all;
			}
		}

		return defaultBlocks;
	}

	function getOperationalBlockLayout() {
		return (
			<Wrapper>
				<Block>
					{getBlock(weekDay.default).map((block, key) => (
						<div key={key}>
							{drawBlocks(
								operationalIndex,
								key,
								block,
								hasInvalidDayBlocks.includes(operationalIndex)
							)}
						</div>
					))}
				</Block>
				<Button
					color="blue"
					hidden={operationalIndex !== 0 || !hasEditPermissions}
					style={{ flex: "1 0 auto", marginTop: 18 }}
					onClick={() => applyToAll(values.days, setFieldValue)}
				>
					Apply To All
				</Button>
			</Wrapper>
		);
	}

	function getCustomBlockLayout() {
		let customDays = [];
		let dateArray = [];

		values.days.forEach((day, dayIndex) => {
			if (day.custom.length) {
				day.custom.forEach((customDay, customDayKey) => {
					customDays.push([customDay, customDayKey, dayIndex]);
					dateArray.push(parseInt(customDay.customDate));
				});
			}
		});

		customDays = orderBy(customDays, (block) => {
			const date = moment(block[0].name, "DD MMM YYYY").format("YYYYMMDD");
			return parseInt(date);
		});

		if (customDays.length) {
			return (
				<div>
					{customDays.map((customDay) => (
						<Wrapper key={customDay}>
							<Block>
								{getBlock(customDay[0]).map((block, key) => (
									<div key={key}>
										{drawBlocks(
											customDay[2],
											key,
											block,
											hasInvalidCustomBlocks.includes(customDay[0].customDate),
											customDay[1]
										)}
									</div>
								))}
							</Block>
						</Wrapper>
					))}
				</div>
			);
		} else {
			return <Block>No Custom Date Added.</Block>;
		}
	}

	function drawBlocks(index, key, block, isConflict, customDayKey) {
		let day = !isNaN(customDayKey)
			? values.days[index].custom[customDayKey]
			: values.days[index].default;

		const length = day.blocks.length || day.all.length;
		const isNotFinalDelimiter = day.open && length > 1 && key + 1 < length;

		return (
			<div>
				<HoursBlock
					block={block}
					label={day.name}
					isFirstBlock={key === 0}
					isCustom={!isNaN(customDayKey)}
					isLastBlock={key === length - 1}
					isOpen={!!day.open}
					isConflict={isConflict}
					onUpdate={(value) =>
						updateTimeValue(value, index, key, day.open, customDayKey)
					}
					onToggle={(value) => toggleOnChange(index, value, customDayKey)}
					onRemove={() => removeBlock(index, key, customDayKey)}
					onAdd={() => addBlock(index, key, customDayKey)}
					onDelete={() =>
						openHoursWizard("remove", day.customDate.toString(), customDayKey)
					}
					hasEditPermissions={hasEditPermissions}
				/>
				{isNotFinalDelimiter && (
					<And isCustom={!isNaN(customDayKey)}>
						<p>And</p>
					</And>
				)}
			</div>
		);
	}

	const updateTimeValue = (value, index, key, open, customDayKey) => {
		let days = cloneDeep(values.days);
		let day = !isNaN(customDayKey)
			? days[index].custom[customDayKey]
			: days[index].default;
		let block = day.blocks[key];

		if (open && block) {
			if (value.from) {
				block.from.time = moment(value.from).format("HH:mm");
			}

			if (value.to) {
				block.to.time = moment(value.to).format("HH:mm");
			}
			setFieldValue("days", days);
		}
	};

	const toggleOnChange = (index, dayOpen, customDayKey) => {
		let days = cloneDeep(values.days);
		let day = !isNaN(customDayKey)
			? days[index].custom[customDayKey]
			: days[index].default;

		if (dayOpen) {
			if (day.all.length) {
				day.blocks = day.all;
				day.all = [];
			} else {
				day.blocks = defaultBlocks;
			}
		} else {
			day.all = day.blocks;
			day.blocks = [];
		}

		day.open = dayOpen;

		setFieldValue("days", days);
	};

	const removeBlock = (index, key, customDayKey) => {
		let days = cloneDeep(values.days);
		let blocks = !isNaN(customDayKey)
			? days[index].custom[customDayKey].blocks
			: days[index].default.blocks;
		let deletes = blocks[key].deletes;

		if (!deletes) deletes = [];
		deletes.push(blocks[key]);

		blocks.splice(key, 1);
		setFieldValue("days", days);
	};

	const addBlock = (index, key, customDayKey) => {
		let days = cloneDeep(values.days);
		let blocks = !isNaN(customDayKey)
			? days[index].custom[customDayKey].blocks
			: days[index].default.blocks;
		let firstBlock = blocks[0];
		let lastBlock = blocks[key];

		let blockToAdd = cloneDeep(defaultBlocks[0]);

		if (!isNaN(customDayKey)) {
			blockToAdd.customDate = days[index].custom[customDayKey].customDate;
		}

		if (lastBlock) {
			blockToAdd.from = {
				time: moment(lastBlock.to.time, "HH:mm")
					.add(1, "minutes")
					.format("HH:mm"),
			};
		}

		//push to block array if last block's end time is not the end of the day
		if (lastBlock.to.time !== "23:59") {
			blocks.push(blockToAdd);
		} else if (firstBlock.from.time !== "00:00") {
			//push to block array if the first block is not the start of the day
			blockToAdd.to = {
				time: moment(firstBlock.from.time, "HH:mm")
					.subtract(1, "minutes")
					.format("HH:mm"),
			};
			blocks.push(blockToAdd);
		} else {
			Alert.error("You've reached the limit of hours for the day");
		}

		setFieldValue("days", days);
	};

	return (
		<Wrapper>
			{isOperationalHours
				? getOperationalBlockLayout()
				: getCustomBlockLayout()}
		</Wrapper>
	);
}
