export const INVALID_SHARED_INSTALLATION =
	"Adding a site to an shared installation requires that all sites are private and exist on different organizations. Only one public site may exist on a shared installation";

export const INVALID_SHARED_INSTALLATION_VIA_SITE =
	"A shared installation requires that all sites are private and exist on different organizations. Only one public site may exist on a shared installation.";

export const INVALID_SHARED_INSTALLATION_VIA_GROUP =
	"Users can have access to just one site that is apart of a shared installation";

export const INVALID_SHARED_INSTALLATION_VIA_PLATE =
	"Site plates can have access to just one site that is apart of a shared installation";

export const PLATE_ALREADY_EXISTS_ON_SITE =
	"Numberplate already exists on site";

export const ANPR_PLATE_ALREADY_EXISTS =
	"Numberplate is already being used by another user";
