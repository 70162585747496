import * as Yup from "yup";
import * as installationApi from "../../api/installation";
import { Check, X } from "react-feather";
import React, { useState } from "react";
import { cloneDeep, debounce } from "lodash";
import PropTypes from "prop-types";
import SearchBox from "../layout/SearchBox";
import styled from "styled-components";

const Wrapper = styled.div`
	position: relative;
`;

const Status = styled.div`
	position: absolute;
	right: 8px;
	top: 8px;
`;

PromotionLookup.propTypes = {
	promotionCode: PropTypes.string.isRequired,
	organizationId: PropTypes.number,
	siteId: PropTypes.number,
	mode: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};

PromotionLookup.defaultProps = {
	onChange: () => {},
};

const schema = Yup.object().shape({
	promotionCode: Yup.string().required(
		"Please supply a unique Promotion Code."
	),
});

let globalTimestamp = Date.now();

function PromotionLookup(props) {
	const [state, setState] = useState({
		promotionCode: props.promotionCode.toUpperCase().trim() || "",
		promotionCodeValid: true,
		loading: false,
	});

	async function onValueChange(value) {
		const promotionCode = cloneDeep(value);

		setState((_state) => ({
			..._state,
			promotionCode: promotionCode.toUpperCase().trim(),
		}));

		const isValid = await schema.isValid({
			promotionCode: promotionCode.toUpperCase().trim(),
		});

		if (isValid) {
			lookupPromotionCode(promotionCode.toUpperCase());
		} else {
			setState((_state) => ({ ..._state, promotionCodeValid: false }));

			props.onChange({
				promotionCode: promotionCode.toUpperCase().trim(),
				promotionCodeValid: false,
			});
		}
	}

	const lookupPromotionCode = debounce(async (promotionCode) => {
		// set a timestamp to prevent older lookups being applied
		const timestamp = Date.now();
		globalTimestamp = timestamp;

		setState((_state) => ({ ..._state, loading: true }));

		try {
			let response = {};
			let valid = false;
			let duplicate = false;

			if (props.mode === "promotionCode") {
				response = await installationApi.checkPromotionCode(
					props.organizationId,
					promotionCode.toUpperCase()
				);

				valid = response.valid && !response.inUse;
				duplicate = response.inUse;
			}

			setTimeout(() => {
				if (globalTimestamp === timestamp) {
					setState((_state) => ({
						..._state,
						loading: false,
						promotionCodeValid: valid,
						duplicate: duplicate,
					}));

					props.onChange({
						promotionCode: promotionCode.toUpperCase().trim(),
						promotionCodeValid: valid,
						promotionCodeDuplicate: duplicate,
					});
				}
			}, 1000);
		} catch (error) {
			setTimeout(() => {
				if (globalTimestamp === timestamp) {
					setState((_state) => ({
						..._state,
						loading: false,
						promotionCodeValid: false,
					}));

					props.onChange({
						promotionCode: null,
						promotionCodeValid: false,
					});
				}
			}, 1000);
		}
	}, 500);

	return (
		<Wrapper>
			<SearchBox
				placeholder="Unique Promotion Code"
				onChange={(value) => {
					onValueChange(value.trim());
				}}
				value={state.promotionCode}
				showLoader={state.loading}
				forceUppercase={props.forceUppercase}
			/>
			{!state.loading &&
				state.promotionCode !== "" &&
				(state.promotionCodeValid ? (
					<Status>
						<Check color="green" />
					</Status>
				) : (
					<Status>
						<X color="red" />
					</Status>
				))}
		</Wrapper>
	);
}

export default PromotionLookup;
