import * as Yup from "yup";
import * as steps from "./space-account-steps";
import Alert from "react-s-alert";
import React from "react";
import WizardLayout from "../WizardLayout";
import { cloneDeep } from "lodash";
import gql from "graphql-tag";
import { useMutateData } from "../../../hooks";
import { useTranslation } from "react-i18next";

const constructWizard = ({ mode }) => {
	switch (mode) {
		case "add":
			return {
				initialStep: 0,
				steps: [
					steps.nameStep,
					steps.emailStep,
					steps.spacesStep,
					steps.spaceAllocationStep,
					steps.summaryStep,
				],
			};
		case "update":
			return {
				initialStep: 4,
				steps: [
					steps.nameStep,
					steps.emailStep,
					steps.spacesStep,
					steps.spaceAllocationStep,
					steps.summaryStep,
				],
			};
		case "remove":
			return {
				initialStep: 0,
				steps: [steps.removeStep],
			};
	}
};

export default function SpaceAccountWizard(props) {
	const { t: translate } = useTranslation();

	const wizard = constructWizard(props);

	const createSpaceAccount = useMutateData(gql`
		mutation (
			$organizationId: Int!
			$name: String!
			$accountOwnerUserId: Int!
			$totalSpaces: Int!
			$spaceAllocations: [SpaceAccountLeaseParkInput]
		) {
			createSpaceAccount(
				organizationId: $organizationId
				name: $name
				accountOwnerUserId: $accountOwnerUserId
				totalSpaces: $totalSpaces
				spaceAllocations: $spaceAllocations
			)
		}
	`);

	const updateSpaceAccount = useMutateData(gql`
		mutation (
			$spaceAccountId: Int!
			$name: String!
			$accountOwnerUserId: Int!
			$totalSpaces: Int!
			$spaceAllocations: [SpaceAccountLeaseParkInput]
		) {
			updateSpaceAccount(
				spaceAccountId: $spaceAccountId
				name: $name
				accountOwnerUserId: $accountOwnerUserId
				totalSpaces: $totalSpaces
				spaceAllocations: $spaceAllocations
			)
		}
	`);

	const deleteSpaceAccount = useMutateData(gql`
		mutation ($spaceAccountId: Int!) {
			deleteSpaceAccount(spaceAccountId: $spaceAccountId)
		}
	`);

	const account = cloneDeep(props.spaceAccount);

	return (
		<WizardLayout
			close={props.close}
			title={({ mode }) => {
				if (mode === "add") return "Add Account";
				if (mode === "update") return "Update Account";
				if (mode === "remove") return "Remove Account";
			}}
			values={[
				{ name: "mode", value: props.mode, validator: Yup.string() },
				{
					name: "name",
					value: account && account.Name ? account.Name : "",
					validator: Yup.string()
						.max(
							100,
							"The account name should be no more than 100 characters long."
						)
						.required("Please supply a name for the account."),
				},
				{
					name: "email",
					value: account.AccountOwnerUser ? account.AccountOwnerUser.Email : "",
					validator: Yup.string().email(),
				},
				{
					name: "user",
					value: account.AccountOwnerUser ? account.AccountOwnerUser : null,
					validator: Yup.object(),
				},
				{
					name: "totalSpaces",
					value: account && account.TotalSpaces ? account.TotalSpaces : 1,
					validator: Yup.number()
						.integer("The account needs to have at least 1 space")
						.min(1, "The account needs to have at least 1 space"),
				},
				{
					name: "spaceAllocations",
					value: account.SpaceAllocations ? account.SpaceAllocations : [{}],
					validator: Yup.array(Yup.object()),
				},
			]}
			onSubmit={async (values) => {
				if (values.mode === "add") {
					try {
						await createSpaceAccount({
							variables: {
								organizationId: props.organization.OrganizationID,
								name: values.name,
								accountOwnerUserId: values.user.UserID,
								totalSpaces: values.totalSpaces,
								spaceAllocations: values.spaceAllocations
									.filter((e) => e.LeaseParkID)
									.map((e) => ({
										LeaseParkID: e.LeaseParkID,
										Spaces: e.Spaces,
									})),
							},
						});

						Alert.success("Account created");
						props.close(true);
					} catch (error) {
						Alert.error(translate("Errors.Generic"));
					}
				} else if (values.mode === "update") {
					try {
						await updateSpaceAccount({
							variables: {
								spaceAccountId: account.SpaceAccountID,
								name: values.name,
								accountOwnerUserId: values.user.UserID,
								totalSpaces: values.totalSpaces,
								spaceAllocations: values.spaceAllocations
									.filter((e) => e.LeaseParkID)
									.map((e) => ({
										LeaseParkID: e.LeaseParkID,
										Spaces: e.Spaces,
									})),
							},
						});

						Alert.success("Account updated");
						props.close(true);
					} catch (error) {
						Alert.error(translate("Errors.Generic"));
					}
				} else if (values.mode === "remove") {
					try {
						await deleteSpaceAccount({
							variables: {
								spaceAccountId: account.SpaceAccountID,
							},
						});

						Alert.success("Account removed");
						props.close(true);
					} catch (error) {
						Alert.error(translate("Errors.Generic"));
					}
				}
			}}
			steps={wizard.steps}
			initialStep={wizard.initialStep}
			wizardProps={props}
		/>
	);
}
