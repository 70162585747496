import { deleteJSON, getJSON, getURL, postJSON, putJSON } from "../index";
import qs from "qs";

const basePath = "/billing/api";

export function createRefundForLeasePayment(
	organizationId,
	userLeaseId,
	leasePaymentId,
	data
) {
	return postJSON(
		`${basePath}/v1/admin/organization/${organizationId}/user-lease/${userLeaseId}/lease-payment/${leasePaymentId}/refund`,
		data
	);
}

export function createRefundForUserLease(organizationId, userLeaseId, data) {
	return postJSON(
		`${basePath}/v1/admin/organization/${organizationId}/user-lease/${userLeaseId}/refund`,
		data
	);
}

export function getCustomersByOrganizationId(organizationId, options) {
	const params = qs.stringify(options);

	return getJSON(
		`${basePath}/v2/admin/organization/${organizationId}/customers?${params}`
	);
}

export function getGlobalCustomerList(options) {
	const params = { search: options };
	const query = options ? `?${qs.stringify(params)}` : "";

	return getJSON(`${basePath}/v1/admin/global/customers${query}`);
}

export function getParkingHistoryBySession(siteId, parkingSessionId) {
	return getJSON(
		`${basePath}/admin/site/${siteId}/session/${parkingSessionId}/history`
	);
}

export function getParkingHistoryByLease(siteId, userLeaseId) {
	return getJSON(
		`${basePath}/admin/site/${siteId}/lease/${userLeaseId}/history`
	);
}

export function getRefundRequest(siteId, refundRequestId) {
	return getJSON(`${basePath}/admin/site/${siteId}/refund/${refundRequestId}`);
}

export function getRevenueReportForOrganization(organizationId, options) {
	const params = qs.stringify(options);

	return getJSON(
		`${basePath}/v1/admin/organization/${organizationId}/revenue/report?${params}`
	);
}

export function resendLeasePaymentReceipt(
	organizationId,
	userLeaseId,
	leasePaymentId
) {
	return postJSON(
		`${basePath}/v1/admin/organization/${organizationId}/user-lease/${userLeaseId}/lease-payment/${leasePaymentId}/resend-payment-receipt`
	);
}

export function resendLeasePaymentRefundReceipt(
	organizationId,
	refundRequestId
) {
	return postJSON(
		`${basePath}/admin/organization/${organizationId}/refund/${refundRequestId}/resend-lease-payment-refund-receipt`
	);
}

export function resendParkingSessionReceipt(siteId, parkingSessionId) {
	return postJSON(
		`${basePath}/v1/admin/site/${siteId}/parking-session/${parkingSessionId}/resend-payment-receipt`
	);
}

export function resendParkingSessionRefundReceipt(siteId, refundRequestId) {
	return postJSON(
		`${basePath}/admin/site/${siteId}/refund/${refundRequestId}/resend-parking-session-refund-receipt`
	);
}

export function resendUserLeaseCancellationReceipt(
	organizationId,
	userLeaseId
) {
	return postJSON(
		`${basePath}/v1/admin/organization/${organizationId}/user-lease/${userLeaseId}/resend-payment-receipt`
	);
}

export function resendUserLeaseCancellationRefundReceipt(
	organizationId,
	refundRequestId
) {
	return postJSON(
		`${basePath}/admin/organization/${organizationId}/refund/${refundRequestId}/resend-lease-cancellation-refund-receipt`
	);
}

export function retryLeasePayment(siteId, userLeaseId, leasePaymentId) {
	return postJSON(
		`${basePath}/v1/admin/site/${siteId}/user-lease/${userLeaseId}/lease-payment/${leasePaymentId}/retry-payment`
	);
}

export function retryParkingSessionPayment(siteId, parkingSessionId) {
	return postJSON(
		`${basePath}/v1/admin/site/${siteId}/parking-session/${parkingSessionId}/retry-payment`
	);
}

export function retryUserLeaseCancellationFee(siteId, userLeaseId) {
	return postJSON(
		`${basePath}/v1/admin/site/${siteId}/user-lease/${userLeaseId}/retry-cancellation-fee-payment`
	);
}

export function getBillingAccountById(billingAccountId) {
	return getJSON(`${basePath}/v1/admin/billing-account/${billingAccountId}`);
}

export function testAccountAccess(siteId, billingAccountId) {
	return getJSON(
		`${basePath}/admin/site/${siteId}/billing-account/${billingAccountId}/test-access`
	);
}

export function testAccountAccessV2(organizationId, billingAccountId) {
	return getJSON(
		`${basePath}/admin/organization/${organizationId}/billing-account/${billingAccountId}/test-access`
	);
}

export function removeBillingAccountFromSite(siteId) {
	return putJSON(`${basePath}/admin/site/${siteId}/remove-billing-account`);
}

export function createBillingAccount(data) {
	return postJSON(`${basePath}/v1/admin/billing-account`, {
		Email: data.Email,
		Country: data.Country,
		CompanyName: data.CompanyName,
	});
}

export function getBillingAccountByEmail(email) {
	const params = qs.stringify({ email });
	const query = params ? `?${params}` : "";

	return getJSON(`${basePath}/v1/admin/billing-account${query}`);
}

export function setSiteBillingAccount(siteId, billingAccountId) {
	return putJSON(
		`${basePath}/admin/site/${siteId}/set-billing-account/${billingAccountId}`
	);
}

export function setInvoicingDetailsOnSite(siteId, data) {
	return putJSON(`${basePath}/site/${siteId}/set-invoicing-details`, data);
}

export function clearPXPostAccountDetails(siteId) {
	return deleteJSON(`${basePath}/v1/admin/site/${siteId}/px-post-account`);
}

export function setPXPostAccountDetails(
	siteId,
	pxPostUsername,
	pxPostSecretKey,
	merchantAccountSettlement
) {
	return postJSON(`${basePath}/v1/admin/site/${siteId}/px-post-account`, {
		PXPostUsername: pxPostUsername,
		PXPostSecretKey: pxPostSecretKey,
		MerchantAccountSettlement: merchantAccountSettlement,
	});
}

export function setFeeStructureOnSite(siteId, data) {
	return putJSON(`${basePath}/v1/site/${siteId}/set-fee-structure`, data);
}

//Invoices
export function getInvoicesForSite(siteId) {
	return getJSON(`${basePath}/v1/admin/site/${siteId}/invoices`);
}

export function createInvoicesForSite(siteId, accountId) {
	return postJSON(`${basePath}/v1/admin/site/${siteId}/invoices`, {
		AccountID: accountId,
	});
}

export function getInvoicePdfUrl(siteId, invoiceId, invoiceAccessKey) {
	return getURL(
		`${basePath}/v1/admin/site/${siteId}/invoices/${invoiceId}/pdf?invoice-access-key=${invoiceAccessKey}`
	);
}

export function getTransactionPdfUrl(siteId, invoiceId, invoiceAccessKey) {
	return getURL(
		`${basePath}/v1/admin/site/${siteId}/invoices/transaction-details/${invoiceId}/pdf?invoice-access-key=${invoiceAccessKey}`
	);
}

export function sendInvoice(siteId, invoiceId) {
	return postJSON(
		`${basePath}/v1/admin/site/${siteId}/invoices/${invoiceId}/send`
	);
}

export function deleteInvoice(siteId, invoiceId) {
	return deleteJSON(
		`${basePath}/v1/admin/site/${siteId}/invoices/${invoiceId}/delete`
	);
}

/*
Void failed payments
*/

export function voidParkingSessionPayment(siteId, parkingSessionId) {
	return postJSON(
		`${basePath}/v1/admin/site/${siteId}/parking-session/${parkingSessionId}/mark-void`
	);
}

export function voidLeasePayment(siteId, userLeaseId, leasePaymentId) {
	return postJSON(
		`${basePath}/v1/admin/site/${siteId}/user-lease/${userLeaseId}/lease-payment/${leasePaymentId}/mark-void`
	);
}

export function voidUserLeaseCancellationFee(siteId, userLeaseId) {
	return postJSON(
		`${basePath}/v1/admin/site/${siteId}/user-lease/${userLeaseId}/mark-void`
	);
}

export function getBillingDetailsForOrganization(organizationId) {
	return getJSON(
		`${basePath}/v1/admin/organization/${organizationId}/billing-details/all`
	);
}

export function getInsensitiveBillingDetailsForOrganization(organizationId) {
	return getJSON(
		`${basePath}/v1/admin/organization/${organizationId}/billing-details/insensitive`
	);
}

export function updateInsensitiveBillingDetails(
	organizationId,
	billingDetailsId,
	data
) {
	return putJSON(
		`${basePath}/v1/admin/organization/${organizationId}/billing-details/${billingDetailsId}/insensitive`,
		data
	);
}

export function createBillingDetails(organizationId, data) {
	return postJSON(
		`${basePath}/v1/admin/organization/${organizationId}/billing-details/`,
		data
	);
}

export function updateBillingDetails(organizationId, billingDetailsId, data) {
	return putJSON(
		`${basePath}/v1/admin/organization/${organizationId}/billing-details/${billingDetailsId}`,
		data
	);
}

export function deleteBillingDetails(organizationId, billingDetailsId) {
	return deleteJSON(
		`${basePath}/v1/admin/organization/${organizationId}/billing-details/${billingDetailsId}`
	);
}

export function getSitesAndBillingDetailsMapping(organizationId) {
	return getJSON(
		`${basePath}/v1/admin/organization/${organizationId}/billing-details/mapping`
	);
}
