import React from "react";
import Dropdown from "../../layout/Dropdown";
import { StepText } from "../WizardLayout";
import { cloneDeep } from "lodash";

export function ChooseSpaces(props) {
	const leaseParkIds = props.values.leaseParkIds;
	const leaseParks = props.wizardProps.availableSpaces;

	const handleValueChange = (value, key) => {
		let _leaseParkIds = cloneDeep(leaseParkIds);
		_leaseParkIds = {
			..._leaseParkIds,
			[key]: value,
		};

		return _leaseParkIds;
	};

	return (
		<div>
			{props.values.validationSites.map((site) => {
				const options = leaseParks
					.filter((lp) => lp.SiteID === site.id)
					.flatMap((lp) =>
						lp.Spaces.map((s) => ({
							value: s.LeaseParkID,
							label: s.Name,
						}))
					);
				return (
					<div key={site.id} style={{ marginTop: "15px" }}>
						<StepText>{site.name}</StepText>
						{options.length ? (
							<div style={{ marginTop: "5px" }}>
								<Dropdown
									options={options}
									value={options.find((option) => {
										return option.value === leaseParkIds[site.id] || null;
									})}
									isDisabled={options.length === 0}
									onChange={(value) => {
										props.handleChange({
											target: {
												name: "leaseParkIds",
												value: handleValueChange(value?.value || null, site.id),
											},
										});
									}}
									isClearable
								/>
							</div>
						) : (
							<p>
								No available spaces for this site. A space can be assigned to
								this site in the Spaces page.
							</p>
						)}
					</div>
				);
			})}
		</div>
	);
}
