import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import RolesContainer from "./roles";
import GroupsContainer from "./groups";

export default function PermissionsContainer(props) {
	return (
		<Switch>
			<Route path={`${props.match.url}/roles`}>
				<RolesContainer {...props} />
			</Route>
			<Route path={`${props.match.url}/groups`}>
				<GroupsContainer {...props} />
			</Route>
			<Redirect to={`${props.match.url}/roles`} />
		</Switch>
	);
}
