import DropdownMenu from "../layout/DropdownMenu";
import { MoreHorizontal } from "react-feather";
import React from "react";

function getItems(item, { canRefund, canResendReceipt }, isRevenue) {
	let array = [];

	if (isRevenue) {
		array.push("Transaction Details");
	}

	if (
		canResendReceipt &&
		item.TransactionType !== "Refund" &&
		item.IsPaid &&
		item.WasBillable &&
		!item.WasVoided
	) {
		array.push("Resend Receipt");
	}

	if (
		canRefund &&
		item.TransactionType !== "Refund" &&
		item.IsPaid &&
		item.WasBillable &&
		item.TotalAmount !== item.RefundedAmount
	) {
		array.push("Refund");
	}

	if (
		canResendReceipt &&
		item.TransactionType !== "Refund" &&
		!item.IsPaid &&
		item.WasBillable &&
		!item.WasVoided
	) {
		array.push("Retry Payment");
	}

	if (item.TransactionType === "Refund") {
		array.push("View Refund");
	}

	if (canResendReceipt && item.TransactionType === "Refund") {
		array.push("Resend Refund Receipt");
	}

	if (canRefund && !item.IsPaid && item.WasBillable && !item.WasVoided) {
		array.push("Void");
	}

	return array;
}

export default function ItemDropDownOptions(props) {
	const cellProps = props.cellProps;
	const items = getItems(
		cellProps.original,
		props.permissions,
		props.isRevenue
	);

	const isMeterParkingSession =
		cellProps.original.ItemType === "MeterParkingSession";

	return items.length ? (
		<DropdownMenu
			triggerContent={<MoreHorizontal size={24} />}
			items={[
				props.isRevenue ? (
					<div
						key="charges"
						onClick={() => {
							const typeMappings = {
								ParkingSession: {
									types: [
										"ParkingSession",
										"ParkingSessionRefund",
										"MooringSession",
										"MooringSessionRefund",
									],
									id: "ItemID",
								},
								UserLease: {
									types: [
										"UserLease",
										"UserLeaseCancellation",
										"LeasePayment",
										"LeasePaymentRefund",
									],
									id: "UserLeaseID",
								},
								ProductPurchase: {
									types: ["ProductPurchase"],
									id: "ItemID",
								},
								ExternalTransaction: {
									types: ["MeterParkingSession"],
									id: "ItemID",
								},
							};

							const getIdFromType = (type, itemType) => {
								const mapping = typeMappings[type];
								if (!mapping || !mapping.types.includes(itemType)) return;
								return cellProps.original[mapping.id];
							};

							const name = cellProps.original.FirstName
								? `${cellProps.original.FirstName} ${cellProps.original.LastName}`
								: "(Unknown)";

							props.showTransactionDetails(
								cellProps.original.SiteID,
								getIdFromType("ParkingSession", cellProps.original.ItemType),
								getIdFromType("UserLease", cellProps.original.ItemType),
								getIdFromType("ProductPurchase", cellProps.original.ItemType),
								getIdFromType(
									"ExternalTransaction",
									cellProps.original.ItemType
								),
								{
									Name: name,
									Email: cellProps.original.Email,
								},
								cellProps.original
							);
						}}
					>
						Transaction Details
					</div>
				) : null,
				items.includes("Resend Receipt") && !isMeterParkingSession ? (
					<div
						key="resend"
						onClick={() =>
							props.showTransactionWizard("resend-receipt", cellProps.original)
						}
					>
						Resend Receipt
					</div>
				) : null,
				isMeterParkingSession && cellProps.original.UserID ? (
					<div
						key="update-user"
						onClick={() =>
							props.showTransactionWizard("update-user", cellProps.original)
						}
					>
						Update User
					</div>
				) : null,
				isMeterParkingSession && cellProps.original.Email ? (
					<div
						key="send"
						onClick={() =>
							props.showTransactionWizard("send-receipt", cellProps.original)
						}
					>
						Send Receipt
					</div>
				) : null,
				items.includes("Refund") && !isMeterParkingSession ? (
					<div
						key="refund"
						onClick={() =>
							props.showTransactionWizard("refund-payment", cellProps.original)
						}
					>
						Refund
					</div>
				) : null,
				items.includes("Retry Payment") ? (
					<div
						key="retry"
						onClick={() =>
							props.showTransactionWizard("retry-payment", cellProps.original)
						}
					>
						Retry Payment
					</div>
				) : null,
				cellProps.original.TransactionType === "Refund" ? (
					<div
						key="refund-details"
						onClick={() =>
							props.showRefundDetails(
								cellProps.original.SiteID,
								cellProps.original.RefundRequestID
							)
						}
					>
						View Refund
					</div>
				) : null,
				items.includes("Resend Refund Receipt") ? (
					<div
						key="resend-refund"
						onClick={() =>
							props.showTransactionWizard("resend-receipt", cellProps.original)
						}
					>
						Resend Refund Receipt
					</div>
				) : null,
				items.includes("Void") ? (
					<div
						key="void"
						onClick={() =>
							props.showTransactionWizard("void-payment", cellProps.original)
						}
					>
						Mark Payment as Void
					</div>
				) : null,
			]}
		/>
	) : null;
}
