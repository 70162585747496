import { MoreHorizontal, UserPlus } from "react-feather";
import React, { useContext, useState } from "react";
import {
	useHasClientAdmin,
	usePermissions,
	useQueryData,
	useSearchFilter,
} from "../../hooks";
import { useHistory, useRouteMatch } from "react-router-dom";
import { AppContext } from "../../context/app-context";
import Button from "../../components/layout/Button";
import ContextMenu from "../../components/menu/ContextMenu";
import ContextMenuLink from "../../components/menu/ContextMenuLink";
import DropdownMenu from "../../components/layout/DropdownMenu";
import ReportWrapper from "../../components/report/ReportWrapper";
import UserWizard from "../../components/wizards/user-wizard/UserWizard";
import { booleanValueFormatter } from "../../components/layout/TableLayout";
import gql from "graphql-tag";
import { useTranslation } from "react-i18next";

const adminUsersColumns = (props) => [
	{
		id: "FirstName",
		Header: "First Name",
		accessor: (d) => d.FirstName,
		highlightCell: (cellProps) => cellProps.original.FirstName,
		className: "bold",
	},
	{
		id: "LastName",
		Header: "Last Name",
		accessor: (d) => d.LastName,
		highlightCell: (cellProps) => cellProps.original.LastName,
		className: "bold",
	},
	{
		id: "Email",
		Header: "Email",
		accessor: (d) => d.Email,
		highlightCell: (cellProps) => cellProps.original.Email,
	},
	{
		id: "IsAdmin",
		Header: "Super Admin",
		accessor: (d) => d.IsAdmin && !d.IsCSAdmin,
		Cell: booleanValueFormatter,
		width: 150,
		filterable: false,
	},
	{
		id: "IsCSAdmin",
		Header: "Admin",
		accessor: (d) => d.IsAdmin && d.IsCSAdmin,
		Cell: booleanValueFormatter,
		width: 150,
		filterable: false,
	},
	{
		id: "AdminDashboardEnabled",
		Header: "Admin Dashboard",
		accessor: (d) => d.AdminDashboardEnabled,
		Cell: booleanValueFormatter,
		width: 150,
		filterable: false,
	},
	// {
	// 	id: "FeaturesPreview",
	// 	Header: "Features Preview",
	// 	accessor: (d) => d.FeaturesPreview,
	// 	Cell: booleanValueFormatter,
	// 	width: 150,
	// 	filterable: false,
	// },
	{
		id: "DebugEnabled",
		Header: "Debug Enabled",
		accessor: (d) => d.DebugEnabled,
		Cell: booleanValueFormatter,
		width: 150,
		filterable: false,
	},
	{
		id: "tasks",
		Header: "",
		accessor: null,
		Cell: (cellProps) =>
			!props.isCSAdmin ? (
				<DropdownMenu
					triggerContent={<MoreHorizontal size={24} />}
					items={[
						<div
							key="update"
							onClick={() =>
								props.openUserWizard(cellProps.original, "admin-update")
							}
						>
							Edit
						</div>,
						<div
							key="remove"
							onClick={() =>
								props.openUserWizard(cellProps.original, "admin-remove")
							}
						>
							Remove
						</div>,
					]}
				/>
			) : null,
		resizable: false,
		width: 50,
		sortable: false,
	},
];

const clientAdminUsersColumns = (props) => [
	{
		id: "ClientName",
		Header: "Client",
		accessor: (d) => d.ClientName,
		highlightCell: (cellProps) => cellProps.original.ClientName,
		className: "bold",
	},
	{
		id: "FirstName",
		Header: "First Name",
		accessor: (d) => d.FirstName,
		highlightCell: (cellProps) => cellProps.original.FirstName,
		className: "bold",
	},
	{
		id: "LastName",
		Header: "Last Name",
		accessor: (d) => d.LastName,
		highlightCell: (cellProps) => cellProps.original.LastName,
		className: "bold",
	},
	{
		id: "Email",
		Header: "Email",
		accessor: (d) => d.Email,
		highlightCell: (cellProps) => cellProps.original.Email,
	},
	{
		id: "ClientAdmin",
		Header: "Admin",
		accessor: () => true,
		Cell: booleanValueFormatter,
		width: 150,
		filterable: false,
	},
	{
		id: "tasks",
		Header: "",
		accessor: null,
		Cell: (cellProps) =>
			!props.isCSAdmin ? (
				<DropdownMenu
					triggerContent={<MoreHorizontal size={24} />}
					items={[
						<div
							key="remove"
							onClick={() =>
								props.openUserWizard(cellProps.original, "client-admin-remove")
							}
						>
							Remove
						</div>,
					]}
				/>
			) : null,
		resizable: false,
		width: 50,
		sortable: false,
	},
];

const usersDefaultSorting = [
	{
		id: "Email",
		desc: false,
	},
];

export default function AdminPermissionsContainer(props) {
	const { t: translate } = useTranslation();

	const history = useHistory();

	const [state, setState] = useState({
		search: "",
		userWizardOpen: false,
		userWizardUser: null,
		userWizardMode: "add",
	});

	const {
		state: { currentUser },
	} = useContext(AppContext);

	function openUserWizard(user, mode) {
		setState((_state) => ({
			..._state,
			userWizardOpen: true,
			userWizardUser: user,
			userWizardMode: mode,
		}));
	}

	async function updateOptions({ search }) {
		setState((_state) => ({ ..._state, search }));
	}

	const isAdmin = usePermissions("IsAdmin");
	const isCSAdmin = usePermissions("IsCSAdmin");
	const hasAnyClientAdmin = useHasClientAdmin(0);

	const clientId =
		currentUser && currentUser.ClientAdmin && currentUser.ClientAdmin.ClientID
			? currentUser.ClientAdmin.ClientID
			: null;

	const {
		data: { getAdminUsers },
		isLoading: isLoadingAdminUsers,
		refetch: refetchAdminUsers,
	} = useQueryData(
		gql`
			query {
				getAdminUsers {
					UserID
					Email
					FirstName
					LastName
					Phone
					CountryCode
					LastUsedAppVersion
					IsAdmin
					IsCSAdmin
					AdminDashboardEnabled
					FeaturesPreview
					DebugEnabled
				}
			}
		`,
		{},
		!isAdmin
	);

	const {
		data: { getClientAdmins },
		isLoading: isLoadingClientAdminUsers,
		refetch: refetchClientAdminUsers,
	} = useQueryData(
		gql`
			query ($clientId: Int) {
				getClientAdmins(clientId: $clientId) {
					ClientAdminUserID
					ClientID
					ClientName
					UserID
					Email
					FirstName
					LastName
					Phone
					CountryCode
					LastUsedAppVersion
				}
			}
		`,
		{ clientId: !isAdmin ? clientId : null }
	);

	const adminUsers = getAdminUsers || [];
	const clientAdminUsers = getClientAdmins || [];

	const filteredAdminUsers = useSearchFilter(adminUsers, state.search, [
		"Email",
		"FirstName",
		"LastName",
	]);

	const filteredClientAdminUsers = useSearchFilter(
		clientAdminUsers,
		state.search,
		["Email", "FirstName", "LastName", "ClientName"]
	);

	const internalPermissions = useRouteMatch({
		path: "/admin-permissions/internal",
		strict: true,
		sensitive: true,
	});

	const clientPermissions = useRouteMatch({
		path: "/admin-permissions/clients",
		strict: true,
		sensitive: true,
	});

	if (hasAnyClientAdmin && !isAdmin && internalPermissions) {
		// redirect to clients page if user only has client admin permissions
		history.replace("/admin-permissions/clients");
		return null;
	}

	if (!internalPermissions && !clientPermissions) {
		// redirect to default page
		history.replace("/admin-permissions/internal");
		return null;
	}

	if (state.userWizardOpen) {
		return (
			<UserWizard
				close={() => {
					setState((_state) => ({ ..._state, userWizardOpen: false }));
					if (isAdmin) refetchAdminUsers();
					refetchClientAdminUsers();
				}}
				mode={state.userWizardMode}
				user={state.userWizardUser}
				users={adminUsers}
				currentUserIsAdmin={isAdmin}
				currentUserClientId={clientId}
			/>
		);
	}

	return (
		<div>
			{isAdmin && (
				<ContextMenu menuCollapsed={props.menuCollapsed}>
					<ContextMenuLink
						key="internal"
						path={`/admin-permissions/internal`}
						name={translate("ContextMenu.AdminPermissions.Internal")}
						location={props.location}
					/>
					<ContextMenuLink
						key="clients"
						path={`/admin-permissions/clients`}
						name={translate("ContextMenu.AdminPermissions.Clients")}
						location={props.location}
					/>
				</ContextMenu>
			)}
			{internalPermissions && (
				<ReportWrapper
					{...props}
					title={"Internal Admin Permissions"}
					data={filteredAdminUsers}
					columns={adminUsersColumns({ openUserWizard, isCSAdmin })}
					defaultSorted={usersDefaultSorting}
					updateOptions={updateOptions}
					loading={isLoadingAdminUsers}
					showDateRangePicker={false}
					showSitePicker={false}
					rightActions={
						isAdmin && !isCSAdmin ? (
							<Button
								color="blue"
								onClick={() => openUserWizard(null, "admin-add")}
							>
								<UserPlus size={20} /> Add Admin User
							</Button>
						) : null
					}
				/>
			)}
			{clientPermissions && (
				<ReportWrapper
					{...props}
					title={"Client Admin Permissions"}
					data={filteredClientAdminUsers}
					columns={clientAdminUsersColumns({ openUserWizard, isCSAdmin })}
					defaultSorted={usersDefaultSorting}
					updateOptions={updateOptions}
					loading={isLoadingClientAdminUsers}
					showDateRangePicker={false}
					showSitePicker={false}
					rightActions={
						<Button
							color="blue"
							onClick={() => openUserWizard(null, "client-admin-add")}
						>
							<UserPlus size={20} /> Add Admin User
						</Button>
					}
				/>
			)}
		</div>
	);
}
