import gql from "graphql-tag";
import { useQueryData } from "../../../../hooks";

export const useGetNodesAndSpaceAccounts = (organizationId) => {
	const { data, isLoading, isError } = useQueryData(
		gql`
			query ($organizationId: Int!) {
				getNodesForOrganization(organizationId: $organizationId) {
					NodeID
					Name
					SerialNumber
					RestrictedAccess
					Sites {
						SiteID
						Name
						OrganizationID
					}
				}
				getSpaceAccounts(organizationId: $organizationId) {
					SpaceAccountID
					OrganizationID
					Name
				}
			}
		`,
		{
			organizationId,
		}
	);

	const { getNodesForOrganization: nodes, getSpaceAccounts: spaceAccounts } =
		data || {};

	return { nodes, spaceAccounts, isLoading, isError };
};
