import React from "react";
import { colours } from "../../styles";
import styled from "styled-components";

const Item = styled.div`
	color: ${colours.white};
	font-size: ${(props) => (props.isCreate ? "12px" : "16px")};
	height: 36px;
	line-height: 1.3em;
	position: relative;
	padding: 8px 24px;
	transition: 0.2s ease;
	white-space: nowrap;
	width: 100%;

	.content {
		transition: 0.2s ease;

		&:hover {
			cursor: pointer;
			opacity: 0.8;
		}
	}
`;

const Name = styled.div`
	color: ${(props) => (props.selected ? colours.green : colours.white)};
	font-weight: ${(props) => (props.selected ? "700" : "400")};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export default function MenuSubItem(props) {
	return (
		<Item isCreate={props.isCreate}>
			<div className="content" onClick={props.onClick}>
				<Name selected={props.selected} isCreate={props.isCreate}>
					{props.itemName}
				</Name>
			</div>
		</Item>
	);
}
