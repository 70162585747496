import WizardLayout, { StepText } from "../WizardLayout";
import Alert from "react-s-alert";
import Button from "../../layout/Button";
import React from "react";
import WizardNavigation from "../WizardNavigation";
import { useMutateData } from "../../../hooks";
import gql from "graphql-tag";
import RadioGroup from "../../layout/RadioGroup";
import Dropdown from "../../layout/Dropdown";

const updateStep = ({ close, handleSubmit, values, setFieldValue }) => ({
	id: "camera",
	label: "Camera",
	render: () => {
		return (
			<div>
				<StepText>
					Please choose the vehicle direction for the access type
				</StepText>
				<br />
				<div>
					The front of a car will trigger Approach, the rear of a car will
					trigger Away.
				</div>

				<div
					style={{
						marginTop: "12px",
						display: "grid",
						gridTemplateColumns: "5rem 10rem",
						gridTemplateRows: "4rem auto",
						alignItems: "center",
					}}
				>
					<div style={{ fontWeight: 600, fontSize: "18px" }}>Entry</div>
					<RadioGroup
						items={[
							{ label: "Approach", value: "Approach" },
							{ label: "Away", value: "Away" },
						]}
						value={values.entryDirection}
						onChange={(item) =>
							setFieldValue(
								"entryDirection",
								values.entryDirection === item.value ? null : item.value
							)
						}
						buttonSize="36px"
					/>

					<div style={{ fontWeight: 600, fontSize: "18px" }}>Exit</div>
					<RadioGroup
						items={[
							{ label: "Approach", value: "Approach" },
							{ label: "Away", value: "Away" },
						]}
						value={values.exitDirection}
						onChange={(item) =>
							setFieldValue(
								"exitDirection",
								values.exitDirection === item.value ? null : item.value
							)
						}
						buttonSize="36px"
					/>
				</div>

				<div style={{ marginTop: "3rem", marginBottom: "1rem" }}>
					Does this camera need to handle events from a neighbouring site?
				</div>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						gap: "1rem",
					}}
				>
					<div style={{ fontWeight: 600, fontSize: "18px" }}>
						Neighbouring Site
					</div>
					<Dropdown
						options={values.availableSites}
						value={values.selectedSite}
						onChange={(option) => setFieldValue("selectedSite", option)}
					/>
				</div>

				<WizardNavigation
					leftItems={[
						<Button key="previous" onClick={close} color="blue">
							Cancel
						</Button>,
					]}
					rightItems={[
						<Button
							key="submit"
							color="green"
							onClick={() => {
								handleSubmit();
							}}
						>
							Update
						</Button>,
					]}
				/>
			</div>
		);
	},
});

export default function UpdateCameraWizard(props) {
	const updateCamera = useMutateData(gql`
		mutation ($cameraId: Int!, $cameraInput: CameraInput!) {
			updateCamera(cameraId: $cameraId, cameraInput: $cameraInput)
		}
	`);

	const siteOptions = [{ label: "None", value: null }].concat(
		props.sites
			.filter((site) => site.SiteID !== props.selectedSite.SiteID)
			.map((site) => ({
				value: site.SiteID,
				label: site.Name,
			}))
	);
	return (
		<WizardLayout
			close={props.close}
			title={"Add Cameras"}
			values={[
				{
					name: "entryDirection",
					value: props.camera.EntryDirection,
				},
				{
					name: "exitDirection",
					value: props.camera.ExitDirection,
				},
				{
					name: "entrySiteNeighbour",
					value: props.camera.EntrySiteNeighbour,
				},
				{
					name: "exitSiteNeighbour",
					value: props.camera.ExitSiteNeighbour,
				},
				{
					name: "availableSites",
					value: siteOptions,
				},
				{
					name: "selectedSite",
					value: siteOptions.find(
						(option) => option.value === props.camera.EntrySiteNeighbour
					),
				},
			]}
			onSubmit={async (values, { setSubmitting }) => {
				setSubmitting(true);

				try {
					await updateCamera({
						variables: {
							cameraId: props.camera.CameraID,
							cameraInput: {
								EntryDirection: values.entryDirection,
								ExitDirection: values.exitDirection,
								EntrySiteNeighbour: values.selectedSite.value,
								ExitSiteNeighbour: values.selectedSite.value,
							},
						},
					});

					Alert.success("Camera Updated");
					setTimeout(() => {
						props.refetch();
						props.close();
					}, 300);
				} catch (error) {
					Alert.error("Something went wrong");
				}
			}}
			steps={[updateStep]}
			initialStep={0}
			wizardProps={props}
		/>
	);
}
