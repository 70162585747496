import gql from "graphql-tag";
import { useQueryData } from "../../../hooks";
export const useGetAvailableSitesForOrg = (organizationId) => {
	const {
		data: { getAvailableSitesForOrganization: sites },
		isLoading,
	} = useQueryData(
		gql`
			query ($organizationId: Int!) {
				getAvailableSitesForOrganization(organizationId: $organizationId) {
					SiteID
					Name
				}
			}
		`,
		{
			organizationId,
		},
		!organizationId
	);

	return { sites, isLoading };
};
