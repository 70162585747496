const integrationMenu = {
	Integrations: "Integrations",
	ApiKeys: "API Keys",
	Requests: "Requests",
	Variables: "Variables",
};
export default {
	Menu: {
		Dashboard: "Overview",
		Revenue: "Revenue",
		Users: "Users",
		Activity: "Activity",
		Transient: "Transient",
		Bookings: "Bookings",
		Validation: "Validation",
		Rates: "Rates",
		SpaceManagement: "Space Management",
		Permissions: "Permissions",
		History: "History",
		Billing: "Billing",
		Brands: "Branding",
		Settings: "Client Settings",
		SiteSettings: "Site Settings",
		Help: "Help",
		Administration: "Administration",
		AdminDashboard: "Admin Dashboard",
		AdminIntegrations: "Admin Integrations",
		AdminPermissions: "Admin Permissions",
		AdminActivity: "Admin Activity",
		AdminAuditHistory: "Admin Audit History",
		GlobalUsers: "Global Users",
		Hardware: "Admin Hardware",
		UnaffiliatedSites: "Unaffiliated Sites",
		LogOut: "Log out",
		Integration: "Integration",
		Signage: "Signage",
		Integrations: "Integrations",
		Enforcement: "Enforcement",
		Occupancy: "Occupancy",
		EventManagement: "Event Management",
		Search: "Search",
	},
	ContextMenu: {
		AdminHardware: {
			Installations: "Installations",
			GateControllers: "Gate Controllers",
			Beacons: "Beacons",
			Cameras: "Cameras",
			Kiosks: "Kiosks",
			ControlBoxes: "Control Boxes",
		},
		AdminIntegrations: { ...integrationMenu, Templates: "Templates" },
		AdminPermissions: {
			Internal: "Internal",
			Clients: "Clients",
		},
		Operator: {
			Integration: integrationMenu,
		},
		Organization: {
			Permissions: {
				Roles: "Roles",
				Groups: "Groups",
			},
			Rates: {
				TransientRates: "Transient Rates",
				BookingRates: "Booking Rates",
				Products: "Products",
			},
			SpaceManagement: {
				Spaces: "Spaces",
				Bays: "Bays",
				Accounts: "Accounts",
			},
			EventManagement: {
				Events: "Events",
				EventPasses: "Event Passes",
			},
			Transient: {
				Sessions: "Sessions",
				Permitted: "Permitted",
				Unpermitted: "Unpermitted",
			},
			Validation: {
				History: "History",
				Beacons: "Beacons",
				QRCodes: "QR Codes",
				Accounts: "Accounts",
				Promotions: "Promotions",
			},
			Signage: {
				Generate: "Generate",
				UniqueQRCodes: "Unique QR Codes",
				History: "History",
			},
			Integration: integrationMenu,
		},
		Site: {
			Settings: {
				General: "General",
				Hours: "Hours",
				Instructions: "Instructions",
				Installation: "Installation",
				Geofencing: "Geofencing",
				Map: "Map",
				Invoices: "Invoices",
				Plates: "Plates",
			},
		},
	},
	Errors: {
		Generic: "Something went wrong. Please try again.",
	},
};
