import * as Yup from "yup";
import * as adminSteps from "./admin-user-steps";
import * as api from "./api";
import * as userSteps from "./user-steps";
import { useMutateData, useQueryData } from "../../../hooks";
import React from "react";
import WizardLayout from "../WizardLayout";
import gql from "graphql-tag";
import { map } from "lodash";

const constructWizard = (props) => {
	if (props.mode === "add") {
		return {
			initialStep: 0,
			steps: [
				!props.spaceAccountOwnerOnly ? userSteps.userTypeStep : null,
				userSteps.emailStep,
				!props.spaceAccountOwnerOnly ? userSteps.permissionsStep : null,
				userSteps.groupsStep,
				userSteps.notifyStep,
				userSteps.summaryStep,
			].filter((e) => e),
		};
	} else if (props.mode === "invite-update") {
		return {
			initialStep: 3,
			steps: [
				!props.spaceAccountOwnerOnly ? userSteps.permissionsStep : null,
				userSteps.groupsStep,
				userSteps.notifyStep,
				userSteps.summaryStep,
			].filter((e) => e),
		};
	} else if (props.mode === "update") {
		return {
			initialStep: 3,
			steps: [
				!props.spaceAccountOwnerOnly ? userSteps.permissionsStep : null,
				userSteps.groupsStep,
				userSteps.notifyStep,
				userSteps.summaryStep,
			].filter((e) => e),
		};
	} else if (props.mode === "invite-remove") {
		return {
			initialStep: 0,
			steps: [userSteps.removeStep],
		};
	} else if (props.mode === "remove") {
		return {
			initialStep: 0,
			steps: [userSteps.removeStep],
		};
	} else if (props.mode === "admin-add") {
		return {
			initialStep: 0,
			steps: [
				adminSteps.emailStep,
				adminSteps.permissionsStep,
				adminSteps.summaryStep,
			],
		};
	} else if (props.mode === "admin-update") {
		return {
			initialStep: 1,
			steps: [adminSteps.permissionsStep, adminSteps.summaryStep],
		};
	} else if (props.mode === "admin-remove") {
		return {
			initialStep: 0,
			steps: [adminSteps.removeStep],
		};
	} else if (props.mode === "client-admin-add") {
		return {
			initialStep: 0,
			steps: [
				adminSteps.clientEmailStep,
				props.currentUserIsAdmin ? adminSteps.clientPermissionStep : null,
				adminSteps.notifyStep,
				adminSteps.clientSummaryStep,
			].filter((e) => e),
		};
	} else if (props.mode === "client-admin-remove") {
		return {
			initialStep: 0,
			steps: [adminSteps.removeStep],
		};
	}
};

export default function UserWizard(props) {
	const wizard = constructWizard(props);

	const removeAdminUser = useMutateData(gql`
		mutation ($userId: Int!) {
			removeAdminUser(userId: $userId)
		}
	`);

	const updateAdminUser = useMutateData(gql`
		mutation (
			$userId: Int!
			$description: String
			$isAdmin: Boolean!
			$isCSAdmin: Boolean!
			$adminDashboardEnabled: Boolean!
			$featuresPreview: Boolean!
			$debugEnabled: Boolean!
		) {
			updateAdminUser(
				userId: $userId
				description: $description
				isAdmin: $isAdmin
				isCSAdmin: $isCSAdmin
				adminDashboardEnabled: $adminDashboardEnabled
				featuresPreview: $featuresPreview
				debugEnabled: $debugEnabled
			) {
				UserID
				Email
				FirstName
				LastName
				Phone
				CountryCode
				LastUsedAppVersion
				IsAdmin
				IsCSAdmin
				AdminDashboardEnabled
				FeaturesPreview
				DebugEnabled
			}
		}
	`);

	const removeClientAdminUser = useMutateData(gql`
		mutation ($clientId: Int!, $userId: Int!) {
			removeClientAdminUser(clientId: $clientId, userId: $userId)
		}
	`);

	const setClientAdminUser = useMutateData(gql`
		mutation ($clientId: Int!, $userId: Int!, $notifyUserByEmail: Boolean) {
			setClientAdminUser(
				clientId: $clientId
				userId: $userId
				notifyUserByEmail: $notifyUserByEmail
			)
		}
	`);

	const {
		data: { getClients },
	} = useQueryData(
		gql`
			query {
				getClients {
					ClientID
					Name
				}
			}
		`,
		{},
		!props.currentUserIsAdmin
	);

	const hasOrganizationAccessGroupExpiry = props.user
		?.organizationAccessGroupsExpiration
		? props.user.organizationAccessGroupsExpiration.length > 0
		: false;

	return (
		<WizardLayout
			close={props.close}
			title={(values) => {
				if (values.mode === "add") return "Add user";
				if (values.mode === "invite-update") return "Update user invitation";
				if (values.mode === "update") return "Update user";
				if (values.mode === "invite-remove") return "Remove invitation";
				if (values.mode === "remove") return "Remove all access";
				if (values.mode === "admin-add") return "Add admin user";
				if (values.mode === "admin-update") return "Update admin user";
				if (values.mode === "admin-remove") return "Remove admin user";
				if (values.mode === "client-admin-add") return "Add admin user";
				if (values.mode === "client-admin-remove") return "Remove admin user";
			}}
			values={[
				{ name: "mode", value: props.mode, validator: Yup.string() },
				{ name: "generalUser", value: false, validator: Yup.boolean() },
				{
					name: "email",
					value: props.user ? props.user.Email : "",
					validator: Yup.string().email(),
				},
				{
					name: "user",
					value: props.user ? props.user : "",
					validator: Yup.object(),
				},
				{
					name: "users",
					value: props.users ? props.users : [],
					validator: Yup.array(),
				},
				{
					name: "roles",
					value:
						props.user && props.user.userRoles
							? api.restoreRoles(
									props.roles,
									props.selectedOrganization.OrganizationID,
									props.sites,
									props.user.userRoles
							  )
							: {},
					validator: Yup.object(),
				},
				{
					name: "groups",
					value:
						props.user && props.user.organizationAccessGroups
							? api.restoreGroups(
									props.selectedOrganization.OrganizationID,
									props.user.organizationAccessGroups
							  )
							: [],
					validator: Yup.array(Yup.object()),
				},
				{
					name: "groupsExpirations",
					value:
						props.user && hasOrganizationAccessGroupExpiry
							? props.user.organizationAccessGroupsExpiration
							: [{}],
					validator: Yup.array(Yup.object()),
				},
				{
					name: "filterGroupIds",
					value:
						props.user && props.user.organizationAccessGroupsExpiration
							? props.user.organizationAccessGroupsExpiration?.map(
									(a) => a.OrganizationAccessGroupID
							  )
							: [{}],
					//validator: Yup.array(),
				},
				{
					name: "superAdmin",
					value: props.user
						? Boolean(props.user.IsAdmin && !props.user.IsCSAdmin)
						: false,
					validator: Yup.boolean(),
				},
				{
					name: "csAdmin",
					value: props.user
						? Boolean(props.user.IsAdmin && props.user.IsCSAdmin)
						: false,
					validator: Yup.boolean(),
				},
				{
					name: "adminDashboardEnabled",
					value: props.user ? Boolean(props.user.AdminDashboardEnabled) : false,
					validator: Yup.boolean(),
				},
				{
					name: "featuresPreview",
					value: props.user ? Boolean(props.user.FeaturesPreview) : false,
					validator: Yup.boolean(),
				},
				{
					name: "debugEnabled",
					value: props.user ? Boolean(props.user.DebugEnabled) : false,
					validator: Yup.boolean(),
				},
				{ name: "notifyUserByEmail", value: true, validator: Yup.boolean() },
				{
					name: "editFromSummary",
					value: props.mode === "invite-update" || props.mode === "update",
					validator: Yup.boolean(),
				},
				{ name: "description", value: "", validator: Yup.string() },
				{
					name: "clientId",
					value: props.user
						? props.user.ClientID
						: !props.currentUserIsAdmin
						? props.currentUserClientId
						: null,
					validator: Yup.number().integer().nullable(),
				},
			]}
			onSubmit={(values, { setSubmitting }) => {
				setSubmitting(true);
				switch (values.mode) {
					case "add":
						api.submitUserInvitation(props, values, setSubmitting);
						break;
					case "invite-update":
						api.submitUpdateUserInvitation(props, values, setSubmitting);
						break;
					case "update":
						api.submitUpdateUser(props, values, setSubmitting);
						break;
					case "invite-remove":
						api.submitRemoveUserInvitation(props, values, setSubmitting);
						break;
					case "remove":
						api.submitRemoveUser(props, values, setSubmitting);
						break;
					case "admin-add":
					case "admin-update":
						api.submitAdminUser(props, values, setSubmitting, updateAdminUser);
						break;
					case "admin-remove":
						api.submitRemoveAdminUser(
							props,
							values,
							setSubmitting,
							removeAdminUser
						);
						break;
					case "client-admin-add":
						api.submitClientAdminUser(
							props,
							values,
							setSubmitting,
							setClientAdminUser
						);
						break;
					case "client-admin-remove":
						api.submitRemoveClientAdminUser(
							props,
							values,
							setSubmitting,
							removeClientAdminUser
						);
						break;
				}
			}}
			steps={wizard.steps}
			initialStep={wizard.initialStep}
			wizardProps={{
				...props,
				clients: getClients
					? map(getClients, (client) => ({
							value: client.ClientID,
							label: client.Name,
					  }))
					: null,
			}}
		/>
	);
}
