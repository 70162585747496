import * as Yup from "yup";
import * as steps from "./steps";
import {
	convertModeNameToWizardTitle,
	submitMutation,
} from "../wizard-helpers";
import React from "react";
import WizardLayout from "../WizardLayout";
import gql from "graphql-tag";
import { useMutateData } from "../../../hooks";

const constructWizard = (props) => {
	switch (props.mode) {
		case "add-control-box-stack":
			return {
				initialStep: 0,
				steps: [steps.controlBoxStackStep],
			};
		case "edit-control-box-stack":
			return {
				initialStep: 0,
				steps: [steps.controlBoxStackStep],
			};
		case "remove-control-box-stack":
			return {
				initialStep: 0,
				steps: [steps.removeControlBoxStackStep],
			};
		case "add-control-box":
			return {
				initialStep: 0,
				steps: [
					steps.controlBoxSerialNumberStep,
					steps.controlBoxNameStep,
					steps.controlBoxSummaryStep,
				],
			};
		case "remove-control-box":
			return {
				initialStep: 0,
				steps: [steps.removeControlBoxStep],
			};
	}
};

export default function ControlBoxStackWizard(props) {
	const wizard = constructWizard(props);
	const createControlBoxStackMutation = useMutateData(gql`
		mutation ($installationId: Int!, $name: String, $groupName: String) {
			createControlBoxStack(
				installationId: $installationId
				name: $name
				groupName: $groupName
			) {
				ControlBoxStackID
			}
		}
	`);

	const editControlBoxStackMutation = useMutateData(gql`
		mutation ($controlBoxStackId: Int!, $name: String, $groupName: String) {
			editControlBoxStack(
				controlBoxStackId: $controlBoxStackId
				name: $name
				groupName: $groupName
			) {
				ControlBoxStackID
			}
		}
	`);

	const deleteControlBoxStackMutation = useMutateData(gql`
		mutation ($controlBoxStackId: Int!) {
			deleteControlBoxStack(controlBoxStackId: $controlBoxStackId)
		}
	`);

	const addControlBoxToControlBoxStackMutation = useMutateData(gql`
		mutation (
			$controlBoxStackId: Int!
			$hardwareId: Int!
			$controlBoxName: String!
		) {
			addControlBoxToControlBoxStack(
				controlBoxStackId: $controlBoxStackId
				hardwareId: $hardwareId
				controlBoxName: $controlBoxName
			) {
				ControlBoxStackID
			}
		}
	`);

	const removeControlBoxFromControlBoxStackMutation = useMutateData(gql`
		mutation ($controlBoxStackId: Int!, $hardwareId: Int!) {
			removeControlBoxFromControlBoxStack(
				controlBoxStackId: $controlBoxStackId
				hardwareId: $hardwareId
			) {
				ControlBoxStackID
			}
		}
	`);

	return (
		<WizardLayout
			close={props.close}
			title={(values) => convertModeNameToWizardTitle(values.mode)}
			values={[
				{
					name: "mode",
					value: props.mode,
					validator: Yup.string(),
				},
				{
					name: "controlBoxStackId",
					value: props.controlBoxStack?.ControlBoxStackID,
					validator: Yup.number().integer(),
				},
				{
					name: "controlBoxStackName",
					value: props.controlBoxStack?.Name,
					validator: Yup.string().nullable(),
				},
				{
					name: "groupName",
					value: props.groupName,
					validator: Yup.string().nullable(),
				},
				{
					name: "hardwareId",
					value: props.controlBox?.HardwareID,
					validator: Yup.number().integer().nullable(),
				},
				{
					name: "controlBoxStackControlBoxId",
					value: props.controlBox?.ControlBoxStackControlBoxID,
					validator: Yup.number().integer().nullable(),
				},
				{
					name: "serialNumber",
					value: props.controlBox?.SerialNumber,
					validator: Yup.string().nullable(),
				},
				{
					name: "controlBoxName",
					value: props.controlBox?.ControlBoxName,
					validator: Yup.string().nullable(),
				},
			]}
			steps={wizard.steps}
			initialStep={wizard.initialStep}
			wizardProps={props}
			onSubmit={async (values) => {
				switch (values.mode) {
					case "add-control-box-stack":
						await submitMutation(
							createControlBoxStackMutation,
							{
								installationId: props.installationId,
								name: values.controlBoxStackName,
								groupName: values.groupName,
							},
							props.close,
							"Control box stack added."
						);
						break;
					case "edit-control-box-stack":
						await submitMutation(
							editControlBoxStackMutation,
							{
								controlBoxStackId: values.controlBoxStackId,
								name: values.controlBoxStackName,
								groupName: values.groupName,
							},
							props.close,
							"Control box stack updated."
						);
						break;
					case "remove-control-box-stack":
						await submitMutation(
							deleteControlBoxStackMutation,
							{ controlBoxStackId: values.controlBoxStackId },
							props.close,
							"Control box stack removed."
						);
						break;
					case "add-control-box":
						await submitMutation(
							addControlBoxToControlBoxStackMutation,
							{
								controlBoxStackId: values.controlBoxStackId,
								hardwareId: values.hardwareId,
								controlBoxName: values.controlBoxName,
							},
							props.close,
							"Control box added."
						);
						break;
					case "remove-control-box":
						await submitMutation(
							removeControlBoxFromControlBoxStackMutation,
							{
								controlBoxStackId: values.controlBoxStackId,
								hardwareId: values.hardwareId,
							},
							props.close,
							"Control box removed."
						);
						break;
				}
			}}
		/>
	);
}
