import React, { useCallback, useState } from "react";
import Button from "../../layout/Button";
import TableLayout from "../../layout/TableLayout";
import WizardLayout from "../WizardLayout";
import WizardNavigation from "../WizardNavigation";
import { colours } from "../../../styles";
import { importKiosksCsv } from "../../../api/installation";
import { sortBy } from "lodash";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import { validateCSV } from "../../../helpers/validate";

const Wrapper = styled.div`
	a {
		color: ${colours.blue};
		text-decoration: none;
		transition: 0.2s ease;

		&:hover {
			cursor: pointer;
			opacity: 0.8;
		}
	}
`;

const FormatLink = styled.div`
	font-size: 16px;
	margin: 16px 0;
`;

const ErrorText = styled.div`
	color: ${colours.red};
	font-size: 18px;
	font-weight: 600;
	margin: 16px 0 32px;
	text-align: center;
`;

const Label = styled.div`
	font-size: 24px;
	font-weight: 700;
	margin-bottom: 16px;
`;

const DropBox = styled.div`
	background: ${colours.highlightGrey};
	border: 4px dashed ${colours.borderGrey};
	border-radius: 4px;
	margin-bottom: 10px;
	padding: 60px 10px;
	text-align: center;
	width: 100%;

	p {
		font-size: 18px;
		font-weight: 600;
	}

	&.dragover-err {
		border: 5px dashed $red;
	}

	&.dragover {
		border: 5px dashed $green;
	}
`;

const schema = [
	{ name: "SerialNumber", isRequired: true },
	{ name: "HardwareVersion", isRequired: true },
	{ name: "CodeName", isRequired: true },
	{ name: "Market", isRequired: true },
	{ name: "Manufacturer", isRequired: true },
	{ name: "ManufacturerID", isRequired: true },
	{ name: "Connectivity", isRequired: true },
	{ name: "Lense", isRequired: false },
	{ name: "Display", isRequired: false },
	{ name: "CardReader", isRequired: false },
	{ name: "SoftwareVersion", isRequired: false },
];

async function importCSV(file, setSuccess, setError) {
	try {
		const results = await importKiosksCsv(file);
		setSuccess(results);
	} catch (error) {
		setError();
	}
}

function Results(props) {
	return (
		<div>
			<Label>Kiosk Import Results</Label>
			{props.data &&
				[
					["Passed", "added"],
					["Failed", "failed"],
					["Duplicates", "duplicates"],
				].map((e) => {
					if (!props.data[e[1]] || props.data[e[1]].length === 0) return null;

					return (
						<div key={e[1]}>
							<h3 style={{ marginTop: 48 }}>{e[0]}</h3>
							<TableLayout
								data={sortBy(props.data[e[1]], "index")}
								columns={[
									{
										id: "Row",
										Header: "Row",
										accessor: "index",
									},
									{
										id: "SerialNumber",
										Header: "SerialNumber",
										accessor: "SerialNumber",
									},
									{
										id: "HardwareVersion",
										Header: "HardwareVersion",
										accessor: "HardwareVersion",
									},
									{
										id: "CodeName",
										Header: "CodeName",
										accessor: "CodeName",
									},
									{
										id: "Market",
										Header: "Market",
										accessor: "Market",
									},
									{
										id: "Manufacturer",
										Header: "Manufacturer",
										accessor: "Manufacturer",
									},
									{
										id: "ManufacturerID",
										Header: "ManufacturerID",
										accessor: "ManufacturerID",
									},
									{
										id: "Connectivity",
										Header: "Connectivity",
										accessor: "Connectivity",
									},
									{
										id: "Lense",
										Header: "Lense",
										accessor: "Lense",
									},
									{
										id: "Display",
										Header: "Display",
										accessor: "Display",
									},
									{
										id: "CardReader",
										Header: "CardReader",
										accessor: "CardReader",
									},
									{
										id: "SoftwareVersion",
										Header: "SoftwareVersion",
										accessor: "SoftwareVersion",
									},
								]}
								sortable={false}
								resizable={false}
								showResultsLength={true}
								showPagination={
									props.data[e[1]] && props.data[e[1]].length > 10
								}
								defaultPageSize={10}
							/>
						</div>
					);
				})}
		</div>
	);
}

function ImportKiosks() {
	const [state, setState] = useState({
		file: null,
		submitting: false,
		submitted: false,
		error: false,
		results: null,
	});

	const onDrop = useCallback((acceptedFiles) => {
		setState((_state) => ({
			..._state,
			file: acceptedFiles ? acceptedFiles[0] : null,
			error: false,
		}));
	}, []);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

	return (
		<Wrapper>
			{!state.submitted && <Label>Import Kiosks</Label>}
			{!state.submitted && (
				<FormatLink>
					The file must be in the following{" "}
					<a
						href={`data:text/csv;charset=utf-8,${encodeURI(
							schema.map((column) => column.name).join(",")
						)}`}
						download="format.csv"
					>
						CSV format
					</a>
					.
				</FormatLink>
			)}
			{!state.submitted && (
				<DropBox {...getRootProps()}>
					<input {...getInputProps()} multiple={false} accept="text/csv" />
					{isDragActive && <p>Drop the file here...</p>}
					{!state.file && !isDragActive && (
						<p>Click to choose a file or drag one here...</p>
					)}
					{state.file && !isDragActive && <p>File chosen: {state.file.name}</p>}
				</DropBox>
			)}
			{!state.submitted && (
				<div style={{ textAlign: "center" }}>
					<Button
						color="green"
						onClick={() => {
							setState((_state) => ({
								..._state,
								submitting: true,
								submitted: true,
								error: false,
							}));

							validateCSV(
								state.file,
								schema,
								(results) => {
									setState((_state) => ({
										..._state,
										submitting: false,
										submitted: true,
										error: false,
										results,
									}));
								},
								() => {
									setState((_state) => ({
										..._state,
										submitting: false,
										submitted: false,
										error: true,
									}));
								},
								importCSV
							);
						}}
						disabled={state.submitted || state.error || !state.file}
					>
						Import
					</Button>
				</div>
			)}
			{state.error && (
				<ErrorText>The file does not match the required format</ErrorText>
			)}
			{state.submitted && <Results data={state.results} />}
		</Wrapper>
	);
}

function _addStep({ close }) {
	return {
		id: "add",
		label: "Add Kiosks",
		render: () => <ImportKiosks />,
		footer: () => {
			return (
				<WizardNavigation
					leftItems={[
						<Button key="previous" onClick={close} color="blue">
							Close
						</Button>,
					]}
				/>
			);
		},
	};
}

export default function AddKioskWizard(props) {
	return (
		<WizardLayout
			close={props.close}
			title={"Add Kiosk"}
			values={[]}
			onSubmit={async () => {}}
			steps={[_addStep]}
			initialStep={0}
			wizardProps={props}
		/>
	);
}
