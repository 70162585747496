import gql from "graphql-tag";
import { useQueryData, useMutateData } from "../../../hooks";

export const useUpsertEventGroup = (group, { onCompleted, onError }) => {
	const upsertEventGroup = useMutateData(
		gql`
			mutation UpsertEventGroup($group: EventGroupInput!) {
				upsertEventGroup(group: $group)
			}
		`,
		{
			group,
		},
		onCompleted,
		onError
	);

	return upsertEventGroup;
};

export const useUpsertOrganizationAccessGroup = () => {
	return useMutateData(
		gql`
			mutation UpsertOrganizationAccessGroup(
				$groupData: OrganizationAccessGroupInput!
				$mappingData: GroupMappingsInput!
			) {
				upsertOrganizationAccessGroup(
					groupData: $groupData
					mappingData: $mappingData
				)
			}
		`
	);
};

export const useDeleteOrganizationAccessGroup = () => {
	return useMutateData(
		gql`
			mutation DeleteOrganizationAccessGroup($organizationAccessGroupId: Int!) {
				deleteOrganizationAccessGroup(
					organizationAccessGroupId: $organizationAccessGroupId
				)
			}
		`
	);
};

export const useGetEventSeriesByGroupId = (groupId) => {
	const skip = !groupId;
	const { data, isLoading, isError } = useQueryData(
		gql`
			query GetEventSeriesByGroupId($groupId: ID!) {
				getEventSeriesByGroupId(groupId: $groupId) {
					EventSeriesID
					Name
				}
			}
		`,
		{
			groupId,
		},
		skip
	);

	const eventSeries = data ? data.getEventSeriesByGroupId : null;

	return { eventSeries, isLoading, isError };
};

export const useGetSitesByGroupId = (organizationAccessGroupId) => {
	const skip = !organizationAccessGroupId;
	const { data, isLoading, isError } = useQueryData(
		gql`
			query getSitesByOrganizationAccessGroupId(
				$OrganizationAccessGroupID: Int!
			) {
				getSitesByOrganizationAccessGroupId(
					OrganizationAccessGroupID: $OrganizationAccessGroupID
				) {
					SiteID
					Name
					LeaseParks {
						LeaseParkID
						Name
					}
				}
			}
		`,
		{
			OrganizationAccessGroupID: organizationAccessGroupId,
		},
		skip
	);

	const sites = data ? data.getSitesByOrganizationAccessGroupId : null;

	return { sites, isLoading, isError };
};

export const useGetGroupById = (organizationAccessGroupId) => {
	const skip = !organizationAccessGroupId;
	const { data, isLoading, isError } = useQueryData(
		gql`
			query getOrganizationAccessGroupById($organizationAccessGroupId: Int!) {
				getOrganizationAccessGroupById(
					organizationAccessGroupId: $organizationAccessGroupId
				) {
					Name
					OrganizationAccessGroupID
					Sites {
						Name
						SiteID
						Timezone
						LeaseParks {
							Name
							Spaces
							LeaseParkID
						}
					}
				}
			}
		`,
		{
			organizationAccessGroupId,
		},
		skip
	);

	const sites = data?.getOrganizationAccessGroupById?.Sites;

	return { sites, isLoading, isError };
};
