import * as sessionApi from "../../../api/session";
import Alert from "react-s-alert";
import moment from "moment";

export async function addUserLease(props, values, setSubmitting, mutation) {
	try {
		let data = values;

		if (data.dateRange) {
			data.startDate = moment(data.dateRange[0]).format("YYYYMMDD");
			data.endDate = moment(data.dateRange[1]).format("YYYYMMDD");
		}

		await mutation({
			variables: {
				userId: data.user.UserID,
				organizationId: data.organizationId,
				leaseParkId: data.selectedSpace.value,
				unregisteredEmail: data.user.Email,
				unregisteredFirstName: data.user.FirstName,
				unregisteredLastName: data.user.LastName,
				startDate: data.startDate,
				endDate: data.endDate,
				numberplate: data.numberplate,
				notifyUser: data.notifyUser,
			},
		});

		setSubmitting(false);
		Alert.success("User lease added");
		props.close(true);
	} catch (error) {
		setSubmitting(false);
		Alert.error("Something went wrong. Please try again.");
	}
}

export async function cancelUserLease(props, values, setSubmitting, mutation) {
	try {
		const isExternal = values.lease.IsExternal;
		if (isExternal) {
			//external bookings will be canceled via GraphQL
			//without the context of a site
			await mutation({ variables: { userLeaseId: values.lease.UserLeaseID } });
		} else {
			let apiRequest =
				values.lease.DurationType === "Months"
					? sessionApi.cancelUserLease
					: sessionApi.cancelUserLeaseAndReleaseSpace;

			await apiRequest(values.lease.SiteID, values.lease.UserLeaseID);
		}

		setSubmitting(false);
		Alert.success("User lease cancelled");
		props.close(true);
	} catch (error) {
		setSubmitting(false);
		if (
			error.errors &&
			error.errors[0] &&
			error.errors[0].code ===
				"CancellingUnneccesaryDueToNoOutstandingLeasePayments"
		) {
			Alert.error("This booking is paid in full, cancelling is unnecessary.");
		} else if (
			error.errors &&
			error.errors[0] &&
			error.errors[0].code === "UserLeaseAlreadyStarted"
		) {
			Alert.error(
				"This booking cannot be cancelled because it has already started."
			);
		} else if (
			error.errors &&
			error.errors[0] &&
			error.errors[0].code === "UserLeaseAlreadyNodeTriggered"
		) {
			Alert.error(
				"This booking cannot be cancelled because the user has already opened the gate."
			);
		} else {
			Alert.error("Something went wrong. Please try again.");
		}
		props.close(true);
	}
}
