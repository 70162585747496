import Alert from "react-s-alert";
import Button from "../layout/Button";
import { Download } from "react-feather";
import React from "react";
import moment from "moment";

function BulkDownloadButton(props) {
	return (
		<Button
			color="blue"
			style={props.style}
			disabled={props.disabled}
			onClick={() => {
				if (props.dateRange) {
					const maxDownloadDayRange = 60;
					const startDate = moment(props.dateRange[0]);
					const endDate = moment(props.dateRange[1]);
					const diff = endDate.diff(startDate, "days");

					if (diff > maxDownloadDayRange) {
						Alert.error(
							`Unable to download. Please select a date range of less than 60 days, i.e. : ${endDate
								.clone()
								.add(-maxDownloadDayRange, "days")
								.format("DD MMM YYYY")} - ${endDate.format("DD MMM YYYY")}.`
						);
						return;
					}
				}

				props.onSubmit();
			}}
		>
			<Download size={20} /> Download
		</Button>
	);
}

export default BulkDownloadButton;
