import * as Yup from "yup";
import * as createSiteSteps from "./create-site-steps";
import * as installationApi from "../../../api/installation";
import { cloneDeep, map } from "lodash";
import Alert from "react-s-alert";
import React, { useState } from "react";
import WizardLayout from "../WizardLayout";
import { IMPORT_TYPES } from "../../../helpers/constants";
import { useMutateData } from "../../../hooks";
import gql from "graphql-tag";

const constructWizard = ({
	importType,
	state,
	setImportType,
	setState,
	createSiteViaCsv,
}) => {
	const steps = [createSiteSteps.createTypeStep(setImportType)];

	if (importType === IMPORT_TYPES.IMPORT) {
		steps.push(
			createSiteSteps.importSitesStep(state, setState, createSiteViaCsv),
			createSiteSteps.resultsStep(state)
		);
	}

	if (importType === IMPORT_TYPES.WIZARD) {
		steps.push(
			createSiteSteps.nameStep,
			createSiteSteps.addressStep,
			createSiteSteps.mapStep,
			createSiteSteps.viewStep,
			createSiteSteps.taxStep,
			createSiteSteps.contactStep,
			createSiteSteps.publicAccessStep,
			createSiteSteps.privateParkingStep,
			createSiteSteps.shortTermParkingStep,
			createSiteSteps.monthlyParkingStep,
			createSiteSteps.maximumCapacityStep,
			createSiteSteps.termsAndConditionsStep,
			createSiteSteps.gateStep,
			createSiteSteps.sessionlessStep,
			createSiteSteps.summaryStep
		);
	}

	return {
		initialStep: 0,
		steps,
	};
};

export default function CreateSiteWizard(props) {
	const [importType, setImportType] = useState(IMPORT_TYPES.WIZARD);
	const [state, setState] = useState({
		file: null,
		submitting: false,
		submitted: false,
		error: false,
		results: null,
	});

	const createSiteViaCsv = useMutateData(gql`
		mutation ($file: Upload!, $organizationId: Int!) {
			createSiteViaCsv(file: $file, organizationId: $organizationId) {
				LocationName
				LocationCode
				LocationAddressLine1
				LocationAddressLine2
				LocationAddressLine3
				LocationCity
				LocationPostCode
				LocationCountry
				Longitude
				Latitude
				Timezone
				Currency
				TaxType
				ContactPhone
				ContactEmail
				CarParkDescription
				ExternalReference
				EnforcementReference
				IsPublic
				SiteType
				ParkingSessions
				Bookings
				PayOnExit
				PayAfterExit
				Validation
				ValidationStacking
				OutstandingBalanceExpiry
				SessionOpenReminder
				AutomaticallyCloseSession
				MondayHours
				TuesdayHours
				WednesdayHours
				ThursdayHours
				FridayHours
				SaturdayHours
				SundayHours

				Succeeded
				ErrorMessages
			}
		}
	`);

	const wizard = constructWizard({
		importType,
		state,
		setImportType,
		setState,
		createSiteViaCsv,
	});

	const onSubmit = async (values, { setSubmitting }) => {
		setSubmitting(true);
		try {
			// trim site data
			const data = cloneDeep(values);
			[
				"defaultTaxRates",
				"importType",
				"editTnCs",
				"editFromSummary",
				"validEmail",
				"validAddress",
				"groups",
			].forEach((key) => delete data[key]);

			let response = await installationApi.createSite(data);

			if (response.SiteID) {
				if (values.groups) {
					const groupIds = map(values.groups, (group) => group.value);

					await installationApi.setOrganizationAccessGroupsForSite(
						props.organizationId,
						response.SiteID,
						groupIds
					);
				}

				Alert.success("Site created");

				//wait a second before going to the new site so that the user can see Alert
				return new Promise(() =>
					setTimeout(() => {
						if (data.SiteType === "Gated") {
							//direct gated sites to the installation page
							window.location.href = `/operator/${props.operatorId}/organization/${props.organizationId}/site/${response.SiteID}/installation`;
						} else {
							window.location.href = `/operator/${props.operatorId}/organization/${props.organizationId}/site/${response.SiteID}`;
						}
					}, 1000)
				);
			}
		} catch (error) {
			setSubmitting(false);
			Alert.error("Something went wrong. Please try again.");
		}
	};

	return (
		<WizardLayout
			close={props.close}
			title="Create Site"
			values={[
				{
					name: "OrganizationID",
					value: props.organizationId,
					validator: Yup.number().integer(),
				},
				{
					name: "Name",
					validator: Yup.string(),
				},
				{
					name: "Description",
					validator: Yup.string(),
				},
				{
					name: "validAddress",
					validator: Yup.boolean(),
				},
				{
					name: "Address",
					validator: Yup.string(),
				},
				{
					name: "Country",
					validator: Yup.string(),
				},
				{
					name: "Latitude",
					validator: Yup.number(),
				},
				{
					name: "Longitude",
					validator: Yup.number(),
				},
				{
					name: "StreetViewRotation",
					value: 180,
					validator: Yup.number(),
				},
				{
					name: "TaxRate",
					value: 0,
					validator: Yup.string(),
				},
				{
					name: "TaxType",
					validator: Yup.string(),
				},
				{
					name: "Timezone",
					value: props.defaultTimezone,
					validator: Yup.string(),
				},
				{
					name: "Currency",
					value: props.defaultCurrency,
					validator: Yup.string(),
				},
				{
					name: "EnquiryPhone",
					validator: Yup.string(),
				},
				{
					name: "EnquiryEmail",
					validator: Yup.string().email(),
				},
				{
					name: "validEmail",
					validator: Yup.boolean(),
				},
				{
					name: "HasPublicAccess",
					value: false,
					validator: Yup.boolean(),
				},
				{
					name: "MaximumCapacity",
					validator: Yup.number().integer(),
				},
				{
					name: "groups",
					value: null,
					validator: Yup.array().nullable(),
				},
				{
					name: "HasCasualParking",
					value: false,
					validator: Yup.boolean(),
				},
				{
					name: "HasLeaseParking",
					value: false,
					validator: Yup.boolean(),
				},
				{
					name: "TnCs",
					value: null,
					validator: Yup.string().nullable(),
				},
				{
					name: "SiteType",
					value: "Gateless",
					validator: Yup.string(),
				},
				{
					name: "HasSessionlessAccess",
					value: false,
					validator: Yup.boolean(),
				},
			]}
			onSubmit={onSubmit}
			steps={wizard.steps}
			initialStep={wizard.initialStep}
			wizardProps={props}
			hideStepIndicator={true}
		/>
	);
}
