import React from "react";
import TableLayoutBeta, {
	booleanValueFormatter,
} from "../../components/layout/TableLayoutBeta";

const VehicleColumns = [
	{
		id: "Plate",
		Header: "Plate",
		accessor: "Numberplate",
		fixedWidth: 200,
	},
	{
		id: "CountryCode",
		Header: "Country",
		accessor: "CountryCode",
		fixedWidth: 200,
	},
	{
		id: "StateCode",
		Header: "State",
		accessor: "StateCode",
		fixedWidth: 200,
	},
	{
		id: "ANPREnabled",
		Header: "ANPR Enabled",
		accessor: (d) => (d.ANPREnabled ? 1 : 0),
		Cell: (cellProps) => booleanValueFormatter(cellProps.value, true),
		fixedWidth: 200,
	},
	{
		id: "MakeAndModel",
		Header: "Make",
		accessor: "Vehicle.MakeAndModel",
		fixedWidth: 200,
	},
	{
		id: "Color",
		Header: "Color",
		accessor: "Vehicle.Color",
		fixedWidth: 200,
	},
	{
		id: "Type",
		Header: "Type",
		accessor: "Vehicle.FuelType",
		fixedWidth: 200,
	},
];

const defaultSorting = [
	{
		id: "Plate",
		desc: false,
	},
];

function VehiclesTable({ data }) {
	return (
		<TableLayoutBeta
			data={data}
			filterOptions={[]}
			columns={VehicleColumns}
			showResultsLength={true}
			defaultSortBy={defaultSorting}
		/>
	);
}

export default VehiclesTable;
