import * as Yup from "yup";
import * as installationApi from "../../api/installation";
import { MoreHorizontal, Plus } from "react-feather";
import React, { useContext, useState } from "react";
import {
	useMutateData,
	useHasClientAdmin,
	useQueryData,
	usePermissions,
} from "../../hooks";
import Accordion from "../../components/layout/Accordion";
import Alert from "react-s-alert";
import { AppContext } from "../../context/app-context";
import BrandingWizard from "../../components/wizards/branding-wizard/BrandingWizard";
import Button from "../../components/layout/Button";
import Card from "../../components/layout/Card";
import DropdownMenuBeta from "../../components/layout/DropdownMenuBeta";
import ErrorBoundary from "../ErrorBoundary";
import FormLayout from "../../components/layout/FormLayout";
import LoadingPlaceholder from "../../components/report/LoadingPlaceholder";
import ModalDialog from "../../components/layout/ModalDialog";
import PageTitle from "../../components/layout/PageTitle";
import TableLayoutBeta from "../../components/layout/TableLayoutBeta";
import { cloneDeep } from "lodash";
import gql from "graphql-tag";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Circle = styled.div`
	border-radius: 20px;
	width: 20px;
	height: 20px;
	float: left;
	margin-right: 10px;
`;

const columns = (props) => [
	{
		Header: "Logo",
		accessor: (brand) => <img src={brand.LogoDark} height="45" />,
		fixedWidth: 100,
	},
	{
		Header: "Icon Logo",
		accessor: (brand) => <img src={brand.IconLogo} height="45" />,
		fixedWidth: 100,
	},
	{
		Header: "Name",
		accessor: "CompanyName",
		fixedWidth: 300,
	},
	{
		Header: "Primary Colour",
		accessor: (brand) => (
			<span>
				<Circle style={{ backgroundColor: brand.MainColor }} />
				{brand.MainColor}
			</span>
		),
	},
	{
		Header: "Secondary Colour",
		accessor: (brand) => (
			<span>
				<Circle style={{ backgroundColor: brand.SecondaryColor }} />
				{brand.SecondaryColor}
			</span>
		),
	},

	{
		id: "tasks",
		Header: "",
		accessor: null,
		Cell: ({ row: { original } }) => (
			<DropdownMenuBeta
				triggerContent={<MoreHorizontal size={24} />}
				items={
					props.isAdmin || props.isClientAdmin
						? [
								<div
									key="edit"
									onClick={() => props.openBrandingWizard(original, "edit")}
								>
									Edit
								</div>,
								<div
									key="delete"
									onClick={() => props.openBrandingWizard(original, "delete")}
								>
									Delete
								</div>,
						  ]
						: [
								<div
									key="edit"
									onClick={() => props.openBrandingWizard(original, "edit")}
								>
									Edit
								</div>,
						  ]
				}
			/>
		),
		fixedWidth: 50,
	},
];

const ActionWrapper = styled.div`
	border-radius: 4px;
	display: flex;
	justify-content: flex-end;
	margin: 16px 0;
	padding: 16px;
	position: relative;

	button {
		svg {
			margin-right: 4px;
			vertical-align: sub;
		}
	}

	&.small {
		display: block;

		> div:last-child:not(:first-child) {
			margin-top: 16px;
		}
	}
`;

export default function BrandsContainer(props) {
	const { t: translate } = useTranslation();

	const [state, setState] = useState({
		reloadToggle: false,
		deleteConfirmationShow: false,
		deletingOrganization: false,
	});

	const {
		dispatch: { getAvailableOrganizations, redirectToDefaultPage },
	} = useContext(AppContext);

	const isAdmin = usePermissions("IsAdmin");
	const canEditSettings = usePermissions(null, "EditSettings", true);
	const isClientAdmin = useHasClientAdmin(props.selectedOperator.ClientID);

	if (!isClientAdmin && !isAdmin && !canEditSettings) {
		redirectToDefaultPage(
			props.selectedOperator.OperatorID,
			props.selectedOrganization.OrganizationID,
			props.selectedOrganization.IsValidation
		);
	}

	const {
		data: { getOrganizationById },
		refetch: refetchBrands,
		isLoading,
	} = useQueryData(
		gql`
			query ($organizationId: Int!) {
				getOrganizationById(organizationId: $organizationId) {
					Brands {
						BrandID
						LogoDark
						CompanyName
						MainColor
						IconLogo
						SecondaryColor
					}
				}
			}
		`,
		{ organizationId: props.selectedOrganization.OrganizationID }
	);

	const mutation = useMutateData(gql`
		mutation ($organizationId: Int!, $name: String, $clientId: Int) {
			updateOrganization(
				organizationId: $organizationId
				name: $name
				clientId: $clientId
			)
		}
	`);

	const settingsSchema = Yup.object().shape({
		Name: Yup.string().required(),
		ClientID: Yup.number().integer().nullable(),
	});

	let initialValues = cloneDeep({
		Name: props.selectedOrganization.Name,
		ClientID: props.selectedOrganization.ClientID,
	});

	async function deleteOrganization(organizationId) {
		try {
			setState((_state) => ({ ..._state, deletingOrganization: true }));
			await installationApi.deleteOrganizationById(organizationId);
			Alert.success("Account deleted.");

			setTimeout(() => {
				window.location.reload();
			}, 1000);
		} catch (error) {
			setState((_state) => ({ ..._state, deletingOrganization: false }));
			Alert.error(translate("Errors.Generic"));
		}
	}

	function confirmationText() {
		return (
			<div>
				Are you sure you want to DELETE this account
				<div style={{ fontWeight: "bold" }}>{initialValues.Name}?</div>
				This action CANNOT be undone.
			</div>
		);
	}

	function openBrandingWizard(details, mode) {
		setState((_state) => ({
			..._state,
			brandingWizardOpen: true,
			brandingWizardDetails: details,
			brandingWizardMode: mode,
		}));
	}

	if (state.brandingWizardOpen) {
		return (
			<BrandingWizard
				close={() => {
					setState((_state) => ({ ..._state, brandingWizardOpen: false }));
				}}
				mode={state.brandingWizardMode}
				brandingDetails={state.brandingWizardDetails}
				organizationId={props.selectedOrganization.OrganizationID}
				refetch={refetchBrands}
			/>
		);
	}

	return (
		<ErrorBoundary>
			<ModalDialog
				isOpen={state.deleteConfirmationShow}
				close={() => {
					setState((_state) => ({ ..._state, deleteConfirmationShow: false }));
				}}
				dialogText={confirmationText()}
				leftButtonText="Cancel"
				leftButtonColor="blue"
				leftButtonDisabled={state.deletingOrganization}
				onLeftButtonClick={() => {
					setState((_state) => ({ ..._state, deleteConfirmationShow: false }));
				}}
				rightButtonText="Delete"
				rightButtonColor="red"
				rightButtonDisabled={state.deletingOrganization}
				onRightButtonClick={() => {
					deleteOrganization(props.selectedOrganization.OrganizationID);
				}}
			/>
			<PageTitle>Branding</PageTitle>
			<FormLayout
				enableReinitialize={true}
				initialValues={initialValues}
				validationSchema={settingsSchema}
				onSubmit={async (values) => {
					try {
						await mutation({
							variables: {
								organizationId: props.selectedOrganization.OrganizationID,
								name: values.Name,
								clientId: values.ClientID,
							},
						});

						setState((_state) => ({
							..._state,
							reloadToggle: !state.reloadToggle,
						}));

						getAvailableOrganizations();

						Alert.success("Account settings updated");
					} catch (error) {
						Alert.error(translate("Errors.Generic"));
					}
				}}
				render={() => {
					if (isLoading) {
						return <LoadingPlaceholder />;
					}

					return (
						<form
							className="form"
							onSubmit={(event) => {
								event.preventDefault();
							}}
						>
							<Card>
								<Accordion title="Branding" expanded={true}>
									<ActionWrapper>
										<Button
											color="blue"
											onClick={() => openBrandingWizard(null, "add")}
										>
											<Plus size={20} /> Add Brand
										</Button>
									</ActionWrapper>

									<TableLayoutBeta
										data={getOrganizationById?.Brands}
										columns={columns({
											openBrandingWizard,
											isAdmin,
											isClientAdmin,
										})}
										defaultSortBy={[]}
									/>
								</Accordion>
							</Card>
						</form>
					);
				}}
			/>
		</ErrorBoundary>
	);
}
