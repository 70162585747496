import React from "react";
import infoIcon from "../../images/icon-information-circle.png";
import styled from "styled-components";

const BoxWrapper = styled.div`
	display: flex;
`;

const Box = styled.div`
	border: 2px solid #000;
	text-align: center;
	padding: 10px;
	border-radius: 10px;
	background-color: #71a8e8;
	border-color: #71a8e8;
	display: flex;
`;

const InfoText = styled.div`
	padding-left: 8px;
	color: white;
	font-style: italic;
`;

function InfoBox(props) {
	return (
		<BoxWrapper {...props}>
			<Box>
				<img
					src={infoIcon}
					alt={"Information Icon"}
					height={"24px"}
					width={"24px"}
				/>
				<InfoText>{props.text}</InfoText>
			</Box>
		</BoxWrapper>
	);
}

export default InfoBox;
