import * as installationApi from "../../api/installation";
import {
	AlertTriangle,
	Check,
	Info,
	Menu,
	MoreHorizontal,
	Plus,
	X,
} from "react-feather";
import React, { useState } from "react";
import { cloneDeep, find, isEqual, map, sortBy } from "lodash";
import { useFetchData, usePermissions } from "../../hooks";
import Button from "../../components/layout/Button";
import Card from "../../components/layout/Card";
import DropdownMenu from "../../components/layout/DropdownMenu";
import ReactDragListView from "react-drag-listview";
import SpecialInstructionWizard from "../../components/wizards/special-instruction-wizard/SpecialInstructionWizard";
import TableLayout from "../../components/layout/TableLayout";
import styled from "styled-components";

const VerticalAlign = styled.div`
	height: 100%;
	position: relative;

	svg {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}
`;

const columns = (props) => [
	{
		Header: "",
		id: "operate",
		width: 80,
		resizable: false,
		accessor: null,
		Cell: () => (
			<VerticalAlign>
				<Menu size={24} />
			</VerticalAlign>
		),
	},
	{
		Header: "Type",
		id: "Type",
		width: 80,
		resizable: false,
		accessor: (d) => {
			if (d.Type === "Do")
				return (
					<VerticalAlign>
						<Check size={24} />
					</VerticalAlign>
				);
			if (d.Type === "DoNot")
				return (
					<VerticalAlign>
						<X size={24} />
					</VerticalAlign>
				);
			if (d.Type === "Info")
				return (
					<VerticalAlign>
						<Info size={24} />
					</VerticalAlign>
				);
			if (d.Type === "Warning")
				return (
					<VerticalAlign>
						<AlertTriangle size={24} />
					</VerticalAlign>
				);
		},
	},
	{
		Header: "Title",
		id: "Title",
		accessor: (d) => d.Title,
	},
	{
		Header: "Instruction",
		id: "Instruction",
		accessor: (d) => d.Instruction,
	},
	{
		Header: "Groups",
		id: "groupNames",
		accessor: (d) => d.groupNames,
	},
	{
		id: "tasks",
		Header: "",
		accessor: null,
		Cell: (cellProps) =>
			props.hasEditSettings ? (
				<DropdownMenu
					triggerContent={<MoreHorizontal size={24} />}
					items={[
						<div
							key="edit-special-instruction"
							onClick={() =>
								props.openSpecialInstructionWizard(
									cellProps.original,
									"edit-special-instruction"
								)
							}
						>
							Edit
						</div>,
						<div
							key="delete-special-instruction"
							onClick={() =>
								props.openSpecialInstructionWizard(
									cellProps.original,
									"delete-special-instruction"
								)
							}
						>
							Delete
						</div>,
					]}
				/>
			) : null,
		resizable: false,
		width: 50,
	},
];

function mapGroupNamesForSpecialInstructions(
	specialInstructions,
	accessGroups
) {
	let _specialInstructions = cloneDeep(specialInstructions);

	for (let index in _specialInstructions) {
		let specialInstruction = _specialInstructions[index];
		if (!specialInstruction.GroupIDs) continue;
		specialInstruction._groupIds = specialInstruction.GroupIDs
			? specialInstruction.GroupIDs.split(",").map((e) => parseInt(e))
			: null;
		_specialInstructions[index]._groupNames = map(
			specialInstruction._groupIds,
			(id) => {
				let group = find(
					accessGroups,
					(accessGroup) => accessGroup.OrganizationAccessGroupID === id
				);

				return group ? group.Name : "(deleted)";
			}
		);
		_specialInstructions[index].groupNames =
			_specialInstructions[index]._groupNames.join(", ");
	}

	_specialInstructions = sortBy(
		_specialInstructions,
		(specialInstruction) => specialInstruction.InstructionOrder
	);

	return _specialInstructions;
}

async function updateInstructionOrder(siteId, instructions) {
	let data = {
		instructionIds: map(
			instructions,
			(instruction) => instruction.SpecialInstructionID
		),
	};
	await installationApi.updateInstructionOrder(siteId, data);
}

export default function SpecialInstructionsContainer(props) {
	const [state, setState] = useState({
		specialInstructions: null,
		accessGroups: null,
		specialInstructionsData: null,
		specialInstructionWizardOpen: false,
	});

	const {
		data: specialInstructions,
		initialFetchComplete: specialInstructionsLoaded,
	} = useFetchData(
		[],
		installationApi.getSpecialInstructions,
		[props.selectedSite.SiteID],
		[props.selectedSite.SiteID, state.specialInstructions]
	);

	const { data: accessGroups, initialFetchComplete: accessGroupsLoaded } =
		useFetchData(
			[],
			installationApi.getOrganizationAccessGroups,
			[props.selectedOrganization.OrganizationID],
			[props.selectedOrganization.OrganizationID]
		);

	const hasEditSettings = usePermissions(null, "EditSettings");

	if (accessGroupsLoaded && state.accessGroups === null) {
		setState((_state) => ({
			..._state,
			accessGroups: accessGroups,
		}));
	}

	if (
		specialInstructionsLoaded &&
		state.accessGroups !== null &&
		!isEqual(state.specialInstructions, specialInstructions)
	) {
		const specialInstructionsData = mapGroupNamesForSpecialInstructions(
			specialInstructions,
			state.accessGroups
		);

		setState((_state) => ({
			..._state,
			specialInstructions: specialInstructions,
			specialInstructionsData: specialInstructionsData,
		}));
	}

	const dragProps = {
		onDragEnd(fromIndex, toIndex) {
			const data = state.specialInstructionsData;
			const item = data.splice(fromIndex, 1)[0];
			data.splice(toIndex, 0, item);
			data.forEach((val, index) => {
				val.InstructionOrder = index + 1;
			});
			updateInstructionOrder(props.selectedSite.SiteID, data);
			setState((_state) => ({
				..._state,
				specialInstructionsData: data,
			}));
		},
		handleSelector: "svg",
		nodeSelector: ".rt-tr-group",
	};

	function openSpecialInstructionWizard(instruction, mode) {
		setState((_state) => ({
			..._state,
			specialInstructionWizardOpen: true,
			specialInstructionWizardInstruction: instruction,
			specialInstructionWizardMode: mode,
		}));
	}

	if (state.specialInstructionWizardOpen) {
		return (
			<SpecialInstructionWizard
				close={() => {
					setState((_state) => ({
						..._state,
						specialInstructionWizardOpen: false,
						specialInstructions: null,
					}));
				}}
				mode={state.specialInstructionWizardMode}
				specialInstruction={state.specialInstructionWizardInstruction}
				accessGroups={state.accessGroups}
				siteId={props.selectedSite.SiteID}
			/>
		);
	}

	return (
		<Card>
			{hasEditSettings && (
				<div style={{ textAlign: "right", margin: "10px 0px 50px 0px" }}>
					<Button
						color="blue"
						onClick={() =>
							openSpecialInstructionWizard({}, "add-special-instruction")
						}
					>
						<Plus style={{ marginRight: 4, verticalAlign: "sub" }} size={20} />
						Add special Instruction
					</Button>
				</div>
			)}
			<ReactDragListView {...dragProps}>
				<TableLayout
					columns={columns({
						openSpecialInstructionWizard,
						hasEditSettings,
					})}
					showPagination={false}
					data={state.specialInstructionsData}
					sortable={false}
					sorted={[
						{
							id: "InstructionOrder",
						},
					]}
				/>
			</ReactDragListView>
		</Card>
	);
}
