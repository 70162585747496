import React from "react";
import { colours } from "../../styles";
import styled from "styled-components";

const LargeWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	min-height: 140px;
	padding: 8px;
	position: relative;
`;

const LargeTitle = styled.div`
	color: ${colours.darkGrey};
	font-size: 20px;
	font-weight: 700;
	line-height: 1.3em;
	min-height: 1.3em;
`;

const LargeSubTitle = styled.div`
	color: ${colours.midGrey};
	font-size: 16px;
	font-weight: 600;
	line-height: 1.3em;
	min-height: 1.3em;
`;

const LargeValue = styled.div`
	color: ${(props) => props.color || colours.blue};
	font-size: 64px;
	font-weight: 600;
	line-height: 1em;
	margin-top: auto;
`;

const MediumWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	min-height: 100px;
	position: relative;
`;

const MediumTitle = styled.div`
	color: ${colours.darkGrey};
	font-size: 18px;
	font-weight: 700;
	line-height: 1em;
	margin-top: 0.2em;
`;

const MediumSubTitle = styled.div`
	color: ${colours.midGrey};
	font-size: 14px;
	font-weight: 600;
	line-height: 1.3em;
	min-height: 1.3em;
`;

const MediumValue = styled.div`
	color: ${(props) => props.color || colours.green};
	font-size: 44px;
	font-weight: 600;
	line-height: 1.3em;
	margin-top: auto;
	min-height: 1.3em;
`;

const SmallWrapper = styled.div`
	padding: 8px;
	position: relative;
`;

const SmallTitle = styled.div`
	color: ${colours.darkGrey};
	font-size: 14px;
	font-weight: 600;
	line-height: 1.3em;
	min-height: 1.3em;
	text-align: right;
	text-transform: uppercase;
`;

const SmallValue = styled.div`
	color: ${(props) => props.color || colours.green};
	font-size: 28px;
	font-weight: 600;
	line-height: 1em;
	margin-top: 0.2em;
	text-align: right;
`;

const MiniWrapper = styled.div`
	padding: 8px;
	position: relative;
`;

const MiniTitle = styled.div`
	color: ${colours.midGrey};
	font-size: 12px;
	font-weight: 600;
	line-height: 1.3em;
	min-height: 1.3em;
	text-align: right;
	text-transform: uppercase;
`;

const MiniValue = styled.div`
	color: ${(props) => props.color || colours.green};
	font-size: 24px;
	font-weight: 600;
	line-height: 1em;
	margin-bottom: 0.2em;
	text-align: right;
	white-space: nowrap;
`;

const LeftBlock = styled.div`
	display: flex;
	flex-direction: column;
`;

const RightBlock = styled.div``;

export default function StatCard(props) {
	if (props.size === "large") {
		return (
			<LargeWrapper>
				<LeftBlock>
					<LargeTitle>{props.title}</LargeTitle>
					<LargeSubTitle>{props.subTitle}</LargeSubTitle>
					<LargeValue color={props.color}>{props.value}</LargeValue>
				</LeftBlock>
				<RightBlock>{props.children}</RightBlock>
			</LargeWrapper>
		);
	}

	if (props.size === "small") {
		return (
			<SmallWrapper>
				<SmallTitle>{props.title}</SmallTitle>
				<SmallValue color={props.color}>{props.value}</SmallValue>
			</SmallWrapper>
		);
	}

	if (props.size === "mini") {
		return (
			<MiniWrapper>
				<MiniValue color={props.color}>{props.value}</MiniValue>
				<MiniTitle>{props.title}</MiniTitle>
			</MiniWrapper>
		);
	}

	return (
		<MediumWrapper>
			<LeftBlock>
				<MediumTitle>{props.title}</MediumTitle>
				<MediumSubTitle>{props.subTitle}</MediumSubTitle>
				<MediumValue color={props.color}>{props.value}</MediumValue>
			</LeftBlock>
			<RightBlock>{props.children}</RightBlock>
		</MediumWrapper>
	);
}
