import React, { useState } from "react";
import { useQueryData, useMutateData } from "../../hooks";
import Card from "../../components/layout/Card";
import FlexWrapper from "../../components/layout/FlexWrapper";
import StatCard from "../../components/layout/StatCard";
import moment from "moment";
import gql from "graphql-tag";
import {
	OFFENCE_EVENTS,
	OFFENCE_EVENTS_TRANSLATIONS,
	PAGINATION_EVENTS,
} from "../../helpers/constants";
import ReportWrapperBeta from "../../components/report/ReportWrapperBeta";
import Alert from "react-s-alert";
import { Pie, PieChart } from "recharts";
// import Input from "../../components/layout/Input";
import DropdownMenuBeta from "../../components/layout/DropdownMenuBeta";
import { MoreHorizontal } from "react-feather";
import EnforcementViolationDetails from "../../components/details-pages/enforcement/EnforcementViolationDetails";

const PIE_COLORS = ["#ffbd2d", "#ed764d", "#b22418", "#450c07"];

const columns = (props) => [
	{
		id: "EnforcementViolationID",
		Header: "ID",
		accessor: (d) => d.EnforcementViolationID,
		highlightCell: (cellProps) => cellProps.row.original.EnforcementViolationID,
	},
	{
		id: "PlateText",
		Header: "Plate",
		accessor: (d) => d.Numberplate?.Numberplate,
		highlightCell: (cellProps) =>
			cellProps.row.original.Numberplate?.Numberplate,
	},
	{
		id: "SiteName",
		Header: "Site",
		accessor: (d) => d.Site?.Name,
		highlightCell: (cellProps) => cellProps.row.original.Site?.Name,
	},
	{
		id: "OffenceEvent",
		Header: "Type",
		accessor: (d) => d.EnforcementRule.OffenceEvent,
		highlightCell: (cellProps) =>
			OFFENCE_EVENTS_TRANSLATIONS[
				cellProps.row.original.EnforcementRule.OffenceEvent
			],
	},
	{
		id: "CreatedOn",
		Header: "Timestamp",
		accessor: (d) => d.CreatedOn,
		highlightCell: (cellProps) =>
			moment(cellProps.row.original.CreatedOn, "X").format(
				"HH:mm on DD MMM YYYY"
			),
	},
	{
		id: "Snapshot",
		Header: "Snapshot (Entry and Exit)",
		Cell: (cellProps) => (
			<div>
				<img
					style={{ maxHeight: "36px", maxWidth: "84px" }}
					src={cellProps.row.original.EntryCameraEvent.PatchImageUrl}
				/>
				<img
					style={{ marginLeft: "6px", maxHeight: "36px", maxWidth: "84px" }}
					src={cellProps.row.original.ExitCameraEvent.PatchImageUrl}
				/>
			</div>
		),
		disableSort: true,
	},
	{
		id: "tasks",
		Header: "",
		accessor: null,
		Cell: (cellProps) => (
			<DropdownMenuBeta
				triggerContent={<MoreHorizontal size={24} />}
				items={[
					<div
						key="userDetails"
						onClick={() =>
							props.openViolationsDetails(
								cellProps.row.original.EnforcementViolationID
							)
						}
					>
						More Info
					</div>,
				]}
			/>
		),
		resizable: false,
		width: 50,
		disableSort: true,
	},
];

const defaultSorting = [
	{
		id: "EnforcementViolationID",
		desc: false,
	},
];

export default function EnforcementContainer(props) {
	const [state, setState] = useState({
		options: {
			sites: [],
			"start-date-time": null,
			"end-date-time": null,
			start: null,
			end: null,
		},
		search: "",
		pageEvent: PAGINATION_EVENTS.INIT,
		pageConnection: null,
		pageSize: 10,
		sortColumn: "EnforcementViolationID",
		sortOrder: "asc",
		offenceEvents: Object.values(OFFENCE_EVENTS),
		fuzzyDistance: 0,
		selectedViolationId: null,
	});

	const organizationId = props.selectedOrganization
		? props.selectedOrganization.OrganizationID
		: null;

	async function updateOptions(options) {
		setState((_state) => ({ ..._state, options }));
	}

	const bulkDownloadReport = useMutateData(gql`
		mutation ($args: BulkDownloadReportInput!) {
			bulkDownloadEnforcementViolationsReport(args: $args)
		}
	`);

	const {
		data: { getEnforcementViolationsPaginated: violationData },
		isLoading,
	} = useQueryData(
		gql`
			query (
				$page: Page!
				$search: String
				$fuzzyDistance: Int
				$offenceEvents: [String!]!
				$organizationId: Int!
				$siteIds: [Int!]!
				$startTimestamp: Int!
				$endTimestamp: Int!
			) {
				getEnforcementViolationsPaginated(
					page: $page
					search: $search
					fuzzyDistance: $fuzzyDistance
					offenceEvents: $offenceEvents
					organizationId: $organizationId
					siteIds: $siteIds
					startTimestamp: $startTimestamp
					endTimestamp: $endTimestamp
				) {
					connection
					pageIndex
					violations {
						EnforcementViolationID
						CreatedOn
						EnforcementRule {
							OffenceEvent
						}
						Numberplate {
							NumberplateID
							Numberplate
						}
						Site {
							Name
						}
						EntryCameraEvent {
							PatchImageUrl
						}
						ExitCameraEvent {
							PatchImageUrl
						}
					}
					totalViolations
					repeatOffenders
					violationsByOffence {
						OffenceEvent
						Amount
					}
				}
			}
		`,
		{
			page: {
				connection: state.pageConnection,
				event: state.pageEvent,
				size: state.pageSize,
				order: state.sortOrder,
				orderBy: state.sortColumn,
				goto: state.goto,
			},
			search: state.search,
			fuzzyDistance: state.fuzzyDistance,
			offenceEvents: state.offenceEvents,
			organizationId,
			siteIds: state.options.sites,
			startTimestamp: moment(state.options["start-date-time"])
				.startOf("day")
				.unix(),
			endTimestamp: moment(state.options["end-date-time"]).endOf("day").unix(),
		},
		!organizationId ||
			!state.options["start-date-time"] ||
			!state.options["end-date-time"]
	);

	if (state.selectedViolationId) {
		return (
			<EnforcementViolationDetails
				close={() => {
					setState((_state) => ({ ..._state, selectedViolationId: null }));
				}}
				enforcementViolationId={state.selectedViolationId}
			/>
		);
	}

	return (
		<div>
			<ReportWrapperBeta
				{...props}
				title="Enforcement Violations"
				data={violationData?.violations ?? []}
				availableSites={props.availableSites}
				columns={columns({
					openViolationsDetails: (id) =>
						setState((_state) => ({ ..._state, selectedViolationId: id })),
				})}
				defaultSortBy={defaultSorting}
				updateOptions={updateOptions}
				loading={isLoading}
				searchTokens={state.search}
				paginateV2
				pageSize={state.pageSize}
				pageIndex={violationData?.pageIndex ?? 0}
				totalRecords={violationData?.totalViolations}
				onChange={(event, data) => {
					setState((_state) => ({
						..._state,
						pageEvent: event,
						pageConnection: violationData?.connection,
						sortOrder: data.order ?? _state.sortOrder,
						sortColumn: data.column ?? _state.sortColumn,
						pageSize: data.pageSize ?? _state.pageSize,
						goto: data.goto,
						search: data.search ?? _state.search,
					}));
				}}
				stats={
					<div>
						<FlexWrapper style={{ marginBottom: -16 }}>
							<Card>
								<StatCard
									value={violationData?.totalViolations || 0}
									title="Total Violation Events"
									subTitle="Within Selected Dates"
									size="medium"
								/>
							</Card>
							<Card>
								<StatCard
									value={
										<PieChart width={484} height={180}>
											<Pie
												data={
													violationData?.violationsByOffence?.map(
														(entry, index) => ({
															name: OFFENCE_EVENTS_TRANSLATIONS[
																entry.OffenceEvent
															],
															value: entry.Amount,
															fill: PIE_COLORS[index % PIE_COLORS.length],
														})
													) ?? []
												}
												outerRadius={50}
												fill="#8884d8"
												label={(entry) => entry.name}
											/>
										</PieChart>
									}
									title="Violations by Type"
									subTitle="Within Selected Dates"
									size="medium"
								/>
							</Card>
							<Card>
								<StatCard
									value={violationData?.repeatOffenders || 0}
									title="Repeat Offenders"
									subTitle="Within Selected Dates"
									size="medium"
								/>
							</Card>
						</FlexWrapper>
					</div>
				}
				onBulkDownloadClicked={async () => {
					try {
						await bulkDownloadReport({
							variables: {
								args: {
									search: state.search,
									organizationId: props.selectedOrganization?.OrganizationID,
									siteIds: state.options.sites,
									startDateTime: moment(state.options["start-date-time"])
										.startOf("day")
										.format(),
									endDateTime: moment(state.options["end-date-time"])
										.endOf("day")
										.format(),
									offenceEvents: state.offenceEvents,
								},
							},
						});

						Alert.success("The report will be sent to your email shortly");
					} catch (error) {
						Alert.error("Something went wrong please try again");
					}
				}}
				filters={[
					{
						name: "Violation Types",
						options: [
							{ label: "Not Paid", value: OFFENCE_EVENTS.NOT_PAID },
							{
								label: "Permit Overstay",
								value: OFFENCE_EVENTS.PERMIT_OVERSTAY,
							},
							{
								label: "Parking Overstay",
								value: OFFENCE_EVENTS.PARKING_OVERSTAY,
							},
							{
								label: "Not Allowed on Location",
								value: OFFENCE_EVENTS.NOT_ALLOWED_ON_LOCATION,
							},
						],
						selected: state.offenceEvents,
						onChange: (o) =>
							setState((_state) => ({
								..._state,
								offenceEvents: o,
								pageEvent: PAGINATION_EVENTS.INIT,
							})),
					},
				]}
				// leftActions={
				// 	<Input
				// 		type="number"
				// 		min={0}
				// 		max={5}
				// 		useLabel=""
				// 		onChange={(event) =>
				// 			setState((_state) => ({
				// 				..._state,
				// 				fuzzyDistance: Number(event.target.value),
				// 				pageEvent: PAGINATION_EVENTS.SEARCH,
				// 			}))
				// 		}
				// 		value={state.fuzzyDistance}
				// 		style={{
				// 			width: "56px",
				// 			height: "40px",
				// 			background: "white",
				// 			marginLeft: "4px",
				// 			paddingLeft: "8px",
				// 			paddingRight: "8px",
				// 			borderRadius: "4px",
				// 			border: "none",
				// 			outline: "none",
				// 		}}
				// 	/>
				// }
			/>
		</div>
	);
}
