import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 32px;
	width: 100%;

	> div {
		display: flex;

		> * {
			margin: 0 8px;

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
`;

export default function WizardNavigation(props) {
	return (
		<Wrapper>
			<div>{props.leftItems}</div>
			<div>{props.rightItems}</div>
		</Wrapper>
	);
}
