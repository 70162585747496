import { feeCalculationStep, flatRate } from "../steps";
import { default as advancedStep } from "../steps/advanced";
import { default as oneOffScheduleStep } from "../steps/oneOff";
import { default as scheduleStep } from "../steps/schedule";
import { default as standardRateStep } from "../steps/standardRate";
import { default as variedRateStep } from "../steps/variedRate";
import { default as eventSeriesStep } from "../steps/eventSeries";

/**
 * @param {Object} values - All values collected by the Wizard
 * @param {Function} setFieldValue - Sets the Formik field valiue
 * @param {Function} goTo - Go to step
 * @param {Object} wizardProps - All props for the Wizard
 * @param {Function} previous - Go to previous step
 */
export default function ({
	values,
	setFieldValue,
	goTo,
	wizardProps,
	previous,
	keyStrokeHandler,
}) {
	const rules = [
		{
			allowed: [
				"Normal",
				"Daytime",
				"AfterHours",
				"EarlyBird",
				"AfterhoursEarlyBird",
			],
			value: values.type.value,
			step: scheduleStep({
				values,
				setFieldValue,
				goTo,
				wizardProps,
				previous,
				keyStrokeHandler,
			}),
		},
		{
			allowed: ["Event"],
			value: values.type.value,
			step: oneOffScheduleStep({
				values,
				setFieldValue,
				goTo,
				wizardProps,
				previous,
				keyStrokeHandler,
			}),
		},
		{
			allowed: ["EventSeries"],
			value: values.type.value,
			step: eventSeriesStep({
				values,
				setFieldValue,
				goTo,
				wizardProps,
				previous,
				keyStrokeHandler,
			}),
		},
		{
			allowed: ["Complex"],
			value: values.type.value,
			step: advancedStep({
				values,
				setFieldValue,
				goTo,
				wizardProps,
				previous,
				keyStrokeHandler,
			}),
		},
		{
			all: true,
			value: values.type.value,
			step: feeCalculationStep({
				values,
				setFieldValue,
				goTo,
				wizardProps,
				previous,
				keyStrokeHandler,
			}),
		},
		{
			allowed: ["Normal"],
			value: values.feeCalculationType,
			step: standardRateStep({
				values,
				setFieldValue,
				goTo,
				wizardProps,
				previous,
				keyStrokeHandler,
			}),
		},
		{
			allowed: ["Varied"],
			value: values.feeCalculationType,
			step: variedRateStep({
				values,
				setFieldValue,
				goTo,
				wizardProps,
				previous,
				keyStrokeHandler,
			}),
		},
		{
			allowed: ["Normal"],
			value: values.feeCalculationType,
			step: flatRate({
				values,
				setFieldValue,
				goTo,
				wizardProps,
				previous,
				keyStrokeHandler,
			}),
		},
	];

	return {
		execute: () =>
			rules.filter((rule) => rule.all || rule.allowed.includes(rule.value)),
	};
}
