import MultiChoiceButton from "../../MultiChoiceButton";
import React from "react";
import { StepTitle } from "../../WizardLayout";
import footer from "./footer";

export default ({
		id,
		label,
		title,
		choices,
		next,
		validator,
		extra,
		summary,
		edit,
	}) =>
	({
		values,
		setFieldValue,
		goTo,
		wizardProps,
		previous,
		keyStrokeHandler,
	}) => {
		const isValid = validator ? validator(values) : values[id];
		return {
			id: id,
			label: label,
			summary: summary
				? {
						value: typeof summary === "function" ? summary(values) : summary,
						edit: () => edit(setFieldValue, goTo),
				  }
				: null,
			render: () => (
				<React.Fragment>
					<div>
						<StepTitle>
							{typeof title === "function" ? title(values) : title}
						</StepTitle>
						{choices.map((choice) => (
							<MultiChoiceButton
								key={choice.value}
								selected={values[id] === choice.value}
								onClick={() => {
									setFieldValue(id, choice.value);
								}}
							>
								<div>{choice.label}</div>
							</MultiChoiceButton>
						))}
					</div>
					{extra && <div>{extra(values, wizardProps, setFieldValue)}</div>}
				</React.Fragment>
			),
			footer: () =>
				footer(
					values,
					previous,
					goTo,
					next,
					wizardProps,
					isValid,
					false,
					setFieldValue,
					false,
					keyStrokeHandler
				),
			isValid: isValid,
		};
	};
