import React from "react";
import Dropdown from "../../../../layout/Dropdown";
import { Label, FieldContent } from "../../styles";
import { useQueryData } from "../../../../../hooks";
import gql from "graphql-tag";
import FieldError from "../../../../layout/FieldError";

export const Branding = ({ orgId, selectedBranding, onChange, error }) => {
	const {
		data: { getOrganizationById },
		isLoading,
	} = useQueryData(
		gql`
			query ($organizationId: Int!) {
				getOrganizationById(organizationId: $organizationId) {
					Brands {
						BrandID
						LogoDark
						CompanyName
						MainColor
						IconLogo
						SecondaryColor
					}
				}
			}
		`,
		{ organizationId: orgId }
	);

	const brandings = getOrganizationById?.Brands.map((b) => ({
		value: b.BrandID,
		label: b.CompanyName,
	}));

	return (
		<div>
			<Label>Branding</Label>

			{isLoading ? (
				<div>Loading Brandings...</div>
			) : (
				<>
					<FieldContent>
						<Dropdown
							options={brandings}
							value={selectedBranding}
							onChange={onChange}
						/>
					</FieldContent>
					<FieldError showError={error} errorText={error} />
				</>
			)}
		</div>
	);
};
