import * as Yup from "yup";
import * as installationApi from "../../api/installation";
import { Check, X } from "react-feather";
import React, { useState } from "react";
import { cloneDeep, debounce } from "lodash";
import PropTypes from "prop-types";
import SearchBox from "../layout/SearchBox";
import styled from "styled-components";

const Wrapper = styled.div`
	position: relative;
`;

const Status = styled.div`
	position: absolute;
	right: 8px;
	top: 8px;
`;

BeaconLookup.propTypes = {
	uniqueId: PropTypes.string.isRequired,
	organizationId: PropTypes.number,
	siteId: PropTypes.number,
	mode: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};

BeaconLookup.defaultProps = {
	onChange: () => {},
};

const schema = Yup.object().shape({
	uniqueId: Yup.string().required("Please supply a Unique ID."),
});

let globalTimestamp = Date.now();

function BeaconLookup(props) {
	const [state, setState] = useState({
		uniqueId: props.uniqueId || "",
		uniqueIdValid: true,
		loading: false,
	});

	async function onValueChange(value) {
		const uniqueId = cloneDeep(value);

		setState((_state) => ({
			..._state,
			uniqueId: uniqueId,
		}));

		const isValid = await schema.isValid({ uniqueId: uniqueId });

		if (isValid) {
			lookupBeaconID(uniqueId);
		} else {
			setState((_state) => ({ ..._state, uniqueIdValid: false }));

			props.onChange({
				uniqueId: uniqueId,
				uniqueIdValid: false,
			});
		}
	}

	const lookupBeaconID = debounce(async (uniqueId) => {
		// set a timestamp to prevent older lookups being applied
		const timestamp = Date.now();
		globalTimestamp = timestamp;

		setState((_state) => ({ ..._state, loading: true }));

		try {
			let response = {};
			let valid = false;
			let duplicate = false;

			if (props.mode === "validationBeacon") {
				response = await installationApi.checkValidationBeaconId(
					props.organizationId,
					uniqueId
				);

				valid = response.valid && !response.inUse;
				duplicate = response.inUse;
			}

			if (props.mode === "beaconSite") {
				response = await installationApi.getBeaconDataByUniqueId(
					props.siteId,
					uniqueId
				);

				valid = response.isValid;
				duplicate = response.isDuplicate;
			}

			if (props.mode === "adminBeacon") {
				response = await installationApi.validateBeaconId(uniqueId);

				valid = response.valid;
				duplicate = response.duplicate;
			}

			setTimeout(() => {
				if (globalTimestamp === timestamp) {
					setState((_state) => ({
						..._state,
						loading: false,
						uniqueIdValid: valid,
						duplicate: duplicate,
					}));

					props.onChange({
						uniqueId: uniqueId,
						uniqueIdValid: valid,
						uniqueIdDuplicate: duplicate,
					});
				}
			}, 1000);
		} catch (error) {
			setTimeout(() => {
				if (globalTimestamp === timestamp) {
					setState((_state) => ({
						..._state,
						loading: false,
						uniqueIdValid: false,
					}));

					props.onChange({
						uniqueId: null,
						uniqueIdValid: false,
					});
				}
			}, 1000);
		}
	}, 500);

	return (
		<Wrapper>
			<SearchBox
				placeholder="Unique ID"
				onChange={(value) => {
					onValueChange(value.trim());
				}}
				value={state.uniqueId}
				showLoader={state.loading}
			/>
			{!state.loading &&
				state.uniqueId !== "" &&
				(state.uniqueIdValid ? (
					<Status>
						<Check color="green" />
					</Status>
				) : (
					<Status>
						<X color="red" />
					</Status>
				))}
		</Wrapper>
	);
}

export default BeaconLookup;
