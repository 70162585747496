import * as installationApi from "../../../api/installation";
import * as sessionApi from "../../../api/session";
import React, { useContext, useState } from "react";
import { StepText, StepTitle } from "../WizardLayout";
import { filter, map } from "lodash";
import { useFetchData, useLazyQueryData } from "../../../hooks";
import { AppContext } from "../../../context/app-context";
import Button from "../../layout/Button";
import DatePicker from "../../layout/DatePicker";
import Dropdown from "../../layout/Dropdown";
import EditableInputField from "../../layout/EditableInputField";
import EmailLookup from "../EmailLookup";
import MultiChoiceButton from "../MultiChoiceButton";
import WizardNavigation from "../WizardNavigation";
import { colours } from "../../../styles";
import { format } from "../../../helpers";
import gql from "graphql-tag";
import moment from "moment";
import styled from "styled-components";

function GatePicker(props) {
	const [state, setState] = useState({
		selectedGate: props.selectedGate,
	});

	const { data: nodes, isLoading } = useFetchData(
		[],
		installationApi.getNodesForSite,
		[props.siteId],
		[props.siteId]
	);

	const nodesOption = map(
		filter(nodes, (node) => node.IsEnabled),
		(e) => ({
			value: e.NodeID,
			label: e.Name || e.SerialNumber,
		})
	);

	function onGateChange(value) {
		setState((_state) => ({ ..._state, selectedGate: value }));
		props.onChange({
			selectedGate: value,
		});
	}

	return (
		<div>
			<Dropdown
				isMulti={false}
				loading={isLoading}
				options={nodesOption}
				value={state.selectedGate}
				onChange={onGateChange}
			/>
		</div>
	);
}

const ActiveSessionWarning = styled.div`
	margin: 30px 0 0 0;
	padding: 5px 15px;
	border-radius: 3px;
	width: 100%;
	height: 60px;
	background-color: ${colours.red};
	color: ${colours.white};
`;

function HasActiveSession(props) {
	const { data } = useFetchData(
		{},
		sessionApi.getCurrentActiveSessionByUser,
		[props.user.UserID, props.siteId],
		[props.user.UserID, props.siteId]
	);

	return data.parkingSession ? (
		<ActiveSessionWarning>
			This user is already in a parking session. <br /> Starting this parking
			session will close the current session.
		</ActiveSessionWarning>
	) : null;
}

function OrgAndSite(props) {
	const {
		state: { availableOrganizations },
	} = useContext(AppContext);

	const [state, setState] = useState({
		selectedOrganization: null,
		selectSite: null,
	});

	const organizationOption = availableOrganizations.map((e) => ({
		value: e.OrganizationID,
		label: e.Name,
	}));

	const { data: sites } = useFetchData(
		[],
		state.selectedOrganization ? installationApi.getSitesForOrganization : null,
		state.selectedOrganization ? [state.selectedOrganization.value] : null,
		[state.selectedOrganization]
	);

	const _sites = filter(
		sites,
		(site) =>
			site.HasCasualParking &&
			!site.HasSessionlessAccess &&
			site.SiteType === "Gated"
	);

	const siteOption = map(_sites, (site) => ({
		value: site.SiteID,
		label: site.Name,
	}));

	return (
		<div>
			<StepTitle>Please choose an organization</StepTitle>
			<Dropdown
				isMulti={false}
				options={organizationOption}
				value={state.selectedOrganization}
				onChange={(newValues) => {
					setState((_state) => ({
						..._state,
						selectedSite: null,
						selectedOrganization: newValues,
					}));
				}}
			/>
			<StepTitle>Please choose a site</StepTitle>
			<Dropdown
				isMulti={false}
				options={siteOption}
				value={state.selectedSite}
				onChange={(newValues) => {
					setState((_state) => ({ ..._state, selectedSite: newValues }));
					props.setFieldValue("selectedSite", newValues);
				}}
			/>
		</div>
	);
}

export const enterUserStep = ({
	values,
	setFieldValue,
	handleSubmit,
	isSubmitting,
	previous,
	keyStrokeHandler,
}) => ({
	id: "enter-user",
	label: "User",
	render: () => (
		<>
			<div>
				<StepTitle>What is the email address of the user?</StepTitle>
				<EmailLookup
					email={values.email}
					user={values.user}
					allowNew={false}
					onChange={(value) => {
						if (value.user && value.user.FirstName && value.user.LastName) {
							value.user.FirstName = value.user.FirstName.trim();
							value.user.LastName = value.user.LastName.trim();
						}

						setFieldValue("email", value.email);
						setFieldValue("user", value.user);

						if (value.user && value.user.UserID) {
							setFieldValue("userExists", true);
						} else {
							setFieldValue("userExists", false);
						}
					}}
				/>
			</div>
			{values.user && (
				<HasActiveSession
					user={values.user}
					siteId={values.selectedSite.value}
				/>
			)}
		</>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="previous" onClick={previous} color="blue">
					Back
				</Button>,
			]}
			rightItems={
				values.userExists && [
					<Button
						key="submit"
						color="green"
						onClick={handleSubmit}
						disabled={isSubmitting}
						keyStrokeHandler={keyStrokeHandler}
					>
						Start Session
					</Button>,
				]
			}
		/>
	),
});

export const chooseSiteStep = ({
	values,
	setFieldValue,
	next,
	wizardProps,
	handleSubmit,
	isSubmitting,
	keyStrokeHandler,
}) => ({
	id: "choose-site",
	label: "Site",
	render: () => {
		const siteOption = wizardProps.sites.map((e) => ({
			value: e.SiteID,
			label: e.Name,
		}));

		return (
			<div>
				<StepTitle>Please choose a site</StepTitle>
				<Dropdown
					isMulti={false}
					options={siteOption}
					value={values.selectedSite}
					onChange={(newValues) => {
						setFieldValue("selectedSite", newValues);
					}}
				/>
				{values.mode === "start-session-for-user" && values.selectedSite && (
					<HasActiveSession
						user={values.user}
						siteId={values.selectedSite.value}
					/>
				)}
			</div>
		);
	},
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={
				values.mode === "start-session" || values.mode === "open-gate"
					? [
							<Button
								key="next"
								color="blue"
								onClick={next}
								disabled={!values.selectedSite}
								keyStrokeHandler={keyStrokeHandler}
							>
								Next
							</Button>,
					  ]
					: [
							<Button
								key="submit"
								color="green"
								onClick={handleSubmit}
								disabled={isSubmitting || !values.selectedSite}
								keyStrokeHandler={keyStrokeHandler}
							>
								Start Session
							</Button>,
					  ]
			}
		/>
	),
});

export const chooseOrgAndSiteStep = ({
	values,
	setFieldValue,
	next,
	wizardProps,
	handleSubmit,
	isSubmitting,
	keyStrokeHandler,
}) => ({
	id: "choose-site",
	label: "Site",
	render: () => (
		<div>
			<OrgAndSite setFieldValue={setFieldValue} />
			{values.mode === "start-session-for-user" && values.selectedSite && (
				<HasActiveSession
					user={values.user}
					siteId={values.selectedSite.value}
				/>
			)}
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={
				values.mode === "start-session" || values.mode === "open-gate"
					? [
							<Button
								key="next"
								color="blue"
								onClick={next}
								disabled={!values.selectedSite}
								keyStrokeHandler={keyStrokeHandler}
							>
								Next
							</Button>,
					  ]
					: [
							<Button
								key="submit"
								color="green"
								onClick={handleSubmit}
								disabled={isSubmitting || !values.selectedSite}
								keyStrokeHandler={keyStrokeHandler}
							>
								Start Session
							</Button>,
					  ]
			}
		/>
	),
});

const ChargeWrapper = styled.div`
	float: right;
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: center;
	width: 350px;
	flex-wrap: wrap;
	align-content: flex-start;
`;

const ChargeTextWrapper = styled.div`
	background-color: #ffffff;
	padding: 8px;
	margin-right: 15px;
	border-radius: 5px;
`;

function PartialChargeSelector({ setFieldValue, session, values }) {
	const getPartialSessionFee = useLazyQueryData(gql`
		query ($endTimestamp: Timestamp!, $parkingSessionId: Int!) {
			getPartialSessionFee(
				endTimestamp: $endTimestamp
				parkingSessionId: $parkingSessionId
			)
		}
	`);

	const [state, setState] = useState();

	return (
		<ChargeWrapper>
			<ChargeTextWrapper>
				<span>End Time:</span>
			</ChargeTextWrapper>
			<DatePicker
				style={{ width: "220px" }}
				options={{
					pickerMode: "datePicker",
					enableTime: true,
					altFormat: "d M Y h:iK",
					minDate: new Date(session.StartDate * 1000),
					maxDate: new Date(),
					minuteIncrement: 1,
				}}
				value={
					values.manualEndTime ? new Date(values.manualEndTime * 1000) : null
				}
				onChange={async ([date]) => {
					const dateTimestamp = moment(date).unix();
					setFieldValue("manualEndTime", dateTimestamp);
					const { data } = await getPartialSessionFee({
						endTimestamp: dateTimestamp,
						parkingSessionId: session.ParkingSessionID,
					});
					setState(data.getPartialSessionFee);
				}}
			/>
			<ChargeTextWrapper style={{ marginTop: "10px" }}>
				<span>Fee: {format.money(state)}</span>
			</ChargeTextWrapper>
		</ChargeWrapper>
	);
}

export const endSessionStep = ({
	setFieldValue,
	handleSubmit,
	values,
	wizardProps,
	isSubmitting,
	keyStrokeHandler,
}) => ({
	id: "end-session",
	label: "End Session",
	render: () => (
		<div>
			<StepText>
				Are you sure you want to end the session for {values.session.User}? This
				will end the session but not open any gates automatically.
			</StepText>
			<MultiChoiceButton
				selected={values.chargeType === values.chargeEnum.FULL_CHARGE}
				onClick={() => {
					setFieldValue("chargeType", values.chargeEnum.FULL_CHARGE);
				}}
			>
				Charge the user
			</MultiChoiceButton>
			{values.chargeType === values.chargeEnum.PARTIAL_CHARGE && (
				<PartialChargeSelector
					setFieldValue={setFieldValue}
					session={wizardProps.session}
					values={values}
				/>
			)}
			<MultiChoiceButton
				selected={values.chargeType === values.chargeEnum.PARTIAL_CHARGE}
				onClick={() => {
					setFieldValue("chargeType", values.chargeEnum.PARTIAL_CHARGE);
				}}
			>
				Partially charge the user
			</MultiChoiceButton>
			<MultiChoiceButton
				selected={values.chargeType === values.chargeEnum.NO_CHARGE}
				onClick={() => {
					setFieldValue("chargeType", values.chargeEnum.NO_CHARGE);
				}}
			>
				Do not charge the user
			</MultiChoiceButton>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={
						isSubmitting ||
						(values.chargeType === values.chargeEnum.PARTIAL_CHARGE &&
							!values.manualEndTime)
					}
					keyStrokeHandler={keyStrokeHandler}
				>
					End Session
				</Button>,
			]}
		/>
	),
});

export const chooseGateStep = ({
	previous,
	next,
	values,
	setFieldValue,
	keyStrokeHandler,
}) => ({
	id: "choose-gate",
	label: "Gate",
	render: () => (
		<div>
			<StepTitle>Choose a gate</StepTitle>
			<GatePicker
				siteId={values.selectedSite.value}
				selectedGate={values.selectedGate}
				onChange={(newValues) => {
					setFieldValue("selectedGate", newValues.selectedGate);
				}}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="previous" onClick={previous} color="blue">
					Back
				</Button>,
			]}
			rightItems={[
				values.selectedGate && (
					<Button
						key="next"
						color="blue"
						onClick={next}
						keyStrokeHandler={keyStrokeHandler}
					>
						Next
					</Button>
				),
			]}
		/>
	),
});

export const reasonStep = ({
	handleSubmit,
	isSubmitting,
	values,
	previous,
	keyStrokeHandler,
}) => ({
	id: "reason",
	label: "Reason",
	render: () => (
		<div>
			<StepTitle>Provide a reason</StepTitle>
			<EditableInputField
				type="text"
				name="reason"
				useLabel="Reason"
				value={values.reason || ""}
				error={
					values.reason && values.reason.length === 0
						? "Please supply a reason."
						: null
				}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="previous" onClick={previous} color="blue">
					Back
				</Button>,
			]}
			rightItems={[
				values.reason && (
					<Button
						key="submit"
						color="green"
						onClick={handleSubmit}
						disabled={isSubmitting}
						keyStrokeHandler={keyStrokeHandler}
					>
						Open Gate
					</Button>
				),
			]}
		/>
	),
});
