import * as siteBillingHelper from "../../../components/billing/siteBillingHelper";
import {
	CREDIT_CARD_TYPES,
	DEFAULT_CONVENIENCE_FEE,
	PAYMENT_PROCESSING_FIXED_FEE,
	PAYMENT_PROCESSING_PERCENTAGE_FEE,
} from "../../../helpers/constants";
import { StepText, StepTitle } from "../WizardLayout";
import { forEach, includes, isEqual, map, sortBy } from "lodash";
import AlertMessage from "../../layout/AlertMessage";
import Button from "../../layout/Button";
import Dropdown from "../../layout/Dropdown";
import EditableInputField from "../../layout/EditableInputField";
import FeeBlock from "../../../components/billing/FeeBlock";
import Input from "../../layout/Input";
import React from "react";
import SummaryTable from "../SummaryTable";
import Toggle from "../../../components/layout/Toggle";
import WizardNavigation from "../WizardNavigation";

const paymentProviderOptions = [
	{ value: null, label: "None" },
	{ value: "PaymentExpress", label: "Payment Express" },
	{ value: "Stripe", label: "Stripe" },
	{ value: "ADVAM", label: "ADVAM" },
];

const creditCardTypeOptions = [
	{ value: CREDIT_CARD_TYPES.VISA, label: CREDIT_CARD_TYPES.VISA },
	{ value: CREDIT_CARD_TYPES.MASTERCARD, label: CREDIT_CARD_TYPES.MASTERCARD },
	{
		value: CREDIT_CARD_TYPES.AMERICAN_EXPRESS,
		label: CREDIT_CARD_TYPES.AMERICAN_EXPRESS,
	},
	{ value: CREDIT_CARD_TYPES.DISCOVER, label: CREDIT_CARD_TYPES.DISCOVER },
];

const settlementOptions = [
	{ value: null, label: "Non-Inugo" },
	{ value: "NZ", label: "InugoNZ" },
	{ value: "US", label: "InugoUS" },
];

const paidByOptions = [
	{ value: "Operator", label: "Operator" },
	{ value: "Consumer", label: "Consumer" },
];

function restoreSitesMapping(siteIds, sites) {
	if (isEqual(siteIds, [0])) {
		return [{ value: 0, label: "All Sites" }];
	}

	let value = [];

	siteIds.forEach((id) => {
		sites.forEach((e) => {
			if (e.SiteID === id) {
				value.push({ value: id, label: e.Name });
			}
		});
	});

	return value;
}

function resortSiteNames(sites, siteIds) {
	if (isEqual(siteIds, [0])) {
		return "All Sites";
	}

	let names = [];

	forEach(sites, (site) => {
		if (includes(siteIds, site.SiteID)) {
			names.push(site.Name);
		}
	});

	return names.length ? names.join(", ") : "";
}

function siteOverwriteAlert(allSites, selectedSites, currentBillingDetailsId) {
	let show = false;

	let siteNames = [];

	allSites.forEach((site) => {
		if (
			site.BillingDetailsID &&
			site.BillingDetailsID !== currentBillingDetailsId &&
			includes(
				isEqual(selectedSites, [0])
					? allSites.map((_site) => _site.SiteID)
					: selectedSites,
				site.SiteID
			)
		) {
			show = true;
			siteNames.push(site.Name);
		}
	});

	return { shouldShow: show, siteNames: siteNames };
}

function updateConvenienceFeeData(values, setFieldValue) {
	// payment provider switching from none
	if (values.PaymentProvider && values.paymentProviderChanged) {
		setFieldValue(
			"ConvenienceFee",
			DEFAULT_CONVENIENCE_FEE[values.PaymentProvider]
		);
	}
}

function updatePaymentProcessingFeeData(values, setFieldValue) {
	// payment provider switching from none
	if (
		values.PaymentProvider &&
		(values.paymentProviderChanged || values.merchantAccountSettlementChanged)
	) {
		setFieldValue(
			"PaymentProcessingFixedFee",
			values.PaymentProvider === "PaymentExpress"
				? PAYMENT_PROCESSING_FIXED_FEE[values.PaymentProvider][
						values.MerchantAccountSettlement
				  ]
				: PAYMENT_PROCESSING_FIXED_FEE[values.PaymentProvider]
		);

		setFieldValue(
			"PaymentProcessingPercentageFee",
			values.PaymentProvider === "PaymentExpress"
				? PAYMENT_PROCESSING_PERCENTAGE_FEE[values.PaymentProvider][
						values.MerchantAccountSettlement
				  ]
				: PAYMENT_PROCESSING_PERCENTAGE_FEE[values.PaymentProvider]
		);
	}
}

export const companyInfo = ({
	values,
	goTo,
	setFieldValue,
	wizardProps,
	next,
	keyStrokeHandler,
}) => ({
	id: "companyInfo",
	label: "Company Info",
	render: () => (
		<div>
			<StepTitle>Company Info</StepTitle>
			<h3>Company Name</h3>
			<Input
				type="text"
				name="CompanyName"
				onChange={(event) => setFieldValue("CompanyName", event.target.value)}
				value={values.CompanyName || ""}
			/>
			<h3>Tax Registration</h3>
			<Input
				type="text"
				name="TaxRegistration"
				onChange={(event) =>
					setFieldValue("TaxRegistration", event.target.value)
				}
				value={values.TaxRegistration || ""}
			/>
			<h3>Billing Address</h3>
			<Input
				type="text"
				name="BillingAddress"
				onChange={(event) =>
					setFieldValue("BillingAddress", event.target.value)
				}
				value={values.BillingAddress || ""}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="cancel" onClick={wizardProps.close} color="blue">
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					disabled={
						!values.CompanyName ||
						!values.TaxRegistration ||
						!values.BillingAddress
					}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const paymentProvider = ({
	values,
	goTo,
	previous,
	setFieldValue,
	wizardProps,
	next,
	keyStrokeHandler,
}) => ({
	id: "paymentProvider",
	label: "Payment Provider",
	render: () => (
		<div>
			<StepTitle>Payment Provider</StepTitle>
			<Dropdown
				isMulti={false}
				options={paymentProviderOptions}
				value={paymentProviderOptions.find(
					(e) => e.value === values.PaymentProvider
				)}
				onChange={(value) => {
					if (value.value !== values.PaymentProvider) {
						setFieldValue("paymentProviderChanged", true);
					}
					setFieldValue("PaymentProvider", value.value);
				}}
			/>
			{values.PaymentProvider === "Stripe" && (
				<AlertMessage
					borderColor="red"
					backgroundColor="red"
					textColor="white"
					top="24px"
					text={`Stripe is not currently supported. Please use Payment Express instead.`}
				/>
			)}
			{/* Payment Express */}
			{values.PaymentProvider === "PaymentExpress" && (
				<div>
					<h3>PX Post Username</h3>
					<Input
						type="text"
						name="PXPostUsername"
						onChange={(event) => {
							setFieldValue("PXPostUsername", event.target.value);
						}}
						value={values.PXPostUsername || ""}
					/>

					<h3>PX Post Password</h3>
					<Input
						type="text"
						name="PXPostSecretKey"
						onChange={(event) => {
							setFieldValue("PXPostSecretKey", event.target.value);
						}}
						value={values.PXPostSecretKey || ""}
					/>

					<h3>PX Pay Username</h3>
					<Input
						type="text"
						name="PxPayUsername"
						onChange={(event) => {
							setFieldValue("PxPayUsername", event.target.value);
						}}
						value={values.PxPayUsername || ""}
					/>

					<h3>PX Pay Password</h3>
					<Input
						type="text"
						name="PxPaySecretKey"
						onChange={(event) => {
							setFieldValue("PxPaySecretKey", event.target.value);
						}}
						value={values.PxPaySecretKey || ""}
					/>

					<h3>Merchant Account Settlement</h3>

					<Dropdown
						options={settlementOptions}
						value={
							values.MerchantAccountSettlement
								? settlementOptions.find(
										(e) => e.value === values.MerchantAccountSettlement
								  )
								: { value: null, label: "Non-Inugo" }
						}
						onChange={(value) => {
							if (value.value !== values.PaymentProvider) {
								setFieldValue("merchantAccountSettlementChanged", true);
							}
							setFieldValue("MerchantAccountSettlement", value.value);
						}}
					/>
				</div>
			)}
			{/* Stripe */}
			{values.PaymentProvider === "Stripe" && values.billingAccountEmail && (
				<div>
					<h3>Billing Account</h3>

					<h4>Email</h4>
					<EditableInputField
						name="BillingAccountEmail"
						value={values.billingAccountEmail || ""}
						disabled={true}
					/>
					<Button
						color="blue"
						style={{ marginRight: "10px" }}
						onClick={() =>
							siteBillingHelper.testAccountAccessV2(
								values.OrganizationID,
								values.billingAccountDetails.BillingAccountID,
								wizardProps.isAdmin
							)
						}
					>
						Test Account Access
					</Button>
					<Button
						color="red"
						onClick={() => {
							setFieldValue("BillingAccountID", null);
							setFieldValue("billingAccountEmail", null);
						}}
					>
						Remove Account
					</Button>
				</div>
			)}
			{values.PaymentProvider === "Stripe" && !values.billingAccountEmail && (
				<div>
					<Toggle
						label={"New Billing Account"}
						checked={values.userNewBillingAccount}
						onChange={(event) => setFieldValue("userNewBillingAccount", event)}
					/>

					{values.userNewBillingAccount ? (
						<div>
							<h3>Create New Blling Account</h3>

							<h4>Company Name</h4>
							<EditableInputField
								name="CompanyName"
								value={values.CompanyName || ""}
								disabled={true}
							/>

							<h4>Email</h4>
							<EditableInputField
								name="NewBillingEmail"
								onChange={(event) =>
									setFieldValue("newBillngEmail", event.target.value)
								}
								value={values.newBillngEmail || ""}
								useLabel=" "
							/>

							<Button
								color="blue"
								onClick={() =>
									siteBillingHelper.createNewBillingAccount(
										values.newBillngEmail,
										values.CompanyName,
										setFieldValue,
										wizardProps.isAdmin
									)
								}
								style={{ marginTop: "10px" }}
							>
								Create Account
							</Button>
						</div>
					) : (
						<div>
							<h3>Use Existing Inugo Billing Account</h3>
							<h4>Email</h4>
							<EditableInputField
								name="ExistingBillingEmail"
								onChange={(event) =>
									setFieldValue("ExistingBillingEmail", event.target.value)
								}
								value={values.ExistingBillingEmail || ""}
								useLabel=" "
							/>

							<Button
								color="blue"
								onClick={() =>
									siteBillingHelper.addExistingBillingEmail(
										values.ExistingBillingEmail,
										setFieldValue,
										wizardProps.isAdmin
									)
								}
								style={{ marginTop: "10px" }}
							>
								Use Existing Account
							</Button>
						</div>
					)}
				</div>
			)}
			{/* ADVAM */}
			{values.PaymentProvider === "ADVAM" && (
				<div>
					<h3>ADVAM Account ID</h3>
					<Input
						type="text"
						name="ADVAMAccountID"
						onChange={(event) => {
							setFieldValue("ADVAMAccountID", event.target.value);
						}}
						value={values.ADVAMAccountID || ""}
					/>
				</div>
			)}

			{values.PaymentProvider && (
				<div>
					<Toggle
						label={"Default Billing Details"}
						checked={values.IsDefault}
						onChange={(event) => setFieldValue("IsDefault", event)}
					/>
				</div>
			)}
		</div>
	),
	footer: () => {
		const nextStep = "webPay";
		return (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [
						<Button key="back" color="blue" onClick={previous}>
							Back
						</Button>,
					]
				}
				rightItems={[
					<Button
						key="next"
						color="blue"
						onClick={() => {
							if (
								values.editFromSummary &&
								(!values.paymentProviderChanged || !values.PaymentProvider) &&
								!values.merchantAccountSettlementChanged
							) {
								goTo("summary");
							} else if (values.PaymentProvider === "ADVAM") {
								if (values.IsDefault) {
									goTo("summary");
								} else {
									goTo(nextStep);
								}
							} else if (!values.PaymentProvider) {
								goTo(nextStep);
							} else {
								updateConvenienceFeeData(values, setFieldValue);
								next();
							}
						}}
						disabled={
							(values.PaymentProvider === "PaymentExpress" &&
								!(
									(values.PXPostUsername &&
										values.PXPostSecretKey &&
										!values.PxPayUsername &&
										!values.PxPaySecretKey) ||
									(!values.PXPostUsername &&
										!values.PXPostSecretKey &&
										values.PxPayUsername &&
										values.PxPaySecretKey) ||
									(values.PXPostUsername &&
										values.PXPostSecretKey &&
										values.PxPayUsername &&
										values.PxPaySecretKey)
								)) ||
							(values.PaymentProvider === "ADVAM" && !values.ADVAMAccountID) ||
							values.PaymentProvider === "Stripe"
						}
						keyStrokeHandler={keyStrokeHandler}
					>
						{values.editFromSummary &&
						(!values.paymentProviderChanged || !values.PaymentProvider) &&
						!values.merchantAccountSettlementChanged
							? "Review"
							: "Next"}
					</Button>,
				]}
			/>
		);
	},
});

export function acceptedCreditCardTypes({
	values,
	goTo,
	previous,
	setFieldValue,
	next,
	keyStrokeHandler,
}) {
	const acceptedCards = [];
	values.AcceptedCreditCardTypes.forEach((c) => {
		const opt = creditCardTypeOptions.find((x) => x.value === c);
		if (opt) {
			acceptedCards.push(opt);
		}
	});

	return {
		id: "acceptedCreditCardTypes",
		label: "Accepted Credit Card Types",
		render: () => (
			<div>
				<StepTitle>Accepted Credit Card Types</StepTitle>
				<Dropdown
					isMulti={true}
					options={creditCardTypeOptions}
					value={acceptedCards}
					onChange={(value) => {
						setFieldValue(
							"AcceptedCreditCardTypes",
							value.map((c) => c.value)
						);
					}}
				/>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [
						<Button key="back" color="blue" onClick={previous}>
							Back
						</Button>,
					]
				}
				rightItems={[
					<Button
						key="next"
						color="blue"
						onClick={() => {
							if (
								values.editFromSummary &&
								(!values.paymentProviderChanged || !values.PaymentProvider) &&
								!values.merchantAccountSettlementChanged
							) {
								goTo("summary");
							} else {
								next();
							}
						}}
						disabled={!acceptedCards.length}
						keyStrokeHandler={keyStrokeHandler}
					>
						{values.editFromSummary &&
						!values.paymentProviderChanged &&
						!values.merchantAccountSettlementChanged
							? "Review"
							: "Next"}
					</Button>,
				]}
			/>
		),
	};
}

export const convenienceFees = ({
	values,
	goTo,
	previous,
	setFieldValue,
	next,
	keyStrokeHandler,
}) => ({
	id: "convenienceFees",
	label: "Convenience Fees",
	render: () => (
		<div>
			<StepTitle>Convenience Fees</StepTitle>
			{values.ConvenienceFee &&
				values.ConvenienceFee.map((b, key) => (
					<FeeBlock
						key={key}
						isTitle={key === 0}
						isLastBlock={key === 5}
						fee={b.fee}
						max={b.max}
						min={b.min}
						onChange={(value) =>
							siteBillingHelper.handleFeeChange(
								value,
								key,
								values.ConvenienceFee,
								setFieldValue
							)
						}
					/>
				))}
			<h3>Paid By</h3>
			<Dropdown
				options={paidByOptions}
				value={paidByOptions.find(
					(e) => e.value === values.ConvenienceFeePaidBy
				)}
				onChange={(value) => {
					setFieldValue("ConvenienceFeePaidBy", value.value);
				}}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="back" color="blue" onClick={previous}>
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (
							values.editFromSummary &&
							!values.paymentProviderChanged &&
							!values.merchantAccountSettlementChanged
						) {
							goTo("summary");
						} else {
							updatePaymentProcessingFeeData(values, setFieldValue);
							next();
						}
					}}
					disabled={!values.ConvenienceFee || !values.ConvenienceFeePaidBy}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary &&
					!values.paymentProviderChanged &&
					!values.merchantAccountSettlementChanged
						? "Review"
						: "Next"}
				</Button>,
			]}
		/>
	),
});

export const paymentProcessingFees = ({
	values,
	goTo,
	previous,
	setFieldValue,
	next,
	keyStrokeHandler,
}) => ({
	id: "paymentProcessingFees",
	label: "Payment Processing Fees",
	render: () => (
		<div>
			<StepTitle>Payment Processing Fees</StepTitle>
			{!(
				values.PaymentProvider === "PaymentExpress" &&
				!values.MerchantAccountSettlement
			) && (
				<div>
					<h3>Fixed Fee</h3>
					<Input
						type="number"
						min="0"
						name="FixedFee"
						onChange={(event) =>
							setFieldValue("PaymentProcessingFixedFee", event.target.value)
						}
						value={
							values.PaymentProcessingFixedFee === 0
								? 0
								: values.PaymentProcessingFixedFee || ""
						}
					/>

					<h3>Percentage</h3>
					<Input
						type="number"
						min="0"
						name="PercentageFee"
						onChange={(event) =>
							setFieldValue(
								"PaymentProcessingPercentageFee",
								event.target.value
							)
						}
						value={
							values.PaymentProcessingPercentageFee === 0
								? 0
								: values.PaymentProcessingPercentageFee || ""
						}
					/>
				</div>
			)}

			<h3>User Pays Credit Card Fee</h3>
			<Input
				type="number"
				min="0"
				name="UserPaysCreditCardFee"
				onChange={(event) =>
					setFieldValue("UserPaysCreditCardFee", event.target.value)
				}
				value={
					values.UserPaysCreditCardFee === 0
						? 0
						: values.UserPaysCreditCardFee || ""
				}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="back" color="blue" onClick={previous}>
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary || values.IsDefault) {
							goTo("summary");
						} else {
							next();
						}
					}}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const webPay = ({
	values,
	goTo,
	previous,
	setFieldValue,
	next,
	keyStrokeHandler,
}) => {
	const checkIncomplete = () => {
		if (values.WebPayEnabled) {
			return (
				!values.brand || !values.siteType || values.webPayTypes.length === 0
			);
		}

		return false;
	};

	return {
		id: "webPay",
		label: "QR Code",
		render: () => (
			<div>
				<StepTitle>QR Code</StepTitle>
				<Toggle
					label="Enabled"
					checked={values.WebPayEnabled}
					onChange={(value) => setFieldValue("WebPayEnabled", value)}
				/>

				{values.WebPayEnabled && (
					<>
						<h3>Branding</h3>
						{!values.brands ? (
							<AlertMessage
								borderColor="red"
								backgroundColor="red"
								textColor="white"
								top="24px"
								text={`No brands available. Please add a brand first.`}
							/>
						) : (
							<Dropdown
								options={values.brands}
								value={values.brand}
								onChange={(value) => {
									setFieldValue("brand", value);
								}}
							/>
						)}

						<h3>Domain(s)</h3>
						<Dropdown
							isMulti={true}
							options={values.allWebPayTypes}
							value={values.webPayTypes}
							onChange={(value) => {
								setFieldValue("webPayTypes", value);
							}}
						/>

						<h3>Site Type</h3>
						<Dropdown
							options={values.allSiteTypes}
							value={values.siteType}
							onChange={(value) => {
								setFieldValue("siteType", value);
							}}
						/>
					</>
				)}
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [
						<Button key="back" color="blue" onClick={previous}>
							Back
						</Button>,
					]
				}
				rightItems={[
					<Button
						key="next"
						color="blue"
						disabled={checkIncomplete()}
						onClick={() => {
							if (values.editFromSummary || values.IsDefault) {
								goTo("summary");
							} else {
								next();
							}
						}}
						keyStrokeHandler={keyStrokeHandler}
					>
						{values.editFromSummary ? "Review" : "Next"}
					</Button>,
				]}
			/>
		),
	};
};

export const chooseSites = ({
	values,
	goTo,
	previous,
	setFieldValue,
	wizardProps,
	next,
	keyStrokeHandler,
}) => ({
	id: "sites",
	label: "Sites",
	render: () => {
		let siteAlert = siteOverwriteAlert(
			wizardProps.sites,
			values.siteIds,
			values.BillingDetailsID
		);
		return (
			<div>
				<StepTitle>Please choose the site(s) that use this billing</StepTitle>
				<Dropdown
					isMulti={true}
					options={[{ value: 0, label: "All Sites" }].concat(
						sortBy(
							wizardProps.sites
								.filter((site) => !site.IsDeactivated)
								.map((e) => ({
									value: e.SiteID,
									label: e.Name,
								})),
							(site) => site.label.toLowerCase()
						)
					)}
					value={restoreSitesMapping(values.siteIds, wizardProps.sites)}
					onChange={(value) => {
						let newSiteIds = map(value, (v) => v.value);

						if (isEqual(values.siteIds, [0])) {
							newSiteIds = newSiteIds.filter((id) => id !== 0);
						} else if (includes(newSiteIds, 0)) {
							newSiteIds = [0];
						}

						setFieldValue("siteIds", newSiteIds);
					}}
				/>
				{siteAlert.shouldShow && (
					<AlertMessage
						borderColor="yellow"
						backgroundColor="yellow"
						textColor="white"
						top="30px"
						text={`${siteAlert.siteNames.join(", ")} ${
							siteAlert.siteNames.length === 1 ? "has" : "have"
						} already been assigned to a set of billing info. Assigning the same site to another billing will overwrite the billing info for that site.`}
					/>
				)}
			</div>
		);
	},
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button
						key="back"
						color="blue"
						onClick={() => {
							if (
								values.PaymentProvider === "ADVAM" ||
								!values.PaymentProvider
							) {
								goTo("paymentProvider");
							} else {
								previous();
							}
						}}
					>
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const summary = ({
	handleSubmit,
	isSubmitting,
	setFieldValue,
	goTo,
	values,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "summary",
	label: "Summary",
	render: () => {
		let items = [
			{
				title: "Company Name",
				value: values.CompanyName,
				key: "companyName",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("companyInfo");
				},
			},
			{
				title: "Tax Registration",
				value: values.TaxRegistration,
				key: "taxRegistration",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("companyInfo");
				},
			},
			{
				title: "Billing Address",
				value: values.BillingAddress,
				key: "billingAddress",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("companyInfo");
				},
			},
			{
				title: "Payment Provider",
				value: values.PaymentProvider || "None",
				key: "paymentProvider",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("paymentProvider");
				},
			},
			{
				title: "QR Code",
				value: values.WebPayEnabled ? "Enabled" : "Disabled",
				key: "webPay",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("webPay");
				},
			},
		];

		if (values.PaymentProvider === "PaymentExpress") {
			items = items.concat([
				{
					title: "PX Post Username",
					value: values.PXPostUsername,
					key: "pXPostUsername",
					edit: () => {
						setFieldValue("editFromSummary", true);
						goTo("paymentProvider");
					},
				},
				{
					title: "PX Post Password",
					value: values.PXPostSecretKey,
					key: "pXPostSecretKey",
					edit: () => {
						setFieldValue("editFromSummary", true);
						goTo("paymentProvider");
					},
				},
				{
					title: "PX Pay Username",
					value: values.PxPayUsername,
					key: "pXPayUsername",
					edit: () => {
						setFieldValue("editFromSummary", true);
						goTo("paymentProvider");
					},
				},
				{
					title: "PX Pay Password",
					value: values.PxPaySecretKey,
					key: "pXPaySecretKey",
					edit: () => {
						setFieldValue("editFromSummary", true);
						goTo("paymentProvider");
					},
				},
				{
					title: "Settlement",
					value: values.MerchantAccountSettlement || "Non-Inugo",
					key: "merchantAccountSettlement",
					edit: () => {
						setFieldValue("editFromSummary", true);
						goTo("paymentProvider");
					},
				},
			]);
		}

		if (values.PaymentProvider === "Stripe") {
			items = items.concat([
				{
					title: "Billing Account",
					value: values.billingAccountEmail,
					key: "billingAccountEmail",
					edit: () => {
						setFieldValue("editFromSummary", true);
						goTo("paymentProvider");
					},
				},
			]);
		}

		if (
			values.PaymentProvider === "PaymentExpress" ||
			values.PaymentProvider === "Stripe"
		) {
			items = items.concat([
				{
					title: "Accepted Credit Card Types",
					value: map(values.AcceptedCreditCardTypes, (cardType) =>
						cardType === CREDIT_CARD_TYPES.MASTERCARD ? "Mastercard" : cardType
					).join(", "),

					key: "acceptedCreditCardTypes",
					edit: () => {
						setFieldValue("editFromSummary", true);
						goTo("acceptedCreditCardTypes");
					},
				},
				{
					title: "Convenience Fees",
					value: "(Click edit to view details)",
					key: "convenienceFees",
					edit: () => {
						setFieldValue("editFromSummary", true);
						goTo("convenienceFees");
					},
				},
				{
					title: "Convenience Fees Paid By",
					value: values.ConvenienceFeePaidBy,
					key: "ConvenienceFeePaidBy",
					edit: () => {
						setFieldValue("editFromSummary", true);
						goTo("convenienceFees");
					},
				},
				{
					title: "Payment Processing Fees",
					value: "(Click edit to view details)",
					key: "fixedFee",
					edit: () => {
						setFieldValue("editFromSummary", true);
						goTo("paymentProcessingFees");
					},
				},
			]);
		}

		if (values.PaymentProvider === "ADVAM") {
			items = items.concat([
				{
					title: "ADVAM Account ID",
					value: values.ADVAMAccountID,
					key: "ADVAMAccountID",
					edit: () => {
						setFieldValue("editFromSummary", true);
						goTo("paymentProvider");
					},
				},
			]);
		}

		if (!values.IsDefault) {
			items = items.concat([
				{
					title: "Sites",
					value: resortSiteNames(wizardProps.sites, values.siteIds),
					key: "sites",
					edit: () => {
						setFieldValue("editFromSummary", true);
						goTo("sites");
					},
				},
			]);
		}

		return (
			<div>
				<StepTitle>Summary</StepTitle>

				<SummaryTable items={items} />
			</div>
		);
	},
	footer: () => (
		<WizardNavigation
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.mode === "edit" ? "Update" : "Create"} Billing Details
				</Button>,
			]}
		/>
	),
});

export const deleteStep = ({
	handleSubmit,
	isSubmitting,
	values,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "delete",
	label: "Delete",
	render: () => (
		<div>
			<StepText>
				Are you sure you want to delete the billing details for{" "}
				<b>{values.CompanyName}</b>? This action CANNOT be undone.
			</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					Delete
				</Button>,
			]}
		/>
	),
});
