import { boxShadows, colours } from "../../styles";
import MultiSelect from "@khanacademy/react-multi-select";
import React from "react";
import styled from "styled-components";

const SelectRow = styled.div`
	position: relative;

	.item-text {
		display: inline-block;
		margin-left: 32px;
		position: relative;
	}
`;

const Checkbox = styled.input`
	appearance: none;
	border: 1px solid ${colours.borderGrey};
	border-radius: 4px;
	cursor: pointer;
	height: 20px;
	outline: 0 !important;
	left: 2px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 20px;

	&:checked {
		background: ${colours.blue};
		border: 0;

		&::before {
			background-color: ${colours.background};
			content: "";
			height: 2px;
			left: 4px;
			position: absolute;
			width: 6px;
			top: 11px;
			transform: rotate(45deg);
		}

		&::after {
			background-color: ${colours.background};
			content: "";
			height: 12px;
			left: 11px;
			position: absolute;
			top: 4px;
			transform: rotate(45deg);
			width: 2px;
		}
	}
`;

const ItemRenderer = ({ checked, option, onClick }) => {
	return (
		<SelectRow>
			<Checkbox
				type="checkbox"
				onChange={onClick}
				checked={checked}
				tabIndex="-1"
			/>
			<span className="item-text">{option.label}</span>
		</SelectRow>
	);
};

const Wrapper = styled.div`
	.multi-select {
		color: ${colours.darkGrey} !important;

		.dropdown-heading {
			background: ${colours.background} !important;
			border: 0 !important;
			border-radius: 4px !important;
			box-shadow: ${boxShadows.light} !important;
			cursor: pointer !important;
			height: 40px !important;

			.dropdown-heading-value {
				font-size: 18px !important;
				font-weight: 400 !important;
				line-height: 40px !important;
				padding-right: 24px !important;

				span {
					color: ${colours.darkGrey} !important;
				}
			}
		}

		.dropdown[aria-expanded="true"] {
			.dropdown-heading-dropdown-arrow span {
				border-color: transparent transparent ${colours.midGrey} !important;
			}
		}

		.dropdown[aria-expanded="false"] {
			.dropdown-heading-dropdown-arrow span {
				border-color: ${colours.midGrey} transparent transparent !important;
			}
		}

		.dropdown-content {
			border-radius: 0 !important;
			max-height: 500px !important;
			z-index: 2 !important;
		}

		.select-item {
			border-top: 1px solid ${colours.lightGrey};
			padding: 12px !important;
		}
	}
`;

export default function Select(props) {
	return (
		<Wrapper style={props.style}>
			<MultiSelect ItemRenderer={ItemRenderer} {...props} />
		</Wrapper>
	);
}
