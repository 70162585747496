import { isNaN } from "lodash";
import Input from "./Input";

export default function NumberInput({ min, max, value, onChange, ...rest }) {
	return (
		<Input
			{...rest}
			type="number"
			min={min}
			max={max}
			onChange={(event) => {
				const val = parseFloat(event.target.value);
				onChange(isNaN(val) ? null : val);
			}}
			value={value}
			onBlur={() => {
				if (value < min) {
					onChange(min);
				}
			}}
		/>
	);
}
