import React from "react";
import { FieldContent } from "../../../styles";
import Dropdown from "../../../../../layout/Dropdown";
import FieldError from "../../../../../layout/FieldError";

export const Sites = ({ availableSites, selectedSites, onChange, error }) => {
	const siteOptions = availableSites.map((site) => ({
		value: site.SiteID,
		label: site.Name,
	}));

	return (
		<div>
			<FieldContent>
				<Dropdown
					options={siteOptions}
					value={selectedSites}
					onChange={onChange}
					placeholder="Select Site"
				/>
			</FieldContent>
			<FieldError showError={error} errorText={error} />
		</div>
	);
};
