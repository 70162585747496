import { useQueryData } from "../../../../../hooks";
import gql from "graphql-tag";

export const useGetAllEventOrgAccessGroups = (orgId) => {
	const { data, isLoading, isError, refetch } = useQueryData(
		gql`
			query ($organizationId: Int!) {
				getOrganizationAccessGroups(organizationId: $organizationId) {
					OrganizationAccessGroupID
					OrganizationID
					Name
					EventGroup
				}
			}
		`,
		{ organizationId: orgId }
	);

	const organizationAccessGroups = data
		? data.getOrganizationAccessGroups
		: null;

	return {
		organizationAccessGroups,
		isLoading,
		isError,
		refetch,
	};
};
