import { boxShadows, colours } from "../../styles";
import styled from "styled-components";

const Card = styled.div`
	background-color: ${colours.background};
	border-radius: 4px;
	box-shadow: ${boxShadows.standard};
	margin: 32px 0;
	padding: 16px;
`;

export default Card;
