import React from "react";
import Button from "../../layout/Button";
import WizardLayout, { StepText } from "../WizardLayout";
import WizardNavigation from "../WizardNavigation";
import { useMutateData } from "../../../hooks";
import Alert from "react-s-alert";
import gql from "graphql-tag";

function openGateStep({ wizardProps, values, handleSubmit, isSubmitting }) {
	return {
		id: "open-gate",
		label: "Open Gate",
		render: () => (
			<div>
				<StepText>
					{`Are you sure you want to open the gate for camera ${values.serialNumber}, that is attached to node ${values.nodeName}?`}
				</StepText>
			</div>
		),
		footer: () => {
			return (
				<WizardNavigation
					leftItems={[
						<Button key="cancel" color="blue" onClick={wizardProps.close}>
							Cancel
						</Button>,
					]}
					rightItems={[
						<Button
							key="submit"
							color="green"
							onClick={handleSubmit}
							disabled={isSubmitting}
						>
							Open
						</Button>,
					]}
				/>
			);
		},
	};
}

export default function CameraActionsWizard(props) {
	const openGate = useMutateData(gql`
		mutation ($cameraId: Int!) {
			openGateForCamera(cameraId: $cameraId)
		}
	`);

	return (
		<WizardLayout
			close={props.close}
			title={"Add Cameras"}
			values={[
				{
					name: "serialNumber",
					value: props.camera.SerialNumber,
				},
				{
					name: "nodeName",
					value: props.camera.Node.Name,
				},
			]}
			onSubmit={async (values, { setSubmitting }) => {
				setSubmitting(true);

				try {
					await openGate({
						variables: {
							cameraId: props.camera.CameraID,
						},
					});

					Alert.success("Gate opened");
					props.close();
				} catch (error) {
					const errorCode =
						error.graphQLErrors?.[0]?.extensions?.exception?.code ||
						error.graphQLErrors?.[0]?.extensions?.code;

					switch (errorCode) {
						case "CAMERA_NOT_WHITELISTED":
							Alert.error(
								"This camera needs to be explicity whitelisted to use this functionality outside of production."
							);
							break;
						case "AccessDenied":
							Alert.error("This action is not supported.");
							break;
					}
				}
			}}
			steps={[openGateStep]}
			initialStep={0}
			wizardProps={props}
		/>
	);
}
