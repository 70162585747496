import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import EventPasses from "../../components/report/events/EventPasses";
import EventManagement from "../../components/report/events/EventManagement";
import { usePermissions } from "../../hooks";

export default function EventsContainer(props) {
	const canManageEvents = usePermissions(null, "ManageEvents", true);
	if (!canManageEvents || !props.selectedOrganization?.HasEventManagement)
		return (
			<Redirect
				to={`${props.match.url.replace("event-management", "")}/dashboard`}
			/>
		);
	return (
		<Switch>
			<Route path={`${props.match.url}/events`}>
				<EventManagement {...props} />
			</Route>
			<Route path={`${props.match.url}/passes`}>
				<EventPasses {...props} />
			</Route>
			<Redirect to={`${props.match.url}/events`} />
		</Switch>
	);
}
