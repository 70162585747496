import { cloneDeep, find, some } from "lodash";
import Alert from "react-s-alert";

export const initialGroup = {
	OverridesOpeningHours: 0,
	SundayOpen: 1,
	MondayOpen: 1,
	TuesdayOpen: 1,
	WednesdayOpen: 1,
	ThursdayOpen: 1,
	FridayOpen: 1,
	SaturdayOpen: 1,

	SundayOpeningTimestamp: null,
	SundayClosingTimestamp: null,
	MondayOpeningTimestamp: null,
	MondayClosingTimestamp: null,
	TuesdayOpeningTimestamp: null,
	TuesdayClosingTimestamp: null,
	WednesdayOpeningTimestamp: null,
	WednesdayClosingTimestamp: null,
	ThursdayOpeningTimestamp: null,
	ThursdayClosingTimestamp: null,
	FridayOpeningTimestamp: null,
	FridayClosingTimestamp: null,
	SaturdayOpeningTimestamp: null,
	SaturdayClosingTimestamp: null,
};

export const days = [
	"Monday",
	"Tuesday",
	"Wednesday",
	"Thursday",
	"Friday",
	"Saturday",
	"Sunday",
];

export function restoreSites(group) {
	if (!group || !group.sites || group.sites.length === 0) {
		return [{ value: -1, label: "No site" }];
	}

	return group.sites.map((site) => ({
		value: site.SiteID,
		label: site.Name,
	}));
}

export function restoreAccount(group, spaceAccounts, props) {
	if (props.spaceAccountOwnerOnly) {
		const defaultAccount = props.user.SpaceAccountOwners.find(
			(e) => e.OrganizationID === props.selectedOrganization.OrganizationID
		);

		if (!group || !group.SpaceAccountID) {
			return {
				value: defaultAccount.SpaceAccountID,
				label: defaultAccount.Name,
			};
		}
	} else {
		if (!group || !group.SpaceAccountID) {
			return null;
		}
	}

	const account = find(
		spaceAccounts,
		(a) => a.SpaceAccountID === group.SpaceAccountID
	);

	if (account) {
		return {
			value: account.SpaceAccountID,
			label: account.Name,
		};
	} else {
		return null;
	}
}

export function restoreLeaseRates(group) {
	if (group && group.leaseRates && group.leaseRates.length > 0) {
		return group.leaseRates.map((leaseRate) => ({
			value: leaseRate.LeaseRateID,
			label: leaseRate.Name,
		}));
	} else {
		return [{ value: -1, label: "No rate" }];
	}
}

export function restoreLocationBasedNodes(group) {
	if (group && group.nodes && group.nodes.length > 0) {
		return group.nodes
			.filter((node) => node.LocationBasedAccess)
			.map((node) => ({
				value: node.NodeID,
				label: node.Name,
			}));
	} else {
		return null;
	}
}

export function restoreRestrictedAccessNodes(group) {
	if (group && group.nodes && group.nodes.length > 0) {
		return group.nodes
			.filter((node) => node.GroupBasedAccess)
			.map((node) => ({
				value: node.NodeID,
				label: node.Name,
			}));
	} else {
		return null;
	}
}

export const getHoursData = (values) => {
	let hoursData = cloneDeep(initialGroup);

	// Ensure any 0 values are converted to false
	// Ensure any 1 values are converted to true
	if (values.overridesOpeningHours) {
		days.forEach((day) => {
			hoursData[`${day}Open`] = values.group[`${day}Open`] === 1;
			hoursData[`${day}OpeningTimestamp`] =
				values.group[`${day}OpeningTimestamp`];
			hoursData[`${day}ClosingTimestamp`] =
				values.group[`${day}ClosingTimestamp`];
		});
		hoursData.OverridesOpeningHours = true;
	} else {
		hoursData.OverridesOpeningHours = false;
	}

	// Convert 1/0 to true/false for all days
	days.forEach((day) => {
		hoursData[`${day}Open`] =
			hoursData[`${day}Open`] === 1
				? true
				: hoursData[`${day}Open`] === 0
				? false
				: hoursData[`${day}Open`];
	});

	return hoursData;
};

export const handleApiError = (error, translate) => {
	if (
		some(
			error?.errors || [],
			(e) => e.code === "InvalidSharedInstallationViaGroup"
		)
	) {
		Alert.error(
			"Cannot add group to site. This group contains users that have access to another site on the shared installation."
		);
	} else if (
		error?.graphQLErrors[0]?.extensions?.exception?.code ===
		"GroupNameMustBeUnique"
	) {
		Alert.error("Cannot save group, group name already exists!");
	} else {
		Alert.error(translate("Errors.Generic"));
	}
};

export const getTitleByMode = (mode) => {
	switch (mode) {
		case "add":
			return "Create group";
		case "update":
			return "Update group";
		case "remove":
			return "Remove group";
		default:
			return "";
	}
};

export const uniqueSites = (sites) => {
	const seen = new Set();
	return sites.filter((site) => {
		const duplicate = seen.has(site.SiteID);
		seen.add(site.SiteID);
		return !duplicate;
	});
};
