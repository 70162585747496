import * as installationApi from "../../../api/installation";
import * as sessionApi from "../../../api/session";
import Alert from "react-s-alert";

export async function startSessionManually(props, values, setSubmitting) {
	try {
		await sessionApi.startSession(values.selectedSite.value, {
			userId: values.user.UserID,
		});

		setSubmitting(false);
		Alert.success("Session started");
		props.close(true);
	} catch (error) {
		setSubmitting(false);

		if (error.errors && error.errors[0]) {
			switch (error.errors[0].code) {
				case "SiteAccessNotAllowed":
					Alert.error("User does not have access to this site.");
					break;
				case "SiteNotOpen":
					Alert.error("The site is not open to this user group at the moment.");
					break;
				case "SiteAccessNotAllowedNoCreditCard":
					Alert.error("User does not have a credit card.");
					break;
				case "SiteAccessNotAllowedOutstandingPayments":
					Alert.error("User has outstanding payments.");
					break;
				case "SiteIsNotOperational":
					Alert.error("The site is currently not operational.");
					break;
				default:
					Alert.error("Something went wrong. Please try again.");
					break;
			}
		} else {
			Alert.error("Something went wrong. Please try again.");
		}
	}
}

export async function endSessionManually(props, values, setSubmitting) {
	try {
		await sessionApi.endSession(values.session.SiteID, {
			userId: values.session.UserID,
			manualEndTime: values.manualEndTime,
		});

		setSubmitting(false);
		Alert.success("Session ended");
		props.close(true);
	} catch (error) {
		setSubmitting(false);

		if (error.errors && error.errors[0]) {
			switch (error.errors[0].code) {
				case "PaymentError":
				case "CreditCardDeclined":
				case "PaymentExpressError":
				case "SiteMissingBillingAccount":
					Alert.error("The payment was not successful.");
					props.close(true);
					break;
				default:
					Alert.error("Something went wrong. Please try again.");
					break;
			}
		} else {
			Alert.error("Something went wrong. Please try again.");
		}
	}
}

export async function forceCloseSession(props, values, setSubmitting) {
	try {
		await sessionApi.forceCloseSession(
			values.session.SiteID,
			values.session.ParkingSessionID
		);

		setSubmitting(false);
		Alert.success("Session ended without charge");
		props.close(true);
	} catch (error) {
		setSubmitting(false);
		Alert.error("Something went wrong. Please try again.");
	}
}

export async function openGateManually(props, values, setSubmitting) {
	const data = {
		reason: values.reason,
	};

	try {
		await installationApi.openGate(
			values.selectedSite.value,
			values.selectedGate.value,
			data
		);

		setSubmitting(false);
		Alert.success("Gate opened");
		props.close(true);
	} catch (error) {
		if (
			error.errors &&
			error.errors[0] &&
			error.errors[0].code === "GateError"
		) {
			Alert.error("Gate did not open.");
		} else {
			Alert.error("Something went wrong. Please try again.");
		}
		setSubmitting(false);
	}
}
