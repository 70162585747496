import * as installationApi from "../../../api/installation";
import { StepText, StepTitle } from "../WizardLayout";
import { cloneDeep, find, _ } from "lodash";
import Button from "../../layout/Button";
import DatePicker from "../../../components/layout/DatePicker";
import Dropdown from "../../layout/Dropdown";
import EditableInputField from "../../layout/EditableInputField";
import PromotionLookup from "../../wizards/PromotionLookup";
import React from "react";
import SummaryTable from "../SummaryTable";
import WizardNavigation from "../WizardNavigation";
import moment from "moment";
import { useFetchData } from "../../../hooks";
import { ChoosePromotionRate } from "./PromotionRate";
import { ChooseSpaces } from "./PromotionSpace";

function getButton(
	buttonLabel,
	color,
	onClick,
	isDisabled,
	isEditing,
	goTo,
	keyStrokeHandler
) {
	let label = isEditing
		? "Review"
		: `${buttonLabel.charAt(0).toUpperCase()}${buttonLabel.slice(1)}`;
	return (
		<Button
			key={buttonLabel}
			color={isEditing ? "blue" : color}
			onClick={isEditing ? () => goTo("summary") : onClick}
			disabled={isDisabled}
			keyStrokeHandler={keyStrokeHandler}
		>
			{label}
		</Button>
	);
}

export function name({
	close,
	next,
	isSubmitting,
	values,
	goTo,
	keyStrokeHandler,
}) {
	const isIncomplete = isSubmitting || !values.name;

	return {
		id: "name",
		label: "Name",
		render: () => (
			<div>
				<StepTitle>What is the name of this promotion?</StepTitle>
				<EditableInputField
					type="text"
					name="name"
					value={values.name || ""}
					useLabel="Name"
				/>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [getButton("cancel", "red", close)]
				}
				rightItems={[
					getButton(
						"next",
						"blue",
						next,
						isIncomplete,
						values.editFromSummary,
						goTo,
						keyStrokeHandler
					),
				]}
			/>
		),
	};
}

export function promotionCode({
	previous,
	next,
	isSubmitting,
	values,
	goTo,
	wizardProps,
	setFieldValue,
	keyStrokeHandler,
}) {
	const isIncomplete =
		isSubmitting ||
		!values.promotionCode ||
		!values.promotionCode === "" ||
		!values.promotionCodeValid;

	return {
		id: "promotionCode",
		label: "Promotion Code",
		render: () => (
			<div>
				<StepTitle>What is the Unique Promotion Code?</StepTitle>
				<PromotionLookup
					promotionCode={values.promotionCode || ""}
					organizationId={wizardProps.organizationId}
					forceUppercase={true}
					mode={"promotionCode"}
					onChange={(value) => {
						setFieldValue("promotionCode", value.promotionCode);
						setFieldValue("promotionCodeValid", value.promotionCodeValid);
					}}
				/>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [getButton("back", "blue", previous)]
				}
				rightItems={[
					getButton(
						"next",
						"blue",
						next,
						isIncomplete,
						values.editFromSummary,
						goTo,
						keyStrokeHandler
					),
				]}
			/>
		),
	};
}

export function sites({
	next,
	previous,
	setFieldValue,
	isSubmitting,
	values,
	goTo,
	keyStrokeHandler,
}) {
	const isIncomplete = values.onAllSites
		? false
		: isSubmitting || !values.promotionSites.length;

	let defaultAvailableOptions = [{ label: "All Sites", value: 0 }].concat(
		values.availableSites.map((site) => ({
			label: site.name,
			value: site.id,
		}))
	);
	let availableOptions = [];
	let dropdownValue = [];

	if (values.onAllSites) {
		availableOptions = [{ label: "All Sites", value: 0 }];
		dropdownValue = [{ label: "All Sites", value: 0 }];
	} else {
		availableOptions = defaultAvailableOptions;
		dropdownValue = values.promotionSites.map((d) => ({
			label: d.Name,
			value: d.SiteID,
		}));
	}

	const handleSiteUpdates = (options) => {
		const isAllSites = find(options, ({ value }) => value === 0);

		dropdownValue = [];

		if (isAllSites?.value === 0) {
			availableOptions = [{ label: "All Sites", value: 0 }];
			dropdownValue = [{ label: "All Sites", value: 0 }];
			setFieldValue("onAllSites", true);
			setFieldValue(
				"promotionSites",
				values.availableSites.map((site) => ({
					SiteID: site.id,
					Name: site.name,
				}))
			);
		} else {
			availableOptions = defaultAvailableOptions;
			options.forEach((d) => {
				dropdownValue.push({
					value: d.value || d.id,
					label: d.label || d.name,
				});
			});
			setFieldValue("onAllSites", false);
			setFieldValue(
				"promotionSites",
				dropdownValue.map((value) => ({
					SiteID: value.value || value.id,
					Name: value.label || value.name,
				}))
			);
		}
	};

	return {
		id: "sites",
		label: "Sites",
		render: () => (
			<div>
				<StepTitle>What site(s) can this promotion be used on?</StepTitle>

				<Dropdown
					isMulti={true}
					options={availableOptions}
					value={dropdownValue}
					onChange={(options) => {
						values.leaseParkIds = [];
						handleSiteUpdates(options);
					}}
				/>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [getButton("back", "blue", previous)]
				}
				rightItems={[
					<Button
						key="next"
						color="blue"
						onClick={() => (values.editFromSummary ? goTo("spaces") : next())}
						disabled={isSubmitting || isIncomplete}
						keyStrokeHandler={keyStrokeHandler}
					>
						Next
					</Button>,
				]}
			/>
		),
	};
}

export function spacesStep({
	next,
	previous,
	setFieldValue,
	wizardProps,
	values,
	goTo,
	keyStrokeHandler,
	handleChange,
}) {
	return {
		id: "spaces",
		label: "Spaces",
		render: () => (
			<div>
				<StepTitle>
					Does this promotion need to be linked to a specific space?
				</StepTitle>
				<ChooseSpaces
					setFieldValue={setFieldValue}
					wizardProps={wizardProps}
					values={values}
					handleChange={handleChange}
				/>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [getButton("back", "blue", previous)]
				}
				rightItems={[
					getButton(
						"next",
						"blue",
						next,
						false,
						values.editFromSummary,
						goTo,
						keyStrokeHandler
					),
				]}
			/>
		),
	};
}

export function promotionType({
	previous,
	next,
	isSubmitting,
	handleChange,
	values,
	goTo,
	keyStrokeHandler,
}) {
	const isIncomplete = isSubmitting || !values.promotionType;

	return {
		id: "promotionType",
		label: "Type",
		render: () => (
			<div>
				<StepTitle>What is the promotion type?</StepTitle>
				<Dropdown
					options={values.promotionTypeOptions}
					value={
						values.promotionTypeOptions.find(
							(e) => e.value === values.promotionType
						) || ""
					}
					onChange={(value) =>
						handleChange({
							target: {
								name: "promotionType",
								value: value.value,
							},
						})
					}
				/>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [getButton("back", "blue", previous)]
				}
				rightItems={[
					getButton(
						"next",
						"blue",
						next,
						isIncomplete,
						values.editFromSummary,
						goTo,
						keyStrokeHandler
					),
				]}
			/>
		),
	};
}

function ChooseLeaseRate(props) {
	let siteIds = props.promotionSites.map((site) => site.SiteID);

	const { data, isLoading } = useFetchData(
		[],
		installationApi.getLeaseRatesForOrganizationId,
		[props.organizationId, { sites: siteIds }],
		[]
	);

	const getRatesOptions = (site, _rates) => {
		let options = [];
		_rates.forEach((rate) => {
			let sitesInformation = [];
			rate.leaseParks.forEach((leasePark) =>
				leasePark.sites.forEach((item) =>
					sitesInformation.push({
						Name: item.Name,
						SiteID: item.SiteID,
					})
				)
			);
			options.push({
				Sites: sitesInformation,
				LeaseRateID: rate.LeaseRateID,
				Name: rate.Name,
			});
		});

		return options.filter((option) =>
			option.Sites.map((s) => s.SiteID).includes(site)
		);
	};

	const handleValueChange = (value, key) => {
		const _sites = cloneDeep(props.promotionSites);
		_sites[key].id = _sites[key].id || _sites[key].SiteID;
		_sites[key].leaseRateId = value;

		return _sites;
	};

	return (
		<div>
			{!isLoading &&
				props.promotionSites.map((site, key) => {
					const options = getRatesOptions(site.SiteID, data);

					return (
						<div key={key} style={{ marginTop: "15px" }}>
							<StepText>{site.Name}</StepText>
							{options.length ? (
								<div style={{ marginTop: "5px" }}>
									<Dropdown
										isMulti={true}
										options={[{ label: "All Rates", value: 0 }].concat(
											options.map((option) => ({
												label: option.Name,
												value: option.LeaseRateID,
											}))
										)}
										value={options.map((d) => {
											if (site.leaseRateId.includes(d.LeaseRateID)) {
												return {
													label: d.Name,
													value: d.SiteID,
												};
											}
										})}
										isDisabled={options.length === 0}
										onChange={(value) => {
											props.handleChange({
												target: {
													name: "promotionSites",
													value: handleValueChange(value.value, key),
												},
											});
										}}
									/>
								</div>
							) : (
								<p>
									No booking rates available. Please add a booking rate in the
									Rates page, under the Booking Rates tab. Otherwise continue.
								</p>
							)}
						</div>
					);
				})}
		</div>
	);
}

export function promotionValue({
	values,
	errors,
	touched,
	previous,
	next,
	handleChange,
	isSubmitting,
	goTo,
	keyStrokeHandler,
}) {
	let isIncomplete;
	if (values.promotionType === "Rate") {
		let isCompleted = null;
		if (values.initialRates && values.initialRates.length > 0) {
			values.initialRates.map((promotionRate) => {
				if (isCompleted === null) {
					isCompleted = !isNaN(promotionRate.RateID);
				} else {
					isCompleted = isCompleted && !isNaN(promotionRate.RateID);
				}
			});
		} else {
			values.promotionSites.map((promotionSite) => {
				if (isCompleted === null) {
					isCompleted = !isNaN(promotionSite.rateId);
				} else {
					isCompleted = isCompleted && !isNaN(promotionSite.rateId);
				}
			});
		}

		const availableSite = values.availableSites.find(
			(site) => site.id === values.promotionSite
		);

		isIncomplete = isSubmitting || !isCompleted;
		return {
			id: "promotionValue",
			label: "Promotion Rate",
			render: () => (
				<div>
					<StepTitle>
						Which promotion rate should be applied to each site?
					</StepTitle>
					<ChoosePromotionRate
						siteType="promotionSites"
						promotionSites={values.promotionSites}
						initialRates={values.initialRates}
						promotionSite={{ ...availableSite, rateId: values.rateId }}
						handleChange={handleChange}
						organizationId={values.organizationId}
					/>
				</div>
			),
			footer: () => (
				<WizardNavigation
					leftItems={
						!values.editFromSummary && [getButton("back", "blue", previous)]
					}
					rightItems={[
						getButton(
							"next",
							"blue",
							next,
							isIncomplete,
							values.editFromSummary,
							goTo,
							keyStrokeHandler
						),
					]}
				/>
			),
		};
	} else {
		isIncomplete = isSubmitting || !values.promotionValue;

		const promotionLabel =
			values.promotionType === "DurationDays" ||
			values.promotionType === "DurationHours"
				? "Duration"
				: values.promotionType;

		return {
			id: "promotionValue",
			label: `${promotionLabel || "Value"}`,
			render: () => (
				<div>
					<StepTitle>
						What is the{" "}
						{promotionLabel === "Duration"
							? promotionLabel.toLowerCase()
							: promotionLabel.toLowerCase() + " amount"}
						?
					</StepTitle>
					<EditableInputField
						type="number"
						min={0.1}
						name="promotionValue"
						value={values.promotionValue || ""}
						useLabel={promotionLabel}
						error={
							errors.promotionValue && touched.promotionValue
								? errors.promotionValue
								: null
						}
					/>
				</div>
			),
			footer: () => (
				<WizardNavigation
					leftItems={
						!values.editFromSummary && [getButton("back", "blue", previous)]
					}
					rightItems={[
						getButton(
							"next",
							"blue",
							next,
							isIncomplete,
							values.editFromSummary,
							goTo,
							keyStrokeHandler
						),
					]}
				/>
			),
		};
	}
}

export function limits({
	previous,
	next,
	isSubmitting,
	values,
	goTo,
	keyStrokeHandler,
	setFieldValue,
}) {
	let isIncomplete = isSubmitting;
	if (values.codeLimit && !values.userLimit) {
		isIncomplete = false;
	} else if (!values.codeLimit && values.userLimit) {
		isIncomplete = false;
	} else if (values.codeLimit < values.userLimit) {
		isIncomplete = true;
	}

	return {
		id: "limits",
		label: "Limits",
		render: () => (
			<div>
				<StepTitle>What are the limitations of this promotion?</StepTitle>
				<h3>Code usage limit (leave blank if unlimited)</h3>
				<EditableInputField
					type="number"
					min={1}
					name="codeLimit"
					value={values.codeLimit || ""}
					useLabel="Code Limit"
					onChange={(e) => {
						if (e.target.value >= 1) {
							setFieldValue("codeLimit", e.target.value);
							return;
						}

						setFieldValue("codeLimit", null);
					}}
				/>

				<h3>Limit per unique user (leave blank if unlimited)</h3>
				<EditableInputField
					type="number"
					min={1}
					name="userLimit"
					value={values.userLimit || ""}
					useLabel="User Limit"
					onChange={(e) => {
						if (e.target.value >= 1) {
							setFieldValue("userLimit", e.target.value);
							return;
						}

						setFieldValue("userLimit", null);
					}}
				/>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [getButton("back", "blue", previous)]
				}
				rightItems={[
					getButton(
						"next",
						"blue",
						next,
						isIncomplete,
						values.editFromSummary,
						goTo,
						keyStrokeHandler
					),
				]}
			/>
		),
	};
}

export function availability({
	previous,
	next,
	isSubmitting,
	setFieldValue,
	values,
	goTo,
	keyStrokeHandler,
}) {
	const isIncomplete = isSubmitting;

	return {
		id: "availability",
		label: "Availability",
		render: () => (
			<div>
				<StepTitle>When is this promotion available?</StepTitle>
				<StepText>(Leave blank if the promotion is always available)</StepText>

				<h3>Available from:</h3>
				<DatePicker
					options={{
						pickerMode: "datePicker",
						showClearButton: true,
						minDate: moment().format("DD MMM YYYY"),
					}}
					value={values.startDate}
					onChange={([date]) => {
						setFieldValue("startDate", date);

						if (
							date &&
							values.endDate &&
							moment(values.endDate).isBefore(moment(date))
						) {
							setFieldValue("endDate", null);
						}
					}}
					whenCleared={() => setFieldValue("startDate", null)}
				/>
				<h3>Available to:</h3>
				<DatePicker
					options={{
						pickerMode: "datePicker",
						showClearButton: true,
						minDate: values.startDate || moment().format("DD MMM YYYY"),
					}}
					value={values.endDate}
					onChange={([date]) => {
						setFieldValue("endDate", date);

						if (
							values.startDate &&
							date &&
							moment(date).isBefore(moment(values.startDate))
						) {
							setFieldValue("startDate", null);
						}
					}}
					whenCleared={() => setFieldValue("endDate", null)}
				/>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [getButton("back", "blue", previous)]
				}
				rightItems={[
					getButton(
						"next",
						"blue",
						next,
						isIncomplete,
						values.editFromSummary,
						goTo,
						keyStrokeHandler
					),
				]}
			/>
		),
	};
}

export function appliesTo({
	next,
	previous,
	isSubmitting,
	values,
	goTo,
	handleChange,
	keyStrokeHandler,
}) {
	const isIncomplete = isSubmitting;

	return {
		id: "appliesTo",
		label: "Applies to",
		render: () => (
			<div>
				<StepTitle>What booking rates does this promotion apply to?</StepTitle>
				<ChooseLeaseRate
					promotionSites={values.promotionSites}
					handleChange={handleChange}
					organizationId={values.organizationId}
				/>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [getButton("back", "blue", previous)]
				}
				rightItems={[
					getButton(
						"next",
						"blue",
						next,
						isIncomplete,
						values.editFromSummary,
						goTo,
						keyStrokeHandler
					),
				]}
			/>
		),
	};
}

export function summary({
	goTo,
	handleSubmit,
	isSubmitting,
	setFieldValue,
	values,
	wizardProps,
	keyStrokeHandler,
}) {
	const mode = wizardProps.mode;

	let _availableDates = "";
	if (!values.startDate & !values.endDate) {
		_availableDates = "Any time";
	} else {
		let startDate = values.startDate
			? `From ${moment(values.startDate).format("DD MMM YYYY")}`
			: "";
		let endDate = values.endDate
			? `To ${moment(values.endDate).format("DD MMM YYYY")}`
			: "";

		_availableDates = `${startDate} ${endDate}`;
	}

	let promotionLabel, promotionListValue;
	switch (values.promotionType) {
		case "DurationDays":
		case "DurationHours":
			promotionLabel = "Duration";
			promotionListValue =
				values.promotionType === "DurationDays"
					? `${values.promotionValue} days free`
					: `${values.promotionValue} hours free`;
			break;
		case "Rate":
			promotionLabel = "Rate";
			if (values.initialRates && values.initialRates.length > 0) {
				promotionListValue = values.promotionSites
					.map(
						(site) =>
							`${site.Name} - ${
								values.initialRates.find((r) => r.SiteID === site.SiteID)
									?.RateID ?? ""
							}`
					)
					.join(", ");
			} else {
				promotionListValue = values.promotionSites
					.filter((d) => d.rateId)
					.map((site) => `${site.Name} - ${site.rateId}`)
					.join(", ");
			}
			break;
		default:
			promotionLabel = `${values.promotionType} Amount`;
			promotionListValue =
				values.promotionType === "Fixed"
					? `-$${values.promotionValue}`
					: `-${values.promotionValue}%`;
			break;
	}

	const items = [
		{
			title: "Name",
			value: values.name,
			key: "name",
			edit: () => {
				setFieldValue("editFromSummary", true);
				goTo("name");
			},
		},
		{
			title: "Sites",
			value: values.onAllSites
				? "All Sites"
				: values.promotionSites?.map((site) => site.Name).join(", "),
			key: "sites",
			edit: () => {
				setFieldValue("editFromSummary", true);
				goTo("sites");
			},
		},
		{
			title: "Spaces",
			value: _.uniqBy(
				Object.values(values.leaseParkIds).flatMap((id) => {
					let spaces = wizardProps.availableSpaces.flatMap((lp) => {
						return lp.Spaces.find((s) => s.LeaseParkID === id);
					});

					return spaces.map((space) => space?.Name).filter((n) => !!n);
				})
			).join(", "),
			edit: () => {
				setFieldValue("editFromSummary", true);
				goTo("spaces");
			},
		},
		{
			title: "Promotion Code",
			value: values.promotionCode,
			key: "promotionCode",
		},
		{
			title: "Type",
			value: values.promotionType,
			key: "promotionType",
			edit: () => {
				setFieldValue("editFromSummary", true);
				goTo("promotionType");
			},
		},
		// {
		// 	title: "Applies to",
		// 	value: "[INSERT]",
		// 	key: "appliesTo",
		// 	edit: () => {
		// 		setFieldValue("editFromSummary", true);
		// 		goTo("appliesTo");
		// 	},
		// },
		{
			title: promotionLabel,
			value: promotionListValue,
			key: "promotionValue",
			edit: () => {
				setFieldValue("editFromSummary", true);
				goTo("promotionValue");
			},
		},
		{
			title: "Availability",
			value: _availableDates,
			key: "availability",
			edit: () => {
				setFieldValue("editFromSummary", true);
				goTo("availability");
			},
		},
		{
			title: "Code Limit",
			value: values.codeLimit || "No Limit",
			key: "codeLimit",
			edit: () => {
				setFieldValue("editFromSummary", true);
				goTo("limits");
			},
		},
		{
			title: "User Limit",
			value: values.userLimit || "No Limit",
			key: "userLimit",
			edit: () => {
				setFieldValue("editFromSummary", true);
				goTo("limits");
			},
		},
	];

	let button = getButton(
		"submit",
		"green",
		handleSubmit,
		isSubmitting,
		undefined,
		undefined,
		undefined,
		keyStrokeHandler
	);

	if (mode === "enable" || mode === "disable") {
		button = getButton(
			mode,
			mode === "enable" ? "green" : "red",
			handleSubmit,
			isSubmitting,
			undefined,
			undefined,
			keyStrokeHandler
		);
	}

	if (mode === "delete") {
		items.forEach((item) => delete item.edit);
		button = getButton(
			"remove",
			"red",
			handleSubmit,
			isSubmitting,
			undefined,
			undefined,
			undefined
		);
	}

	return {
		id: "summary",
		label: "Summary",
		render: () => (
			<div>
				<StepTitle>Summary</StepTitle>
				<SummaryTable items={items} />
			</div>
		),
		footer: () => <WizardNavigation rightItems={[button]} />,
	};
}
