import React, { useState } from "react";
import { fetchDataCallback, paginatedState } from "../../helpers/pagination";
import DropdownMenuBeta from "../../components/layout/DropdownMenuBeta";
import { MoreHorizontal } from "react-feather";
import ReportWrapperBeta from "../../components/report/ReportWrapperBeta";
import UserDetails from "../../components/details-pages/users/UserDetails";
import { format } from "../../helpers";
import gql from "graphql-tag";
import { useQueryData } from "../../hooks";

const columns = (props) => [
	{
		id: "UserID",
		Header: "User ID",
		accessor: (d) => d.UserID,
		width: 80,
		highlightCell: (cellProps) => cellProps.row.original.UserID,
	},
	{
		id: "Name",
		Header: "Name",
		accessor: (d) => d.Name,
		highlightCell: (cellProps) => cellProps.row.original.Name,
		className: "bold",
	},
	{
		id: "Email",
		Header: "Email",
		minWidth: 200,
		accessor: (d) => d.Email,
		highlightCell: (cellProps) => cellProps.row.original.Email,
	},
	{
		id: "Phone",
		Header: "Phone",
		accessor: (d) => d.Phone || "",
	},
	{
		id: "CreatedOn",
		Header: "Created On",
		accessor: (d) => d.CreatedOn,
		Cell: (cellProps) => format.localDate(cellProps.row.original.CreatedOn),
	},
	{
		id: "LastUsedClientCode",
		Header: "Last Used Client Code",
		accessor: (d) => d.LastUsedClientCode || "",
	},
	{
		id: "LastUsedAppVersion",
		Header: "Last Used App Version",
		accessor: (d) => d.LastUsedAppVersion || "",
	},
	{
		id: "ClientGroup",
		Header: "Client Group",
		accessor: (d) => d.ClientGroup || "",
	},
	{
		id: "CountryCode",
		Header: "Country Code",
		accessor: (d) => String(d.CountryCode || "").toUpperCase(),
	},
	{
		id: "LastOrgParked",
		Header: "Last Organization Parked",
		accessor: (d) => d.OrganizationName || "",
	},
	{
		id: "LastSiteParked",
		Header: "Last Site Parked",
		accessor: (d) => d.SiteName || "",
		highlightCell: (cellProps) => cellProps.row.original.SiteName || "",
	},
	{
		id: "LastDateParked",
		Header: "Last Date Parked",
		accessor: (d) => d.LastParkTimestamp || "",
		Cell: (cellProps) =>
			format.localDate(cellProps.row.original.LastParkTimestamp),
	},
	{
		id: "tasks",
		Header: "",
		accessor: null,
		Cell: (cellProps) => (
			<DropdownMenuBeta
				triggerContent={<MoreHorizontal size={24} />}
				items={[
					<div
						key="userDetails"
						onClick={() => props.openUserDetails(cellProps.row.original)}
					>
						More Info
					</div>,
				]}
			/>
		),
		resizable: false,
		fixedWidth: 50,
		sortable: false,
	},
];

const defaultSorting = [
	{
		id: "UserID",
		desc: true,
	},
];

export default function AdminUsersContainer(props) {
	const [state, setState] = useState({
		search: "",
		userDetailsOpen: false,
		userDetailsUser: null,
		userDetailsMode: "parking",
	});

	const [paginate, setPaginate] = useState(paginatedState);

	const fetchPaginatedData = fetchDataCallback(setPaginate);

	async function updateOptions({ search }) {
		setState((_state) => ({ ..._state, search }));
	}

	const skipQuery =
		!paginate.pageSize || !paginate.sortBy || !paginate.sortOrder;

	const {
		data: { getGlobalCustomerList: globalUsersReport },
		isLoading,
		refetch,
	} = useQueryData(
		gql`
			query ($page: PageInfo!, $cursor: Int) {
				getGlobalCustomerList(page: $page, cursor: $cursor) {
					trueLength
					previousEvent
					users {
						UserID
						Name
						Email
						Phone
						CreatedOn
						LastUsedClientCode
						LastUsedAppVersion
						CountryCode
						OrganizationName
						SiteName
						ClientGroup
						LastDateParked
						LastParkTimestamp
						IsDeleted
						RankingScore
					}
				}
			}
		`,
		{
			page: {
				next: paginate.next,
				pageOffset: paginate.pageOffset,
				pageSize: paginate.pageSize,
				sortBy: paginate.sortBy,
				sortOrder: paginate.sortOrder,
				searchTokens: paginate.searchTokens,
				eventType: paginate.eventType,
				sortedVal: paginate.cursor.sortedVal,
			},
			cursor: paginate.cursor.cursor,
		},
		skipQuery
	);

	const users =
		globalUsersReport && globalUsersReport.users ? globalUsersReport.users : [];

	function openUserDetails(user, mode) {
		setState((_state) => ({
			..._state,
			userDetailsOpen: true,
			userDetailsUser: user,
			userDetailsMode: mode,
		}));
	}

	function closeUserDetails() {
		setState((_state) => ({ ..._state, userDetailsOpen: false }));
	}

	if (state.userDetailsOpen) {
		return (
			<UserDetails
				close={closeUserDetails}
				user={state.userDetailsUser}
				global={true}
				refetch={refetch}
			/>
		);
	}

	return (
		<div>
			<ReportWrapperBeta
				{...props}
				title="Global Users"
				data={users}
				columns={columns({ openUserDetails: openUserDetails })}
				defaultSortBy={defaultSorting}
				updateOptions={updateOptions}
				loading={false}
				showDateRangePicker={false}
				showSitePicker={false}
				fetchPaginatedData={fetchPaginatedData}
				dataTrueLength={globalUsersReport ? globalUsersReport.trueLength : 0}
				cursorColumn="UserID"
				paginationIsLoading={isLoading || skipQuery}
				pageSize={state.pageSize}
				previousEvent={globalUsersReport && globalUsersReport.previousEvent}
				searchTokens={paginate.searchTokens}
			/>
		</div>
	);
}
