import React, { useState } from "react";
import { fetchDataCallback, paginatedState } from "../../helpers/pagination";
import { format, user } from "../../helpers";
import { useMutateData, usePermissions, useQueryData } from "../../hooks";
import Alert from "react-s-alert";
import Button from "../../components/layout/Button";
import Card from "../../components/layout/Card";
import DropdownMenu from "../../components/layout/DropdownMenu";
import FlexWrapper from "../../components/layout/FlexWrapper";
import Lightbox from "react-image-lightbox";
import { MoreHorizontal } from "react-feather";
import ReportWrapperBeta from "../../components/report/ReportWrapperBeta";
import SessionWizard from "../../components/wizards/session-wizard/SessionWizard";
import StatCard from "../../components/layout/StatCard";
import _ from "lodash";
import gql from "graphql-tag";
import moment from "moment";

const lightboxColumn = (props) => ({
	id: "tasks",
	Header: "",
	accessor: null,
	Cell: ({ row: { original } }) => (
		<>
			{original.ImageUrl && (
				<DropdownMenu
					triggerContent={<MoreHorizontal size={24} />}
					items={[
						<div key="view-image" onClick={() => props.openLightbox(original)}>
							View Image
						</div>,
					]}
				/>
			)}
		</>
	),
	resizable: false,
	fixedWidth: 50,
	sortable: false,
});

const columns = (props) => [
	{
		id: "UserName",
		Header: "User",
		csvAccessor: "_userNameFormatted",
		accessor: (d) => d.UserName || "",
		highlightCell: ({ row: { original: d } }) => d._userNameFormatted,
		className: "bold",
	},
	{
		id: "SiteName",
		Header: "Site",
		csvAccessor: "SiteName",
		accessor: (d) => d.SiteName || "",
		highlightCell: ({ row: { original: d } }) => d.SiteName || "",
	},
	{
		id: "LaneAndGate",
		Header: "Lane & Gate",
		accessor: (d) => d.LaneAndGate || "",
		csvAccessor: "_laneAndGateCsv",
		highlightCell: ({
			row: {
				original: { LaneName, GateName },
			},
		}) => (
			<>
				<div>{LaneName}</div>
				<div style={{ fontWeight: 700 }}>{GateName}</div>
			</>
		),
	},
	{
		id: "InteractedOn",
		Header: "Triggered At",
		csvAccessor: "InteractedOn",
		accessor: (d) => d.InteractedOn || 0,
		highlightCell: ({ row: { original: d } }) => d._triggeredAt || "",
	},
	{
		id: "Numberplate",
		Header: "Plate",
		csvAccessor: "Numberplate",
		accessor: (d) => d.Numberplate,
		highlightCell: ({ row: { original: d } }) =>
			!d.Numberplate && d.CameraEventID ? "No Plate" : d.Numberplate ?? "",
	},
	{
		id: "InteractionType",
		Header: "Interaction Type",
		csvAccessor: "InteractionType",
		accessor: (d) => d.InteractionType || "",
		highlightCell: ({ row: { original: d } }) =>
			d.LocationBased
				? `${d.InteractionType} - Location Based`
				: d.InteractionType || "",
	},
	{
		id: "OutcomeType",
		Header: "Outcome",
		accessor: (d) => d.OutcomeType || "",
		csvAccessor: "OutcomeType",
		highlightCell: ({ row: { original: d } }) => d.OutcomeType || "",
	},
	{
		id: "DeviceID",
		Header: "Device ID",
		accessor: (d) => d.DeviceID || 0,
		csvAccessor: "DeviceID",
		highlightCell: ({ row: { original: d } }) => d.DeviceID || "",
	},
	lightboxColumn(props),
];

const adminColumns = (props) => [
	...columns().slice(0, -1),
	{
		id: "OpeningDelayDuration",
		Header: "Opening Delay (ms)",
		accessor: (d) => d._openingDelay || 0,
		csvAccessor: "_openingDelay",
		highlightCell: ({ row: { original: d } }) => d._openingDelay || "",
	},
	{
		id: "ClientInteractionDuration",
		Header: "Client Delay (ms)",
		accessor: (d) => d.ClientInteractionDuration || 0,
		csvAccessor: "ClientInteractionDuration",
		highlightCell: ({ row: { original: d } }) =>
			d.ClientInteractionDuration || "",
	},
	lightboxColumn(props),
];

const csvHeaders = () => {
	const headers = _.map(columns(), (c) => ({
		label: c.Header,
		key: c.csvAccessor === "InteractedOn" ? "_triggeredAt" : c.csvAccessor,
	}));
	headers.pop();

	return headers;
};

const adminCsvHeaders = () => {
	const headers = _.map(adminColumns(), (c) => ({
		label: c.Header,
		key: c.csvAccessor === "InteractedOn" ? "_triggeredAt" : c.csvAccessor,
	}));
	headers.pop();

	return headers;
};

const defaultSorting = [
	{
		id: "InteractedOn",
		desc: true,
	},
];

export default function ActivityContainer(props) {
	const [state, setState] = useState({
		initialLoad: true,
		options: {
			sites: [],
			"start-date-time": null,
			"end-date-time": null,
		},
		search: "",
		lightboxOpen: false,
		lightboxImage: null,
	});

	const isAdmin = usePermissions("IsAdmin");
	const canManageSessions = usePermissions(null, "SessionAdmin", true);

	const [paginate, setPaginate] = useState(paginatedState);

	const fetchPaginatedData = fetchDataCallback(setPaginate);

	function formatData(_data) {
		return _.map(_data, (d) => ({
			...d,
			_laneAndGateCsv: d.LaneAndGate.replace(/^\s*(\()(\d+)(\))$/g, "$2"),
			_triggeredAt: format.localDate(
				d.InteractedOn || d.CreatedAt,
				"X",
				false,
				true
			),
			_openingDelay: d.OpeningDelayDuration || d.Duration,
			_userNameFormatted:
				!d.UserEmail || user.isAnonymous(d.UserEmail)
					? "Unknown"
					: d.UserName || "",
		}));
	}

	const skipDataQuery =
		!state.options["start-date-time"] ||
		!state.options["end-date-time"] ||
		!paginate.pageSize ||
		!paginate.sortBy ||
		!paginate.sortOrder;

	const { data, isLoading } = useQueryData(
		gql`
			query (
				$start: Timestamp!
				$end: Timestamp!
				$siteIds: [Int!]
				$admin: Boolean
				$page: PageInfo!
				$cursor: NICursor
			) {
				getNodeInteractionsPaginated(
					start: $start
					end: $end
					siteIds: $siteIds
					admin: $admin
					page: $page
					cursor: $cursor
				) {
					interactions {
						NodeInteractionID
						InteractedOn
						InteractionType
						OutcomeType
						OpeningDelayDuration
						ClientInteractionDuration
						DeviceID
						AccessCode
						ClientCode
						LocationBased
						Source
						LaneName
						GateName
						LaneAndGate
						UserName
						SiteName
						RankingScore
						CameraEventID
						UserEmail
						Numberplate
						ImageUrl
					}
					trueLength
					previousEvent
				}
			}
		`,
		{
			start: moment(state.options["start-date-time"]).startOf("day").unix(),
			end: moment(state.options["end-date-time"]).endOf("day").unix(),
			siteIds: state.options.sites || [],
			admin: props.admin,
			page: {
				pageOffset: paginate.pageOffset,
				pageSize: paginate.pageSize,
				sortBy: paginate.sortBy,
				sortOrder: paginate.sortOrder,
				searchTokens: paginate.searchTokens,
				eventType: paginate.eventType,
				sortedVal: paginate.cursor.sortedVal,
			},
			cursor:
				!paginate.cursor.cursor ||
				Object.keys(paginate.cursor.cursor).length === 0
					? { NodeInteractionID: 0, CameraEventID: "" }
					: paginate.cursor.cursor,
		},
		skipDataQuery
	);

	const bulkDownloadReport = useMutateData(gql`
		mutation ($args: BulkDownloadReportInput!) {
			bulkDownloadActivityReport(args: $args)
		}
	`);

	let interactions = _.get(
		data,
		"getNodeInteractionsPaginated.interactions",
		[]
	);

	interactions = formatData(interactions);

	const trueLength = _.get(data, "getNodeInteractionsPaginated.trueLength", 0);

	function openSessionWizard(mode) {
		setState((_state) => ({
			..._state,
			sessionAdminWizardOpen: true,
			sessionAdminWizardMode: mode,
		}));
	}

	function openLightbox(interaction) {
		setState((_state) => ({
			..._state,
			lightboxOpen: true,
			lightboxImage: interaction.ImageUrl,
		}));
	}

	async function updateOptions({ search, ...options }) {
		setState((_state) => ({ ..._state, initialLoad: false, search, options }));
	}

	if (state.sessionAdminWizardOpen) {
		return (
			<SessionWizard
				close={() => {
					setState((_state) => ({ ..._state, sessionAdminWizardOpen: false }));
				}}
				mode={state.sessionAdminWizardMode}
				sites={_.filter(
					props.availableSites,
					(site) => site.SiteType === "Gated"
				)}
			/>
		);
	}

	return (
		<div>
			<ReportWrapperBeta
				{...props}
				title="Activity"
				data={interactions}
				columns={
					isAdmin ? adminColumns({ openLightbox }) : columns({ openLightbox })
				}
				defaultSortBy={defaultSorting}
				updateOptions={updateOptions}
				availableSites={_.filter(
					props.availableSites,
					(site) => site.SiteType === "Gated" || !!site.InstallationID
				)}
				loading={false}
				csvHeaders={isAdmin ? adminCsvHeaders() : csvHeaders()}
				csvName={"inugo-activity-report.csv"}
				rightActions={
					!props.admin && canManageSessions ? (
						<Button
							style={{ marginLeft: 8 }}
							color="blue"
							onClick={() => openSessionWizard("open-gate")}
						>
							Open Gate
						</Button>
					) : null
				}
				stats={
					<FlexWrapper style={{ marginBottom: -16 }}>
						<Card style={{ flex: 0.3 }}>
							<StatCard
								value={trueLength}
								title="Total Interactions"
								size="medium"
							/>
						</Card>
					</FlexWrapper>
				}
				showSitePicker={!props.admin}
				dataTrueLength={trueLength}
				cursorColumn={(d) => ({
					NodeInteractionID: d.NodeInteractionID,
					CameraEventID: d.CameraEventID,
				})}
				paginationIsLoading={isLoading || skipDataQuery || props.isLoading}
				pageSize={state.pageSize}
				previousEvent={data?.getNodeInteractionsPaginated?.previousEvent}
				searchTokens={paginate.searchTokens}
				fetchPaginatedData={fetchPaginatedData}
				onBulkDownloadClicked={async () => {
					try {
						await bulkDownloadReport({
							variables: {
								args: {
									organizationId: props.selectedOrganization?.OrganizationID,
									siteIds: state.options.sites,
									startDateTime: moment(state.options["start-date-time"])
										.startOf("day")
										.format(),
									endDateTime: moment(state.options["end-date-time"])
										.endOf("day")
										.format(),
									page: {
										sortBy: paginate.sortBy,
										sortOrder: paginate.sortOrder,
										searchTokens: paginate.searchTokens,
									},
									adminPage: props.admin,
								},
							},
						});

						Alert.success("The report will be sent to your email shortly");
					} catch (error) {
						Alert.error("Something went wrong please try again");
					}
				}}
			/>
			{state.lightboxOpen && (
				<Lightbox
					mainSrc={state.lightboxImage}
					onCloseRequest={() =>
						setState((_state) => ({
							..._state,
							lightboxOpen: false,
						}))
					}
					imageTitle={"Capture"}
				/>
			)}
		</div>
	);
}
